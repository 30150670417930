import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Hyperlink from './Hyperlink';
import Button from '../../globals/buttons/Button';

import './hyperlink.scss';

/**
 * @description - sub navigation component
 * @param {*} fields - sub navigation component fields
 * @returns {Node} - HTML component
 */
const HyperlinkCardBlock = ({ fields, params, t }) => {
  const { title, items, bgColor, isBoxBlock } = fields;

  const [showMore, setShowMore] = useState(
    items && items.length > Number(params?.noOfItems) ? true : false
  );
  const [navItems, setNavItems] = useState([]);
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    if (window) {
      resizeEvent();
      window.addEventListener('resize', resizeEvent);
    }
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  /**
   * @description to get width on load and resize
   * @param {string} setValue - url
   * @returns {null} - empty
   */
  const resizeEvent = () => {
    if (window) setMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    setNavItems(
      showMore
        ? items && Array.isArray(items) && items.slice(0, parseInt(params.noOfItems))
        : items
    );
  }, [showMore]);

  return isBoxBlock?.value === true ? (
    <section className="navbox-section hyperlink-cardblock">
      <div className="container">
        {title && <h2 className="title">{title?.value}</h2>}
        <div className="navbox-items">
          {navItems &&
            Array.isArray(navItems) &&
            navItems.map((item) => (
              <Hyperlink
                key={item?.title}
                icon={item?.fields?.icon}
                altText={item?.fields?.altText}
                title={item?.fields?.title}
                url={item?.fields?.url}
                queryParams={item?.fields?.queryParams}
                blockType={isBoxBlock}
              />
            ))}
        </div>
        {showMore && (
          <Button
            cssClass="blue-sd"
            text={t('load-more')}
            handleButtonClick={() => setShowMore(false)}
          />
        )}
      </div>
    </section>
  ) : (
    <section
      className={`seo-jobs ${
        bgColor?.value ? `seo-jobs--${bgColor?.value}` : `seo-jobs--default`
      }`}
    >
      <div className="container">
        <div className="row">
          <h2 className="seo-jobs__category">{title?.value}</h2>
          <div className="col-sm-12 col-md-12 col-xl-12">
            {!mobileView ? (
              <div className="row">
                {items &&
                  Array.isArray(items) &&
                  items.map((item, index) => {
                    return (
                      <Hyperlink
                        key={index}
                        title={item?.fields?.title}
                        url={item?.fields?.url}
                        queryParams={item?.fields?.queryParams}
                        blockType={isBoxBlock}
                      />
                    );
                  })}
              </div>
            ) : (
              <div className="row">
                {items &&
                  Array.isArray(items) &&
                  items.map((item, index) => {
                    return (
                      <Hyperlink
                        key={index}
                        title={item?.fields?.title}
                        url={item?.fields?.url}
                        queryParams={item?.fields?.queryParams}
                        blockType={isBoxBlock}
                      />
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

HyperlinkCardBlock.defaultProps = {
  fields: {
    title: {
      value: 'IT Focus Areas',
    },
    items: [
      {
        name: 'Business',
        displayName: 'Business',
        fields: {
          title: {
            value: 'Business',
          },
          icon: {
            src: '',
          },
          url: {
            value: {
              href: '',
              linktype: 'internal',
            },
          },
          queryParams: {
            value: 'Business',
          },
        },
      },
    ],
  },
  params: {
    noOfItems: '',
  },
};

HyperlinkCardBlock.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    isBoxBlock: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    bgColor: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        displayName: PropTypes.string,
        fields: PropTypes.shape({
          title: PropTypes.shape({
            value: PropTypes.string.isRequired,
          }),
          icon: PropTypes.shape({
            src: PropTypes.string.isRequired,
          }),
          url: PropTypes.shape({
            value: PropTypes.shape({
              href: PropTypes.string.isRequired,
              linktype: PropTypes.string,
            }),
          }),
          queryParams: PropTypes.shape({
            value: PropTypes.string.isRequired,
          }),
        }),
      })
    ),
    btnText: PropTypes.string,
  }),
  params: PropTypes.shape({
    noOfItems: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withSitecoreContext()(HyperlinkCardBlock));
