import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '../globals/link/ButtonLink';
import './diamondpagebanner.scss';
import { getImg } from '../../../utils/helperUtils';
import { mediaApi, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
/**
 * @description - My Path banner.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template.
 */
const SingleImageDiamondPageBanner = (props) => {
  const { fields } = props;
  const { title, text, ctaText, ctaUrl, backgroundMain, mobileBanner } = fields;

  return (
    <section
      className="singleimage banner-section stats diamond about single"
      style={{
        backgroundImage: `url(${mediaApi.updateImageUrl(getImg(backgroundMain))})`,
      }}
    >
      <Image class="mobile" field={mobileBanner} />
      <div className="white-bg"></div>
      <div className="solid-bg"></div>
      <ul className="diamond-grid">
        <li className="item2 dark"></li>
        <li className="item1"></li>
        <li className="item2"></li>
        <li className="item3"></li>
      </ul>
      <div className="container">
        <div className="row in-left">
          <div className="col-xl-6 col-lg-6 col-md-12">
            <RichText tag="h1" className="reversed title" field={title} />
            <RichText
              tag="p"
              className="large reversed singlediamondtext"
              field={text}
            />

            <ButtonLink
              ctaText={ctaText}
              ctaUrl={ctaUrl}
              cssClass="reversed"
              arrow
            />
          </div>
        </div>
      </div>
    </section>
  );
};

SingleImageDiamondPageBanner.defaultProps = {
  fields: {
    title: { value: '' },
    text: { value: '' },
    ctaText: { value: '' },
    ctaUrl: { value: '' },
    backgroundMain: { value: { src: '' } },
    mobileBanner: { value: { src: '' } },
  },
};

SingleImageDiamondPageBanner.propTypes = {
  fields: PropTypes.shape({
    text: PropTypes.shape({ value: PropTypes.string.isRequired }),
    title: PropTypes.shape({ value: PropTypes.string.isRequired }),
    ctaText: PropTypes.shape({ value: PropTypes.string }),
    ctaUrl: PropTypes.shape({ value: PropTypes.object }),
    backgroundMain: PropTypes.shape({ value: PropTypes.object.isRequired }),
    mobileBanner: PropTypes.shape({ value: PropTypes.object.isRequired }),
  }),
};

export default SingleImageDiamondPageBanner;
