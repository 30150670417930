import React from 'react';
import PropTypes from 'prop-types';
import './progressBar.scss';

const ProgressBar = (props) => {
  const {
    completed,
    height,
    primaryColor,
    secondaryColor,
    gradient,
    showLabel,
    background,
  } = props;
  const customStyles = {
    width: `${completed}%`,
    height,
    background: gradient
      ? `transparent linear-gradient(269deg, ${primaryColor}  0%,${secondaryColor} 120% ) 0% 0% no-repeat padding-box`
      : primaryColor,
  };
  const containerCustomStyle = {
    backgroundColor: background,
  };
  return (
    <div className="containerStyles" style={containerCustomStyle}>
      <div className="fillerStyles" style={customStyles}>
        {showLabel && <span className="labelStyles">{`${completed}%`}</span>}
      </div>
    </div>
  );
};
ProgressBar.defaultProps = {
  completed: 0,
  height: '20px',
  primaryColor: '#C25700',
  secondaryColor: '#fff',
  gradient: false,
  showLabel: false,
  background: '#d9d9d9',
};

ProgressBar.propTypes = {
  completed: PropTypes.number.isRequired,
  height: PropTypes.string,
  primaryColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  gradient: PropTypes.bool,
  showLabel: PropTypes.bool,
  background: PropTypes.string.isRequired,
};
export default ProgressBar;
