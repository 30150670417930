import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { checkArray } from '../../../utils/helperUtils';
import SiteFooterNavList from './SiteFooterNavList';

/**
 * @description - Site Footer component.
 * @param {*} props
 */
const SiteFooterNav = ({ menuItems }) => {
  return (
    <Fragment>
      <div className="row footer-nav">
        {checkArray(menuItems).map((colmns, idx) => {
          const { fields } = colmns;
          const itemVals = (fields && fields.items) || [];
          if (idx !== 2) {
            return (
              <div className="col-lg-4 col-6" key={`${colmns.name}_${idx}`}>
                <SiteFooterNavList items={itemVals} section="section 1" />
              </div>
            );
          }
          if (idx === 2) {
            const section1 = [];
            const section2 = [];
            itemVals.map((item, idx1) => {
              if (idx1 + 1 !== itemVals.length) section1.push(item);
              else section2.push(item);
            });

            return (
              <div className="col-lg-4 col-sm-12" key={`${colmns.name}_${idx}`}>
                <div className="row">
                  <div className="col-lg-12 col-6">
                    <SiteFooterNavList items={section1} section="section 2" />
                  </div>
                  <div className="col-lg-12 col-6">
                    <SiteFooterNavList items={section2} section="section 3" />
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </Fragment>
  );
};

SiteFooterNav.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({})),
};

SiteFooterNav.defaultProps = {
  menuItems: [],
};

export default SiteFooterNav;
