/* eslint-disable */

import {
  filterArrayByType,
  isNullorEmpty,
} from '../../../../../../utils/helperUtils';
import { checkValueTypeAndGetTheCount } from '../../../../Utils/helper';
import lget from 'lodash.get';

export const missingInfo = (item, data, filterRequired = false) => {
  const obj = {
    emptyCount: 0,
    temp: 0,
  };
  item?.fields?.items?.map((subItem) => {
    const field = subItem?.fields?.name?.value.split('/');
    const requiredFields = subItem?.fields?.multipleRequired?.value
      ? subItem?.fields?.multipleRequired?.value?.split('/')
      : [subItem?.fields?.required?.value];
    if (subItem?.fields?.type?.value === 'file' && filterRequired) {
      data = filterArrayByType(data?.attachment, subItem?.fields?.name?.value);
      if (
        data &&
        (requiredFields?.[0] === 'true' || requiredFields?.[0] === true) &&
        !checkValueTypeAndGetTheCount(data) &&
        isNullorEmpty(subItem?.fields?.value?.value)
      ) {
        obj.emptyCount = obj.emptyCount + 1;
      }
      if (
        checkValueTypeAndGetTheCount(data) ||
        !isNullorEmpty(subItem?.fields?.value?.value)
      ) {
        obj.temp = obj.temp + 1;
      }
    } else {
      const fieldLength = field?.length;
      for (let i = 0; i < fieldLength; i++) {
        if (
          data &&
          (requiredFields?.[i] === 'true' || requiredFields?.[i] === true) &&
          !checkValueTypeAndGetTheCount(lget(data, field?.[i])) &&
          isNullorEmpty(subItem?.fields?.value?.value)
        ) {
          obj.emptyCount = obj.emptyCount + 1;
          break;
        }
      }
      for (let i = 0; i < fieldLength; i++) {
        if (
          checkValueTypeAndGetTheCount(lget(data, field?.[i])) ||
          !isNullorEmpty(subItem?.fields?.value?.value)
        ) {
          obj.temp = obj.temp + 1;
          break;
        }
      }
    }
  });
  return obj;
};
