/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import PhotoUpload from '../PhotoUpload';

/**
 * @description - DashboardProfileMobile component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const DashboardProfileMobile = ({ fields, userDetails, t }) => {
  const { icon, title } = fields;
  const [newTitle, setTitle] = useState({ value: '' });

  useEffect(() => {
    if (userDetails?.firstName) {
      title.value = title.value.replace('{0}', userDetails?.firstName || t('user'));
      setTitle(title);
    }
  }, [userDetails]);

  return (
    <div className="dashboard-profile-mobile">
      <div className="dashboard-thumb">
        <PhotoUpload width={150} height={150} icon={icon} t={t} />
      </div>
      <div className="dashboard-greeting">
        <h1 className="sr-only">{newTitle?.value?.replace(/(<([^>]+)>)/gi, '')}</h1>
        {newTitle && <RichText field={newTitle} className="clearfix" />}
      </div>
    </div>
  );
};

DashboardProfileMobile.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};

DashboardProfileMobile.defaultProps = {
  fields: {},
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginReducer.user,
  };
};

export default connect(mapStateToProps)(withTranslation()(DashboardProfileMobile));
