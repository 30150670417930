import { createGlobalStyle } from 'styled-components';

export const HamburgerStyle = createGlobalStyle`
.site-header{
    #ot-sdk-btn.ot-sdk-show-settings{
    border: none;
    color: ${({ theme }) => theme?.normalButtonLink};
    background-color: transparent;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    padding-left: 5px;
    text-transform: Uppercase;
    }
    #ot-sdk-btn.ot-sdk-show-settings:hover{
        color: ${({ theme }) => theme?.normalButtonLink};
        background-color: transparent;
    }
    .otbtncontainer.title {
        padding-top:24px
    }
}
  `;
