import React from 'react';
import PropTypes from 'prop-types';
import { DateField, mediaApi, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import TextButton from '../globals/buttons/TextButton';
import StaticLink from '../globals/link/StaticLink';

import { showString } from '../../../utils/helperUtils';
import { CardStyle } from './CardTheme';
import { DATE_FORMATS } from '../../../utils/enums';

/**
 * @description - Render button or arraow link.
 * @param {string} mode - Mode for display.
 * @param {string|object} ctaText - CTA name.
 * @returns {Node} - Button or arrow link.
 */
const renderButton = (mode, ctaText) => {
  switch (mode) {
    case 'arrow':
      return (
        <>
          <div className="article-arrow">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-arrow-right article-icon arrow-icon"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              />
            </svg>
          </div>
        </>
      );

    case 'link':
      return <StaticLink cssClass="articles-link" ctaText={ctaText} arrow />;
    case 'text':
      return <TextButton cssClass="cta" text={ctaText} />;
    default:
      return <div className="arrow-icon" />;
  }
};

/**
 * @description - Article Card.
 * @param {object} param0 - Input params.
 * @returns {Node} - HTML card component.
 */
const CardArticle = ({
  title,
  subTitle,
  date,
  img,
  altText,
  ctaUrl,
  ctaText,
  ctaMode,
  isFeatured,
  dateFormat,
}) => {
  const imgVal = mediaApi.updateImageUrl(img);

  return (
    <>
      <CardStyle />
      <Link
        to={ctaUrl}
        className={`card article card--wipe ${isFeatured ? 'featured' : ''}`}
      >
        <span className="card--wipe--apollo" />
        {isFeatured === true && (
          <figure
            className="article-image"
            style={{ backgroundImage: `url(${imgVal})` }}
          >
            <img className="sr-only" alt={altText} src={imgVal} />
          </figure>
        )}
        <div className="card-body">
          {subTitle?.value ? (
            <RichText tag="div" className="sub-title" field={subTitle} />
          ) : (
            <div className="sub-title"> {showString(subTitle)}</div>
          )}
          <div className="date">
            <DateField
              field={date}
              render={() => moment(date?.value).format(dateFormat)}
            />
          </div>
          <RichText tag="div" className="title" field={title} />
          {renderButton(ctaMode, ctaText, ctaUrl)}
        </div>
      </Link>
    </>
  );
};

CardArticle.defaultProps = {
  title: { value: '' },
  subTitle: '',
  date: { value: new Date() },
  img: { src: '' },
  altText: '',
  ctaUrl: '#',
  ctaText: 'Read more',
  ctaMode: 'arrow',
  isFeatured: false,
  dateFormat: DATE_FORMATS.DEFAULT_US,
};

CardArticle.propTypes = {
  title: PropTypes.shape({ value: PropTypes.string }),
  /** Source or type of article */
  subTitle: PropTypes.oneOfType([
    PropTypes.shape({ value: PropTypes.string }),
    PropTypes.string,
  ]),
  date: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  /** Image only used when featured */
  img: PropTypes.shape({}),
  altText: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaText: PropTypes.string,
  ctaMode: PropTypes.oneOf(['arrow', 'text']).isRequired,
  isFeatured: PropTypes.bool,
  dateFormat: PropTypes.string,
};

export default CardArticle;
