/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import lget from 'lodash.get';
import { withTranslation } from 'react-i18next';
import { getValidationDatabyType } from '../DynamicForm/helper/filter';
import { checkValueTypeAndGetTheCount } from '../../../business/Utils/helper';

/**
 * @description - Dynamic MultiCheckbox component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template for dashboard.
 */
const MultiCheckbox = ({
  id,
  name,
  label,
  placeholder,
  type,
  icon,
  customStyle,
  readOnly,
  customError,
  hintText,
  register,
  dataValidations,
  getValues,
  setValue,
  watch,
  showBasedOnFieldName,
  hideBasedOnFieldName,
  isHideField,
  value,
  list,
  t,
  requiredValidation = [],
  formName,
}) => {
  const fieldError = lget(customError, name);
  const requiredData =
    requiredValidation?.length > 0
      ? requiredValidation[0]
      : getValidationDatabyType(dataValidations, 'required');
  const [showField, setShowField] = useState(true);
  const [hideField, setHideField] = useState(false);
  const showFieldName = formName
    ? `${formName}[${showBasedOnFieldName}]`
    : showBasedOnFieldName;
  const hideFieldName = formName
    ? `${formName}[${hideBasedOnFieldName}]`
    : hideBasedOnFieldName;

  const showFieldValue = showFieldName ? watch(showFieldName) : null;
  const hideFieldValue = hideFieldName ? watch(hideFieldName) : null;

  useEffect(() => {
    if (showFieldValue) {
      let isFieldHasVal = checkValueTypeAndGetTheCount(showFieldValue);
      setShowField(isFieldHasVal);
    }
  }, [showFieldValue]);

  useEffect(() => {
    if (hideFieldValue) {
      let isFieldHasVal = checkValueTypeAndGetTheCount(hideFieldValue);
      setHideField(isFieldHasVal);
    }
  }, [hideFieldValue]);

  useEffect(() => {
    if (!value) return;
    list.map((item) => {
      let dfValue =
        item?.fields?.key?.value &&
        value[item.fields.key.value] &&
        value[item.fields.key.value] === 'yes'
          ? true
          : false;
      setValue(`${name}.${item.fields.key.value}`, dfValue, {
        shouldValidate: true,
      });
    });
  }, [value]);

  const filterValueLength = (_) => {
    const values = getValues({ nest: true });
    let filterLength =
      values &&
      values[name] &&
      Object.keys(values[name]).filter((key) => values[name][key] === true).length;
    return filterLength;
  };

  const validateInput = async () => {
    return filterValueLength() >= 1 || 'Select at least 1.';
  };

  return (
    <div className="form-block">
      <div className="checBoxLabelText">{label}</div>
      {list.map((item, i) => (
        <div
          className={`checkbox ${fieldError ? 'error withoutBg' : ''} ${
            isHideField || !showField || hideField ? 'hide' : ''
          }`}
          style={customStyle}
          key={i}
        >
          <input
            name={`${name}.${item.fields.key.value}`}
            id={item.id}
            type="checkbox"
            readOnly={item.fields.readOnly}
            onChange={(e) => {
              return e.target.checked ? e.target.value : '';
            }}
            ref={
              register
                ? register({
                    validate:
                      (!isHideField || !showField || hideField) &&
                      requiredData?.fields?.value?.value
                        ? validateInput
                        : false,
                  })
                : () => {}
            }
          />
          <label htmlFor={item.id}>
            {item?.fields?.label?.value ? (
              item?.fields?.label?.value
            ) : (
              <span className="sr-only">Checkbox Label</span>
            )}
          </label>
        </div>
      ))}
      {fieldError && (
        <div className="error-msg" id={`err_${id}`} role="alert">
          {fieldError.type === 'required'
            ? requiredData?.fields?.message?.value
              ? requiredData?.fields?.message?.value?.replace(
                  '{0}',
                  label?.replace('*', '')
                )
              : t('mandatory-field-message').replace('{0}', label?.replace('*', ''))
            : fieldError?.message?.replace('{0}', label?.replace('*', ''))}
        </div>
      )}
    </div>
  );
};

MultiCheckbox.defaultProps = {
  id: '',
  name: '',
  label: '',
  placeholder: '',
  type: 'checkbox',
  icon: '',
  readOnly: false,
  setValue: () => {},
  t: () => {},
};

MultiCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  icon: PropTypes.string,
  readOnly: PropTypes.bool,
  setValue: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation()(MultiCheckbox);
