/* eslint-disable require-jsdoc */
import { ADB2C } from '../actions/actionTypes';

const initialState = {
  showLogin: false,
  authProvider: null,
  login: () => null,
  logout: () => null,
  editProfile: () => null,
  editPhoneNumber: () => null,
  editPassword: () => null,
  refreshToken: () => null,
};

const authFunctionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADB2C.SETAUTHFUNC:
      return {
        ...state,
        ...action.payload,
      };
    case ADB2C.SHOWLOGIN:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default authFunctionsReducer;
