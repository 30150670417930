import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  withPlaceholder,
  withSitecoreContext,
  isExperienceEditorActive,
} from '@sitecore-jss/sitecore-jss-react';

import SiteFooterNav from '../SiteFooter/SiteFooterNav';

import './sitefootercountryselector.scss';
import './sitefooter.scss';
import '../globals/link/links.scss';
import { withTranslation } from 'react-i18next';

/**
 * @description - Site Footer Container.
 * @param {Object} props - Input props.
 * @returns {Node} - Footer element.
 */
const FooterContainer = (props) => {
  const { tabComponents = [], disclaimerComponent = [], sitecoreContext } = props;
  const placeholders = sitecoreContext?.route?.placeholders;
  const common = placeholders && placeholders['jss-common'];
  const { items = [] } = (common && common[0]?.fields) || {};
  const [footerContent, setFooterContent] = useState([]);

  useEffect(() => {
    setFooterContent(disclaimerComponent[0]);
  }, [disclaimerComponent]);

  return (
    <footer className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <SiteFooterNav menuItems={items} />
          </div>
          <div className="col-lg-4">
            <div className="footer-rightside">
              {tabComponents.map((component, index) => {
                if (component.props && component.props.type === 'text/sitecore')
                  return component;
                return <Fragment key={index}>{component}</Fragment>;
              })}
            </div>
          </div>
        </div>
      </div>
      {/* This is being used to display footer disclaimer content */}
      {footerContent?.props && footerContent?.props?.type === 'text/sitecore' ? (
        footerContent
      ) : (
        <Fragment key="content">{footerContent}</Fragment>
      )}
      {/* This loop is only used to display blank placeholder in experience editor for footer disclaimer */}
      {disclaimerComponent.map((component) => {
        if (component?.props && component?.props?.type === 'text/sitecore')
          return component;
      })}
      {/* This is being used to display footer bottom content (copyrights, social Icons and Counry selector) */}
      {!isExperienceEditorActive() && (
        <div className="sub-footer-disclaimer">
          <div className="sub-footer-disclaimer-inner col-10">
            {disclaimerComponent.map((component, index) => {
              if (index > 0) {
                if (component?.props && component?.props?.type === 'text/sitecore')
                  return component;
                return <Fragment key={index}>{component}</Fragment>;
              }
            })}
          </div>
        </div>
      )}
    </footer>
  );
};

FooterContainer.defaultProps = {
  tabComponents: [],
  disclaimerComponent: [],
  fields: {},
  sitecoreContext: {},
};

FooterContainer.propTypes = {
  tabComponents: PropTypes.arrayOf(PropTypes.shape()),
  disclaimerComponent: PropTypes.arrayOf(PropTypes.shape({})),
  fields: PropTypes.shape({}).isRequired,
  sitecoreContext: PropTypes.shape({}).isRequired,
};

const containerComp = withSitecoreContext()(
  withTranslation()(
    withPlaceholder([
      { placeholder: 'jss-footer-right', prop: 'tabComponents' },
      { placeholder: 'jss-footer-disclaimer', prop: 'disclaimerComponent' },
    ])(FooterContainer)
  )
);

export default containerComp;
