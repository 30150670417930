export const style = {
  Experis: {
    primaryColor: 'rgb(56, 96, 151)',
    secondaryColor: 'rgb(194, 87, 0)',
    backgroundColor: 'rgb(67, 128, 128)',
    primaryButton: 'rgb(56, 96, 151)',
    secondaryButton: 'rgb(194, 87, 0)',
    normalText: 'rgb(255, 255, 255)',
    heading: 'rgb(255, 255, 255)',
    headingSecondary: 'rgb(56, 96, 151)',
    subText: 'rgb(255, 255, 255)',
    secondaryButtonLink: 'rgb(117, 147, 188)',
    normalButtonLink: 'rgb(177, 199, 226)',
    gradient: 'rgb(28, 62, 107)',
    horizontalRule: 'rgb(56, 96, 151)',
    secondaryText: 'rgb(73, 75, 84)',
    ErrorColor: 'rgb(157, 50, 61)',
    SuccessColor: 'rgb(92, 125, 112)',
    secondaryBackgroundColor: 'rgb(56, 96, 151)',
    secondaryTitleText: 'rgb(203, 213, 227)',
    primaryTitleText: 'rgb(56, 96, 151)',
    primaryBackgroundColor: 'rgb(49, 102, 102)',
    footerBackground: 'rgb(217, 217, 219)',
    footerText: 'rgb(0, 0, 0)',
    primaryLight: 'rgb(232, 238, 245)',
    primaryLightBackground: 'rgb(117, 147, 188)',
  },
  Stegplus: {
    primaryColor: 'rgb(232, 39, 23)',
    secondaryColor: 'rgb(255, 255, 255)',
    backgroundColor: 'rgb(247, 237, 236)',
    primaryButton: 'rgb(232, 39, 23)',
    secondaryButton: 'rgb(232, 39, 23)',
    normalText: 'rgb(33, 37, 32)',
    heading: 'rgb(232, 39, 23)',
    headingSecondary: 'rgb(68, 97, 111)',
    subText: 'rgb(73, 75, 84)',
    secondaryButtonLink: 'rgb(250, 142, 133)',
    normalButtonLink: 'rgb(255, 255, 255)',
    gradient: 'rgb(177, 29, 17)',
    horizontalRule: 'rgb(68, 97, 111)',
    secondaryText: 'rgb(67, 97, 112)',
    ErrorColor: ' rgb(157, 50, 61)',
    SuccessColor: 'rgb(92, 125, 112)',
    secondaryBackgroundColor: 'rgb(247, 237, 236)',
    secondaryTitleText: 'rgb(69, 97, 110)',
    primaryTitleText: 'rgb(67, 97, 112)',
    primaryBackgroundColor: 'rgb(230, 42, 36)',
    footerBackground: 'rgb(217, 217, 219)',
    footerText: 'rgb(0, 0, 0)',
    primaryLight: 'rgb(249, 201, 197)',
    primaryLightBackground: 'rgb(250, 142, 133)',
  },
  Bankpower: {
    primaryColor: 'rgb(32, 44, 118)',
    secondaryColor: 'rgb(255, 255, 255)',
    backgroundColor: 'rgb(233, 238, 244)',
    primaryButton: 'rgb(32, 44, 118)',
    secondaryButton: 'rgb(32, 44, 118)',
    normalText: 'rgb(33, 37, 32)',
    heading: 'rgb(32, 44, 118)',
    headingSecondary: 'rgb(68, 97, 111)',
    subText: 'rgb(73, 75, 84)',
    secondaryButtonLink: 'rgb(109, 119, 183)',
    normalButtonLink: 'rgb(255, 255, 255)',
    gradient: 'rgb(21, 48, 85)',
    horizontalRule: 'rgb(68, 97, 111)',
    secondaryText: 'rgb(67, 97, 112)',
    ErrorColor: ' rgb(157, 50, 61)',
    SuccessColor: 'rgb(92, 125, 112)',
    secondaryBackgroundColor: 'rgb(243, 243, 244)',
    secondaryTitleText: 'rgb(100, 115, 125)',
    primaryTitleText: 'rgb(0, 0, 0)',
    primaryBackgroundColor: 'rgb(39, 58, 119)',
    footerBackground: 'rgb(217, 217, 219)',
    footerText: 'rgb(40, 42, 50)',
    primaryLight: 'rgb(232, 238, 245)',
    primaryLightBackground: 'rgb(109, 119, 183)',
  },
  Stegmann: {
    primaryColor: 'rgb(220, 60, 0)',
    secondaryColor: 'rgb(255, 255, 255)',
    backgroundColor: 'rgb(242, 224, 217)',
    primaryButton: 'rgb(220, 60, 0)',
    secondaryButton: 'rgb(220, 60, 0)',
    normalText: 'rgb(40, 42, 50)',
    heading: 'rgb(220, 60, 0)',
    headingSecondary: 'rgb(68, 97, 111)',
    subText: 'rgb(40, 42, 50)',
    secondaryButtonLink: 'rgb(248, 142, 135)',
    normalButtonLink: 'rgb(255, 255, 255)',
    gradient: 'rgb(165, 45, 0)',
    horizontalRule: 'rgb(68, 97, 111)',
    secondaryText: 'rgb(40, 42, 50)',
    ErrorColor: 'rgb(157, 50, 61)',
    SuccessColor: 'rgb(92, 125, 112)',
    secondaryBackgroundColor: 'rgb(242, 224, 217)',
    secondaryTitleText: 'rgb(100, 115, 125)',
    primaryTitleText: 'rgb(100, 115, 125)',
    primaryBackgroundColor: 'rgb(218, 61, 23)',
    footerBackground: 'rgb(217, 217, 219)',
    footerText: 'rgb(0, 0, 0)',
    primaryLight: 'rgb(249, 201, 197)',
    primaryLightBackground: 'rgb(248, 142, 135)',
  },
  Manpower: {
    primaryColor: 'rgb(56, 96, 151)',
    secondaryColor: 'rgb(194, 87, 0)',
    backgroundColor: 'rgb(76, 121, 175)',
    primaryButton: 'rgb(194, 87, 0)',
    secondaryButton: 'rgb(194, 87, 0)',
    normalText: 'rgb(255, 255, 255)',
    heading: 'rgb(255, 255, 255)',
    headingSecondary: 'rgb(68, 97, 111)',
    subText: 'rgb(255, 255, 255)',
    secondaryButtonLink: 'rgb(240, 99, 46)',
    normalButtonLink: 'rgb(177, 199, 226)',
    gradient: 'rgb(28, 62, 107)',
    horizontalRule: 'rgb(68, 97, 111)',
    secondaryText: 'rgb(73, 75, 84)',
    ErrorColor: 'rgb(157, 50, 61)',
    SuccessColor: 'rgb(92, 125, 112)',
    secondaryBackgroundColor: 'rgb(243, 243, 244)',
    secondaryTitleText: 'rgb(203, 213, 227)',
    primaryTitleText: 'rgb(56, 96, 151)',
    primaryBackgroundColor: 'rgb(56, 96, 151)',
    footerBackground: 'rgb(217, 217, 219)',
    footerText: 'rgb(0, 0, 0)',
    primaryLight: 'rgb(232, 238, 245)',
    primaryLightBackground: 'rgb(117, 147, 188)',
  },
};
