import React from 'react';
import PropTypes from 'prop-types';
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import ConnectorButtonLink from '../../globals/buttons/ConnectorButtonLink';
import { setAltUrlForImg } from '../../../../utils/helperUtils';

/**
 * @description - Connector Card Component
 * @param {*} param0 - component props
 * @returns {noed} -  html
 */
const CardConnector = ({ title, text, img, altText, ctaText, ctaUrl }) => (
  <div
    className="card connector"
    role="article"
    aria-labelledby="title"
    aria-describedby="info"
  >
    <div className="card-image">
      <Image field={setAltUrlForImg(img, altText)} />
    </div>
    <div className="card-body">
      <RichText tag="h2" className="title" id="title" field={title} />
      <div className="text" id="info">
        <RichText tag="p" className="large" field={text} />
      </div>
      <div className="cta">
        <ConnectorButtonLink ctaText={ctaText} ctaUrl={ctaUrl} cssClass="blue" />
      </div>
    </div>
  </div>
);

CardConnector.defaultProps = {
  title: '',
  text: '',
  img: {},
  altText: '',
  ctaText: 'Read more',
  ctaUrl: '#',
};

CardConnector.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  img: PropTypes.shape({}),
  altText: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
};

export default CardConnector;
