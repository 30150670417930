/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { attachementDownloadApi } from '../../../../../../services/apiServices/candidateService';
import lget from 'lodash.get';
import {
  filterArrayByType,
  capitalize,
  replaceTextwithJSX,
} from '../../../../../../utils/helperUtils';
import {
  defineArrFromObjectArray,
  defineKeyValueObjFromObjectArray,
  replaceTextWithValues,
} from '../../../../Utils/helper';
import parse from 'html-react-parser';
import moment from 'moment';
import TooltipComponent from '../../../../../core/Tooltip';
import { Link } from 'react-router-dom';
import { parsePhoneNumber } from 'libphonenumber-js';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import Modal from '../../../../../core/Modal';
import Button from '../../../../../core/globals/buttons/Button';
import { toast } from 'react-toastify';
import DownloadIcon from '../../../../../core/Forms/FormFields/UploadBlock/DownloadIcon';
import { withTheme } from 'styled-components';

const ProfileItemTemplate = ({
  block,
  data,
  filterRequired,
  candidateId,
  t,
  itemNumber,
  sitecoreContext,
  cssClass,
  theme,
}) => {
  // const [selectedId, setSelectedId] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [apiPoint, setApiPoint] = useState('');
  const [file, setFile] = useState(null);

  const getItemSplitValues = (item, key) =>
    item?.fields?.[key]?.value ? item?.fields?.[key]?.value?.split('/') : [];

  const getItemValues = (item, key, defaultValue = '') =>
    item?.fields?.[key]?.value ? item?.fields?.[key]?.value : defaultValue;

  const getFieldValues = (item) => {
    const obj = {};
    obj['apiEndPoint'] = getItemValues(item, 'apiEndPoint');
    obj['name'] = getItemSplitValues(item, 'name');
    obj['subLabel'] = getItemSplitValues(item, 'subLabel');
    obj['separator'] = getItemValues(item, 'separator', ' ');
    obj['label'] = getItemValues(item, 'label', '');
    obj['type'] = getItemValues(item, 'type', '');
    obj['defaultValue'] = getItemValues(item, 'value', '');
    obj['connectorField'] = getItemSplitValues(item, 'connectorField');
    obj['format'] = getItemSplitValues(item, 'format');
    obj['showMissingText'] = getItemSplitValues(item, 'showMissingText');

    return obj;
  };

  const downloadSuccess = (fileName) => {
    toast.success(`${t('download-success-message')} ${fileName}`, {
      delay: 2000,
    });
  };

  const errorCallBack = (fileName) => {
    toast.error(`${t('download-error-message')} ${fileName}`, {
      delay: 2000,
    });
  };

  const handleDownloadModal = () => {
    setShowModal(!showModal);
  };

  const download = (file, apiEndPoint) => {
    setFile(file);
    setApiPoint(apiEndPoint);
    handleDownloadModal();
  };

  const handleYes = () => {
    const endpoint = apiPoint?.replace('{cid}', `cid=${candidateId}`);
    toast.success(`${t('downloading')} ${file?.name}`, {
      autoClose: 3000,
    });
    attachementDownloadApi(
      file?.name,
      `${endpoint}&fileID=${file?.id}`,
      downloadSuccess,
      errorCallBack
    );
    handleDownloadModal();
  };

  const handleDownloadOnFocus = (e, item, apiEndPoint) => {
    if (e && (e.keyCode === 13 || e.keyCode === 32)) {
      download(item, apiEndPoint);
    }
  };

  const handleNo = () => handleDownloadModal();

  const parseFileValue = (value, apiEndPoint, data, filterRequired) => {
    let newValue = [];
    const dataValue = filterRequired
      ? filterArrayByType(data?.attachment, value)
      : lget(data, value);
    if (dataValue?.length > 0) {
      newValue = (
        <>
          {dataValue?.map((file) => (
            <div
              onClick={() => download(file, apiEndPoint)}
              onKeyUp={(event) => handleDownloadOnFocus(event, file, apiEndPoint)}
              className="file-list"
              role="link"
              tabIndex={0}
            >
              {file?.name}{' '}
              <div className="download-icon-profile">
                <DownloadIcon iconColor={theme?.primaryButton} />
              </div>
              {/* {selectedId?.includes(file?.id) && file?.id && (
                <React.Fragment>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                  ></span>
                </React.Fragment>
              )} */}
            </div>
          ))}
          <Modal showModal={showModal} handleModal={handleDownloadModal} size="md">
            <div className="inner-modal-container" key="body">
              {t('download-confirmation')}
              <div className="confirm-button-container" role="button" tabIndex={0}>
                <Button
                  cssClass="yes-button"
                  handleButtonClick={handleYes}
                  text={t('yes')}
                />
                <Button handleButtonClick={handleNo} text={t('no')} />
              </div>{' '}
            </div>
          </Modal>
        </>
      );
    }
    return newValue || [];
  };

  const getValueByFileTemplate = (item, data, filterRequired) => {
    const fieldObj = getFieldValues(item);
    let result = [];
    fieldObj?.name?.forEach(function (field, i) {
      result = parseFileValue(field, fieldObj.apiEndPoint, data, filterRequired);
    });
    return result;
  };

  const getValueByBlockTemplate = (item) => {
    const fieldObj = getFieldValues(item);
    return (
      <div className="skill-list-container">
        {data?.[fieldObj?.name] && data?.[fieldObj?.name].length > 0
          ? data?.[fieldObj?.name]?.map((item) => (
              <div className="skill-item">{item}</div>
            ))
          : null}
      </div>
    );
  };

  const checkConnectorFieldValidation = (connectorField, fieldName) => {
    const connectorValue = lget(data, connectorField);
    const fieldValue = lget(data, fieldName) || '';
    return connectorValue ? fieldValue.includes(connectorValue) : false;
  };
  const getDate = (dt) => {
    if (dt && dt !== null) {
      if (typeof dt === 'string' && dt.split('/').length > 1) {
        const dateVar = dt.split('/');
        const dateVal = new Date(dateVar[1] + '/' + dateVar[0] + '/' + dateVar[2]);
        return dateVal;
      } else if (moment(dt).isValid()) return dt;
      else {
        const theDate = new Date(parseInt(dt));
        const dateString = theDate.toGMTString();
        return dateString;
      }
    }
    return null;
  };

  const missingText = (label, subLabel = '', fieldLength) => {
    let result = '';
    if (label) {
      if (fieldLength > 1 && subLabel) {
        result += `${t('missing')} (${subLabel})`;
      } else {
        result += t('missing');
      }
    } else {
      result += subLabel ? `${t('missing')} (${subLabel})` : t('missing');
    }
    return `<span class="not-added-cls" >${result}</span>`;
  };

  const parseValue = (
    value,
    sep,
    fieldLabel,
    defaultValue,
    index,
    fieldType,
    options,
    fieldCount,
    subLabel,
    showMissingText
  ) => {
    let newValue = defaultValue ? defaultValue : lget(data, value);
    let result = '';
    switch (fieldType) {
      case 'customText':
        const convertedVal = newValue?.toString();
        result = replaceTextWithValues(options?.[convertedVal] || '', data);
        break;
      default:
        result += newValue
          ? `${newValue}${sep} `
          : showMissingText !== 'false'
          ? `${missingText(fieldLabel, subLabel, fieldCount)}${sep} `
          : '';
        break;
    }
    return result;
  };

  const getValueByFieldName = (item, index) => {
    const fieldObj = getFieldValues(item);
    let options =
      item?.fields?.options && item?.fields?.options?.length > 0
        ? item?.fields?.options
        : [];
    options = defineKeyValueObjFromObjectArray(options);
    let result = '';
    fieldObj?.name?.forEach(function (value, i) {
      let sep = i !== fieldObj?.name?.length - 1 ? fieldObj?.separator : '';
      result += parseValue(
        value,
        sep,
        fieldObj?.label,
        fieldObj?.defaultValue,
        index,
        fieldObj?.type,
        options,
        fieldObj?.name?.length,
        fieldObj?.subLabel?.[i],
        fieldObj?.showMissingText?.[i]
      );
    });
    const headerClss = index === 0 ? 'header_item' : '';
    return result
      ? parse(
          `<div class="row"><div class="col textItem ${cssClass} ${headerClss}">${
            fieldObj?.label ? `${fieldObj?.label} ` : ''
          }${result}</div></div>`
        )
      : '';
  };

  const getValueByCustomValidator = (item, data) => {
    const fieldObj = getFieldValues(item);
    let hintText =
      item?.fields?.hintText?.value || item?.fields?.connectorMessage?.value;
    return checkConnectorFieldValidation(
      fieldObj?.connectorField?.[0],
      fieldObj?.name?.[0]
    ) ? (
      ''
    ) : lget(data, fieldObj?.name?.[0]) ? (
      <div className="readOnlyFieldWrapper">
        <div>{fieldObj?.label}</div>
        <div className="readOnlyField">
          {fieldObj?.defaultValue
            ? fieldObj?.defaultValue
            : lget(data, fieldObj?.name?.[0])}
          <TooltipComponent text={hintText} placement="left">
            <div className="error-info-icon">i</div>
          </TooltipComponent>
        </div>
      </div>
    ) : (
      ''
    );
  };

  const getDiff = (startDate, endDate) => {
    const startYear = moment(startDate).format('YYYY');
    const endYear = moment(endDate).format('YYYY');
    const startMonth = moment(startDate).format('MM');
    const endMonth = moment(endDate).format('MM');
    let start = moment(`${startYear}-${startMonth}-1`);
    let end = moment(`${endYear}-${endMonth}-1`);
    let mn = end.diff(start, 'months');
    end.add(months, 'months');

    let years = Math.floor(mn / 12);
    let months = mn % 12;
    let yr = years ? `${years} ${t('yrs')}` : '';
    let sep = years && months ? ' • ' : '';
    let ms = months ? `${sep} ${months} ${t('mos')}` : '';
    let calDiff = yr == '' && ms == '' ? `` : `(${yr}${ms})`;
    return calDiff;
  };

  const checkValidDate = (date) => (date ? moment(date).isValid() : false);

  const getValueByYear = (item) => {
    const fieldObj = getFieldValues(item);
    let stDate = getDate(data[fieldObj?.name?.[0]]);
    let enDate = getDate(data[fieldObj?.name?.[1]]);
    const startDate =
      fieldObj?.connectorField?.[0] && data?.[fieldObj?.connectorField?.[0]]
        ? t('present')
        : stDate && checkValidDate(stDate)
        ? moment(stDate).format(
            fieldObj?.format?.[0] ? fieldObj?.format?.[0] : 'MMM YYYY'
          )
        : fieldObj?.showMissingText?.[0] !== 'false'
        ? missingText(
            fieldObj?.label,
            fieldObj?.subLabel?.[0],
            fieldObj?.subLabel?.length
          )
        : '';
    const endDate =
      fieldObj?.connectorField?.[1] && data?.[fieldObj?.connectorField?.[1]]
        ? t('present')
        : enDate && checkValidDate(enDate)
        ? moment(enDate).format(
            fieldObj?.format?.[1] ? fieldObj?.format?.[1] : 'MMM YYYY'
          )
        : fieldObj?.showMissingText?.[1] !== 'false'
        ? missingText(
            fieldObj?.label,
            fieldObj?.subLabel?.[1],
            fieldObj?.subLabel?.length
          )
        : '';
    const currentDate = new Date();
    const diff =
      fieldObj?.connectorField?.[1] &&
      data?.[fieldObj?.connectorField?.[1]] &&
      checkValidDate(stDate)
        ? getDiff(stDate, currentDate)
        : fieldObj?.connectorField?.[0] &&
          data?.[fieldObj?.connectorField?.[0]] &&
          checkValidDate(enDate)
        ? getDiff(enDate, currentDate)
        : checkValidDate(stDate) && checkValidDate(enDate)
        ? getDiff(stDate, enDate)
        : '';
    const sep = startDate && endDate ? fieldObj?.separator : '';
    return `${startDate} ${sep} ${endDate} ${diff}`;
  };

  const getValueByDateConvert = (item, data) => {
    const fieldObj = getFieldValues(item);
    let newValue = fieldObj?.defaultValue
      ? fieldObj?.defaultValue
      : lget(data, fieldObj?.name?.[0]);
    return newValue
      ? moment(newValue).format('MMM YYYY')
      : fieldObj?.showMissingText?.[0] !== 'false'
      ? parse(
          missingText(
            fieldObj?.label,
            fieldObj?.subLabel[0],
            fieldObj?.subLabel?.length
          )
        )
      : '';
  };

  const getValueByPhoneConvert = (item, data) => {
    const fieldObj = getFieldValues(item);
    let phone = lget(data, fieldObj?.name?.[0]);
    const phoneNumber =
      phone && sitecoreContext?.Country?.code
        ? isNaN(phone)
          ? phone
          : parsePhoneNumber(
              phone,
              sitecoreContext?.Country?.code?.toUpperCase()
            )?.formatInternational()
        : '';
    let res = phoneNumber
      ? phoneNumber
      : missingText(
          fieldObj?.label,
          fieldObj?.subLabel[0],
          fieldObj?.subLabel?.length
        );
    let result = res
      ? parse(
          `<div class="row"><div class="col textItem">${
            fieldObj?.label ? `${fieldObj?.label} ` : ''
          }${res}</div></div>`
        )
      : '';
    return result;
  };

  const getTemplate = (item, data, filterRequired, index) => {
    const type = getItemValues(item, 'type');
    let result = '';
    switch (type) {
      case 'file':
        result = getValueByFileTemplate(item, data, filterRequired);
        break;
      case 'block':
        result = getValueByBlockTemplate(item);
        break;
      case 'yearDiff':
        result = item?.fields?.name?.value ? parse(getValueByYear(item)) : '';
        break;
      case 'customValidation':
        result = getValueByCustomValidator(item, data);
        break;
      case 'dateConvert':
        result = getValueByDateConvert(item, data);
        break;
      case 'phone':
        result = getValueByPhoneConvert(item, data);
        break;
      default:
        result = getValueByFieldName(item, index);
        break;
    }
    return result;
  };

  return (
    <div className="container">
      {block?.fields?.title?.value && (
        <div className="row">
          <div className="col">
            <label>{block?.fields?.title?.value}</label>
            {block?.fields?.hintText?.value &&
              (block.fields?.ctaText?.value ? (
                <span className="hinteText">
                  {' '}
                  {replaceTextwithJSX(
                    block?.fields?.hintText?.value,
                    '{0}',
                    <Link
                      key={`personalInfo${itemNumber}`}
                      to={block?.fields?.ctaUrl?.value?.href}
                    >
                      {block?.fields?.ctaText?.value}
                    </Link>
                  )}
                </span>
              ) : (
                <span className="hinteText">{block?.fields?.hintText?.value}</span>
              ))}
          </div>
        </div>
      )}
      {block &&
        block?.fields?.items?.map((item, i) =>
          item?.fields?.name?.value ? getTemplate(item, data, filterRequired, i) : ''
        )}
    </div>
  );
};

ProfileItemTemplate.defaultProps = {
  cssClass: '',
};

ProfileItemTemplate.propTypes = {
  t: PropTypes.func.isRequired,
  cssClass: PropTypes.string,
};

export default withTheme(withSitecoreContext()(ProfileItemTemplate));
