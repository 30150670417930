import { PROFILE_EMPTY_FIELDS } from '../actions/actionTypes';

const initialState = {};

const profileEmptyFieldsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_EMPTY_FIELDS.SAVE:
      return {
        ...state,
        ...action.payload,
      };
    case PROFILE_EMPTY_FIELDS.CLEAR:
      return {
        ...state,
      };
    default:
      return state;
  }
};
export default profileEmptyFieldsReducer;
