import { createGlobalStyle } from 'styled-components';

export const JobApplyTheme = createGlobalStyle`
.create-profile-success-block { 
    .titleText { 
      color:  ${({ theme }) => theme?.primaryColor};
    }
}
.job-title {
    .job-name {
        a {
            color:  ${({ theme }) => theme?.primaryButton};
        }
    }
}
.dynamicForm {
    .form-radio-group {
        .radio-toggle {
            input[type='radio'] {
                &:checked + label::after {
                    background-color:  ${({ theme }) => theme?.primaryButton};
                }
            }
        }
    }
}
.form-radio-group {
    .radio-toggle {
        input[type='radio']:checked:after {
            background-color:  ${({ theme }) => theme?.primaryButton};
        }
    }
}
.file-container {
    .file-name {
        color: ${({ theme }) => theme?.primaryButton} !important;
    }
}
.checkBoxLabel {
    span {
        a {
            color: ${({ theme }) => theme?.secondaryButton} !important;
        }   
    }
}
.job-alert {
    .alert-title {
        color: ${({ theme }) => theme?.primaryColor};
    }
    .alert-description {
        a {
            color: ${({ theme }) => theme?.secondaryButton} !important;
        }
    }
}
`;
