import { createGlobalStyle } from 'styled-components';

export const ApsisFormStyle = createGlobalStyle`
.apsis-form {
    &.newsletter {
        form {
            &.al-form {
                input[type='button'] {
                    color:${({ theme }) => theme?.secondaryButton}
                }
            }
        }
    }
}
`;
