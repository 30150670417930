/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable require-jsdoc */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  withSitecoreContext,
  mediaApi,
  RichText,
} from '@sitecore-jss/sitecore-jss-react';
import ModalComponent from '../../Modal';
import JobAlert from '../SearchJob/JobAlert/JobAlert';
import JobAlertByEmail from '../SearchJob/JobAlert/JobAlertByEmail';
// import TextButton from '../../globals/buttons/TextButton';
import AuthWrapper from '../../../core/LoginMSAL/AuthWrapper';
import { authstates } from '../../../../utils/enums';
import ModalFilter from './ModalFilter';
import FilterPill from './FilterPill';
import { DEVICE } from '../../../../constants';
import Button from '../../globals/buttons/Button';

/**
 * @description to show search result count and related information
 * @param {number} searchResultCount - no of results
 * @param {string} searchQuery - query used to generate the result
 * @param {string} searchLocation -location used for search
 * @param {array} jobAlertOptions - job alert frequency options
 * @param {string} ctaText - job alert button label
 * @returns {node} html
 */

const SearchRibbon = ({
  searchResultCount,
  searchQuery,
  searchLocation,
  jobAlertOptions,
  ctaText,
  mode,
  translate,
  jobAlertSuccessMessage,
  showJobAlert,
  dashboardUrl,
  sitecoreContext,
  consentText,
  totalPositions,
  totalPositionsLabel,
  icons,
  JobFilters,
  jobctaText,
  jobctaUrl,
  searchLabel,
  paginationCount,
  selectedFilters,
  hideFilter,
  showJobAlertOnError,
  zeroSearchResultsText,
  SearchRibbonError = false,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const loginReducer = useSelector((state) => state.loginReducer);
  const sortJobsReducer = useSelector((state) => state.sortJobsReducer.data);
  const [isMobView, setIsMobView] = useState(false);
  useEffect(() => {
    const isMobWidth = window.innerWidth <= DEVICE.TABLET.WIDTH;
    // initial rendering.
    if (isMobView !== isMobWidth) {
      setIsMobView(!isMobView);
    }
  }, []);

  const getReplacedLabel = (label) => {
    const mapObj = {
      count: paginationCount,
      totalcount: searchResultCount,
      keyword: searchQuery,
      location: decodeURIComponent(searchLocation),
      sortby: sortJobsReducer || '',
    };

    const regexKeys = new RegExp(Object.keys(mapObj).join('|'), 'gi');
    let newSearchLabel = label?.replace(regexKeys, function (matched) {
      return mapObj[matched];
    });
    newSearchLabel = newSearchLabel?.replace(/[()]/g, '');
    return newSearchLabel;
  };

  const brand_name = sitecoreContext?.Country?.brandName?.toLowerCase() || '';
  //const country_name = sitecoreContext?.Country?.name;
  let { iconAlert, iconFilter, iconClose } = {};
  /**
   * @description to show job alert dialog
   * @returns {undefined} - nothing
   */
  const handleModal = () => {
    setShowModal(!showModal);
  };

  const handleModalFilter = () => {
    setShowModalFilter(!showModalFilter);
  };

  /**
   * @description to set  icons
   * @returns {undefined} -  no returns
   */
  const setIcons = () => {
    if (Array.isArray(icons)) {
      icons.forEach((iconItem) => {
        switch (iconItem?.itemName) {
          case 'Alert':
            iconAlert = iconItem.icon;
            break;
          case 'Filter':
            iconFilter = iconItem.icon;
            break;
          case 'Close':
            iconClose = iconItem.icon;
          default:
            break;
        }
      });
    }
  };
  setIcons();

  useEffect(() => {
    if (
      loginReducer.initialized &&
      loginReducer.state === authstates.AUTHENTICATED
    ) {
      const createJobAlertButtonClickedToLogin = sessionStorage.getItem(
        'createJobAlertButtonClickedToLogin'
      );
      if (createJobAlertButtonClickedToLogin === 'true') {
        handleModal();
        sessionStorage.removeItem('createJobAlertButtonClickedToLogin');
      }
    }
  }, []);

  /**
   * @description - login user and create alert
   * @param {*} login - to login
   * @returns {undefined} -
   */
  const loginUserAndCreateJobAlert = (login) => {
    if (login !== null && typeof window !== 'undefined') {
      sessionStorage.setItem('createJobAlertButtonClickedToLogin', true);
      localStorage.setItem(
        'authenticationType',
        'Apply > Create Account / Apply > Sign in'
      );
      login(null, 'createAlert');
    }
  };

  return (
    <section className="search-ribbon-global">
      <div
        className={`search-ribbon-wrap ${mode === 'show-results' ? 'active' : ''}`}
      >
        <div className="">
          <div className="">
            <div className="">
              <div className="search-ribbon-inner">
                <div className="results">
                  <h2 className="text">
                    <RichText
                      tag="div"
                      className="searchLabel"
                      field={{
                        value: getReplacedLabel(searchLabel?.labelForDesktop),
                      }}
                    />

                    {totalPositions ? (
                      <>
                        {', '}
                        <div className="searchLabel">
                          {totalPositionsLabel} <span>{totalPositions}</span>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </h2>
                  {isMobView ? (
                    <section>
                      <div className="job-search-pills">
                        <FilterPill data={selectedFilters} iconClose={iconClose} />
                      </div>
                    </section>
                  ) : null}
                </div>
                <div
                  className={`${hideFilter ? 'filter-hide' : 'filters-list-global'}`}
                >
                  <button
                    className="filter-block-result-mobile"
                    onClick={handleModalFilter}
                  >
                    <div className="icon-filters">
                      <img
                        src={mediaApi.updateImageUrl(iconFilter)}
                        className="filter_icon"
                        alt=""
                      />
                    </div>
                    <div className="text">{translate('filter-result')}</div>
                  </button>
                  <ModalFilter
                    visibility={showModalFilter}
                    handleModal={handleModalFilter}
                    JobFilters={JobFilters}
                    jobctaText={jobctaText}
                    jobctaUrl={jobctaUrl}
                    searchLabel={getReplacedLabel(searchLabel?.labelForMobile)}
                    totalPositions={totalPositions}
                    totalPositionsLabel={totalPositionsLabel}
                    translate={translate}
                    iconFilter={iconFilter}
                    selectedFilters={selectedFilters}
                    iconClose={iconClose}
                  />
                  {showJobAlert && sitecoreContext?.LoginType === 'ADB2C' && (
                    <AuthWrapper>
                      {({ login, authenticationState }) => {
                        return (
                          <>
                            {/* <div className="create-alert-btn">
                              <TextButton
                                text={ctaText}
                                handleTextClick={
                                  authenticationState === authstates.AUTHENTICATED
                                    ? handleModal
                                    : () => loginUserAndCreateJobAlert(login)
                                }
                              /> */}
                            <button
                              className={`create-alert-btn ${brand_name}`}
                              onClick={() => {
                                window?.dataLayer.push({
                                  event: 'jobAlertClick',
                                });
                                authenticationState === authstates.AUTHENTICATED
                                  ? handleModal()
                                  : loginUserAndCreateJobAlert(login);
                              }}
                            >
                              {ctaText}
                              <div className="icon-alert">
                                <img
                                  src={mediaApi.updateImageUrl(iconAlert)}
                                  className="filter_icon"
                                  alt=""
                                />
                              </div>
                            </button>
                            {/* </div> */}
                          </>
                        );
                      }}
                    </AuthWrapper>
                  )}
                  {showJobAlert && sitecoreContext?.LoginType === 'ROMA' && (
                    // <TextButton
                    //   text={ctaText}
                    //   handleTextClick={() => {
                    //     window?.dataLayer.push({
                    //       event: 'jobAlertClick',
                    //     });
                    //     handleModal();
                    //   }}
                    // />
                    <button
                      className="create-alert-btn"
                      onClick={() => {
                        window?.dataLayer.push({
                          event: 'jobAlertClick',
                        });
                        handleModal();
                      }}
                    >
                      {ctaText}
                      <div className="icon-alert">
                        <img
                          src={mediaApi.updateImageUrl(iconAlert)}
                          className="filter_icon"
                          alt=""
                        />
                      </div>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`search-ribbon-wrap ${
          mode === 'no-results' ? 'active alert' : ''
        }`}
      >
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="search-ribbon-inner">
                <div className="results">
                  <h2 className="text">{translate('empty-job-results')}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {SearchRibbonError ? (
        <div className="no-job-results">
          <div className="no-job-search-result-card ">
            <RichText
              tag="p"
              className="no-job-desc"
              field={{
                value: zeroSearchResultsText,
              }}
            />
            {showJobAlertOnError && sitecoreContext?.LoginType === 'ADB2C' && (
              <AuthWrapper>
                {({ login, authenticationState }) => {
                  return (
                    <>
                      <Button
                        cssClass={`create-alert-btn ${brand_name}`}
                        text={ctaText}
                        handleButtonClick={
                          authenticationState === authstates.AUTHENTICATED
                            ? handleModal
                            : () => loginUserAndCreateJobAlert(login)
                        }
                      />
                    </>
                  );
                }}
              </AuthWrapper>
            )}
            {showJobAlertOnError && sitecoreContext?.LoginType === 'ROMA' && (
              <Button
                cssClass="create-alert-btn"
                text={ctaText}
                handleButtonClick={() => {
                  window?.dataLayer.push({
                    event: 'jobAlertClick',
                  });
                  handleModal();
                }}
              />
            )}
          </div>
        </div>
      ) : (
        ''
      )}

      <ModalComponent
        showModal={showModal}
        handleModal={handleModal}
        className="create-alert_modal"
      >
        {sitecoreContext?.LoginType === 'ADB2C' && (
          <JobAlert
            key="body"
            jobAlertOptions={jobAlertOptions}
            jobAlertSuccessMessage={jobAlertSuccessMessage}
            dashboardUrl={dashboardUrl}
            handleModal={handleModal}
            showModal={showModal}
          />
        )}
        {sitecoreContext?.LoginType === 'ROMA' && (
          <JobAlertByEmail
            key="body"
            jobAlertOptions={jobAlertOptions}
            jobAlertSuccessMessage={jobAlertSuccessMessage}
            dashboardUrl={dashboardUrl}
            handleModal={handleModal}
            consentText={consentText}
            showModal={showModal}
          />
        )}
      </ModalComponent>
    </section>
  );
};

SearchRibbon.defaultProps = {
  searchResultCount: '',
  searchQuery: '',
  searchLocation: '',
  jobAlertOptions: [],
  ctaText: '',
  ctaUrl: '',
  mode: 'show-results',
  translate: () => {},
  totalPositions: '',
  totalPositionsLabel: '',
};

SearchRibbon.propTypes = {
  searchResultCount: PropTypes.number.isRequired,
  searchQuery: PropTypes.string,
  searchLocation: PropTypes.string.isRequired,
  jobAlertOptions: PropTypes.arrayOf(),
  ctaText: PropTypes.string,
  mode: PropTypes.oneOf(['show-results', 'no-results']),
  translate: PropTypes.func,
  consentText: PropTypes.shape({}).isRequired,
  totalPositions: PropTypes.number,
  totalPositionsLabel: PropTypes.string,
};

export default withSitecoreContext()(SearchRibbon);
