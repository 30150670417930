/* eslint-disable */
import React, { useState, useEffect, useRef, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  RichText,
  Text,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';

import Textbox from '../Forms/FormFields/Textbox';
import LocationSearch from '../globals/forms/LocationSearch';
import Button from '../globals/buttons/Button';
import TextButton from '../globals/buttons/TextButton';
import {
  isObjNotEmpty,
  getSearchURLParamsUpdated,
} from '../../../utils/helperUtils';
import NavLink from '../globals/NavLinks';
// import { SEARCH } from '../../../constants';
import './headerJobs.scss';
import { saveSelectedLocation } from '../../../redux/actions';
import SearchIcon from './SearchIcon';

/**
 *
 * @param {*} props
 */
const FormElements = (props) => {
  const { register, control, locationSelected, countryCode } = props;
  const { errors, searchLocation, onInputChange, t, setSearchOpen } = props;
  const { setTextFocused, setValue, getValues } = props;
  const { regexValidationPattern } = props;

  /**
   * @description - Hack to fix menu close on google autocomplete selection
   * @returns {undefined}
   */
  const handleBlur = () => {
    setTimeout(() => {
      if (
        document &&
        document.getElementById('sr-location-hdr') !== document.activeElement
      ) {
        setSearchOpen(false);
      }
    }, 500);
  };

  /**
   *
   * @param {*} searchLoc
   */
  const validateLocation = (searchLoc) => {
    return !(
      searchLoc &&
      (!isObjNotEmpty(searchLocation) ||
        (isObjNotEmpty(searchLocation) && searchLocation.place !== searchLoc))
    );
  };

  return (
    <Fragment>
      <Textbox
        id="jobsearchtext"
        label={t('job-title-industry-skill')}
        name="headerSearchKey"
        customProps={{
          autoComplete: 'off',
          ref: register,
          name: 'headerSearchKey',
        }}
        trimLabelCharacters={40}
        focusHandler={() => setTextFocused(true)}
        onBlurValidation={() => setTextFocused(false)}
        register={register}
        getValues={getValues}
        setValue={setValue}
        customError={errors}
        // clearErrors={clearErrors}
        dataValidations={[
          {
            fields: {
              message: {
                value: t('search-validation-message'),
              },
              inputType: {
                value: 'regexPatternvalidator',
              },
              value: {
                value: regexValidationPattern,
              },
            },
          },
        ]}
        // ariaLabel={t('job-title-industry-skill')}
      />
      <Controller
        as={LocationSearch}
        control={control}
        onLocationSelect={locationSelected}
        region={countryCode}
        errorMessage={t('invalid-location')}
        // placeholder={t('search-location-header')}
        label={t('search-location-header')}
        id="sr-location-hdr"
        name="headerSearchLoc"
        disableEnterSubmit={true}
        rules={{ validate: validateLocation }}
        showError={errors?.headerSearchLoc}
        selectedLocation={searchLocation}
        changeHandler={onInputChange}
        focusHandler={() => setSearchOpen(true)}
        focusOutHandler={handleBlur}
        // refVal={register}
        defaultValue={searchLocation?.place}
        showCurrentLocation={true}
      />
    </Fragment>
  );
};

/**
 *
 */
// const getLocFromStorage = () => {
//   let locValue = {};
//   if (sessionStorage && sessionStorage.getItem(SEARCH.JOB_SEARCH.SEARCH_LOCATION)) {
//     locValue = JSON.parse(sessionStorage.getItem(SEARCH.JOB_SEARCH.SEARCH_LOCATION));
//   }
//   return locValue;
// };

/**
 *
 * @param {*} props
 */
const HeaderSearchJobs = (props) => {
  const { fields, t, sitecoreContext, history, location } = props;
  const { dispatch, locationState } = props;
  const { header, subTitle, navigationText, linkText } = fields;
  const { partnerText, partnerLink } = fields;
  const [focused, setFocused] = useState(false);
  const [textFocused, setTextFocused] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  // const params = new URLSearchParams(location?.search);
  const [searchLocation, setSrLocation] = useState({});
  const url = sitecoreContext?.Country?.jobSearchURL || '';
  const regexValidationPattern = sitecoreContext?.Country?.regExPattern || '';
  const urlVal = url.split('?')[0];
  const menu = useRef(null);
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';
  const {
    register,
    setValue,
    getValues,
    clearErrors,
    handleSubmit,
    errors,
    control,
    // reset,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: {
      headerSearchLoc: searchLocation?.place,
      headerSearchKey: '',
    },
  });

  useMemo(() => {
    if (isObjNotEmpty(locationState)) {
      setValue('headerSearchLoc', locationState.place);
      setSrLocation(locationState);
    }
  }, [locationState]);

  useEffect(() => {
    // setSrLocation(getLocFromStorage());
    clearErrors('headerSearchLoc');
    setValue('headerSearchKey', '');
  }, [location]);

  /**
   *
   */
  const onSubmit = (values) => {
    if (menu?.current) menu.current.style.display = 'none';
    const paramsVal = new URLSearchParams();
    const finalParams = getSearchURLParamsUpdated(
      paramsVal,
      values?.headerSearchKey,
      values?.headerSearchLoc ? searchLocation : null
    );
    if (isObjNotEmpty(searchLocation)) {
      dispatch(saveSelectedLocation(searchLocation));
    }
    if (location?.pathname === urlVal) {
      history.replace({
        pathname: urlVal,
        search: String(decodeURIComponent(finalParams)),
      });
    } else {
      history.push({
        pathname: urlVal,
        search: String(decodeURIComponent(finalParams)),
      });
    }
    setValue('headerSearchKey', '');
  };

  /**
   * @description function called followed by place selection
   * @param {object} locVal  - geometry of location
   * @returns {undefined} - no return
   */
  const locationSelected = (locVal) => {
    if (locVal) {
      setSrLocation(locVal);
      setValue('headerSearchLoc', locVal.place);
      clearErrors('headerSearchLoc');
      // if (document) document.getElementById('sr-location-hdr').focus();
    } else {
      setSrLocation({});
    }
  };

  /**
   *
   */
  const onInputChange = (value) => {
    setValue('headerSearchLoc', value);
    if (value === '') {
      setSrLocation({});
      clearErrors('headerSearchLoc');
    }
  };

  return (
    <>
      <div
        id="job-search-oevrlay-menu"
        className={`main-nav-item search ${
          focused || searchOpen || textFocused ? 'link-focused' : ''
        }`}
        onMouseOver={() => {
          if (menu?.current) menu.current.style.display = 'block';
        }}
        onFocus={() => {
          if (document && !document.getElementsByClassName('hide_outline').length)
            setFocused(true);
        }}
        onBlur={() => setFocused(false)}
      >
        <Link
          to={url}
          role="menuitem"
          aria-haspopup={true}
          aria-labelledby="job-header-menu"
        >
          <span>
            <SearchIcon />
            {/* <div className="icon-search" />  */}
            <Text field={navigationText} />
          </span>
        </Link>
        <div
          className={`main-nav-dropdown ${brandName}`}
          id="job-header-menu"
          ref={menu}
        >
          <div className="main-nav-dropdown-inside">
            <div className="triangle"></div>
            <div className={`find-career-block ${brandName}`}>
              <div>
                <RichText tag="div" className="title" field={header} />
                <RichText tag="p" field={subTitle} />
                <FormElements
                  register={register}
                  control={control}
                  locationSelected={locationSelected}
                  // countryCode={sitecoreContext?.Country?.code}
                  countryCode={
                    t('country-code') != 'country-code'
                      ? `${sitecoreContext?.Country?.code},${t('country-code')}`
                      : `${sitecoreContext?.Country?.code}`
                  }
                  errors={errors}
                  getValues={getValues}
                  setValue={setValue}
                  searchLocation={searchLocation}
                  onInputChange={onInputChange}
                  t={t}
                  setSearchOpen={setSearchOpen}
                  setTextFocused={setTextFocused}
                  regexValidationPattern={regexValidationPattern}
                  clearErrors={clearErrors}
                />
                <div className="row btns-container">
                  <div className="col-auto">
                    <Button
                      cssClass="orange-sd"
                      text={t('search')}
                      handleButtonClick={handleSubmit(onSubmit)}
                    />
                  </div>
                  <div className="col">
                    <TextButton
                      text={linkText?.value}
                      cssClass="seo-blue"
                      handleTextClick={() => {
                        if (menu?.current) menu.current.style.display = 'none';
                        clearErrors('headerSearchKey');
                        clearErrors('headerSearchLoc');
                        setSrLocation({});
                        history.push(urlVal);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-section">
              <NavLink url={partnerLink} cssClass="footer-link">
                <Text field={partnerText} />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

HeaderSearchJobs.propTypes = {
  fields: PropTypes.shape({
    header: PropTypes.shape({}),
    subTitle: PropTypes.shape({}),
    navigationText: PropTypes.shape({}),
    linkText: PropTypes.shape({}),
  }),
  t: PropTypes.func.isRequired,
};

HeaderSearchJobs.defaultProps = {
  fields: {},
  t: (val) => val,
};

const mapStateToProps = (state) => {
  return {
    locationState: state.jobLocationReducer.location,
  };
};

export default connect(mapStateToProps)(
  withSitecoreContext()(withRouter(withTranslation()(HeaderSearchJobs)))
);
