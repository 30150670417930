import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../globals/buttons/Button';
import { withTranslation } from 'react-i18next';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import AccordionBlock from './AccordionBlock';
// import mockData from './mockData';
import './accordion.scss';

/**
 * @description - Accordion component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const Accordion = ({ fields, t }) => {
  // fields = Object.entries(fields).length ? fields : mockData;
  const { title, text, mode, allowMultipleOpen, items = [], count } = fields;
  const limit = count && count.value ? parseInt(count.value) : 5;
  const isMultipleItemOpen =
    allowMultipleOpen && allowMultipleOpen.value ? allowMultipleOpen.value : false;
  const [n, setN] = useState(limit);

  function loadMore(n) {
    return parseInt(n) + limit;
  }
  const delaySetter = (scroll = () => {}, id) => {
    setTimeout(() => {
      id && scroll();
    }, 10);
  };
  const handleAccordionClick = (id) => {
    delaySetter(
      () => document?.getElementById(id)?.scrollIntoView({ behavior: 'smooth' }),
      id
    );
  };

  return (
    <section
      className={`accordion-section core ${mode?.value ? 'gradient' : ''}`}
      aria-labelledby="title"
      // aria-describedby="info"
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-12">
                <RichText
                  field={title}
                  className="title"
                  tag="div"
                  id="title"
                  role="heading"
                />
                <RichText field={text} tag="p" className="text" id="info" />
              </div>
            </div>
            <div className="row">
              <div className="col">
                {items && items.length > 0 && (
                  <AccordionBlock
                    allowMultipleOpen={isMultipleItemOpen}
                    data={items.slice(0, n)}
                    handleAccordionClick={handleAccordionClick}
                  />
                )}
              </div>
            </div>
            {items && n < items.length && (
              <div className="row">
                <div className="col">
                  <Button
                    cssClass="orange-sd"
                    text={t('read-more')}
                    handleButtonClick={() => setN(loadMore)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

Accordion.defaultProps = {
  fields: {
    title: { value: '' },
    text: { value: '' },
    mode: { value: false },
    allowMultipleOpen: { value: false },
    count: { value: '' },
    items: [
      {
        name: '',
        displayName: '',
        fields: {
          title: { value: '' },
          content: { value: '' },
        },
      },
    ],
  },
};

Accordion.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    text: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    mode: PropTypes.shape({
      value: PropTypes.bool.isRequired,
    }),
    allowMultipleOpen: PropTypes.shape({
      value: PropTypes.bool.isRequired,
    }),
    count: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        displayName: PropTypes.string,
        fields: PropTypes.shape({
          title: PropTypes.shape({
            value: PropTypes.string.isRequired,
          }),
          content: PropTypes.shape({
            value: PropTypes.string.isRequired,
          }),
        }),
      })
    ),
  }),
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Accordion);
