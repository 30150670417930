/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import lget from 'lodash.get';
import { dropDownFormat } from './helper/dataFactory';
import DateInputField from '../FormFields/DateInputField/dateInputComplex';
import Textbox from '../FormFields/Textbox';
import TextareaComplex from '../FormFields/Textarea';
import UploadBlock from '../FormFields/UploadBlock';
import MultiCheckbox from '../FormFields/MultiCheckbox';
import SelectComplex from '../FormFields/SelectComplex';
import { SORTORDER } from '../../../../utils/enums';
import RadioToggle from '../FormFields/RadioToggle';
import Checkbox from '../FormFields/Checkbox';
import DatePickerComponent from '../FormFields/DatePickerComponent';
import './dynamicForm.scss';
import '../forms.scss';
import SkillsPicker from '../FormFields/SkillsPicker';
import MonthYearDropdown from '../FormFields/MonthYearDropdown';

/**
 * @description - Dynamic form component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML template.
 */
const DynamicForm = ({
  formData,
  register,
  errors,
  setError,
  clearErrors,
  customIndex,
  getValues,
  setValue,
  trigger,
  values = {},
  watch,
  handleSelect = () => {},
  handleUpload = () => {},
  handleDelete = () => {},
  control,
  formName,
  validateOnLoad,
  trimLabelCharacters,
  APIQuerryParams,
  readOnlyFields,
  fieldsToHide,
  apiCollection,
  apiList,
}) => {
  const [formValues, setFormValues] = useState({});
  const [apiData, setApiData] = useState({});
  /**
   * @description - Function to calculate width of field.
   * @param {string} width - Custom width.
   * @returns {string} - Field width.
   */
  const getWidth = (width) => (width && width !== '' ? width + '%' : '100%');
  // const { data, loading, error, load } = useFetchData();

  useEffect(() => {
    if (values?.data !== formValues) setFormValues({ ...values?.data });
  }, [values?.data]);

  useEffect(() => {
    setApiData(apiCollection);
  }, [apiCollection]);

  /**
   * @description Function to get data for dropdown from API.
   * @param {boolean} isApi - Whether api required or not.
   * @param {Array} formValues - Default values from form if not to be taken from api.
   * @param {string} type - Type of dropdown.
   * @param {string} dateFormat - Date format.
   * @returns {Array} - Formatted data.
   */
  const getData = (isApi = false, formValues, type, dateFormat = 'LL') => {
    let options = [];
    if (!isApi) {
      options = dropDownFormat(formValues, type, 0, 0, '', dateFormat);
    }
    return options;
  };

  /**
   * @description - Get APi
   * @param {string} apiEndPoint - endpoint
   * @returns {string} - Formatted data.

   */
  const getApi = (apiEndPoint) => {
    let api = apiEndPoint;
    if (
      Object.keys(APIQuerryParams).length > 0 &&
      apiEndPoint &&
      apiEndPoint !== null
    ) {
      Object.keys(APIQuerryParams).forEach((param) => {
        api = api.replace(`{${param}}`, `${param}=${APIQuerryParams[param]}`);
      });
    }
    return api;
  };

  return (
    <div className="dynamicForm">
      {formData.map((form) => {
        const displayField =
          form?.fields?.inputType?.value === 'Hidden' ? { display: 'none' } : {};
        const formProps = {
          name: formName
            ? `${formName}[${customIndex}][${form?.fields?.name?.value}]`
            : form?.fields?.name?.value,
          formName: formName ? `${formName}[${customIndex}]` : '',
          fieldName: form?.fields?.name?.value,
          id: form?.id + customIndex,
          key: form?.id + customIndex,
          placeholder: form?.fields?.placeholderText?.value,
          label: form?.fields?.label?.value,
          text: form?.fields?.text,
          // label2: form.fields?.placeholderText?.value,
          type: form?.fields?.inputType?.value,
          readOnly: form?.fields?.readonly?.value
            ? form?.fields?.readonly?.value
            : readOnlyFields && readOnlyFields.includes(form?.fields?.name?.value),
          value:
            form?.fields?.value?.value ||
            lget(formValues, form?.fields?.name?.value),
          customStyle: {
            width: getWidth(form?.fields?.width?.value),
            ...displayField,
          },
          dataValidations: form?.fields?.Validations,
          register: register,
          control: control,
          customError: errors,
          getValues: getValues,
          setValue: setValue,
          connectorField: form?.fields?.connectorField?.value,
          connectorType: form?.fields?.connectorType?.value,
          connectorMessage: form?.fields?.connectorMessage?.value,
          connectorPattern: form?.fields?.connectorPattern?.value,
          dateFormat: form?.fields?.dateFormat?.value,
          isHideField: form?.fields?.isHideField?.value
            ? form?.fields?.isHideField?.value
            : fieldsToHide && fieldsToHide.includes(form?.fields?.name?.value),
          showBasedOnFieldName: form?.fields?.showBasedOnFieldName?.value,
          hideBasedOnFieldName: form?.fields?.hideBasedOnFieldName?.value,
          watch: watch,
          validateOnLoad: validateOnLoad,
          trimLabelCharacters: trimLabelCharacters,
          isSearchEnabled: false,
          onEnter: false,
          className: form?.fields?.name?.value,
          requiredValidation: form?.fields?.CustomRequiredValidator,
          trigger: trigger,
          isApi: form?.fields?.isApi?.value,
          subType: form?.fields?.subType?.value,
          apiEndPoint: getApi(form?.fields?.apiEndPoint?.value),
          isClearable: true,
          isMulti: form?.fields?.isMultiple?.value,
          isSearchable: false,
          showHint: form?.fields?.inputType?.value === 'Password',
          hintText: form?.fields?.hintText?.value,
          list: form?.fields?.values,
          setError: setError,
          clearErrors: clearErrors,
          text: form?.fields?.text,
          hasApiCollection: apiList?.includes(form?.fields?.apiEndPoint?.value),
          apiData: apiData?.[getApi(form?.fields?.apiEndPoint?.value)],
        };

        switch (form?.fields?.inputType?.value) {
          case 'Text':
          case 'Hidden':
          case 'Password':
            return <Textbox {...formProps} />;
          case 'MultiLineText':
            return <TextareaComplex {...formProps} />;
          case 'Date':
            return <DateInputField {...formProps} />;
          case 'NormalDropdown':
          case 'DateDropdown':
          case 'NumberDropdown':
            return (
              <SelectComplex
                {...formProps}
                options={
                  form?.fields?.inputType?.value === 'NumberDropdown'
                    ? dropDownFormat(
                        [],
                        'NumberDropdown',
                        form.fields?.start.value !== ''
                          ? parseInt(form.fields?.start?.value)
                          : 0,
                        form.fields?.incre.value !== ''
                          ? parseInt(form.fields?.incre?.value)
                          : 0,
                        form.fields?.sortOrder?.value &&
                          form.fields?.sortOrder?.value !== '' &&
                          form.fields?.sortOrder?.value !== null
                          ? form.fields?.sortOrder?.value?.toUpperCase()
                          : SORTORDER.ASCENDING
                      )
                    : getData(
                        form?.fields?.isApi,
                        form?.fields?.values,
                        form?.fields?.inputType?.value,
                        form?.fields?.dateFormat?.value
                      )
                }
              />
            );
          case 'MonthYearDropdown':
            return (
              <MonthYearDropdown
                {...formProps}
                monthList={getData(
                  form?.fields?.isApi,
                  form?.fields?.values,
                  'NormalDropdown',
                  form?.fields?.dateFormat?.value
                )}
                yearList={dropDownFormat(
                  [],
                  'NumberDropdown',
                  form.fields?.start.value !== ''
                    ? parseInt(form.fields?.start?.value)
                    : 0,
                  form.fields?.incre.value !== ''
                    ? parseInt(form.fields?.incre?.value)
                    : 0,
                  form.fields?.sortOrder?.value &&
                    form.fields?.sortOrder?.value !== '' &&
                    form.fields?.sortOrder?.value !== null
                    ? form.fields?.sortOrder?.value?.toUpperCase()
                    : SORTORDER.ASCENDING
                )}
                incrementValue={
                  form.fields?.incre.value && form.fields?.incre.value !== null
                    ? parseInt(form.fields?.incre?.value)
                    : 0
                }
              />
            );
          case 'TypeaheadDropdown':
            return (
              <SelectComplex
                {...formProps}
                options={getData(
                  form?.fields?.isApi,
                  form?.fields?.values,
                  'TypeaheadDropdown'
                )}
                isSearchable={true}
                handleSelect={(selected) => handleSelect(selected)}
              />
            );
          case 'FileUpload':
            return (
              <UploadBlock
                {...formProps}
                optionsData={form?.fields?.values}
                handleUpload={handleUpload}
                handleDelete={handleDelete}
                isMultiFile={form?.fields?.isMultifiles}
                showOnload={form?.fields?.showOnload?.value}
                toolTipText={form?.fields?.toolTip?.value}
                limit={form?.fields?.maxFileCount?.value}
                filterReq={form?.fields?.isAttachmentFilterRequired?.value}
                value={
                  form?.fields?.value?.value ||
                  (form?.fields?.isAttachmentFilterRequired?.value
                    ? lget(formValues, 'attachment')
                    : lget(formValues, form?.fields?.name?.value))
                }
                fileTypes={form?.fields?.fileTypes?.value}
                fileSize={form?.fields?.fileSize?.value || 3145728}
              />
            );
          case 'RadioButton':
            return (
              <RadioToggle
                {...formProps}
                options={dropDownFormat(form?.fields?.values, 'radio')}
                inlineLabel={
                  form?.fields?.labelPosition?.value &&
                  form?.fields?.labelPosition?.value?.toLowerCase() === 'inline'
                }
                toggleStyle={{
                  flexDirection:
                    form?.fields?.position?.value &&
                    form?.fields?.position?.value === 'horizontal'
                      ? 'row'
                      : 'column',
                }}
                showLabelText
              />
            );
          case 'MultiCheckbox':
            return (
              <MultiCheckbox
                {...formProps}
                defaultChecked={formValues[form?.fields?.name?.value]}
              />
            );
          case 'CheckBox':
            return <Checkbox {...formProps} />;
          case 'DatePicker':
            return (
              <DatePickerComponent
                {...formProps}
                locale={form?.fields?.locale?.value}
                maxDate={form?.fields?.maxDate?.value}
                minDate={form?.fields?.minDate?.value}
              />
            );
          case 'Picker':
            return (
              <SkillsPicker
                {...formProps}
                optionData={formValues['skillsData']}
                isSearchable={true}
                titleText={form?.fields?.hintText?.value}
                hintText=""
                options={getData(
                  form?.fields?.isApi,
                  form?.fields?.values,
                  'TypeaheadDropdown'
                )}
              />
            );
          default:
            return '';
        }
      })}
    </div>
  );
};
DynamicForm.propTypes = {
  formData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.shape({}),
  setError: PropTypes.shape({}),
  clearErrors: PropTypes.shape({}),
  customIndex: PropTypes.number,
  getValues: PropTypes.func.isRequired,
  validateOnLoad: PropTypes.bool,
  trimLabelCharacters: PropTypes.number,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  values: PropTypes.shape({}),
  APIQuerryParams: PropTypes.shape({}),
  watch: PropTypes.func,
  handleSelect: PropTypes.func,
  handleUpload: PropTypes.func,
  handleDelete: PropTypes.func,
  handleDownload: PropTypes.func,
  downloading: PropTypes.bool,
  control: PropTypes.func,
  isMultiFile: PropTypes.bool,
  formName: PropTypes.string,
  fileArray: PropTypes.arrayOf(PropTypes.shape({})),
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
  fieldsToHide: PropTypes.arrayOf(PropTypes.string),
};

DynamicForm.defaultProps = {
  formData: [],
  errors: {},
  setError: {},
  clearErrors: {},
  register: () => {},
  isMultiFile: false,
  validateOnLoad: false,
  trimLabelCharacters: 25,
  customIndex: '',
  handleSelect: () => {},
  handleUpload: () => {},
  handleDelete: () => {},
  handleDownload: () => {},
  watch: () => {},
  control: () => {},
  setValue: () => {},
  getValues: () => {},
  values: {},
  formName: '',
  fileArray: [],
  downloading: false,
  APIQuerryParams: {},
  readOnlyFields: [],
  fieldsToHide: [],
};

export default DynamicForm;
