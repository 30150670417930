import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import TextLink from '../../globals/link/TextLink';

import './CalloutBarSteps.scss';

/**
 * @description steps callout bar component
 * @param {object} fields - parameters get from sitecore
 * @returns {node} - html node
 */
const CalloutSteps = ({ fields }) => {
  const { title, items } = fields;
  const [isMobView, setIsMobView] = useState(false);
  const steps = items.map((item) => ({
    id: item?.id,
    displayName: item?.displayName,
    isActive: item?.fields?.isActive?.value,
    text: item?.fields?.title?.value,
  }));
  const numSteps = steps.length;
  const lengthOfActiveSteps = steps.filter((item) => item.isActive === true).length;

  /**
   * @returns {*} - Null.
   */
  const resizeEvent = useCallback(() => {
    const isMobWidth = window.innerWidth <= 992;
    if (isMobView !== isMobWidth) {
      setIsMobView(!isMobView);
    }
  }, [isMobView]);
  useEffect(() => {
    const isMobWidth = window.innerWidth <= 992;
    // initial rendering.
    if (isMobView !== isMobWidth) {
      setIsMobView(!isMobView);
    }
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, [isMobView, resizeEvent]);
  /**
   * @description - Progress Width calculator.
   * @returns {*} - Null.
   */
  const getProgressWidth = () => {
    let progressWidth = 0;
    if (numSteps > 1) {
      progressWidth = +(100 * ((lengthOfActiveSteps - 1) / (numSteps - 1)));
      if (numSteps === 4 && isMobView) {
        progressWidth = progressWidth.toFixed(2) === '33.33' ? 36.33 : progressWidth;
      }
    }
    const styles = isMobView
      ? { height: `${progressWidth.toFixed(2)}%` }
      : { width: `${progressWidth.toFixed(2)}%` };
    return styles;
  };

  return (
    <section className="callout-steps">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2 className="title">{title?.value}</h2>
            <div className="steps-container">
              <div className="steps-wrap">
                <div
                  className={`steps-progress-bar ${
                    numSteps === 4 ? 'four-steps' : ''
                  }`}
                >
                  <div className="steps-progress">
                    <div
                      style={{ ...getProgressWidth(), left: '0px' }}
                      className="steps-progress-fill"
                    ></div>
                  </div>
                </div>
                <ul className="steps-progress">
                  {steps.map((step, index) => (
                    <li className="step" key={step.id}>
                      <span className="step-number">{index + 1}</span>
                      <span className="step-name">{step.text}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/*
            <div className="cta-wrap">
              <p className="text">{text}</p>
              <TextLink cssClass="reversed" ctaText={ctaText} />
            </div>
            */}
          </div>
        </div>
      </div>
    </section>
  );
};

CalloutSteps.defaultProps = {
  fields: {
    title: '',
    items: [],
  },
};

CalloutSteps.propTypes = {
  fields: PropTypes.string.isRequired,
};

export default CalloutSteps;
