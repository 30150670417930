/* eslint-disable */
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
  Fragment,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Loader from '../../../core/ErrorBoundary/Loader';
import ScreenerQuestionsForm from '../../../core/Forms/ScreenerQuestionsForm';
import { mergeCommonObjects } from '../../../../utils/datafactory';
import { SCREENER_QUESTIONS } from '../../../../constants';
import { dataFetcher } from '../../../../dataFetcher';
import './screener_question.scss';
import Error from '../../../core/ErrorBoundary/Error';
import { withTheme } from 'styled-components';
import { toast } from 'react-toastify';

// const mockData = [
//   {
//     questionID: 1,
//     questionType: 'Pre-Screen',
//     sortOrder: '1',
//     industry: null,
//     editType: 'FreeText',
//     question: 'Describe yourself',
//     sectionHeading: 'About You',
//     listofAnswers: [],
//     expectedAnswer: '',
//     automaticRejection: null,
//     score: 0,
//   },
//   {
//     questionID: 2,
//     questionType: 'Pre-Screen',
//     sortOrder: '2',
//     industry: null,
//     editType: 'Radio Button',
//     question:
//       'Adpscing malort cane lorem ipsum dolor adpscing ips malort cane lorem ipsum dolor?',
//     sectionHeading: '',
//     listofAnswers: ['Yes', 'No', "I don't know"],
//     expectedAnswer: '',
//     automaticRejection: 'Yes',
//     score: 0,
//   },
//   {
//     questionID: 3,
//     questionType: 'Pre-Screen',
//     sortOrder: '3',
//     industry: null,
//     editType: 'Multi-Select',
//     question:
//       'Adpscing malort cane lorem ipsum dolor adpscing ips malort cane lorem ipsum dolor?',
//     sectionHeading: 'Job',
//     listofAnswers: ['Lorem', 'Ipsum', 'Dolor', 'None'],
//     expectedAnswer: '',
//     automaticRejection: 'Yes',
//     score: 0,
//   },
//   {
//     questionID: 4,
//     questionType: 'Pre-Screen',
//     sortOrder: '4',
//     industry: null,
//     editType: 'Single-Select',
//     question: 'Adpscing malort cane lorem ipsum dolor?',
//     sectionHeading: '',
//     listofAnswers: [
//       'Lorem ipsum dolor',
//       'Consectetur adipiscing elit',
//       'Praesent porta dolor sed',
//       'Ut ornare erat bibendum porta',
//     ],
//     expectedAnswer: '',
//     automaticRejection: 'Yes',
//     score: 0,
//   },
// ];

/**
 * @description - Screener Questions Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const ScreenerQuestions = ({ inputRef, theme, ...props }) => {
  const { register, handleSubmit, getValues, errors, setValue, control } = useForm({
    mode: 'onBlur',
    revalidate: 'onBlur',
  });
  const formSubmitRef = useRef();
  let { fields, t, candidateJobDetails } = props;
  // fields = Object.entries(fields).length ? fields : mockData;
  // fields = mockData;
  const { title, subTitle, text, ctaUrl, Validations = [] } = fields;
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [formData, setFormData] = useState([]);
  const [errMsg, setMessage] = useState('');
  const history = useHistory();
  const queryStrings = new URLSearchParams(useLocation().search);
  const jobItemId = queryStrings.get('id');

  useEffect(() => {
    setLoading(false);
    setMessage('');
  }, [history.location]);

  useEffect(() => {
    if (jobItemId) {
      setFormLoading(true);
      dataFetcher(
        `${SCREENER_QUESTIONS.GET_SCREENER_QUESTIONS}?jobItemID=${jobItemId}`
        // 'jobItemID=5367BD10-DE9C-4D81-9813-693A406F1CD3'
      ).then(
        (response) => {
          setFormLoading(false);
          // setFormData(formMockData);
          setFormData(response?.data?.data);
        },
        () => {
          setFormLoading(false);
          setMessage('error-message');
          toast.error(t('error-message'), {
            position: 'top-center',
          });
        }
      );
    }
  }, []);

  const getFormattedData = (formData) => {
    let tempData = { ...formData };
    Object.keys(formData).forEach((key) => {
      if (
        typeof formData[key] === 'object' &&
        formData[key] !== null &&
        Object.keys(formData[key]).length > 0
      ) {
        // const tempArray = [];
        // Object.keys(formData[key]).forEach((k) => {
        //   if (formData[key][k]) {
        //     tempArray.push(k);
        //   }
        //   tempData[key] = tempArray;
        // });
        tempData[key] = formData[key];
      } else tempData[key] = [tempData[key]];
    });
    return tempData;
  };

  const getPostData = (data) => {
    let postData = [];
    Object.keys(data).forEach((key) => {
      let tempData = {};
      tempData.questionID = parseInt(key);
      tempData.answer = data[key];
      postData.push(tempData);
    });
    return {
      jobItemID: jobItemId,
      candidateId: candidateJobDetails?.CandidateMinimalInfo?.id,
      answers: postData,
    };
  };

  /**
   * @description - onsubmit hander.
   * @returns {undefined}
   */
  const onSubmit = (formData) => {
    const newFormData = mergeCommonObjects(formData);
    const formattedData = getFormattedData(newFormData);
    const postData = getPostData(formattedData);
    setLoading(true);
    props.onSubmitHandler(true, '');
    if (postData?.answers?.length > 0) {
      dataFetcher(SCREENER_QUESTIONS.POST_SCREENER_QUESTIONS, 'POST', postData).then(
        (response) => {
          setLoading(false);
          if (response?.data?.status !== 1000) {
            if (sessionStorage) {
              sessionStorage.setItem(
                'jobDetails',
                JSON.stringify({
                  jobTitle: candidateJobDetails?.JobDetails?.jobTitle,
                  jobURL: candidateJobDetails?.JobDetails?.jobURL,
                })
              );
            }
            history.push({
              pathname: ctaUrl?.value?.href,
            });
          } else props.onSubmitHandler(loading, response?.data?.status);
        },
        () => {
          setLoading(false);
          setMessage('error-message');
          toast.error(t('error-message'), {
            position: 'top-center',
          });
          props.onSubmitHandler(loading, 'error');
        }
      );
    } else {
      setMessage('error-message');
      toast.error(t('error-message'), {
        position: 'top-center',
      });
      setLoading(false);
      props.onSubmitHandler(loading, 'error');
    }
  };

  /**
   * @description - useImperativeHandle function.
   * @returns {undefined}
   */
  useImperativeHandle(inputRef, () => ({
    trigger: () => {
      if (!formLoading) {
        let event;
        if (typeof Event === 'function') {
          event = new Event('submit', { cancelable: true });
        } else {
          event = document.createEvent('Event');
          event.initEvent('submit', false, { cancelable: true });
        }
        formSubmitRef.current.dispatchEvent(event);
      }
      // formSubmitRef?.current?.dispatchEvent(
      //   new Event('submit', { cancelable: true })
      // );
    },
  }));

  return (
    <div className="screenerQuestion">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1 className="title">{title?.value}</h1>
            <div className="text">{subTitle?.value}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 offset-lg-3 ">
            <div className="screenerQuestionForm">
              {formLoading ? (
                <div style={{ paddingBottom: '60px' }}>
                  <Loader
                    loaderMsg={t('fetching-screener-question')}
                    loaderWithMsg
                    customLoaderStyle={{
                      width: '4rem',
                      height: '4rem',
                      color: theme?.secondaryButton,
                    }}
                    customTextStyle={{ color: theme?.secondaryButton }}
                  />
                </div>
              ) : errMsg ? (
                <Error errorMsg={t('error-message')} t={t} />
              ) : (
                <Fragment>
                  {formData?.length > 0 && (
                    <div className="formText">{text?.value}</div>
                  )}
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    ref={formSubmitRef}
                    className="form"
                  >
                    <ScreenerQuestionsForm
                      register={register}
                      errors={errors}
                      getValues={getValues}
                      Validations={Validations}
                      formData={formData}
                      setValue={setValue}
                      control={control}
                    />
                  </form>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ScreenerQuestions.propTypes = {};

ScreenerQuestions.defaultProps = {};

const ConnectedComponent = ScreenerQuestions;
export default withTheme(
  forwardRef((props, ref) => {
    return <ConnectedComponent {...props} inputRef={ref} />;
  })
);
