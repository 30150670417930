/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withPlaceholder, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import $ from 'jquery';
import './home_vertical_carousel.scss';
import { isDataExists } from '../../../utils/helperUtils';
import classNames from 'classnames';

/**
 * @description - Small carousel section For Small Blocks.
 * @param {object} data
 * @returns {Node} - Components.
 */
const SmallCarouselSection = ({ data }) => {
  let result = null;
  if (isDataExists(data)) {
    result = data.map((component, index) => {
      const key = component['key'];
      if (component.props && component.props.type === 'text/sitecore')
        return component;
      return (
        <div
          data-section-name={key}
          className={classNames(
            'home-block',
            'small-carousel',
            index === 0 ? `${key}` : ''
          )}
          id={index === 0 ? 'one' : ''}
          key={index}
        >
          {component}
        </div>
      );
    });
  }
  return result;
};

/**
 * @description - Large carousel section For Large Blocks.
 * @param {object} data
 * @returns {Node} - Components.
 */
const LargeCarouselSection = ({ data }) => {
  let result = null;
  if (isDataExists(data)) {
    result = data.map((component) => {
      return component;
    });
  }
  return result;
};

const HomeVerticalCarousel = ({
  smallBlockContent,
  largeBlockContent,
  carouselProps,
}) => {
  let currentNewIndex = smallBlockContent?.[0]?.key
    ? smallBlockContent[0].key
    : 'one';
  const [firstLoad, setload] = useState(true);

  const disableScrollify = (toggle) => {
    if ($.scrollify) {
      if (toggle) {
        $.scrollify.disable();
      } else {
        $.scrollify.enable();
        $('.scrolify-pagination a').on('click', $.scrollify.move);
      }
    }
  };
  useEffect(() => {
    if (
      !/Android|webOS|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i.test(
        navigator.userAgent
      )
    ) {
      $(function () {
        disableScrollify(false);
        require('./jquery.scrollify.js');
        if (firstLoad === true) {
          window.scrollTo(0, 0);
          $.scrollify.move(0);
          setload(false);
        }
        $.scrollify({
          section: '.homepage .home-block',
          updateHash: false,
          standardScrollElements: '.site-header, .home-block.no-scroll',
          // setHeight: false,
          // interstitialSection:
          //   '.site-footer .container, .site-footer .footer-disclaimer',
          easing: 'easeOutExpo',
          scrollSpeed: 1100,
          // offset: 0,
          // scrollbars: false,
          // standardScrollElements: "",
          setHeights: true,
          // overflowScroll: true,
          // updateHash: true,
          touchScroll: true,
          before: function (i, panels) {
            setTimeout(function () {
              $('.homepage .home-block').removeClass('active');
              $.scrollify.current().addClass('active');
            }, 400);

            let ref = panels[i].attr('data-section-name');
            // console.log('ref+++++', ref);

            $('.scrolify-pagination .active').removeClass('active');
            if (ref === undefined) {
              ref = currentNewIndex;
            } else {
              currentNewIndex = ref;
            }

            // console.log('currentNewIndex+++++', currentNewIndex);

            $('.scrolify-pagination')
              .find('a[href="#' + ref + '"]')
              .addClass('active');
          },
          afterRender: function () {
            let pagination = '<ul class="scrolify-pagination">';
            let activeClass = '';
            $('.home-block').each(function (i) {
              activeClass = '';
              if (i === 0) {
                activeClass = 'active';
              }
              pagination +=
                '<li><a class="' +
                activeClass +
                '" href="#' +
                $(this).attr('data-section-name') +
                '"><span>' +
                $(this).attr('data-section-name')?.charAt(0)?.toUpperCase() +
                $(this).attr('data-section-name')?.slice(1) +
                '</span></a></li>';
            });

            pagination += '</ul>';

            $('.home').append(pagination);

            $('.scrolify-pagination a').on('click', $.scrollify.move);
          },
        });
      });
      return () => {
        disableScrollify(true);
      };
    }
  }, []);

  return (
    <div className="homepage">
      <ul className="scrolify-pagination" aria-label="pagination">
        {smallBlockContent &&
          smallBlockContent.length > 0 &&
          smallBlockContent.map((child, index) => (
            <li key={index}>
              <a
                id={`list${index}`}
                className={index === 0 ? 'active' : ''}
                href={'#' + child.key}
                role="presentation"
                tabIndex="0"
                aria-label={`slide${index + 1}`}
              >
                <span></span>
              </a>
            </li>
          ))}
        <li>
          <a
            id="last"
            href="#block"
            role="presentation"
            tabIndex="0"
            aria-label={'last slide'}
          >
            <span></span>
          </a>
        </li>
      </ul>
      <SmallCarouselSection data={smallBlockContent} />
      <div
        data-section-name="block"
        className={classNames('home-block', 'no-scroll')}
      >
        <div className="large-container">
          {largeBlockContent?.length > 0 && (
            <LargeCarouselSection data={largeBlockContent} />
          )}
          {/* <Placeholder name="jss-footer" {...carouselProps} /> */}
        </div>
      </div>
    </div>
  );
};

HomeVerticalCarousel.defaultProps = {
  smallBlockContent: [],
  largeBlockContent: [],
};

HomeVerticalCarousel.propTypes = {
  smallBlockContent: PropTypes.arrayOf(PropTypes.shape()),
  largeBlockContent: PropTypes.arrayOf(PropTypes.shape()),
};

export default withPlaceholder([
  { placeholder: 'jss-content', prop: 'smallBlockContent' },
  { placeholder: 'jss-common', prop: 'largeBlockContent' },
])(HomeVerticalCarousel);
