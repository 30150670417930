/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ProfileItemTemplate from './Template/ProfileItem';
import { missingInfo } from './Template/MissingInfoTemplate';

/**
 * @description - Education component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const Education = ({ fields, profileInfo, t, getEmptyFields, componentName }) => {
  const { items } = fields;
  const [newFieldArr, setFieldArr] = useState([]);
  const [data, setData] = useState([]);
  const [candidateId, setCandidateId] = useState('');

  useEffect(() => {
    if (profileInfo?.educationInfo) {
      setData(profileInfo.educationInfo?.data);
      setCandidateId(profileInfo?.data?.id);
      if (items.length > 0 && profileInfo.educationInfo?.formCount > 0) {
        setFieldArr(new Array(profileInfo.educationInfo?.formCount).fill(items[0]));
      }
    }
  }, [profileInfo]);

  useEffect(() => {
    let educationInfoData = profileInfo.educationInfo?.data;
    let eduFieldsItems = items;
    if (eduFieldsItems.length > 0 && profileInfo?.educationInfo?.formCount > 0) {
      eduFieldsItems = new Array(profileInfo?.educationInfo?.formCount).fill(
        items[0]
      );
    }
    let emptyCount = 0;
    let emptyObj = {};
    let temp = 0;
    let array = [];
    if (educationInfoData && educationInfoData?.length === 0) {
      emptyCount = eduFieldsItems.length;
    } else {
      eduFieldsItems?.map((item, index) => {
        array.push(missingInfo(item, educationInfoData?.[index]));
      });
      array.map((item) => {
        Object.keys(item).map((k) => {
          if (k === 'emptyCount') {
            emptyCount = emptyCount + item[k];
          }
        });
      });
    }
    emptyObj[componentName] = emptyCount;
    getEmptyFields(emptyObj);
  }, [profileInfo]);

  return (
    <div className="accordion-container-block">
      {data.length > 0 ? (
        newFieldArr.map((block, index) => (
          <ProfileItemTemplate
            data={data[index]}
            candidateId={candidateId}
            block={block}
            filterRequired={true}
            t={t}
            cssClass="edu_section"
          />
        ))
      ) : (
        <div className="no-content-data">{t('no-education-data')}</div>
      )}
    </div>
  );
};

Education.defaultProps = {
  fields: {},
  t: (val) => val,
};

Education.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
  };
};

export default withTranslation()(connect(mapStateToProps)(Education));
