import React, { Fragment } from 'react';
// import SearchFiltersAccItemFocused from './SearchFiltersAccItemFocused';

/**
 * @description - Filter component for jobs.
 * @param {object} param0 - Filter params.
 * @returns {Node} - Filter elements.
 */
const ArrowIcon = () => (
  <Fragment>
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      className="bi bi-arrow-right"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
      />
    </svg>
  </Fragment>
);

ArrowIcon.propTypes = {};

ArrowIcon.defaultProps = {};

export default ArrowIcon;
