import React, { useState, Fragment } from 'react';
import {
  withPlaceholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Button from '../../core/globals/buttons/Button';
import { isDataExists } from '../../../utils/helperUtils';
import { getPartnerArticleList } from '../../../services';
import Loader from '../../core/ErrorBoundary/Loader';
import Error from '../../core/ErrorBoundary/Error';
import NoData from '../../core/ErrorBoundary/NoData';
import { SmallCards } from '../CardBlockInsights/Cards';
import { PartnerFilterContext } from '../Contexts';
import { toast } from 'react-toastify';
import '../CardBlockInsights/cardInsights.scss';

/**
 * @description - Get initial count for api call.
 * @param {Number} pageCount - Page number count.
 * @param {Number} jobCardPosition - Ad Card position.
 * @param {Array} cardBlocks - Ad Card block component.
 * @returns {Number} - Final count.
 */
const getInitialCount = (pageCount, jobCardPosition, cardBlocks) => {
  let initialCount = pageCount;
  if (
    jobCardPosition?.value &&
    isDataExists(cardBlocks) &&
    Number(jobCardPosition?.value) < pageCount
  ) {
    initialCount = initialCount - 1;
  }
  return initialCount;
};

/**
 * @description - Load more pagination.
 * @param {object} e - Event object.
 * @param {object} param1 - Action params.
 * @returns {undefined} - Sets the latest data.
 */
const loadMore = (e, { fetchMore, cursor, count, setBtnClick, t }) => {
  setBtnClick(true);
  fetchMore({
    variables: {
      first: count,
      after: cursor,
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      setBtnClick(false);
      if (!fetchMoreResult) return prev;
      const items = [
        ...prev?.cards?.results?.items,
        ...fetchMoreResult?.cards?.results?.items,
      ];
      if (fetchMoreResult.cards?.results) {
        fetchMoreResult.cards.results.items = items;
      }
      return fetchMoreResult;
    },
  }).catch(() => {
    setBtnClick(false);
    toast.error(t('error-message'), {
      position: 'top-center',
    });
  });
};

/**
 * @description - Filter block section.
 * @param {object} filterBlock - Filter block component.
 * @returns {Node} - Components.
 */
const FilterSection = ({ filterBlock }) => {
  let filter = null;
  if (isDataExists(filterBlock)) {
    filter = filterBlock.map((component, index) => {
      if (component.props && component.props.type === 'text/sitecore')
        return component;
      return (
        <div className="row" key={`filter_${index}`}>
          <div className="col">{component}</div>
        </div>
      );
    });
  }
  return filter;
};

/**
 * @description - Insights Card Block component.
 * @param {object} props - Input props.
 * @returns {Node} - HTML cards.
 */
const PartnerInsights = ({
  fields,
  cardBlocks,
  t,
  filterBlock,
  sitecoreContext,
}) => {
  const { jobCardPosition, count = {} } = fields;
  const [updateType, setUpdateType] = useState(null);
  const [btnClick, setBtnClick] = useState(false);
  const pageCount = count?.value || 9;
  const cardBlk = updateType ? [] : cardBlocks;
  const initialCount = getInitialCount(pageCount, jobCardPosition, cardBlk);
  const stateVal = {
    updateType,
    setUpdateType,
    t,
  };

  const { loading, error, data = {}, fetchMore } = getPartnerArticleList(
    initialCount,
    updateType || '*'
  );
  const { cards } = data;
  const hasData = isDataExists(cards?.results?.items);

  let cardList = null;

  if (loading && !btnClick) {
    cardList = <Loader />;
  } else if (error) {
    cardList = <Error errorMsg={t('error-message') || error?.message} t={t} />;
  } else {
    if (hasData) {
      const items = Object.assign([], cards?.results?.items);
      cardList = (
        <Fragment>
          <SmallCards
            smallCards={items}
            jobCardPosition={jobCardPosition}
            cardBlocks={cardBlk}
            ctaMode="link"
            ctaText={t('more-information')}
            type="partner"
            dateFormat={sitecoreContext?.Country?.dateFormat}
          />
          {cards?.results?.pageInfo?.hasNextPage && (
            <div className="row">
              <div className="col">
                <div className="load-more-btn">
                  <Button
                    text={t('load-more')}
                    handleButtonClick={loadMore}
                    actionParams={{
                      fetchMore: fetchMore,
                      cursor: cards?.results?.pageInfo?.endCursor,
                      count: pageCount,
                      setBtnClick,
                      t,
                    }}
                    isLoading={loading}
                  />
                </div>
              </div>
            </div>
          )}
        </Fragment>
      );
    } else {
      if (updateType) cardList = <NoData message={t('no-results-found')} />;
      else cardList = <NoData message={t('no-data-exists')} />;
    }
  }

  return (
    <section className="card-block insights partner-article">
      <PartnerFilterContext.Provider value={stateVal}>
        <div className="container">
          <FilterSection filterBlock={filterBlock} />
          {cardList}
        </div>
      </PartnerFilterContext.Provider>
    </section>
  );
};

export default withTranslation()(
  withPlaceholder([
    { placeholder: 'jss-content', prop: 'filterBlock' },
    { placeholder: 'jss-left', prop: 'cardBlocks' },
  ])(withSitecoreContext()(PartnerInsights))
);

PartnerInsights.propTypes = {
  fields: PropTypes.shape({
    jobCardPosition: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    placeholderImage: PropTypes.shape({
      value: PropTypes.shape({}),
    }),
    count: PropTypes.shape({
      value: PropTypes.number,
    }),
    rootItem: PropTypes.shape({
      value: PropTypes.string,
    }),
  }).isRequired,
  cardBlocks: PropTypes.arrayOf(PropTypes.shape({})),
  t: PropTypes.func.isRequired,
  filterBlock: PropTypes.arrayOf(PropTypes.shape({})),
  location: PropTypes.shape({}).isRequired,
  sitecoreContext: PropTypes.shape({}).isRequired,
};

PartnerInsights.defaultProps = {
  fields: {},
  cardBlocks: [],
  filterBlock: [],
  t: (val) => val,
  sitecoreContext: {},
};
