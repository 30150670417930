/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

/**
 * @description - Modal Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const TooltipComponent = (props) => {
  const [text, setText] = useState(props.text);
  const { placement } = props;
  useEffect(() => {
    setText(props.text);
  }, [props.text]);

  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip id={`tooltip-right`}>
          <RichText field={{ value: text }} />
        </Tooltip>
      }
    >
      {props.children}
    </OverlayTrigger>
  );
};

TooltipComponent.propTypes = {
  text: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

TooltipComponent.defaultProps = {
  text: '',
  placement: 'right',
};

export default TooltipComponent;
