import React from 'react';
import PropTypes from 'prop-types';
import { mediaApi, RichText } from '@sitecore-jss/sitecore-jss-react';
import TextLink from '../../../../core/globals/link/TextLink';

import { getImg } from '../../../../../utils/helperUtils';

import './careerResources.scss';

/**
 * @description - Card component for card scrolls.
 * @param {*} param0 - Input props.
 * @returns {Node} - HTML card.
 */
const CareerResources = ({ fields }) => {
  const { title, items = [] } = fields;
  return (
    <section className="career-resources">
      <RichText tag="h2" field={title} className="main-heading" />
      <div className="card-deck">
        {items.map((item, index) => {
          const { ctaText, ctaUrl, description, image, title } = item?.fields || {};
          const imgVal = getImg(image) || image?.src;
          const imgJSSValue = mediaApi.updateImageUrl(imgVal);
          return (
            <div className={`card card-${index}`} key={`${item.id}_${index}`}>
              {imgVal ? (
                <figure
                  className="article-image"
                  style={{
                    backgroundImage: `url(${imgJSSValue})`,
                  }}
                >
                  <img
                    className="sr-only"
                    // alt={image?.alt || image?.value?.alt}
                    alt=""
                    src={imgJSSValue}
                  />
                </figure>
              ) : null}
              <div className="card-body">
                <RichText field={title} tag="div" className="title" role="heading" />
                <RichText field={description} tag="p" className="description" />
              </div>
              <div className="card-button">
                <TextLink
                  ctaText={ctaText}
                  ctaUrl={ctaUrl}
                  arrow
                  cssClass={index !== 0 ? 'white-link' : ''}
                />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

CareerResources.defaultProps = {
  fields: {},
};

CareerResources.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({
      value: PropTypes.string,
    }),
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default CareerResources;
