/* eslint-disable */
import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import crypto from 'crypto-js';
import { UPDATE_SESSION } from '../../../constants';

import { useFetchData } from '../../../hooks/http-client';
import { GET_JOB_DETAILS } from '../../../constants';
import { authstates } from '../../../utils/enums';
import { saveJobDetails, handleRedirect } from '../../../redux/actions/actions';
import Loader from '../../core/ErrorBoundary/Loader';
import { logoutSuccess } from '../../../redux/actions/loginActions';
import { STORAGE } from '../../../constants';
import { withTheme } from 'styled-components';

/**
 * @description - This is a placeholder component for todo pages.
 * @param {object} props - Input props.
 * @returns {Node} - HTML to render.
 */
const Redirect = (props) => {
  const {
    location,
    fields,
    profileInfo,
    jobDetails,
    sitecoreContext,
    authFunc,
    theme,
  } = props;
  const dispatch = useDispatch();
  const { load } = useFetchData();
  const loginReducer = useSelector((state) => state.loginReducer);
  // const profileInfoReducer = useSelector((state) => state.profileInfoReducer);
  const history = useHistory();

  const handlePageRedirect = (path) => {
    if (path.indexOf('candidate/jobapply?id') != -1) {
      async function updateSesion() {
        await load(`${UPDATE_SESSION}`, { isLogout: false });
      }
      updateSesion().then(() => {
        history.push(path);
        dispatch(handleRedirect(true));
        if (sessionStorage) {
          sessionStorage.removeItem('redirectPath');
          sessionStorage.removeItem('isLogout');
        }
      });
    } else {
      if (
        path.indexOf('candidate/jobapplysuccess') !== -1 ||
        path.indexOf('candidate/jobapply-failure') !== -1
      ) {
        history.push(sitecoreContext?.Country?.jobSearchURL);
      } else history.push(path);
      dispatch(handleRedirect(true));
      if (sessionStorage) {
        sessionStorage.removeItem('isLogout');
        sessionStorage.removeItem('redirectPath');
      }
    }
  };

  useEffect(() => {
    if (jobDetails.isRedirect) return;
    const isLogout = sessionStorage.getItem('isLogout');
    const redirectPath = sessionStorage.getItem('redirectPath');
    if (isLogout) {
      if (location.pathname !== redirectPath) {
        handlePageRedirect(redirectPath);
      } else {
        history.push('/');
        dispatch(handleRedirect(true));
        if (sessionStorage) {
          sessionStorage.removeItem('redirectPath');
          sessionStorage.removeItem('isLogout');
        }
      }
    }
  }, []);

  useEffect(() => {
    if (jobDetails.isRedirect) return;
    if (
      loginReducer.initialized &&
      loginReducer.state === authstates.AUTHENTICATED
    ) {
      if (
        !sitecoreContext?.ADB2C?.countryToken
          ?.toLowerCase()
          .includes(loginReducer?.idToken?.idTokenClaims?.country?.toLowerCase())
      ) {
        authFunc.logout(sitecoreContext?.ADB2C?.authFailedRedirect);
        dispatch(logoutSuccess());
        dispatch(handleRedirect(true));
        history.push(sitecoreContext?.ADB2C?.authFailedRedirect);
      } else {
        localStorage.setItem(
          STORAGE.UUID,
          crypto.SHA3(loginReducer?.idToken?.uniqueId).toString()
        );
        const redirectPath = sessionStorage.getItem('redirectPath');        
        if (
          !redirectPath ||
          redirectPath === sitecoreContext?.ADB2C?.authFailedRedirect
        ) {
          history.push('/');
        } else {          
              //normal flow
              if (location.pathname !== redirectPath) {
                sessionStorage.setItem(STORAGE.PAGE_EVENT, true);
              handlePageRedirect(redirectPath);
              }
            }
          }
        }          
  }, [loginReducer.state, profileInfo]);

  return (
    <div className="container-fluid">
      <Loader
        loaderMsg="Redirecting"
        loaderWithMsg
        customLoaderStyle={{
          width: '4rem',
          height: '4rem',
          color: theme?.secondaryButton,
        }}
        customTextStyle={{ color: theme?.secondaryButton }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer,
    jobDetails: state.jobDetailsReducer,
    authFunc: state.authFunctionsReducer,
  };
};

Redirect.propTypes = {
  // location: '',
};

Redirect.defaultProps = {
  // location: '',
};

export default withSitecoreContext()(
  withTheme(connect(mapStateToProps)(withRouter(Redirect)))
);
