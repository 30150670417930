/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { geolocated } from 'react-geolocated';
import { isObjNotEmpty } from '../../../../utils/helperUtils';
import {
  getLocFromStorage,
  checkLocationExists,
} from '../../../../utils/locationUtils';
import { withTranslation } from 'react-i18next';
import usePrevious from '../../../../hooks/usePrevious';
import { saveSelectedLocation } from '../../../../redux/actions';

import './forms.scss';
import './location-search.scss';

/**
 * @description to handle location access error
 * @param {string} message - error message
 * @returns {undefined} - no
 */
const locationAlert = (message) => {
  // eslint-disable-next-line no-alert
  alert(message);
};

/**
 * @description - Set selected location.
 * @param {object} selectedLocation - Selected location.
 * @param {Function} codeLatLng - Mark based on latitude and longitude
 * @param {Function} setQuery - Set search query in input.
 * @param {Function} onLocationSelect - Location select action.
 * @param {Function} t - Dictionary function.
 * @returns {undefined}
 */
const setSelectedLocation = (
  selectedLocation,
  codeLatLng,
  setQuery,
  onLocationSelect,
  t
) => {
  if (selectedLocation) {
    if (
      isObjNotEmpty(selectedLocation) &&
      selectedLocation.lat &&
      selectedLocation.lng
    ) {
      const { lat, lng, place } = selectedLocation;
      if (place) {
        setQuery(place);
      } else {
        codeLatLng(lat, lng, setQuery, onLocationSelect, t);
      }
    } else {
      setQuery('');
    }
  }
};

/**
 * @description - This function is triggered on Key enter
 * @param {object} input - Reference
 * @returns {undefined} - nothing
 */
function enableEnterKey(input) {
  /* Store original event listener */
  const _addEventListener = input.addEventListener;

  const addEventListenerWrapper = (type, listener) => {
    if (type === 'keydown') {
      /* Store existing listener function */
      const _listener = listener;
      listener = (event) => {
        /* Simulate a 'down arrow' keypress if no address has been selected */
        const suggestionSelected = document.getElementsByClassName(
          'pac-item-selected'
        ).length;
        if (event.key == 'Shift') return false;
        if ((event.key === 'Tab' || event.key === 'Enter') && !suggestionSelected) {
          const e = new KeyboardEvent('keydown', {
            key: 'ArrowDown',
            code: 'ArrowDown',
            keyCode: 40,
          });
          _listener.apply(input, [e]);
        }
        _listener.apply(input, [event]);
      };
    }
    _addEventListener.apply(input, [type, listener]);
  };

  input.addEventListener = addEventListenerWrapper;
}

/**
 * @description - callback to be called followed by script load
 * @param {function} updateQuery - state update selected location
 * @param {function} sendLocation - to send geometry back
 * @param {object} autoCompleteRef - input reference
 * @param {string} region - search region
 * @param {function} updateError - to update error message
 * @param {object} refVal - Reference
 * @returns {undefined} - nothing
 */
function handleScriptLoad(
  updateQuery,
  sendLocation,
  autoCompleteRef,
  region,
  updateError,
  refVal
) {
  const regionList =
    region.indexOf(',') > 0 ? region.replace(/ /g, '').split(',') : region;
  const autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    {
      types: ['(regions)'],
      componentRestrictions: {
        //country: [region],
        country: regionList,
      },
    }
  );
  autoComplete.setFields(['address_components', 'formatted_address', 'geometry']);
  autoComplete.addListener('place_changed', () => {
    handlePlaceSelect(updateQuery, sendLocation, updateError, autoComplete, refVal);
  });
  enableEnterKey(autoCompleteRef.current);
}

/**
 * @description - to handle place select event
 * @param {function} updateQuery - state update function
 * @param {function} sendLocation - to send geometry back
 * @param {function} updateError - to update error message
 * @param {function} autoComplete - AutoComplete reference
 * @param {object} refVal - Reference value.
 * @returns {undefined}
 */
async function handlePlaceSelect(
  updateQuery,
  sendLocation,
  updateError,
  autoComplete,
  refVal
) {
  const addressObject = await autoComplete.getPlace();
  const query = addressObject?.formatted_address;
  if (addressObject?.geometry?.location.lat()) {
    updateQuery(query);
    updateError(false);
    sendLocation({
      lat: addressObject?.geometry?.location.lat(),
      lng: addressObject?.geometry?.location.lng(),
      place: query,
    });
  } else {
    if (!refVal) {
      sendLocation(null);
      updateError(true);
    }
  }
}

/**
 * @description - to get place from lat n lng
 * @param {number} lat - latitude
 * @param {number} lng - longitude
 * @param {function} updateQuery - to update place name in the textbox
 * @param {function} updateLocation - to update caller component
 * @param {function} translate - translate function
 * @returns {undefined} - no returns
 */
const codeLatLng = (lat, lng, updateQuery, updateLocation, translate) => {
  if (window?.google?.maps) {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = new window.google.maps.LatLng(lat, lng);
    geocoder.geocode({ latLng: latlng }, function (results, status) {
      if (status === window.google.maps.GeocoderStatus.OK) {
        const result =
          results.find(
            (value) =>
              value.types &&
              value.types[0] === 'locality' &&
              value.types[1] === 'political'
          ) || results[3];
        if (result) {
          updateQuery(result.formatted_address);
          updateLocation({ lat: lat, lng: lng, place: result.formatted_address });
        } else {
          locationAlert(translate('location-not-available'));
        }
      }
    });
  }
};

/**
 * @description - autocomplete search location component
 * @param {object} props - Input props.
 * @returns {Node} - HTML
 */
function LocationSearch(props) {
  const { onLocationSelect, label, region, errorMessage, refVal, showError } = props;
  const { showCurrentLocation, showSearchIcon, id, name, selectedLocation } = props;
  const { changeHandler, placeholder, focusHandler, focusOutHandler, t } = props;
  const { disableEnterSubmit, coords } = props;
  const [query, setQuery] = useState('');
  const [error, setError] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const autoCompleteRef = useRef(refVal);
  let typeProp = {};
  if (disableEnterSubmit) typeProp = { type: 'button' };
  const prevValue = usePrevious({ selectedLocation });
  const dispatch = useDispatch();

  /**
   * to handle navigator error
   * @param {*} error - error object
   * @returns {undefined} - nothing
   */
  const handleError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        // User denied access to location. Perhaps redirect to alternate content?
        locationAlert(props.t('location-permission-denied'));
        break;
      case error.POSITION_UNAVAILABLE:
        locationAlert(props.t('location-not-available'));
        break;
      case error.PERMISSION_DENIED_TIMEOUT:
        locationAlert(props.t('location-permission-timedout'));
        break;
      case error.UNKNOWN_ERROR:
        locationAlert(props.t('location-unknown-error'));
        break;
    }
  };

  /**
   * @description - to handle position if cords available
   * @param {object} position - position cords
   * @returns {undefined} - no returns
   */
  const handlePosition = (position) => {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;
    codeLatLng(lat, lng, setQuery, onLocationSelect, props.t);
  };

  /**
   * @description to load map script if not loaded
   * @returns {unedfined}- nothing
   */
  const initPalceSearch = () => {
    if (scriptLoaded === false) {
      if (window?.google) {
        handleScriptLoad(
          setQuery,
          onLocationSelect,
          autoCompleteRef,
          region,
          setError,
          refVal
        );
        setScriptLoaded(true);
      }
    }
  };

  /**
   * @description to get location on button click
   * @param {boolean} showCurrent - whether to show current location
   * @returns {undefined}
   */
  const search = (showCurrent) => {
    initPalceSearch();
    if (navigator.geolocation && showCurrent) {
      navigator.geolocation.getCurrentPosition(handlePosition, handleError);
    }
  };

  useEffect(() => {
    initPalceSearch();
  }, []);

  useEffect(() => {
    if (showCurrentLocation) {
      const storageLocation = getLocFromStorage();
      if (!checkLocationExists(storageLocation)) {
        codeLatLng(
          coords?.latitude,
          coords?.longitude,
          setQuery,
          onLocationSelect,
          t
        );
        dispatch(
          saveSelectedLocation({ lat: coords?.latitude, lng: coords?.longitude })
        );
      } else {
        codeLatLng(
          storageLocation?.lat,
          storageLocation?.lng,
          setQuery,
          onLocationSelect,
          t
        );
        dispatch(saveSelectedLocation(storageLocation));
      }
    }
  }, [coords, showCurrentLocation]);

  useEffect(() => {
    if (
      prevValue?.selectedLocation?.lat !== selectedLocation?.lat ||
      prevValue?.selectedLocation?.lng !== selectedLocation?.lng ||
      prevValue?.selectedLocation?.place !== selectedLocation?.place
    ) {
      // search(true);
      setSelectedLocation(
        selectedLocation,
        codeLatLng,
        setQuery,
        onLocationSelect,
        t
      );
    }
  }, [selectedLocation]);

  return (
    <div className="location-search" id={`${id}_card`}>
      <div className={`form-block ${label ? 'complex' : ''}`}>
        <input
          ref={autoCompleteRef}
          onChange={(event) => {
            setQuery(event.target.value);
            if (changeHandler) changeHandler(event.target.value);
          }}
          onClick={() => initPalceSearch()}
          value={query ? query : ''}
          title={query ? query : label}
          placeholder={placeholder}
          className={query ? 'filled' : ''}
          tabIndex={0}
          aria-label={label}
          id={id}
          name={name}
          autoComplete="off"
          onFocus={(event) => {
            if (focusHandler) focusHandler(event);
          }}
          onBlur={(event) => {
            if (focusOutHandler) focusOutHandler(event);
          }}
          {...(error || showError
            ? { 'aria-invalid': true, 'aria-describedby': `error_${id}` }
            : '')}
        />
        {label ? <InputLabel label={label} /> : null}
        {showSearchIcon && (
          <button
            className="search-icon"
            onClick={() => search(true)}
            aria-label="Geo Location Search"
            {...typeProp}
          ></button>
        )}
        {(error || showError) && (
          <div className="error-msg" id={`error_${id}`}>
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
}

LocationSearch.defaultProps = {
  onLocationSelect: () => {},
  label: '',
  region: 'US',
  errorMessage: 'Something went wrong',
  showCurrentLocation: false,
  showSearchIcon: true,
  placeholder: '',
  name: 'locationText',
  id: 'location',
  refVal: null,
  focusHandler: null,
  focusOutHandler: null,
  selectedLocation: null,
  changeHandler: null,
  t: () => {},
  showError: false,
  disableEnterSubmit: false,
};

LocationSearch.propTypes = {
  onLocationSelect: PropTypes.func,
  label: PropTypes.string,
  region: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  showCurrentLocation: PropTypes.bool,
  showSearchIcon: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  refVal: PropTypes.node,
  id: PropTypes.string,
  focusHandler: PropTypes.func,
  focusOutHandler: PropTypes.func,
  selectedLocation: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  changeHandler: PropTypes.func,
  t: PropTypes.func,
  showError: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  disableEnterSubmit: PropTypes.bool,
};

export default geolocated()(withTranslation()(LocationSearch));

/**
 * @description - Label for input.
 * @param {object} param0 - Input props.
 * @returns {Node} - HTML label.
 */
const InputLabel = ({ label, id }) => (
  <label htmlFor={id}>
    <div className="active">
      {/* <div className="input-pin-icon-wrap">
        <span className="input-pin-icon"></span>
      </div> */}
      {label}
    </div>
    <div className="inactive">
      {/* <div className="input-pin-icon-wrap">
        <span className="input-pin-icon"></span>
      </div> */}
      {label}
    </div>
  </label>
);

InputLabel.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
};

InputLabel.defaultProps = {
  id: 'location',
};
