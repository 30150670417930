/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import lget from 'lodash.get';
import { isNullorEmpty } from '../../../../../utils/helperUtils';
import { CREATE_PROFILE } from '../../../../../constants';
import { attachementDownloadApi } from '../../../../../services/apiServices/candidateService';
import parse from 'html-react-parser';
import { checkValueTypeAndGetTheCount } from '../../../Utils/helper';
import ProfileItemTemplate from './Template/ProfileItem';
import { missingInfo } from './Template/MissingInfoTemplate';

// import { flattenObject } from '../../../../../utils/datafactory';

/**
 * @description - Certifications component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const Certifications = ({
  fields,
  profileInfo,
  t,
  getEmptyFields,
  componentName,
}) => {
  const { items } = fields;
  const [data, setData] = useState([]);
  const [candidateId, setCandidateId] = useState('');
  const [noData, showNoData] = useState(false);

  useEffect(() => {
    if (profileInfo?.id) {
      setData(profileInfo?.certificationInfo?.certifications);
      setCandidateId(profileInfo?.id);
    }
  }, [profileInfo]);

  useEffect(() => {
    let certificationInfoData = profileInfo?.certificationInfo?.certifications;
    let emptyCount = 0;
    let emptyObj = {};
    let temp = 0;
    let array = [];
    items?.map((item, index) => {
      array.push(missingInfo(item, certificationInfoData));
    });
    array.map((item) => {
      Object.keys(item).map((k) => {
        if (k === 'emptyCount') {
          emptyCount = emptyCount + item[k];
        } else if (k === 'temp') {
          temp = temp + item[k];
        }
      });
    });
    emptyObj[componentName] = emptyCount;
    getEmptyFields(emptyObj);
    if (temp === 0) {
      showNoData(true);
    }
  }, [profileInfo]);

  return (
    <div className="accordion-container-block">
      {data ? (
        items?.map((block, index) => (
          <ProfileItemTemplate
            data={data}
            candidateId={candidateId}
            block={block}
            t={t}
          />
        ))
      ) : (
        <div className="no-content-data">{t('no-certification-data')}</div>
      )}
      {noData && <div className="no-content-data">{t('no-certification-data')}</div>}
    </div>
  );
};

Certifications.defaultProps = {
  fields: {},
  t: (val) => val,
};

Certifications.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
  };
};

export default withTranslation()(connect(mapStateToProps)(Certifications));
