import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  RichText,
  Image,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { checkDataExists, showString } from '../../../../utils/helperUtils';
import TextLink from '../../globals/link/TextLink';
import ButtonLink from '../../globals/link/ButtonLink';
import SaveJobAlert from '../../Search/SearchJob/SaveJobAlert';
import { useFetchData } from '../../../../hooks/http-client';
import { SEARCH } from '../../../../constants';

import '../calloutbar.scss';
import '../../Search/search.scss';

/**
 * @description large callout bar component
 * @param {object} props - parameters get from sitecore
 * @returns {node} - html node
 */
const CalloutBarLarge = (props) => {
  const { params, sitecoreContext, fields, t = () => {} } = props;
  const {
    title,
    text,
    ctaText,
    ctaUrl,
    image,
    cssClass,
    componentBackgroundColor,
  } = fields;
  let color_code = '';
  let css_class = '';
  const isbool = true;
  const { load } = useFetchData();
  const location = useLocation();
  const [freqlist, setFreqList] = useState([]);
  const [response, setResponse] = useState({});
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || 'manpower';
  const updatebackgroundColor = componentBackgroundColor?.map((item) => {
    const { colorCode } = item?.fields;
    css_class = item?.fields?.class?.value;
    color_code = checkDataExists(colorCode) ? checkDataExists(colorCode) : '';
    return color_code;
  });
  const { mode = '', site = '' } = params;
  const btnType =
    typeof params?.mode !== 'undefined' ? showString(mode).toLowerCase() : '';
  const siteName =
    typeof params?.site !== 'undefined' ? showString(site).toLowerCase() : '';
  const expiredSearchPageUrl = sitecoreContext?.route?.name?.toLowerCase() || '';
  const isExpiredComp =
    expiredSearchPageUrl?.toLowerCase()?.indexOf('job search') >= 0;
  const isQueryParamAvail = location?.search !== '';
  const isCalloutBarAvail = isExpiredComp
    ? isQueryParamAvail
      ? isbool
      : !isbool
    : isbool;
  /**
   * @description - callback for successful api reponse
   * @param {object} response - api response object
   * @returns {undefined} - no returns
   */
  const setData = (response) => {
    const data = [];
    const freqOption = response?.data?.frequency;
    freqOption.forEach((list, index) => {
      data[index] = {
        fields: {
          key: { value: list.key },
          label: { value: list.label },
        },
        id: list.fequencyID,
      };
    });
    setFreqList(data);
    setResponse(response?.data);
  };
  /**
   * @description get alert list
   * @returns {undefined} nothing
   */
  const getAlerts = () => {
    load(SEARCH.JOB_SEARCH.GET_SEARCH_RESULT_SETTINGS, null, setData);
  };
  useEffect(() => {
    btnType === 'jobalert' && getAlerts();
  }, [btnType]);

  return (
    <>
      {isCalloutBarAvail && (
        <section
          className={`calloutbar-section large ${
            cssClass?.value ? `${cssClass?.value}` : `white-bg`
          } ${css_class}`}
          style={{ background: `${updatebackgroundColor}` }}
        >
          <div className="calloutbar-body container">
            <div className="row">
              <div className="col-xl-5 col-lg-6">
                <div className="callout-image">
                  <div className="blurred">
                    <Image field={image} />
                  </div>
                  <Image field={image} />
                </div>
              </div>
              <div className="col-xl-5 col-lg-6">
                {title && (
                  <RichText
                    tag="div"
                    className="title"
                    role="heading"
                    field={title}
                  />
                )}

                {text && (
                  <RichText
                    tag="p"
                    className="text-content"
                    id="section-description"
                    field={text}
                  />
                )}

                {btnType !== 'jobalert' ? (
                  brandName === 'manpower' ||
                  cssClass?.value === 'manpower' ||
                  cssClass?.value === 'bankpower' ? (
                    <ButtonLink
                      cssClass="orange"
                      ctaText={ctaText}
                      ctaUrl={ctaUrl}
                      ctaLabel={`${title?.value} ${ctaText?.value}`}
                      arrow
                    />
                  ) : (
                    <TextLink
                      ctaText={ctaText}
                      ctaUrl={ctaUrl}
                      arrow
                      // ctaIcon={ctaIcon?.value?.src}
                    />
                  )
                ) : (
                  <section tabIndex="-1">
                    {
                      siteName !== 'bankpower' &&
                        siteName !== 'sweden' &&
                        Object.keys(response).length > 0 && (
                          <SaveJobAlert
                            searchResultCount={response?.newLabelCount}
                            searchQuery={null}
                            searchLocation={null}
                            jobAlertOptions={freqlist}
                            ctaText={t('create-alert-for-future-jobs')}
                            translate={t}
                            jobAlertSuccessMessage={{
                              value: response?.alertSuccess,
                            }}
                            showJobAlert={response?.showJobAlert}
                            dashboardUrl={{ href: response?.dashboardUrl }}
                            consentText={{ value: response?.consentText }}
                            totalPositions={null}
                            totalPositionsLabel={t('total-positions')}
                          />
                        )
                      //    {response?.data?.filters?.totalCount === 0 ? (
                      //   <SearchRibbon
                      //     ctaText={t('create-alert-for-future-jobs')}
                      //     mode={'no-results'}
                      //     translate={t}
                      //     totalPositions={response?.data?.totalNumberOfPosition}
                      //     totalPositionsLabel={t('total-positions')}
                      //   />
                      // ) : null}
                    }
                  </section>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

CalloutBarLarge.defaultProps = {
  fields: {
    title: {
      value: '',
    },
    text: {
      value: '',
    },
    ctaText: {
      value: '',
    },
    ctaUrl: {
      value: {
        href: '#',
      },
    },
    cssClass: {
      value: '',
    },
    image: {
      value: {
        src:
          'http://man-designsystem-qa.azurewebsites.net/experis-storybook/static/media/mpg-logo.58e5f8d9.png',
      },
    },
    ctaIcon: {
      value: {
        src:
          'http://man-designsystem-qa.azurewebsites.net/experis-storybook/static/media/mpg-logo.58e5f8d9.png',
      },
    },
    componentBackgroundColor: [],
  },
  params: { mode: { value: '' } },
  t: () => {},
};

CalloutBarLarge.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    text: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    ctaText: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    ctaUrl: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string.isRequired,
      }),
    }),
    cssClass: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    image: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }),
    }),
    ctaIcon: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }),
    }),
    componentBackgroundColor: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  sitecoreContext: PropTypes.shape({}).isRequired,
  params: PropTypes.shape({
    mode: {
      value: PropTypes.oneOf(['', '']),
    },
  }),
  t: PropTypes.func,
};

export default withTranslation()(withSitecoreContext()(CalloutBarLarge));
