import React from 'react';
import PropTypes from 'prop-types';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Card from '../../Cards/Card';
import NoData from '../../ErrorBoundary/NoData';
import Slider from 'react-slick';
import TextLink from '../../globals/link/TextLink';
import { getImg, isDataExists } from '../../../../utils/helperUtils';

import '../cardscroll.scss';

const settings = {
  accessibility: true,
  dots: true,
  // infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  infinite: false,
  arrows: false,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1.1,
        slidesToScroll: 1,
      },
    },
  ],
};

/**
 * @description - Card Scroll case studies component.
 * @param {object} props - Input props.
 * @returns {Node} - HTML section.
 */
const CardScrollCaseStudies = (props) => {
  const { cssClass, fields, t, sitecoreContext, page } = props;
  const { title, ctaUrl, ctaText, placeholderImage, items = [], linkText } = fields;
  const { contentType, topic, subTopic } = fields;
  const history = useHistory();
  const isItemExists = isDataExists(items);
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;
  let ctaUrlVal = '';
  if (page === 'partner') {
    ctaUrlVal = ctaUrl;
  } else {
    const urlVal = ctaUrl?.value?.href ? ctaUrl?.value?.href.split('?') : [];
    const queryVal = isItemExists
      ? {
          contentType: {
            id: contentType?.id,
            name: contentType?.fields?.name?.value,
          },
          topic: {
            id: topic?.id,
            name: topic?.fields?.name?.value,
          },
          subTopic: {
            id: subTopic?.id,
            name: subTopic?.fields?.name?.value,
          },
        }
      : {};
    ctaUrlVal = {
      pathname: urlVal[0],
      search: urlVal[1],
      query: queryVal,
    };
  }

  return isItemExists || isEditing ? (
    <>
      <section
        className={`card-scroll ${cssClass} ${isItemExists ? '' : 'no-padding'}`}
      >
        <div className="container">
          <div className="card-scroll-header">
            <RichText tag="div" className="title" field={title} />
            {ctaUrl?.value && ctaText?.value && (
              <div className="cta">
                <TextLink cssClass="blue" ctaText={ctaText} ctaUrl={ctaUrlVal} />
              </div>
            )}
          </div>
          {isItemExists ? (
            <Slider {...settings}>
              {items.map((item, idx) => {
                const itemVal = item?.fields || {};
                const url = itemVal?.url?.value || itemVal?.url;
                return (
                  <Card
                    key={`card_${idx}`}
                    title={itemVal?.headline}
                    text={itemVal?.text}
                    img={
                      itemVal.image?.src ||
                      getImg(itemVal.image) ||
                      getImg(placeholderImage)
                    }
                    altText={itemVal.image?.altText || `Card Image ${idx}`}
                    ctaText={linkText}
                    // ctaUrl={url}
                    clickHandler={() => history.push(url)}
                  />
                );
              })}
            </Slider>
          ) : (
            <NoData message={t('no-data-exists')} />
          )}
        </div>
      </section>
    </>
  ) : null;
};

CardScrollCaseStudies.defaultProps = {
  fields: {
    items: [],
    contentType: {},
    topic: {},
    subTopic: {},
  },
  cssClass: '',
  page: 'placeholder',
};

CardScrollCaseStudies.propTypes = {
  fields: PropTypes.shape({
    /** Header title */
    title: PropTypes.shape({
      value: PropTypes.string,
    }),

    /** Header link or button text */
    ctaText: PropTypes.shape({
      value: PropTypes.string,
    }),

    /** Header link or button destination. If blank, link will not show. */
    ctaUrl: PropTypes.shape({
      value: PropTypes.shape({}),
    }),

    items: PropTypes.arrayOf(PropTypes.shape({})),
    placeholderImage: PropTypes.shape({
      value: PropTypes.shape({}),
    }),
    linkText: PropTypes.shape({
      value: PropTypes.string,
    }),
    contentType: PropTypes.shape({}),
    topic: PropTypes.shape({}),
    subTopic: PropTypes.shape({}),
  }),
  cssClass: PropTypes.string,
  page: PropTypes.string,
  t: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({
    pageEditing: PropTypes.bool,
  }).isRequired,
};

export default withTranslation()(withSitecoreContext()(CardScrollCaseStudies));
