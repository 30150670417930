/* eslint-disable */
/* eslint-disable max-lines-per-function */
import React from 'react';
import Slider from 'react-slick';
import { Link, useLocation } from 'react-router-dom';
import { mediaApi, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

/**
 * @description - DashboardNav component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const DashboardNav = ({ fields, sitecoreContext }) => {
  const location = useLocation();
  const path = location.pathname;
  const { items = [] } = fields;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    accessibility: true,
    unslick: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3.4,
          unslick: false,
        },
      },
    ],
  };
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || 'experis';

  return (
    <div className={'dashboard-nav ' + brandName}>
      {/* Desktop */}
      <div className="only-desktop">
        <ul className="leftBar-content">
          {items.map((child, index) => (
            <li>
              <Link
                key={child?.id}
                to={child?.fields?.url?.value?.href}
                aria-label={`Go to ${child?.fields?.title?.value}`}
                className={path == child?.fields?.url?.value?.href ? 'active' : ''}
                aria-current={
                  path == child?.fields?.url?.value?.href ? 'page' : false
                }
                key={index}
              >
                <span className="nav-icon">
                  <img
                    className={
                      child?.fields?.icon?.value?.src ? 'dashboard-nav-image' : ''
                    }
                    src={
                      child?.fields?.icon?.value?.src &&
                      mediaApi.updateImageUrl(child?.fields?.icon?.value?.src)
                    }
                    alt=" "
                  />
                </span>
                <span className="nav-text">{child?.fields?.title?.value}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>

      {/* Mobile - Slick Slider */}
      <div className="only-mobile">
        <ul className="leftBar-content">
          <Slider {...settings}>
            {items.map((child, index) => (
              <li>
                <Link
                  key={child?.id}
                  to={child?.fields?.url?.value?.href}
                  // aria-label={`Go to ${child?.fields?.title?.value}`}
                  className={path == child?.fields?.url?.value?.href ? 'active' : ''}
                  aria-current={
                    path == child?.fields?.url?.value?.href ? 'page' : false
                  }
                  key={index}
                >
                  <span className="nav-icon">
                    <img
                      className={
                        child?.fields?.icon?.value?.src ? 'dashboard-nav-image' : ''
                      }
                      src={
                        child?.fields?.icon?.value?.src &&
                        mediaApi.updateImageUrl(child?.fields?.icon?.value?.src)
                      }
                      alt=" "
                    />
                  </span>
                  <span className="nav-text">{child?.fields?.title?.value}</span>
                </Link>
              </li>
            ))}
          </Slider>
        </ul>
      </div>
    </div>
  );
};

export default withSitecoreContext()(DashboardNav);
