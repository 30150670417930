/* eslint-disable require-jsdoc */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '../globals/link/ButtonLink';
import Button from '../globals/buttons/Button';
import { mediaApi, RichText } from '@sitecore-jss/sitecore-jss-react';
import { checkDataExists } from '../../../utils/helperUtils';
import './cards.scss';
import RegisterPartner from '../Forms/RegisterPartnerForm/RegisterPartner';

const CardSubpageDiamond = ({
  title,
  text,
  img,
  altText,
  ctaText,
  ctaUrl,
  items,
}) => {
  const externalLinkType = ctaUrl?.value?.linktype === 'internal' ? false : true;
  const imgVal = mediaApi.updateImageUrl(img.value.src);
  const [showRegPart, setshowRegPart] = useState(false);
  const handleModal = () => {
    setshowRegPart(!showRegPart);
  };

  return (
    <div className="flex-wrapper">
      <div className="diamond-wrapper">
        <div className="diamond">
          <img className="image" src={imgVal} alt={altText} />
        </div>
        <div className="card-body-diamond subpage-subcard ">
          <RichText tag="p" className="large title" field={title} />
          <RichText tag="p" className="large" field={text} />
          {checkDataExists(ctaUrl) && checkDataExists(ctaText) && (
            <ButtonLink
              ctaText={ctaText}
              ctaUrl={ctaUrl}
              ctaLabel={`${title} ${ctaText}`}
              cssClass="orange-border"
              externalLink={externalLinkType}
              arrow
            />
          )}
          {items.map((x, index) => (
            <div key={index}>
              <Button
                text={'Become a partner'}
                handleButtonClick={handleModal}
                // isLoading={loading}
                //isDisabled={disabled}
                cssClass="primary-button reversed"
              />
              {showRegPart && (
                <RegisterPartner
                  title={x.fields.title}
                  items={x.fields.items}
                  handlefn={handleModal}
                  showMode={showRegPart}
                  cookie={x.fields.cookiesStatement}
                  privacy={x.fields.privacyStatement}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

CardSubpageDiamond.defaultProps = {
  title: '',
  text: '',
  img: 'http://via.placeholder.com/500x500',
  altText: '',
  ctaText: 'Learn more',
  ctaUrl: '#',
  items: [],
};

CardSubpageDiamond.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  altText: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

export default CardSubpageDiamond;
