/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import './sidebarcards.scss';

/**
 * @description to show recruter card in job details
 * @returns {node} html
 */
//const SidebarCardRecruiter = ({ t, fields}) => {
const SidebarCardRecruiter = (props) => {
  const { fields, t, sitecoreContext } = props;
  const brandName = sitecoreContext?.site?.name?.toLowerCase();
  return (
    <div className="recruiter-sidebar">
      <div className="sidebar-title">{t('recruiter')}</div>
      {Array.isArray(fields?.items) &&
        fields.items.map((recruiter, index) => {
          return (
            <div className="sidebar-body" key={index}>
              {recruiter?.recImage && (
                <div className="recruiter-image">
                  <img src={recruiter?.recImage} alt="recruiter" />
                </div>
              )}
              {recruiter?.recruiterName && (
                <div className="recruiter-name">{recruiter.recruiterName}</div>
              )}

              {recruiter?.recruiterPhoneNumber && (
                <a
                  href={`tel: ${recruiter?.recruiterPhoneNumber}`}
                  className={`social-item phone`}
                  aria-label="Phone number opens in a new window/tab"
                >
                  <div className="icon">
                    {/*  <img src={IconPhone} alt="phone icon" /> */}
                    <div className="icon-phone"></div>
                  </div>
                  <div className="text">{recruiter?.recruiterPhoneNumber}</div>
                </a>
              )}

              {brandName != 'experis-germany' && recruiter?.recEmailId && (
                <a
                  href={`mailto: ${recruiter?.recEmailId}`}
                  className={`social-item`}
                  aria-label="Email opens in a new window/tab"
                >
                  <div className="icon">
                    {/* <img src={IconEmail} alt="email icon" />  */}
                    <div className="icon-mail"></div>
                  </div>
                  <div className="text">{recruiter?.recEmailId}</div>
                </a>
              )}

              {recruiter?.linkedinId && (
                <a
                  href={recruiter?.linkedinId}
                  className={`social-item`}
                  aria-label="LinkedIn opens in a new window/tab"
                >
                  <div className="icon">
                    {/*  <img src={IconLn} alt="linkdn icon" /> */}
                    <div className="icon-ln"></div>
                  </div>
                  <div className="text">{recruiter?.linkedinId}</div>
                </a>
              )}
              {recruiter?.recBranchAddress && (
                <div className="address-container">
                  <div className="address">{recruiter?.recBranchAddress}</div>
                  {recruiter?.recBranchLocation && (
                    <div className="address">{recruiter?.recBranchLocation}</div>
                  )}
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

SidebarCardRecruiter.defaultProps = {
  fields: {
    items: [],
  },
  t: () => {},
};

SidebarCardRecruiter.propTypes = {
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  t: PropTypes.func,
};

//export default SidebarCardRecruiter;
export default withSitecoreContext()(withTranslation()(SidebarCardRecruiter));
