import React from 'react';
import PropTypes from 'prop-types';
import TextButton from '../globals/buttons/TextButton';
import { cookiePolicy } from '../../../utils/helperUtils';

const CookieSettings = (props) => {
  const { fields } = props;
  const { title } = fields;
  return (
    title && (
      <div className="title customStyle">
        <TextButton
          cssClass="blue custom"
          handleTextClick={cookiePolicy}
          text={title?.value}
        />
      </div>
    )
  );
};
CookieSettings.defaultProps = {
  fields: {},
};
CookieSettings.propTypes = {
  fields: PropTypes.shape({}),
};
export default CookieSettings;
