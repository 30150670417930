/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import TextLink from '../globals/link/TextLink';
import JobsForm from '../PageBanners/BannerJobs/JobsForm';
import {
  withSitecoreContext,
  isExperienceEditorActive,
} from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
/**
 * @description - HomeCareerBlock component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const HomeCareerBlock = (props) => {
  const { fields, sitecoreContext } = props;
  const {
    title,
    text,
    ctaText,
    ctaUrl,
    findJobsCtaText,
    //  backgroundColor,
    backgroundImage,
    t = () => {},
  } = fields;
  // const background_color = backgroundColor?.value;
  const brand_name = sitecoreContext?.Country?.brandName?.toLowerCase() || 'experis';
  return (
    <section
      className={`home-career-block ${brand_name}`}
      style={{
        backgroundImage:
          'url(' +
          backgroundImage?.value?.src +
          '),' +
          'url(' +
          backgroundImage?.value?.src +
          ')',
      }}
    >
      <div className="bg-img1"></div>
      <div className="bg-img2"></div>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2">
              <h2 className="title">{title?.value}</h2>
              <div className="text">
                <p className="large">{text?.value}</p>
              </div>
              <div className="form">
                {isExperienceEditorActive() ? (
                  <div>{t('job-search-experience-editor-preview')}</div>
                ) : (
                  <JobsForm ctaText={findJobsCtaText?.value} />
                )}
              </div>
              <div className="careerpath cta">
                <TextLink
                  cssClass="reversed"
                  ctaText={ctaText}
                  ctaUrl={ctaUrl}
                  arrow
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

HomeCareerBlock.defaultProps = {
  fields: {
    title: '',
    text: '',
    namePlaceHolder: '',
    locationPlaceHolder: '',
    findJobsCtaText: '',
    ctaText: '#',
    ctaUrl: '',
    submitUrl: '/',
    backgroundImage: '',
  },
  // backgroundColor: '',
};

HomeCareerBlock.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    namePlaceHolder: PropTypes.string.isRequired,
    locationPlaceHolder: PropTypes.string.isRequired,
    findJobsCtaText: PropTypes.string.isRequired,
    ctaText: PropTypes.string,
    ctaUrl: PropTypes.string,
    submitUrl: PropTypes.string.isRequired,
    backgroundImage: PropTypes.string.isRequired,
  }),
  // backgroundColor: PropTypes.string.isRequired,
};

export default withTranslation()(withSitecoreContext()(HomeCareerBlock));
