/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './photoUpload.scss';
import AvatarEditor from 'react-avatar-editor';
import removeIcon from '../../../../../assets/images/icon__close--black.svg';
import Button from '../../../../core/globals/buttons/Button';
import { withTranslation } from 'react-i18next';

/**
 * @description - Photo Upload component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const EditPhoto = (props) => {
  const {
    width,
    height,
    photoUploaded,
    handleImageUpload,
    removePic,
    onCrop,
    photo,
    edit,
    uploadIcon,
    showUpload,
    t,
  } = props;
  const [uploadedPic, setUpload] = useState('');
  const [editedPhoto, setPhoto] = useState('');
  const [showEdit, setEdit] = useState(false);
  const [rotate, setRotate] = useState(0);
  const [zoom, setZoom] = useState(1);
  useEffect(() => {
    setUpload(photoUploaded);
  }, [photoUploaded]);
  useEffect(() => {
    setPhoto(photo);
  }, [photo]);
  useEffect(() => {
    setEdit(edit);
  }, [edit]);
  const setEditorRef = (data) => {
    onCrop(data);
  };
  const openUpload = (event) => {
    event.target.value = '';
  };
  const handleRotate = () => {
    setRotate(rotate + 90);
  };
  const handleZoomSlider = (event) => {
    setZoom(event.target.value);
  };
  const removePicOnFocus = (e) => {
    if (e && (e.keyCode === 13 || e.keyCode === 32)) {
      removePic();
    }
  };
  const rotateOnFocus = (e) => {
    if (e && (e.keyCode === 13 || e.keyCode === 32)) {
      handleRotate();
    }
  };
  return (
    <>
      {!showEdit && (
        <div
          className="photo-modal-container"
          style={{
            backgroundImage: `url(${uploadedPic})`,
            backgroundSize: 'cover',
          }}
        />
      )}
      {showEdit && (
        <div className="avatar-editor">
          <AvatarEditor
            width={width}
            height={height}
            image={editedPhoto}
            className="canvas-container"
            rotate={rotate}
            borderRadius={100}
            scale={zoom}
            ref={setEditorRef}
          />
          <div className="avatar-editor-button">
            <Button
              handleButtonClick={handleRotate}
              text={t('rotate')}
              tabIndex={0}
              onKeyUp={rotateOnFocus}
            />
          </div>
          <div className="avatar-editor-zoom">
            <label>{t('zoom')}</label>
            <input
              type="range"
              min={1}
              max={10}
              step={1}
              value={zoom}
              onChange={handleZoomSlider}
              style={{ width: 200 }}
              tabIndex={0}
            />
          </div>
        </div>
      )}
      <div className="button-container">
        {showUpload && (
          <div tabIndex={0} role="button">
            <label className="button-div" htmlFor="img">
              <div
                className="modal-icon"
                style={{
                  backgroundImage: `url(${uploadIcon})`,
                }}
              />
              {t('upload-photo')}
            </label>
            <input
              type="file"
              id="img"
              name="img"
              accept="image/*"
              className="photo-upload-input"
              onChange={handleImageUpload}
              onClick={openUpload}
            />
          </div>
        )}
        {!showUpload && (
          <div
            className="button-div"
            role="button"
            aria-label={t('remove-photo')}
            onClick={removePic}
            tabIndex={0}
            onKeyUp={removePicOnFocus}
          >
            <div
              className="modal-icon"
              style={{
                backgroundImage: `url(${removeIcon})`,
                marginTop: '10px',
              }}
            ></div>
            {t('remove-photo')}
          </div>
        )}
      </div>
    </>
  );
};

EditPhoto.defaultProps = {
  width: 300,
  height: 300,
};

EditPhoto.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default withTranslation()(EditPhoto);
