import React, { useState, useContext, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  withPlaceholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import JobSearchForm from './JobSearchForm';
import SiteSearchForm from './SiteSearchForm';
import { HeaderContext } from '../../SiteHeader/HamburgerMenu/HeaderContext';

import { isDataExists } from '../../../../utils/helperUtils';

import '../search.scss';
import '../search-filters.scss';

/**
 * @description - Job search tab.
 * @param {object} props - Input props.
 * @returns {Node} - Search jobs tab.
 */
const JobSearch = ({ urlVal }) => {
  const searchVals = useContext(SearchContext) || {};
  const { mode, setMode, t, isInHeader } = searchVals;
  const history = useHistory();
  const params = new URLSearchParams();

  /**
   * @description - Set tab as job.
   * @returns {undefined}
   */
  const setJobOptions = () => {
    setMode('jobs');
    if (!isInHeader) {
      history.replace({
        pathname: urlVal,
        search: String(params),
      });
      if (window?.dataLayer) {
        window.dataLayer.push({
          event: 'PageLoad',
          page: urlVal,
          pageTitle: t('search-jobsearch'),
        });
      }
    }
  };

  return (
    <div
      className={`tab ${mode !== 'site' ? 'active' : ''}`}
      data-search-option="search-jobs"
      onClick={setJobOptions}
      role="tab"
      aria-selected={mode !== 'site'}
      aria-controls={`search-job-${isInHeader && 'header'}`}
      id="search-job-tab"
      aria-label="Job Search Tab"
      tabIndex={0}
      onKeyUp={(e) => {
        if (e && e.keyCode === 13) setJobOptions();
      }}
    >
      {isInHeader ? (
        t('search-jobsearch')
      ) : (
        <h1 className="h1tag">{t('search-jobsearch')}</h1>
      )}
    </div>
  );
};

/**
 * @description - Site search tab.
 * @param {object} props - Input props.
 * @returns {Node} - Search site tab.
 */
const SiteSearch = ({ urlVal }) => {
  const searchVals = useContext(SearchContext) || {};
  const { mode, setMode, t, isInHeader } = searchVals;
  const history = useHistory();
  const params = new URLSearchParams();
  params.set('mode', 'site');

  /**
   * @description - Set tab as site.
   * @returns {undefined}
   */
  const setSiteOptions = () => {
    setMode('site');
    if (!isInHeader) {
      history.replace({
        pathname: urlVal,
        search: String(params),
      });
      if (window?.dataLayer) {
        window.dataLayer.push({
          event: 'PageLoad',
          page: urlVal,
          pageTitle: t('search-searchsite'),
        });
      }
    }
  };

  return (
    <div
      className={`tab ${mode === 'site' ? 'active' : ''}`}
      data-search-option="search-everything"
      onClick={setSiteOptions}
      role="tab"
      aria-selected={mode === 'site'}
      aria-controls={`search-site-${isInHeader && 'header'}`}
      aria-label="Site Search Tab"
      id="search-site-tab"
      tabIndex={0}
      onKeyUp={(e) => {
        if (e && e.keyCode === 13) setSiteOptions();
      }}
    >
      {t('search-searchsite')}
    </div>
  );
};

const SearchContext = React.createContext({
  mode: '',
  setMode: () => null,
  isInHeader: false,
  t: (val) => val,
});

/**
 * @description - Search Header tabs for site and job search.
 * @param {object} props - Input props.
 * @returns {Node} - Search header tabs and forms.
 */
const SearchHeader = (props) => {
  const { fields, location, t, filterComp, brandName, sitecoreContext } = props;
  const { isInHeader } = useContext(HeaderContext) || {};
  const { searchPage } = fields;
  const regexValidationPattern = sitecoreContext?.Country?.regExPattern || '';
  const params = new URLSearchParams(location?.search);
  const paramMode = params.get('mode') || '';
  const modeVal = isInHeader ? '' : paramMode;
  const [mode, setMode] = useState(modeVal);
  const isFilterExists = isDataExists(filterComp);
  const url = sitecoreContext?.Country?.jobSearchURL || '';
  const urlVal = url.split('?')[0];

  useEffect(() => {
    setMode(paramMode);
  }, [paramMode]);

  return (
    <section className={`search-header ${brandName}`}>
      <div className="container">
        <div className="row">
          <div
            className={
              isInHeader
                ? 'col-lg-12'
                : 'col-lg-12 offset-lg-0 col-xl-11 offset-xl-1'
            }
          >
            <div className="search-options" role="tablist">
              <SearchContext.Provider value={{ mode, setMode, isInHeader, t }}>
                <JobSearch urlVal={urlVal} />
                <SiteSearch urlVal={urlVal} />
              </SearchContext.Provider>
            </div>
            {mode !== 'site' ? (
              <JobSearchForm
                mode={mode}
                t={t}
                location={location}
                urlVal={searchPage?.value?.href}
                isFilterExists={isFilterExists}
                defaultRange={20}
              />
            ) : (
              <SiteSearchForm
                mode={mode}
                urlVal={searchPage?.value?.href}
                isInHeader={isInHeader}
                t={t}
                location={location}
                regexValidationPattern={regexValidationPattern}
              />
            )}
          </div>
        </div>
      </div>
      {isDataExists(filterComp) && mode !== 'site'
        ? filterComp.map((component) => component)
        : null}
    </section>
  );
};

SearchHeader.defaultProps = {
  btnText: 'Search Jobs',
  jobFormSubmitUrl: '/',
  fields: {},
  filterComp: [],
  t: (val) => val,
  brandName: '',
};

SearchHeader.propTypes = {
  btnText: PropTypes.string,
  jobFormSubmitUrl: PropTypes.string,
  fields: PropTypes.shape({
    searchPage: PropTypes.shape({}),
  }).isRequired,
  t: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
  filterComp: PropTypes.arrayOf(PropTypes.node),
  brandName: PropTypes.string,
  sitecoreContext: PropTypes.shape({}).isRequired,
};

export default withRouter(
  withPlaceholder([{ placeholder: 'jss-content', prop: 'filterComp' }])(
    withTranslation()(withSitecoreContext()(SearchHeader))
  )
);
