import React, { useState, useEffect } from 'react';

/**
 *
 * @param {*} props
 */
const FocusWrapper = ({ children }) => {
  const [outline, setOutline] = useState(false);

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    document.addEventListener('mousedown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
      document.removeEventListener('mousedown', handleKeydown);
    };
  }, []);

  /**
   *
   * @param {*} e
   */
  const handleKeydown = (e) => {
    // Detect a keyboard user from a tab key press (also down and up arrow key press)
    if (e) {
      const isTabEvent = e.keyCode === 9 || e.keyCode === 40 || e.keyCode === 38;

      if (isTabEvent) {
        setOutline(true);
      } else {
        setOutline(false);
      }
    }
  };

  return <div className={!outline ? 'hide_outline' : ''}>{children}</div>;
};

export default FocusWrapper;
