import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import FilterItem from '../../core/Filters/FilterItem';
import { FilterContext } from '../Contexts';
import {
  getFilterArray,
  getSubTopicFilterArray,
} from '../../../factory/insightsFactory';
import { getSubTopicsList, getTopicsList } from '../../../services';
// import IconDown from '../../../assets/images/icon-down.svg';

import './articlefilters.scss';

/**
 * @description - Sub Topic filter component.
 * @returns {Node} - HTML filter.
 */
const TopicFilter = () => {
  const filterVals = useContext(FilterContext) || {};
  const { contentType, topic, subTopic, setSubTopic, t, location } =
    filterVals || {};
  let subTopicOptions = [];
  const { error: subTopicError, data: subTopicData = {} } = getSubTopicsList(
    contentType || '*',
    topic
  );
  if (topic) {
    subTopicOptions = subTopicError
      ? []
      : getSubTopicFilterArray(subTopicData?.subTopics?.facets, t('all'), topic);
  }
  return (
    <div className="sub-row" aria-disabled={true}>
      <FilterItem
        text={t('subtopic')}
        iconAfter={<div className="down-arrow" />}
        dropdown="sort"
        dropdownSize="small"
        dropdownOptions={subTopicOptions}
        emptyText={t('subtopic')}
        actionParams={{ setSubTopic }}
        clickHandler={handleSubTopicChange}
        selectedOption={subTopic}
        selectedValue={location?.query?.subTopic?.name}
      />
    </div>
  );
};

/**
 * @description - Handle ContentType filter change.
 * @param {object} option - Selected option
 * @param {object} filterVals - Filter params.
 * @returns {undefined}
 */
const handleContentTypeChange = (option, filterVals) => {
  const { setContentType, topic, setTopic, subTopic, setSubTopic } = filterVals;
  setContentType(option.id);
  if (topic) {
    setTopic(null);
  }
  if (subTopic) {
    setSubTopic(null);
  }
};

/**
 * @description - Handle Topic filter change.
 * @param {object} option - Selected option
 * @param {object} filterVals - Filter params.
 * @returns {undefined}
 */
const handleTopicChange = (option, { setTopic, subTopic, setSubTopic }) => {
  setTopic(option.id);
  if (subTopic) {
    setSubTopic(null);
  }
};

/**
 * @description - Handle SubTopic filter change.
 * @param {object} option - Selected option
 * @param {object} filterVals - Filter params.
 * @returns {undefined}
 */
const handleSubTopicChange = (option, { setSubTopic }) => {
  setSubTopic(option.id);
};

/**
 * @description - Filters for insights card blocks.
 * @param {object} props - Input props.
 * @returns {Node} - HTML filter items.
 */
const ArticleFilters = (props) => {
  const { t, fields } = props;
  const filterVals = useContext(FilterContext) || {};
  const { contentType, topic, setTopic, subTopic, setSubTopic } = filterVals;
  const { location } = filterVals;
  const contentTypeOptions = getFilterArray(
    fields?.data?.contentTypes?.facets,
    t('all')
  );
  let topicOptions = getFilterArray(fields?.data?.topics?.facets, t('all'));

  const { error: topicError, data: topicData = {} } = getTopicsList(contentType);
  if (contentType) {
    topicOptions = topicError
      ? []
      : getFilterArray(topicData?.customSearch?.facets, t('all'));
  }

  return (
    <section className="article-filters">
      <div className="filter-row">
        <div className="filter-label">{t('filter')}:</div>
        <FilterItem
          text={t('content-type')}
          iconAfter={<div className="down-arrow" />}
          dropdown="sort"
          dropdownSize="small"
          dropdownOptions={contentTypeOptions}
          emptyText={t('content-type')}
          actionParams={filterVals}
          clickHandler={handleContentTypeChange}
          selectedOption={contentType}
          selectedValue={location?.query?.contentType?.name}
        />
        <FilterItem
          text={t('topic')}
          iconAfter={<div className="down-arrow" />}
          dropdown="sort"
          dropdownSize="small"
          dropdownOptions={topicOptions}
          emptyText={t('topic')}
          actionParams={{ setTopic, subTopic, setSubTopic }}
          clickHandler={handleTopicChange}
          selectedOption={topic}
          selectedValue={location?.query?.topic?.name}
        />
      </div>
      <TopicFilter />
    </section>
  );
};

ArticleFilters.defaultProps = {
  subTopicOptions: [],
  fields: {},
  t: () => null,
};

ArticleFilters.propTypes = {
  subTopicOptions: PropTypes.arrayOf(PropTypes.shape({})),
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    data: PropTypes.shape({
      ContentTypes: PropTypes.arrayOf(PropTypes.shape({})),
      Topics: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default ArticleFilters;
