import { createGlobalStyle } from 'styled-components';

export const HubSpotStyle = createGlobalStyle`
.newsletter-footer .hubspotForm{
    .hubspotformTitle {
      color: ${({ theme }) => theme?.footerText} !important;
    }
    .hs-submit{
      input {
        display: block;
        text-align: center;
        border: 2px solid;
        border-color: ${({ theme }) => theme?.primaryButton};
        background-color: #fff;
        color: ${({ theme }) => theme?.primaryButton};
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1.2px;
        line-height: 1.2;
        padding: 10px;
        transition: .25s cubic-bezier(.215,.61,.355,1);
        width: auto!important;
        border-radius: 26px;
        &:hover {
          background-color: ${({ theme }) => theme?.primaryButton ?? '#386097'};
          color: #fff;
        }
      }
    }
    .hs-richtext {
      margin-bottom: 3px;
      color: #83858B;
      p {
        font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
        font-size: 14px;
        line-height: 24px;
        margin-top: 30px;
        margin-bottom: 10px;
        a {
          color: ${({ theme }) => theme?.secondaryButton};
        }
      }
    }
    .hs-form {
      margin-bottom: 3px;
      font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
      line-height: 24px;
      font-size: 14px;
      color: #83858B;
    }
    .hs-form-required {
      color: red !important;
      display: inline !important;
      margin-left: 3px !important;
    }
    label.hs-main-font-element {
      display: none;
    }
    .hs_error_rollup {
      display: none;
    }
    .hs-custom-style {
      box-sizing: border-box;
      padding: 0 15px;
      min-height: 27px;
    }
    .hs-input {
      display: inline-block;
      width: 100%;
      max-width: 500px;
      height: 40px;
      padding: 9px 10px;
      font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
      font-size: 16px;
      font-weight: normal;
      line-height: 22px;
      color: #83858B;
      border: 1px solid #cbd6e2;
      border-radius: 3px;
      &:focus {
        border: 2px solid #52a8eccc;
      }
    }
    .hs-error-msgs {
      padding-left: 0;
      li {
        list-style: none;
      }
      label {
        padding: 15px !important;
        font-family: "Helvetica Neue Reg";
        font-size: 13px;
        line-height: 1;
        color: #9d323d;
        background-color: rgba(157,50,61,.25);
      }
    }
    .hs-form-field label {
      font-family: helvetica;
      font-size: 13px;
    }
    .inputs-list label {
        display: block;
        float: none;
        width: auto;
        padding: 0;
        line-height: 14px;
        text-align: left;
        white-space: normal;
        font-weight: normal;
        margin: 0px 0px 4px;
    }
    ul {
      padding-left: 0px;
      margin-bottom: 8px;
      .hs-form-booleancheckbox {
        list-style: none;
      }
    }
    input.hs-input.error, input.hs-input.error:focus, div.field.error input:focus, div.field.error textarea:focus, div.field.error .chzn-choices:focus, textarea.hs-input.error:focus, select.hs-input.error:focus {
      border: 2px solid #b9554d;
    }
    .hs-input[type=checkbox], .hs-input[type=radio] {
      cursor: pointer;
      width: auto;
      height: auto;
      padding: 0px;
      margin-right: 5px;
      line-height: normal;
      border: none;
      float: left;
      margin-top: 3px;
    }
    .hs-form-booleancheckbox-display span { 
      font-size: 14px;
      color: #83858B;
      font-family: helvetica;
      margin-left: 20px;
      display: block;
      line-height: 1.3;
    }
    .submitted-message p { 
      font-size: 14px;
      color: #83858B;
      font-family: helvetica;
      margin: 15px 0px 0px 0px;
    }
  }
.newsletter-hamburger .hubspotForm {
  .hs-submit{
    input {
      display: block;
      text-align: center;
      border: 2px solid;
      border-color: #fff;
      background-color: #fff;
      color: ${({ theme }) => theme?.primaryButton};
      font-family: "Inter";
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1.2px;
      line-height: 1.2;
      padding: 10px;
      transition: .25s cubic-bezier(.215,.61,.355,1);
      width: auto!important;
      border-radius: 26px;
        &:hover {
          color: #fff;
          background-color: ${({ theme }) => theme?.primaryButton};
        }
    }
  }
  .hs-richtext {
    margin-bottom: 3px;
    color: #fff;
    p {
      font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
      font-size: 14px;
      line-height: 24px;
      margin-top: 30px;
      margin-bottom: 10px;
      a {
        color: ${({ theme }) => theme?.primaryColor};
        font-weight: 600;
      }
    }
  }
  .hs-form {
    margin-bottom: 3px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    line-height: 24px;
    font-size: 14px;
    color: #fff;
  }
  .hs-form-required {
    color: red !important;
    display: inline !important;
    margin-left: 3px !important;
  }
  label.hs-main-font-element {
    display: none;
  }
  .hs_error_rollup {
    display: none;
  }
  .hs-custom-style {
    box-sizing: border-box;
    padding: 0 15px;
    min-height: 27px;
  }
  .hs-input {
    display: inline-block;
    width: 100%;
    max-width: 500px;
    height: 40px;
    padding: 9px 10px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
    color: #83858B;
    border: 1px solid #cbd6e2;
    border-radius: 3px;
    &:focus {
      border-color: 2px solid #52a8eccc;
    }
  }
  .hs-error-msgs {
    padding-left: 0;
    li {
      list-style: none;
    }
    label {
      background-color: hsla(0,0%,100%,.6);
      padding: 15px !important;
      font-family: "Helvetica Neue Reg";
      font-size: 13px;
      line-height: 1;
      color: #9d323d;
    }
  }
  .hs-form-field label {
    font-family: helvetica;
    font-size: 13px;
  }
  .inputs-list label {
      display: block;
      float: none;
      width: auto;
      padding: 0;
      line-height: 14px;
      text-align: left;
      white-space: normal;
      font-weight: normal;
      margin: 0px 0px 4px;
  }
  ul {
    padding-left: 0px;
    margin-bottom: 8px;
    .hs-form-booleancheckbox {
      list-style: none;
    }
  }
  input.hs-input.error, input.hs-input.error:focus, div.field.error input:focus, div.field.error textarea:focus, div.field.error .chzn-choices:focus, textarea.hs-input.error:focus, select.hs-input.error:focus {
    border: 2px solid #b9554d;
  }
  .hs-input[type=checkbox], .hs-input[type=radio] {
    cursor: pointer;
    width: auto;
    height: auto;
    padding: 0px;
    margin-right: 5px;
    line-height: normal;
    border: none;
    float: left;
    margin-top: 3px;
  }
  .hs-form-booleancheckbox-display span { 
    font-size: 14px;
    color: #fff;
    font-family: helvetica;
    margin-left: 20px;
    display: block;
    line-height: 1.3;
  }
  .submitted-message p { 
    font-size: 14px;
    color: #fff;
    font-family: helvetica;
    margin: 15px 0px 0px 0px;
  }
  .justify-content-center {
    color: #fff;
  }
}
.contact-us .hubspotForm {
  .hs-richtext {
    margin-bottom: 3px;
    color: #83858B;
    p {
      font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
      font-size: 14px;
      line-height: 24px;
      margin-top: 30px;
      margin-bottom: 10px;
    }
    a {
      color: ${({ theme }) => theme?.secondaryButton};
    }
  }
  .hs-submit{
    input {
      display: block;
      text-align: center;
      border: 2px solid;
      border-color: ${({ theme }) => theme?.secondaryButton};
      background-color: #fff;
      color: ${({ theme }) => theme?.secondaryButton};
      font-family: "Inter";
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1.2px;
      line-height: 1.2;
      border-radius: 26px;
      padding: 19px 20px 17px;
      transition: .25s cubic-bezier(.215,.61,.355,1);
      width: auto !important;
      height: auto !important;
      margin: 0 auto;
      &:hover {
        background-color: ${({ theme }) => theme?.secondaryButton};
        color: #fff;
      }
    }
  }
  .hs-form {
    margin-bottom: 3px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    line-height: 24px;
    font-size: 14px;
    color: #83858B;
  }
  .hs-form-required {
    color: red !important;
    display: contents !important;
  }
  label.hs-main-font-element {
    display: none;
  }
  .hs_error_rollup {
    display: none;
  }
  .hs-custom-style {
    box-sizing: border-box;
    padding: 0 15px;
    min-height: 27px;
  }
  .hs-input {
    display: inline-block;
    width: 90%;
    max-width: 500px;
    height: 40px;
    padding: 9px 10px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
    color: #83858B;
    border: 1px solid #cbd6e2;
    border-radius: 3px;
    &:focus {
      border: 2px solid #52a8eccc;
    }
  }
  .hs-error-msgs {
    padding-left: 0;
    padding-right: 2rem;
    li {
      list-style: none;
    }
    label {
      padding: 15px !important;
      font-family: "Helvetica Neue Reg";
      font-size: 13px;
      line-height: 1;
      color: #9d323d;
      background-color: rgba(157,50,61,.25);
    }
  }
  .hs-form-field label {
    font-family: helvetica;
    font-size: 13px;
  }
  .inputs-list label {
      display: block;
      float: none;
      width: auto;
      padding: 0;
      line-height: 14px;
      text-align: left;
      white-space: normal;
      font-weight: normal;
      margin: 0px 0px 4px;
  }
  ul {
    padding-left: 0px;
    margin-bottom: 8px;
    .hs-form-booleancheckbox {
      list-style: none;
    }
  }
  input.hs-input.error, input.hs-input.error:focus, div.field.error input:focus, div.field.error textarea:focus, div.field.error .chzn-choices:focus, textarea.hs-input.error, textarea.hs-input.error:focus, select.hs-input.error, select.hs-input.error:focus {
    border: 2px solid #b9554d;
  }
  .hs-input[type=checkbox], .hs-input[type=radio] {
    cursor: pointer;
    width: auto;
    height: auto;
    padding: 0px;
    margin-right: 5px;
    line-height: normal;
    border: none;
    float: left;
  }
  .hs-form-booleancheckbox-display span { 
    font-size: 14px;
    color: #83858B;
    font-family: helvetica;
    margin-left: 20px;
    display: block;
  }
  .submitted-message p { 
    font-size: 14px;
    color: #83858B;
    font-family: helvetica;
    margin: 15px 0px 0px 0px;
  }
  .form-columns-1, .form-columns-2 {
    margin-bottom: 5px;
  }
  .hubspot-render {
    display: flex !important;
    justify-content: center;
    margin-bottom: 20px;
  }
  .hs-form-field label {
    margin-bottom: 0px;
  }
  .hs-form {
    input,select { 
      margin-block:24px 8px;
      height: 64px;
    }
    textarea {
      height: 200px;
    }
    textarea::placeholder {
      font-size: 16px;
    }
  }
} 
`;
