/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable require-jsdoc */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { dataFetcher } from '../../../../dataFetcher';
import Modal from '../../Modal';
import { useForm } from 'react-hook-form';
import Button from '../../globals/buttons/Button';
import DynamicForm from '../DynamicForm';
import './RegisterPartner.scss';
import { REGISTER_PARTNER } from '../../../../constants';
//import Checkbox from '../../globals/forms/Checkbox';
//import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
//import { replaceTextwithJSX } from '../../../../utils/helperUtils';

/**
 * @description - to show inline alert
 * @param {string} message - message to show
 * @param {string} type - type of alert
 * @returns {node} - html
 */
// eslint-disable-next-line max-lines-per-function
const RegisterPartner = (props) => {
  /**
   * @description to handle alert pop up interaction
   * @param {*} action - true/false
   * @returns {undefined} - no retunrs
   */

  //const { items, title, showMode, handlefn, cookie, privacy, t } = props;
  const { items, title, showMode, handlefn, t } = props;
  //const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState(false);
  //const [showPrivacy, setShowPrivacy] = useState(false);
  //const [showCookie, setShowCookie] = useState(false);
  const [isRegistered, setisRegistered] = useState(false);
  //const [consenttext, setConsentText] = useState('');
  const submitButtonRef = useRef();
  const hiddenInputField = useRef();

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    control,
    reset,
  } = useForm();
  /*
  const handlePrivacy = () => {
    setConsentText(cookie.value);
    setShowPrivacy(!showPrivacy);
    setShowCookie(false);
  };

  const handleCookie = () => {
    setConsentText(privacy.value);
    setShowCookie(!showCookie);
    setShowPrivacy(false);
  };*/

  const onSubmit = (data) => {
    data.checkField = hiddenInputField.current.value;
    const formData = Object.assign({}, data);
    setLoading(true);

    const postData = {
      ...formData,
    };
    if (document.activeElement.type === 'submit') {
      dataFetcher(REGISTER_PARTNER.POST_URL, 'POST', postData).then(
        (response) => {
          setLoading(false);
          setisRegistered(true);

          if (response?.data?.success && response?.data?.success === 'true') {
            //setMessage('success');
            setErrorCode(false);
            //To rest form
            reset();
          } else {
            // setMessage('error');
            setErrorCode(true);

            reset();
          }
          //document.getElementById('formTitle').scrollIntoView();
        },
        () => {
          setisRegistered(true);
          setLoading(false);
          //setMessage('error');
          setErrorCode(true);
          reset();
          //document.getElementById('formTitle').scrollIntoView();
        }
      );
    }
  };

  /*
  const text = '{0}';
  const conscentText = replaceTextwithJSX(
    text,
    '{0}',
    <div>
      <span className="consent">
        {t('confirm-policy')}&nbsp;
        <span className="privacy" onClick={handlePrivacy}>
          {t('privacy-statements')}
        </span>
        &nbsp;{t('and')}&nbsp;
        <span className="cookie" onClick={handleCookie}>
          {t('cookie-policy')}
        </span>
      </span>
    </div>
  );*/

  return (
    <Modal
      dialogClassName="react-register-modal"
      showModal={showMode}
      handleModal={handlefn}
    >
      <div
        className="inner-modal-container register-partner header-cont"
        key="header"
      >
        {!isRegistered && <div className="title">{title.value}</div>}
      </div>
      <div className="inner-modal-container register-partner" key="body">
        {!isRegistered ? (
          <>
            <form>
              <div>
                <div className="row">
                  <div className="title"></div>
                  {items.map((item, index) => (
                    <div key={index} className={'col-lg-12'}>
                      <div className="title">{item?.fields?.title?.value}</div>

                      <DynamicForm
                        formData={item?.fields?.items}
                        register={register}
                        errors={errors}
                        getValues={getValues}
                        setValue={setValue}
                        control={control}
                      />
                    </div>
                  ))}
                </div>
                <div className="partner-consent">
                  {/* <div className="check-consent">
                    <Checkbox
                      name="regpart"
                      // text="I consent to receive communications about my job application in accordance with Experis’ Privacy Policy. I understand that I can opt-out at any time."
                      customLabel={conscentText}
                      required={true}
                      register={register}
                      //showBasedOnFieldName={true}
                      errors={errors}
                      // ref={register({ required: true })}
                      // ref={register({ required: 'This is required' })}
                    />
                  </div> */}

                  <div className="submit-button">
                    <Button
                      text={t('register-partner')}
                      handleButtonClick={handleSubmit(onSubmit)}
                      type={'submit'}
                      ref={submitButtonRef}
                      isLoading={loading}
                    ></Button>
                  </div>
                </div>
              </div>
              <input
                className="check-input-field"
                type="text"
                ref={hiddenInputField}
              />
            </form>
            <div className="privacy-cookie-cont">
              {/*showPrivacy || showCookie ? (
                <div className="privacy-cookie-text">
                  <RichText field={{ value: consenttext }} tag="div" />
                </div>
              ) : null*/}
            </div>
          </>
        ) : (
          <>
            {!errorCode && <div className="alert-title">{t('thank-you')}</div>}
            {!errorCode && (
              <div className="alert-description">{t('success-msg')}</div>
            )}
            {errorCode && <div className="alert-description">{t('error-msg')}</div>}

            <div className="success-page-btn">
              <Button text={t('ok')} handleButtonClick={handlefn} />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

RegisterPartner.propTypes = {
  title: PropTypes.string,
  items: [],
  handlefn: () => {},
  showMode: false,
  // privacy: '',
  // cookie: '',
  t: (val) => val,
};

RegisterPartner.defaultProps = {
  title: '',
  items: PropTypes.arrayOf(PropTypes.shape({})),
  handlefn: PropTypes.func.isRequired,
  showMode: PropTypes.bool,
  //cookie: PropTypes.string,
  //privacy: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(RegisterPartner);
