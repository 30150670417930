import { createGlobalStyle } from 'styled-components';

export const BannerJobsStyle = createGlobalStyle`
.banner-section{
    &.jobs{
        background-color: ${({ theme }) => theme?.primaryColor};
        .title{
            color: ${({ theme }) => theme?.heading};
        }
        p {
            color: ${({ theme }) => theme?.normalText};
        }
    } 
}
`;
