/*eslint-disable*/
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import MenuLink from '../../globals/MenuLink';
import {
  isDataExists,
  checkArray,
  checkDataExists,
  pushNavToDataLayer,
} from '../../../../utils/helperUtils';
import variables from '../../../../styles/scss_variables/variables.scss';

/**
 * @description - Submenu items.
 * @param {object} props - Input params.
 * @returns {Node} - HTML links.
 */
const SubNavigationItems = (props) => {
  const { subItems, subTitleClass } = props;
  return (
    <Fragment>
      {checkArray(subItems).map((subItem, index) => {
        const { url: subUrl, title: subTitle, items: l3Items, isHighlighted } =
          subItem.fields || {};
        const isSubTitle = isDataExists(l3Items) || checkDataExists(isHighlighted);
        const isLast = subItems.length === index + 1;
        return (
          <section className="slideout-nav-block" key={subItem?.name}>
            <nav
              className={classNames({
                'no-padding': !isSubTitle,
                'last-sub-tile': isLast,
              })}
            >
              <MenuLink
                url={subUrl}
                routeName={subTitle}
                noSpan
                activeLinkClass={`${subTitleClass} ${isSubTitle ? 'sub-title' : ''}`}
                clickHandler={pushNavToDataLayer}
                actionParams={['Hamburger Nav', subTitle?.value]}
              />
              {/* {subUrl?.value?.linktype === 'external' ? (
                <a
                  href={subUrl?.value?.href}
                  className={`${subTitleClass} ${isSubTitle ? 'sub-title' : ''}`}
                  target={subUrl?.value?.target}
                >
                  {subTitle?.value}
                </a>
              ) : (
                <Link
                  key={subItem?.name}
                  to={subUrl?.value?.href}
                  className={`${subTitleClass} ${isSubTitle ? 'sub-title' : ''}`}
                >
                  {subTitle?.value}
                </Link>
              )} */}

              {isDataExists(l3Items) ? (
                <Fragment>
                  {checkArray(l3Items).map((l3Item) => {
                    const { url: l3Url, title: l3Title } = l3Item.fields || {};
                    return (
                      <MenuLink
                        key={l3Item?.name}
                        url={l3Url}
                        routeName={l3Title}
                        noSpan
                        clickHandler={pushNavToDataLayer}
                        actionParams={['Hamburger Nav', l3Title?.value]}
                      />
                      // <Link key={l3Item?.name} to={l3Url?.value?.href}>
                      //   {l3Title?.value}
                      // </Link>
                    );
                  })}
                </Fragment>
              ) : null}
            </nav>
          </section>
        );
      })}
    </Fragment>
  );
};

SubNavigationItems.propTypes = {
  subItems: PropTypes.arrayOf(PropTypes.shape({})),
  subTitleClass: PropTypes.string.isRequired,
};

SubNavigationItems.defaultProps = {
  subItems: [],
};

/**
 * @description - Navigation Blocks.
 * @param {object} props - Input props;
 * @returns {Node} - HTML Links.
 */
const NavBlock = (props) => {
  const { section, onChangeValue, items, subTitleClass, t } = props;
  const history = useHistory();

  /**
   * @description - Handle menu link click.
   * @param {boolean} hasSub - Has sub menus.
   * @param {string} name - Route name.
   * @param {string} route - Route url.
   * @returns {undefined}
   */
  const handleClick = (hasSub, name, route) => {
    if (window.innerWidth > variables.tablet_width_nopx) {
      triggerNavigation(route, name);
      return;
    }
    if (hasSub) {
      onChangeValue(name);
    } else {
      triggerNavigation(route, name);
    }
  };

  /**
   *
   * @param {*} route
   */
  const triggerNavigation = (route, name) => {
    const routeVal = route?.href;
    pushNavToDataLayer('Hamburger Nav', name);
    if (route?.linktype === 'external') {
      if (route?.target) window.open(routeVal, route?.target);
      else window.location.assign(routeVal);
    } else {
      history.push(routeVal);
    }
  };

  /**
   * @description - Handle action on enter.
   * @param {object} e - Event object.
   * @param {boolean} hasSub - Has submenu.
   * @param {string} name - Menu name.
   * @param {string} route - Route url.
   * @returns {undefined}
   */
  const onEnter = (e, hasSub, name, route) => {
    if (e && e.keyCode === 13) {
      handleClick(hasSub, name, route);
    }
  };

  /**
   * @description - On Back button click.
   * @returns {undefined}
   */
  const handleBackClick = () => {
    onChangeValue('back');
  };

  /**
   * @description - On back button click using enter.
   * @param {object} e - Event object.
   * @returns {undefined}
   */
  const onBackEnter = (e) => {
    if (e && e.keyCode === 13) {
      handleBackClick();
    }
  };

  return (
    <Fragment>
      {items.map((item) => {
        const { title, items: subItems, url } = item.fields || {
          title: {},
          items: [],
        };
        const hasSubItem = isDataExists(subItems);
        const isActive = section === item.name;
        return (
          <section
            key={item.name}
            className={classNames('slideout-nav-block', 'main-slide-nav', {
              active: isActive,
              'not-active': section && section !== item.name,
            })}
          >
            <div
              className={classNames('title', {
                'no-arrow': !hasSubItem,
              })}
            >
              {title && (
                <div
                  className="title-text"
                  onClick={() => handleClick(hasSubItem, item.name, url?.value)}
                  role="link"
                  tabIndex={0}
                  onKeyUp={(e) => onEnter(e, hasSubItem, item.name, url?.value)}
                >
                  {title?.value}
                </div>
              )}
              <span
                className="back-text"
                onClick={handleBackClick}
                role="button"
                tabIndex={0}
                onKeyUp={onBackEnter}
              >
                {t('back')}
              </span>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Link to={url?.value?.href} className="nav-title">
                  {title?.value}
                </Link>
                <SubNavigationItems
                  subItems={subItems}
                  subTitleClass={subTitleClass}
                />
              </div>
            </div>
          </section>
        );
      })}
    </Fragment>
  );
};

NavBlock.propTypes = {
  section: PropTypes.string,
  onChangeValue: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  subTitleClass: PropTypes.string.isRequired,
};

NavBlock.defaultProps = {
  section: '',
  items: [],
};

export default withTranslation()(NavBlock);
