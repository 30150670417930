/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import Button from '../../../core/globals/buttons/Button';
import Modal from '../../../core/Modal';

const RouteLeavingGuard = ({ when, t, navigate, shouldBlockNavigation }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    closeModal();
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal showModal={modalVisible} handleModal={closeModal} size="md">
        <div className="inner-modal-container" key="body">
          {t('you-will-loose-do-you-wish-to-continue')}
          <div className="confirm-button-container">
            <Button
              cssClass="yes-button"
              handleButtonClick={handleConfirmNavigationClick}
              text={t('yes')}
            />
            <Button handleButtonClick={closeModal} text={t('no')} />
          </div>
        </div>
      </Modal>
    </>
  );
};
export default RouteLeavingGuard;
