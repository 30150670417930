import { createGlobalStyle } from 'styled-components';

export const BuildProfileStyle = createGlobalStyle`
.buildProfileAccordion{
    .build-profile{
        .subTextContainer{
            a{
                color: ${({ theme }) => theme?.primaryButton} !important;
            }
        }
        .blue-enabled{
            background-color: ${({ theme }) => theme?.primaryButton} !important;
        }
        .white-disabled {
            &:hover {
                color: ${({ theme }) => theme?.primaryButton} !important;
            }
        }
        .profile-skills{
            .skills-list{
                .checkbox {
                    &.checkbox--select {
                        &:before {
                            background-color: ${({ theme }) =>
                              theme?.primaryButton} !important;
                        }
                    }
                }
            }
        }
    } 
    .coverWrapper {
        .file-name {
            color: ${({ theme }) => theme?.primaryButton} !important;
        }
    }
} 
`;
