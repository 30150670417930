/* eslint-disable */
import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const LEFT_ARROW = 'LEFT_ARROW';
const RIGHT_ARROW = 'RIGHT_ARROW';

/**
 * @description Helper method for creating a range of numbers
 * @param {number} from - range starts
 * @param {number} to - range ends
 * @param {number} step - step to generate
 * @returns {Array} - range of numbers array
 */
const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

/**
 * @description - to fetch page numbers to show
 * @param {*} index - selected page index
 * @param {*} pageNeighbours - offset to calculate page numbers
 * @param {*} totalPages - total pages
 * @param {*} setCurrentIndex - pivot to show page numbers
 * @returns {Array} - array of page numbers
 */
const fetchPageNumbers = (index, pageNeighbours, totalPages, setCurrentIndex) => {
  /**
   * totalNumbers: the total page numbers to show on the control
   * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
   */
  const totalNumbers = pageNeighbours * 2 + 3;
  const totalBlocks = totalNumbers + 2;

  if (totalPages > totalBlocks) {
    const startPage = Math.max(2, index - pageNeighbours);
    const endPage = Math.min(totalPages - 1, index + pageNeighbours);
    setCurrentIndex(index);

    let noOfPages = range(startPage, endPage);

    /**
     * hasLeftSpill: has hidden pages to the left
     * hasRightSpill: has hidden pages to the right
     * spillOffset: number of hidden pages either to the left or to the right
     */
    const hasLeftSpill = startPage > 2;
    const hasRightSpill = totalPages - endPage > 1;
    const spillOffset = totalNumbers - (noOfPages.length + 1);

    switch (true) {
      // handle: (1) ... {5 6} [7] {8 9} (10)
      case hasLeftSpill && !hasRightSpill: {
        const extraPages = range(startPage - spillOffset, startPage - 1);
        noOfPages = [LEFT_PAGE, ...extraPages, ...noOfPages];
        break;
      }

      // handle: (1) {2 3} [4] {5 6} ... (10)
      case !hasLeftSpill && hasRightSpill: {
        const extraPages = range(endPage + 1, endPage + spillOffset);
        noOfPages = [...noOfPages, ...extraPages, RIGHT_PAGE];
        break;
      }

      // handle: (1) ... {4 5} [6] {7 8} ... (10)
      case hasLeftSpill && hasRightSpill:
      default: {
        noOfPages = [LEFT_PAGE, ...noOfPages, RIGHT_PAGE];
        break;
      }
    }

    return [1, ...noOfPages, totalPages];
  }

  return range(1, totalPages);
};

/**
 * @description - component generating pagination
 * @param {*} props - component properties
 * @returns {node} - html
 */
const Pagination = (props) => {
  const {
    totalRecords = null,
    pageLimit = 10,
    onPageChanged,
    selectedPage,
    label,
    t,
    showPrevNext,
    pageNeighbours = 3,
    sitecoreContext,
    canonical,
    searchUrl,
  } = props;

  const totalPages = Math.ceil(totalRecords / pageLimit);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [pages, setPages] = useState([]);
  const [n, setN] = useState(pageLimit ? pageLimit - 1 : 9);
  const displayName = searchUrl;
  const pageRef = useRef();

  useEffect(() => {
    setCurrentPage(selectedPage);
  }, [selectedPage]);

  useEffect(() => {
    setPages(
      fetchPageNumbers(selectedPage, pageNeighbours, totalPages, setCurrentIndex)
    );
    gotoPage(1);
  }, [totalPages]);

  /**
   * @description to move across pages
   * @param {number} page - selected page
   * @returns {undefined}
   */
  const gotoPage = (page) => {
    //const currentPage = Math.max(0, Math.min(page, totalPages));
    if (page !== currentPage) {
      setCurrentPage(page);
      onPageChanged(page);
    }
  };

  const handlePageSelection = (page) => {
    setCurrentIndex(page);
    setPages(fetchPageNumbers(page, pageNeighbours, totalPages, setCurrentIndex));
    gotoPage(page);
  };
  /**
   * @description to handle page selection
   * @param {object} evt - user interaction event
   * @param {number} page - selected page
   * @returns {undefined}
   */
  const handleClick = (evt, page) => {
    evt.preventDefault();
    handlePageSelection(page);

    setTimeout(() => {
      if (pageRef.current) pageRef.current.focus();
    }, 0);
  };

  /**
   * @description to handle left side number loading
   * @param {*} evt - user interaction event
   * @returns {undefined}
   */
  const handleMoveLeft = (evt) => {
    evt.preventDefault();
    const index = Math.max(0, Math.min(currentIndex - n, totalPages));
    setCurrentIndex(index);
    setPages(fetchPageNumbers(index, pageNeighbours, totalPages, setCurrentIndex));
  };

  /**
   * @description to handle right side number loading
   * @param {*} evt - user interaction event
   * @returns {undefined}
   */
  const handleMoveRight = (evt) => {
    evt.preventDefault();
    const index = Math.max(
      0,
      Math.min(
        currentIndex +
          (currentIndex === 1 ? pageNeighbours * 2 + 3 + 1 : pageNeighbours * 2 - 1),
        totalPages
      )
    );
    setPages(fetchPageNumbers(index, pageNeighbours, totalPages, setCurrentIndex));
    setN(index === 1 ? pageLimit - 1 : pageNeighbours * 2 - 1);
  };

  /**
   * @description to handle next arrow click
   * @param {*} evt - user interaction event
   * @returns {undefined}
   */
  const goToNext = (evt) => {
    evt.preventDefault();
    handlePageSelection(selectedPage + 1);
  };

  /**
   * @description to handle previous arrow click
   * @param {*} evt - user interaction event
   * @returns {undefined}
   */
  const goToPrev = (evt) => {
    evt.preventDefault();
    handlePageSelection(selectedPage - 1);
  };

  const prev = history?.location?.search?.replace(
    `page=${selectedPage}`,
    `page=${parseInt(selectedPage) - 1}`
  );

  let next;
  const searchParams = history?.location?.search;
  if (searchParams?.includes('page')) {
    next = searchParams?.replace(`page=${selectedPage}`, `page=${selectedPage + 1}`);
  } else next = '?page=2';

  return (
    <Fragment>
      <Helmet>
        {selectedPage == 1 ? null : <link rel="prev" href={canonical + prev} />}
        {selectedPage != totalPages ? (
          <link rel="next" href={canonical + next} />
        ) : null}
      </Helmet>
      <nav aria-label="Pagination" role="navigation">
        <ul className="pagination page-list">
          {label && (
            <li className="page-item">
              <span>{label}</span>
            </li>
          )}
          <li
            key="page_prev"
            className={`page-item prev${currentPage === 1 ? '-disabled' : ''}`}
            aria-label="Pagination"
          >
            <a
              className={currentPage === 1 ? 'disabledCursor' : 'page-link more'}
              href={`${displayName}?page=${selectedPage - 1}`}
              aria-label="Previous"
              onClick={(e) => goToPrev(e)}
              tabIndex={currentPage === 1 ? -1 : 0}
            >
              <span aria-hidden="true">
                {showPrevNext ? t('previous') : <>&lt;</>}
              </span>
              <span className="sr-only">Previous</span>
            </a>
          </li>
          {pages.map((page, index) => {
            if (page === LEFT_PAGE)
              return (
                <li key={index} className="page-item" aria-label="Pagination">
                  <a
                    className="page-link more"
                    href="/#"
                    aria-label="Previous"
                    onClick={(e) => handleMoveLeft(e)}
                  >
                    <span aria-hidden="true">...</span>
                    <span className="sr-only">Previous</span>
                  </a>
                </li>
              );

            if (page === RIGHT_PAGE)
              return (
                <li key={index} className="page-item" aria-label="Pagination">
                  <a
                    className="page-link more"
                    href="/#"
                    aria-label="Next"
                    onClick={(e) => handleMoveRight(e)}
                  >
                    <span aria-hidden="true">...</span>
                    <span className="sr-only">Next</span>
                  </a>
                </li>
              );
            return (
              <li
                key={index}
                aria-label="Pagination"
                className={`page-item${currentPage === page ? ' active' : ''}`}
              >
                {' '}
                <a
                  ref={currentPage === page ? pageRef : null}
                  className="page-link"
                  href={`${displayName}?page=${page}`}
                  onClick={(e) => handleClick(e, page)}
                  aria-selected={currentPage === page ? true : false}
                  aria-label={`${
                    currentPage === page
                      ? `Page ${currentPage}`
                      : `Go to page ${page}`
                  }`}
                >
                  {page}
                </a>
              </li>
            );
          })}
          <li
            key="page_next"
            className={`page-item next${
              currentPage >= totalPages ? '-disabled' : ''
            }`}
            aria-label="Pagination"
          >
            <a
              className={
                currentPage >= totalPages ? 'disabledCursor' : 'page-link more'
              }
              href={
                currentPage >= totalPages
                  ? '#'
                  : `${displayName}?page=${selectedPage + 1}`
              }
              aria-label="Next"
              onClick={(e) => goToNext(e)}
              tabIndex={currentPage === totalPages ? -1 : 0}
            >
              <span aria-hidden="true">
                {showPrevNext ? t('next-page') : <>&gt;</>}
              </span>
              <span className="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </Fragment>
  );
};

Pagination.defaultProps = {
  totalRecords: 0,
  pageLimit: 0,
  onPageChanged: () => {},
  selectedPage: 0,
  label: '',
  showPrevNext: false,
};

Pagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number.isRequired,
  onPageChanged: PropTypes.func,
  selectedPage: PropTypes.number,
  label: PropTypes.string,
  showPrevNext: PropTypes.bool,
};

export default withSitecoreContext()(withTranslation()(Pagination));
