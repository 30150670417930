import React from 'react';
import PropTypes from 'prop-types';
import {
  mediaApi,
  isExperienceEditorActive,
  RichText,
} from '@sitecore-jss/sitecore-jss-react';

import JobsForm from './JobsForm';
import { getImg } from '../../../../utils/helperUtils';
import '../page-banners.scss';
import { withTranslation } from 'react-i18next';

/**
 *
 * @param {*} param0
 */
const BannerJobs = ({ fields, params }) => {
  const { title, text, backgroundImage, ctaText, t = () => {} } = fields;

  let styleJobForm = 'col-xl-11 col-lg-11 col-11';
  let isMyPath = '';
  if (params?.isMyPath === 'True') {
    isMyPath = 'my-path';
    styleJobForm = 'col-lg-12 offset-lg-0 col-xl-11 offset-xl-1';
  }
  return (
    <section
      className={`banner-section jobs ${isMyPath}`}
      style={{
        backgroundImage: `url(${mediaApi.updateImageUrl(getImg(backgroundImage))})`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-9 col-11">
            <RichText tag="h1" className="title h1tag" field={title} />
            <RichText tag="p" field={text} />
          </div>
        </div>
        <div className="row">
          <div className={`${styleJobForm}`}>
            {isExperienceEditorActive() ? (
              <div>{t('job-search-experience-editor-preview')}</div>
            ) : (
              <JobsForm ctaText={ctaText?.value} isMyPath={params?.isMyPath} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

BannerJobs.defaultProps = {
  fields: {},
  params: {},
};

BannerJobs.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({ value: PropTypes.string }),
    text: PropTypes.shape({ value: PropTypes.string }),
    ctaText: PropTypes.shape({ value: PropTypes.string }),
    backgroundImage: PropTypes.shape({ value: PropTypes.shape({}) }),
  }),
  params: PropTypes.shape({
    isMyPath: PropTypes.shape({ value: PropTypes.string }),
  }),
};

export default withTranslation()(BannerJobs);
