/* eslint-disable max-lines-per-function */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../globals/buttons/Button';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { dataFetcher } from '../../../dataFetcher';
import { useForm } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import './formContactUs.scss';
import DynamicForm from './DynamicForm';
import { withRouter } from 'react-router-dom';
import { CONTACT_US_FORM } from '../../../constants';

/**
 * @description - Contact Us form.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Form.
 */
const FormContactUs = (props) => {
  const { fields, params, t, prevLocation = '', location } = props;
  const { Title, SubTitle, items = [] } = fields;
  const { acknowledgementmail, servicerequestmail } = params;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [prevData, setPrevData] = useState({});
  const [errorCode, setErrorCode] = useState(false);
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    control,
    reset,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const [previousPath, setPreviousPath] = useState('');

  useEffect(() => {
    const prevPath = prevLocation
      ? window?.location?.origin + prevLocation
      : window?.location?.origin + location?.pathname;
    setPreviousPath(prevPath);
  }, []);

  /**
   * @description - Handle form submit function.
   * @param {Object} data - Form Data.
   * @returns {undefined}
   */
  const onSubmit = (data) => {
    const formData = Object.assign({}, data);
    const postData = {
      ...formData,
      acknowledgementmail,
      servicerequestmail,
      previousPath,
    };
    const isSameData = JSON.stringify(prevData) === JSON.stringify(data);
    if (!isSameData) {
      setLoading(true);
      dataFetcher(CONTACT_US_FORM.POST_URL, 'POST', postData).then(
        (response) => {
          setLoading(false);
          if (response?.data?.status && response?.data?.status !== 200) {
            setMessage('contact-us-status-message-failed');
            setErrorCode(true);
            reset();
          } else {
            setMessage('contact-us-status-message-success');
            setErrorCode(false);
            //To rest form
            reset();
            if (window?.dataLayer) {
              window.dataLayer.push({
                event: 'Forms',
                // eventCategory: 'Form submission',
                // formType: 'Contact Us Submission',
                // // formPosition: '',
                // relativeUrl: location?.pathname,
                eventCategory: 'Contact Us Submission',
                eventAction: 'Contact Us Submission',
                eventLabel: location?.pathname,
              });
            }
          }
          document.getElementById('formTitle').scrollIntoView();
        },
        () => {
          setLoading(false);
          setMessage('error-message');
          setErrorCode(true);
          reset();
          document.getElementById('formTitle').scrollIntoView();
        }
      );
      setPrevData(data);
    }
  };
  return (
    <section className="contact-us-form">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <RichText tag="div" id="formTitle" className="title" field={Title} />
            {message !== '' && (
              <p
                id="statusMessage"
                className={`statusMessage${errorCode ? 'Error' : 'Success'}`}
              >
                {t(message)}
              </p>
            )}
            <form>
              <RichText tag="div" className="form-indicator" field={SubTitle} />
              <div className="row">
                {items.map((item, index) => (
                  <div
                    key={index}
                    className={
                      items.length > 1
                        ? `col-lg-6 ${index === 1 ? 'left-side' : ''}`
                        : 'col-lg-12'
                    }
                  >
                    <DynamicForm
                      formData={item?.fields?.items}
                      register={register}
                      errors={errors}
                      getValues={getValues}
                      setValue={setValue}
                      control={control}
                    />
                  </div>
                ))}
              </div>
              <div className="cta">
                <Button
                  cssClass="blue"
                  text={t('submit')}
                  handleButtonClick={handleSubmit(onSubmit)}
                  isLoading={loading}
                  isDisabled={loading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

FormContactUs.defaultProps = {
  fields: {},
  params: {},
};

FormContactUs.propTypes = {
  fields: PropTypes.shape({
    Title: PropTypes.string.isRequired,
    SubTitle: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
  prevLocation: PropTypes.string.isRequired,
  params: PropTypes.shape({
    acknowledgementmail: PropTypes.string.isRequired,
    servicerequestmail: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withRouter(FormContactUs));
