import { createGlobalStyle } from 'styled-components';

export const ActionBarStyles = createGlobalStyle`
.job-actionbar {
    .save,
    .share,
    .full-page {
        color: ${({ theme }) => theme?.primaryButton} !important;
    }
}
`;
