import { useEffect } from 'react';

/**
 * @description - On outside click hook.
 * @param {object} ref - Current reference to node.
 * @param {Function} handler - Event handler function.
 * @returns {undefined}
 */
const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    /**
     * @description - Event listener.
     * @param {object} event - Current event.
     * @returns {undefined}
     */
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('keyup', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.addEventListener('keyup', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
