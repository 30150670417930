import { createGlobalStyle } from 'styled-components';

export const TextLinkStyles = createGlobalStyle`
.textLink{
    a,.link-div,
    .button-text{
        color: ${({ theme }) => theme?.secondaryButton} !important;
        &.reversed{
            color: ${({ theme }) => theme?.heading} !important;
            svg.bi.bi-arrow-right{
                fill: ${({ theme }) => theme?.heading};
                width: 22px;
                height: 22px;
                margin-left: 8px;
            }
        }
        &.reversed-link{
            svg.bi.bi-arrow-right{
                fill: white !important;
                width: 22px !important;
                height: 22px !important;
                margin-left: 8px;
            }
        }
}
.link-blue {
    color:${({ theme }) => theme?.secondaryButtonLink};

}
.link-div{
    svg.bi.bi-arrow-right{
        fill: ${({ theme }) => theme?.secondaryButton};
        width: 22px;
       height: 22px;
       margin-left: 8px;
}
}
`;
