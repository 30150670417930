import { createGlobalStyle } from 'styled-components';

export const DropdownStyles = createGlobalStyle`

.sort-option {
   
    &.active {
      color: ${({ theme }) => theme?.secondaryButton};
      text-shadow:  ${({ theme }) => `0px 0px 1px ${theme?.secondaryButton}`};
    }

}

`;
