/*eslint-disable*/
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  saveSkill,
  saveProfileInfoForm,
  saveProfileInfoFormCount,
  saveProfileInfoFormCountIfFormValid,
} from '../../../../redux/actions/actions';
import Button from '../../../core/globals/buttons/Button';
import './buildProfileAccordion.scss';
import { getValidationDatabyType } from '../../../core/Forms/DynamicForm/helper/filter';
import DynamicForm from '../../../core/Forms/DynamicForm';
import { formObjIteration } from '../../Utils/helper';
import { traverseAndDataExist } from '../../../../utils/helperUtils';

/**
 * @description - ProfileExperience component.
 * @param {Object} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const ProfileExperience = ({ inputRef, ...props }) => {
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    errors,
    setValue,
    control,
    formState,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    revalidate: 'onChange',
    shouldFocusError: false,
  });
  const submitRef = useRef();
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [optionData, setOptionData] = useState({});
  const {
    profileInfo,
    handleClick,
    fields,
    accordionId,
    t,
    profileInfoFormData,
    profileEmptyFieldsData,
    getEmptyFields,
    componentName,
  } = props;
  const { subTitle, items, subText } = fields;

  const requiredData = getValidationDatabyType(
    items?.[0]?.fields?.Validations,
    'required'
  );

  const dispatch = useDispatch();
  const [triggered, settriggered] = useState(false);
  const [handleChangeTrigggered, setHandleChange] = useState(false);
  const { isValid, isDirty, touched, isValidating } = formState;

  useEffect(() => {
    let emptyObj = {};
    emptyObj[componentName] = isValid ? 0 : 1;
    getEmptyFields(emptyObj);
  }, [isValid]);

  useEffect(() => {
    setTimeout(() => {
      let isEmpty = !traverseAndDataExist(getValues());
      dispatch(saveSkill({ isDirty, isValid, touched, isEmpty }));
    }, 500);
  }, [isValid, isDirty, touched, isValidating]);

  useEffect(() => {
    const primaryOptions = profileInfo?.expertiseAndSkills?.primarySkills;
    const secondaryOptions = profileInfo?.expertiseAndSkills?.secondarySkills;
    setSelectedSkills(
      primaryOptions && primaryOptions !== null ? primaryOptions : []
    );
    const skills = { primaryOptions, secondaryOptions };
    setOptionData(skills);
  }, [profileInfo]);

  useImperativeHandle(inputRef, () => ({
    trigger: () => {
      settriggered(true);
      setTimeout(() => dispatch(saveProfileInfoFormCount(2)), 50);
      let event;
      if (typeof Event === 'function') {
        event = new Event('submit', { cancelable: true });
      } else {
        event = document.createEvent('Event');
        event.initEvent('submit', false, { cancelable: true });
      }
      submitRef?.current?.dispatchEvent(event);
    },
  }));

  const onSubmit = (data) => {
    let skills = {};
    if (triggered) {
      if (handleChangeTrigggered) {
        skills.expertiseAndSkills = true;
      }
    } else {
      skills.expertiseAndSkills = true;
    }
    dispatch(
      saveProfileInfoForm({
        ...profileInfoFormData.data,
        EditExpertiseAndSkills: data,
      })
    );
    if (triggered) {
      settriggered(false);
      dispatch(saveProfileInfoFormCountIfFormValid(2));
    }
  };

  const handleNext = (data) => {
    requiredData?.fields?.value?.value === 'true'
      ? selectedSkills.length > 0 && handleClick(parseInt(accordionId) + 1)
      : handleClick(parseInt(accordionId) + 1);
  };

  const transform = (onSubmitFunc) => (data) => {
    let formattedData = Object.assign({}, data);
    formattedData = formObjIteration(data);
    onSubmitFunc(formattedData);
  };

  return (
    <div className="build-profile experience">
      <div className="build-profile experience">
        <div className="profileExperience">
          <div className="col-lg-6 offset-lg-3">
            {/* <div className="title">{item?.fields?.title?.value}</div> */}
            <div className="text-value">{subTitle?.value}</div>
          </div>
          <form
            className="col-lg-6 offset-lg-3 signinup expertise"
            onSubmit={handleSubmit(transform(onSubmit))}
            ref={submitRef}
          >
            <DynamicForm
              formData={items}
              register={register}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
              control={control}
              values={{ data: { skillsData: optionData } }}
            />
          </form>
          <div className="accordionNextBtn">
            <Button
              text={t('next')}
              cssClass={`blue ${!isValid ? 'white-disabled' : 'blue-enabled'}`}
              handleButtonClick={handleSubmit(transform(handleNext))}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ProfileExperience.defaultProps = {
  formData: {},
  onSubmitHandler: () => {},
  profileInfo: {},
  profileInfoFormData: {},
  profileEmptyFieldsData: {},
  getEmptyFields: () => {},
};

ProfileExperience.propTypes = {
  profileInfo: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    items: PropTypes.shape([]),
  }),
  t: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  getEmptyFields: PropTypes.func.isRequired,
  profileInfoFormData: PropTypes.shape({}).isRequired,
  profileEmptyFieldsData: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
    profileInfoFormData: state.profileInfoFormReducer,
    profileEmptyFieldsData: state.profileEmptyFieldsReducer,
    buildProfileFormState: state.buildProfileFormReducer,
  };
};

const ConnectedComponent = connect(mapStateToProps)(ProfileExperience);
export default forwardRef((props, ref) => {
  return <ConnectedComponent {...props} inputRef={ref} />;
});
