/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { AccordionSectionStyle } from './AccordionStyle';

/**
 * @description - Accordion Section component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const AccordionSection = ({ onClick, id, title, content, isOpen }) => {
  /**
   * @description - to handle accordian click
   * @returns {undefined}
   */
  const handleClick = () => {
    onClick(id);
  };

  /**
   * @description - to handle accordian toggle
   * @param {*} event - click eventി
   * @returns {undefined} - nothing
   */
  const handleToggleClick = (event) => {
    if (event.keyCode === 13) {
      onClick(id);
    }
  };

  return (
    <>
      <AccordionSectionStyle />
      <div
        className={'accordion-block ' + (isOpen ? 'active' : '')}
        role="listitem"
        id={`accordion${id}`}
      >
        <div
          className="icon"
          onKeyUp={handleToggleClick}
          onClick={handleClick}
          role="button"
          tabIndex={0}
          aria-label={`toggle accordian ${title?.value}`}
          aria-expanded={isOpen}
        ></div>
        <Text
          field={title}
          tag="div"
          className="accordion-title"
          onClick={handleClick}
        />
        <RichText field={content} className="accordion-content clearfix" />
      </div>
    </>
  );
};

AccordionSection.defaultProps = {
  isOpen: '',
  id: '',
  title: { value: '' },
  content: { value: '' },
};
AccordionSection.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
  content: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
  onClick: PropTypes.func.isRequired,
};

export default AccordionSection;
