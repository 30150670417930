import { createGlobalStyle } from 'styled-components';

export const DropdownStyle = createGlobalStyle`
.footer-rightside {
    .label-footer {
        color: ${({ theme }) => theme?.primaryButton} !important
    }
}
.dp-down {
    &.item-label, .item-label {
        color: ${({ theme }) => theme?.normalButtonLink} 
    }
}

`;
