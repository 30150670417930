/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import TextLink from '../globals/link/TextLink';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

/**
 * @description - to list each office location
 * @param {string} title - office title
 * @param {string} address - office address
 * @param {string} phone - office phone number
 * @param {string} hours - office open hours
 * @param {string} ctaText - more details link text
 * @param {object} ctaUrl - more details link
 * @param {string} cssClass - to have diffrent style changes
 * @param {func} click - to highlight selected item
 * @returns {node} -  html node
 */
const LocationsFinderItem = ({
  title,
  address,
  phone,
  hours,
  ctaText,
  ctaUrl,
  cssClass,
  click,
}) => {
  /**
   * @description -  to handle enter key
   * @param {*} e - key event
   * @returns {undefined}
   */
  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      click();
    }
  };
  return (
    <li
      className={`location-finder-item ${cssClass}`}
      onClick={click}
      tabIndex={0}
      onKeyUp={handleKeyUp}
    >
      <RichText
        tag="h2"
        className="title"
        aria-label={`location ${title?.value}`}
        field={title}
      />
      <div className="row">
        <div className="col-md-6">
          <RichText
            tag="div"
            className="address"
            aria-label={`address ${address?.value}`}
            field={address}
          />
        </div>
        <div className="col-md-6">
          <div className="phone">
            <a
              href={`tel:${phone.value}`}
              aria-label={`phone number opens in a new window/tab`}
            >
              <Text field={phone} />
            </a>
          </div>
          <RichText
            tag="div"
            className="hours"
            aria-label={`open ${hours?.value}`}
            field={hours}
          />
        </div>
      </div>
      <div className="cta">
        <TextLink ctaText={ctaText} ctaUrl={ctaUrl} arrow />
      </div>
    </li>
  );
};

LocationsFinderItem.defaultProps = {
  title: '',
  address: '',
  phone: '',
  hours: '',
  ctaText: '',
  ctaUrl: {
    value: {
      href: '#',
    },
  },
  cssClass: '',
  click: () => {},
};

LocationsFinderItem.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  hours: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.shape({
    value: PropTypes.shape({
      href: PropTypes.string.isRequired,
    }),
  }),
  cssClass: PropTypes.string,
  click: PropTypes.func,
};

export default LocationsFinderItem;
