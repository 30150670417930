import React from 'react';
// import { CardStyle } from '../../components/core/Cards/CardTheme';
import { CommonStyle } from './component_styles/commonStyle';
import { ApsisFormStyle } from './component_styles/apsisForm';
import { ArticleHeaderStyle } from './component_styles/ArticleHeaderTheme';
import { ButtonTheme } from './component_styles/buttonTheme';
import { ButtonStyles } from './component_styles/buttonStyle';
import { CardScrollStyle } from './component_styles/CardScrollTheme';
import { BannerJobsStyle } from './component_styles/bannerJobsTheme';
import { ActionBarStyles } from './component_styles/ActionBarStyle';
import { TextLinkStyles } from './component_styles/textLinkStyle';
import { CalloutBarStyles } from './component_styles/calloutBarStyle';
import { FooterStyles } from './component_styles/sitefooter';
import { HubSpotStyle } from './component_styles/hubspot';
import { DropdownStyle } from './component_styles/dropdownTheme';
import { HamburgerStyle } from './component_styles/HamburgerMenuStyle';
import { ErrorTheme } from './component_styles/errorTheme';
import { SearchIconStyles } from './component_styles/searchIconStyle';
import { SubMenuStyle } from './component_styles/subMenu';
import { HeaderStyles } from './component_styles/siteHeader.js';
import { JobHeaderStyles } from './component_styles/jobHeaderStyles.js';
import { SearchFiltersStyle } from './component_styles/searchFilters';
import { DashboardStyles } from './component_styles/dashboard';
import { DropdownStyles } from './component_styles/DropdownStyle';
import { HomeCareerBlockStyles } from './component_styles/HomeCareerBlockStyle';
import { SideBarStyle } from './component_styles/sidebarcardTheme';
import { ErrorScreenStyles } from './component_styles/errorScreenStyle';
import { SearchEverythingStyle } from './component_styles/SearchEverything';
import { FormStyles } from './component_styles/formStyle';
import { LocationFinderItemStyles } from './component_styles/LocationFinderItemStyle';
import { SearchHeaderStyle } from './component_styles/searchHeader';
import { PageBannerStyle } from './component_styles/PageBannerTheme';
import { BuildProfileStyle } from './component_styles/buildProfileTheme';
import { JobApplyTheme } from './component_styles/jobApply';
import { CalloutBarStepsStyles } from './component_styles/CalloutBarStepsTheme';
import { AccoridonTheme } from './component_styles/AccordionStyle';
import { PressReleaseStyle } from './component_styles/PressReleaseTheme';

const ThemeWrapper = () => {
  return (
    <>
      {/* <CardStyle /> */}
      <CommonStyle />
      <ApsisFormStyle />
      <ArticleHeaderStyle />
      <ButtonTheme />
      <ButtonStyles />
      <BannerJobsStyle />
      <CardScrollStyle />
      <ActionBarStyles />
      <TextLinkStyles />
      <CalloutBarStyles />
      <FooterStyles />
      <HubSpotStyle />
      <DropdownStyle />
      <HamburgerStyle />
      <ErrorTheme />
      <SearchIconStyles />
      <SubMenuStyle />
      <HeaderStyles />
      <JobHeaderStyles />
      <SearchFiltersStyle />
      <DashboardStyles />
      <DropdownStyles />
      <HomeCareerBlockStyles />
      <SideBarStyle />
      <ErrorScreenStyles />
      <SearchEverythingStyle />
      <FormStyles />
      <LocationFinderItemStyles />
      <SearchHeaderStyle />
      <PageBannerStyle />
      <BuildProfileStyle />
      <JobApplyTheme />
      <CalloutBarStepsStyles />
      <AccoridonTheme />
      <PressReleaseStyle />
    </>
  );
};

export default ThemeWrapper;
