/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import AuthWrapper from '../../../../core/LoginMSAL/AuthWrapper';
import Loader from '../../../../core/ErrorBoundary/Loader';
import Error from '../../../../core/ErrorBoundary/Error';
import parse from 'html-react-parser';
import { parsePhoneNumber } from 'libphonenumber-js';
import { getCandidateDetailsApi } from '../../../../../services/apiServices/candidateService';

/**
 * @description - Account Details component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const AccountDetails = ({
  t,
  fields,
  profileInfo,
  loginReducer,
  sitecoreContext,
  dispatch,
}) => {
  const { subTitle } = fields;
  const [data, setData] = useState({});
  const [profileDataLoading, setprofileDataLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (profileInfo?.id) {
      setData(profileInfo?.personalDetails);
      setprofileDataLoading(false);
    } else if (profileInfo?.error) {
      setprofileDataLoading(false);
      setError(true);
    }
  }, [profileInfo]);

  const formatPhone = () => {
    let phone = data?.phone
      ? data?.phone
      : loginReducer?.user?.phone !== 'not-added'
      ? loginReducer?.user?.phone
      : null;
    if (phone && sitecoreContext?.Country?.code) {
      if (isNaN(phone)) return phone;
      return parsePhoneNumber(
        phone,
        sitecoreContext?.Country?.code?.toUpperCase()
      ).formatInternational();
    }
    return null;
  };

  const handleApiRefresh = () => {
    setprofileDataLoading(true);
    setTimeout(() => getCandidateDetailsApi({}, dispatch), 500);
  };

  return (
    <AuthWrapper>
      {({ editProfile, editPhoneNumber, editPassword }) => {
        return (
          <div className="dashboard-agent dashboard-block">
            {profileDataLoading ? (
              <Loader />
            ) : error ? (
              <Error
                errorMsg={t('error-message')}
                t={t}
                refreshApi={true}
                handleApiRefresh={handleApiRefresh}
              />
            ) : (
              <>
                <RichText field={subTitle} tag="p" className="content" />
                <div className="dashboard-account-details">
                  <div className="account-edit-block">
                    <p>
                      {t('email-address-label')}{' '}
                      {data?.email || (
                        <span class="not-added-cls">{t('not-added')}</span>
                      )}
                      {/* {parse(
                        `${t('email-address-label')} ${
                          data?.email ||
                          `<span class="not-added-cls">${t('not-added')}</span>`
                        }`
                      )} */}
                    </p>
                    <p>
                      {`${t('fullname-label')} ${data?.firstName || ''} ${
                        data?.lastName || ''
                      }`}{' '}
                      {data.firstName && data.lastName
                        ? null
                        : parse(
                            `<span class="not-added-cls">${t('not-added')}</span>`
                          )}
                    </p>
                    <button
                      type="button"
                      className="primary-button"
                      onClick={editProfile}
                      onKeyDown={editProfile}
                    >
                      {t('update-name')}
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        className="bi bi-arrow-right"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="account-edit-block">
                    <p>
                      {parse(
                        `${t('phone-number-label')} ${
                          formatPhone() ||
                          `<span class="not-added-cls">${t('not-added')}</span>`
                        }`
                      )}
                    </p>
                    <button
                      type="button"
                      className="primary-button"
                      onClick={editPhoneNumber}
                      onKeyDown={editPhoneNumber}
                    >
                      {t('update-phone')}
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        className="bi bi-arrow-right"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </button>
                  </div>
                  {loginReducer?.idToken?.idTokenClaims?.idp?.toLowerCase() ===
                  'local' ? (
                    <div className="account-edit-block">
                      <p>
                        {t('password-label')} {t('password-value')}
                      </p>
                      <button
                        type="button"
                        className="primary-button"
                        onClick={editPassword}
                        onKeyDown={editPassword}
                      >
                        {t('update-password')}
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                          className="bi bi-arrow-right"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                          />
                        </svg>
                      </button>
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </div>
        );
      }}
    </AuthWrapper>
  );
};

AccountDetails.defaultProps = {
  profileInfo: {},
};
AccountDetails.propTypes = {
  profileInfo: PropTypes.shape({}),
};

/**
 * @description Mapping the state to props.
 * @param {object} state - state.
 * @returns {object} - Slice of state.
 */
const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
    loginReducer: state.loginReducer,
  };
};

export default withSitecoreContext()(connect(mapStateToProps)(AccountDetails));
