/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import lget from 'lodash.get';
import { getValidationDatabyType } from '../DynamicForm/helper/filter';
import { checkValueTypeAndGetTheCount } from '../../../business/Utils/helper';

/**
 *
 * @param {*} props
 */
const CheckboxGroup = (props) => {
  const {
    name,
    control,
    requiredValidation,
    dataValidations,
    t,
    customError,
    label,
    setValue,
    defaultOptions,
    options,
    isPicker,
    updateDefaultOptions,
    id,
    showBasedOnFieldName,
    hideBasedOnFieldName,
    formName,
    watch,
    isHideField,
  } = props;
  const fieldError = lget(customError, name);
  const requiredData =
    requiredValidation?.length > 0
      ? requiredValidation[0]
      : getValidationDatabyType(dataValidations, 'required');
  const [checkedValues, setCheckedValues] = useState(defaultOptions);
  const [showField, setShowField] = useState(true);
  const [hideField, setHideField] = useState(false);
  const showFieldName = formName
    ? `${formName}[${showBasedOnFieldName}]`
    : showBasedOnFieldName;
  const hideFieldName = formName
    ? `${formName}[${hideBasedOnFieldName}]`
    : hideBasedOnFieldName;

  const showFieldValue = showFieldName ? watch(showFieldName) : null;
  const hideFieldValue = hideFieldName ? watch(hideFieldName) : null;

  useEffect(() => {
    if (showFieldValue) {
      let isFieldHasVal = checkValueTypeAndGetTheCount(showFieldValue);
      setShowField(isFieldHasVal);
    }
  }, [showFieldValue]);

  useEffect(() => {
    if (hideFieldValue) {
      let isFieldHasVal = checkValueTypeAndGetTheCount(hideFieldValue);
      setHideField(isFieldHasVal);
    }
  }, [hideFieldValue]);

  const handleSelect = (checkedName) => {
    const newNames = checkedValues?.includes(checkedName)
      ? checkedValues?.filter((name) => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];
    setCheckedValues(newNames);
    updateDefaultOptions(newNames);
    return newNames;
  };

  useEffect(() => {
    if (defaultOptions.length > 0) {
      let newArrSet = [...defaultOptions, ...checkedValues];
      let result = [...new Set(newArrSet)];
      setValue(name, result, { shouldValidate: true, shouldDirty: true });
      setCheckedValues(result);
    }
  }, [options, defaultOptions]);

  return (
    <fieldset>
      <ul
        className={`form-checkbox-group checklist ${
          fieldError ? 'error withoutBg' : ''
        } ${isHideField || !showField || hideField ? 'hide' : ''}`}
        role="group"
      >
        {options.map((item) => (
          <li className="checklist__item" key={item?.id}>
            <div
              className={`${
                !isPicker ? 'form-block checkbox' : ''
              } checkbox--toggle`}
            >
              <Controller
                name={name}
                render={({ onChange: onCheckChange, onBlur }) => {
                  return (
                    <input
                      type="checkbox"
                      checked={checkedValues?.includes(item)}
                      onChange={() => onCheckChange(handleSelect(item))}
                      onBlur={onBlur}
                    />
                  );
                }}
                rules={{
                  required:
                    ((!isHideField || !showField || hideField) &&
                      requiredData?.fields?.value?.value) ||
                    false,
                }}
                control={control}
              />
              <label>{item}</label>
            </div>
          </li>
        ))}
      </ul>
      {fieldError && (
        <div className="error-msg" id={`err_${id}`} role="alert">
          {fieldError.type === 'required'
            ? requiredData?.fields?.message?.value
              ? requiredData?.fields?.message?.value?.replace(
                  '{0}',
                  label?.replace('*', '')
                )
              : t('mandatory-field-message').replace('{0}', label?.replace('*', ''))
            : fieldError?.message?.replace('{0}', label?.replace('*', ''))}
        </div>
      )}
    </fieldset>
  );
};

CheckboxGroup.defaultProps = {
  options: [],
  checkedValues: [],
  defaultOptions: [],
  selectHandler: () => {},
  t: () => {},
  isPicker: false,
  updateDefaultOptions: () => {},
};

CheckboxGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  checkedValues: PropTypes.arrayOf(PropTypes.string),
  defaultOptions: PropTypes.arrayOf(PropTypes.string),
  selectHandler: PropTypes.func,
  t: PropTypes.func,
  isPicker: PropTypes.bool,
  updateDefaultOptions: PropTypes.func,
};

export default withTranslation()(CheckboxGroup);
