import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { isNullorEmpty } from '../../../utils/helperUtils';

/**
 * @description - Breadcrumb Links component.
 * @param {Object} param0 - Input props.
 * @returns {Node} - HTML template for Breadcrumb.
 */
const BreadcrumbLink = ({ text, url, isLastItem, isJobbIncludes, t }) => {
  const linkText = isJobbIncludes ? t('search-jobsearch') : text;
  return (
    !isNullorEmpty(linkText) && (
      <li>
        <Link
          to={url === null ? '#' : url}
          {...(isLastItem ? { 'aria-current': 'page' } : '')}
        >
          {linkText}
        </Link>
        {isJobbIncludes && (
          <>
            <Link
              className="nav"
              style={{ textDecoration: 'none', cursor: 'none !important' }}
            >
              {' > '}
            </Link>
            <Link to="#">{text}</Link>
          </>
        )}
      </li>
    )
  );
};

BreadcrumbLink.defaultProps = {
  text: '',
  url: '',
  isLastItem: '',
  isJobbIncludes: false,
  t: (val) => val,
};
BreadcrumbLink.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  isLastItem: PropTypes.bool,
  isJobbIncludes: PropTypes.bool,
  t: PropTypes.func.isRequired,
};
export default withTranslation()(BreadcrumbLink);
