import React from 'react';
import PropTypes from 'prop-types';
import ConnectorButtonLink from '../globals/link/TextLink';

//import mockData from './mockData';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import './homeblocks.scss';
import { getImg } from '../../../utils/helperUtils';
/**
 * @description - StatBlock component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const CTABlockDiamond = (props) => {
  // fields = Object.entries(fields).length ? fields : mockData;
  const { fields } = props;
  const {
    title,
    text,
    Image,
    CtaText1,
    CtaText2,
    CtaText3,
    CtaUrl1,
    CtaUrl2,
    CtaUrl3,
  } = fields;
  return (
    // <div className="home-block-cta active">
    <section className="home-cta-block">
      <div className="bg-image"></div>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 offset-xl-1 col-lg-6 diamond-row">
              <div className="image-container diamond">
                <div
                  className="main-img image"
                  style={{ backgroundImage: `url(${getImg(Image)})` }}
                ></div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="body desktop">
                <RichText tag="h2" className="title" field={title} />
                <RichText tag="p" className="large" field={text} />
                <div className="cta-container">
                  <ConnectorButtonLink
                    ctaText={CtaText1}
                    ctaUrl={CtaUrl1}
                    cssClass="blue"
                    arrow="true"
                  />

                  <ConnectorButtonLink
                    ctaText={CtaText2}
                    ctaUrl={CtaUrl2}
                    cssClass="blue"
                    arrow="true"
                  />

                  <ConnectorButtonLink
                    ctaText={CtaText3}
                    ctaUrl={CtaUrl3}
                    cssClass="blue"
                    arrow="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    // </div>
  );
};

CTABlockDiamond.defaultProps = {
  fields: {
    title: '',
    text: '',
    Image: 'http://via.placeholder.com/500',
    altText: '',
    CtaText1: '',
    CtaText2: '',
    CtaText3: '',
    CtaUrl1: '',
    CtaUrl2: '',
    CtaUrl3: '',
  },
};

CTABlockDiamond.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    Image: PropTypes.string.isRequired,
    altText: PropTypes.string,
    CtaText1: PropTypes.string,
    CtaText2: PropTypes.string,
    CtaText3: PropTypes.string,
    CtaUrl1: PropTypes.string,
    CtaUrl2: PropTypes.string,
    CtaUrl3: PropTypes.string,
  }),
};

export default CTABlockDiamond;
