/*eslint-disable*/
import { useState } from 'react';
import axios from 'axios';
import { useStore } from 'react-redux';
import { setAuthHeader } from '../utils/auth';

const addAuthHeader = () => {
  const store = useStore();
  setAuthHeader(store);
};

/**
 * @description - to fetch data from api services
 * @returns {object} - fetched data
 */
function useFetchData() {
  addAuthHeader();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  /**
   * @description - to init sate variables
   * @returns {undefined} - nothing
   */
  function init() {
    setData([]);
    setError(false);
    setErrorMsg('');
  }

  /**
   * @description - initiate api call
   * @param {string} url - url from which data to be fetched
   * @param {object} params - params to be passed
   * @returns {object} - data/error
   */
  async function load(url, params, callback = () => {}) {
    init();
    setLoading(true);
    try {
      await axios
        .get(url, {
          params: params,
        })
        .then((result) => {
          callback(result);
          setData(result.data);
          setLoading(false);
        })
        .catch((error) => {
          setErrorMsg(error.message);
          throw error;
        });
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  }

  /**
   * @description - initiate post api call
   * @param {string} url - url from which data to be posted
   * @param {object} params - params to be passed
   * @returns {object} - data/error
   */
  async function post(url, params) {
    init();
    setLoading(true);
    try {
      await axios
        .post(url, { ...params })
        .then((result) => {
          setData(result.data);
          setLoading(false);
        })
        .catch((error) => {
          setErrorMsg(error.message);
          throw error;
        });
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  }

  return { data, loading, error, errorMsg, load, post };
}

/**
 * @description -  to post data
 * @returns {object} - response
 */
function usePostData() {
  addAuthHeader();

  const [response, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  /**
   * @description - to init sate variables
   * @returns {undefined} - nothing
   */
  function init() {
    setData([]);
    setError(false);
  }

  /**
   * @description - initiate api call
   * @param {string} url - url from which data to be fetched
   * @param {object} params - params to be passed
   * @returns {object} - data/error
   */
  async function write(url, params, callback = () => {}) {
    init();
    setLoading(true);
    try {
      await axios
        .post(url, params)
        .then((result) => {
          setData(result);
          callback(result);
        })
        .catch((error) => {
          throw error;
        });
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  }

  async function postWithParam(url, payload, params, callback = () => {}) {
    init();
    setLoading(true);
    try {
      await axios
        .post(url, payload, { params: params })
        .then((result) => {
          setData(result);
          callback(result);
        })
        .catch((error) => {
          throw error;
        });
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  }

  return { response, loading, error, write, postWithParam };
}

export { useFetchData, usePostData };
