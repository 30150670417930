import { createGlobalStyle } from 'styled-components';

export const DashboardStyles = createGlobalStyle`
.dashboard {
  .dashboard-nav {
    a:hover, a.active {
      background: ${({ theme }) =>
        theme?.primaryLightBackground} 0% 0% no-repeat padding-box;
    }
  }
  &-summary {
    &.normal {
      background: ${({ theme }) => theme?.primaryColor};
      .dashboard-tip {
        background: ${({ theme }) => theme?.primaryLightBackground};
      }
      .dashboard-continue {
        .primary-button {
          svg.bi.bi-arrow-right {
            transform: translateX(0);
          }
          &:hover {
            color: ${({ theme }) => theme?.secondaryButton} !important;
            border-color: ${({ theme }) => theme?.secondaryButton};
            svg.bi.bi-arrow-right {
              transform: translateX(2px);
              width: 22px;
              height: 22px;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
  &__sidebar {
    background: transparent linear-gradient(180deg, ${({ theme }) =>
      theme?.primaryColor},  ${({ theme }) =>
  theme?.gradient}) 0% 0% no-repeat padding-box;
  }
  &__content {
    .accordion-container-block {
      .container {
        .hinteText {
          a {
            color: ${({ theme }) => theme?.primaryButton};
          }
        }
      }
      .skill-list-container {
        .skill-item {
          background: ${({ theme }) =>
            theme?.primaryLight} 0% 0% no-repeat padding-box;
        }
      }
    }
  }
  .dashboard-account-details {
    .account-edit-block {
      button {
        border-color: ${({ theme }) => theme?.secondaryButton};
        background: ${({ theme }) => theme?.secondaryButton};
        svg.bi.bi-arrow-right {
          transform: translateX(0);
        }
        &:hover {
          color: ${({ theme }) => theme?.secondaryButton} !important;
          border-color: ${({ theme }) => theme?.secondaryButton};
          svg.bi.bi-arrow-right {
            transform: translateX(5px);
            width: 22px;
            height: 22px;
            margin-left: 8px;
          }
        }
      }
    }
  }
  .dashboard-block {
    &.consent-section {
      .checkbox-section {
        .checkbox {
          a {
            color: ${({ theme }) => theme?.primaryButton}
          }
        }
      }
    }
  }
}
.file-container {
  .input-wrapper{
  color: ${({ theme }) => theme?.primaryButton} !important;
  text-decoration: underline
  }
}
.file-list{
  color: ${({ theme }) => theme?.primaryButton} !important;
}
`;
