import { createGlobalStyle } from 'styled-components';

export const CalloutBarStyles = createGlobalStyle`
.calloutbar-section{
    background: linear-gradient(180deg, ${({ theme }) => theme?.primaryColor},  ${({
  theme,
}) => theme?.gradient})
}
@media (max-width: 992px){
  .calloutbar-section.footer .connector-button .primary-button:hover {
    color: ${({ theme }) => theme?.secondaryButton};
  }
  .calloutbar-section.footer .connector-button .primary-button.orange:hover {
    color: ${({ theme }) => theme?.secondaryButton} !important;
  }
}
`;
