import React from 'react';
import PropTypes from 'prop-types';
import { mediaApi, RichText } from '@sitecore-jss/sitecore-jss-react';
import { getImg } from '../../../utils/helperUtils';
import './page-banners.scss';

/**
 * @description - Banner Insights.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template.
 */
const InsightsPageBannerWithLogo = ({ fields }) => {
  const { title, subTitle, text, color, image } = fields;
  const theme = color?.value ? color.value.toLowerCase() : 'dark_green';
  return (
    <section className={`banner-section insights with-logo ${theme}`}>
      <div
        className="insights-x"
        style={{
          backgroundImage: `url(${mediaApi.updateImageUrl(getImg(image))})`,
        }}
      ></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 order-lg-1 order-2">
            <RichText tag="h5" className="sub-title" field={title} />
            <RichText tag="h1" className="title" field={subTitle} />
            <RichText
              tag="p"
              className="large reversed"
              id="insights-page-description"
              field={text}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

InsightsPageBannerWithLogo.defaultProps = {
  fields: {
    title: { value: '' },
    subTitle: { value: '' },
    text: { value: '' },
  },
};

InsightsPageBannerWithLogo.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({ value: PropTypes.string.isRequired }),
    subTitle: PropTypes.shape({ value: PropTypes.string.isRequired }),
    text: PropTypes.shape({ value: PropTypes.string.isRequired }),
  }).isRequired,
};

export default InsightsPageBannerWithLogo;
