import { RESUME } from '../actions/actionTypes';

const initialState = {
  data: {},
  removeFile: '',
};
/**
 * @description - Resume Reducer
 * @param {*} state
 * @param {*} action
 */
const resumeReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESUME.GET:
      return {
        ...state,
        data: action.payload,
      };
    case RESUME.CLEAR:
      return {
        ...state,
        removeFile: action.payload,
      };
    case RESUME.CLEARALL:
      return initialState;
    default:
      return state;
  }
};
export default resumeReducer;
