import React from 'react';
import './boundaries.scss';

/**
 *
 */
const Snackbar = (props) => {
  const { isActive, message, isSuccess } = props;
  return (
    <div className="snackBarContainer">
      {/* {isSuccess ? <div className="icon-info" /> : <div className="icon-error" />} */}
      <div className="inner-container">
        <div
          className={
            'snackbar ' +
            (isSuccess ? 'success ' : 'error ') +
            (isActive ? 'fadeIn' : 'fadeOut')
          }
        >
          {isSuccess ? (
            <div className="icon-info" />
          ) : (
            <div className="icon-error" />
          )}
          {message}
        </div>
      </div>
    </div>
  );
};

export default Snackbar;
