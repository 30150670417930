import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
// import { createUseStyles } from 'react-jss';
import './textBlock.scss';

import { checkDataExists } from '../../../utils/helperUtils';
/**
 * @description - Get linear gradient color value.
 * @param {object} componentBackgroundColor - Components color field.
 * @returns {Array} - Array of colors.
 */
// const getGradientColors = (componentBackgroundColor) => {
//   let bgColor = [];
//   bgColor = componentBackgroundColor?.map((item) => {
//     const { colorCode } = item?.fields;
//     let color_code = '';
//     color_code = checkDataExists(colorCode) ? checkDataExists(colorCode) : '';
//     return color_code;
//   });
//   return bgColor;
// };
/**
 * @description - TextBlock component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const TextBlock = (props) => {
  const { fields = {} } = props;
  const { text, componentBackgroundColor = [] } = fields;
  const colorValues = componentBackgroundColor[0]?.fields || {};
  let color_code = [];
  const updatebackgroundColor =
    componentBackgroundColor.length > 0 &&
    componentBackgroundColor?.map((item) => {
      const { colorCode } = item?.fields;
      color_code = checkDataExists(colorCode) ? checkDataExists(colorCode) : '';
      color_code = color_code.indexOf(',') > -1 ? color_code.split(',') : color_code;
      if (checkDataExists(colorCode).indexOf(',') > -1) {
        color_code = `linear-gradient(${color_code[0]}, ${color_code[1]})`;
      } else {
        color_code = color_code;
      }
      return color_code;
    });

  // const themeColors = createUseStyles({
  //   bgColor: {
  //     '&.rich-text-block': {
  //       background:
  //         colorValues?.colorCode?.value?.indexOf(',') > -1
  //           ? `linear-gradient(${colorValues?.colorCode?.value})`
  //           : colorValues?.colorCode?.value,
  //     },
  //   },
  //   richTextFont: {
  //     color: colorValues?.fontColor?.value || 'inherit',
  //     '& *': {
  //       color: colorValues?.fontColor?.value || 'inherit',
  //     },
  //   },
  // });
  // const classes = themeColors();

  return (
    <div
      className={`rich-text-block`}
      style={{ background: `${updatebackgroundColor}` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <RichText
              field={text}
              // className={`content ${classes.richTextFont}`}
              className="content"
              style={{ color: colorValues?.fontColor?.value || 'inherit' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

TextBlock.defaultProps = {
  fields: {},
};

TextBlock.propTypes = {
  fields: PropTypes.shape({
    text: PropTypes.shape({ value: PropTypes.string }),
    componentBackgroundColor: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default TextBlock;
