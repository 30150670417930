import { BUILD_PROFILE_FORM } from '../actions/actionTypes';

const initialState = {
  EditPersonalInfo: {},
  EditExperience: {},
  EditExpertiseAndSkills: {},
  EditEducation: {},
  EditCertifications: {},
  EditCoverLetters: {},
  UploadCandidateResume: {},
};

/**
 *
 * @param {*} state
 * @param {*} action
 */
const buildProfileFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case BUILD_PROFILE_FORM.PERSONAL_SAVE:
      return {
        ...state,
        EditPersonalInfo: action.payload,
      };
    case BUILD_PROFILE_FORM.EXP_SAVE:
      return {
        ...state,
        EditExperience: action.payload,
      };
    case BUILD_PROFILE_FORM.EDU_SAVE:
      return {
        ...state,
        EditEducation: action.payload,
      };
    case BUILD_PROFILE_FORM.SKILL_SAVE:
      return {
        ...state,
        EditExpertiseAndSkills: action.payload,
      };
    case BUILD_PROFILE_FORM.CERT_SAVE:
      return {
        ...state,
        EditCertifications: action.payload,
      };
    case BUILD_PROFILE_FORM.RESUME_SAVE:
      return {
        ...state,
        UploadCandidateResume: action.payload,
      };
    case BUILD_PROFILE_FORM.COVER_LETTER_SAVE:
      return {
        ...state,
        EditCoverLetters: action.payload,
      };
    case BUILD_PROFILE_FORM.CLEAR:
      return initialState;
    default:
      return state;
  }
};
export default buildProfileFormReducer;
