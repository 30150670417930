import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SocialIcon from '../globals/icons/FollowUs';
import './actionbars.scss';

/**
 * @description - Action bar social share.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template.
 */
const ActionBarFollowUs = (props) => {
  const { fields = {} } = props;
  const { items = [] } = fields;
  return (
    <div className="social-bar">
      <div className="social-icons" aria-expanded={true}>
        {items.map((item, index) => (
          <Fragment key={index}>
            <SocialIcon
              img={item?.fields?.icon}
              altText={item?.fields?.name?.value}
              tooltip={item?.fields?.name?.value}
              ctaUrl={item?.fields?.url}
              arialabel="Open in a new window/tab"
              utmQueryParam={item?.fields?.utmQueryParam?.value}
            />
          </Fragment>
        ))}
      </div>
    </div>
  );
};

ActionBarFollowUs.defaultProps = {
  fields: {
    items: [],
  },
};

ActionBarFollowUs.propTypes = {
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

export default ActionBarFollowUs;
