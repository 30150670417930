/*eslint-disable*/
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, connect } from 'react-redux';
import {
  saveEdu,
  saveProfieEmptyFields,
  saveProfileInfoForm,
  saveProfileInfoFormCount,
  saveProfileInfoFormCountIfFormValid,
  // saveField,
} from '../../../../redux/actions/actions';
import DynamicForm from '../../../core/Forms/DynamicForm';
import TextButton from '../../../core/globals/buttons/TextButton';
import Button from '../../../core/globals/buttons/Button';
import { getValidationDatabyType } from '../../../core/Forms/DynamicForm/helper/filter';
import { isNullorEmpty, traverseAndDataExist } from '../../../../utils/helperUtils';
import { formObjIteration } from '../../Utils/helper';
import { dataFetcher } from '../../../../dataFetcher';
import { API } from '../../../../constants';
// import items from '../../../mocks/menuItems';

const hiddenFields = {
  id: 'id_exp',
  name: 'id',
  fields: {
    name: {
      value: 'id',
    },
    inputType: {
      value: 'Hidden',
    },
    value: {
      value: '',
    },
    readonly: {
      value: false,
    },
    label: {
      value: '',
    },
    placeholderText: {
      value: '',
    },
    required: {
      value: false,
    },
    width: {
      value: '',
    },
    pattern: {
      value: '',
    },
    Validations: [],
  },
};

/**
 * @description - ProfileEducation component.
 * @param {Object} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const ProfileEducation = ({ inputRef, ...props }) => {
  const {
    profileInfo,
    fields,
    t,
    handleClick,
    accordionId,
    profileInfoFormData,
    componentName,
    profileEmptyFieldsData,
    getEmptyFields,
    setLoader,
    // uploadFields,
  } = props;
  const formSubmitRef = useRef();
  const [fieldLength, setFieldLength] = useState(0);
  const [triggered, settriggered] = useState(false);
  const [apiListLoading, setApiListLoading] = useState(false);

  let [newFieldArr, setFieldArr] = useState([]);
  const [data, setData] = useState([]);
  const [fileUpload, setFileUpload] = useState({});
  const [fieldList, setFieldList] = useState([]);
  const [candidateId, setCandidateId] = useState('');

  const {
    register,
    handleSubmit,
    getValues,
    errors,
    setValue,
    control,
    formState,
    setError,
    clearErrors,
    reset,
    trigger,
    watch,
  } = useForm({
    mode: 'onChange',
    revalidate: 'onChange',
    shouldFocusError: false,
  });

  const dispatch = useDispatch();
  const { apiList } = fields;
  const [apiCollection, setApiCollection] = useState({});
  const [apiCalledCount, setApiCalledCount] = useState(0);
  const apiEndPoints = apiList?.value?.split(',')?.filter((el) => el !== '') || [];

  const { isValid, isDirty, touched, isValidating } = formState;

  /**
   * @description - Get APi
   * @param {string} apiEndPoint - endpoint
   * @returns {string} - Formatted data.

   */
  const getApi = (apiEndPoint, APIQuerryParams = {}) => {
    let api = apiEndPoint;
    if (
      Object.keys(APIQuerryParams).length > 0 &&
      apiEndPoint &&
      apiEndPoint !== null
    ) {
      Object.keys(APIQuerryParams).forEach((param) => {
        api = api.replace(`{${param}}`, `${param}=${APIQuerryParams[param]}`);
      });
    }
    return api;
  };

  useEffect(() => {
    if (apiEndPoints && apiEndPoints?.length > 0) {
      setApiListLoading(true);
      setLoader(true);
      let count = 0;
      let collectionObj = {};
      apiEndPoints.map((api) => {
        dataFetcher(`${API.HOST}${getApi(api)}`).then(
          (response) => {
            collectionObj = response?.data && {
              ...collectionObj,
              [getApi(api)]: response?.data,
            };
            count++;
            setApiCollection(collectionObj);
            setApiCalledCount(count);
          },
          (error) => {
            collectionObj = { ...collectionObj, [getApi(api)]: [] };
            count++;
            setApiCollection(collectionObj);
            setApiCalledCount(count);
          }
        );
      });
    }
  }, []);

  useEffect(() => {
    let emptyObj = {};
    if (apiCalledCount !== 0 && apiCalledCount === apiEndPoints?.length) {
      setLoader(false);
    }
    emptyObj[componentName] = isValid ? 0 : 1;
    getEmptyFields(emptyObj);
  }, [isValid, apiCalledCount]);

  useEffect(() => {
    if (profileInfo?.id) {
      setCandidateId(profileInfo?.id);
    }
  }, [profileInfo]);

  useEffect(() => {
    setTimeout(() => {
      let isEmpty = !traverseAndDataExist(getValues());
      dispatch(saveEdu({ isDirty, isValid, touched, isEmpty }));
    }, 500);
  }, [isValid, isDirty, touched, isValidating]);

  const onSubmit = (data) => {
    dispatch(
      saveProfileInfoForm({
        ...profileInfoFormData?.data,
        ...data,
        files: { ...profileInfoFormData?.data?.files, fileUpload },
        deletedFiles: [
          ...new Set([...profileInfoFormData?.data?.deletedFiles, ...fieldList]),
        ],
      })
    );
    if (triggered) {
      settriggered(false);
      dispatch(saveProfileInfoFormCountIfFormValid(3));
    } else handleClick(parseInt(accordionId) + 1);
  };

  const transform = (onSubmitFunc) => (data) => {
    let formattedData = Object.assign({}, data);
    formattedData = formObjIteration(data);
    formattedData.EditEducation = formattedData?.EditEducation.filter(
      (edu) => edu !== null
    );
    onSubmitFunc(formattedData);
  };

  useEffect(() => {
    if (profileInfo?.educationInfo) {
      // reset();
      // setData([]);
      // setFieldArr([]);
      if (profileInfo.educationInfo?.formCount > 0) {
        setData(profileInfo.educationInfo?.data);
        setFieldArr(
          new Array(profileInfo.educationInfo?.formCount).fill(fields?.items)
        );
      } else {
        setData([]);
        setFieldArr(new Array(fields?.items));
      }
    }
  }, [profileInfo]);

  // useEffect(() => {
  //   let educationInfo = profileInfo?.educationInfo
  //     ? profileInfo?.educationInfo?.data
  //     : [];
  //   // educationInfo = arrayToObject(educationInfo);
  //   let emptyCount = 0;
  //   let emptyObj = {};
  //   if (educationInfo?.length === 0) {
  //     emptyCount = fields?.items?.length;
  //   } else {
  //     fields?.items.map((item) => {
  //       const requiredData = getValidationDatabyType(
  //         item?.fields?.Validations,
  //         'required'
  //       );
  //       educationInfo?.map((expItem) => {
  //         if (
  //           requiredData?.fields?.value?.value === 'true' &&
  //           isNullorEmpty(expItem?.[item?.fields?.name?.value]) &&
  //           isNullorEmpty(item?.fields?.value?.value)
  //         )
  //           emptyCount = emptyCount + 1;
  //       });
  //     });
  //   }
  //   emptyObj[componentName] = emptyCount;
  //   getEmptyFields(emptyObj);
  //   dispatch(saveProfieEmptyFields({ ...profileEmptyFieldsData, ...emptyObj }));
  // }, [profileInfo]);

  useEffect(() => {
    let temp = [...newFieldArr];
    temp = temp.filter((item) => item !== undefined);
    setFieldLength(temp.length);
  }, [newFieldArr]);

  /**
   * @description - Add button click handler function.
   * @returns {undefined}.
   */
  const _handleAddItem = () => {
    const items = [...fields?.items];
    setFieldArr([...newFieldArr, items]);
  };

  const deleteUploadedFile = (temp, id, data) => {
    let item = '';
    temp.map((item) => {
      if (item.fields.inputType.value === 'FileUpload') {
        item = item.fields.name.value;
        return;
      }
    });
    const deletedKey = `EditEducation[${id}].[${item}]`;
    const fileList = fileUpload;
    if (fileUpload.hasOwnProperty(deletedKey)) {
      delete fileList[deletedKey];
      setFileUpload(fileList);
    } else {
      data &&
        item &&
        data[item].map((fileItem) => {
          if (fileItem?.id) {
            setFieldList([...fieldList, { fileid: fileItem?.id, type: item }]);
          }
        });
    }
  };

  const _handleRemoveItem = (idx) => {
    // assigning the list to temp variable
    let temp = [...newFieldArr];
    deleteUploadedFile(temp[idx], idx, data[idx]);
    delete temp[idx];
    const deletedId = data[idx]?.id;
    if (deletedId)
      dispatch(
        saveProfileInfoForm({
          ...profileInfoFormData?.data,
          deletedEducationList: [
            ...profileInfoFormData?.data?.deletedEducationList,
            deletedId,
          ],
        })
      );
    dispatch(saveProfileInfoFormCountIfFormValid(3));
    // updating the list
    setFieldArr(temp);
    let isEmpty = !traverseAndDataExist(getValues());
    dispatch(
      saveEdu({ isDirty, isValid, touched: { EditEducation: true }, isEmpty })
    );
  };

  useImperativeHandle(inputRef, () => ({
    trigger: () => {
      settriggered(true);
      setTimeout(() => dispatch(saveProfileInfoFormCount(3)), 50);
      let event;
      if (typeof Event === 'function') {
        event = new Event('submit', { cancelable: true });
      } else {
        event = document.createEvent('Event');
        event.initEvent('submit', false, { cancelable: true });
      }
      formSubmitRef?.current?.dispatchEvent(event);
      // formSubmitRef?.current?.dispatchEvent(
      //   new Event('submit', { cancelable: true })
      // );
    },
  }));

  const handleUpload = (fileData, name) => {
    const fileList = fileUpload;
    const obj = { [name]: fileData };
    if (fileUpload.hasOwnProperty(name)) {
      delete fileList[name];
    }
    setFileUpload({ ...fileList, ...obj });
    // if (!uploadFields.includes(name)) {
    //   dispatch(saveField(name));
    // }
  };

  const handleDelete = (id, name, index) => {
    if (fileUpload.hasOwnProperty(name)) {
      const fileList = fileUpload;
      const temp = fileUpload[name];
      delete temp[index];
      delete fileList[name];
      const newtemp = temp?.filter((item) => item);
      const obj = { ...fileUpload, [name]: newtemp };
      setFileUpload({ ...fileList, ...obj });
    }
    if (id) {
      setFieldList([...fieldList, { fileid: id, type: name }]);
    }
  };

  return (
    <div className="personalInfo">
      <div className="build-profile education">
        <div className="text">{fields?.subTitle?.value}</div>
        <section className="forms signinup">
          <form
            autoComplete="off"
            onSubmit={handleSubmit(transform(onSubmit))}
            ref={formSubmitRef}
          ></form>
          {newFieldArr.map((item, idx) => {
            if (item) {
              return (
                <div className="formBlock" key={idx} id={`EditEducation_${idx}`}>
                  <div className="formText">{fields?.formText?.value}</div>
                  <div className="personal-info-form">
                    <DynamicForm
                      customIndex={idx}
                      formName="EditEducation"
                      formData={[...item, hiddenFields]}
                      register={register}
                      errors={errors}
                      getValues={getValues}
                      values={{ data: data[idx] }}
                      setValue={setValue}
                      setError={setError}
                      clearErrors={clearErrors}
                      control={control}
                      validateOnLoad={true}
                      trigger={trigger}
                      watch={watch}
                      handleUpload={handleUpload}
                      APIQuerryParams={{ cid: candidateId }}
                      handleDelete={handleDelete}
                      apiCollection={apiCollection}
                      apiList={apiList?.value}
                    />
                    {fieldLength > 1 && (
                      <div className="removeBtn">
                        <TextButton
                          cssClass="blue"
                          text={`- ${t('remove')}`}
                          handleTextClick={() => _handleRemoveItem(idx)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            }
          })}
          <div className="add-form">
            <TextButton
              cssClass={`blue ${!isValid && 'grey-disabled'}`}
              text={t('add-education')}
              handleTextClick={handleSubmit(transform(_handleAddItem))}
              isDisabled={!isValid}
            />
          </div>
          <Button
            text={t('next')}
            cssClass={`blue ${!isValid ? 'white-disabled' : 'blue-enabled'}`}
            handleButtonClick={handleSubmit(transform(onSubmit))}
          />
        </section>
      </div>
    </div>
  );
};

ProfileEducation.defaultProps = {
  fields: {},
  profileInfo: {},
  profileInfoFormData: {},
  profileEmptyFieldsData: {},
  getEmptyFields: () => {},
  setLoader: () => {},
};

ProfileEducation.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  profileInfo: PropTypes.shape({}).isRequired,
  profileInfoFormData: PropTypes.shape({}).isRequired,
  getEmptyFields: PropTypes.func,
  setLoader: PropTypes.func,
  profileEmptyFieldsData: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
    profileEmptyFieldsData: state.profileEmptyFieldsReducer,
    profileInfoFormData: state.profileInfoFormReducer,
    // uploadFields: state.uploadFieldsReducer,
  };
};

const ConnectedComponent = connect(mapStateToProps)(ProfileEducation);
export default forwardRef((props, ref) => {
  return <ConnectedComponent {...props} inputRef={ref} />;
});
