import gql from 'graphql-tag';

export const GET_INSIGHT_ARTICELS = gql`
  query GetArticleCardBlocks(
    $first: Int
    $after: String!
    $contentType: String!
    $topic: String!
    $subTopic: String!
  ) {
    customSearch(
      index: "sitecore_web_index"
      first: $first
      after: $after
      sort: { field: "date", dir: DESC }
      fieldsEqual: [
        { name: "_template", value: "731156987037453b9a054ea42ff6a0c7" }
        { name: "contenttype_sm", value: $contentType }
        { name: "topic_sm", value: $topic }
        { name: "subtopic_sm", value: $subTopic }
      ]
    ) {
      results {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
        items {
          item {
            path
            ... on Article {
              url
              contentType {
                value
                targetId
                targetItem {
                  field(name: "name") {
                    value
                  }
                }
              }
              date {
                value
                jss
              }
              headline {
                value
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_TOPICS = gql`
  query GetTopicDropdown($contentType: String!) {
    customSearch(
      index: "sitecore_web_index"
      fieldsEqual: [
        { name: "_template", value: "731156987037453b9a054ea42ff6a0c7" }
        { name: "contenttype_sm", value: $contentType }
      ]
      facetOn: ["topic_sm"]
    ) {
      facets {
        values {
          item {
            field(name: "name") {
              value
            }
          }
          value
        }
      }
    }
  }
`;

export const GET_SUB_TOPICS = gql`
  query GetSubTopics($contentType: String!, $topic: String!) {
    subTopics: customSearch(
      fieldsEqual: [
        { name: "_template", value: "731156987037453b9a054ea42ff6a0c7" }
        { name: "contenttype_sm", value: $contentType }
        { name: "topic_sm", value: $topic }
      ]
      facetOn: ["subtopic_sm"]
    ) {
      facets {
        values {
          item {
            name
            id
            parent {
              name
              id
            }
          }
          value
        }
      }
    }
  }
`;
