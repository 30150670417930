import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '../globals/link/ButtonLink';
import { mediaApi, RichText } from '@sitecore-jss/sitecore-jss-react';
//import mockData from '../../mocks/CalloutBarJW'
import './calloutbar.scss';
import { getImg } from '../../../utils/helperUtils';
/**
 * @description - callout bar footer action;
 * @param {object} props - input props;
 * @returns{Node} - html block;
 */
const FooterActionCalloutBarWithImage = (props) => {
  const { fields } = props;
  const { title, text, ctaText, ctaUrl, topImg, bottomImg, mainImg } = fields;
  return (
    <section className="calloutbar-section footer diamond">
      <div className="white-bg"></div>
      <ul className="diamond-grid">
        <li className="item2 dark"></li>
        <li className="item1">
          <div
            className="bg"
            style={{
              backgroundImage: `url(${mediaApi.updateImageUrl(getImg(topImg))})`,
            }}
          ></div>
        </li>
        <li className="item2">
          <div
            className="bg"
            style={{
              backgroundImage: `url(${mediaApi.updateImageUrl(getImg(mainImg))})`,
            }}
          ></div>
        </li>
        <li className="item3">
          <div
            className="bg"
            style={{
              backgroundImage: `url(${mediaApi.updateImageUrl(getImg(bottomImg))})`,
            }}
          ></div>
        </li>
      </ul>
      <div className="calloutbar-body container">
        <div className="row">
          <div className="col-lg-7">
            <RichText tag="div" className="title reversed" field={title} />
            <RichText tag="p" className="reversed large" field={text} />
            <ButtonLink cssClass="orange" ctaText={ctaText} ctaUrl={ctaUrl} arrow />
          </div>
        </div>
      </div>
    </section>
  );
};

FooterActionCalloutBarWithImage.defaultProps = {
  fields: {
    title: '',
    text: '',
    ctaText: '',
    ctaUrl: 'true',
    topImg: 'https://cdn2.hubspot.net/hubfs/2942250/GettyImages-CalloutBarTop.jpg',
    mainImg: 'https://cdn2.hubspot.net/hubfs/2942250/GettyImages-CalloutBarMain.jpg',
    bottomImg: 'https://cdn2.hubspot.net/hubfs/2942250/GettyImages-CalloutBar.jpg',
  },
};

FooterActionCalloutBarWithImage.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    ctaText: PropTypes.string.isRequired,
    ctaUrl: PropTypes.string.isRequired,
    topImg: PropTypes.string.isRequired,
    mainImg: PropTypes.string.isRequired,
    bottomImg: PropTypes.string.isRequired,
  }),
};

export default FooterActionCalloutBarWithImage;
