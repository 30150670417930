import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { authstates } from '../../../utils/enums';
import Loader from '../ErrorBoundary/Loader';
import { getCandidateDetailsApi } from '../../../services/apiServices/candidateService';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTheme } from 'styled-components';

/**
 * @description - PrivateRoute component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template for PrivateRoute.
 */
const PrivateRoute = ({ children, redirectUrl, t, sitecoreContext, theme }) => {
  const loginReducer = useSelector((state) => state.loginReducer);
  const profileInfoReducer = useSelector((state) => state.profileInfoReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    /**
     * @description - gets local state
     * @returns {Object} - state or undefined.
     */
    const getStorageItem = () => {
      const serializedState = localStorage
        ? localStorage.getItem('usersession')
        : null;
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    };

    (() => {
      const localState = getStorageItem();
      if (
        ((loginReducer.initialized &&
          loginReducer.state !== authstates.AUTHENTICATED) ||
          (localState !== undefined &&
            localState.state !== authstates.AUTHENTICATED)) &&
        sitecoreContext?.LoginType !== 'NA'
      )
        window.location.href = redirectUrl;
    })();
  }, [loginReducer.state, loginReducer.initialized]);

  useEffect(() => {
    if (loginReducer.state === authstates.AUTHENTICATED) {
      if (profileInfoReducer?.data?.error && !profileInfoReducer?.data?.loading) {
        getCandidateDetailsApi({}, dispatch);
      }
    }
  }, []);

  return (
    <>
      {(loginReducer && loginReducer.state === authstates.AUTHENTICATED) ||
      sitecoreContext?.LoginType === 'NA' ? (
        children
      ) : (
        <Loader
          loaderMsg={t('redirected-message')}
          loaderWithMsg
          customLoaderStyle={{
            width: '4rem',
            height: '4rem',
            color: theme?.secondaryButton,
          }}
        />
      )}
    </>
  );
};

PrivateRoute.defaultProps = {
  redirectUrl: '/',
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  redirectUrl: PropTypes.string,
  t: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({}).isRequired,
};

export default withTranslation()(withSitecoreContext()(withTheme(PrivateRoute)));
