/* eslint-disable */
import heroImg from '../../../assets/images/hero.jpg';
import DashboardProfile from '../../../assets/images/icon__dashboard-profile.svg';
import DashboardSetting from '../../../assets/images/icon__dashboard-setting.svg';
import Dashboard from '../../../assets/images/icon__dashboard.svg';
import UploadIcon from '../../../assets/images/icon_upload.svg';

export const assountSettingMock = {
  componentName: 'Dashboard',
  fields: {
    backgroundImage: {
      value: {
        src: '',
        alt: '',
        width: '',
        height: '',
      },
    },
  },
  placeholders: {
    'jss-left': [
      {
        componentName: 'DashboardProfile',
        dataSource: '{F7BA8A88-2133-41BB-A58B-986DEA4BD5DA}',
        params: {},
        fields: {
          icon: {
            value: {
              src: UploadIcon,
              alt: 'info-active',
              width: '200',
              height: '200',
            },
          },
          title: {
            value: 'Welcome, {0}!',
          },
        },
      },
      {
        componentName: 'DashboardNav',
        dataSource: '{F7BA8A88-2133-41BB-A58B-986DEA4BD5DA}',
        params: {},
        fields: {
          items: [
            {
              fields: {
                icon: {
                  value: {
                    src: Dashboard,
                    alt: 'info-active',
                    width: '200',
                    height: '200',
                  },
                },
                title: {
                  value: 'Dashboard',
                },
                url: {
                  value: '/candidate/dashboard',
                },
              },
            },
            {
              fields: {
                icon: {
                  value: {
                    src: DashboardProfile,
                    alt: 'info-active',
                    width: '200',
                    height: '200',
                  },
                },
                title: {
                  value: 'Profile',
                },
                url: {
                  value: '/candidate/profile',
                },
              },
            },
            {
              fields: {
                icon: {
                  value: {
                    src: DashboardSetting,
                    alt: 'info-active',
                    width: '200',
                    height: '200',
                  },
                },
                title: {
                  value: 'Account Settings',
                },
                url: {
                  value: '/candidate/account-settings',
                },
              },
            },
          ],
        },
      },
    ],
    'jss-content': [
      {
        componentName: 'DashboardProfileMobile',
        dataSource: '{F7BA8A88-2133-41BB-A58B-986DEA4BD5DA}',
        params: {},
        fields: {
          icon: {
            value: {
              src: UploadIcon,
              alt: 'info-active',
              width: '200',
              height: '200',
            },
          },
          title: {
            value: 'Welcome {0}',
          },
          width: {
            value: '100',
          },
        },
      },
      {
        uid: '7f01db03-f45b-428b-b502-b791d06a7969',
        componentName: 'ProgressBarBlock',
        dataSource:
          '/sitecore/content/Manpowergroup/Manpowergroup/Manpowergroup-Germany/Home/Candidate/Dashboard/Data/Dashboard Progressbar Block',
        params: {},
        fields: {
          items: [
            {
              id: '231856b9-fae6-4c2d-9f17-f966c2d652e6',
              name: 'Partial',
              displayName: 'Partial',
              fields: {
                text: {
                  value: 'A More complete profile can increase your matches by 30%.',
                },
                ctaUrl: {
                  value: {
                    href: '/de/candidate/buildprofile',
                    id: '{BCC279D6-AAD1-4F36-8D48-45078C0BA519}',
                    querystring: '',
                    target: '|Custom',
                    text: '',
                    anchor: '',
                    title: '',
                    class: '',
                    url:
                      '/Manpowergroup/Manpowergroup/Manpowergroup-Germany/Home/Candidate/BuildProfile',
                    linktype: 'internal',
                  },
                },
                ctaText: {
                  value: 'CONTINUE BUILDING PROFILE',
                },
                percentage: {
                  value: '',
                },
                type: {
                  value: '1',
                },
                width: {
                  value: '100',
                },
                title: {
                  value: 'Profile: {0}% complete',
                },
              },
            },
            {
              id: 'a78096e1-740c-4693-a8ac-64d14fff4fcb',
              name: 'Complete',
              displayName: 'Complete',
              fields: {
                text: {
                  value: 'A More complete profile can increase your matches by 30%.',
                },
                ctaUrl: {
                  value: {
                    href: '/de/candidate/buildprofile',
                    id: '{BCC279D6-AAD1-4F36-8D48-45078C0BA519}',
                    querystring: '',
                    target: '|Custom',
                    text: '',
                    anchor: '',
                    title: '',
                    class: '',
                    url:
                      '/Manpowergroup/Manpowergroup/Manpowergroup-Germany/Home/Candidate/BuildProfile',
                    linktype: 'internal',
                  },
                },
                ctaText: {
                  value: 'UPDATE PROFILE',
                },
                percentage: {
                  value: '',
                },
                type: {
                  value: '1',
                },
                width: {
                  value: '100',
                },
                title: {
                  value: 'Do you need to update your profile?',
                },
              },
            },
          ],
        },
      },
      ,
      {
        componentName: 'AccountAccordion',
        fields: {
          text: {
            value: '111Lorem ipsum Lorem ipsum Lorem ipsumLorem ipsum',
          },
          allowMultipleOpen: {
            value: true,
          },
          ctaText: {
            value: '',
          },
          ctaUrl: {
            value: {},
          },
          type: '1',
        },
        placeholders: {
          'jss-content': [
            {
              uid: 'cc2ed2a6-afe3-45dd-a90b-a3ed5f921e5d',
              componentName: 'AccountDetails',
              fields: {
                title: {
                  value: 'Account Details',
                },
                subTitle: {
                  value:
                    'Use the button below to change your account details for your Experis account. Account details include first name, last name, email, phone number and password. Email address cant be modified at this time.',
                },
              },
            },
            {
              uid: 'e67fd304-d855-468b-8f07-ec085713d324',
              componentName: 'ConsentForm',
              dataSource:
                '/sitecore/content/Manpowergroup/Experis/Experis-Spain/Home/Candidate/Account-Settings/Data/Consent To Use And Store Data',
              params: {},
              fields: {
                ctaUrl: {
                  value: {
                    href: '',
                  },
                },
                ctaText: {
                  value: '',
                },
                mandatoryConsentText: {
                  value: '',
                },
                title: {
                  value: 'Consent To Use And Store Data',
                },
                description: {
                  value: '',
                },
                promotionalConsentText: {
                  value: '',
                },
                subTitle: {
                  value: '',
                },
                items: [
                  {
                    id: '29c910ea-51db-416b-9039-f7dd9d783b67',
                    name: 'Section 1',
                    displayName: 'Section 1',
                    fields: {
                      text: {
                        value:
                          '<p style="font-size=20px; color: #282a32;">You have to give consent for us to use your personal informations and Process your job application.</p><br />\n<p style="font-size=20px; color: #282a32;">By submitting my personal information, work Experience and education, I confirm that I have read and agree to the Experis Privacy Policy, wich explains how Experis collects, processes and shares my personal data. I consent to my data being processed in accordance with Experis Privacy Policy so that Experis can optimize my Experience with the Experis Brand:</p>',
                      },
                      showLineBar: {
                        value: true,
                      },
                      items: [
                        {
                          id: '3dba6c03-e120-4e46-8473-4f168bafe193',
                          name: 'General Consent',
                          displayName: 'General Consent',
                          fields: {
                            text: {
                              value:
                                '<p style="font-size=20px; color: #282a32;">Regarding the processing of my personal data for the purpose of providing me with temporary or permanent employment opportunities, sending me alerts about available positions, evaluating (even by automatic means) my profile and professional skills for the evaluation of individual development and career plans, as described in more detail in the&nbsp;<a href="/en/privacy-policy">Privacy Policy*</a></p>',
                            },
                            values: [
                              {
                                id: '5cd0a5fc-1ac8-44b0-a2d2-c4d16339eaa9',
                                fields: {
                                  label: {
                                    value: 'True',
                                  },
                                  key: {
                                    value: '0',
                                  },
                                },
                              },
                              {
                                id: '8fa43687-dd8c-4e0b-a29f-2fd618898bb0',
                                fields: {
                                  label: {
                                    value: 'False',
                                  },
                                  key: {
                                    value: '1',
                                  },
                                },
                              },
                            ],
                            Validations: [],
                            connectorType: {
                              value: '',
                            },
                            connectorMessage: {
                              value: '',
                            },
                            connectorField: {
                              value: '',
                            },
                            connectorPattern: {
                              value: '',
                            },
                            isHideField: {
                              value: false,
                            },
                            inputType: {
                              value: 'CheckBox',
                            },
                            width: {
                              value: '',
                            },
                            label: {
                              value: '',
                            },
                            readonly: {
                              value: true,
                            },
                            placeholderText: {
                              value: '',
                            },
                            value: {
                              value: '',
                            },
                            showBasedOnFieldName: {
                              value: '',
                            },
                            hintText: {
                              value: '',
                            },
                            subType: {
                              value: '',
                            },
                            name: {
                              value: 'isLegalNoticePrivacy',
                            },
                          },
                        },
                      ],
                    },
                  },
                  {
                    id: 'e309f46b-8fe1-4881-bea7-81ddab7d66bc',
                    name: 'Section 2',
                    displayName: 'Section 2',
                    fields: {
                      text: {
                        value:
                          '<p style="font-size=20px; color: #282a32;">By submitting my personal information, work experience and education, I confirm that I have read and agree to the Experis Privacy Policy, which explains how Experis collects, processes and shares my personal data. I consent to my data being processed in accordance with Experis&rsquo; Privacy Policy so that Experis can optimize my experience with the Experis brand.</p>',
                      },
                      showLineBar: {
                        value: false,
                      },
                      items: [
                        {
                          id: '3e6ee358-773a-4e84-9ed1-c917e02b660f',
                          name: 'Training Consent',
                          displayName: 'Training Consent',
                          fields: {
                            values: [
                              {
                                id: '5cd0a5fc-1ac8-44b0-a2d2-c4d16339eaa9',
                                fields: {
                                  label: {
                                    value: 'I Accept',
                                  },
                                  key: {
                                    value: 'True',
                                  },
                                },
                              },
                              {
                                id: '8fa43687-dd8c-4e0b-a29f-2fd618898bb0',
                                fields: {
                                  label: {
                                    value: 'I dont Accept',
                                  },
                                  key: {
                                    value: 'False',
                                  },
                                },
                              },
                            ],
                            position: {
                              value: 'horizontal',
                            },
                            labelPosition: {
                              value: '',
                            },
                            text: {
                              value:
                                '<p style="font-size=20px; color: #282a32;">Training Activities : With respect to the processing of my sensitive personal data, for the purpose of carrying out recruitment tasks or activities related to employment, when strictly necessary, as described in the&nbsp;<a href="/en/privacy-policy">Privacy Policy</a>.</p>',
                            },
                            placeholderText: {
                              value: '',
                            },
                            inputType: {
                              value: 'RadioButton',
                            },
                            width: {
                              value: '',
                            },
                            isHideField: {
                              value: false,
                            },
                            showBasedOnFieldName: {
                              value: '',
                            },
                            readonly: {
                              value: false,
                            },
                            value: {
                              value: '',
                            },
                            Validations: [
                              {
                                id: '7730be13-d71e-4584-b914-c68ad16b7554',
                                fields: {
                                  message: {
                                    value: '{0} is mandatory',
                                  },
                                  value: {
                                    value: 'true',
                                  },
                                  inputType: {
                                    value: 'requiredValidator',
                                  },
                                },
                              },
                            ],
                            connectorType: {
                              value: '',
                            },
                            connectorField: {
                              value: '',
                            },
                            connectorMessage: {
                              value: '',
                            },
                            connectorPattern: {
                              value: '',
                            },
                            name: {
                              value: 'isGeneralPrivacy',
                            },
                          },
                        },
                        {
                          id: 'b0fc0a68-164d-431d-b408-5cb40cddfccd',
                          name: 'Commercial Activity',
                          displayName: 'Commercial Activity',
                          fields: {
                            values: [
                              {
                                id: 'c07366f7-c36f-4061-85d1-d66a76c8f654',
                                fields: {
                                  key: {
                                    value: 'True',
                                  },
                                  label: {
                                    value: 'I Accept',
                                  },
                                },
                              },
                              {
                                id: 'bb8b1aa0-6998-4603-be86-8a49f6dd77aa',
                                fields: {
                                  key: {
                                    value: 'False',
                                  },
                                  label: {
                                    value: 'I do not accept',
                                  },
                                },
                              },
                            ],
                            position: {
                              value: 'horizontal',
                            },
                            labelPosition: {
                              value: '',
                            },
                            text: {
                              value:
                                '<p style="font-size=20px; color: #282a32;">Commercial Activity: Regarding the processing of my personal data by Experis ManpowerGroup, S.L.U. and / or the companies of the business group to which it belongs (*) (the "Data controller") in order to provide me with commercial and promotional information and commercial communication, including interactive communication, market studies, events, programs, contests and statistical studies, carried out by email, sms and mms (as described in more detail in the Privacy Policy. I understand that I can unsubscribe at any time or exercise my individual rights by following the steps described in the Privacy Notice, and that this consent is revocable, without retroactive effects, with the simple notification of your will to the sender, in accordance with the provisions of article 22 of Law 34/2002, of July 11, on Information Society services and Electronic Commerce.</p>',
                            },
                            placeholderText: {
                              value: '',
                            },
                            inputType: {
                              value: 'RadioButton',
                            },
                            width: {
                              value: '',
                            },
                            isHideField: {
                              value: false,
                            },
                            showBasedOnFieldName: {
                              value: '',
                            },
                            readonly: {
                              value: false,
                            },
                            value: {
                              value: '',
                            },
                            Validations: [
                              {
                                id: '7730be13-d71e-4584-b914-c68ad16b7554',
                                fields: {
                                  message: {
                                    value: '{0} is mandatory',
                                  },
                                  value: {
                                    value: 'true',
                                  },
                                  inputType: {
                                    value: 'requiredValidator',
                                  },
                                },
                              },
                            ],
                            connectorType: {
                              value: '',
                            },
                            connectorField: {
                              value: '',
                            },
                            connectorMessage: {
                              value: '',
                            },
                            connectorPattern: {
                              value: '',
                            },
                            name: {
                              value: 'isMarketingPrivacy',
                            },
                          },
                        },
                        {
                          id: '983422bd-4d08-4c15-825b-a183aea44c3c',
                          name: 'Sensitive Data',
                          displayName: 'Sensitive Data',
                          fields: {
                            values: [
                              {
                                id: 'c07366f7-c36f-4061-85d1-d66a76c8f654',
                                fields: {
                                  key: {
                                    value: 'True',
                                  },
                                  label: {
                                    value: 'I Accept',
                                  },
                                },
                              },
                              {
                                id: 'bb8b1aa0-6998-4603-be86-8a49f6dd77aa',
                                fields: {
                                  key: {
                                    value: 'False',
                                  },
                                  label: {
                                    value: 'I do not accept',
                                  },
                                },
                              },
                            ],
                            position: {
                              value: 'horizontal',
                            },
                            labelPosition: {
                              value: '',
                            },
                            text: {
                              value:
                                '<p style="font-size=20px; color: #282a32;">Sensitive Data: With respect to the processing of my sensitive personal data, for the purpose of carrying out recruitment tasks or activities related to employment, when strictly necessary, as described in the&nbsp;&nbsp;<a href="/en/privacy-policy">Privacy Policy</a></p>',
                            },
                            placeholderText: {
                              value: '',
                            },
                            inputType: {
                              value: 'RadioButton',
                            },
                            width: {
                              value: '',
                            },
                            isHideField: {
                              value: false,
                            },
                            showBasedOnFieldName: {
                              value: '',
                            },
                            readonly: {
                              value: false,
                            },
                            value: {
                              value: '',
                            },
                            Validations: [
                              {
                                id: '7730be13-d71e-4584-b914-c68ad16b7554',
                                fields: {
                                  message: {
                                    value: '{0} is mandatory',
                                  },
                                  value: {
                                    value: 'true',
                                  },
                                  inputType: {
                                    value: 'requiredValidator',
                                  },
                                },
                              },
                            ],
                            connectorType: {
                              value: '',
                            },
                            connectorField: {
                              value: '',
                            },
                            connectorMessage: {
                              value: '',
                            },
                            connectorPattern: {
                              value: '',
                            },
                            name: {
                              value: 'isSensitivePrivacy',
                            },
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              uid: 'cc2ed2a6-afe3-45dd-a90b-a3ed5f921e5d33',
              componentName: 'DeleteAccount',
              fields: {
                title: {
                  value: 'Delete Account',
                },
                content: {
                  value:
                    '<span style="background-color: #ffffff; color: #000000;">If you want to exercise your rights in accordance with the General Data Protection Regulation, </span><a rel="noopener noreferrer" href="https://app-de.onetrust.com/app/#/webform/a703c42d-bb4a-40d4-95f7-8d2ae55e1913" style="color: var(--communication-foreground,rgba(0, 90, 158, 1)); background-color: #ffffff;" target="_blank">Click here</a><span style="background-color: #ffffff; color: #000000;">.</span><div><span style="font-size: 14.6667px; text-indent: 24px; background-color: #ffffff; font-family: Calibri, sans-serif; color: #000000;"><br /></span></div>',
                },
              },
            },
            {
              uid: 'cc2ed2a6-afe3-45dd-a90b-a3ed5f921e5d44',
              componentName: 'CommunicationPreference',
              fields: {
                title: {
                  value: 'Communication Preference',
                },
              },
            },
          ],
        },
      },
    ],
  },
};
