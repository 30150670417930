/*eslint-disable*/
import { dataFetcher } from '../../dataFetcher';
import { CREATE_PROFILE, DASHBOARD, API_STATUS_CODES, API } from '../../constants';
import {
  clearProfileInfo,
  errorProfileInfo,
  getProPic,
  saveProfileInfo,
} from '../../redux/actions/actions';
import { dataURItoBlob, filterArrayByType } from '../../utils/helperUtils';
import { saveAs } from 'file-saver';
import { mockData } from '../../components/business/Dashboard/getCandidiateApiResponseMock';
import { CVmockData } from '../../components/business/BuildProfile/cvparserData';
import { jobApplyEvent } from './gtmService';
import { setCandidateId } from '../../redux/actions/loginActions';

const saveCandidateDetails = (data, dispatch) => {
  if (data?.Id) {
    dispatch(setCandidateId(data.Id));
    dispatch(
      saveProfileInfo({
        id: data?.Id,
        personalDetails: {
          ...(data?.CandidateDetailsFetchResponse?.data
            ? {
                ...data?.CandidateDetailsFetchResponse?.data[0],
                firstName: data?.CandidateDetailsFetchResponse?.data?.[0]?.firstName
                  ? decodeURIComponent(
                      data?.CandidateDetailsFetchResponse?.data?.[0]?.firstName
                    )
                  : data?.CandidateDetailsFetchResponse?.data?.[0]?.firstName,
                lastName: data?.CandidateDetailsFetchResponse?.data?.[0]?.lastName
                  ? decodeURIComponent(
                      data?.CandidateDetailsFetchResponse?.data?.[0]?.lastName
                    )
                  : data?.CandidateDetailsFetchResponse?.data?.[0]?.lastName,
                attachment: data?.CandidateAttachmentFetchResponse?.data
                  ? data?.CandidateAttachmentFetchResponse?.data
                  : [],
              }
            : {}),
        },
        experienceInfo: {
          data: data?.CandidateWorkHistoryFetchResponse?.data || [],
          formCount: data?.CandidateWorkHistoryFetchResponse?.count || 0,
        },
        expertiseAndSkills: {
          ...({
            primarySkills: data?.CandidateDetailsFetchResponse?.data
              ? data?.CandidateDetailsFetchResponse?.data[0]?.skills
              : [],
          } || {}),
        },
        educationInfo: {
          data: data?.CandidateEducationFetchResponse?.data || [],
          formCount: data?.CandidateEducationFetchResponse?.count || 0,
        },
        certificationInfo: {
          certifications: data?.CandidateDetailsFetchResponse?.data
            ? data?.CandidateDetailsFetchResponse?.data[0]?.certificationData
            : {},
        },
        jobSubmissionInfo: {
          data: data?.JobSubmissionFetchResponse?.data || [],
          formCount: data?.JobSubmissionFetchResponse?.count || 0,
        },
        profilepercentage: data?.Profilepercentage || '0',
        lastmodifiedDate: data?.LastmodifiedDate,
        isRedirect: true,
      })
    );
  } else {
    dispatch(clearProfileInfo());
    dispatch(errorProfileInfo());
  }
};

/**
 * @description - Get candidate api
 * @param {*} user
 * @param {*} dispatch
 */
export const getCandidateDetailsApi = (
  user,
  dispatch,
  isRedirectFromAdb2c = false,
  retries = 0
) => {
  const forceUpdateParam =
    isRedirectFromAdb2c === null ? false : isRedirectFromAdb2c;
  const inputParams = {
    entity: 'all',
    forceUpdate: forceUpdateParam,
  };
  dataFetcher(
    `${CREATE_PROFILE.GET_CREATE_PROFILE.CANDIDATE_DETAILS}`,
    'GET',
    {},
    inputParams
  ).then(
    (response) => {
      saveCandidateDetails(response.data, dispatch);
    },
    (error) => {
      if (retries > 0) {
        dispatch(clearProfileInfo());
        return getCandidateDetailsApi(
          {},
          dispatch,
          isRedirectFromAdb2c,
          retries - 1
        );
      } else {
        dispatch(clearProfileInfo());
        dispatch(errorProfileInfo());
      }
    }
  );
  //saveCandidateDetails(mockData, dispatch);
};

/**
 * @description - Upload attachment
 * @param {*} candidateId
 * @param {*} type
 * @param {*} list
 * @param {*} data
 */
export const uploadAttachmentApi = (candidateId, type, list, data) => {
  dataFetcher(
    `${CREATE_PROFILE.POST_CREATE_PROFILE.FILE_ATTACHMENT_UPLOAD}?id=${candidateId}&type=${type}&fileIdList=${list}`,
    'POST',
    data
  ).then(() => {});
};

/**
 * @description - Job Apply
 * @param {*} data
 */
export const jobApplyApi = (
  data,
  callback = () => {},
  hideLoader = () => {},
  showError = () => {},
  dispatch = () => {},
  jobDetails,
  revenuePerApplication,
  loginType,
  loginReducer,
  t
) => {
  dataFetcher(`${CREATE_PROFILE.POST_CREATE_PROFILE.JOB_APPLY}`, 'POST', data)
    .then((res) => {
      hideLoader();
      if (res?.data?.status === API_STATUS_CODES.SUCCESS) {
        const jobId = res?.data?.changedEntityId
          ? res?.data?.changedEntityId
          : jobDetails?.jobId;
        const loginState = loginReducer?.state;
        jobApplyEvent(
          jobId,
          revenuePerApplication,
          jobDetails,
          loginState,
          res?.data?.data,
          t
        );
        pushAppliedJobDetails(jobDetails);
        callback();
      } else if (res?.data?.status === API_STATUS_CODES.DATA_EXIST) {
        showError('already-applied-job', true);
      } else if (res?.data?.status === API_STATUS_CODES.NOT_FOUND) {
        showError('no-job-found');
      } else {
        showError();
      }
      if (loginType !== 'NA') {
        dispatch(clearProfileInfo());
        getCandidateDetailsApi({}, dispatch);
      }
    })
    .catch(function () {
      hideLoader();
      showError();
    });
};

/**
 * @description - Non Auth Job Apply and LoginType=NA used for Bankpower only
 * @param {*} data
 */
export const jobApplyApi_NoAuth = (
  data,
  callback = () => {},
  hideLoader = () => {},
  showError = () => {},
  dispatch = () => {},
  jobDetails,
  revenuePerApplication,
  loginType,
  loginReducer,
  t
) => {
  dataFetcher(
    `${
      loginType !== 'NA'
        ? CREATE_PROFILE.POST_CREATE_PROFILE.QUICK_APPLY
        : CREATE_PROFILE.POST_CREATE_PROFILE.JOB_APPLY_NO_AUTH
    }`,
    'POST',
    data
  )
    .then((res) => {
      hideLoader();
      if (res?.data?.status === API_STATUS_CODES.SUCCESS) {
        const jobId = res?.data?.changedEntityId
          ? res?.data?.changedEntityId
          : jobDetails?.jobId;
        const loginState = loginReducer?.state;
        jobApplyEvent(
          jobId,
          revenuePerApplication,
          jobDetails,
          loginState,
          res?.data?.data,
          t
        );
        pushAppliedJobDetails(jobDetails);
        callback();
      } else if (res?.data?.status === API_STATUS_CODES.DATA_EXIST) {
        showError('already-applied-job', true);
      } else if (res?.data?.status === API_STATUS_CODES.NOT_FOUND) {
        showError('no-job-found');
      } else {
        showError();
      }
      // if (loginType !== 'NA') {
      //   dispatch(clearProfileInfo());
      //   getCandidateDetailsApi({}, dispatch);
      // }
    })
    .catch(function () {
      hideLoader();
      showError();
    });
};

/**
 * @description- Download Attachment
 * @param {*} id
 * @param {*} cid
 */
export const attachementDownloadApi = (
  fileName,
  apiEndpoint,
  callback = () => {},
  errCallBack = () => {}
) => {
  dataFetcher(`${API.HOST}${apiEndpoint}`, 'GET')
    .then((res) => {
      if (res?.data.length > 0) {
        const file = res?.data.length > 0 && res?.data[0]?.File;
        const b64 = file.fileContent;
        const blob = dataURItoBlob(b64);
        saveAs(blob, file?.name);
      }
      callback(fileName);
    })
    .catch((err) => errCallBack(fileName));
};

/**
 * @description  Get profile pic
 * @param {*} dispatch
 */
export const getProfilePicApi = (dispatch) => {
  dataFetcher(`${DASHBOARD.GET_DASHBOARD.PROFILE_PICTURE}`, 'GET').then((res) => {
    const pic = res?.data?.fileContent
      ? `data:image/png;base64,${res.data.fileContent}`
      : '';
    dispatch(getProPic(pic));
    return pic;
  });
};

/**
 * @description - Update profile pic
 * @param {*} data
 * @param {*} dispatch
 */
export const updateProPicApi = (data, img, dispatch) => {
  dataFetcher(`${DASHBOARD.POST_DASHBOARD.PROFILE_PICTURE}`, 'POST', data).then(
    () => {
      dispatch(getProPic(img));
    }
  );
};

/**
 * @description- Download Attachment
 * @param {*} id
 * @param {*} cid
 */
export const resumeParseApi = (
  callback = () => {},
  data,
  params,
  callbackFailed = () => {}
) => {
  // callback(CVmockData);
  dataFetcher(
    `${CREATE_PROFILE.POST_CREATE_PROFILE.PARSE_RESUME}`,
    'POST',
    data,
    params
  ).then(
    (res) => {
      callback(res.data);
    },
    () => {
      callbackFailed();
    }
  );
};

export const buildProfileApi = (
  postData,
  callback = () => {},
  hideReviewLoader = () => {},
  errorCallback = () => {}
) => {
  dataFetcher(
    `${CREATE_PROFILE.POST_CREATE_PROFILE.POST_CANDIDATEDETAILS_ALL}`,
    'POST',
    postData
  ).then(
    (res) => {
      if (window?.dataLayer) {
        window.dataLayer.push({
          event: 'Forms',
          // eventCategory: 'Form submission',
          // formType: 'Build Profile Submission',
          // // formPosition: 'Build Profile',
          // relativeUrl: window.location?.pathname,
          eventCategory: 'Contact Us Submission',
          eventAction: 'Contact Us Submission',
          eventLabel: window.location?.pathname,
        });
      }
      if (res?.data?.status === API_STATUS_CODES.SUCCESS) {
        callback();
      } else {
        hideReviewLoader();
        errorCallback();
      }
    },
    () => {
      hideReviewLoader();
      errorCallback();
    }
  );
  (err) => {
    hideReviewLoader();
    errorCallback();
  };
};

const pushAppliedJobDetails = (data) => {
  const { jobId, jobTitle, domain } = data;
  if (window?.dataLayer) {
    window.dataLayer.push({
      event: 'JobApplication',
      // eventCategory: 'Job Application',
      // eventAction: 'Job Apply',
      // jobTitle: jobTitle,
      // jobReferenceNo: jobId,
      // jobIndustry: domain,
      // authenticationType: localStorage.getItem('authenticationType'),
      eventCategory: `Job Application | ${domain} Industry`,
      eventAction: `Job Apply | ${localStorage.getItem('authenticationType')}`,
      eventLabel: `${jobTitle} | ${jobId}`,
    });
  }
};
