import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SocialIcon from '../globals/icons/SocialIcon';
import { isNullorEmpty } from '../../../utils/helperUtils';

import './actionbars.scss';

/**
 * @description - Action bar social share.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template.
 */
const ActionBarSocialShare = (props) => {
  const { fields, sitecoreContext, sharableUrl, emailContent } = props;
  const { items } = fields;
  const [currentPageUrl, setCurrentPageUrl] = useState('');

  useEffect(() => {
    setCurrentPageUrl(window.location.href);
  }, []);

  return (
    <div className="social-bar social-bar-job">
      <div className="social-icons">
        {items &&
          Array.isArray(items) &&
          items?.map((item, index) => (
            <Fragment key={index}>
              <SocialIcon
                img={item?.fields?.icon}
                altText={`${item?.fields?.name?.value} icon`}
                tooltip={item?.fields?.name?.value}
                ctaUrl={item?.fields?.url?.value}
                displayName={
                  isNullorEmpty(item?.displayName)
                    ? item?.fields?.name?.value
                    : item?.displayName
                }
                arialabel={`${item?.fields?.name?.value} - Link will open in new window`}
                parentFields={
                  emailContent
                    ? emailContent
                    : isNullorEmpty(sitecoreContext?.route?.fields)
                    ? props
                    : sitecoreContext?.route?.fields
                }
                currentPageUrl={sharableUrl ? sharableUrl : currentPageUrl}
                utmQueryParam={item?.fields?.utmQueryParam?.value}
                setShareEvent={props.setShareEvent}
              />
            </Fragment>
          ))}
      </div>
    </div>
  );
};

ActionBarSocialShare.defaultProps = {
  // email: false,
  fields: {
    items: [],
  },
};

ActionBarSocialShare.propTypes = {
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

export default ActionBarSocialShare;
