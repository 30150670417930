import jwt_decode from 'jwt-decode';

/**
 * @description - to get jwt token payload
 * @param {object} store - redux store
 * @returns {undefined}
 */
export const getPayLoad = async (store) => {
  try {
    //const tokenProvider = await initAuth().getIdToken();
    //const token = tokenProvider.idToken.rawIdToken;
    //const token = sessionStorage.getItem('msal.idtoken');
    const {
      loginReducer: {
        idToken: {
          idToken: { rawIdToken },
        },
      },
    } = store.getState();
    if (rawIdToken) {
      return jwt_decode(rawIdToken);
    }
  } catch (e) {}
};
