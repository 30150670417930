import { createGlobalStyle } from 'styled-components';

export const ButtonStyles = createGlobalStyle`
.primary-button{
    color: ${({ theme }) => theme?.secondaryButton};
    border-color: ${({ theme }) => theme?.secondaryButton};
    &:hover {
        background-color: ${({ theme }) => theme?.secondaryButton};
    }
    &.reversed-search{
        &:hover {
            color: ${({ theme }) => theme?.secondaryButton} !important;
        }
    }
    &.reversed-button-link{
        &:hover {
            color: ${({ theme }) => theme?.secondaryButton} !important;
        }
    }
    &.reversed-white {
        &:hover {
            color: ${({ theme }) => theme?.secondaryButton} !important;
        }
    }
    &.reversed-button {
        color: ${({ theme }) => theme?.heading};
        border-color: ${({ theme }) => theme?.heading} !important;
        &:hover {
            background-color: ${({ theme }) => theme?.heading} !important;
            color: ${({ theme }) => theme?.secondaryColor} !important;
        }
    }
    &.blue{
        color: ${({ theme }) => theme?.primaryButton};
        border-color: ${({ theme }) => theme?.primaryButton};
        &:hover {
            background-color: ${({ theme }) => theme?.primaryButton} !important;
        }
    }
    &.orange {
        color: ${({ theme }) => theme?.secondaryButton};
        border-color: ${({ theme }) => theme?.secondaryButton};
        &:hover {
            color: white;
            background-color: ${({ theme }) => theme?.secondaryButton};
        }
    }
    &.orange-bg {
        background-color: ${({ theme }) => theme?.secondaryButton};
        border-color: ${({ theme }) => theme?.secondaryButton};
        &:hover {
            color: ${({ theme }) => theme?.secondaryButton} !important;
        }
    }
}

.connector-button {
    &.blue{
        .fat-line {
            background-color: ${({ theme }) => theme?.primaryButton} !important;
        }
        .line {
            .inner-line {
                background-color: ${({ theme }) => theme?.primaryButton} !important;
            }
        }
    }
    &.orange{
        .fat-line {
            background-color: ${({ theme }) => theme?.secondaryButton} !important;
        }
        .line {
            .inner-line {
                background-color: ${({ theme }) =>
                  theme?.secondaryButton} !important;
            }
        }
        @media (max-width: 992px){
            svg.bi.bi-arrow-right:hover{
                fill: ${({ theme }) => theme?.secondaryButton} !important; 
            }  
        }
    } 
}

.button-text {
    color: ${({ theme }) => theme?.secondaryButton};
    &.blue {
        color: ${({ theme }) => theme?.primaryButton};
      }
}
@media (max-width: 992px) {
    .primary-button.reversed-button-link {
        color: ${({ theme }) => theme?.secondaryButton} !important;
        border-color: ${({ theme }) => theme?.secondaryButton} !important;
        &:hover {
            background-color: ${({ theme }) => theme?.secondaryButton} !important;
            color: white !important;
        }
    }
}
`;
