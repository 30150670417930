import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { mediaApi } from '@sitecore-jss/sitecore-jss-react';

import { youtubeParser } from '../../../utils/helperUtils';

/**
 * @description - Card component for card scrolls.
 * @param {string} src - media source
 * @param {boolean} isVideo - is a video check
 * @returns {Node} - HTML card.
 */
const MediaCard = ({ src, isVideo }) => {
  const [videoSrc, setVideoSrc] = useState(null);
  const [imgVal, setImgVal] = useState(null);

  useEffect(() => {
    !isVideo
      ? setImgVal(mediaApi.updateImageUrl(src))
      : setVideoSrc(youtubeParser(src));
  }, []);

  return (
    <div className="card standard card--wipe media-card" id="job-resources">
      <figure
        className="article-image media-image"
        style={imgVal ? { backgroundImage: `url(${imgVal})` } : {}}
      >
        {videoSrc && (
          <iframe
            title="job resource video"
            src={`https://www.youtube.com/embed/${videoSrc}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            width="100%"
            height="100%"
            className="optanon-category-C0004"
          />
        )}
      </figure>
    </div>
  );
};

MediaCard.defaultProps = {
  src: '',
  isVideo: '',
};

MediaCard.propTypes = {
  src: PropTypes.string,
  isVideo: PropTypes.string,
};

export default MediaCard;
