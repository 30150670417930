import React from 'react';
import PropTypes from 'prop-types';
import FilterItem from '../../Filters/FilterItem';

import Pagination from '../../globals/pagination/Pagination';
/**
 * @description to show pagination in search results
 * @param {boolean} showJobAlert - whether to show job alert or not
 * @returns {Node} - html
 */
const SearchPagination = ({
  showJobAlert,
  handlePageChange,
  totalRecords,
  pageLimit,
  selectedPage,
  label,
}) => {
  return (
    <section className="search-pagination">
      <div className="container">
        <div className="search-pagination-wrap">
          <Pagination
            totalRecords={totalRecords}
            pageLimit={pageLimit}
            onPageChanged={handlePageChange}
            selectedPage={selectedPage}
            label={label}
          ></Pagination>
          {showJobAlert && (
            <FilterItem
              text="Create Alert for Future Jobs"
              dropdown="job-alert"
              mode="right"
            />
          )}
        </div>
      </div>
    </section>
  );
};

SearchPagination.defaultProps = {
  showJobAlert: true,
  handlePageChange: () => {},
  totalRecords: 0,
  pageLimit: 0,
  selectedPage: 1,
  label: 'Page',
};

SearchPagination.propTypes = {
  showJobAlert: PropTypes.bool,
  handlePageChange: PropTypes.func,
  totalRecords: PropTypes.number,
  pageLimit: PropTypes.number,
  selectedPage: PropTypes.number,
  label: PropTypes.string,
};

export default SearchPagination;
