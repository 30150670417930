/* eslint-disable */
import { GTM } from '../../../src/constants';

const enums = {
  AUTHENTICATED: 'Authenticated',
  UNAUTHENTICATED: 'Non-authenticated',
};

const hasProfile = (responseData, loginState) => {
  return (
    !responseData?.hasResume &&
    !responseData?.hasExperience &&
    !responseData?.hasEducation &&
    loginState === 'AUTHENTICATED'
  );
};

const getExpOrEdu = (responseData, t) => {
  return `${
    responseData?.hasExperience ? t('Experience') : t('No Experience')
  } and ${responseData?.hasEducation ? t('Education') : t('No Education')}`;
};

export const jobApplyEvent = (
  applicationId,
  revenuePerApplication,
  data = {},
  loginState,
  responseData = {},
  t
) => {
  if (window?.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: GTM.EVENTS.JOB_APPLICATION_SUCCESS,
      ecommerce: {
        purchase: {
          actionField: {
            id: applicationId,
            revenue: revenuePerApplication || '',
          },
          products: [
            {
              name: data?.jobTitle || 'na',
              id: data?.jobId || 'na',
              price: revenuePerApplication || 'na',
              brand: data?.companyName ? data?.companyName : 'na',
              category: data?.domain || 'na',
              variant: data?.employmentType || 'na',
              quantity: 1,
              dimension11: `Location: ${data?.jobLocation || 'na'} | Education: ${
                data?.education ? data?.education.join(', ') : 'na'
              } | Salary: ${
                data?.salaryRate
                  ? data?.salaryRate
                  : data?.salaryUnit
                  ? data?.salaryUnit
                  : 'na'
              } | Hours: ${data?.JobType || 'na'}`,
              dimension12: `${enums?.[loginState || 'UNAUTHENTICATED']}`,
              dimension13: `${
                enums?.[loginState || 'UNAUTHENTICATED'] === 'Non-authenticated'
                  ? 'quick apply'
                  : 'standard apply'
              }`,
              dimension14: hasProfile(responseData, loginState)
                ? t('Existing Profile')
                : responseData?.hasResume
                ? t('With resume')
                : t('With resume'),
              dimension15: hasProfile(responseData, loginState)
                ? t('Existing Profile')
                : getExpOrEdu(responseData, t),
              dimension16: data?.clientID ? data.clientID : 'na',
              dimension17: data?.jobLocation ? data?.jobLocation : 'na',
              dimension18: data?.education ? data?.education.join(', ') : 'na',
              dimension19: data?.salaryRate ? data?.salaryRate : 'na',
              dimension20: data?.JobType ? data?.JobType : 'na',
            },
          ],
        },
      },
    });
  }
};

export const jobResultsEvent = (jobs = [], revenue, isCompanySearch, loginState) => {
  if (window?.dataLayer) {
    const impressions = [];
    jobs?.forEach((job, index) => {
      impressions.push({
        name: job.jobTitle || 'na',
        id: job.jobID || job.positionID || 'na',
        price: revenue || 'na',
        brand: job?.companyName ? job.companyName : 'na',
        category: job.domain || 'na',
        variant: job.employmentType || 'na',
        list: isCompanySearch ? 'Vacancies by employer' : 'Search Results',
        position: index + 1,
        dimension11: `Location: ${job?.jobLocation || 'na'} | Education: ${
          job?.education ? job?.education.join(', ') : 'na'
        } | Salary: ${job?.salaryRate || 'na'} | Hours: ${job?.jobType || 'na'}`,
        dimension12: `${enums?.[loginState || 'UNAUTHENTICATED']}`,
        dimension13: `${
          enums?.[loginState || 'UNAUTHENTICATED'] === 'Non-authenticated'
            ? 'quick apply'
            : 'standard apply'
        }`,
        dimension14: 'na',
        dimension15: 'na',
        dimension16: job?.clientID ? job?.clientID : 'na',
        dimension17: job?.jobLocation ? job?.jobLocation : 'na',
        dimension18: job?.education ? job?.education.join(', ') : 'na',
        dimension19: job?.salaryRate ? job?.salaryRate : 'na',
        dimension20: job?.jobType ? job?.jobType : 'na',
      });
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: isCompanySearch
        ? GTM.EVENTS.SEO_JOB_SEARCH_RESULTS
        : GTM.EVENTS.JOB_SEARCH_RESULTS,
      ecommerce: {
        impressions: impressions,
      },
    });
  }
};

export const jobVacancyEvent = (job, revenue, loginState) => {
  if (window?.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: GTM.EVENTS.JOB_DETAILS,
      pageVariant: job?.jobItemType ? job?.jobItemType : 'na',
      ecommerce: {
        detail: {
          products: [
            {
              name: job?.jobTitle || 'na',
              id: job?.jobID || job?.positionID || 'na',
              price: revenue || 'na',
              brand: job?.companyName ? job?.companyName : 'na',
              category: job?.domain || 'na',
              variant: job?.employmentType || 'na',
              quantity: 1,
              dimension11: `Location: ${job?.jobLocation || 'na'} | Education: ${
                job?.education ? job?.education.join(', ') : 'na'
              } | Salary: ${job?.salaryRate || 'na'} | Hours: ${
                job?.jobType || 'na'
              }`,
              dimension12: `${enums?.[loginState || 'UNAUTHENTICATED']}`,
              dimension13: `${
                enums?.[loginState || 'UNAUTHENTICATED'] === 'Non-authenticated'
                  ? 'quick apply'
                  : 'standard apply'
              }`,
              dimension14: 'na',
              dimension15: 'na',
              dimension16: job?.clientID ? job?.clientID : 'na',
              dimension17: job?.jobLocation ? job?.jobLocation : 'na',
              dimension18: job?.education ? job?.education.join(', ') : 'na',
              dimension19: job?.salaryRate ? job?.salaryRate : 'na',
              dimension20: job?.jobType ? job?.jobType : 'na',
            },
          ],
        },
      },
    });
  }
};

export const preJobApplyEvent = (jobStorageDetails, revenue, loginState) => {
  if (window?.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: GTM.EVENTS.JOB_APPLICATION,
      ecommerce: {
        checkout: {
          actionField: { step: 1 },
          products: [
            {
              name: jobStorageDetails?.jobTitle || 'na',
              id: jobStorageDetails?.jobId || 'na',
              price: revenue || 'na',
              brand: jobStorageDetails?.companyName
                ? jobStorageDetails?.companyName
                : 'na',
              category: jobStorageDetails?.domain || 'na',
              variant: jobStorageDetails?.employmentType || 'na',
              quantity: 1,
              dimension11: `Location: ${
                jobStorageDetails?.jobLocation || 'na'
              } | Education: ${
                jobStorageDetails?.education
                  ? jobStorageDetails?.education.join(', ')
                  : 'na'
              } | Salary: ${
                jobStorageDetails?.salaryRate
                  ? jobStorageDetails?.salaryRate
                  : jobStorageDetails?.salaryUnit
                  ? jobStorageDetails?.salaryUnit
                  : 'na'
              } | Hours: ${jobStorageDetails?.JobType || 'na'}`,
              dimension12: `${enums?.[loginState || 'UNAUTHENTICATED']}`,
              dimension13: `${
                enums?.[loginState || 'UNAUTHENTICATED'] === 'Non-authenticated'
                  ? 'quick apply'
                  : 'standard apply'
              }`,
              dimension14: 'na',
              dimension15: 'na',
              dimension16: jobStorageDetails?.clientID
                ? jobStorageDetails?.clientID
                : 'na',
              dimension17: jobStorageDetails?.jobLocation
                ? jobStorageDetails?.jobLocation
                : 'na',
              dimension18: jobStorageDetails?.education
                ? jobStorageDetails?.education.join(', ')
                : 'na',
              dimension19: jobStorageDetails?.salaryRate
                ? jobStorageDetails?.salaryRate
                : 'na',
              dimension20: jobStorageDetails?.JobType
                ? jobStorageDetails?.JobType
                : 'na',
            },
          ],
        },
      },
    });
  }
};

export const searchResultClickEvent = (
  eventName,
  job,
  revenue,
  loginState,
  jobCategory,
  isCompanySearch,
  index
) => {
  if (window?.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: eventName,
      ecommerce: {
        click: {
          actionField: {
            list: `${
              jobCategory
                ? `Vacancies by ${jobCategory}`
                : `${isCompanySearch ? `Vacancies by employer` : `Search Results`}`
            }`,
          },
          products: [
            {
              name: job?.jobTitle || 'na',
              id: job?.jobID || job?.positionID || 'na',
              price: revenue || 'na',
              brand: job?.companyName ? job?.companyName : 'na',
              category: job?.domain || 'na',
              variant: job?.employmentType || 'na',
              quantity: 1,
              position: index + 1,
              dimension11: `Location: ${job?.jobLocation || 'na'} | Education: ${
                job?.education ? job?.education.join(', ') : 'na'
              } | Salary: ${job?.salaryRate || 'na'} | Hours: ${
                job?.jobType || 'na'
              }`,
              dimension12: `${enums?.[loginState || 'UNAUTHENTICATED']}`,
              dimension13: `${
                enums?.[loginState || 'UNAUTHENTICATED'] === 'Non-authenticated'
                  ? 'quick apply'
                  : 'standard apply'
              }`,
              dimension14: 'na',
              dimension15: 'na',
              dimension16: job?.clientID ? job?.clientID : 'na',
              dimension17: job?.jobLocation ? job?.jobLocation : 'na',
              dimension18: job?.education ? job?.education.join(', ') : 'na',
              dimension19: job?.salaryRate ? job?.salaryRate : 'na',
              dimension20: job?.jobType ? job?.jobType : 'na',
            },
          ],
        },
      },
    });
  }
};

export const seojobResultsEvent = (jobs = [], revenue, loginState, jobCategory) => {
  if (window?.dataLayer) {
    const impressions = [];
    jobs?.forEach((job, index) => {
      impressions.push({
        name: job?.jobTitle || 'na',
        id: job?.jobID || job?.positionID || 'na',
        price: revenue || 'na',
        brand: job?.companyName ? job.companyName : 'na',
        category: job?.domain || 'na',
        variant: job?.employmentType || 'na',
        list: `${jobCategory ? `Vacancies by ${jobCategory}` : 'Vacancies'}`,
        position: index + 1,
        dimension11: `Location: ${job?.jobLocation || 'na'} | Education: ${
          job?.education ? job?.education.join(', ') : 'na'
        } | Salary: ${job?.salaryRate || 'na'} | Hours: ${job?.jobType || 'na'}`,
        dimension12: `${enums?.[loginState || 'UNAUTHENTICATED']}`,
        dimension13: `${
          enums?.[loginState || 'UNAUTHENTICATED'] === 'Non-authenticated'
            ? 'quick apply'
            : 'standard apply'
        }`,
        dimension14: 'na',
        dimension15: 'na',
        dimension16: job?.clientID ? job?.clientID : 'na',
        dimension17: job?.jobLocation ? job?.jobLocation : 'na',
        dimension18: job?.education ? job?.education.join(', ') : 'na',
        dimension19: job?.salaryRate ? job?.salaryRate : 'na',
        dimension20: job?.jobType ? job?.jobType : 'na',
      });
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: GTM.EVENTS.SEO_JOB_SEARCH_RESULTS,
      ecommerce: {
        impressions: impressions,
      },
    });
  }
};
