import React, { Fragment, useEffect, useState } from 'react';
import i18n from 'i18next';
import { useCookies } from 'react-cookie';
import { Redirect, useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import usePreviousLocation from './hooks/usePreviousLocation';

/**
 * @description - Error Page
 * @param {object} param0 - Site params.
 * @returns {Node} - HTML node.
 */
const ErrorPage = ({
  context = { site: { name: '' } },
  currentLang,
  unauthorized = false,
  notFound = false,
}) => {
  const [langVal, setLangVal] = useState(context?.language || currentLang);
  const [cookies] = useCookies();
  const location = useLocation();
  const previousPath = usePreviousLocation(location);

  useEffect(() => {
    const siteName = context?.site?.name;
    if (siteName) {
      setLangVal(
        cookies[`${siteName.toLowerCase()}#lang`] || context?.language || currentLang
      );
    }
  }, [cookies]);
  if (unauthorized) {
    return <Redirect to={`/${langVal}/unauthorized`} />;
  }
  if (notFound) {
    return (
      <Fragment>
        <Helmet>
          <title>{i18n.t('Page not found')}</title>
        </Helmet>
        <Redirect to={`/${langVal}/page-not-found?path=${previousPath}`} />
      </Fragment>
    );
  }
};

export default ErrorPage;
