/*eslint-disable*/
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import '../../apply_job_flow.scss';
import './statusText.scss';
import { useHistory } from 'react-router-dom';
import PrivateRoute from '../../../../core/PrivateRoute';
import NoData from '../../../../core/ErrorBoundary/NoData';

/**
 * @description - Success component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML template.
 */
const JobApplyStatusText = ({
  fields,
  userDetails,
  t,
  params,
  sitecoreContext,
  dispatch,
}) => {
  const [jobStorageDetails, setJobStorageDetails] = useState({});
  const { text } = fields;
  const history = useHistory();
  const [newText, setText] = useState({ value: '' });
  const [candidateName, setCandidateName] = useState('');
  const [redirection, setRedirection] = useState(false);
  // const [searchUrl, setSearchUrl] = useState('/');

  useEffect(() => {
    setText(text);
    if (sessionStorage) {
      let data = {};
      data =
        sessionStorage.getItem('jobDetails') !== null
          ? JSON.parse(sessionStorage.getItem('jobDetails'))
          : {};
      if (Object.keys(data).length === 0) {
        setRedirection(true);
        history.push({ pathname: sitecoreContext?.Country?.jobSearchURL });
      }
      let candidate =
        sessionStorage.getItem('candidateName') &&
        sessionStorage.getItem('candidateName') !== null
          ? sessionStorage.getItem('candidateName')
          : '';
      setCandidateName(candidate);
      setJobStorageDetails(data);
      if (candidate) {
        text.value = text?.value?.replace('(_)', candidate || t('user'));
        setText(text);
      }
    }

    return () => {
      if (sessionStorage) {
        sessionStorage.removeItem('jobDetails');
        sessionStorage.removeItem('candidateName');
      }
    };
  }, []);

  useEffect(() => {
    if (candidateName) {
      text.value = text?.value?.replace('(_)', candidateName || t('user'));
      setText(text);
    }
  }, [candidateName]);

  return (
    <>
    {Object.keys(jobStorageDetails).length > 0 ? (
      <Fragment>
        <div className="col-lg-6 offset-lg-3 job-title">
          <span>{t('you-have-applied-to')}&nbsp;</span>
          <span className="job-name">
            <Link to={jobStorageDetails?.jobURL}>{jobStorageDetails?.jobTitle}</Link>
          </span>
        </div>
        {!redirection && (
          <div className="create-profile-success-flex col-lg-6 offset-lg-3">
            <div className="container greet-container">
              {newText && <RichText field={newText} className="congrats-section" />}
            </div>
          </div>
        )}
      </Fragment>
      ) : (
        <NoData message={t('no-data-exists')} />
      )}
    </>
  );
};

JobApplyStatusText.propTypes = {
  name: PropTypes.string,
  t: PropTypes.func.isRequired,
  jobName: PropTypes.string,
  sitecoreContext: PropTypes.shape({}).isRequired,
  fields: PropTypes.shape({
    text: PropTypes.shape({
      value: PropTypes.string,
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

JobApplyStatusText.defaultProps = {
  fields: {},
};
const mapStateToProps = (state) => {
  return {
    userDetails: state.loginReducer.user,
  };
};
export default withSitecoreContext()(
  connect(mapStateToProps)(withTranslation()(JobApplyStatusText))
);
