/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import $ from 'jquery';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss';
import HubspotForm from 'react-hubspot-form';
import { connect, useDispatch } from 'react-redux';
import { showHubspotForm } from '../../../redux/actions';

import './hubspot.scss';

const HubspotFormApp = (props, rendering) => {
  const { fields, t } = props;
  const formId = fields?.HubspotFormID?.value;
  const portalId = fields?.HubspotPortalID?.value;
  const hubspotTitle = fields?.hubspotTitle?.value
    ? fields?.hubspotTitle?.value
    : '';
  const hubspotLocation = props?.fields?.cssClass?.value
    ? props?.fields?.cssClass?.value
    : '';
  const dispatch = useDispatch();
  const location = useLocation();
  const [loadForm, setLoadForm] = useState(false);

  useEffect(() => {
    setLoadForm(true);
    window.addEventListener('hbsp-form-reload', () => {
      dispatch(showHubspotForm(OptanonActiveGroups));
    });
  }, []);

  if (
    typeof formId !== 'undefined' &&
    formId.length > 0 &&
    typeof portalId !== 'undefined' &&
    portalId.length > 0
  ) {
    return (
      <>
        {props?.showForm && props?.updatedCategories ? (
          <Helmet>
            {(function () {
              window.jQuery = window.jQuery || $;
            })()}
          </Helmet>
        ) : null}
        <div className={`container ${hubspotLocation}`}>
          <div className="row">
            <div className={`hubspotForm col d-flex justify-content-center`}>
              <div className="hubspotForm-content">
                {hubspotTitle && (
                  <h2 id="formTitle" className="hubspotformTitle">
                    {hubspotTitle ? hubspotTitle : ''}
                  </h2>
                )}
                <div className="hubspot-render">
                  {(props?.showForm && props?.updatedCategories) || loadForm ? (
                    <HubspotForm
                      portalId={portalId}
                      formId={formId}
                      onSubmit={() => {
                        if (window?.dataLayer) {
                          window.dataLayer.push({
                            event: 'Forms',
                            // eventCategory: 'Form submission',
                            // formType: params?.hubspotTitle,
                            // formPosition: params?.hubspotLocation,
                            // relativeUrl: location?.pathname,
                            eventCategory: hubspotLocation?.replace(/\-/g, ' '),
                            eventAction: hubspotLocation?.replace(/\-/g, ' '),
                            eventLabel: location?.pathname,
                          });
                        }
                      }}
                      // onReady={(form) => console.log('Form ready!')}
                      loading={
                        <div className="d-flex justify-content-center">
                          {t('loading')}
                        </div>
                      }
                    />
                  ) : (
                    <div className="d-flex justify-content-center">
                      {t('onetrust-error')}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (isExperienceEditorActive()) {
    return (
      <>
        <div className="container-fluid  pt-3 px-0">
          <div className="card text-center">
            <div className="card-header">{rendering.componentName}</div>
            <div className="card-body">
              <p className="card-text">{t('hbspot-rendering-parameters')}</p>
            </div>
          </div>
        </div>
      </>
    );
  }
  return <div className="hubspotForm-empty"></div>;
};

const mapStateToProps = (state) => {
  return {
    showForm: state.hubspotFormReducer.showForm,
    updatedCategories: state.hubspotFormReducer.activeGroups,
  };
};

export default withTranslation()(connect(mapStateToProps)(HubspotFormApp));
