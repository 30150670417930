import React from 'react';
import PropTypes from 'prop-types';
// import { Text } from '@sitecore-jss/sitecore-jss-react';
import CardEvent from '../../Cards/CardEvent';
import { withTranslation } from 'react-i18next';
import NoData from '../../ErrorBoundary/NoData';
// import moment from 'moment';
import Slider from 'react-slick';
import { isDataExists } from '../../../../utils/helperUtils';
import '../cardscroll.scss';

/**
 * @description Event Card Scroll
 */
class EventsCardScroll extends React.Component {
  /**
   * @description constructor method
   * @param {object} props -inputprops
   */
  constructor(props) {
    super(props);
  }

  /**
   * @description - descrition
   * @param{object} - input
   * @returns{node}- html block
   */
  render() {
    const {
      fields,
      t,
      rendering: { uid },
    } = this.props;
    const { items = [] } = fields;
    const isItemExists = isDataExists(items);

    /**
     * @description Slide load hook.
     * @returns {undefined}
     */
    const slideLoadHook = () => {
      if (typeof window !== 'undefined') {
        document.querySelectorAll(`.scroll_${uid} .slick-slide`).forEach((node) => {
          if (node.className.includes('slick-active')) {
            // node.style.visibility = '';
            node.setAttribute('aria-hidden', 'false');
          } else {
            // node.style.visibility = 'hidden';
            node.setAttribute('aria-hidden', 'true');
          }
        });
      }
    };

    const settings = {
      dots: true,
      // infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: false,
      accessibility: true,
      lazyLoad: true,
      arrows: true,
      onLazyLoad: () => slideLoadHook(),
      afterChange: () => slideLoadHook(),
      responsive: [
        {
          breakpoint: 992,
          settings: {
            dots: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            dots: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 564,
          settings: {
            dots: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <section className={`card-scroll blue scroll_${uid}`}>
        <div className="container">
          <div className="card-scroll-header">
            <div className="title">{t('more-events')}</div>
          </div>
          {isItemExists ? (
            <Slider {...settings}>
              {items.map((item, ind) => {
                const itemVal = item?.fields || {};
                return (
                  <CardEvent
                    key={`cardEvent_${ind}`}
                    headline={itemVal?.headline}
                    startDate={itemVal?.startDate}
                    ctaText={t('lorem-ipsum')}
                    altText={`Card Event ${ind}`}
                    ctaUrl={itemVal?.url}
                  />
                );
              })}
            </Slider>
          ) : (
            <NoData message={'No Data'} />
          )}
        </div>
      </section>
    );
  }
}

EventsCardScroll.defaultProps = {
  fields: {
    items: [],
  },
};

EventsCardScroll.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  rendering: PropTypes.shape({ uid: PropTypes.string }).isRequired,
};

export default withTranslation()(EventsCardScroll);
