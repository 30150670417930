/* eslint-disable react/destructuring-assignment */
import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import SearchAccordionSection from './SearchAccordionSection';
import { CollapseFilterContext } from '../../../business/Contexts';
/**
 * @description - Accordion Block component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const SearchAccordion = (props) => {
  const [openSections, setOpenSession] = useState({});
  const filterVals = useContext(CollapseFilterContext) || {};
  const isInitialMount = useRef(true);

  /**
   * @description - to handle accordian click
   * @param {number} id - id
   * @returns {undefined}
   */
  const handleClick = (id) => {
    const isOpen = !!openSections[id];

    if (props.allowMultipleOpen) {
      const newSection = {
        [id]: !isOpen,
      };
      setOpenSession({
        ...openSections,
        ...newSection,
      });
    } else {
      setOpenSession({
        [id]: !isOpen,
      });
    }

    filterVals.setExpandedFilters((prev) => {
      if (!openSections[id]) {
        return prev + 1;
      }
      return prev - 1;
    });
    // props.handleAccordionClick(`accordion${id}`);
  };

  //collapse & expand on click
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      // Your useEffect code here to be run on update
      let obj = {};
      let openCount = 0;
      Object.keys(openSections).forEach(function (key) {
        if (filterVals?.isCollapse == false) {
          openCount += 1;
        }
        obj = { ...obj, ...{ [key]: filterVals?.isCollapse ? false : true } };
      });
      filterVals.setExpandedFilters(openCount);
      setOpenSession(obj);
    }
  }, [filterVals?.collapseButton]);

  return (
    <div role="list" className="search-accordion-section">
      {props?.data?.map((child, index) => {
        if (props?.filterContent(child)) {
          return (
            <SearchAccordionSection
              focus={props?.focusId === index ? true : false}
              isOpen={!!openSections[index]}
              key={index}
              id={index}
              onClick={() => handleClick(index)}
              title={child}
              filterContent={props?.filterContent(child)}
              setOpenSession={setOpenSession}
              selected={props?.selected}
              sortOption={props?.sortOption}
              defaultValues={props?.defaultValues}
              icons={filterVals?.mediaIcons}
            />
          );
        }
      })}
    </div>
  );
};

SearchAccordion.defaultProps = {
  allowMultipleOpen: false,
  data: [],
  handleAccordionClick: () => {},
};

SearchAccordion.propTypes = {
  allowMultipleOpen: PropTypes.bool,
  data: PropTypes.instanceOf(Object).isRequired,
  handleAccordionClick: PropTypes.func,
};

export default SearchAccordion;
