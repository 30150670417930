import { createGlobalStyle } from 'styled-components';

export const FormStyles = createGlobalStyle`
.skills-list {
    input[type='checkbox']:checked + label::before {
      background-color: ${({ theme }) => theme?.primaryButton};
    }
}
.checkbox {
  input[type='checkbox']:checked + label::before {
    background-color: ${({ theme }) => theme?.primaryButton};
  }
}
.chkBox input:checked ~ .checkmark {
  background-color: ${({ theme }) => theme?.primaryButton} !important;
  border-color: ${({ theme }) => theme?.primaryButton} !important;
}
`;
