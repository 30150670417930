/*eslint-disable*/
import lget from 'lodash.get';
import DashboardProfile from '../Dashboard/LeftBarContent/DashboardProfile';
import DashboardNav from '../Dashboard/LeftBarContent/DashboardNav';
import DashboardProfileMobile from '../Dashboard/LeftBarContent/DashboardProfileMobile';

import DashboardProgressBarBlock from '../Dashboard/MainContent/DashboardProgressBarBlock';
import ProgressBarBlock from '../Dashboard/MainContent/ProgressBarBlock';

import DashboardApplications from '../Dashboard/MainContent/DashboardApplications';
import DashboardSuggestedJobs from '../Dashboard/MainContent/DashboardSuggestedJobs';
import DashboardSavedJobs from '../Dashboard/MainContent/DashboardSavedJobs';

import DashboardAccountAccordion from '../Dashboard/MainContent/DashboardAccountAccordion';
import DashboardProfileAccordion from '../Dashboard/MainContent/DashboardProfileAccordion';

import AccountDetails from '../Dashboard/MainContent/DashboardAccountAccordion/AccountDetails';
import CommunicationPreference from '../Dashboard/MainContent/DashboardAccountAccordion/CommunicationPreference';
import ConsentForm from '../Dashboard/MainContent/DashboardAccountAccordion/ConsentForm';
import DeleteAccount from '../Dashboard/MainContent/DashboardAccountAccordion/DeleteAccount';

import PersonalInfo from '../Dashboard/MainContent/DashboardProfileAccordion/PersonalInfo';
import Certifications from '../Dashboard/MainContent/DashboardProfileAccordion/Certifications';
import CoverLetters from '../Dashboard/MainContent/DashboardProfileAccordion/CoverLetters';
import Education from '../Dashboard/MainContent/DashboardProfileAccordion/Education';
import Experience from '../Dashboard/MainContent/DashboardProfileAccordion/Experience';
import ExpertiseAndSkills from '../Dashboard/MainContent/DashboardProfileAccordion/ExpertiseAndSkills';
import Resume from '../Dashboard/MainContent/DashboardProfileAccordion/Resume';
import BuildProfileAccordion from '../BuildProfile/BuildProfileAccordion';
import UploadCandidateResume from '../BuildProfile/UploadResume';
import EditPersonalInfo from '../BuildProfile/BuildProfileAccordion/EditPersonalInfo';
import EditExperience from '../BuildProfile/BuildProfileAccordion/EditExperience';
import EditEducation from '../BuildProfile/BuildProfileAccordion/EditEducation';
import EditExpertiseAndSkills from '../BuildProfile/BuildProfileAccordion/EditExpertiseAndSkills';
import EditCertifications from '../BuildProfile/BuildProfileAccordion/EditCertifications';
import EditCoverLetters from '../BuildProfile/BuildProfileAccordion/EditCoverLetters';
import CareerResources from '../Dashboard/MainContent/CareerResources';

//import DashboardAgent from './MainContent/Sample/DashboardAgent';
//import DashboardCallout from './MainContent/Sample/DashboardCallout';

/*eslint-disable*/
export const colorSelection = {
  green: '#5C7D70',
  brown: '#9D323D',
  black: '#000000',
  default: '#00000',
};

export const Components = {
  DashboardProfile: DashboardProfile,
  DashboardNav: DashboardNav,
  DashboardProfileMobile: DashboardProfileMobile,
  DashboardProgressBarBlock: DashboardProgressBarBlock,
  ProgressBarBlock: ProgressBarBlock,
  DashboardSavedJobs: DashboardSavedJobs,
  DashboardSuggestedJobs: DashboardSuggestedJobs,
  DashboardApplications: DashboardApplications,
  AccountAccordion: DashboardAccountAccordion,
  ProfileAccordion: DashboardProfileAccordion,
  AccountDetails: AccountDetails,
  ConsentForm: ConsentForm,
  CommunicationPreference: CommunicationPreference,
  DeleteAccount: DeleteAccount,
  PersonalInfo: PersonalInfo,
  Experience: Experience,
  Education: Education,
  ExpertiseAndSkills: ExpertiseAndSkills,
  Certifications: Certifications,
  CoverLetters: CoverLetters,
  Resume: Resume,
  EditPersonalInfo: EditPersonalInfo,
  EditExperience: EditExperience,
  EditEducation: EditEducation,
  EditExpertiseAndSkills: EditExpertiseAndSkills,
  EditCertifications: EditCertifications,
  EditCoverLetters: EditCoverLetters,
  BuildProfileAccordion: BuildProfileAccordion,
  UploadCandidateResume: UploadCandidateResume,
  DashboardCareerResources: CareerResources,
  CareerResources: CareerResources,
};

export const formObjIteration = (obj, val = 'value') => {
  Object.keys(obj).forEach((key) => {
    const objValue = obj[key];
    if (!objValue || typeof objValue !== 'object') {
      if (/_hidden$/.test(key) || /_picker$/.test(key)) {
        delete obj[key];
      } else {
        obj[key] = objValue;
      }
    } else {
      if (Object.keys(objValue).includes('label')) {
        if (/_hidden$/.test(key) || /_picker$/.test(key)) {
          delete obj[key];
        } else {
          obj[key] = objValue[val];
        }
      } else {
        obj[key] = formObjIteration(objValue, val);
      }
    }
  });
  return obj;
};

export const recursiveWithConstantCallback = (obj, constObj) => {
  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === 'object') {
      recursiveWithConstantCallback(val, constObj);
    } else {
      obj[key] =
        constObj[val] !== '' && constObj[val] !== undefined && constObj[val] !== null
          ? constObj[val]
          : val;
    }
  });
  return obj;
};

export const YesNo = {
  true: 'Yes',
  false: 'No',
};

export const yesno = {
  Yes: 'yes',
  No: 'no',
};

export const defineKeyValueObjFromObjectArray = (array, result = {}) => {
  let temp = result;
  array.forEach((item, index) => {
    let key = item?.fields?.key?.value ? item?.fields?.key?.value : index;
    temp[key] = item?.fields?.label?.value;
  });
  return temp;
};

export const checkProperties = (obj) => {
  for (var key in obj) {
    if (!obj[key]) return false;
  }
  return true;
};

export const replaceTextWithValues = (template, replacements, missingText = '') => {
  let resultStr = template;
  if (template) {
    resultStr = template.replace(/{([^{^}]+)}/g, (match, key) => {
      return replacements?.[key] !== undefined && replacements?.[key] !== null
        ? replacements?.[key]
        : missingText;
    });
  }
  return resultStr;
};

export const isTrue = (value) => {
  if (typeof value === 'string') {
    value = value.trim().toLowerCase();
  }
  switch (value) {
    case 'true':
    case 1:
    case '1':
    case 'on':
    case 'yes':
    case 'y':
      return true;
    case 'false':
    case 0:
    case '0':
    case 'off':
    case 'no':
    case 'n':
      return false;
    default:
      return null;
  }
};

export const checkValueTypeAndGetTheCount = (value) => {
  let res = false;
  if (value) {
    if (typeof value === 'string') {
      let booleanVal = isTrue(value);
      if (booleanVal === null) {
        res = value?.length > 0;
      } else {
        res = booleanVal;
      }
    } else if (Object.keys(value).length > 0) {
      res = Object.keys(value).length > 0;
    } else if (typeof answer === 'boolean') {
      res = value;
    } else if (Array.isArray(value)) {
      res = value?.length > 0;
    }
  }
  return res;
};
