/* eslint-disable */
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DynamicForm from '../../../core/Forms/DynamicForm';
// import { flattenObject } from '../../../../utils/datafactory';
import { savePublicPosition } from '../../../../redux/actions/actions';
import './public_position.scss';
import { recursiveWithConstantCallback, YesNo, yesno } from '../../Utils/helper';
// import Loader from '../../../core/ErrorBoundary/Loader';
// import Error from '../../../core/ErrorBoundary/Error';

/**
 * @description - Public Position Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const PublicPosition = ({ inputRef, ...props }) => {
  const { profileInfo, t, candidateJobDetails } = props;
  const { title, subTitle, text, bigText, items = [] } = props.fields;
  const [newProfileInfo, flattenProfileInfo] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    errors,
    trigger,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    revalidate: 'onChange',
  });
  const formSubmitRef = useRef();

  /**
   * @description - onsubmit hander.
   * @returns {undefined}
   */
  const onSubmit = (formData) => {
    //Navigate to next page on form submission
    props.dispatch(
      savePublicPosition({
        ...formData,
      })
    );
    props.onSubmitHandler();
  };

  const transform = (onSubmitFunc) => (data) => {
    let transformData = recursiveWithConstantCallback(data, YesNo);
    onSubmitFunc(transformData);
  };

  useEffect(() => {
    if (candidateJobDetails?.CandidateMinimalInfo?.id) {
      const publicPositionValues =
        candidateJobDetails?.CandidateMinimalInfo?.publicPosition &&
        candidateJobDetails?.CandidateMinimalInfo?.publicPosition !== null
          ? candidateJobDetails?.CandidateMinimalInfo?.publicPosition
          : {};
      let transformData = recursiveWithConstantCallback(publicPositionValues, yesno);
      flattenProfileInfo({
        data: {
          ...{ publicPosition: transformData },
          publicPosition: transformData,
        },
      });
    }
  }, [candidateJobDetails]);
  /**
   * @description - useImperativeHandle function.
   * @returns {undefined}
   */
  useImperativeHandle(inputRef, () => ({
    trigger: () => {
      let event;
      if (typeof Event === 'function') {
        event = new Event('submit', { cancelable: true });
      } else {
        event = document.createEvent('Event');
        event.initEvent('submit', false, { cancelable: true });
      }
      formSubmitRef.current.dispatchEvent(event);
      // formSubmitRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
    },
  }));
  return (
    <div className="publicPosition">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1 className="title">{title?.value}</h1>
            <div className="text">{subTitle?.value}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 offset-lg-4 ">
            <div className="publicPositionForm">
              <div className="formText">{text?.value}</div>
              <div className="publicPosition-form">
                <form
                  onSubmit={handleSubmit(transform(onSubmit))}
                  ref={formSubmitRef}
                >
                  <DynamicForm
                    formData={items}
                    register={register}
                    errors={errors}
                    getValues={getValues}
                    setValue={setValue}
                    trigger={trigger}
                    watch={watch}
                    values={newProfileInfo}
                  />
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-3">
            <p className="staticText">{bigText?.value}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

PublicPosition.propTypes = {
  profileInfo: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};

PublicPosition.defaultProps = {
  profileInfo: {},
};

const mapStateToProps = (state) => {
  return { profileInfo: state.profileInfoReducer };
};

const ConnectedComponent = connect(mapStateToProps)(PublicPosition);
export default forwardRef((props, ref) => {
  return <ConnectedComponent {...props} inputRef={ref} />;
});
