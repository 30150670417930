/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const SingleJob = ({ t, sitecoreContext, ...props }) => {
  const { fieldsData: { items } = {}, jobListing } = props || {};
  const {
    jobAdvertisementTeaser = '',
    publicDescription = '',
    companyDescription = '',
    openingParagraph = '',
  } = items || {};

  return (
    <div className="single-job">
      <div className="details-rich-text">
        {
          <RichText
            field={{ value: jobAdvertisementTeaser }}
            tag="p"
            className="large job-teaser"
            role="heading"
            aria-level="2"
          />
        }
        {openingParagraph && (
          <p>
            <RichText field={{ value: openingParagraph }} />
          </p>
        )}
        {publicDescription && (
          <p>
            <RichText field={{ value: publicDescription }} />
          </p>
        )}
        {companyDescription && (
          <p>
            <RichText
              field={{ value: companyDescription }}
              // tag="p"
              aria-hidden={jobListing}
            />
          </p>
        )}
      </div>
    </div>
  );
};

export default withSitecoreContext()(withTranslation()(SingleJob));
