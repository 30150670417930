import React from 'react';
import PropTypes from 'prop-types';
import CardInfo from '../Cards/CardInfo';

import { withTranslation } from 'react-i18next';
//import { Text } from '@sitecore-jss/sitecore-jss-react';
import { checkArray, showString } from '../../../utils/helperUtils';
//import mockData from './mockData';
import './cardblock.scss';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

/**
 * @description-  CardBlockInfo standard component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */
const CardBlockInfo = (props) => {
  const { fields, params, t, sitecoreContext } = props;
  const { colorMode = '', linkColor = '' } = params;
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';
  const link_color = linkColor ? linkColor : 'BLUE';
  const color_Mode = showString(colorMode).toLowerCase();
  // const itemLength = fields.items.length + 1;
  // const gridValue = 12 / 4;
  const gridValue = checkArray(fields.items).length > 3 ? 'col-lg-3' : 'col-lg-4';

  return (
    <div className={`card-block ${color_Mode} ${brandName}`}>
      <div className="container">
        <div className="row">
          <div className="col">
            <h2 className="title">{fields?.title?.value}</h2>
          </div>
        </div>
        <div className="row justify-content-md-center">
          {checkArray(fields.items).map((item) => (
            <div
              key={item.name}
              className={`cardInfoContainer ${gridValue} col-md-6`}
            >
              <CardInfo
                title={item.fields.title}
                text={item.fields.text}
                icon={item.fields.image}
                ctaUrl={item?.fields?.url}
                ctaText={t('services-read-more')}
                readMoreColour={link_color}
                mode={'dark'} //for dark mode only
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

CardBlockInfo.defaultProps = {
  fields: { title: {}, items: [] },
  params: { colorMode: { value: 'dark' }, linkColor: { value: 'blue' } },
  sitecoreContext: {},
};

CardBlockInfo.propTypes = {
  fields: PropTypes.shape({
    /** Main title above cards */
    title: {
      value: PropTypes.string,
    },

    /** Set of data items */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
        icon: PropTypes.string,
        altText: PropTypes.string,
        ctaText: PropTypes.string,
        ctaUrl: PropTypes.string,
        colorMode: PropTypes.oneOf(['dark', 'light']),
      })
    ),
  }),
  params: PropTypes.shape({
    colorMode: {
      value: PropTypes.oneOf(['dark', 'light']),
    },
    linkColor: {
      value: PropTypes.oneOf(['green', 'blue']),
    },
  }),
  t: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({}),
  /** Color mode: dark or light */
  // mode: PropTypes.oneOf(['dark', 'light']).isRequired,
};

export default withSitecoreContext()(withTranslation()(CardBlockInfo));
