/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
// import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import TypeAheadDropDown from '../globals/dropdown/TypeAheadDropDown';

import './countrylink.scss';

/**
 * @description - CountryLinks component.
 * @param {object} props - Input props.
 * @returns {Node} - HTML section.
 */
const CountryLinks = (props) => {
  const { fields, sitecoreContext, t, params } = props;
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';
  const [country, setCountry] = useState('');
  const [language, setLanguage] = useState('');
  const [countryList, setCountryListArray] = useState([]);
  const [languageList, setLanguageListArray] = useState([]);
  const [userLanguage, setUserLanuage] = useState();
  const [cookies, setCookie] = useCookies(['first-visit']);
  // const history = useHistory();
  // const [showLang, setLang] = useState(
  //   params?.hideLang && params?.hideLang === 'true' ? false : true
  // );

  useEffect(() => {
    setCountryList();
    setUserLanuage(navigator.language || navigator.userLanguage);
  }, [fields]);

  /**
   * set country list
   * @params {}
   * @returns {undefined}
   */
  const setCountryList = () => {
    const countries = [];
    fields &&
      fields.items &&
      Array.isArray(fields.items) &&
      fields.items.forEach((country, index) => {
        if (
          country?.siteName?.toLowerCase() ===
          sitecoreContext?.site?.name?.toLowerCase()
        ) {
          setCountry(country?.countryName);
          setLanguageList(country?.languages);
        }
        countries[index] = {
          text: country?.countryName,
          value: country?.countryURL,
        };
      });
    setCountryListArray(countries);
  };

  /**
   * to set language list
   * @param {*} languages - selected country languages
   * @returns {undefined}
   */
  const setLanguageList = (languages = []) => {
    const langList = [];
    languages &&
      Array.isArray(languages) &&
      languages.forEach((language, index) => {
        if (
          userLanguage &&
          userLanguage.toLowerCase() === language?.isoCode?.toLowerCase() &&
          !cookies['first-visit'] & (window.location.pathname === '/') &&
          userLanguage.toLowerCase() !== sitecoreContext?.languageISO?.toLowerCase()
        ) {
          switchToUserLang(language?.regionalISO);
        }
        if (
          sitecoreContext?.languageISO?.toLowerCase() ===
          language?.isoCode?.toLowerCase()
        ) {
          setLanguage(language?.name);
        }
        langList[index] = {
          text: language?.name,
          value: language?.isoCode,
          url: language?.redirectURL,
        };
      });
    setLanguageListArray(langList);
  };

  /**
   * @description to redirect to browser language selected
   * @returns {undefined} - no returns
   */
  const switchToUserLang = (regionalISO) => {
    setCookie('first-visit', 'true', { path: '/' });
    window.location = `${window.location.origin}/${regionalISO}`;
  };

  /**
   * to handle country selection
   * @param {*} countryUrl - selected country url
   * @returns {undefined}
   */
  const handleCountrySelection = (countryUrl) => {
    if (!countryUrl.value.includes(window.origin)) {
      window.location = countryUrl.value;
    }
  };

  /**
   * Function to handle click
   * @param {*} language - selected language
   * @returns {undefined}
   */
  const handleLanguageSelection = (language) => {
    if (
      sitecoreContext?.languageISO?.toLowerCase() !== language?.value?.toLowerCase()
    ) {
      window.location.href = language.url;
    }
  };

  return (
    <div className="country-link">
      <section className="slideout-nav-country">
        <TypeAheadDropDown
          items={countryList}
          textField="text"
          valueField="value"
          label={t('country')}
          floatingLabel={t('enter-country')}
          seletedItem={country}
          onItemSelect={handleCountrySelection}
          brandName={brandName}
        />
      </section>
      {languageList?.length > 1 && (
        <section className="slideout-nav-language">
          <TypeAheadDropDown
            items={languageList}
            textField="text"
            valueField="value"
            label={t('language')}
            floatingLabel={t('enter-language')}
            seletedItem={language}
            onItemSelect={handleLanguageSelection}
            brandName={brandName}
          />
        </section>
      )}
    </div>
  );
};

CountryLinks.propTypes = {
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        countryName: PropTypes.shape({
          value: PropTypes.string.isRequired,
        }),
        countryUrl: PropTypes.shape({
          value: PropTypes.string.isRequired,
        }),
        languages: PropTypes.arrayOf(
          PropTypes.shape({
            fields: PropTypes.shape({
              languageName: PropTypes.shape({
                value: PropTypes.string.isRequired,
              }),
              isoCode: PropTypes.shape({
                value: PropTypes.string.isRequired,
              }),
            }),
          })
        ),
      })
    ),
  }),
  sitecoreContext: PropTypes.shape({
    site: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    Country: PropTypes.shape({
      brandName: PropTypes.string,
    }),
  }),
  t: PropTypes.func.isRequired,
};

CountryLinks.defaultProps = {
  fields: {
    items: [
      {
        fields: {
          countryName: {
            value: '',
          },
          countryUrl: {
            value: '',
          },
          languages: [
            {
              fields: {
                languageName: {
                  value: '',
                },
                isoCode: {
                  value: '',
                },
              },
            },
            {
              fields: {
                languageName: {
                  value: '',
                },
                isoCode: {
                  value: '',
                },
              },
            },
          ],
        },
      },
    ],
  },
  sitecoreContext: {
    site: {
      name: '',
    },
    Country: {
      brandName: '',
    },
  },
  t: () => {},
};

export default withTranslation()(CountryLinks);
