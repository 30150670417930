import React, { useState, useEffect, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { connect } from 'react-redux';

import Button from '../../globals/buttons/Button';
import Textbox from '../../Forms/FormFields/Textbox';
import LocationSearch from '../../globals/forms/LocationSearch';
import {
  isObjNotEmpty,
  getSearchURLParamsUpdated,
} from '../../../../utils/helperUtils';
import { saveSelectedLocation } from '../../../../redux/actions';
import { SEARCH } from '../../../../constants';

/**
 * @description - Form Elements.
 * @param {*} props - Input props
 * @returns {Node} - HTML node.
 */
const FormElements = (props) => {
  const {
    register,
    control,
    locationSelected,
    countryCode,
    isMyPath,
    bannerMode,
  } = props;
  const { errors, searchLocation, onLocChange, t, regexValidationPattern } = props;
  const { getValues, setValue } = props;

  let styleTextbox = 'col-lg-5';
  let styleController = 'col-lg-4';
  if (isMyPath === 'True') {
    styleTextbox = 'col-lg-6';
    styleController = 'col-lg-3';
  }
  if (bannerMode === 'b2c') {
    styleTextbox = 'col-lg-12';
    styleController = 'col-lg-12';
  }

  /**
   * @description - Validate location
   * @param {*} careerSearchLoc - location
   * @returns {location} - valiadte location.
   */
  const validateLocation = (careerSearchLoc) => {
    return !(
      careerSearchLoc &&
      (!isObjNotEmpty(searchLocation) ||
        (isObjNotEmpty(searchLocation) && searchLocation.place !== careerSearchLoc))
    );
  };

  return (
    <Fragment>
      <div className={`${styleTextbox}`}>
        <Textbox
          id="career-search-text"
          label={
            bannerMode === 'b2c'
              ? `${t('jobs-search-keyword-ph')}`
              : `${t('job-title-industry-skill')}`
          }
          name={'careerSearchKey'}
          trimLabelCharacters={bannerMode === 'b2c' ? 45 : 40}
          // customProps={{ ref: register  }}
          // onHandleChange={onChangeKeywordHandler}
          // focusHandler={focusHandler}
          // labelMsg={ariaLabel}
          register={register}
          getValues={getValues}
          setValue={setValue}
          customError={errors}
          dataValidations={[
            {
              fields: {
                message: {
                  value: t('search-validation-message'),
                },
                inputType: {
                  value: 'regexPatternvalidator',
                },
                value: {
                  value: regexValidationPattern,
                },
              },
            },
          ]}
        />
      </div>

      <div className={`${styleController}`}>
        <Controller
          as={LocationSearch}
          control={control}
          onLocationSelect={locationSelected}
          region={countryCode}
          errorMessage={t('invalid-location')}
          label={
            bannerMode === 'b2c'
              ? `${t('jobs-search-location-ph')}`
              : `${t('search-location-header')}`
          }
          id="career-search-location"
          name="careerSearchLoc"
          disableEnterSubmit={true}
          rules={{ validate: validateLocation }}
          refVal={register}
          showError={errors?.careerSearchLoc}
          selectedLocation={searchLocation}
          changeHandler={onLocChange}
          showCurrentLocation={true}
        />
      </div>
    </Fragment>
  );
};

FormElements.propTypes = {
  register: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  locationSelected: PropTypes.func.isRequired,
  countryCode: PropTypes.string.isRequired,
  isMyPath: PropTypes.string,
  bannerMode: PropTypes.string,
  errors: PropTypes.shape({}).isRequired,
  searchLocation: PropTypes.shape({}).isRequired,
  onLocChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

/**
 * @description - Job Forms component.
 * @param {object} props - props
 * @returns {Node} - HTML node.
 */
const JobsForm = ({
  t,
  sitecoreContext,
  locationState,
  dispatch,
  ctaText,
  isMyPath,
  bannerMode,
}) => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const [searchLocation, setSrLocation] = useState({});
  const url = sitecoreContext?.Country?.jobSearchURL || '';
  const regexValidationPattern = sitecoreContext?.Country?.regExPattern || '';
  const urlVal = url.split('?')[0];
  let buttonCssClass = 'reversed-button';
  let styleButton = 'col-lg-3';
  if (isMyPath === 'True') {
    buttonCssClass = '';
  }
  if (bannerMode === 'b2c') {
    buttonCssClass = 'find-job-btn';
    styleButton = 'col-lg-12';
  }

  const {
    register,
    setValue,
    getValues,
    clearErrors,
    handleSubmit,
    errors,
    control,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: {
      careerSearchLoc: searchLocation?.place,
    },
  });

  useEffect(() => {
    if (
      sessionStorage &&
      sessionStorage.getItem(SEARCH.JOB_SEARCH.SEARCH_LOCATION)
    ) {
      const locValue = JSON.parse(
        sessionStorage.getItem(SEARCH.JOB_SEARCH.SEARCH_LOCATION)
      );
      setValue('careerSearchLoc', locValue.place);
      // setSrLocation(locValue);
    }
  }, []);

  useMemo(() => {
    if (isObjNotEmpty(locationState)) {
      setValue('careerSearchLoc', locationState.place);
      setSrLocation(locationState);
    }
  }, [locationState]);

  /**
   * @description function called followed by place selection
   * @param {object} locVal  - geometry of location
   * @returns {undefined}
   */
  const locationSelected = (locVal) => {
    if (locVal) {
      setSrLocation(locVal);
      setValue('careerSearchLoc', locVal.place);
      clearErrors('careerSearchLoc');
    } else {
      setSrLocation({});
    }
  };

  /**
   * @description function called on location change
   * @param {*} value - value
   * @returns {undefined}
   */
  const onLocChange = (value) => {
    setValue('careerSearchLoc', value);
    if (value === '') {
      setSrLocation({});
      clearErrors('careerSearchLoc');
    }
  };

  /**
   * @description function called on form submit
   * @param {*} values - values
   * @returns {undefined}
   */
  const onSubmit = (values) => {
    const { careerSearchKey } = values;
    const finalParams = getSearchURLParamsUpdated(
      params,
      careerSearchKey,
      searchLocation
    );
    if (isObjNotEmpty(searchLocation)) {
      dispatch(saveSelectedLocation(searchLocation));
    }
    history.push({
      pathname: urlVal,
      search: String(decodeURIComponent(finalParams)),
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-fields">
        <div className="row">
          <FormElements
            register={register}
            control={control}
            locationSelected={locationSelected}
            //countryCode={sitecoreContext?.Country?.code}
            countryCode={
              t('country-code') != 'country-code'
                ? `${sitecoreContext?.Country?.code},${t('country-code')}`
                : `${sitecoreContext?.Country?.code}`
            }
            errors={errors}
            searchLocation={searchLocation}
            onLocChange={onLocChange}
            regexValidationPattern={regexValidationPattern}
            getValues={getValues}
            setValue={setValue}
            t={t}
            isMyPath={isMyPath}
            bannerMode={bannerMode}
          />
          <div className={`${styleButton}`}>
            <div
              className={`cta ${errors?.careerSearchLoc ? 'searchLocError' : ''}`}
            >
              <Button
                cssClass={`${buttonCssClass}`}
                text={ctaText}
                handleButtonClick={handleSubmit(onSubmit)}
                typeProp={{ type: 'submit' }}
                arrow
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

JobsForm.defaultProps = {
  ctaText: 'Find jobs',
  isMyPath: '',
  bannerMode: '',
  t: (val) => val,
};

JobsForm.propTypes = {
  ctaText: PropTypes.string,
  isMyPath: PropTypes.string,
  bannerMode: PropTypes.string,
  t: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({}).isRequired,
  locationState: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    place: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.shape({}).isRequired,
  ctaText: PropTypes.shape({}),
};

/**
 * @description function called to return state location
 * @param {*} state - state
 * @returns {undefined}
 */
const mapStateToProps = (state) => {
  return {
    locationState: state.jobLocationReducer.location,
  };
};

export default connect(mapStateToProps)(
  withSitecoreContext()(withTranslation()(JobsForm))
);
