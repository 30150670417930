import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import './boundaries.scss';
// import ButtonLink from '../globals/link/ButtonLink';
import Button from '../globals/buttons/Button';

/**
 *
 * @param {*} param0
 */
const Error = ({ errorMsg, t, handleApiRefresh, refreshApi }) => (
  <>
    <div className="container-fluid text-center error">
      <div className="alert alert-danger" role="alert">
        <h4 className="alert-heading">{t('error-header')}</h4>
        <p>{errorMsg}</p>
        {refreshApi && (
          <div className="refresh-button">
            <Button
              text={t('refresh')}
              handleButtonClick={() => handleApiRefresh()}
              cssClass="dark-red"
            />
            {/* <span className="reload">&#x21bb;</span> */}
          </div>
        )}
      </div>
    </div>
  </>
);

Error.propTypes = {
  errorMsg: PropTypes.string,
  handleApiRefresh: PropTypes.func,
  refreshApi: PropTypes.bool,
};

Error.defaultProps = {
  errorMsg: 'Some Error occurred.',
  handleApiRefresh: () => {},
  refreshApi: false,
};

export default withTranslation()(Error);
