/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import lget from 'lodash.get';
import { withTranslation } from 'react-i18next';
import {
  symbolValidationCheck,
  patternRegexCheck,
  validateDNI,
} from '../DynamicForm/helper/validationFactory';
import { getValidationDatabyType } from '../DynamicForm/helper/filter';
import { truncate } from '../../../../utils/helperUtils';
import { checkValueTypeAndGetTheCount } from '../../../business/Utils/helper';

/**
 * @description - Dynamic Textarea component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template for dashboard.
 */
const Textarea = ({
  id,
  name,
  label,
  placeholder,
  icon,
  customStyle,
  readOnly,
  customError,
  hintText,
  register,
  rows,
  dataValidations,
  connectorField,
  connectorType,
  getValues,
  watch,
  showBasedOnFieldName,
  hideBasedOnFieldName,
  isHideField,
  setValue,
  value,
  control,
  validateOnLoad,
  formName,
  t,
  requiredValidation = [],
}) => {
  const fieldError = lget(customError, name);
  const patternData = getValidationDatabyType(dataValidations, 'pattern');
  const requiredData =
    requiredValidation?.length > 0
      ? requiredValidation[0]
      : getValidationDatabyType(dataValidations, 'required');
  let param = readOnly
    ? { shouldValidate: true }
    : { shouldValidate: true, shouldDirty: true };
  param = validateOnLoad ? param : {};
  const maximumData = getValidationDatabyType(dataValidations, 'maximum');
  const minimumData = getValidationDatabyType(dataValidations, 'minimum');
  const dniValidator = getValidationDatabyType(dataValidations, 'dni');
  const [selectedValue, setSelectedValue] = useState();
  const [showField, setShowField] = useState(true);
  const [hideField, setHideField] = useState(false);
  const connectorFormName = formName
    ? `${formName}[${connectorField}]`
    : connectorField;
  const showFieldName = formName
    ? `${formName}[${showBasedOnFieldName}]`
    : showBasedOnFieldName;
  const hideFieldName = formName
    ? `${formName}[${hideBasedOnFieldName}]`
    : hideBasedOnFieldName;

  const showFieldValue = showFieldName ? watch(showFieldName) : null;
  const hideFieldValue = hideFieldName ? watch(hideFieldName) : null;

  useEffect(() => {
    if (showFieldValue) {
      let isFieldHasVal = checkValueTypeAndGetTheCount(showFieldValue);
      setShowField(isFieldHasVal);
    }
  }, [showFieldValue]);

  useEffect(() => {
    if (hideFieldValue) {
      let isFieldHasVal = checkValueTypeAndGetTheCount(hideFieldValue);
      setHideField(isFieldHasVal);
    }
  }, [hideFieldValue]);

  useEffect(() => {
    let newValue = value !== null ? value : '';
    setValue(name, newValue, param);
    setSelectedValue(newValue);
  }, [value]);

  const dataValidator = (value) => {
    let result = true;
    const connectoreFieldsValue = connectorFormName
      ? getValues(connectorFormName)
      : null;
    if (value && connectoreFieldsValue) {
      result = symbolValidationCheck(value, connectoreFieldsValue, connectorType);
    }
    return result;
  };

  return (
    <div
      className={`form-block complex ${fieldError ? 'error withoutBg' : ''} ${
        isHideField || !showField || hideField ? 'hide' : ''
      }`}
      style={customStyle}
    >
      <div
        className="textAreaOuter"
        htmlFor={id}
        onClick={() => {
          document.getElementById(id).focus();
        }}
      >
        <textarea
          className={(getValues && getValues(name)) || value ? 'filled' : ''}
          name={name}
          id={id}
          rows={rows}
          autoComplete="autoComplete_off"
          readOnly={readOnly}
          onChange={(e) =>
            setValue(name, e.target.value, {
              shouldValidate: true,
              shouldDirty: true,
            })
          }
          defaultValue={selectedValue}
          ref={
            register
              ? register({
                  required:
                    ((!isHideField || showField) &&
                      requiredData?.fields?.value?.value) ||
                    false,
                  validate: {
                    dateValidator: (value) =>
                      (connectorField ? dataValidator(value) : true) ||
                      connectorMessage ||
                      t('validation-failed'),
                    dniValidationCheck: (value) =>
                      (dniValidator?.fields?.value?.value === 'true' ||
                      dniValidator?.fields?.value?.value === true
                        ? validateDNI(value)
                        : true) ||
                      dniValidator?.fields?.message?.value ||
                      t('validation-failed'),
                  },
                  maxLength: {
                    value: maximumData?.fields?.value?.value || null,
                    message:
                      maximumData?.fields?.message?.value || t('validation-failed'),
                  },
                  minLength: {
                    value: minimumData?.fields?.value?.value || null,
                    message:
                      minimumData?.fields?.message?.value || t('validation-failed'),
                  },
                  pattern: {
                    value: patternData?.fields?.value?.value
                      ? patternRegexCheck(patternData.fields.value.value)
                      : '',
                    message:
                      patternData?.fields?.message?.value || t('validation-failed'),
                  },
                })
              : () => {}
          }
          defaultValue={selectedValue}
          {...(fieldError ? { 'aria-invalid': true } : '')}
        />

        {label && (
          <label htmlFor={id}>
            <div className="label-text">
              {icon && (
                <div className="input-icon">
                  <span className="input-icon"></span>
                </div>
              )}
              {truncate(label, 25)}
            </div>
          </label>
        )}
      </div>
      {fieldError && (
        <div className="error-msg" id={`err_${id}`} role="alert">
          {fieldError.type === 'required'
            ? requiredData?.fields?.message?.value
              ? requiredData?.fields?.message?.value?.replace(
                  '{0}',
                  label?.replace('*', '')
                )
              : t('mandatory-field-message').replace('{0}', label?.replace('*', ''))
            : fieldError?.message?.replace('{0}', label?.replace('*', ''))}
        </div>
      )}
      {hintText && <div className="hintText">{hintText}</div>}
    </div>
  );
};

Textarea.defaultProps = {
  id: '',
  name: '',
  label: '',
  placeholder: '',
  type: 'text',
  icon: '',
  readOnly: false,
  setValue: () => {},
  t: () => {},
};

Textarea.propTypes = {
  id: PropTypes.string.isRequired,
  t: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  icon: PropTypes.string,
  setValue: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default withTranslation()(Textarea);
