/* eslint-disable */
import React, { useEffect, useState, forwardRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import lget from 'lodash.get';
import Loader from '../../../core/ErrorBoundary/Loader';
import Button from '../../../core/globals/buttons/Button';
import DynamicForm from '../../../core/Forms/DynamicForm';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import {
  jobApplyApi,
  jobApplyApi_NoAuth,
  attachementDownloadApi,
} from '../../../../services/apiServices/candidateService';
import './upload_resume.scss';
import { authstates } from '../../../../utils/enums';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import SnackBar from '../../../core/ErrorBoundary/SnackBar';
import CustomOverlayLoader from '../../../core/ErrorBoundary/CustomOverlayLoader';
import {
  formObjIteration,
  recursiveWithConstantCallback,
  YesNo,
} from '../../Utils/helper';
import {
  filterArrayByType,
  isNullorEmpty,
  isObjNotEmpty,
  JSONParser,
} from '../../../../utils/helperUtils';
import { preJobApplyEvent } from '../../../../services/apiServices/gtmService';
import { toast } from 'react-toastify';

/**
 * @description - Upload Resume Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const UploadResume = ({ inputRef, ...props }) => {
  const {
    candidateJobDetails,
    fields,
    t,
    publicPosition,
    userDetails,
    context,
    auth,
    sitecoreContext,
  } = props;
  const {
    title,
    subTitle,
    items = [],
    ctaUrl,
    errorPageUrl,
    text,
    percentagePerField,
    userNameIdentifier,
  } = fields;

  const { isActive, message, isSuccess, openSnackBar } = useSnackbar();
  const loginReducer = useSelector((state) => state.loginReducer);
  const loginState = loginReducer?.state;
  const defaultPercentagePerField = percentagePerField?.value
    ? JSONParser(percentagePerField?.value, [])
    : [];
  const layout = items?.filter(function (el) {
    return candidateJobDetails?.JobDetails?.isPublicPosition
      ? el.name === 'Layout1'
      : el.name === 'Layout2';
  })?.[0]?.fields?.items;

  const userNameIdentifierVal = userNameIdentifier?.value || 'firstName';

  const history = useHistory();
  const [data, setData] = useState({});
  const [candidateId, setCandidateId] = useState('');
  const [fieldList, setFieldList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [fileUpload, setFileUpload] = useState({});
  const [utmParams, setUtmParams] = useState({});
  const {
    register,
    handleSubmit,
    getValues,
    errors,
    setValue,
    control,
    setError,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    revalidate: 'onChange',
  });
  const [newTitle, setTitle] = useState(title?.value);
  const dispatch = useDispatch();

  const showSnackbarHandler = (openSnackBar, msg, isSuccessMsg = false) => {
    openSnackBar(msg, isSuccessMsg);
  };

  useEffect(() => {
    if (sessionStorage) {
      let utmValues = sessionStorage.getItem('utmParams')
        ? sessionStorage.getItem('utmParams')
        : sessionStorage.getItem('utmReferrer');
      utmValues = !isNullorEmpty(utmValues) ? JSON.parse(utmValues) : {};
      setUtmParams(utmValues);
    }
    preJobApplyEvent(
      candidateJobDetails?.JobDetails,
      context?.Country?.revenuePerApplication,
      loginState
    );
  }, []);
  useEffect(() => {
    if (userDetails?.firstName) {
      const formattedTitle = title?.value?.replace(
        '{0}',
        userDetails?.firstName || t('user')
      );
      setTitle(formattedTitle);
    } else {
      const formattedTitle = title.value.replace(', {0}', '');
      setTitle(formattedTitle);
    }
  }, [userDetails]);

  useEffect(() => {
    if (candidateJobDetails?.CandidateMinimalInfo?.id) {
      setCandidateId(candidateJobDetails?.CandidateMinimalInfo?.id);
      setData({
        ...candidateJobDetails?.CandidateMinimalInfo,
      });
    }
  }, [candidateJobDetails]);

  const showSuccess = () => {
    history.push({
      pathname: ctaUrl.value.href,
    });
    setLoading(false);
  };

  const applyJobLoader = () => {
    setLoading(false);
  };

  const showError = (message = 'error-message', failureRedirect = false) => {
    if (failureRedirect === true) {
      history.push({
        pathname: errorPageUrl?.value?.href,
      });
    } else {
      let errorMessage = message?.replace(
        '{0}',
        candidateJobDetails?.JobDetails?.jobTitle
      );
      //setError(e);
      showSnackbarHandler(openSnackBar, t(errorMessage), false);
      toast.error(t(errorMessage), {
        position: 'top-center',
      });
    }
    setLoading(false);
  };

  const getPercent = () => {
    let percent =
      candidateJobDetails?.ProfilePercentage &&
      candidateJobDetails?.ProfilePercentage !== null
        ? parseInt(candidateJobDetails?.ProfilePercentage)
        : 0;
    const candidateDetails = candidateJobDetails?.CandidateMinimalInfo;
    const candidateAttachmentDetails = candidateDetails?.attachment || [];
    defaultPercentagePerField.map((item) => {
      if (item.type === 'attachment') {
        const val = filterArrayByType(candidateAttachmentDetails, item?.name);
        if (val.length === 0) {
          percent += item?.percentage || 0;
        }
      } else {
        const val = lget(candidateDetails, item.name);
        if (isNullorEmpty(val)) {
          percent += item?.percentage || 0;
        }
      }
    });
    return percent > 100 ? 100 : percent;
  };

  const transform = (onSubmitFunc) => (data) => {
    let transformData = formObjIteration(data, {}, 'value');
    // transformData = recursiveWithConstantCallback(transformData, YesNo);
    onSubmitFunc(transformData);
  };

  const onSubmit = (formData) => {
    const data = new FormData();
    Object.keys(fileUpload).forEach((item) => {
      for (let i = 0; i < fileUpload[item].length; i++) {
        if (!fileUpload?.[item]?.[i]?.id)
          data.append(`${item}`, fileUpload?.[item]?.[i]);
      }
    });

    if (sessionStorage) {
      sessionStorage.setItem(
        'jobDetails',
        JSON.stringify({
          jobTitle: candidateJobDetails?.JobDetails?.jobTitle,
          jobURL: candidateJobDetails?.JobDetails?.jobURL,
        })
      );

      sessionStorage.setItem(
        'candidateName',
        auth === authstates.UNAUTHENTICATED
          ? formData
            ? lget(formData, userNameIdentifierVal) || t('user')
            : t('user')
          : lget(userDetails, userNameIdentifierVal)
      );
    }
    setLoading(true);

    const postData = JSON.stringify({
      ...formData,
      positionID: candidateJobDetails?.JobDetails?.positionID || undefined,
      deletedFiles: fieldList?.length > 0 ? fieldList : undefined,
      utmSource: isObjNotEmpty(utmParams) ? utmParams.UTM_Source : '',
      utmCampaign: isObjNotEmpty(utmParams) ? utmParams.UTM_Campaign : '',
      utmMedium: isObjNotEmpty(utmParams) ? utmParams.UTM_Medium : '',
      utmTerm: isObjNotEmpty(utmParams) ? utmParams.UTM_Term : '',
      utmContent: isObjNotEmpty(utmParams) ? utmParams.UTM_Content : '',
      referer: isObjNotEmpty(utmParams) ? utmParams.Referer : 'Direct',
      candidateId: candidateId || undefined,
      jobId: candidateJobDetails?.JobDetails?.jobId,
      jobItemID: candidateJobDetails?.JobDetails?.jobItemID,
      // jobTitle: candidateJobDetails?.JobDetails?.jobTitle,
      // domain: candidateJobDetails?.JobDetails?.domain,
      // isPublic: candidateJobDetails?.JobDetails?.isPublicPosition,
      // status: '',
      // comments: '',
      percent: getPercent() || undefined,
      // ...publicPosition,
    });
    data.append('profileData', postData);

    if (auth === authstates.AUTHENTICATED) {
      jobApplyApi(
        data,
        showSuccess,
        applyJobLoader,
        showError,
        dispatch,
        candidateJobDetails?.JobDetails,
        context?.Country?.revenuePerApplication,
        context?.LoginType,
        loginReducer,
        t
      );
    } else {
      jobApplyApi_NoAuth(
        data,
        showSuccess,
        applyJobLoader,
        showError,
        dispatch,
        candidateJobDetails?.JobDetails,
        context?.Country?.revenuePerApplication,
        context?.LoginType,
        loginReducer,
        t
      );
    }
  };

  const handleUpload = (fileData, name) => {
    const fileList = fileUpload;
    const obj = { [name]: fileData };
    if (fileUpload.hasOwnProperty(name)) {
      delete fileList[name];
    }
    setFileUpload({ ...fileList, ...obj });
  };

  const handleDelete = (id, name, index) => {
    if (fileUpload.hasOwnProperty(name)) {
      const fileList = fileUpload;
      const temp = fileUpload[name];
      delete temp[index];
      delete fileList[name];
      const newtemp = temp?.filter((item) => item);
      const obj = { ...fileUpload, [name]: newtemp };
      setFileUpload({ ...fileList, ...obj });
    }
    if (id) {
      setFieldList([...fieldList, { fileId: id, type: name }]);
    }
  };

  // const hideLoader = () => {
  //   setDownloading(false);
  // };

  // const handleDownload = (id) => {
  //   setDownloading(true);
  //   // attachementDownloadApi(id, candidateId, hideLoader);
  // };
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';
  return (
    <section className="resume-upload">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            {newTitle && <h1 className="title">{newTitle}</h1>}
            {/* <h2 className="title">{title?.value}</h2> */}
            <div className="cover-letter-text">{subTitle?.value}</div>
            {/* {errorMsg && <div className="cover-letter-text err-msg">{errorMsg}</div>} */}

            <>
              <RichText tag="div" className="form-validation-text" field={text} />
              <div className="col-lg-8 offset-lg-2 upload-resume-form">
                {layout?.map((section, index) => {
                  return (
                    <>
                      <div className={`formSection form_${index}`}>
                        {section?.fields?.title?.value && (
                          <RichText
                            tag="div"
                            className="titleSection"
                            field={section?.fields?.title}
                          />
                        )}
                        {section?.fields?.Abstract?.value && (
                          <RichText
                            tag="div"
                            className="textSection"
                            field={section?.fields?.Abstract}
                          />
                        )}
                        {/* New added */}
                        {section?.fields?.text?.value && (
                          <RichText
                            tag="div"
                            className="textSection"
                            field={section?.fields?.text}
                          />
                        )}
                        <DynamicForm
                          formData={section?.fields?.items}
                          register={register}
                          errors={errors}
                          getValues={getValues}
                          values={{ data: data }}
                          APIQuerryParams={{ cid: candidateId }}
                          setValue={setValue}
                          setError={setError}
                          clearErrors={clearErrors}
                          handleUpload={handleUpload}
                          handleDelete={handleDelete}
                          control={control}
                          readOnlyFields={
                            candidateJobDetails?.CandidateMinimalInfo?.readOnlyFields
                          }
                          fieldsToHide={
                            candidateJobDetails?.CandidateMinimalInfo?.fieldsToHide
                          }
                          t={t}
                        />
                      </div>
                      {section?.fields?.showLineBar?.value === 'true' ||
                      section?.fields?.showLineBar?.value ? (
                        <div className="seperatorLine" />
                      ) : (
                        ''
                      )}
                    </>
                  );
                })}
              </div>
              <div className="upload-button-container" role="button" tabIndex={0}>
                <Button
                  text={t('apply')}
                  handleButtonClick={handleSubmit(transform(onSubmit))}
                  isDisabled={isLoading}
                  cssClass={brandName === 'stegplus' ? 'blue' : ''}
                ></Button>
              </div>
            </>
          </div>
        </div>
      </div>
      {isLoading ? (
        <CustomOverlayLoader
          showSpinner
          customLoaderMsg={t('submitting-details')}
          customLoaderStyle={{
            width: '4rem',
            height: '4rem',
            color: '#fff',
          }}
          customTextStyle={{ color: '#fff' }}
          customStyle={{
            backgroundColor: 'rgb(0, 0, 0, 0.5)',
            background: 'rgba(0, 0, 0, 0.5)',
            opacity: '1',
          }}
        />
      ) : (
        ''
      )}
      <SnackBar isActive={isActive} message={message} isSuccess={isSuccess} />
    </section>
  );
};

UploadResume.propTypes = {
  fields: PropTypes.shape({}).isRequired,
};

UploadResume.defaultProps = {
  fields: {},
};

const mapStateToProps = (state) => {
  return {
    publicPosition: state.publicPositionReducer.data,
    userDetails: state.loginReducer.user,
  };
};

const ConnectedComponent = connect(mapStateToProps)(UploadResume);
export default withSitecoreContext()(
  forwardRef((props, ref) => {
    return <ConnectedComponent {...props} inputRef={ref} />;
  })
);
