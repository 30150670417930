import axios from 'axios';

/**
 * @description - to set authorization header
 * @param {object} store - Store Object.
 * @returns {undefined}
 */
export const setAuthHeader = (store) => {
  /**
   * Request Interceptor.
   */
  axios.interceptors.request.use((config) => {
    try {
      // if (config.url.includes('/api/services/')) {
      try {
        const {
          loginReducer: {
            idToken: {
              idToken: { rawIdToken },
            },
            user,
          },
        } = store.getState();

        if (typeof localStorage !== 'undefined') {
          const userSession = JSON.parse(localStorage.getItem('usersession'));
          config.headers.candidateId = userSession.candidateId;
        }
        config.headers.Authorization = `Bearer ${rawIdToken}`;
        config.headers.Firstname = user.firstName
          ? encodeURIComponent(user.firstName)
          : '';
        config.headers.Lastname = user.lastName
          ? encodeURIComponent(user.lastName)
          : '';
      } catch (e) {}
      // }
      return config;
    } catch (e) {}
  });
};
