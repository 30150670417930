import { createGlobalStyle } from 'styled-components';

export const CommonStyle = createGlobalStyle`
a {
    color: ${({ theme }) => theme?.primaryButton};
    &:hover {
        opacity: 0.5;
        color: ${({ theme }) => theme?.primaryButton};
    }
}

`;
