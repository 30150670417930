import React from 'react';
import PropTypes from 'prop-types';

import './boundaries.scss';

/**
 *
 * @param {*} param0
 */
const NoData = ({ message }) => (
  <div className="container-fluid text-center no-data">
    <div className="alert alert-light" role="alert">
      <div className="alert-heading">{message}</div>
    </div>
  </div>
);

NoData.propTypes = {
  message: PropTypes.string,
};

NoData.defaultProps = {
  message: 'No Data Exists.',
};

export default NoData;
