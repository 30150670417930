import React, { useState, Fragment, useEffect } from 'react';
import {
  withPlaceholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Button from '../../core/globals/buttons/Button';
import { isDataExists } from '../../../utils/helperUtils';
import { getInsightsList } from '../../../services';
import Loader from '../../core/ErrorBoundary/Loader';
import Error from '../../core/ErrorBoundary/Error';
import NoData from '../../core/ErrorBoundary/NoData';
import { toast } from 'react-toastify';
import { ImageCards, SmallCards } from './Cards';
import { FilterContext } from '../Contexts';
import { usePostData } from '../../../hooks/http-client';
import { ARTICLE } from '../../../constants';

import './cardInsights.scss';

/**
 * @description - Split cards into image and small.
 * @param {Array} items - Items array.
 * @returns {Array} - Image cards.
 */
const splitCards = (items) => {
  let imageCards = items;
  if (items.length > 4) {
    imageCards = items.splice(0, 4);
  }
  return imageCards;
};

/**
 * @description - Get initial count for api call.
 * @param {Number} pageCount - Page number count.
 * @param {Number} jobCardPosition - Ad Card position.
 * @param {Array} cardBlocks - Ad Card block component.
 * @returns {Number} - Final count.
 */
const getInitialCount = (pageCount, jobCardPosition, cardBlocks) => {
  let initialCount = pageCount;
  if (
    jobCardPosition?.value &&
    isDataExists(cardBlocks) &&
    Number(jobCardPosition?.value) < pageCount
  ) {
    initialCount = initialCount - 1;
  }
  return initialCount;
};

/**
 * @description - Load more pagination.
 * @param {object} e - Event object.
 * @param {object} param1 - Action params.
 * @returns {undefined} - Sets the latest data.
 */
const loadMore = (e, { fetchMore, cursor, count, setBtnClick, t }) => {
  setBtnClick(true);
  fetchMore({
    variables: {
      first: count,
      after: cursor,
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      setBtnClick(false);
      if (!fetchMoreResult) return prev;
      const items = [
        ...prev?.customSearch?.results?.items,
        ...fetchMoreResult?.customSearch?.results?.items,
      ];
      if (fetchMoreResult.customSearch?.results) {
        fetchMoreResult.customSearch.results.items = items;
      }
      return fetchMoreResult;
    },
  }).catch(() => {
    setBtnClick(false);
    toast.error(t('error-message'), {
      position: 'top-center',
    });
  });
};

/**
 * @description - Filter block section.
 * @param {object} filterBlock - Filter block component.
 * @returns {Node} - Components.
 */
const FilterSection = ({ filterBlock }) => {
  let filter = null;
  if (isDataExists(filterBlock)) {
    filter = filterBlock.map((component, index) => {
      if (component.props && component.props.type === 'text/sitecore')
        return component;
      return (
        <div className="row" key={`filter_${index}`}>
          <div className="col">{component}</div>
        </div>
      );
    });
  }
  return filter;
};

/**
 * @description - Remove hyphen and get id from location param.
 * @param {string} id - Search id.
 * @returns {string} - Search id without hypen.
 */
const getId = (id = '') => {
  return id ? id.replace(/\-/g, '') : null;
};

/**
 * @description - Insights Card Block component.
 * @param {object} props - Input props.
 * @returns {Node} - HTML cards.
 */
const CardBlockInsights = (props) => {
  const { fields, cardBlocks, t, filterBlock, location, sitecoreContext } = props;
  const { jobCardPosition, placeholderImage, count = {} } = fields;
  const [contentType, setContentType] = useState(
    getId(location?.query?.contentType?.id)
  );
  const [topic, setTopic] = useState(getId(location?.query?.topic?.id));
  const [subTopic, setSubTopic] = useState(getId(location?.query?.subTopic?.id));
  const [btnClick, setBtnClick] = useState(false);
  const pageCount = count?.value || 10;
  const cardBlk = contentType || topic || subTopic ? [] : cardBlocks;
  const initialCount = getInitialCount(pageCount, jobCardPosition, cardBlk);
  const stateVal = {
    contentType,
    setContentType,
    topic,
    setTopic,
    subTopic,
    setSubTopic,
    t,
    location,
  };

  const { loading, error, data = {}, fetchMore } = getInsightsList(
    initialCount,
    contentType || '*',
    topic || '*',
    subTopic || '*'
  );
  const { customSearch } = data;
  const { write } = usePostData();
  const hasData = isDataExists(customSearch?.results?.items);
  const customerFilterData = customSearch?.results?.items;

  let cardList = null;

  /**
   * @description prepare params to be get image urls
   * @returns {Object} - params object
   */
  const prepareParams = () => {
    const params = [];
    hasData &&
      customSearch.results.items.forEach((item) => {
        params.push({ itemID: item.item.path });
      });
    return params;
  };

  /**
   * @description set articles array with image urls
   * @param {Object} imageData - image urls array obtained
   * @returns {*} - no returns
   */
  const setImageFields = (imageData) => {
    customSearch.results.items.map((t1, index) => {
      return (customSearch.results.items[index].item = {
        ...t1.item,
        ...{
          image: imageData.data.Data.find((t2) => t2.itemID === t1.item.path).image,
        },
      });
    });
  };

  useEffect(() => {
    if (hasData) {
      write(ARTICLE.GET_IMAGES, prepareParams(), setImageFields);
    }
  }, [customerFilterData]);

  if (loading && !btnClick) {
    cardList = <Loader />;
  } else if (error) {
    cardList = <Error errorMsg={t('error-message') || error?.message} t={t} />;
  } else {
    if (hasData) {
      const items = Object.assign([], customSearch?.results?.items);
      const imageCards = splitCards(items);
      const smallCards = customSearch?.results?.items?.length > 4 ? items : [];
      cardList = (
        <Fragment>
          <ImageCards
            imageCards={imageCards}
            jobCardPosition={jobCardPosition}
            placeholderImage={placeholderImage}
            cardBlocks={cardBlk}
            dateFormat={sitecoreContext?.Country?.dateFormat}
          />
          <SmallCards
            smallCards={smallCards}
            jobCardPosition={jobCardPosition}
            cardBlocks={cardBlk}
            dateFormat={sitecoreContext?.Country?.dateFormat}
          />
          {customSearch?.results?.pageInfo?.hasNextPage && (
            <div className="row">
              <div className="col">
                <div className="load-more-btn">
                  <Button
                    text={t('load-more')}
                    handleButtonClick={loadMore}
                    actionParams={{
                      fetchMore: fetchMore,
                      cursor: customSearch?.results?.pageInfo?.endCursor,
                      count: pageCount,
                      setBtnClick,
                      t,
                    }}
                    isLoading={loading}
                    cssClass="orange"
                  />
                </div>
              </div>
            </div>
          )}
        </Fragment>
      );
    } else {
      if (contentType || topic || subTopic)
        cardList = <NoData message={t('no-results-found')} />;
      else cardList = <NoData message={t('no-data-exists')} />;
    }
  }

  return (
    <section className="card-block insights">
      <FilterContext.Provider value={stateVal}>
        <div className="container">
          <FilterSection filterBlock={filterBlock} />
          {cardList}
        </div>
      </FilterContext.Provider>
    </section>
  );
};

export default withTranslation()(
  withPlaceholder([
    { placeholder: 'jss-left', prop: 'filterBlock' },
    { placeholder: 'jss-content', prop: 'cardBlocks' },
  ])(withRouter(withSitecoreContext()(CardBlockInsights)))
);

CardBlockInsights.propTypes = {
  fields: PropTypes.shape({
    jobCardPosition: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    placeholderImage: PropTypes.shape({
      value: PropTypes.shape({}),
    }),
    count: PropTypes.shape({
      value: PropTypes.number,
    }),
    rootItem: PropTypes.shape({
      value: PropTypes.string,
    }),
  }).isRequired,
  cardBlocks: PropTypes.arrayOf(PropTypes.shape({})),
  t: PropTypes.func.isRequired,
  filterBlock: PropTypes.arrayOf(PropTypes.shape({})),
  location: PropTypes.shape({}).isRequired,
  sitecoreContext: PropTypes.shape({}).isRequired,
};

CardBlockInsights.defaultProps = {
  fields: {},
  cardBlocks: [],
  filterBlock: [],
  sitecoreContext: {},
};
