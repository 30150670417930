import React from 'react';
import PropTypes from 'prop-types';
import { mediaApi, RichText } from '@sitecore-jss/sitecore-jss-react';

import TextLink from '../globals/link/TextLink';
import StaticLink from '../globals/link/StaticLink';
import { showString } from '../../../utils/helperUtils';

/**
 * @description - On enter key press for accessibility.
 * @param {object} e - Event object.
 * @param {Function} clickHandler - Event handler function.
 * @returns {undefined}
 */
const onEnter = (e, clickHandler) => {
  if (e && e.keyCode === 13) {
    clickHandler(e);
  }
};

/**
 * @description - Card component for card scrolls.
 * @param {*} param0 - Input props.
 * @returns {Node} - HTML card.
 */
const Card = ({ title, text, img, altText, ctaText, ctaUrl, clickHandler }) => {
  const imgVal = mediaApi.updateImageUrl(img);
  return (
    <div
      className="card standard card--wipe"
      onClick={clickHandler}
      onKeyUp={(e) => onEnter(e, clickHandler)}
      tabIndex={0}
      role="link"
    >
      <span className="card--wipe--apollo" />
      <figure
        className="article-image"
        style={{ backgroundImage: `url(${imgVal})` }}
      >
        <img className="sr-only" alt={altText} src={imgVal} />
      </figure>
      <div className="card-body">
        {title?.value ? (
          <RichText field={title} tag="div" className="title" />
        ) : (
          <div className="title">{showString(title)}</div>
        )}
        {text?.value ? <RichText field={text} tag="p" /> : <p>{showString(text)}</p>}
      </div>
      {ctaText?.value && (
        <div className="cta__wrap card-footer">
          {clickHandler ? (
            <StaticLink ctaText={ctaText} arrow />
          ) : (
            <TextLink
              ctaText={ctaText}
              ctaUrl={ctaUrl}
              ctaLabel={`${title?.value}_${ctaText?.value}`}
              tabIndex={ctaUrl === '' ? -1 : 0}
              arrow
            />
          )}
        </div>
      )}
    </div>
  );
};

Card.defaultProps = {
  title: '',
  text: '',
  img: {},
  altText: '',
  ctaText: 'Read more',
  ctaUrl: '',
  clickHandler: () => {},
};

Card.propTypes = {
  title: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  text: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  img: PropTypes.shape({}),
  altText: PropTypes.string,
  ctaText: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  ctaUrl: PropTypes.string,
  clickHandler: PropTypes.func,
};

export default Card;
