import { JOB_ALERTS } from '../actions/actionTypes';

const initialSate = {
  clearAlerts: false,
};
const consentReducer = (state = initialSate, action) => {
  switch (action.type) {
    case JOB_ALERTS.CLEAR:
      return { ...state, clearAlerts: true };
    default:
      return state;
  }
};

export default consentReducer;
