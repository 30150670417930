import { createGlobalStyle } from 'styled-components';

export const AccoridonTheme = createGlobalStyle`
.accordion-section {
    &.gradient {
        .accordion-block {
            .accordion-title {
                background-image: linear-gradient(267deg, ${({ theme }) =>
                  theme?.primaryColor},  ${({ theme }) => theme?.gradient});
            }
        }
    }
    a {
        color: ${({ theme }) => theme?.primaryButton} ;
        &:hover {
            color: ${({ theme }) => theme?.primaryButton};
            opacity: 0.5;
        }
    }
}
.profile-accordion-block {
    a {
        color: ${({ theme }) => theme?.primaryButton};
        &:hover {
            color: ${({ theme }) => theme?.primaryButton};
            opacity: 0.5;
        }
    }
}

`;
