'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { compose, lifecycle } from 'recompose';

import { COUNTRY_ISOS } from '../../../constants/countryIsos';

import PinIcon from '../../../assets/images/icon-location_pinpoint-orange.svg';
import PinIconSmall from '../../../assets/images/icon-location_pinpoint-orange-small.svg';
import './map.scss';

const mapStyles = [
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#5e6066',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#eff0f0',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#d9d9db',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#8aacd5',
      },
      {
        visibility: 'on',
      },
    ],
  },
];

/**
 * @description - Checks whether location available for marking.
 * @param {Array} markers - Array of location to be marked on map.
 * @returns {boolean} - Check whether markers available.
 */
const isMarkersExists = (markers = []) =>
  markers?.filter((marker) => marker?.isPrimary === true)[0] || markers[0];

/**
 * @description - Get center of map based on marker
 * @param {boolean} fitBound - Is fixed boundary
 * @param {Array} markers - Lat, Lng markers
 * @param {string} countryCode - Country Code from context
 * @returns {object} - lat lng object
 */
const getCenter = (fitBound, markers = [], countryCode) => {
  if (fitBound) {
    const primaryMarker = markers?.filter((marker) => marker?.isPrimary === true)[0];
    if (primaryMarker) {
      return primaryMarker;
    }
  } else if (markers[0]) {
    return markers[0];
  }
  return COUNTRY_ISOS.get(countryCode?.toUpperCase()) || COUNTRY_ISOS.get('DE');
};

/**
 * @description to show google map
 */
const MapComponent = compose(
  lifecycle({
    /**
     * @description to set map bounds when component prop changes
     * @param {object} prevState - previous state values
     * @returns {undefined}
     */
    componentDidUpdate(prevState) {
      if (
        JSON.stringify(prevState.markers) !== JSON.stringify(this.props.markers) &&
        this.props.fitBound
      ) {
        this.setState({
          zoomToMarkers: (map) => {
            const bounds = new window.google.maps.LatLngBounds();
            if (map && map.props) {
              map.props.children.forEach((child) => {
                bounds.extend(
                  new window.google.maps.LatLng(
                    child?.props?.children?.props?.position?.lat,
                    child?.props?.children?.props?.position?.lng
                  )
                );
              });
              try {
                map.fitBounds(bounds);
              } catch {}
            }
          },
        });
      }
    },
    /**
     * @description to set map bounds when component mount
     * @returns {undefined}
     */
    componentDidMount() {
      if (this.props.locationFinder === false) {
        this.setState({
          zoomToMarkers: (map) => {
            const bounds = new window.google.maps.LatLngBounds();
            if (map && map.props && map.props.children?.length) {
              map.props.children.forEach((child) => {
                bounds.extend(
                  new window.google.maps.LatLng(
                    child?.props?.children?.props?.position?.lat,
                    child?.props?.children?.props?.position?.lng
                  )
                );
              });
              map.fitBounds(bounds);
            } else {
              bounds.extend(
                new window.google.maps.LatLng(
                  map?.props?.center?.lat,
                  map?.props?.center?.lng
                )
              );
            }
          },
        });
      }
    },
  }),
  withGoogleMap
)((props) => (
  <GoogleMap
    ref={props.zoomToMarkers}
    defaultZoom={isMarkersExists(props?.markers) ? 5 : 3}
    center={getCenter()}
    defaultOptions={{
      styles: mapStyles,
      mapTypeControl: false,
      streetViewControl: false,
    }}
  >
    {props?.markers?.map((m, index) => (
      <>
        {m.isPrimary ? (
          <Marker
            position={m}
            icon={PinIcon}
            optimized={false}
            onClick={() => props.onPinClick(index)}
          />
        ) : (
          <Marker
            position={m}
            icon={PinIconSmall}
            optimized={false}
            onClick={() => props.onPinClick(index)}
          />
        )}
      </>
    ))}
  </GoogleMap>
));
/**
 * google map wrapper component
 */
class Map extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  /**
   * @description renders google map
   * @returns {Node} -  html
   */
  render() {
    return (
      <div className="map">
        <MapComponent
          markers={this.props?.markers}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          locationFinder={this.props?.locationFinder}
          onPinClick={this.props?.onPinClick}
          fitBound={this.props?.fitBound}
          countryCode={this.props?.countryCode}
        />
      </div>
    );
  }
}

Map.defaultProps = {
  fitBound: false,
  markers: [],
  onPinClick: () => {},
};

Map.propTypes = {
  fitBound: PropTypes.bool,
  onPinClick: PropTypes.func,
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
      isPrimary: PropTypes.bool.isRequired,
    })
  ).isRequired,
};

export default Map;
