import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import {
  Image,
  RichText,
  // Link as JssLink,
} from '@sitecore-jss/sitecore-jss-react';
import { showString, setAltUrlForImg } from '../../../utils/helperUtils';
import ReadMore from '../ReadMore';
import { CardStyle } from './CardTheme';
import NavLink from '../globals/NavLinks';
/**
 * @description - On enter key press for accessibility.
 * @param {object} e - Event object.
 * @param {Function} clickHandler - Event handler function.
 * @returns {undefined}
 */
// const onEnter = (e) => {
//   if (e && e.keyCode === 13) {
//     window.open(shareUrl, '', 'width=600, height=400');
//   }
// };

/**
 * @description - Handle menu link click.
 * @params ctaUrl string
 * @returns {undefined}
 */
// const handleClick = (ctaUrl) => {
//   window.open(ctaUrl, '', 'width=600, height=400');
// };

/**
 * @description - Card component for card scrolls.
 * @param {*} param0 - Input props.
 * @returns {Node} - HTML card.
 */
const CardLeader = ({ title, text, img, ctaUrl }) => {
  const [textDesc, setTextDesc] = useState();

  useEffect(() => {
    setTextDesc(text?.value);
  }, [text]);
  return (
    <>
      <CardStyle />
      {ctaUrl?.href ? (
        <div className="cardDiv">
          <div className="card leadership card--leaderwipe" tabIndex={0} role="link">
            {/* <span className="card--wipe--apollo" /> */}
            <NavLink url={ctaUrl}>
              <div className="article-image">
                <Image
                  className="infoIcon"
                  field={setAltUrlForImg(img, 'card-leader')}
                />
              </div>
            </NavLink>
            <ReadMore cardVerticalPadding={24}>
              {title?.value ? (
                <RichText tag="div" className="title" field={title} />
              ) : (
                <div className="title">{showString(title)}</div>
              )}
              <RichText field={{ value: textDesc }} tag="p" />
            </ReadMore>
          </div>
        </div>
      ) : (
        <div className="cardDiv">
          <div className="card leadership card--leaderwipe" tabIndex={0} role="link">
            {/* <span className="card--wipe--apollo" /> */}
            <div className="article-image">
              <Image
                className="infoIcon"
                field={setAltUrlForImg(img, 'card-leader')}
              />
            </div>
            <ReadMore cardVerticalPadding={24}>
              {title?.value ? (
                <RichText tag="div" className="title" field={title} />
              ) : (
                <div className="title">{showString(title)}</div>
              )}
              <RichText field={{ value: textDesc }} tag="p" />
            </ReadMore>
          </div>
        </div>
      )}
    </>
  );
};

CardLeader.defaultProps = {
  title: '',
  text: '',
  img: {},
  altText: '',
  ctaUrl: {
    href: '',
    target: '',
  },
};

CardLeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  text: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  img: PropTypes.shape({}),
  altText: PropTypes.string,
  ctaUrl: PropTypes.string,
};

export default CardLeader;
