/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import lget from 'lodash.get';
import { Link } from 'react-router-dom';
import {
  replaceTextwithJSX,
  isNullorEmpty,
  capitalize,
} from '../../../../../utils/helperUtils';
import parse from 'html-react-parser';
import { attachementDownloadApi } from '../../../../../services/apiServices/candidateService';
import TooltipComponent from '../../../../core/Tooltip';
import ProfileItemTemplate from './Template/ProfileItem';
import { missingInfo } from './Template/MissingInfoTemplate';

/**
 * @description - PersonalInfo component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const PersonalInfo = ({ fields, profileInfo, t, getEmptyFields, componentName }) => {
  const { items } = fields;
  const [data, setData] = useState({});
  const [candidateId, setCandidateId] = useState('');
  const [noData, showNoData] = useState(false);
  useEffect(() => {
    if (profileInfo?.id) {
      setData(profileInfo?.personalDetails);
      setCandidateId(profileInfo?.data?.id);
    }
  }, [profileInfo]);

  useEffect(() => {
    let personalDetailsData = profileInfo?.personalDetails;
    let emptyCount = 0;
    let emptyObj = {};
    let temp = 0;
    let array = [];
    // let obj = missingInfo(items, personalDetailsData);
    items?.map((item, index) => {
      array.push(missingInfo(item, personalDetailsData));
    });
    array.map((item) => {
      Object.keys(item).map((k) => {
        if (k === 'emptyCount') {
          emptyCount = emptyCount + item[k];
        } else if (k === 'temp') {
          temp = temp + item[k];
        }
      });
    });
    emptyObj[componentName] = emptyCount;
    getEmptyFields(emptyObj);
    if (temp === 0) {
      showNoData(true);
    }
  }, [profileInfo]);

  return (
    <div className="accordion-container-block">
      {Object.keys(data).length > 0 && !noData ? (
        items?.map((block, index) => (
          <ProfileItemTemplate
            data={data}
            candidateId={candidateId}
            block={block}
            filterRequired={true}
            t={t}
            itemNumber={index}
          />
        ))
      ) : (
        <div className="no-content-data">{t('no-personal-data')}</div>
      )}
      {/* {noData && <div className="no-content-data">{t('no-personal-data')}</div>} */}
    </div>
  );
};

PersonalInfo.defaultProps = {
  fields: {},
  t: (val) => val,
};

PersonalInfo.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
  };
};

export default withTranslation()(connect(mapStateToProps)(PersonalInfo));
