/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { UserAgentApplication } from 'msal';
import store from '../../redux/store';
import {
  adb2cRedirect,
  tokenSuccess,
  authStateChange,
  loginError,
  initializing,
  initialized,
  passwordReset,
  editProfileStatus,
  setAuthFunctions,
  updatePhone,
  logoutSuccess,
} from '../../redux/actions/loginActions';
import { authstates } from '../../utils/enums';
import { STORAGE, SOCIAL_LOGIN_VALUES } from '../../constants';

// Action dispatches
/**
 * @description _ onLoginProgressDispatch.
 * @returns {Node} - HTML node.
 */
const onLoginProgressDispatch = () => {
  store.dispatch(initializing());
  store.dispatch(passwordReset(false));
  store.dispatch(authStateChange(authstates.PROGRESS));
};

/**
 * @description _ onLoginSuccessDispatch.
 * @param {object} response - response Props passed.
 * @returns {Node} - HTML node.
 */
const onLoginSuccessDispatch = (response) => {
  // stores token
  store.dispatch(adb2cRedirect(true));
  store.dispatch(initialized());
  store.dispatch(tokenSuccess(response));
  store.dispatch(authStateChange(authstates.AUTHENTICATED));
};

/**
 * @description _ onLoginErrorDispatch.
 * @returns {Node} - HTML node.
 */
const onLoginErrorDispatch = () => {
  store.dispatch(initialized());
  store.dispatch(authStateChange(authstates.UNAUTHENTICATED));
  store.dispatch(loginError());
};

/**
 * @description _ onProfileEditedDispatch.
 * @param {object} response - response Props passed.
 * @param {string} phone - phonenumber string.
 * @returns {Node} - HTML node.
 */
const onProfileEditedDispatch = (response, phone) => {
  onLoginSuccessDispatch(response);
  store.dispatch(editProfileStatus(true));
  store.dispatch(updatePhone(phone));
};

/**
 * @description _ onPhoneNumberUpdatedDispatch.
 * @param {object} response - response Props passed.
 * @returns {Node} - HTML node.
 */
const onPhoneNumberUpdatedDispatch = (response) => {
  onLoginSuccessDispatch(response);
};

/**
 * @description _ onPasswordUpdatedDispatch.
 * @param {object} response - response Props passed.
 * @param {string} phone - phonenumber string.
 * @returns {Node} - HTML node.
 */
const onPasswordUpdatedDispatch = (response, phone) => {
  onLoginSuccessDispatch(response);
  store.dispatch(updatePhone(phone));
};

/**
 * @description - Remove user session from localstorage.
 * @returns {undefined}
 */
const removeStorage = (updateUserDetails = false) => {
  if (localStorage) {
    if (updateUserDetails) {
      const userSession = localStorage.getItem('usersession');
      localStorage.setItem('userSessionCopy', userSession);
      localStorage.removeItem(STORAGE.UUID);
    }
    localStorage.removeItem('usersession');
  }
};

/**
 * @description - Login check for all types
 * @param {string} type - login from.
 * @returns {undefined}
 */
const checkLoginType = (type) => {
  switch (type) {
    case 'saveJob':
      sessionStorage.removeItem('applyButtonClickedToLogin');
      sessionStorage.removeItem('createJobAlertButtonClickedToLogin');
      break;
    case 'applyJob':
      sessionStorage.removeItem('savejobButtonClickedToLogin');
      sessionStorage.removeItem('createJobAlertButtonClickedToLogin');
      break;
    case 'createAlert':
      sessionStorage.removeItem('applyButtonClickedToLogin');
      sessionStorage.removeItem('savejobButtonClickedToLogin');
      break;
    case 'forgotPassword':
      break;
    default:
      sessionStorage.removeItem('applyButtonClickedToLogin');
      sessionStorage.removeItem('savejobButtonClickedToLogin');
      sessionStorage.removeItem('createJobAlertButtonClickedToLogin');
  }
};

/**
 * @description - Initialize the MSAL application object.
 * @returns {Object} - Auth Object.
 */
const initAuth = async () => {
  let authProvider = null;
  /**
   * @description - Initialize login.
   * @returns {null} - Null function.
   */
  let login = () => null;
  /**
   * @description - Initialize logout.
   * @returns {null} - Null function.
   */
  let logout = () => null;
  /**
   * @description - Initialize editProfile.
   * @returns {null} - Null function.
   */
  let editProfile = () => null;
  /**
   * @description - Initialize editPhoneNumber.
   * @returns {null} - Null function.
   */
  let editPhoneNumber = () => null;
  /**
   * @description - Initialize editPassword.
   * @returns {null} - Null function.
   */
  let editPassword = () => null;
  /**
   * @description - Initialize refreshToken.
   * @returns {null} - Null function.
   */
  let refreshToken = () => null;

  const {
    adb2cConfigReducer: { msalConfig, authority, b2cPolicy },
    loginReducer: { location },
  } = store.getState();

  if (msalConfig) {
    authProvider = new UserAgentApplication({
      auth: { ...msalConfig.auth },
      cache: { ...msalConfig.cache },
    });

    refreshToken = () => {
      const {
        loginReducer: { user },
      } = store.getState();
      if (user) {
        const { email } = user;
        authProvider
          .ssoSilent({ loginHint: email })
          .then(() => {
            // session silently established
            // console.log(response);
          })
          .catch((error) => {
            // handle error by invoking an interactive login method
            // authProvider.loginRedirect({
            //   scopes: msalConfig.scopes,
            //   extraQueryParameters: msalConfig.extraQueryParameters,
            // });
            throw error;
          });
      }
    };

    /**
     * @description - Login Function.
     * @param {object} policy - login policy.
     * @param {string} type - login from.
     * @param {boolean} jobApply - Job apply clicked or not.
     * @param {string} jobTitle - Job title.
     * @returns {undefined}
     */
    login = (policy, type, jobApply = false, jobTitle = null) => {
      if (sessionStorage) {
        if (policy === b2cPolicy.forgotPassword) {
          const redirectPath = sessionStorage.getItem('redirectPath');
          sessionStorage.setItem('redirectPath', redirectPath);
        } else {
          sessionStorage.setItem(
            'redirectPath',
            location.pathname + location.search
          );
        }
        checkLoginType(type);
        onLoginProgressDispatch();
        authProvider.loginRedirect({
          scopes: msalConfig.scopes,
          extraQueryParameters: {
            ...msalConfig.extraQueryParameters,
            jobApply: jobApply,
            jobTitle: jobTitle,
          },
        });
      }
    };

    /**
     * @description - Logout Function.
     * @returns {undefined}
     */
    logout = (redirectPath) => {
      if (sessionStorage) {
        sessionStorage.setItem('searchParams', location.search);
        sessionStorage.setItem(
          'redirectPath',
          redirectPath ? redirectPath : location.pathname + location.search
        );
        sessionStorage.setItem('isLogout', true);
      }
      removeStorage();
      authProvider.logout();
    };

    /**
     * @description - Profile Update Function.
     * @returns {undefined}
     */
    editProfile = () => {
      if (sessionStorage)
        sessionStorage.setItem('redirectPath', location.pathname + location.search);
      removeStorage(true);
      sessionStorage.setItem('setAccordionOpen', 0);
      authProvider.loginRedirect({
        authority: authority.profileUpdate,
        extraQueryParameters: msalConfig.extraQueryParameters,
      });
    };

    /**
     * @description - Phone number Update Function.
     * @returns {undefined}
     */
    editPhoneNumber = () => {
      if (sessionStorage)
        sessionStorage.setItem('redirectPath', location.pathname + location.search);
      removeStorage(true);
      sessionStorage.setItem('setAccordionOpen', 0);
      authProvider.loginRedirect({
        authority: authority.editPhoneNumber,
        extraQueryParameters: msalConfig.extraQueryParameters,
      });
    };

    /**
     * @description - Password Update Function.
     * @returns {undefined}
     */
    editPassword = () => {
      if (sessionStorage)
        sessionStorage.setItem('redirectPath', location.pathname + location.search);
      removeStorage(true);
      sessionStorage.setItem('setAccordionOpen', 0);
      authProvider.loginRedirect({
        authority: authority.passwordUpdate,
        extraQueryParameters: msalConfig.extraQueryParameters,
      });
    };

    /**
     * @description Call back function.
     * @param {object} error - Error Props passed.
     * @param {object} response - Response Props passed.
     * @returns {Node} - HTML node.
     */
    const authRedirectCallBack = (error, response) => {
      let parsedUserSession = null;
      if (typeof window !== 'undefined') {
        const userSessionCopy = localStorage.getItem('userSessionCopy');
        parsedUserSession = userSessionCopy ? JSON.parse(userSessionCopy) : null;
      }
      // Error handling
      if (error) {
        // Forgot Password
        if (error.errorMessage.indexOf('AADB2C90118') > -1) {
          try {
            onLoginErrorDispatch();
            // Password reset policy/authority
            authProvider.loginRedirect({
              authority: authority.forgotPassword,
              extraQueryParameters: msalConfig.extraQueryParameters,
            });
          } catch (err) {
            throw err;
          }
        }
        //Cancel button
        else if (error.errorMessage.indexOf('AADB2C90091') > -1) {
          if (
            parsedUserSession !== null &&
            parsedUserSession?.state === authstates.AUTHENTICATED
          ) {
            onLoginSuccessDispatch(parsedUserSession.idToken);
          }
          const redirectPath = sessionStorage.getItem('redirectPath');
          setTimeout(() => {
            window.location.href = redirectPath;
          }, 100);
        }
        //Cancel button - Social Login
        else if (error.errorMessage.indexOf('AADB2C90273') > -1) {
          if (window) window.location.href = sessionStorage.getItem('redirectPath');
        }
        //Logout from adb2c page
        else if (error.errorMessage.indexOf('AADB2C92118') > -1) {
          store.dispatch(initialized());
          store.dispatch(authStateChange(authstates.UNAUTHENTICATED));
          logout();
          store.dispatch(logoutSuccess());
          if (window) window.location.href = '/';
        } else {
          sessionStorage.setItem('errorMsg', true);
          setTimeout(() => {
            if (window)
              window.location.href = sessionStorage.getItem('redirectPath');
          }, 3000);
        }
      } else {
        if (
          response.tokenType === 'id_token' &&
          response.idToken.claims['acr'] === b2cPolicy.forgotPassword
        ) {
          login(response.idToken.claims['acr'], 'forgotPassword');
        } else if (
          response.tokenType === 'id_token' &&
          response.idToken.claims['acr'] === b2cPolicy.signUpSignIn
        ) {
          if (window?.dataLayer) {
            const idpVal = response?.idToken?.claims?.idp;
            window.dataLayer.push({
              event: 'Authentication',
              eventCategory:
                response.idToken.claims['email'] &&
                response.idToken.claims.hasOwnProperty(
                  'extension_TermsOfUseConsented'
                )
                  ? 'Create an account'
                  : 'Sign in to an account',
              // createOrSignInMethod: 'MFA',
              // authenticationType: localStorage.getItem('authenticationType'),
              eventAction: SOCIAL_LOGIN_VALUES.get(idpVal?.toLowerCase()) || idpVal,
              eventLabel: localStorage.getItem('authenticationType'),
            });
            // if (response.idToken.claims['email'])
            //   window.dataLayer.push({
            //     event: 'new-user-registration',
            //   });
          }
          onLoginSuccessDispatch(response);
        } else if (
          response.tokenType === 'id_token' &&
          response.idToken.claims['acr'] === b2cPolicy.profileUpdate
        ) {
          const phone =
            parsedUserSession !== null ? parsedUserSession.user.phone : null;
          onProfileEditedDispatch(response, phone);
        } else if (
          response.tokenType === 'id_token' &&
          response.idToken.claims['acr'] === b2cPolicy.editPhoneNumber
        ) {
          onPhoneNumberUpdatedDispatch(response);
        } else if (
          response.tokenType === 'id_token' &&
          response.idToken.claims['acr'] === b2cPolicy.passwordUpdate
        ) {
          const phone =
            parsedUserSession !== null ? parsedUserSession.user.phone : null;
          onPasswordUpdatedDispatch(response, phone);
        } else {
          logout();
        }
      }
      localStorage.removeItem('userSessionCopy');
      localStorage.removeItem('updateUserDetails');
    };

    // setting callback.
    authProvider.handleRedirectCallback(authRedirectCallBack);
  }

  store.dispatch(
    setAuthFunctions({
      authProvider,
      login,
      logout,
      editProfile,
      editPhoneNumber,
      editPassword,
      refreshToken,
    })
  );
  return {
    authProvider,
    login,
    logout,
    editProfile,
    editPhoneNumber,
    editPassword,
    refreshToken,
  };
};

export default initAuth;
