import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dropdown from '../Dropdowns/Dropdown';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { isDataExists } from '../../../utils/helperUtils';
import './filteritem.scss';

/**
 * @description - Filter items.
 * @param {object} props - Input props.
 * @returns {Node} - Filter blocks.
 */
const FilterItem = (props) => {
  const { emptyText, dropdownOptions, iconBefore, iconAfter } = props;
  const { dropdown, dropdownSize, mode, text, selectedValue } = props;
  const { selectedOption, actionParams, clickHandler } = props;
  const [active, setActive] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(selectedValue || '');
  const ref = useRef();
  const isListEmpty = !isDataExists(dropdownOptions);
  useOnClickOutside(ref, () => setActive(false));

  /**
   * @description - Toggle active states.
   * @returns {undefined}
   */
  const toggleActive = () => {
    if (isListEmpty) {
      return;
    }
    setActive(!active);
  };

  /**
   * @description - Open/Close menu on enter.
   * @param {object} e - Event object.
   * @returns {undefined}
   */
  const onEnter = (e) => {
    if (e && (e.keyCode === 13 || e.keyCode === 32)) {
      toggleActive();
    }
  };

  useEffect(() => {
    /**
     * @description - to hide dropdown on tab focus out
     * @param {*} event - event
     * @returns {Object} - event listener
     */
    const listener = (event) => {
      if (
        event &&
        event.keyCode === 9 &&
        !event.target?.className.includes('sort-option')
      ) {
        setActive(false);
      }
    };
    document.addEventListener('keyup', listener);
    return () => {
      document.addEventListener('keyup', listener);
    };
  }, []);

  return (
    <div
      className={classNames('filter-block', { active: active })}
      onClick={toggleActive}
      ref={ref}
      onKeyUp={onEnter}
      role="combobox"
      tabIndex={0}
      aria-expanded={active}
      aria-controls="filter-item"
    >
      <div className="triangle"></div>
      <div className="filter-block-inner">
        <div
          className={classNames('filter-block-clicker', { blocked: isListEmpty })}
        ></div>
        {iconBefore && (
          <div className="icon-filters">
            {iconBefore}
            {/* <img src={iconBefore} alt="" /> */}
          </div>
        )}

        <div className={classNames('text', { blocked: isListEmpty })}>
          {selectedOption === null ? (
            <span className="placeholder">{emptyText}</span>
          ) : (
            selectedLabel || <span className="placeholder">{text}</span>
          )}
        </div>

        {iconAfter && (
          <div className="icon-down">
            {iconAfter}
            {/* <img src={props.iconAfter} alt="" /> */}
          </div>
        )}
      </div>
      {dropdown && (
        <Dropdown
          dropdown={dropdown}
          dropdownSize={dropdownSize}
          dropdownOptions={dropdownOptions}
          mode={mode}
          actionParams={actionParams}
          clickHandler={clickHandler}
          selectedOption={selectedOption}
          setSelectedLabel={setSelectedLabel}
        />
      )}
    </div>
  );
};

FilterItem.defaultProps = {
  iconBefore: '',
  text: '',
  iconAfter: '',
  dropdown: 'sort',
  dropdownSize: '',
  dropdownOptions: [
    {
      title: 'Newest',
    },
    {
      title: 'Oldest',
    },
  ],
  mode: '',
  emptyText: 'Select..',
  selectedOption: '',
  setSelectedOption: () => {},
  actionParams: {},
  clickHandler: () => {},
  selectedValue: '',
};

FilterItem.propTypes = {
  iconBefore: PropTypes.string,
  text: PropTypes.string.isRequired,
  iconAfter: PropTypes.string,
  /** Style type and inner content */
  dropdown: PropTypes.oneOf(['job-filters', 'sort', 'job-alert']).isRequired,
  /** Flyout size */
  dropdownSize: PropTypes.oneOf(['', 'small']).isRequired,
  /** Simple value options */
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    })
  ),
  mode: PropTypes.oneOf(['right']), // Default none = left
  emptyText: PropTypes.string,
  selectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSelectedOption: PropTypes.func,
  actionParams: PropTypes.shape({}),
  clickHandler: PropTypes.func,
  selectedValue: PropTypes.string,
};

export default FilterItem;
