/* eslint-disable */
import heroImg from '../../../assets/images/hero.jpg';
import DashboardProfile from '../../../assets/images/icon__dashboard-profile.svg';
import DashboardSetting from '../../../assets/images/icon__dashboard-setting.svg';
import Dashboard from '../../../assets/images/icon__dashboard.svg';
import UploadIcon from '../../../assets/images/icon_upload.svg';

export const mockData = {
  componentName: 'Dashboard',
  fields: {
    backgroundImage: {
      value: {
        src: heroImg,
        alt: 'info-active',
        width: '200',
        height: '200',
      },
    },
  },
  placeholders: {
    'jss-left': [
      {
        componentName: 'DashboardProfile',
        dataSource: '{F7BA8A88-2133-41BB-A58B-986DEA4BD5DA}',
        params: {},
        fields: {
          icon: {
            value: {
              src: UploadIcon,
              alt: 'info-active',
              width: '200',
              height: '200',
            },
          },
          title: {
            value: 'Welcome, {0}!',
          },
        },
      },
      {
        componentName: 'DashboardNav',
        dataSource: '{F7BA8A88-2133-41BB-A58B-986DEA4BD5DA}',
        params: {},
        fields: {
          items: [
            {
              fields: {
                icon: {
                  value: {
                    src: Dashboard,
                    alt: 'info-active',
                    width: '200',
                    height: '200',
                  },
                },
                title: {
                  value: 'Dashboard',
                },
                url: {
                  value: '/candidate/dashboard',
                },
              },
            },
            {
              fields: {
                icon: {
                  value: {
                    src: DashboardProfile,
                    alt: 'info-active',
                    width: '200',
                    height: '200',
                  },
                },
                title: {
                  value: 'Profile',
                },
                url: {
                  value: '/candidate/profile',
                },
              },
            },
            {
              fields: {
                icon: {
                  value: {
                    src: DashboardSetting,
                    alt: 'info-active',
                    width: '200',
                    height: '200',
                  },
                },
                title: {
                  value: 'Account Settings',
                },
                url: {
                  value: '/candidate/account-settings',
                },
              },
            },
          ],
        },
      },
    ],
    'jss-content': [
      {
        componentName: 'DashboardProfileMobile',
        dataSource: '{F7BA8A88-2133-41BB-A58B-986DEA4BD5DA}',
        params: {},
        fields: {
          icon: {
            value: {
              src: UploadIcon,
              alt: 'info-active',
              width: '200',
              height: '200',
            },
          },
          title: {
            value: 'Welcome {0}',
          },
          width: {
            value: '100',
          },
        },
      },
      {
        uid: '7f01db03-f45b-428b-b502-b791d06a7969',
        componentName: 'ProgressBarBlock',
        dataSource:
          '/sitecore/content/Manpowergroup/Manpowergroup/Manpowergroup-Germany/Home/Candidate/Dashboard/Data/Dashboard Progressbar Block',
        params: {},
        fields: {
          items: [
            {
              id: '231856b9-fae6-4c2d-9f17-f966c2d652e6',
              name: 'Partial',
              displayName: 'Partial',
              fields: {
                text: {
                  value: 'A More complete profile can increase your matches by 30%.',
                },
                ctaUrl: {
                  value: {
                    href: '/de/candidate/buildprofile',
                    id: '{BCC279D6-AAD1-4F36-8D48-45078C0BA519}',
                    querystring: '',
                    target: '|Custom',
                    text: '',
                    anchor: '',
                    title: '',
                    class: '',
                    url:
                      '/Manpowergroup/Manpowergroup/Manpowergroup-Germany/Home/Candidate/BuildProfile',
                    linktype: 'internal',
                  },
                },
                ctaText: {
                  value: 'CONTINUE BUILDING PROFILE',
                },
                percentage: {
                  value: '',
                },
                type: {
                  value: '1',
                },
                width: {
                  value: '100',
                },
                title: {
                  value: 'Profile: {0}% complete',
                },
              },
            },
            {
              id: 'a78096e1-740c-4693-a8ac-64d14fff4fcb',
              name: 'Complete',
              displayName: 'Complete',
              fields: {
                text: {
                  value: 'A More complete profile can increase your matches by 30%.',
                },
                ctaUrl: {
                  value: {
                    href: '/de/candidate/buildprofile',
                    id: '{BCC279D6-AAD1-4F36-8D48-45078C0BA519}',
                    querystring: '',
                    target: '|Custom',
                    text: '',
                    anchor: '',
                    title: '',
                    class: '',
                    url:
                      '/Manpowergroup/Manpowergroup/Manpowergroup-Germany/Home/Candidate/BuildProfile',
                    linktype: 'internal',
                  },
                },
                ctaText: {
                  value: 'UPDATE PROFILE',
                },
                percentage: {
                  value: '',
                },
                type: {
                  value: '1',
                },
                width: {
                  value: '100',
                },
                title: {
                  value: 'Do you need to update your profile?',
                },
              },
            },
          ],
        },
      },
      {
        componentName: 'DashboardSuggestedJobs',
        fields: {
          title: {
            value: 'SUGGESTED JOBS',
          },
          width: {
            value: '100',
          },
        },
      },
      {
        componentName: 'DashboardSavedJobs',
        fields: {
          title: {
            value: 'SAVED JOBS',
          },
          width: {
            value: '100',
          },
          icon: {
            value: {
              src: DashboardProfile,
              alt: 'info-active',
              width: '200',
              height: '200',
            },
          },
        },
      },
      {
        componentName: 'DashboardApplications',
        fields: {
          title: {
            value: 'CURRENT APPLICATIONS',
          },
          columnSettings: [
            {
              id: '7730be13-d71e-4584-b914-c68ad16b755sdfsdf4',
              fields: {
                key: {
                  value: 'dateAdded',
                },
                label: {
                  value: 'dateAdded',
                },
              },
            },
            {
              id: '7730be13-d71e-4584-b914-c68adgwwh16b7554',
              fields: {
                key: {
                  value: 'position',
                },
                label: {
                  value: 'position',
                },
              },
            },
            {
              id: '7730be13-d71e-4584-b914-c68ad16bwwer7554',
              fields: {
                key: {
                  value: 'companyName',
                },
                label: {
                  value: 'companyName',
                },
              },
            },
            {
              id: '7730be13-d71e-4584-b914-c68ad16b7gg5asd54',
              fields: {
                key: {
                  value: 'employmentType',
                },
                label: {
                  value: 'employmentType',
                },
              },
            },
            {
              id: '7730be13-d71e-4584-b914-c68ad16b755asaa4',
              fields: {
                key: {
                  value: 'status',
                },
                label: {
                  value: 'status',
                },
              },
            },
            {
              id: '7730be13-d71e-4584-b914-c6ee8ad16b755as4',
              fields: {
                key: {
                  value: 'path',
                },
                label: {
                  value: 'path',
                },
              },
            },
          ],
          width: {
            value: '100',
          },
          subTitle: {
            value: 'Whithdraw Your Application',
          },
          text: {
            value:
              "You are about to withdraw your application for the Manufacturing Engineer job. This can't be undone and you will no longer be considered for this position. Please confirm and let us know why you've decided to withdraw the application.",
          },
          message: {
            value: 'The job application has withdrawn successfully.',
          },
          items: [
            {
              id: '52868f9e-317f-4693-bb08-6833d6d6f1f5',
              name: 'Widthdraw Reson',
              displayName: 'Widthdraw Reason',
              fields: {
                values: [
                  {
                    id: '80c6ca73-e86d-4d75-946b-77572f73befa',
                    fields: {
                      key: {
                        value: 'got a different job',
                      },
                      label: {
                        value: 'got a different job',
                      },
                    },
                  },
                  {
                    id: '80c6ca73-e86d-4d75-946b-77572fdf73befa',
                    fields: {
                      key: {
                        value: 'applied by accident',
                      },
                      label: {
                        value: 'applied by accident',
                      },
                    },
                  },
                  {
                    id: '80c6ca73-e86d-4d75-946b-77572fdf73befa',
                    fields: {
                      key: {
                        value: 'I am not qualified',
                      },
                      label: {
                        value: 'I am not qualified',
                      },
                    },
                  },
                  {
                    id: '80c6ca73-e86d-4d75-946b-77572fdf73befa',
                    fields: {
                      key: {
                        value: 'This job doesn’t meet my needs',
                      },
                      label: {
                        value: 'This job doesn’t meet my needs',
                      },
                    },
                  },
                  {
                    id: '80c6ca73-e86d-4d75-946b-77572fdf73befa',
                    fields: {
                      key: {
                        value: 'I am no longer interested',
                      },
                      label: {
                        value: 'I am no longer interested',
                      },
                    },
                  },
                  {
                    id: '80c6ca73-e86d-4d75-946b-77572fdf73befa',
                    fields: {
                      key: {
                        value: 'Other',
                      },
                      label: {
                        value: 'Other',
                      },
                    },
                  },
                ],
                readonly: {
                  value: false,
                },
                label: {
                  value: 'Reason for withdrawing the application',
                },
                placeholderText: {
                  value: 'Reason for withdrawing the application',
                },
                name: {
                  value: 'comments',
                },
                inputType: {
                  value: 'NormalDropdown',
                },
                required: {
                  value: true,
                },
                value: {
                  value: 'other',
                },
                width: {
                  value: '100',
                },
                pattern: {
                  value: '',
                },
                Validations: [],
                CustomRequiredValidator: [
                  {
                    id: '7730be13-d71e-4584-b914-c68ad16b7554',
                    fields: {
                      message: {
                        value: 'Please select the reason for withdrawal.',
                      },
                      value: {
                        value: 'true',
                      },
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        componentName: 'DashboardCallout',
        fields: {
          title: {
            value: 'YOUR RECRUITER',
          },
          width: {
            value: '50',
          },
        },
      },
      {
        componentName: 'DashboardAgent',
        fields: {
          title: {
            value: 'LOCAL BRANCH',
          },
          width: {
            value: '50',
          },
        },
      },
      {
        componentName: 'DashboardCareerResources',
        fields: {
          title: {
            value: 'CAREER RESOURCES',
          },
          width: {
            value: '100',
          },
        },
      },
    ],
  },
};
