import React from 'react';
import PropTypes from 'prop-types';

import './boundaries.scss';

/**
 *
 * @param {*} param0
 */
const OverlayLoader = ({ loaderMsg }) => (
  <div className="overlay text-center">
    <div
      className="spinner-grow"
      style={{ width: '0.5rem', height: '0.5rem' }}
      role="status"
    />
    <div className="spinner-grow spinner-grow-sm" role="status" />
    <div
      className="spinner-grow"
      style={{ width: '1.5rem', height: '1.5rem' }}
      role="status"
    />
    <span className="sr-only">{loaderMsg}</span>
  </div>
);

OverlayLoader.propTypes = {
  loaderMsg: PropTypes.string,
};

OverlayLoader.defaultProps = {
  loaderMsg: 'Loading...',
};

export default OverlayLoader;
