import { PARSE, PROFILE_INFO_FORM } from '../actions/actionTypes';

const initialState = {
  data: {
    EditPersonalInfo: undefined,
    EditExperience: undefined,
    EditExpertiseAndSkills: undefined,
    EditEducation: undefined,
    EditCertifications: undefined,
    EditCoverLetters: undefined,
    deletedEducationList: [],
    deletedExperienceList: [],
    deletedFiles: [],
    files: {},
    UploadCandidateResume: undefined,
  },
  count: [],
  countIfValid: [],
  parse: false,
};

const profileInfoFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_INFO_FORM.SAVE:
      return {
        ...state,
        data: action.payload,
      };
    case PROFILE_INFO_FORM.SAVE_COUNT_IF_VALID:
      const uniq = [...new Set([...state.countIfValid, action.payload])];
      return {
        ...state,
        countIfValid: uniq,
      };
    case PROFILE_INFO_FORM.SAVE_COUNT:
      return {
        ...state,
        count: [...state.count, action.payload],
      };
    case PROFILE_INFO_FORM.CLEAR_COUNT:
      return {
        ...state,
        count: [],
        countIfValid: [],
      };
    case PROFILE_INFO_FORM.CLEAR:
      return initialState;
    case PARSE.SET:
      return {
        ...state,
        parse: action.payload,
      };
    default:
      return state;
  }
};
export default profileInfoFormReducer;
