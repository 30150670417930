import React from 'react';
import { withTranslation } from 'react-i18next';
//import { Text } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import { mediaApi, RichText } from '@sitecore-jss/sitecore-jss-react';
import { getImg } from '../../../utils/helperUtils';

/**
 * @description - Contact Us Callout bar.
 * @param {Object} param0 - Input props.
 * @returns {Node} - HTML Template.
 */
const ContactCalloutBar = ({ fields, t }) => {
  const { title, text, phone, hours, color, backgroundImage } = fields;
  const theme = color?.value ? color.value.toLowerCase() : 'blue';
  return (
    <section className={`calloutbar-section contact gradient${theme}`}>
      {/* <div className={`gradient_${theme}`}></div> */}
      {backgroundImage?.value && backgroundImage?.value != '' && (
        <div
          className="calloutbar-image"
          style={{
            backgroundImage: `url(${mediaApi.updateImageUrl(
              getImg(backgroundImage)
            )})`,
          }}
        ></div>
      )}
      {!getImg(backgroundImage) && theme == 'blue' && (
        <div className="calloutbar-x"></div>
      )}
      <div className="calloutbar-body container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <RichText tag="div" className="title reversed" field={title} />
            {text && <RichText tag="p" className="text-content" field={text} />}

            <div className="phone-hours">
              <div className="phone">
                <div className="phone-heading">
                  <strong>{t('phone')}</strong>
                </div>
                <a
                  href={`tel: ${phone.value}`}
                  aria-label="Phone number opens in a new window/tab"
                >
                  <RichText className="phone-value" tag="div" field={phone} />
                </a>
              </div>
              <div className="hours">
                <div className="hours-heading">
                  <strong>{t('hours')}</strong>
                </div>
                <RichText className="hours-value" tag="div" field={hours} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ContactCalloutBar.defaultProps = {
  fields: {
    title: { value: '' },
    text: { value: '' },
    phone: { value: '' },
    hours: { value: '' },
    color: { value: 'pale_blue' },
    backgroundImage: {
      value: {
        src: '',
      },
    },
  },
};

ContactCalloutBar.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    phone: PropTypes.string.isRequired,
    hours: PropTypes.string.isRequired,
    color: PropTypes.string,
    backgroundImage: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ContactCalloutBar);
