import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ArrowIcon from './ArrowIcon';

/**
 *
 * @param {*} param0
 */
const Button = ({
  cssClass,
  text,
  handleButtonClick,
  actionParams,
  isLoading,
  isDisabled,
  type,
  typeProp,
  arrow,
}) => (
  <>
    <button
      type={type}
      className={`primary-button ${cssClass}`}
      onClick={handleButtonClick ? (e) => handleButtonClick(e, actionParams) : null}
      disabled={isDisabled}
      {...typeProp}
    >
      {isLoading && (
        <Fragment>
          <span
            className={` ${cssClass} spinner-border spinner-border-sm`}
            role="status"
          ></span>
        </Fragment>
      )}
      {text}
      {arrow && <ArrowIcon />}
    </button>
  </>
);

Button.defaultProps = {
  cssClass: '',
  text: 'Button',
  handleButtonClick: null,
  actionParams: null,
  isLoading: false,
  isDisabled: false,
  type: 'button',
  typeProp: {},
  arrow: false,
};

Button.propTypes = {
  /** Color class  */
  cssClass: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func,
  actionParams: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  type: PropTypes.string,
  typeProp: PropTypes.shape({}),
};

export default Button;
