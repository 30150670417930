import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import TextboxComplex from '../../Forms/FormFields/Textbox';
import Button from '../../globals/buttons/Button';
import { HeaderContext } from '../../SiteHeader/HamburgerMenu/HeaderContext';

/**
 * @description - Search site form.
 * @param {object} param0 - Input props.
 * @returns {Node} - Form element.
 */
const SiteSearchForm = ({ mode, urlVal, t, location, regexValidationPattern }) => {
  const history = useHistory();
  const params = new URLSearchParams(location?.search);
  const headerVals = useContext(HeaderContext) || {};
  const { isInHeader, setMenuOpen, setMenuSection, menuOpen } = headerVals;
  const name = `searchSiteText${isInHeader ? 'Header' : ''}`;
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      searchSiteText: params.get('mode') === 'site' ? params.get('searchText') : '',
      searchSiteTextHeader: '',
    },
  });

  /**
   * @description - On submit/search function.
   * @param {object} values - Form values.
   * @returns {undefined} - Sets the params and redirect.
   */
  const onSubmit = (values = {}) => {
    params.delete('searchKeyword');
    params.delete('filters');
    params.delete('page');
    params.set('searchText', values[name]);
    params.set('mode', mode);
    if (location?.pathname === urlVal) {
      history.replace({
        pathname: urlVal,
        search: String(params),
      });
    } else {
      history.push({
        pathname: urlVal,
        search: String(params),
      });
    }
    setMenuOpen(false);
    setMenuSection('');
    if (isInHeader) {
      setValue(name, null);
    } else {
      setValue(name, params.get('searchText'));
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    if (!isInHeader && params.get('mode') === 'site') {
      setValue(name, params.get('searchText'));
      clearErrors(name);
    }
    if (!menuOpen && isInHeader) {
      clearErrors(name);
    }
  }, [location, isInHeader, setValue, name, menuOpen, clearErrors]);

  return (
    <form
      id={`search-site-${isInHeader && 'header'}`}
      role="tabpanel"
      aria-labelledby="search-site-tab"
      tabIndex={0}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={`search-everything ${mode === 'site' ? 'active' : ''}`}>
        <div className="row">
          <div className="col-lg-8">
            <TextboxComplex
              id={`search_everything_${isInHeader && 'header'}`}
              name={name}
              label={t('search-searchplaceholder')}
              isSearchEnabled={false}
              register={register}
              value={getValues(name)}
              trimLabelCharacters={40}
              showOrgVal
              getValues={getValues}
              setValue={setValue}
              customError={errors}
              dataValidations={[
                {
                  fields: {
                    message: {
                      value: t('search-requiredfieldmessage'),
                    },
                    value: {
                      value: 'true',
                    },
                    inputType: {
                      value: 'requiredValidator',
                    },
                  },
                },
                {
                  fields: {
                    message: {
                      value: t('search-validation-message'),
                    },
                    inputType: {
                      value: 'regexPatternvalidator',
                    },
                    value: {
                      value: regexValidationPattern || '',
                    },
                  },
                },
              ]}
            />
          </div>
          <div className={'col-lg-4 searchbtn-pad-lg'}>
            <Button
              cssClass="reversed"
              text={t('search-searchsite')}
              handleButtonClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default SiteSearchForm;

SiteSearchForm.propTypes = {
  urlVal: PropTypes.string.isRequired,
  regexValidationPattern: PropTypes.shape({}).isRequired,
  mode: PropTypes.string.isRequired,
  location: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};
