import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Link as JssLink,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import {
  checkJSSObject,
  checkHref,
  checkLinkType,
} from '../../../../utils/helperUtils';

/**
 * @description - Header main links
 * @param {object} props - Input props
 * @returns {Node} - Links for route
 */
const NavLink = (props) => {
  const { url, children, cssClass, sitecoreContext, ariaLabel } = props;
  const isJSSObject = checkJSSObject(url);
  const { pageEditing } = sitecoreContext;
  let linkVal = null;
  // const isJSSText = ctaText && ctaText.hasOwnProperty('value');
  // const isTextExists = isJSSText ? ctaText?.value : ctaText;
  const isHrefVlaue = isJSSObject ? checkHref(url) : url;
  if ((isJSSObject && checkLinkType(url)) || !isJSSObject) {
    linkVal = (
      <Link
        to={isHrefVlaue}
        className={cssClass}
        target={url?.value?.target || url?.target}
      >
        {children}
      </Link>
    );
  } else {
    linkVal = (
      <JssLink
        field={url}
        className={cssClass}
        editable={false}
        {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
      >
        {children}
      </JssLink>
    );
  }

  return pageEditing || isHrefVlaue ? linkVal : null;
  // return (
  //   <Fragment>
  //     {checkLinkType(url) ? (
  //       <Link
  //         to={checkHref(url)}
  //         className={cssClass}
  //         target={url?.value?.target || url?.target}
  //       >
  //         {children}
  //       </Link>
  //     ) : (
  //       <JssLink field={url?.value || url} className={cssClass}>
  //         {children}
  //       </JssLink>
  //     )}
  //   </Fragment>
  // );
};

NavLink.propTypes = {
  url: PropTypes.shape({
    value: PropTypes.shape({
      linktype: PropTypes.string,
      href: PropTypes.string,
    }),
  }).isRequired,
  children: PropTypes.node.isRequired,
  cssClass: PropTypes.string,
  ariaLabel: PropTypes.string,
};

NavLink.defaultProps = {
  url: {
    value: {
      linktype: '',
      href: '#',
    },
  },
  cssClass: '',
  ariaLabel: '',
};

export default withSitecoreContext()(NavLink);
