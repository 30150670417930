/*eslint-disable */
import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ButtonLink from '../../../core/globals/link/ButtonLink';
import AuthWrapper from '../../../core/LoginMSAL/AuthWrapper';
import { authstates } from '../../../../utils/enums';
import Button from '../../../core/globals/buttons/ButtonWithIcon';
import TextButton from '../../../core/globals/buttons/TextButton.jsx';
import AlertInline from '../../ErrorBoundary/AlertInline';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useSelector } from 'react-redux';
import { isNullorEmpty } from '../../../../../src/utils/helperUtils';
/**
 *
 * @param {*} apply
 * @param {*} jobDetails
 */
const ApplyJob = ({
  apply,
  jobDetails,
  reversedClass,
  blue = '',
  reversed = '',
  applyJobUrl,
  t,
  revenue,
  classDetails = '',
  cssClass = '',
  sitecoreContext,
}) => {
  const history = useHistory();
  const {
    positionID,
    jobID,
    jobItemID,
    jobType,
    jobTitle,
    companyName,
    jobLocation,
    employmentType,
    domain,
    education,
    salaryRate,
    //applyNowUrl,
    siteName,
    applyUrl,
    applicationType,
    clientID,
  } = jobDetails;

  const [utmParameters, setutmParameters] = useState({});
  const authStates = {
    AUTHENTICATED: 'Authenticated',
    UNAUTHENTICATED: 'Non-authenticated',
    PROGRESS: 'PROGRESS',
  };

  let sourceValue;
  const countryBrandName = sitecoreContext?.Country?.brandName;
  if (
    !isNullorEmpty(utmParameters?.UTM_Source) &&
    !isNullorEmpty(utmParameters?.UTM_Medium)
  ) {
    sourceValue = utmParameters.UTM_Source + '/' + utmParameters.UTM_Medium;
  } else if (!isNullorEmpty(utmParameters?.UTM_Source)) {
    sourceValue = utmParameters.UTM_Source;
  } else if (
    !isNullorEmpty(utmParameters?.Referer) &&
    utmParameters?.Referer !== 'direct'
  ) {
    sourceValue = utmParameters.Referer;
  } else {
    sourceValue = countryBrandName ? countryBrandName : '';
  }
  const params = new URLSearchParams();
  const loginReducer = useSelector((state) => state.loginReducer);

  params.set('positionID', positionID || '');
  params.set('source', sourceValue);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const jobSessionQueryParams = sessionStorage.getItem('utmParams')
        ? sessionStorage.getItem('utmParams')
        : sessionStorage.getItem('utmReferrer');
      if (jobSessionQueryParams) {
        const parsedJobSessionQueryParams = jobSessionQueryParams
          ? JSON.parse(jobSessionQueryParams)
          : {};
        setutmParameters(parsedJobSessionQueryParams);
      }
    }
  }, []);

  const addToDatalayer = () => {
    if (window?.dataLayer) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          add: {
            products: [
              {
                name: jobTitle || 'na',
                id: jobID || positionID || 'na',
                brand: companyName || 'na',
                category: domain || 'na',
                variant: employmentType || 'na',
                price: revenue || 'na',
                quantity: 1,
                dimension11: `Location: ${
                  jobLocation ? jobLocation : 'na'
                } | Education: ${
                  education ? education.join(', ') : 'na'
                } | Salary: ${salaryRate ? salaryRate : 'na'} | Hours: ${
                  jobType ? jobType : 'na'
                }`,
                dimension12: `${
                  authStates?.[loginReducer?.state || 'UNAUTHENTICATED']
                }`,
                dimension13: `${
                  authStates?.[
                    loginReducer?.state || authStates?.UNAUTHENTICATED
                  ] === authStates?.UNAUTHENTICATED
                    ? 'quick apply'
                    : 'standard apply'
                }`,
                dimension14: 'na',
                dimension15: 'na',
                dimension16: clientID ? clientID : 'na',
                dimension17: jobLocation ? jobLocation : 'na',
                dimension18: education ? education.join(', ') : 'na',
                dimension19: salaryRate ? salaryRate : 'na',
                dimension20: jobType ? jobType : 'na',
              },
            ],
          },
        },
      });
    }
  };

  return (
    <div className={`${cssClass ? cssClass : ''} cta`}>
      {!jobDetails?.isApplied ? (
        applyUrl ? (
          <TextButton
            cssClass={`text-flow ${reversedClass ? 'external-cta' : ''}`}
            text={
              companyName
                ? t('apply with company as').replace(
                    '{0}',
                    companyName.toUpperCase()
                  )
                : t('apply on company website')
            }
            handleTextClick={() => {
              addToDatalayer();
              window.open(applyUrl);
            }}
            arrow
          />
        ) : (
          <Button
            classArrow={cssClass ? false : true}
            cssClass={`${
              (countryBrandName?.toLowerCase() === 'stegplus' && !reversedClass) ||
              blue
                ? 'blue'
                : ''
            } ${reversed ? 'reversed' : ''} ${
              reversedClass ? 'reversed-search' : 'orange-sd'
            }`}
            text={apply}
            handleButtonClick={() => {
              addToDatalayer();
              history.push({
                pathname: applyJobUrl?.href,
                search: String('?id=' + jobItemID),
              });
            }}
          />
        )
      ) : (
        <div className="status-applied">
          <AlertInline message={t('job-applied')} type="success jobdetails" />
        </div>
      )}
    </div>
  );
};

ApplyJob.defaultProps = {
  jobDetails: {},
  applyJobUrl: {},
  blue: '',
  reversedClass: false,
  reversed: '',
  apply: '',
  cssClass: '',
};

ApplyJob.propTypes = {
  jobDetails: PropTypes.shape({}),
  applyJobUrl: PropTypes.shape({}),
  blue: PropTypes.string,
  reversed: PropTypes.string,
  apply: PropTypes.string,
};

export default withRouter(withTranslation()(withSitecoreContext()(ApplyJob)));
