import { createGlobalStyle } from 'styled-components';

export const ErrorScreenStyles = createGlobalStyle`
.errorScreen {
    .calloutbar-section {
        background: linear-gradient(180deg, ${({ theme }) =>
          theme?.primaryColor},  ${({ theme }) => theme?.gradient})  
    }
}
`;
