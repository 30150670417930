import React from 'react';
import PropTypes from 'prop-types';
// import JobFilters from '../Filters/JobFilters';
// import JobAlert from '../Filters/JobAlert';
import Sort from './Sort';
import './dropdowns.scss';
/**
 * @description - Dropdown options.
 * @param {object} props - Input props.
 * @returns {Node} - HTML block.
 */
const Dropdown = (props) => {
  const { dropdownSize, mode, dropdown, dropdownOptions } = props;
  const { clickHandler, actionParams, selectedOption, setSelectedLabel } = props;
  return (
    <div className={`dropdown-block ${dropdownSize ? dropdownSize : ''} ${mode}`}>
      <div className="dropdown-block-inside">
        <div className="dropdown-content">
          {/* {props.dropdown === 'job-filters' && (
              <JobFilters
                title="Filter Your Search Results"
                distanceText="Distance from 'Chicago':"
                milesText="miles"
                ctaText="update"
                cancelText="cancel"
              />
            )} */}
          {dropdown === 'sort' && (
            <Sort
              options={dropdownOptions}
              selectedOption={selectedOption}
              clickHandler={clickHandler}
              actionParams={actionParams}
              setSelectedLabel={setSelectedLabel}
            />
          )}
          {/* {props.dropdown === 'job-alert' && <JobAlert />} */}
        </div>
      </div>
    </div>
  );
};

Dropdown.defaultProps = {
  dropdown: 'sort',
  dropdownSize: '',
  dropdownOptions: [
    {
      title: 'Newest',
    },
    {
      title: 'Oldest',
    },
  ],
  mode: '',
  setSelectedOption: null,
};

Dropdown.propTypes = {
  /** Style type and inner content */
  dropdown: PropTypes.oneOf(['job-filters', 'sort', 'job-alert']),

  /** Flyout size */
  dropdownSize: PropTypes.oneOf(['', 'small']),

  /** Simple value options */
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    })
  ),

  mode: PropTypes.oneOf(['right']), // Default none = left
  setSelectedOption: PropTypes.func,
};

export default Dropdown;
