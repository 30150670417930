/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ButtonLink from '../../../../core/globals/link/ButtonLink';
import '../../apply_job_flow.scss';
import ProgressBar from '../../../../core/ProgressBar';
import { withTheme } from 'styled-components';
import Loader from '../../../../core/ErrorBoundary/Loader';


/**
 * @description - Success component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML template.
 */
const SuccessBlock = ({ fields, t, theme, profileInfo }) => {
  const { items } = fields;
  const [percentage, setPercentage] = useState(0);
  const [profileDataLoading, setprofileDataLoading] = useState(true);  

  useEffect(() => {
    setprofileDataLoading(profileInfo?.loading);
    if (profileInfo?.data?.id) {
      const percentValue = parseInt(profileInfo?.data?.profilepercentage);
      setPercentage(percentValue);
    } else if (profileInfo?.data?.error) {
      setprofileDataLoading(profileInfo?.loading);      
    }
  }, [profileInfo]);

  const partiallyCompleteProfileTemplate = (layout) => {
    let titleText = layout?.title?.value?.replace('{0}', percentage);
    return (
      <div className="build-profile">
        <div className="titleText">{titleText}</div>
        <div className="col-lg-6 customStyle">
          <ProgressBar
            completed={percentage}
            primaryColor={theme?.gradient}
            secondaryColor={theme?.primaryColor}
            gradient
          />
        </div>
        <div className="tipText">
          <strong>{t('tip')}&nbsp;</strong>
          <span>{layout?.text?.value}</span>
        </div>
        {layout?.ctaText?.value && (
          <div className="btnContainer">
            <ButtonLink
              ctaText={layout?.ctaText}
              ctaUrl={layout?.ctaUrl}
              externalLink={
                layout?.ctaUrl?.value?.linktype === 'internal' ? false : true
              }
            />
          </div>
        )}
      </div>
    );
  };
  const completedProfileTemplate = (layout) => {
    return (
      <div className="build-profile">
        <div className="titleText">{layout?.title?.value}</div>
        <div className="subText">{layout?.text?.value}</div>
        {layout?.ctaText?.value && (
          <div className="btnContainer">
            <ButtonLink
              ctaText={layout?.ctaText}
              ctaUrl={layout?.ctaUrl}
              externalLink={
                layout?.ctaUrl?.value?.linktype === 'internal' ? false : true
              }
              arrow
            />
          </div>
        )}
      </div>
    );
  };

  const getTemplate = () => {
    const layout = items?.filter(function (el) {
      if (percentage < 100) return el.name === 'Partial';
      else return el.name === 'Complete';
    })?.[0]?.fields;
    const template =
      percentage < 100
        ? partiallyCompleteProfileTemplate(layout)
        : completedProfileTemplate(layout);
    return template;
  };

  return (
    <div className="create-profile-success-block col-lg-6 offset-lg-3">
      {profileDataLoading ? (
        <Loader
          loaderMsg={t('fetching-your-data')}
          loaderWithMsg
          customLoaderStyle={{
            width: '4rem',
            height: '4rem',
            color: theme?.secondaryButton,
          }}
          customTextStyle={{ color: theme?.secondaryButton }}
        />
      ) : (
        <>{Object.keys(fields).length > 0 && getTemplate()}</>
      )}
    </div>
  );
};

SuccessBlock.propTypes = {
  fields: PropTypes.shape({
    text: PropTypes.shape({
      value: PropTypes.string,
    }),
  }).isRequired,
  t: PropTypes.func.isRequired,
};

SuccessBlock.defaultProps = {
  fields: {},
};

const mapStateToProps = (state) => {
  return { profileInfo: state.profileInfoReducer };
};

export default connect(mapStateToProps)(withTheme(withTranslation()(SuccessBlock)));
