import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '../globals/link/ButtonLink';
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
// import mockBannerData from './mockBannerData';
import './page-banners.scss';

/**
 * @description - Banner Insights.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template.
 */
const InsightsPageBanner = ({ fields }) => {
  const { title, subTitle, text, ctaText, ctaUrl, image } = fields;
  // const theme = color?.value ? color.value.toLowerCase() : 'dark_green';
  return (
    <section className={`banner-section insights`}>
      <div className="banner-section__overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 order-lg-1 order-2">
            <RichText tag="h5" className="sub-title" field={title} />
            <RichText tag="h1" className="title" field={subTitle} />
            <RichText tag="p" className="large reversed" field={text} />
            <ButtonLink
              cssClass="reversed-white"
              ctaText={ctaText}
              ctaUrl={ctaUrl}
              arrow
            />
          </div>
          <div className="col-lg-6 order-lg-2 order-1">
            {image && (
              <Image className="banner-image" editable={true} field={image} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

InsightsPageBanner.defaultProps = {
  fields: {
    title: { value: '' },
    subTitle: { value: '' },
    text: { value: '' },
    ctaText: { value: '' },
    ctaUrl: { value: '#' },
    image: {
      value: { src: '' },
    },
    color: { value: '' },
  },
};

InsightsPageBanner.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({ value: PropTypes.string.isRequired }),
    subTitle: PropTypes.shape({ value: PropTypes.string.isRequired }),
    text: PropTypes.shape({ value: PropTypes.string.isRequired }),
    ctaText: PropTypes.shape({ value: PropTypes.string }),
    ctaUrl: PropTypes.shape({ value: PropTypes.string }),
    image: PropTypes.shape({ value: PropTypes.string.isRequired }),
    color: PropTypes.shape({ value: PropTypes.string.isRequired }),
  }).isRequired,
};

export default InsightsPageBanner;
