/* eslint-disable require-jsdoc */
/* eslint-disable no-console */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import './TypeAheadSimple.scss';

/**
 * @description to generate dropdown
 * @param {Object} props - Props passed.
 * @returns {Node} - HTML section
 */
// eslint-disable-next-line max-lines-per-function
const TypeAheadSimple = (props) => {
  const itemWrapper = useRef(null);
  const typeAheadWrapper = useRef(null);
  //eldho
  const inputWrapper = useRef(null);
  const [locList, setLocList] = useState([]);
  const [locSelected, setLocSelected] = useState('');
  //const [pinMode, setPinmode] = useState(false);
  const { data, placeholder, onChange, icon } = props;

  useEffect(() => {
    /**
     * @description - Close Dropdown if clicked on outside of element.
     * @param {Object} event - Event triggered.
     * @returns {undefined}
     */
    function handleClickOutside(event) {
      if (
        typeAheadWrapper.current &&
        !typeAheadWrapper.current.contains(event.target)
      ) {
        setLocList([]);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [typeAheadWrapper]);

  /**
   * @description - Handle onKeyDown function.
   * @param {String} loc - Location selected.
   * @param {Object} ev - Event triggered.
   * @returns {undefined}
   */
  const onInputKeyDown = (loc, ev) => {
    if (ev.keyCode === 13) {
      setLocList([]);
      return;
    }
    const idxLoc = locList.indexOf(loc);
    if (ev.keyCode === 40 && idxLoc <= -1) {
      itemWrapper.current.children[0].focus();
    }
  };

  /**
   * @description - Handle onKeyDown function.
   * @param {String} loc - Location selected.
   * @param {Object} ev - Event triggered.
   * @returns {undefined}
   */
  const onOptionKeyDown = (loc, ev) => {
    const idxLoc = locList.indexOf(loc.name);
    if (ev.keyCode === 13) {
      setLocSelected(loc.name);
      setLocList([]);
    } else if (ev.keyCode === 38 && idxLoc > 0) {
      itemWrapper.current.children[idxLoc - 1].focus();
    } else if (ev.keyCode === 40 && idxLoc < locList.length - 1) {
      itemWrapper.current.children[idxLoc + 1].focus();
    }
    ev.preventDefault();
  };

  /**
   * @description - Handle onFocus function.
   * @param {String} loc - Location selected.
   * @returns {undefined}
   */
  const onItemFocusHandler = (loc) => {
    setLocSelected(loc.locationName);

    if (!isNaN(parseInt(inputWrapper.current.value))) onChange(loc.locationPin);
    else onChange(loc.locationName);
  };

  /**
   * @description - Handle onFocus function.
   * @param {String} loc - Location selected.
   * @returns {undefined}
   */
  const onClickHandler = (loc) => {
    setLocSelected(loc.name);
    setLocList([]);
  };

  /**
   * @description - onChange Handler.
   * @param {Object} event - on change event triggered.
   * @returns {null} - return nothing.
   */
  const onChangeHandler = (event) => {
    let locationList = [];
    const { value } = event.target;
    setLocSelected(value);
    onChange(value);

    if (data.length && value.trim()) {
      // const regex = new RegExp(value, 'ig');
      if (!isNaN(parseInt(value[0]))) {
        locationList = data.filter((loc) => {
          if (
            loc.locationName?.toLowerCase().startsWith(value.toLowerCase()) ||
            loc.locationPin?.toLowerCase().startsWith(value)
          )
            return (loc.locationName = [loc.locationPin, loc.locationName].join(
              ' '
            ));
        });
      } else {
        locationList = data.filter((loc) =>
          loc.locationName?.toLowerCase().startsWith(value.toLowerCase())
        );
      }
    }
    if (data.length && value.trim() === '') {
      locationList = [...data];
    }
    setLocList(locationList);
  };

  return (
    <div className="form-block typeaheadSimpleContainer" ref={typeAheadWrapper}>
      <input
        placeholder={placeholder}
        className="input-icon"
        style={icon !== '' ? { backgroundImage: `url('${icon}')` } : null}
        value={locSelected}
        onChange={onChangeHandler}
        onClick={onChangeHandler}
        onKeyDown={onInputKeyDown.bind(this, locSelected)}
        ref={inputWrapper}
      />
      {locList.length ? (
        <div className="dropList">
          <div className="item-wrapper" ref={itemWrapper}>
            {locList.map((loc) => (
              <div
                key={loc.locationName}
                className="item"
                role="option"
                aria-selected={loc.locationName === locSelected}
                tabIndex={0}
                onClick={onClickHandler.bind(this, loc)}
                onKeyDown={onOptionKeyDown.bind(this, loc)}
                onFocus={onItemFocusHandler.bind(this, loc)}
              >
                {loc.locationName}
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

TypeAheadSimple.propTypes = {
  icon: PropTypes.string,
  data: PropTypes.arrayOf({}).isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

TypeAheadSimple.defaultProps = {
  icon: '',
};

export default TypeAheadSimple;
