/* eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import { mediaApi } from '@sitecore-jss/sitecore-jss-react';
import './cards.scss';
import { Link } from 'react-router-dom';
import {CardStyle} from './CardTheme';

const CardRelatedContent = ({ subTitle, date, text, ctaUrl, image, cssClass }) => {
  const cardImage = mediaApi.updateImageUrl(image?.src);
  return (
    <>
    <CardStyle/>
    <Link to={ctaUrl} className="card related-content">
      <div className={`card-body ${cssClass}`}>
        {image && (
          <div className="featured-article">
            <img
              src={cardImage}
              // alt={image?.altText}
              alt=""
            />
          </div>
        )}
        <div className="sub-title">{subTitle}</div>
        <div className="date">{date}</div>
        <div className="text">{text}</div>
      </div>
    </Link>
    </>
  );
};

CardRelatedContent.defaultProps = {
  cssClass: '',
};

CardRelatedContent.propTypes = {
  subTitle: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
  }).isRequired,
  cssClass: PropTypes.string,
};

export default CardRelatedContent;
