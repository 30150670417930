/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import lget from 'lodash.get';
import { withTranslation } from 'react-i18next';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { getValidationDatabyType } from '../DynamicForm/helper/filter';
import { checkValueTypeAndGetTheCount } from '../../../business/Utils/helper';

const RadioToggle = (props) => {
  const {
    options,
    name,
    value,
    selectHandler,
    customStyle,
    showLabelText,
    customError,
    inlineLabel,
    readOnly,
    t,
    label,
    notRequired,
    register,
    dataValidations,
    text,
    id,
    watch,
    formName,
    isHideField,
    showBasedOnFieldName,
    hideBasedOnFieldName,
    validateOnLoad,
    setValue,
    getValues,
  } = props;
  const [selected, setSelected] = useState(value);
  const fieldError = lget(customError, name);
  const [showField, setShowField] = useState(true);
  const [hideField, setHideField] = useState(false);
  let param = readOnly
    ? { shouldValidate: true }
    : { shouldValidate: true, shouldDirty: true };
  param = validateOnLoad ? param : {};
  const showFieldName = formName
    ? `${formName}[${showBasedOnFieldName}]`
    : showBasedOnFieldName;
  const hideFieldName = formName
    ? `${formName}[${hideBasedOnFieldName}]`
    : hideBasedOnFieldName;
  const requiredData = getValidationDatabyType(dataValidations, 'required');

  const showFieldValue = showFieldName ? watch(showFieldName) : null;
  const hideFieldValue = hideFieldName ? watch(hideFieldName) : null;

  useEffect(() => {
    if (showFieldValue) {
      let isFieldHasVal = checkValueTypeAndGetTheCount(showFieldValue);
      setShowField(isFieldHasVal);
    }
  }, [showFieldValue]);

  useEffect(() => {
    if (hideFieldValue) {
      let isFieldHasVal = checkValueTypeAndGetTheCount(hideFieldValue);
      setHideField(isFieldHasVal);
    }
  }, [hideFieldValue]);

  useEffect(() => {
    setSelected(value);
    setValue(name, value, param);
  }, [value]);

  /**
   *
   */
  const handleSelect = (event, option) => {
    let evn = event || window.event;
    let target = evn.srcElement || evn.target;
    if (evn && target) {
      setSelected(target.value);
      if (selectHandler) {
        // Returns event and selected value
        selectHandler(evn, option);
      }
    }
  };

  /**
   *
   * @param {*} option
   */
  const unSelectRadio = (event, value) => {
    let evn = event || window.event;
    if (notRequired && selected === value) {
      setSelected('');
      setValue(name, '', param);
      if (selectHandler) {
        selectHandler(evn, '');
      }
    }
  };
  return (
    <div
      className={`form-block formFields ${
        isHideField || !showField || hideField ? 'hide' : ''
      }`}
      style={customStyle}
    >
      {!inlineLabel && showLabelText && (
        <div className="form-check-label labelText">
          {text?.value ? (
            <RichText field={text} editable={false} className="checkBoxLabel" />
          ) : (
            label
          )}
        </div>
      )}
      <div
        className={`form-radio-group checklist ${
          fieldError ? 'error withoutBg' : ''
        } ${inlineLabel ? 'radioInline' : ''}`}
        role="radiogroup"
      >
        <div className="inlineLabel">
          {inlineLabel && <div className="form-check-label">{label}:</div>}
        </div>
        <ul
          className={`form-radio-group checklist ${
            fieldError ? 'error withoutBg' : ''
          }`}
          style={props?.toggleStyle}
          role="radiogroup"
        >
          {options.map((option) => {
            const { id, text, value } = option || {};
            const val = value || text;
            return (
              <li className="checklist__item" key={name + id}>
                <div className="form-check radio-toggle">
                  <input
                    className="form-check-input"
                    type="radio"
                    name={name}
                    id={name + id}
                    value={val}
                    checked={getValues && getValues(name) === val}
                    onChange={(event) => handleSelect(event, option)}
                    onClick={(event) => unSelectRadio(event, val)}
                    onKeyUp={(e) => {
                      if (e && e.keyCode === 13) unSelectRadio(e, val);
                    }}
                    disabled={readOnly}
                    ref={
                      register
                        ? register({
                            required:
                              ((!isHideField || !showField || hideField) &&
                                requiredData?.fields?.value?.value) ||
                              false,
                          })
                        : () => {}
                    }
                  />
                  <label className="form-check-label" htmlFor={name + id}>
                    {text}
                  </label>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      {fieldError && (
        <div className="error-msg" id={`err_${id}`} role="alert">
          {fieldError.type === 'required'
            ? requiredData?.fields?.message?.value
              ? requiredData?.fields?.message?.value?.replace(
                  '{0}',
                  label?.replace('*', '')
                )
              : t('mandatory-field-message').replace('{0}', label?.replace('*', ''))
            : fieldError?.message?.replace('{0}', label?.replace('*', ''))}
        </div>
      )}
    </div>
  );
};

RadioToggle.defaultProps = {
  options: [],
  value: '',
  selectHandler: () => {},
  setValue: () => {},
  getValues: () => {},
  t: () => {},
  notRequired: false,
  customStyle: {},
  showLabelText: false,
  toggleStyle: {},
  inlineLabel: false,
};

RadioToggle.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Field identifier  */
      id: PropTypes.string.isRequired,

      /** Label text */
      text: PropTypes.string.isRequired,
      value: PropTypes.string,
    })
  ),
  customStyle: PropTypes.shape({}),
  toggleStyle: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  selectHandler: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  t: PropTypes.func,
  notRequired: PropTypes.bool,
  showLabelText: PropTypes.bool,
  inlineLabel: PropTypes.bool,
};

export default withTranslation()(RadioToggle);
