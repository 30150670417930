/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Controller, useController } from 'react-hook-form';
import NormalUpload from './NormalUpload';
import DropBox from './DropBox';
import GoogleDrive from './GoogleDrive';
import OneDriveComponent from './OneDriveComponent';
import Select, { components } from 'react-select';
import { RichText, mediaApi } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import './upload-block.scss';
import {
  dataURLtoFile,
  base64ArrayBuffer,
  getImg,
  filterArrayByType,
} from '../../../../../utils/helperUtils';
import { fileTypesAllowed } from '../../../../../utils/enums';
import Modal from '../../../Modal';
import Button from '../../../globals/buttons/Button';
import { withTranslation } from 'react-i18next';
import lget from 'lodash.get';
import { getValidationDatabyType } from '../../DynamicForm/helper/filter';
import FileList from './FileList';
import TooltipComponent from '../../../Tooltip';
import { attachementDownloadApi } from '../../../../../services/apiServices/candidateService';
import { toast } from 'react-toastify';
import DownloadIcon from './DownloadIcon';
import { withTheme } from 'styled-components';

const { ValueContainer, Placeholder } = components;
/**
 * @description - Value Container
 * @param {*} param0
 */
const NewValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder
        {...props}
        isFocused={props.selectProps.menuIsOpen || props.hasValue}
      >
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? (
          <div className="dummy-input-wrapper">{child}</div>
        ) : null
      )}
    </ValueContainer>
  );
};

/**
 * @description - File upload Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const UploadBlock = (props) => {
  const {
    label,
    customStyle,
    optionsData,
    handleUpload,
    name,
    value,
    t,
    handleDelete,
    config,
    isMultiFile,
    dataValidations,
    requiredValidation,
    setValue,
    customError,
    className,
    control,
    limit,
    showOnload,
    apiEndPoint,
    cssClass,
    toolTipText,
    filterReq,
    clearErrors,
    setError,
    validateOnLoad,
    fileTypes,
    readOnly,
    id,
    hintText,
    theme,
    fileSize,
  } = props;
  const fieldError = lget(customError, name);
  const [file, setFile] = useState([]);
  // const [sizeError, setSizeError] = useState(false);
  // const [formatError, setFormatError] = useState(false);
  const [src, setSrc] = useState('#');
  const [options, setOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [removeFileIndex, setFileIndex] = useState('');
  const [api, setApi] = useState('');
  const [confirmDownload, setConfirmDownload] = useState(false);
  const [downloadIndex, setDownloadIndex] = useState(0);

  const requiredData = requiredValidation
    ? requiredValidation[0]
    : getValidationDatabyType(dataValidations, 'required');
  // var validFileTypes = ['HTML', 'PDF', 'DOC', 'DOCX', 'RTF', 'HTM'];
  const validFileTypes = fileTypes
    ? fileTypes.toUpperCase().split(',')
    : fileTypesAllowed;
  const normal = React.useRef();
  const dbox = React.useRef();
  const gDrive = React.useRef();
  const oneD = React.useRef();
  let param = readOnly
    ? { shouldValidate: true }
    : { shouldValidate: true, shouldDirty: true };
  param = validateOnLoad ? param : {};

  const { field } = useController({
    name,
    control,
  });

  /**
   * @description - Option component
   * @param {*} icon
   * @param {*} text
   */
  const optionComponent = (icon, text) => (
    <div className="upload-container">
      <div
        style={{
          backgroundImage: `url(${mediaApi.updateImageUrl(getImg(icon))})`,
        }}
        className="upload-icon"
      />
      {text}
    </div>
  );

  useEffect(() => {
    const temp = [];
    optionsData.map((item) => {
      temp.push({
        value: item?.fields?.key?.value,
        label: optionComponent(item.fields.img, item.fields.label.value),
      });
    });
    setOptions(temp);
  }, [optionsData]);

  useEffect(() => {
    if (value && Array.isArray(value) && showOnload) {
      console.log('in upload block+++', name, value);
      let data = [];
      if (filterReq) {
        data = filterArrayByType(value, name);
      } else {
        data = value;
      }
      if (limit && data.length > limit) {
        data = data.slice(0, 5);
      }
      setFile(data);
      if (data.length > 0) {
        setValue(name, options[0], param);
      }
    }
  }, [value, showOnload]);

  useEffect(() => {
    setApi(apiEndPoint);
  }, [apiEndPoint]);

  /**
   * @description On file select
   */
  const onSuccess = (val) => {
    if (val !== null) {
      const extn = val?.name.split('.').pop();
      const size = val?.size || val?.bytes || val?.sizeBytes;
      if (checkValidFormat(extn)) {
        if (checkValidSize(size)) {
          setValue(name, options[0], param);
          clearErrors(name);
          // setSizeError(false);
          // setFileName(val.name);
          const fileArray = [...file, val];
          setFile(fileArray);
          setSrc(URL.createObjectURL(val));
          handleUpload(fileArray, name);
          setLoading(false);
        } else {
          setValue(name, null, param);
          toast.error(t('the-document-size-should-not-exceed-3mb'));
          // setSizeError(true);
          setLoading(false);
        }
      } else {
        setValue(name, null, param);
        toast.error(t('please-upload-document-with-the-following-file-type'));
        // setFormatError(true);
        setLoading(false);
      }
    }
  };

  /**
   * @description Loader
   */
  const setLoader = () => {
    setLoading(true);
  };

  /**
   * @description To check valid file types
   * @param {*} extn file extension
   * @returns {boolean} Boolean
   */
  const checkValidFormat = (extn) => validFileTypes.includes(extn?.toUpperCase());

  /**
   * @description To check valid file size
   * @param {*} extn file size
   * @returns {boolean} Boolean
   */

  const checkValidSize = (size) => size < parseInt(fileSize);

  /**
   * @description - On dropdown select
   * @param {*} value
   */
  const onSelect = (value) => {
    // setValue(name, null, param);
    // setSizeError(false);
    // setFormatError(false);
    if (value) {
      switch (value.value) {
        case '0':
          return normal.current.trigger();
          break;
        case '1':
          return dbox.current.trigger();
          break;
        case '2':
          return oneD.current.trigger();
          break;
        case '3':
          return gDrive.current.trigger();
          break;
        default:
          return null;
      }
    }
  };

  /**
   * @description handle success file upload from dropbox
   * @param {*} url path of file
   * @param {*} name name of file
   * @returns {undefined} nothing
   */
  const handleSuccess = (url, name) => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'arraybuffer';
    xhr.onload = function () {
      const type =
        file?.name?.split('.').pop() === 'pdf'
          ? 'application/pdf'
          : 'application/msword';
      var base64 = `data:${type};base64,` + base64ArrayBuffer(xhr.response);
      const temp = dataURLtoFile(base64, name);
      onSuccess(temp);
    };
    xhr.send();
  };

  /**
   * @description - dropbox
   */
  const openFile = () => {
    var applicationId = config?.ONEDRIVE?.apiKey;
    var options = {
      clientId: config?.ONEDRIVE?.apiKey,
      action: 'download',
      multiSelect: true,
      success: function (files) {
        setLoader();
        const url = files.value[0]['@microsoft.graph.downloadUrl'];
        handleSuccess(url, files?.value[0].name);
      },
      error: function (e) {
        console.log('Error occurred while picking a file: ' + e, e);
      },
      advanced: {
        redirectUri: history?.location?.pathname,
      },
    };
    OneDrive.open(options);
  };

  const downloadSuccess = (fileName) => {
    toast.success(`${t('download-success-message')} ${fileName}`, {
      delay: 2000,
    });
  };

  const errorCallBack = (fileName) => {
    toast.error(`${t('download-error-message')} ${fileName}`, {
      delay: 2000,
    });
  };

  const handleDownload = () => {
    toast.success(`${t('downloading')} ${file[downloadIndex]?.name}`, {
      autoClose: 3000,
    });
    var ua = navigator?.userAgent;
    var msie = ua?.indexOf('MSIE');
    if (file[downloadIndex]?.id) {
      attachementDownloadApi(
        file[downloadIndex]?.name,
        `${api}&fileID=${file[downloadIndex]?.id}`,
        downloadSuccess,
        errorCallBack
      );
    } else if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      var blob = new Blob([file[downloadIndex]], {
        type: 'text/plain;charset=utf-8;',
      });
      if (navigator.msSaveBlob) {
        handleDownloadConfirm();
        downloadSuccess(file[downloadIndex]?.name);
        return navigator.msSaveBlob(blob, file[downloadIndex].name);
      }
    } else {
      var link = document?.createElement('a');
      link.download = file[downloadIndex]?.name;
      link.href = src;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      link = null;
      downloadSuccess(file[downloadIndex]?.name);
    }
    handleDownloadConfirm();
  };

  const handleNoDownload = () => {
    handleDownloadConfirm();
  };

  /**
   * @description to show file remove alert dialog
   * @returns {undefined} - nothing
   */
  const handleModal = () => {
    setShowModal(!showModal);
  };

  /**
   * @description to show download alert dialog
   * @returns {undefined} - nothing
   */
  const handleDownloadConfirm = () => {
    setConfirmDownload(!confirmDownload);
  };

  /**
   * @description - Remove file
   * @returns {undefined} - nothing
   */
  const handleYes = () => {
    // console.log('removeFileIndex', removeFileIndex);
    if (file && file.length > 0 && file[removeFileIndex].id) {
      handleDelete(file[removeFileIndex]?.id, name, removeFileIndex);
    } else {
      handleDelete(null, name, removeFileIndex);
    }
    // console.log('file', file);
    setFileIndex('');
    const temp = file;
    delete temp[removeFileIndex];
    const newtemp = temp.filter((item) => item);
    setFile(newtemp);
    setSrc('#');
    handleModal();
    if (newtemp.length === 0) setValue(name, null, param);
    // setFormatError(false);
    if (
      requiredData?.fields?.value?.value &&
      file.filter(function (e) {
        return e;
      }).length === 0
    ) {
      setError(name, {
        type: 'required',
      });
    }
  };

  /**
   * @description to show job alert dialog
   * @returns {undefined} - nothing
   */
  const handleNo = () => {
    handleModal();
    setFileIndex('');
  };

  const removeFile = (index) => {
    handleModal();
    setFileIndex(index);
    field.onBlur();
  };

  const downloadFile = (index) => {
    handleDownloadConfirm();
    setDownloadIndex(index);
  };

  return (
    <>
      <div
        className={`select-wrapper form-block complex ${cssClass}`}
        style={customStyle}
        role="combobox"
        aria-label={label}
      >
        <div
          className={classNames(
            className,
            !file[0]?.name || isMultiFile?.value ? '' : 'hide-field'
          )}
        >
          <Controller
            className={className}
            name={name}
            control={control}
            rules={{
              required: requiredData?.fields?.value?.value || false,
            }}
            render={({ onChange, onBlur, value, name, ref }) => (
              <Select
                name={name}
                onChange={(selected) => {
                  // setFormatError(false);
                  // setSizeError(false);
                  onSelect(selected);
                }}
                onBlur={onBlur}
                defaultValue={value}
                options={options}
                placeholder={
                  <div className="upload-placeholder">
                    {label}
                    {toolTipText && (
                      <TooltipComponent text={toolTipText}>
                        <div className="info-icon">i</div>
                      </TooltipComponent>
                    )}
                  </div>
                }
                isMulti={false}
                isClearable={file[0]?.name && !isMultiFile ? true : false}
                autocomplete={false}
                isSearchable={false}
                isDisabled={
                  limit && file?.length === parseInt(limit) && isMultiFile
                    ? true
                    : false
                }
                styles={{
                  placeholder: (provided, state) => ({
                    ...provided,
                    position: 'absolute',
                    top:
                      (state.isFocused ||
                        state.isSelected ||
                        state.selectProps.inputValue) &&
                      file[0]?.name &&
                      !isMultiFile
                        ? 11
                        : '50%',
                    transition: 'top 0.1s, font-size 0.1s',
                    fontSize:
                      (state.isFocused ||
                        state.isSelected ||
                        state.selectProps.inputValue) &&
                      file[0]?.name &&
                      !isMultiFile &&
                      11,
                  }),
                  indicatorSeparator: (state) => ({
                    display: 'none',
                  }),
                  dropdownIndicator: (defaultStyles) => ({
                    ...defaultStyles,
                    display: file[0]?.name && !isMultiFile && 'none',
                  }),
                  control: (base, state) => ({
                    ...base,
                    ...(cssClass
                      ? {
                          border: '1px solid  #9d323d',
                          '&:hover': { borderColor: '#9d323d' },
                          boxShadow: 'none',
                        }
                      : { boxShadow: 'none' }),
                  }),
                }}
                components={{ ValueContainer: NewValueContainer }}
              />
            )}
          />
        </div>
        {file[0]?.name && !isMultiFile?.value && (
          <div className="file-select-container">
            <div className="label-container">
              {' '}
              <div className="upload-placeholder">
                {label}
                {toolTipText && (
                  <TooltipComponent text={toolTipText}>
                    <div className="info-icon">i</div>
                  </TooltipComponent>
                )}
              </div>
            </div>
            <div className="file-container">
              <div className="input-wrapper" onClick={() => downloadFile(0)}>
                {file[0]?.name}
              </div>
              <div className="download-icon-blue" onClick={() => downloadFile(0)}>
                {' '}
                <DownloadIcon iconColor={theme?.primaryButton} />
              </div>
              <div className="close-icon" onClick={() => removeFile(0)} />
            </div>
          </div>
        )}
        {isLoading && (
          <span
            className="spinner-wrapper-upload spinner-border spinner-border-sm"
            role="status"
          ></span>
        )}
        {fieldError && (
          <div className="error-msg" aria-label="this field is required">
            {fieldError.type === 'required'
              ? requiredData?.fields?.message?.value
                ? requiredData.fields.message.value.replace(
                    '{0}',
                    label.replace('*', '')
                  )
                : t('mandatory-field-message')
              : fieldError.message.replace('{0}', label.replace('*', ''))}
          </div>
        )}
        {hintText && <RichText field={{ value: hintText }} className="hintText" />}
        <div style={{ display: 'none' }} className="upload-config-cls">
          {' '}
          <NormalUpload
            onSuccess={onSuccess}
            ref={normal}
            config={config}
            id={id}
            name={name}
          />
          <DropBox
            onSuccess={onSuccess}
            ref={dbox}
            config={config}
            setLoader={setLoader}
          />
          <GoogleDrive
            onSuccess={onSuccess}
            ref={gDrive}
            config={config}
            setLoader={setLoader}
          />
          <OneDriveComponent openFile={openFile} ref={oneD} />
        </div>
        <Modal showModal={showModal} handleModal={handleModal} size="md">
          <div className="inner-modal-container" key="body">
            {t('would-you-like-to-delete-the-attached-document')}
            <div className="confirm-button-container" role="button" tabIndex={0}>
              <Button
                cssClass="yes-button"
                handleButtonClick={handleYes}
                text={t('yes')}
              />
              <Button handleButtonClick={handleNo} text={t('no')} />
            </div>{' '}
          </div>
        </Modal>
        <Modal
          showModal={confirmDownload}
          handleModal={handleDownloadConfirm}
          size="md"
        >
          <div className="inner-modal-container" key="body">
            {t('download-confirmation')}
            <div className="confirm-button-container" role="button" tabIndex={0}>
              <Button
                cssClass="yes-button"
                handleButtonClick={handleDownload}
                text={t('yes')}
              />
              <Button handleButtonClick={handleNoDownload} text={t('no')} />
            </div>{' '}
          </div>
        </Modal>
        {isMultiFile?.value && file?.length > 0 ? (
          <FileList
            fileItem={file}
            removeFile={removeFile}
            t={t}
            downloadFile={downloadFile}
          />
        ) : null}
      </div>
    </>
  );
};

UploadBlock.propTypes = {
  label: PropTypes.string.isRequired,
  customStyle: PropTypes.shape({}).isRequired,
  t: PropTypes.func,
  readOnly: PropTypes.bool,
  hintText: PropTypes.string,
};

UploadBlock.defaultProps = {
  label: 'Upload Resume',
  customStyle: {},
  readOnly: false,
  hintText: '',
};

const mapStateToProps = (state) => {
  return {
    config: state.uploadConfigReducer.data,
  };
};

export default withTheme(withTranslation()(connect(mapStateToProps)(UploadBlock)));
