import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import ArrowIcon from './ArrowIcon';

import NavLink from '../NavLinks';
import { checkHref, checkLinkType } from '../../../../utils/helperUtils';
/**
 * @description - Connector Button Link
 * @param {object} param0 - Input props.
 * @returns {Node} - HTML button link.
 */
const ConnectorButtonLink = ({ cssClass, ctaText, ctaUrl }) =>
  (checkHref(ctaUrl) || checkLinkType(ctaUrl)) && ctaText?.value ? (
    <div className={`connector-button ${cssClass}`}>
      <div className="fat-line" />
      <div className="line">
        <div className="inner-line" />
      </div>
      <div className="outer-cta">
        <NavLink url={ctaUrl} cssClass={`primary-button ${cssClass}`}>
          <Text field={ctaText} />
          <ArrowIcon />
        </NavLink>
      </div>
    </div>
  ) : null;

ConnectorButtonLink.defaultProps = {
  cssClass: '',
  ctaText: {},
  ctaUrl: {},
};

ConnectorButtonLink.propTypes = {
  cssClass: PropTypes.string.isRequired,
  ctaText: PropTypes.shape({}).isRequired,
  ctaUrl: PropTypes.shape({ href: PropTypes.string }).isRequired,
};

export default ConnectorButtonLink;
