/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './upload-block.scss';
import TextButton from '../../../globals/buttons/TextButton';
import DownloadIcon from './DownloadIcon';
import { withTheme } from 'styled-components';

/**
 * @description - File upload Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const FileList = (props) => {
  const { fileItem, removeFile, t, downloadFile, theme } = props;
  const [filearr, setFileArr] = useState([]);
  const [showMore, setShowMore] = useState(true);

  useEffect(() => {
    setFileArr(
      showMore
        ? fileItem && Array.isArray(fileItem) && fileItem.slice(0, parseInt(5))
        : fileItem
    );
  }, [showMore, fileItem]);

  /**
   * @description to handle show more and show less button click
   */

  const handleTextClick = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="file-container">
      {filearr?.map((item, index) => (
        <div className="file-list" key={index}>
          <div
            className="file-name"
            onClick={() => {
              downloadFile(index);
            }}
          >
            {item?.name}
          </div>
          <div className="download-icon-blue" onClick={() => downloadFile(index)}>
            <DownloadIcon iconColor={theme?.primaryButton} />
          </div>
          <div className="close-icon" onClick={() => removeFile(index)} />
        </div>
      ))}
      {showMore && fileItem?.length > 5 && (
        <TextButton text={t('show-more')} handleTextClick={handleTextClick} />
      )}
      {!showMore && fileItem?.length > 5 && (
        <TextButton text={t('show-less')} handleTextClick={handleTextClick} />
      )}
    </div>
  );
};

FileList.propTypes = {
  item: PropTypes.shape({}).isRequired,
};

FileList.defaultProps = {};

export default withTheme(FileList);
