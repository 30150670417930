/* eslint-disable */
import UploadIcon from '../../../assets/images/icon_upload.svg';
import personalinfoiconblue from '../../../assets/images/profile/personalinfoiconblue.svg';
import personalinfoicongray from '../../../assets/images/profile/personalinfoiconred.svg';
import experienceIcongray from '../../../assets/images/profile/experienceIconred.svg';
import experienceiconblue from '../../../assets/images/profile/experienceiconblue.svg';
import educationicongray from '../../../assets/images/profile/educationiconred.svg';
import educationiconblue from '../../../assets/images/profile/educationiconblue.svg';
import coverlettergray from '../../../assets/images/profile/coverlettergray.svg';
import coverletterblue from '../../../assets/images/profile/coverletterblue.svg';
import NormalIcon from '../../../assets/images/upload_icon_orange.svg';
import onedrive from '../../../assets/images/onedrive.svg';
import dropbox from '../../../assets/images/dropbox.svg';
import gDrive from '../../../assets/images/logo_of_Google_Drive.svg';

const BuildProfileMock = {
  componentName: 'BuildProfile',
  fields: {
    title: {
      value: 'Build Your Profile!',
    },
    text: {
      value: 'Get a head start by uploading your resume/CV!',
    },
    reviewLink: {
      value: '/en/candidate/profile',
    },
    saveLink: {
      value: '/',
    },
  },
  placeholders: {
    'jss-content': [
      {
        uid: '5e3cf2d1-6391-4230-8108-4daa81c9ef90',
        componentName: 'UploadCandidateResume',
        dataSource:
          '/sitecore/content/Manpowergroup/Experis/Experis-Norway/Home/JobApply/Data/Upload Resume',
        params: {},
        fields: {
          title: {
            value: 'Build Your Profile!',
          },
          subTitle: {
            value: 'Get a head start by uploading your resume/CV!',
          },

          items: [
            {
              id: '52868f9e-317f-4693-bb08-6833d6d6f1dd',
              name: 'Upload Resume',
              displayName: 'Upload Resume',
              fields: {
                values: [
                  {
                    id: '80c6ca73-e86d-4d75-946b-77572f73befa',
                    fields: {
                      key: {
                        value: '0',
                      },
                      label: {
                        value: 'From you device',
                      },
                      img: {
                        value: {
                          src: NormalIcon,
                        },
                      },
                    },
                  },
                  {
                    id: '80c6ca73-e86d-4d75-946b-77572f73befa',
                    fields: {
                      key: {
                        value: '0',
                      },
                      label: {
                        value: 'From dropBox',
                      },
                      img: {
                        value: {
                          src: dropbox,
                        },
                      },
                    },
                  },
                  {
                    id: '80c6ca73-e86d-4d75-946b-77572f73befa',
                    fields: {
                      key: {
                        value: '0',
                      },
                      label: {
                        value: 'From oneDrive',
                      },
                      img: {
                        value: {
                          src: onedrive,
                        },
                      },
                    },
                  },
                  {
                    id: '80c6ca73-e86d-4d75-946b-77572f73befa',
                    fields: {
                      key: {
                        value: '0',
                      },
                      label: {
                        value: 'From Google Drive',
                      },
                      img: {
                        value: {
                          src: gDrive,
                        },
                      },
                    },
                  },
                ],
                readonly: {
                  value: false,
                },
                label: {
                  value: 'Upload Resume',
                },
                placeholderText: {
                  value: 'Upload Resume',
                },
                name: {
                  value: 'CV',
                },
                showOnload: {
                  value: true,
                },
                apiEndPoint: {
                  value: 'api/services/Candidate/DownloadAttachment?{cid}',
                },
                inputType: {
                  value: 'FileUpload',
                },
                isAttachmentFilterRequired: {
                  value: true,
                },
                required: {
                  value: true,
                },
                value: {
                  value: '',
                },
                width: {
                  value: '100',
                },
                pattern: {
                  value: '',
                },
                Validations: [],
              },
            },
          ],
        },
      },
      {
        uid: '5e3cf2d1-6391-4230-8108-4daa81c9ef9011',
        componentName: 'BuildProfileAccordion',
        dataSource:
          '/sitecore/content/Manpowergroup/Experis/Experis-Norway/Home/JobApply/Data/Upload Resume',
        params: {},
        fields: {
          mandatoryFields: {
            value:
              'EditPersonalInfo,EditExperience,EditExpertiseAndSkills,EditEducation,EditCertifications',
          },
          text: {
            value:
              'Update your profile details or fill them in manually to continue.',
          },
          reviewLink: {
            value: {
              href: '/en/candidate/profile',
            },
          },
          percentagePerSection: {
            value:
              '{"EditPersonalInfo":10,"EditExperience":20,"EditEducation":20,"EditExpertiseAndSkills":20,"EditCertifications":10,"UploadCandidateResume":10,"EditCoverLetters":0,"Default":10 }',
          },
          buttonLayout: {
            value: 'inline',
          },
          ctaUrl1: {
            value: {
              href: '/en/search',
              text: '',
              anchor: '',
              linktype: 'internal',
              class: '',
              title: '',
              querystring: '',
              id: '{4CD0B209C-C28D-4E1A-8A79-3AA46FD19CF9}',
            },
          },
          ctaUrl2: {
            value: {
              href: '/en/candidate/dashboard-profile',
              text: '',
              anchor: '',
              linktype: 'internal',
              class: '',
              title: '',
              querystring: '',
              id: '{4CD0B209C-C28D-4E1A-8A79-3AA46FD19CF9}',
            },
          },
          allowMultipleOpen: {
            value: false,
          },
        },
        placeholders: {
          'jss-content': [
            {
              uid: 'cc2ed2a6-afe3-45dd-a90b-a3ed5f921e5ggg',
              componentName: 'EditPersonalInfo',
              fields: {
                title: {
                  value: 'Personal Info',
                },
                text: {
                  value: '(missing information)',
                },
                subTitle: {
                  value:
                    'It’s time to get personal. Fill in your profile details lorem ipsum.',
                },
                formText: {
                  value: 'All fields are required unless otherwise stated.',
                },
                ctaText: { value: 'Account Settings Dashboard' },
                ctaUrl: {
                  value: {
                    href: '/en/candidate/account-settings',
                    text: '',
                    anchor: '',
                    linktype: 'internal',
                    class: '',
                    title: '',
                    querystring: '',
                    id: '{4CD0B09C-C28D-4E1A-8A79-3AA46FD19CF9}',
                  },
                },
                subText: {
                  value:
                    'Account details, such as first name, last name, email, phone and password, can be changed in {0} page in your dashboard',
                },
                items: [
                  // {
                  //   id: 'f98281e3-2606-4c4c-897a-01e0a8b33796',
                  //   name: 'DOB',
                  //   displayName: 'DOB',
                  //   fields: {
                  //     readonly: {
                  //       value: false,
                  //     },
                  //     label: {
                  //       value: 'DOB',
                  //     },
                  //     placeholderText: {
                  //       value: 'DOB',
                  //     },
                  //     name: {
                  //       value: 'dateOfBirth',
                  //     },
                  //     inputType: {
                  //       value: 'DatePicker',
                  //     },
                  //     required: {
                  //       value: true,
                  //     },
                  //     value: {
                  //       value: '',
                  //     },
                  //     width: {
                  //       value: '100',
                  //     },
                  //     pattern: {
                  //       value: '',
                  //     },
                  //     minDate: {
                  //       value: '2020-11-01T05:00:00Z',
                  //     },
                  //     maxDate: {
                  //       value: '2020-12-30T05:00:00Z',
                  //     },
                  //     Validations: [
                  //       {
                  //         id: '7730be13-d71e-4584-b914-c68ad16b7554',
                  //         fields: {
                  //           message: {
                  //             value: '{0} is required',
                  //           },
                  //           inputType: {
                  //             value: 'requiredValidator',
                  //           },
                  //           value: {
                  //             value: 'true',
                  //           },
                  //         },
                  //       },
                  //     ],
                  //   },
                  // },
                  {
                    id: 'f98281e3-2606-4c4c-897a-01e0a8b33796',
                    name: 'Address1',
                    displayName: 'Address1',
                    fields: {
                      readonly: {
                        value: false,
                      },
                      label: {
                        value: 'Adress1',
                      },
                      placeholderText: {
                        value: 'Adress 1',
                      },
                      name: {
                        value: 'address.address1',
                      },
                      inputType: {
                        value: 'Text',
                      },
                      required: {
                        value: true,
                      },
                      value: {
                        value: '',
                      },
                      width: {
                        value: '100',
                      },
                      pattern: {
                        value: '',
                      },
                      Validations: [
                        {
                          id: '7730be13-d71e-4584-b914-c68ad16b7554',
                          fields: {
                            message: {
                              value: '{0} is required',
                            },
                            inputType: {
                              value: 'requiredValidator',
                            },
                            value: {
                              value: 'true',
                            },
                          },
                        },
                      ],
                    },
                  },
                  {
                    id: '54d17222-9c4e-4313-b61b-1cce53d2236a',
                    name: 'Address2',
                    displayName: 'Address2',
                    fields: {
                      readonly: {
                        value: false,
                      },
                      label: {
                        value: 'Address2',
                      },
                      placeholderText: {
                        value: 'Address2',
                      },
                      name: {
                        value: 'address.address2',
                      },
                      inputType: {
                        value: 'Text',
                      },
                      required: {
                        value: true,
                      },
                      value: {
                        value: '',
                      },
                      width: {
                        value: '100',
                      },
                      pattern: {
                        value: '',
                      },
                      Validations: [],
                    },
                  },
                  {
                    id: 'a066fec2-57de-46a2-a1cf-f1e3669e8312',
                    name: 'City',
                    displayName: 'City',
                    fields: {
                      readonly: {
                        value: false,
                      },
                      label: {
                        value: 'City',
                      },
                      placeholderText: {
                        value: 'City',
                      },
                      name: {
                        value: 'address.city',
                      },
                      inputType: {
                        value: 'Text',
                      },
                      required: {
                        value: true,
                      },
                      value: {
                        value: '',
                      },
                      width: {
                        value: '100',
                      },
                      pattern: {
                        value: '',
                      },
                      Validations: [
                        {
                          id: '7730be13-d71e-4584-b914-c68ad16b7554',
                          fields: {
                            message: {
                              value: '{0} is required',
                            },
                            inputType: {
                              value: 'requiredValidator',
                            },
                            value: {
                              value: 'true',
                            },
                          },
                        },
                      ],
                    },
                  },
                  {
                    id: '709ba8d9-36fe-4adf-a0dd-679ae3af7cef',
                    name: 'Zipcode',
                    displayName: 'Zipcode',
                    fields: {
                      readonly: {
                        value: false,
                      },
                      label: {
                        value: 'Zipcode',
                      },
                      placeholderText: {
                        value: 'Enter your Zipcode',
                      },
                      name: {
                        value: 'address.zip',
                      },
                      inputType: {
                        value: 'Text',
                      },
                      required: {
                        value: true,
                      },
                      value: {
                        value: '',
                      },
                      width: {
                        value: '100',
                      },
                      pattern: {
                        value: '',
                      },
                      Validations: [
                        {
                          id: '7730be13-d71e-4584-b914-c68ad16b7554',
                          fields: {
                            message: {
                              value: '{0} is required',
                            },
                            inputType: {
                              value: 'requiredValidator',
                            },
                            value: {
                              value: 'true',
                            },
                          },
                        },
                      ],
                    },
                  },
                  {
                    id: '9cc517e0-5066-4295-889e-705b6bf9d2c1',
                    name: 'Country',
                    displayName: 'Country',
                    fields: {
                      readonly: {
                        value: true,
                      },
                      label: {
                        value: 'Country',
                      },
                      placeholderText: {
                        value: '',
                      },
                      name: {
                        value: 'sourceCountry',
                      },
                      inputType: {
                        value: 'Text',
                      },
                      required: {
                        value: true,
                      },
                      value: {
                        value: 'Germany',
                      },
                      width: {
                        value: '100',
                      },
                      pattern: {
                        value: '',
                      },
                      Validations: [
                        {
                          id: '7730be13-d71e-4584-b914-c68ad16b7554',
                          fields: {
                            message: {
                              value: '{0} is required',
                            },
                            inputType: {
                              value: 'requiredValidator',
                            },
                            value: {
                              value: 'true',
                            },
                          },
                        },
                      ],
                    },
                  },
                ],

                icon: {
                  value: {
                    src: personalinfoiconblue,
                    alt: 'info-active',
                    width: '200',
                    height: '200',
                  },
                },
                missingIcon: {
                  value: {
                    src: personalinfoicongray,
                    alt: 'info-active',
                    width: '200',
                    height: '200',
                  },
                },
              },
            },
            {
              uid: 'cc2ed2a6-afe3-45dd-a90b-a3ed5f921e5dyffff',
              componentName: 'EditExperience',
              fields: {
                title: {
                  value: 'Experience',
                },
                text: {
                  value: '(missing information)',
                },
                icon: {
                  value: {
                    src: experienceiconblue,
                    alt: 'info-active',
                    width: '200',
                    height: '200',
                  },
                },
                missingIcon: {
                  value: {
                    src: experienceIcongray,
                    alt: 'info-active',
                    width: '200',
                    height: '200',
                  },
                },
                subTitle: {
                  value: 'Adpscing malort cane lorem ipsum dolor',
                },
                formText: {
                  value: 'All fields are required unless otherwise stated.',
                },
                items: [
                  {
                    id: '14b7bd39-7c6e-44f0-9f4d-65d8e9e1762a',
                    name: 'Title',
                    displayName: 'Title',
                    fields: {
                      label: {
                        value: 'Title',
                      },
                      placeholderText: {
                        value: 'Title',
                      },
                      name: {
                        value: 'title',
                      },
                      inputType: {
                        value: 'Text',
                      },
                      required: {
                        value: true,
                      },
                      value: {
                        value: '',
                      },
                      pattern: {
                        value: '',
                      },
                      width: {
                        value: '100',
                      },
                      readonly: {
                        value: false,
                      },
                      Validations: [
                        {
                          id: '7730be13-d71e-4584-b914-c68ad16b7554',
                          fields: {
                            message: {
                              value: '{0} is required',
                            },
                            inputType: {
                              value: 'requiredValidator',
                            },
                            value: {
                              value: 'true',
                            },
                          },
                        },
                      ],
                    },
                  },
                  {
                    id: 'f62236eb-d681-4ff5-9945-04e9d16076e3',
                    name: 'Company',
                    displayName: 'Company',
                    fields: {
                      label: {
                        value: 'Company',
                      },
                      placeholderText: {
                        value: 'Company',
                      },
                      name: {
                        value: 'companyName',
                      },
                      inputType: {
                        value: 'Text',
                      },
                      required: {
                        value: true,
                      },
                      value: {
                        value: '',
                      },
                      pattern: {
                        value: '',
                      },
                      width: {
                        value: '100',
                      },
                      readonly: {
                        value: false,
                      },
                      Validations: [
                        {
                          id: '7730be13-d71e-4584-b914-c68ad16b7554',
                          fields: {
                            message: {
                              value: '{0} is required',
                            },
                            inputType: {
                              value: 'requiredValidator',
                            },
                            value: {
                              value: 'true',
                            },
                          },
                        },
                      ],
                    },
                  },
                  {
                    id: 'db3776a8-a725-4e6b-bba5-dd2721822f2e',
                    name: 'Start Date',
                    displayName: 'Start Date',
                    fields: {
                      connectorType: {
                        value: '<=',
                      },
                      connectorField: {
                        value: 'endDate',
                      },
                      connectorMessage: {
                        value: 'Start Date has to be lesser',
                      },
                      connectorPattern: {
                        value: '',
                      },
                      values: [
                        {
                          id: '5cd0a5fc-1ac8-44b0-a2d2-c4d16339eaa9as',
                          fields: {
                            label: {
                              value: 'Jan',
                            },
                            key: {
                              value: '1',
                            },
                          },
                        },
                        {
                          id: '8fa43687-dd8c-4e0b-a29f-2fd618898bb0',
                          fields: {
                            label: {
                              value: 'Feb',
                            },
                            key: {
                              value: '2',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-4337-86e2-f1684ca0bab2',
                          fields: {
                            label: {
                              value: 'Mar',
                            },
                            key: {
                              value: '3',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-4337-86e2-f1684caas0bab2',
                          fields: {
                            label: {
                              value: 'April',
                            },
                            key: {
                              value: '4',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-4337-86e2-f1684ca04bab2',
                          fields: {
                            label: {
                              value: 'May',
                            },
                            key: {
                              value: '5',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-4337-86e2w-f1684ca0bab2',
                          fields: {
                            label: {
                              value: 'June',
                            },
                            key: {
                              value: '6',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-4337-8684ca0bab2',
                          fields: {
                            label: {
                              value: 'July',
                            },
                            key: {
                              value: '7',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-4337-86fgca0bab2',
                          fields: {
                            label: {
                              value: 'Aug',
                            },
                            key: {
                              value: '8',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-4337-86e2-sdf',
                          fields: {
                            label: {
                              value: 'Sep',
                            },
                            key: {
                              value: '9',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-4337-86e2-sdaqq',
                          fields: {
                            label: {
                              value: 'Oct',
                            },
                            key: {
                              value: '10',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-4337-86e2-asffa',
                          fields: {
                            label: {
                              value: 'Nov',
                            },
                            key: {
                              value: '11',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-4337-86e2-3erwwe',
                          fields: {
                            label: {
                              value: 'Dec',
                            },
                            key: {
                              value: '12',
                            },
                          },
                        },
                      ],
                      incre: {
                        value: '4',
                      },
                      start: {
                        value: '1950',
                      },
                      sortOrder: {
                        value: 'Descending',
                      },
                      label: {
                        value: 'Start Date',
                      },
                      placeholderText: {
                        value: 'Start Month/Start Year',
                      },
                      name: {
                        value: 'startDate',
                      },
                      inputType: {
                        value: 'MonthYearDropdown',
                      },
                      required: {
                        value: true,
                      },
                      value: {
                        value: '',
                      },
                      pattern: {
                        value: '',
                      },
                      width: {
                        value: '100',
                      },
                      readonly: {
                        value: false,
                      },
                      Validations: [
                        {
                          id: '7730be13-d71e-4584-b914-c68ad16b7554',
                          fields: {
                            message: {
                              value: '{0} is required',
                            },
                            inputType: {
                              value: 'requiredValidator',
                            },
                            value: {
                              value: 'true',
                            },
                          },
                        },
                      ],
                    },
                  },
                  {
                    id: '44b3fafc-fe6e-4f40-80b8-57f22304859d',
                    name: 'Still Working',
                    displayName: 'Still Working',
                    fields: {
                      text: {
                        value: '',
                      },
                      Validations: [],
                      connectorType: {
                        value: '',
                      },
                      connectorMessage: {
                        value: '',
                      },
                      connectorField: {
                        value: '',
                      },
                      connectorPattern: {
                        value: '',
                      },
                      isHideField: {
                        value: false,
                      },
                      inputType: {
                        value: 'CheckBox',
                      },
                      width: {
                        value: '',
                      },
                      label: {
                        value: 'Is still working?',
                      },
                      readonly: {
                        value: false,
                      },
                      placeholderText: {
                        value: '',
                      },
                      value: {
                        value: '',
                      },
                      showBasedOnFieldName: {
                        value: '',
                      },
                      hideBasedOnFieldName: {
                        value: 'endDate',
                      },
                      hintText: {
                        value: '',
                      },
                      subType: {
                        value: '',
                      },
                      name: {
                        value: 'isStillWorking',
                      },
                      values: [
                        // {
                        //   id: '5cd0a5fc-1ac8-44b0-a2d2-c4d16339eaa9',
                        //   fields: {
                        //     label: {
                        //       value: 'true',
                        //     },
                        //     key: {
                        //       value: 'true',
                        //     },
                        //   },
                        // },
                        // {
                        //   id: '8fa43687-dd8c-4e0b-a29f-2fd618898bb0',
                        //   fields: {
                        //     label: {
                        //       value: 'false',
                        //     },
                        //     key: {
                        //       value: 'false',
                        //     },
                        //   },
                        // },
                      ],
                    },
                  },
                  {
                    id: 'db3776a8-a725-4e6b-bba5-dd2721822f2ews',
                    name: 'End Date',
                    displayName: 'End Date',
                    fields: {
                      connectorType: {
                        value: '>=',
                      },
                      connectorField: {
                        value: 'startDate',
                      },
                      connectorMessage: {
                        value: 'End Date has to be greater',
                      },
                      connectorPattern: {
                        value: '',
                      },
                      values: [
                        {
                          id: '5cd0a5fc-1ac8-44b0-a2d5432-c4d16339eaa9as',
                          fields: {
                            label: {
                              value: 'Jan',
                            },
                            key: {
                              value: '1',
                            },
                          },
                        },
                        {
                          id: '8fa43687-dd8c-4e012db-a29f-2fd618898bb0',
                          fields: {
                            label: {
                              value: 'Feb',
                            },
                            key: {
                              value: '2',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-4337-86e2-f1684ca0bggtab2',
                          fields: {
                            label: {
                              value: 'Mar',
                            },
                            key: {
                              value: '3',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-4337-86e2-f1684caas0beceab2',
                          fields: {
                            label: {
                              value: 'April',
                            },
                            key: {
                              value: '4',
                            },
                          },
                        },
                        {
                          id: '4c0871aa45-00dc-4337-86e2-f1684ca04bab2',
                          fields: {
                            label: {
                              value: 'May',
                            },
                            key: {
                              value: '5',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-4337-86eh2w-f1684ca0bab2',
                          fields: {
                            label: {
                              value: 'June',
                            },
                            key: {
                              value: '6',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-werwer34234-8684ca0bab2',
                          fields: {
                            label: {
                              value: 'July',
                            },
                            key: {
                              value: '7',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-4337-yuyui',
                          fields: {
                            label: {
                              value: 'Aug',
                            },
                            key: {
                              value: '8',
                            },
                          },
                        },
                        {
                          id: '4c087145-lll-4337-86e2-sdf',
                          fields: {
                            label: {
                              value: 'Sep',
                            },
                            key: {
                              value: '9',
                            },
                          },
                        },
                        {
                          id: '4c087145-asdas-4337-86e2-sdaqq',
                          fields: {
                            label: {
                              value: 'Oct',
                            },
                            key: {
                              value: '10',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-4337-86were2-asffa',
                          fields: {
                            label: {
                              value: 'Nov',
                            },
                            key: {
                              value: '11',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-4337-86eaa2-3erwwe',
                          fields: {
                            label: {
                              value: 'Dec',
                            },
                            key: {
                              value: '12',
                            },
                          },
                        },
                      ],
                      incre: {
                        value: '4',
                      },
                      start: {
                        value: '1950',
                      },
                      label: {
                        value: 'End Date',
                      },
                      placeholderText: {
                        value: 'End Month/End Year',
                      },
                      sortOrder: {
                        value: 'Descending',
                      },
                      name: {
                        value: 'endDate',
                      },
                      inputType: {
                        value: 'MonthYearDropdown',
                      },
                      required: {
                        value: true,
                      },
                      value: {
                        value: '',
                      },
                      pattern: {
                        value: '',
                      },
                      width: {
                        value: '100',
                      },
                      readonly: {
                        value: false,
                      },
                      showBasedOnFieldName: {
                        value: '',
                      },
                      hideBasedOnFieldName: {
                        value: 'isStillWorking',
                      },
                      Validations: [
                        // {
                        //   id: '7730be13-d71e-4584-b914-c68ad16b7554',
                        //   fields: {
                        //     message: {
                        //       value: '{0} is required',
                        //     },
                        //     inputType: {
                        //       value: 'requiredValidator',
                        //     },
                        //     value: {
                        //       value: 'true',
                        //     },
                        //   },
                        // },
                      ],
                    },
                  },
                  {
                    id: '67b63e74-3e33-4391-85d9-ec5d49f2a623',
                    name: 'Description',
                    displayName: 'Description',
                    fields: {
                      label: {
                        value: 'Description',
                      },
                      placeholderText: {
                        value: 'Description',
                      },
                      name: {
                        value: 'description',
                      },
                      inputType: {
                        value: 'MultiLineText',
                      },
                      required: {
                        value: true,
                      },
                      value: {
                        value: '',
                      },
                      pattern: {
                        value: '',
                      },
                      width: {
                        value: '',
                      },
                      readonly: {
                        value: false,
                      },
                      Validations: [
                        {
                          id: '7730be13-d71e-4584-b914-c68ad16b7554',
                          fields: {
                            message: {
                              value: '{0} is required',
                            },
                            inputType: {
                              value: 'requiredValidator',
                            },
                            value: {
                              value: 'true',
                            },
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              uid: 'cc2ed2a6-afe3-45dd-a90b-a3ed5f921e5dseee',
              componentName: 'EditExpertiseAndSkills',
              fields: {
                title: {
                  value: 'Expertise & Skills',
                },
                text: {
                  value: '(missing information)',
                },
                icon: {
                  value: {
                    src: experienceiconblue,
                    alt: 'info-active',
                    width: '200',
                    height: '200',
                  },
                },
                missingIcon: {
                  value: {
                    src: experienceIcongray,
                    alt: 'info-active',
                    width: '200',
                    height: '200',
                  },
                },
                subTitle: {
                  value: 'Help employers see what you bring to the table',
                },
                subText: {
                  value: 'Suggested skills based on your experience',
                },
                items: [
                  {
                    id: 'cc9fd7b2-69b7-4f6e-8ae1-41e526f8bff0',
                    name: 'Job Skill',
                    displayName: 'Job Skill',
                    fields: {
                      readonly: {
                        value: false,
                      },
                      label: {
                        value: 'Job Skill',
                      },
                      placeholderText: {
                        value: 'Job Skill',
                      },
                      name: {
                        value: 'skills',
                      },
                      inputType: {
                        value: 'Picker',
                      },
                      subType: {
                        value: 'Text',
                      },
                      required: {
                        value: true,
                      },
                      isMultiple: {
                        value: false,
                      },
                      isApi: {
                        value: true,
                      },
                      apiEndPoint: {
                        value: 'api/services/Candidate/GetMasterDataSkillList',
                      },
                      values: [],
                      width: {
                        value: '100',
                      },
                      pattern: {
                        value: '',
                      },
                      Validations: [],
                      CustomRequiredValidator: [
                        {
                          id: '7730be13-d71e-4584-b914-c68ad16b7554',
                          fields: {
                            message: {
                              value: 'Please enter atleast one skill to proceed',
                            },
                            value: {
                              value: 'true',
                            },
                          },
                        },
                      ],
                    },
                  },
                  // {
                  //   id: 'cc9fd7b2-69b7-4f6e-8ae1-41e526fdf8bff0',
                  //   name: 'Job Textarea',
                  //   displayName: 'Job Textarea',
                  //   fields: {
                  //     readonly: {
                  //       value: false,
                  //     },
                  //     label: {
                  //       value: 'Job Skilasl',
                  //     },
                  //     placeholderText: {
                  //       value: 'Job Skasasill',
                  //     },
                  //     name: {
                  //       value: 'sdsssss',
                  //     },
                  //     inputType: {
                  //       value: 'MultiLineText',
                  //     },
                  //     required: {
                  //       value: true,
                  //     },
                  //     isMultiple: {
                  //       value: false,
                  //     },
                  //     isApi: {
                  //       value: true,
                  //     },
                  //     apiEndPoint: {
                  //       value: 'api/services/Candidate/GetMasterDataSkillList',
                  //     },
                  //     values: [],
                  //     width: {
                  //       value: '100',
                  //     },
                  //     pattern: {
                  //       value: '',
                  //     },
                  //     Validations: [
                  //       {
                  //         id: '7730be13-d71e-4584-b914-c68ad16b7554',
                  //         fields: {
                  //           message: {
                  //             value: '{0} is required',
                  //           },
                  //           inputType: {
                  //             value: 'requiredValidator',
                  //           },
                  //           value: {
                  //             value: 'true',
                  //           },
                  //         },
                  //       },
                  //     ],
                  //     CustomRequiredValidator: [
                  //       {
                  //         id: '7730be13-d71e-4584-b914-c68ad16b7554',
                  //         fields: {
                  //           message: {
                  //             value: 'Please enter atleast one skill to proceed',
                  //           },
                  //           value: {
                  //             value: 'true',
                  //           },
                  //         },
                  //       },
                  //     ],
                  //   },
                  // },
                ],
              },
            },
            {
              uid: 'cc2ed2a6-afe3-45dd-a90b-a3ed5f921e5dsddd',
              componentName: 'EditEducation',
              fields: {
                title: {
                  value: 'Education',
                },
                text: {
                  value: '(missing information)',
                },
                icon: {
                  value: {
                    src: educationiconblue,
                    alt: 'info-active',
                    width: '200',
                    height: '200',
                  },
                },
                missingIcon: {
                  value: {
                    src: educationicongray,
                    alt: 'info-active',
                    width: '200',
                    height: '200',
                  },
                },
                subTitle: {
                  value: 'Adpscing malort cane lorem ipsum dolor.',
                },
                formText: {
                  value: 'All fields are required unless otherwise stated.',
                },
                apiList: {
                  value: 'api/services/Candidate/GetMasterDataDegreeList',
                },
                items: [
                  {
                    id: 'e855c3a1-57ec-4332-956a-c6b5af0da88d',
                    name: 'University',
                    displayName: 'University',
                    fields: {
                      label: {
                        value: 'University/College',
                      },
                      placeholderText: {
                        value: 'University/College',
                      },
                      name: {
                        value: 'university',
                      },
                      inputType: {
                        value: 'Text',
                      },
                      required: {
                        value: true,
                      },
                      value: {
                        value: '',
                      },
                      pattern: {
                        value: '',
                      },
                      width: {
                        value: '100',
                      },
                      readonly: {
                        value: false,
                      },
                      Validations: [
                        {
                          id: '7730be13-d71e-4584-b914-c68ad16b7554',
                          fields: {
                            message: {
                              value: '{0} is required',
                            },
                            inputType: {
                              value: 'requiredValidator',
                            },
                            value: {
                              value: 'true',
                            },
                          },
                        },
                      ],
                    },
                  },
                  {
                    id: 'cc9fd7b2-69b7-4f6e-8ae1-41e526f8bffasd0',
                    name: 'Degree',
                    displayName: 'Degree',
                    fields: {
                      readonly: {
                        value: false,
                      },
                      label: {
                        value: 'Degree',
                      },
                      placeholderText: {
                        value: 'Degree',
                      },
                      name: {
                        value: 'degreeList',
                      },
                      inputType: {
                        value: 'TypeaheadDropdown',
                      },
                      required: {
                        value: true,
                      },
                      isMultiple: {
                        value: false,
                      },
                      isApi: {
                        value: true,
                      },
                      apiEndPoint: {
                        value: 'api/services/Candidate/GetMasterDataDegreeList',
                      },
                      values: [],
                      width: {
                        value: '100',
                      },
                      pattern: {
                        value: '',
                      },
                      Validations: [
                        {
                          id: '7730be13-d71e-4584-b914-c68ad16b7554',
                          fields: {
                            message: {
                              value: '{0} is required',
                            },
                            inputType: {
                              value: 'requiredValidator',
                            },
                            value: {
                              value: 'true',
                            },
                          },
                        },
                      ],
                      CustomRequiredValidator: [],
                    },
                  },
                  {
                    id: 'db3776a8-a725-4e6b-bba5-dd2721as822f2ews',
                    name: 'Graduation Start Date',
                    displayName: 'Graduation Start Date',
                    fields: {
                      connectorType: {
                        value: '<=',
                      },
                      connectorField: {
                        value: 'GraduationEndDate',
                      },
                      connectorMessage: {
                        value: 'Graduation Start Date has to be greater',
                      },
                      connectorPattern: {
                        value: '',
                      },
                      values: [
                        {
                          id: '5cd0a5fc-1ac8-44b0-a2d5dd432-c4d16339eaa9as',
                          fields: {
                            label: {
                              value: 'Jan',
                            },
                            key: {
                              value: '1',
                            },
                          },
                        },
                        {
                          id: '8fa43687-dd8c-4e012db-a29f-2fd6188v98bb0',
                          fields: {
                            label: {
                              value: 'Feb',
                            },
                            key: {
                              value: '2',
                            },
                          },
                        },
                        {
                          id: '4c087145-e00dc-4337-86e2-f1684ca0bggtab2',
                          fields: {
                            label: {
                              value: 'Mar',
                            },
                            key: {
                              value: '3',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-43p37-86e2-f1684caas0beceab2',
                          fields: {
                            label: {
                              value: 'April',
                            },
                            key: {
                              value: '4',
                            },
                          },
                        },
                        {
                          id: '4c0871aa45-00dc-4337j-86e2-f1684ca04bab2',
                          fields: {
                            label: {
                              value: 'May',
                            },
                            key: {
                              value: '5',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-43537-86eh2w-f1684ca0bab2',
                          fields: {
                            label: {
                              value: 'June',
                            },
                            key: {
                              value: '6',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-wer4wer34234-8684ca0bab2',
                          fields: {
                            label: {
                              value: 'July',
                            },
                            key: {
                              value: '7',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-4337-yuy66ui',
                          fields: {
                            label: {
                              value: 'Aug',
                            },
                            key: {
                              value: '8',
                            },
                          },
                        },
                        {
                          id: '4c087145-234lll-4337-86e2-sdf',
                          fields: {
                            label: {
                              value: 'Sep',
                            },
                            key: {
                              value: '9',
                            },
                          },
                        },
                        {
                          id: '4c087145-as246das-4337-86e2-sdaqq',
                          fields: {
                            label: {
                              value: 'Oct',
                            },
                            key: {
                              value: '10',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-4337-86were8822-asffa',
                          fields: {
                            label: {
                              value: 'Nov',
                            },
                            key: {
                              value: '11',
                            },
                          },
                        },
                        {
                          id: '4c087122145-00dc-4337-86eaa2-3erwwe',
                          fields: {
                            label: {
                              value: 'Dec',
                            },
                            key: {
                              value: '12',
                            },
                          },
                        },
                      ],
                      incre: {
                        value: '4',
                      },
                      start: {
                        value: '1950',
                      },
                      label: {
                        value: 'Start Date',
                      },
                      placeholderText: {
                        value: 'End Month/End Year',
                      },
                      sortOrder: {
                        value: 'Descending',
                      },
                      name: {
                        value: 'graduationStartDate',
                      },
                      inputType: {
                        value: 'MonthYearDropdown',
                      },
                      required: {
                        value: true,
                      },
                      value: {
                        value: '',
                      },
                      pattern: {
                        value: '',
                      },
                      width: {
                        value: '100',
                      },
                      readonly: {
                        value: false,
                      },
                      Validations: [
                        {
                          id: '7730be13-d71e-4584-bss914-c68ad16b7554',
                          fields: {
                            message: {
                              value: '{0} is required',
                            },
                            inputType: {
                              value: 'requiredValidator',
                            },
                            value: {
                              value: 'true',
                            },
                          },
                        },
                      ],
                    },
                  },
                  {
                    id: 'db3776a8-a725-4e6b-bba5-ddasdasd12721as822f2ews',
                    name: 'Graduation End Date',
                    displayName: 'Graduation End Date',
                    fields: {
                      connectorType: {
                        value: '>=',
                      },
                      connectorField: {
                        value: 'graduationStartDate',
                      },
                      connectorMessage: {
                        value: 'Graduation End Date has to be greater',
                      },
                      connectorPattern: {
                        value: '',
                      },
                      values: [
                        {
                          id: '5cd0a5fc-1ac8-44sad12b0-a2d5dd432-c4d16339eaa9as',
                          fields: {
                            label: {
                              value: 'Jan',
                            },
                            key: {
                              value: '1',
                            },
                          },
                        },
                        {
                          id: '8fa43687-dd8c-4sas1e012db-a29f-2fd6188v98bb0',
                          fields: {
                            label: {
                              value: 'Feb',
                            },
                            key: {
                              value: '2',
                            },
                          },
                        },
                        {
                          id: '4c087145-e00dc-43sa337-86e2-f1684ca0bggtab2',
                          fields: {
                            label: {
                              value: 'Mar',
                            },
                            key: {
                              value: '3',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dc-43p37-86qq1e2-f1684caas0beceab2',
                          fields: {
                            label: {
                              value: 'April',
                            },
                            key: {
                              value: '4',
                            },
                          },
                        },
                        {
                          id: '4c0871xaa45-00dc-4337j-86e2-f1684ca04bab2',
                          fields: {
                            label: {
                              value: 'May',
                            },
                            key: {
                              value: '5',
                            },
                          },
                        },
                        {
                          id: '4c0871g45-00dc-43537-86eh2w-f1684ca0bab2',
                          fields: {
                            label: {
                              value: 'June',
                            },
                            key: {
                              value: '6',
                            },
                          },
                        },
                        {
                          id: '4c087145-00zadc-wer4wer34234-8684ca0bab2',
                          fields: {
                            label: {
                              value: 'July',
                            },
                            key: {
                              value: '7',
                            },
                          },
                        },
                        {
                          id: '4c087145-0z0dc-4337-yuy66ui',
                          fields: {
                            label: {
                              value: 'Aug',
                            },
                            key: {
                              value: '8',
                            },
                          },
                        },
                        {
                          id: '4c087145-234lcll-4337-86e2-sdf',
                          fields: {
                            label: {
                              value: 'Sep',
                            },
                            key: {
                              value: '9',
                            },
                          },
                        },
                        {
                          id: '4c0871qq45-as246das-4337-86e2-sdaqq',
                          fields: {
                            label: {
                              value: 'Oct',
                            },
                            key: {
                              value: '10',
                            },
                          },
                        },
                        {
                          id: '4c087145-00dfc-4337-86were8822-asffa',
                          fields: {
                            label: {
                              value: 'Nov',
                            },
                            key: {
                              value: '11',
                            },
                          },
                        },
                        {
                          id: '4c0d87122145-00dc-4337-86eaa2-3erwwe',
                          fields: {
                            label: {
                              value: 'Dec',
                            },
                            key: {
                              value: '12',
                            },
                          },
                        },
                      ],
                      incre: {
                        value: '4',
                      },
                      start: {
                        value: '1950',
                      },
                      label: {
                        value: 'End Date',
                      },
                      placeholderText: {
                        value: 'End Month/End Year',
                      },
                      sortOrder: {
                        value: 'Descending',
                      },
                      name: {
                        value: 'graduationEndDate',
                      },
                      inputType: {
                        value: 'MonthYearDropdown',
                      },
                      required: {
                        value: true,
                      },
                      value: {
                        value: '',
                      },
                      pattern: {
                        value: '',
                      },
                      width: {
                        value: '100',
                      },
                      readonly: {
                        value: false,
                      },
                      Validations: [
                        {
                          id: '7730be13-d71e-4584-bss914-c6a8ad16b7554',
                          fields: {
                            message: {
                              value: '{0} is required',
                            },
                            inputType: {
                              value: 'requiredValidator',
                            },
                            value: {
                              value: 'true',
                            },
                          },
                        },
                      ],
                    },
                  },
                  // {
                  //   id: '8990dcdd-f749-4bc2-9e9a-f67d801e232e47b',
                  //   name: 'Graduation Month',
                  //   displayName: 'Graduation Month',
                  //   fields: {
                  //     values: [
                  //       {
                  //         id: '5cd0a5fc-1ac8-44b0-a2d2-c4d16339eaa9',
                  //         fields: {
                  //           label: {
                  //             value: 'Jan',
                  //           },
                  //           key: {
                  //             value: '1',
                  //           },
                  //         },
                  //       },
                  //       {
                  //         id: '8fa43687-dd8c-4e0b-a29f-2fd618898bb0',
                  //         fields: {
                  //           label: {
                  //             value: 'Feb',
                  //           },
                  //           key: {
                  //             value: '2',
                  //           },
                  //         },
                  //       },
                  //       {
                  //         id: '4c087145-00dc-4337-86e2-f1684ca0bab2',
                  //         fields: {
                  //           label: {
                  //             value: 'Mar',
                  //           },
                  //           key: {
                  //             value: '3',
                  //           },
                  //         },
                  //       },
                  //       {
                  //         id: '4c087145-00dc-4337-86e2-f1684ca0bab2',
                  //         fields: {
                  //           label: {
                  //             value: 'April',
                  //           },
                  //           key: {
                  //             value: '4',
                  //           },
                  //         },
                  //       },
                  //       {
                  //         id: '4c087145-00dc-4337-86e2-f1684ca0bab2',
                  //         fields: {
                  //           label: {
                  //             value: 'May',
                  //           },
                  //           key: {
                  //             value: '5',
                  //           },
                  //         },
                  //       },
                  //       {
                  //         id: '4c087145-00dc-4337-86e2-f1684ca0bab2',
                  //         fields: {
                  //           label: {
                  //             value: 'June',
                  //           },
                  //           key: {
                  //             value: '6',
                  //           },
                  //         },
                  //       },
                  //       {
                  //         id: '4c087145-00dc-4337-86e2-f1684ca0bab2',
                  //         fields: {
                  //           label: {
                  //             value: 'July',
                  //           },
                  //           key: {
                  //             value: '7',
                  //           },
                  //         },
                  //       },
                  //       {
                  //         id: '4c087145-00dc-4337-86e2-f1684ca0bab2',
                  //         fields: {
                  //           label: {
                  //             value: 'Aug',
                  //           },
                  //           key: {
                  //             value: '8',
                  //           },
                  //         },
                  //       },
                  //       {
                  //         id: '4c087145-00dc-4337-86e2-f1684sdfca0bab2',
                  //         fields: {
                  //           label: {
                  //             value: 'Sep',
                  //           },
                  //           key: {
                  //             value: '9',
                  //           },
                  //         },
                  //       },
                  //       {
                  //         id: '4c087145-00dc-4337-86e2-f1684ca0bab2',
                  //         fields: {
                  //           label: {
                  //             value: 'Oct',
                  //           },
                  //           key: {
                  //             value: '10',
                  //           },
                  //         },
                  //       },
                  //       {
                  //         id: '4c087145-00dc-4337-86e2-f1684ca0bab2',
                  //         fields: {
                  //           label: {
                  //             value: 'Nov',
                  //           },
                  //           key: {
                  //             value: '11',
                  //           },
                  //         },
                  //       },
                  //       {
                  //         id: '4c087145-00dc-4337-86e2-f1684ca0bab2',
                  //         fields: {
                  //           label: {
                  //             value: 'Dec',
                  //           },
                  //           key: {
                  //             value: '12',
                  //           },
                  //         },
                  //       },
                  //     ],
                  //     connectorType: {
                  //       value: '',
                  //     },
                  //     connectorField: {
                  //       value: '',
                  //     },
                  //     connectorMessage: {
                  //       value: '',
                  //     },
                  //     connectorPattern: {
                  //       value: '',
                  //     },
                  //     label: {
                  //       value: 'Graduation Month',
                  //     },
                  //     placeholderText: {
                  //       value: 'Graduation Month',
                  //     },
                  //     name: {
                  //       value: 'graduationMonth',
                  //     },
                  //     inputType: {
                  //       value: 'NormalDropdown',
                  //     },
                  //     required: {
                  //       value: true,
                  //     },
                  //     value: {
                  //       value: '',
                  //     },
                  //     pattern: {
                  //       value: '',
                  //     },
                  //     width: {
                  //       value: '50',
                  //     },
                  //     readonly: {
                  //       value: false,
                  //     },
                  //     Validations: [
                  //       {
                  //         id: '7730be13-d71e-4584-b914-c68ad16b7554',
                  //         fields: {
                  //           message: {
                  //             value: '{0} is required',
                  //           },
                  //           inputType: {
                  //             value: 'requiredValidator',
                  //           },
                  //           value: {
                  //             value: 'true',
                  //           },
                  //         },
                  //       },
                  //     ],
                  //   },
                  // },
                  // {
                  //   id: 'bf0f3480-dab4-4828-9896-111048010f9sd8',
                  //   name: 'Start Year',
                  //   displayName: 'Start Year',
                  //   fields: {
                  //     incre: {
                  //       value: '',
                  //     },
                  //     start: {
                  //       value: '1950',
                  //     },
                  //     connectorType: {
                  //       value: '',
                  //     },
                  //     connectorField: {
                  //       value: '',
                  //     },
                  //     connectorMessage: {
                  //       value: '',
                  //     },
                  //     connectorPattern: {
                  //       value: '',
                  //     },
                  //     label: {
                  //       value: 'Graduation Year',
                  //     },
                  //     placeholderText: {
                  //       value: 'Graduation Year',
                  //     },
                  //     name: {
                  //       value: 'graduationYear',
                  //     },
                  //     inputType: {
                  //       value: 'NumberDropdown',
                  //     },
                  //     required: {
                  //       value: true,
                  //     },
                  //     value: {
                  //       value: '',
                  //     },
                  //     pattern: {
                  //       value: '',
                  //     },
                  //     width: {
                  //       value: '50',
                  //     },
                  //     readonly: {
                  //       value: false,
                  //     },
                  //     Validations: [
                  //       {
                  //         id: '7730be13-d71e-4584-b914-c68ad16b7554',
                  //         fields: {
                  //           message: {
                  //             value: '{0} is required',
                  //           },
                  //           inputType: {
                  //             value: 'requiredValidator',
                  //           },
                  //           value: {
                  //             value: 'true',
                  //           },
                  //         },
                  //       },
                  //     ],
                  //   },
                  // },
                ],
              },
            },
            {
              uid: 'cc2ed2a6-afe3-45dd-a90b-a3ed5f921e5dscascc',
              componentName: 'EditCertifications',
              fields: {
                title: {
                  value: 'Certifications',
                },
                text: {
                  value: '(missing information)',
                },

                icon: {
                  value: {
                    src: educationiconblue,
                    alt: 'info-active',
                    width: '200',
                    height: '200',
                  },
                },
                missingIcon: {
                  value: {
                    src: educationicongray,
                    alt: 'info-active',
                    width: '200',
                    height: '200',
                  },
                },

                subTitle: {
                  value: 'Adpscing malort cane lorem ipsum dolor.',
                },
                formText: {
                  value: 'All fields are required unless otherwise stated.',
                },
                items: [
                  // {
                  //   id: 'e855c3a1-57ec-4332-956a-c6b5af0da88d',
                  //   name: 'University',
                  //   displayName: 'University',
                  //   fields: {
                  //     label: {
                  //       value: 'University/College',
                  //     },
                  //     placeholderText: {
                  //       value: 'University/College',
                  //     },
                  //     name: {
                  //       value: 'university',
                  //     },
                  //     inputType: {
                  //       value: 'Text',
                  //     },
                  //     required: {
                  //       value: true,
                  //     },
                  //     value: {
                  //       value: '',
                  //     },
                  //     pattern: {
                  //       value: '',
                  //     },
                  //     width: {
                  //       value: '100',
                  //     },
                  //     readonly: {
                  //       value: false,
                  //     },
                  //     Validations: [
                  //       {
                  //         id: '7730be13-d71e-4584-b914-c68ad16b7554',
                  //         fields: {
                  //           message: {
                  //             value: '{0} is required',
                  //           },
                  //           inputType: {
                  //             value: 'requiredValidator',
                  //           },
                  //           value: {
                  //             value: 'true',
                  //           },
                  //         },
                  //       },
                  //     ],
                  //   },
                  // },
                  {
                    id: '52868f9e-317f-4693-bb08-6833d6dasas6f1dd',
                    name: 'Upload Certificate',
                    displayName: 'Upload Certificate',
                    fields: {
                      values: [
                        {
                          id: '80c6ca73-e86d-4d75-946b-77sas572f73befa',
                          fields: {
                            key: {
                              value: '0',
                            },
                            label: {
                              value: 'From you device',
                            },
                            img: {
                              value: {
                                src: NormalIcon,
                              },
                            },
                          },
                        },
                        {
                          id: '80c6ca73-e86d-4d75-946b-775sas72f73befa',
                          fields: {
                            key: {
                              value: '0',
                            },
                            label: {
                              value: 'From dropBox',
                            },
                            img: {
                              value: {
                                src: dropbox,
                              },
                            },
                          },
                        },
                        {
                          id: '80c6ca73-e86d-4d75-946b-775sas72f73befa',
                          fields: {
                            key: {
                              value: '0',
                            },
                            label: {
                              value: 'From oneDrive',
                            },
                            img: {
                              value: {
                                src: onedrive,
                              },
                            },
                          },
                        },
                        {
                          id: '80c6ca73-e86d-4d75-946b-77572fsas73befa',
                          fields: {
                            key: {
                              value: '0',
                            },
                            label: {
                              value: 'From Google Drive',
                            },
                            img: {
                              value: {
                                src: gDrive,
                              },
                            },
                          },
                        },
                      ],
                      readonly: {
                        value: false,
                      },
                      label: {
                        value: 'Upload Certificate',
                      },
                      placeholderText: {
                        value: 'Upload Certificate',
                      },
                      name: {
                        value: 'uploadCertifications',
                      },
                      showOnload: {
                        value: true,
                      },
                      isMultifiles: {
                        value: true,
                      },
                      apiEndPoint: {
                        value: 'api/services/Candidate/DownloadAttachment?{cid}',
                      },
                      inputType: {
                        value: 'FileUpload',
                      },
                      required: {
                        value: true,
                      },
                      value: {
                        value: '',
                      },
                      width: {
                        value: '100',
                      },
                      pattern: {
                        value: '',
                      },
                      Validations: [
                        {
                          id: '7730be13-d71e-4584-b914-c68ad16bsas7554',
                          fields: {
                            message: {
                              value: '{0} is required',
                            },
                            inputType: {
                              value: 'requiredValidator',
                            },
                            value: {
                              value: 'true',
                            },
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              uid: 'cc2ed2a6-afe3-45dd-a90b-a3ed5f921sase5dsaaa',
              componentName: 'EditCoverLetters',
              fields: {
                title: {
                  value: 'Cover Letters and Additional Files',
                },
                text: {
                  value: '(missing information)',
                },
                icon: {
                  value: {
                    src: coverletterblue,
                    alt: 'info-active',
                    width: '200',
                    height: '200',
                  },
                },
                missingIcon: {
                  value: {
                    src: educationicongray,
                    alt: 'info-active',
                    width: '200',
                    height: '200',
                  },
                },
                subTitle: {
                  value: 'Add cover letters, references, additional resume’s, etc…',
                },
                items: [
                  {
                    id: 'e855c3a1-57ec-4332-956a-cas6b5af0sasda88d',
                    name: 'University',
                    displayName: 'University',
                    fields: {
                      label: {
                        value: 'University/College',
                      },
                      placeholderText: {
                        value: 'University/College',
                      },
                      name: {
                        value: 'universaity',
                      },
                      inputType: {
                        value: 'Text',
                      },
                      required: {
                        value: true,
                      },
                      value: {
                        value: '',
                      },
                      pattern: {
                        value: '',
                      },
                      width: {
                        value: '100',
                      },
                      readonly: {
                        value: false,
                      },
                      Validations: [],
                    },
                  },
                  {
                    id: '52868f9e-317f-4693-basasb08-68xxs33d6d6f1dd',
                    name: 'Upload File',
                    displayName: 'Upload File',
                    fields: {
                      values: [
                        {
                          id: '80c6ca73-e86aad-4d75-946aaaab-77572f73befa',
                          fields: {
                            key: {
                              value: '0',
                            },
                            label: {
                              value: 'From you device',
                            },
                            img: {
                              value: {
                                src: NormalIcon,
                              },
                            },
                          },
                        },
                        {
                          id: '80c6ca73-e8aaa6d-4d75-946b-77572fasas73befa',
                          fields: {
                            key: {
                              value: '0',
                            },
                            label: {
                              value: 'From dropBox',
                            },
                            img: {
                              value: {
                                src: dropbox,
                              },
                            },
                          },
                        },
                        {
                          id: '80c6ca73-e86d-4d75-946b-77572f7asasa3befa',
                          fields: {
                            key: {
                              value: '0',
                            },
                            label: {
                              value: 'From oneDrive',
                            },
                            img: {
                              value: {
                                src: onedrive,
                              },
                            },
                          },
                        },
                        {
                          id: '80c6ca73-e86d-4d75-946b-77572f73asasasaaaabefa',
                          fields: {
                            key: {
                              value: '0',
                            },
                            label: {
                              value: 'From Google Drive',
                            },
                            img: {
                              value: {
                                src: gDrive,
                              },
                            },
                          },
                        },
                      ],
                      readonly: {
                        value: false,
                      },
                      label: {
                        value: 'Upload File',
                      },
                      placeholderText: {
                        value: 'Upload File',
                      },
                      name: {
                        value: 'Cover Letter',
                      },
                      isAttachmentFilterRequired: {
                        value: true,
                      },
                      showOnload: {
                        value: true,
                      },
                      isMultifiles: {
                        value: true,
                      },
                      apiEndPoint: {
                        value: 'api/services/Candidate/DownloadAttachment?{cid}',
                      },
                      inputType: {
                        value: 'FileUpload',
                      },
                      required: {
                        value: true,
                      },
                      value: {
                        value: '',
                      },
                      width: {
                        value: '100',
                      },
                      pattern: {
                        value: '',
                      },
                      Validations: [
                        // {
                        //   id: '7730be13-d71e-4584-b914-c68adasas16b7554',
                        //   fields: {
                        //     message: {
                        //       value: '{0} is required',
                        //     },
                        //     inputType: {
                        //       value: 'requiredValidator',
                        //     },
                        //     value: {
                        //       value: 'true',
                        //     },
                        //   },
                        // },
                      ],
                    },
                  },
                  // {
                  //   id: 'f98281e3-2606-4c4c-897a-01e0a8b33796',
                  //   name: 'Address1',
                  //   displayName: 'Address1',
                  //   fields: {
                  //     readonly: {
                  //       value: false,
                  //     },
                  //     label: {
                  //       value: 'Adress1',
                  //     },
                  //     placeholderText: {
                  //       value: 'Adress 1',
                  //     },
                  //     name: {
                  //       value: 'address.address1',
                  //     },
                  //     inputType: {
                  //       value: 'Text',
                  //     },
                  //     required: {
                  //       value: true,
                  //     },
                  //     value: {
                  //       value: '',
                  //     },
                  //     width: {
                  //       value: '100',
                  //     },
                  //     pattern: {
                  //       value: '',
                  //     },
                  //     Validations: [
                  //       {
                  //         id: '7730be13-d71e-4584-b914-c68ad16b7554',
                  //         fields: {
                  //           message: {
                  //             value: '{0} is required',
                  //           },
                  //           inputType: {
                  //             value: 'requiredValidator',
                  //           },
                  //           value: {
                  //             value: 'true',
                  //           },
                  //         },
                  //       },
                  //     ],
                  //   },
                  // },
                ],
              },
            },
          ],
        },
      },
    ],
  },
};

export default BuildProfileMock;
