import React from 'react';
import PropTypes from 'prop-types';
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';

import '../subnav.scss';

/**
 * @description - sub navigation tile component
 * @param {string} title - title to be displayed
 * @param {string} icon - icon to be displayed
 * @param {string} link - link to be redirected on click
 * @returns {Node} - HTML
 */
const SubNavBox = ({ title, icon, link }) => (
  <Link to={link} className="navbox image--wipe">
    <div className="figure" role="presentation">
      {icon ? (
        <>
          <div className="imageContainer">
            <Image className="icon" media={icon} />
            {/* <img
              src={`https://bankpowersitgermany.manpowergroup.com${icon?.value?.src}`}
              alt="subnav-icon"
              className="icon"
            /> */}
          </div>
          <RichText
            tag="span"
            className="caption"
            role="presentation"
            field={title}
            editable={false}
          />
        </>
      ) : (
        <RichText
          tag="span"
          className="caption"
          role="presentation"
          field={title}
          editable={false}
        />
      )}
    </div>
    <span className="wipe--apollo" />
  </Link>
);

SubNavBox.defaultProps = {
  title: '',
  icon: '',
  link: '',
};

SubNavBox.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default SubNavBox;
