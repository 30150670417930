import React, { Fragment, useState, useEffect } from 'react';
// import Rsvp from './EventRegistration';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  RichText,
  Image,
  DateField,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';

import { DATE_FORMATS } from '../../../../utils/enums';

/**
 * @description - Single Event component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML.
 */
const SingleEvent = (props) => {
  const {
    image,
    startDate,
    endDate,
    EventRegistration,
    t,
    description,
    sitecoreContext,
  } = props;
  const [richTextField, setRichTextField] = useState('');
  const dateFormat = sitecoreContext?.Country?.dateFormat || DATE_FORMATS.LOCALE;
  const timeFormat = DATE_FORMATS.TIME_FULL_HR_MIN;

  useEffect(() => {
    setRichTextField(description);
  }, [description]);

  return (
    <div className="single-event">
      <Image className="responsive" editable={true} field={image} />
      <h2 className="title">{t('about-the-event')}</h2>
      {richTextField && (
        <RichText tag="p" field={richTextField} className="content" />
      )}
      <h2 className="title">{t('when')}</h2>
      <p>
        <DateField
          field={startDate}
          render={() => moment(startDate?.value).format(dateFormat)}
        />
        <br aria-hidden={true} />
        <DateField
          field={startDate}
          render={() => moment(startDate?.value).format(timeFormat)}
        />
        &nbsp;-&nbsp;
        <DateField
          field={endDate}
          render={() => moment(endDate?.value).format(timeFormat)}
        />
      </p>
      {EventRegistration.map((component, index) => {
        if (component.props && component.props.type === 'text/sitecore')
          return component;
        return <Fragment key={index}>{component}</Fragment>;
      })}
    </div>
  );
};

SingleEvent.defaultProps = {
  EventRegistration: [],
};

SingleEvent.propTypes = {
  EventRegistration: PropTypes.arrayOf(PropTypes.shape()),
  description: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  image: PropTypes.shape({
    value: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }).isRequired,
  startDate: PropTypes.shape({ value: PropTypes.string }).isRequired,
  endDate: PropTypes.shape({ value: PropTypes.string }).isRequired,
  t: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({}).isRequired,
};

export default withSitecoreContext()(withTranslation()(SingleEvent));
