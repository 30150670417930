/*eslint-disable*/
/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import PropTypes from 'prop-types';
import {
  symbolValidationCheck,
  patternRegexCheck,
} from '../../Forms/DynamicForm/helper/validationFactory';
import { getValidationDatabyType } from '../../Forms/DynamicForm/helper/filter';
import { isNullorEmpty } from '../../../../utils/helperUtils';
import { connect } from 'react-redux';
import { removeFocus } from '../../../../redux/actions/actions';

/**
 * @description - Select dropdown component.
 * @returns {Node} - HTML.
 */
class Select extends React.Component {
  /**
   * @description - Constructor.
   * @param {Object} props - Props
   */
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      filled: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.selectRef = React.createRef(null);
  }

  /**
   * @description - Component DidMount Lifecycle hook.
   * @returns {undefined}
   */
  componentDidMount() {
    if (this.props.value && this.props.value !== '')
      this.setState({
        filled: this.props.value && this.props.value !== '',
        value: this.props.value,
      });
    document.addEventListener('touchstart', (e) => this.listener(e));
    document.addEventListener('click', (e) => this.listener(e));
    if (!isNullorEmpty(this.selectRef) && !isNullorEmpty(this.selectRef.current))
      this.selectRef.current.focus();
  }

  /**
   * @description - Component DidUpdate Lifecycle hook.
   * @param {Object} prevProps - Previous Props.
   * @returns {undefined}
   */
  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        filled: this.props.value && this.props.value !== '',
        value: this.props.value,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('touchstart', this.listener);
    document.removeEventListener('click', this.listener);
  }

  listener(e) {
    if (e.target.id != this.props.id) {
      this.addPlaceholder();
    }
  }
  /**
   * @description - Handle Change Function.
   * @param {Object} event - Select Event.
   * @returns {undefined}
   */
  handleChange(event) {
    if (!isNullorEmpty(this.selectRef)) this.props.removeFocus(false);
    if (event.target.value === '') {
      setTimeout(() => this.addPlaceholder(), 100);
    }
    const { selectHandler, options } = this.props;
    this.setState({
      value: event.target.value,
      filled: event?.target?.value ? true : false,
    });
    if (selectHandler) {
      selectHandler(
        event,
        options.find((option) => option.value == event?.target?.value)
      );
    }
  }

  handleClick() {
    if (
      this.props.showPlaceholder &&
      document.getElementById(this.props.id).options[0].innerHTML ===
        this.props.placeholder
    ) {
      document.getElementById(this.props.id).options[0].remove();
    }
  }

  addPlaceholder() {
    if (
      this.props.showPlaceholder &&
      document.getElementById(this.props.id)?.options[0]?.innerHTML !==
        this.props.placeholder &&
      document.getElementById(this.props.id)?.selectedIndex === 0
    ) {
      let select = document.getElementById(this.props.id);
      let opt = new Option(this.props.placeholder, '', true, true);
      select?.insertBefore(opt, select.firstChild);
    }
  }

  showNoneOption = (noneOption) =>
    noneOption ? (
      <option value="" role="option">
        {noneOption}
      </option>
    ) : null;

  /**
   * @description - Default render.
   * @returns {Node} HTML.
   */
  render() {
    const {
      complexClass,
      dataValidations,
      connectorField,
      connectorType,
      getValues,
      connectorMessage,
      showPlaceholder,
      options,
    } = this.props;
    const {
      cssClass,
      customStyle,
      id,
      name,
      register,
      placeholder,
      ariaLabel,
    } = this.props;
    const {
      labelText,
      labelText2,
      errorMsg,
      customError,
      noneOption,
      focusId,
    } = this.props;
    const { selectedValue } = this.props;
    const { filled, value } = this.state;
    const patternData = getValidationDatabyType(dataValidations, 'pattern');
    const requiredData = getValidationDatabyType(dataValidations, 'required');
    const maximumData = getValidationDatabyType(dataValidations, 'maximum');
    const minimumData = getValidationDatabyType(dataValidations, 'minimum');
    const connectorData = {
      connectorField: connectorField,
      connectorType: connectorType,
      getValues: getValues,
    };
    const showField = this.props.watch
      ? this.props.watch(this.props.showBasedOnFieldName)
      : true;
    const valProp = selectedValue ? { value: selectedValue } : {};
    return (
      <div
        className={`form-block ${complexClass} ${cssClass} ${
          value ? 'select-active' : ''
        } ${this.props.isHideField && !showField ? 'hide' : ''}`}
        style={customStyle}
      >
        <select
          id={id}
          name={name}
          onClick={this.handleClick}
          onTouchStart={this.handleClick}
          onChange={this.handleChange}
          defaultValue={value}
          value={value}
          aria-label={ariaLabel}
          ref={
            register
              ? register({
                  required:
                    ((!this.props.isHideField || showField) &&
                      requiredData?.fields?.value?.value) ||
                    false,
                  validate: {
                    symbolValidationCheck: (value) =>
                      (connectorData.connectorField
                        ? symbolValidationCheck(value, connectorData)
                        : true) ||
                      connectorMessage ||
                      'Invalid Values',
                  },
                  maxLength: {
                    value: maximumData?.fields?.value?.value || null,
                    message:
                      maximumData?.fields?.message?.value || 'Exceeded the limits',
                  },
                  minLength: {
                    value: minimumData?.fields?.value?.value || null,
                    message:
                      minimumData?.fields?.message?.value ||
                      'Minimum characters not reached',
                  },
                  pattern: {
                    value: patternData?.fields?.value?.value
                      ? patternRegexCheck(patternData.fields.value.value)
                      : '',
                    message:
                      patternData?.fields?.message?.value ||
                      'Pattern validation failed',
                  },
                })
              : !isNullorEmpty(focusId) && focusId.current === id
              ? this.selectRef
              : () => {}
          }
          className={complexClass && filled ? 'filled' : ''}
          {...valProp}
        >
          {showPlaceholder && (
            <option value="" hidden selected role="option">
              {placeholder}
            </option>
          )}
          {this.showNoneOption(noneOption)}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.title}
            </option>
          ))}
        </select>
        <label htmlFor={id}>
          <div className="active">{labelText}</div>
          <div className="inactive">{labelText2 || labelText}</div>
        </label>
        {errorMsg && <div className="error-msg">{errorMsg}</div>}
        {customError && customError[name] && (
          <div className="error-msg">
            {customError[name].type === 'required'
              ? requiredData?.fields?.message?.value
                ? requiredData.fields.message.value.replace(
                    '{0}',
                    labelText.replace('*', '')
                  )
                : 'This Field is Required'
              : customError[name].message.replace('{0}', labelText.replace('*', ''))}
          </div>
        )}
      </div>
    );
  }
}

Select.defaultProps = {
  id: '',
  name: '',
  labelText: '',
  placeholder: '',
  showPlaceholder: false,
  options: [],
  //  [
  //   {
  //     title: '',
  //     value: '0',
  //   },
  //   {
  //     title: 'Option 1',
  //     value: '1',
  //   },
  //   {
  //     title: 'Option 2',
  //     value: '2',
  //   },
  //   {
  //     title: 'Option 3',
  //     value: '3',
  //   },
  // ],
  errorMsg: '',
  customStyle: {},
  complexClass: '',
  selectedValue: null,
  selectHandler: null,
  ariaLabel: '',
};

Select.propTypes = {
  /** Field identifier  */
  id: PropTypes.string.isRequired,

  /** Field name  */
  name: PropTypes.string.isRequired,

  /** Label text */
  labelText: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  showPlaceholder: PropTypes.bool.isRequired,

  /** Options to display in the drop down */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,

  /** Error message if validation occurs */
  errorMsg: PropTypes.string,
  customStyle: PropTypes.shape({}),
  complexClass: PropTypes.string,
  selectHandler: PropTypes.func,
  selectedValue: PropTypes.string,
  ariaLabel: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeFocus: () => dispatch(removeFocus()),
  };
};
export default connect(null, mapDispatchToProps)(Select);
