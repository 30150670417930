/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

/**
 * @description - File upload Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const DownloadIcon = (props) => {
  const { iconColor } = props;
  return (
    <svg width="15px" height="15px" viewBox="0 0 16 21">
      <g
        id="Insights"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="L3-Your-Career" transform="translate(-607.000000, -4403.000000)">
          <g id="Resources" transform="translate(0.000000, 4151.000000)">
            <g id="CTA" transform="translate(350.000000, 251.000000)">
              <g id="icon_download" transform="translate(256.929545, 0.500000)">
                <path
                  d="M0.804469274,9.65370813 L13.0648045,9.65370813 L7.59441341,15.2580144 C7.2726257,15.5876794 7.2726257,16.082177 7.59441341,16.4118421 C7.75530726,16.5766746 7.99664804,16.6590909 8.1575419,16.6590909 C8.31843575,16.6590909 8.55977654,16.5766746 8.72067039,16.4118421 L15.5586592,9.40645933 C15.8804469,9.07679426 15.8804469,8.58229665 15.5586592,8.25263158 L8.72067039,1.2472488 C8.39888268,0.917583732 7.91620112,0.917583732 7.59441341,1.2472488 C7.2726257,1.57691388 7.2726257,2.07141148 7.59441341,2.40107656 L13.0648045,8.00538278 L0.804469274,8.00538278 C0.321787709,8.00538278 -4.77787254e-13,8.33504785 -4.77787254e-13,8.82954545 C-4.77787254e-13,9.32404306 0.321787709,9.65370813 0.804469274,9.65370813 Z"
                  id="Path-Copy-4"
                  fill={iconColor}
                  fill-rule="nonzero"
                  transform="translate(7.900000, 8.829545) rotate(-270.000000) translate(-7.900000, -8.829545) "
                ></path>
                <path
                  d="M1.07045455,20 L15.0704545,20"
                  id="Line-3"
                  stroke={iconColor}
                  stroke-width="1.5"
                  stroke-linecap="round"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

DownloadIcon.propTypes = {
  item: PropTypes.shape({}).isRequired,
};

DownloadIcon.defaultProps = {
  iconColor: '#30588f'
};

export default withTheme(DownloadIcon);
