import { useEffect, useState } from 'react';

/**
 *
 */
export function useSnackbar() {
  const [isActive, setIsActive] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccess, setSuccessStatus] = useState();

  useEffect(() => {
    if (isActive === true) {
      setTimeout(() => {
        setIsActive(false);
      }, 5000);
    }
  }, [isActive]);

  const openSnackBar = (msg = 'Something went wrong...', showSucess = false) => {
    setMessage(msg);
    setIsActive(true);
    setSuccessStatus(showSucess);
  };

  return { isActive, message, isSuccess, openSnackBar };
}
