import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import FilterItem from '../../core/Filters/FilterItem';
import { PartnerFilterContext } from '../Contexts';
import { getFilterArray } from '../../../factory/partnerInsightsFactory';

import '../ArticleFilters/articlefilters.scss';

// import { filterFields } from '../../mocks/partnerArticles';

/**
 *
 * @param {*} option
 * @param {*} param1
 */
const handleUpdateTypeChange = (option, filterVals) => {
  const { setUpdateType } = filterVals;
  setUpdateType(option.id);
};

/**
 * @description - Filters for insights card blocks.
 * @param {object} props - Input props.
 * @returns {Node} - HTML filter items.
 */
const ArticleFilters = (props) => {
  const { t, fields } = props;
  const filterVals = useContext(PartnerFilterContext) || {};
  const { updateType } = filterVals;
  const { location } = filterVals;
  // const options = getFilterArray(filterFields?.data?.updateTypes?.facets, t('all'));
  const options = getFilterArray(fields?.data?.updateTypes?.facets, t('all'));

  return (
    <section className="article-filters">
      <FilterItem
        text={t('filters')}
        iconBefore={<div className="filter-icon" />}
        iconAfter={<div className="down-arrow" />}
        ddropdown="sort"
        dropdownSize="small"
        dropdownOptions={options}
        emptyText={t('Filters')}
        actionParams={filterVals}
        clickHandler={handleUpdateTypeChange}
        selectedOption={updateType}
        selectedValue={location?.query?.contentType?.name}
      />
    </section>
  );
};

ArticleFilters.defaultProps = {
  fields: {},
  t: (val) => val,
};

ArticleFilters.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    data: PropTypes.shape({
      updateTypes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default ArticleFilters;
