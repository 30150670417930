/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import TextLink from '../../../../core/globals/link/TextLink';
import { usePostData, useFetchData } from '../../../../../hooks/http-client';
import Table from '../../../../core/Table';
import { DASHBOARD, API_STATUS_CODES } from '../../../../../constants';
import AlertPopUp from '../../../../core/ErrorBoundary/AlertPopUp';
import DefaultIcon from '../../../../../../src/assets/images/icon_logo_default.svg';

/**
 * @description - DashboardSavedJobs component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const DashboardSavedJobs = (props) => {
  const { fields, t } = props;
  const { title, icon, itemsPerPage } = fields;
  const { write } = usePostData();
  const { load, loading, error } = useFetchData();

  const [data, setData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [jobToBeRemoved, setJobToBeRemoved] = useState(null);
  const [response, setResponse] = useState(null);
  const [page, setPage] = useState(1);
  const [showError, setError] = useState(error);
  const [showLoading, setLoading] = useState(loading);

  useEffect(() => {
    setError(error);
  }, [error]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  /**
   * @description to handle alert pop up
   * @returns {undefined} no return
   */
  const handleAlert = () => {
    setShowAlert(!showAlert);
  };

  /**
   * @description - to delete a saved job
   * @param {string} jobItemId - ob id
   * @returns {undefined} - no returns
   */
  const handleDelete = (jobItemId) => {
    write(
      `${DASHBOARD.SAVED_JOBS.DELETE}?jobID=${jobItemId}`,
      null,
      handleDeleteResponse
    );
  };

  /**
   * @description - post delete action
   * @param {object} apiResponse  - api response object
   * @returns {undefined} - no returns
   */
  const handleDeleteResponse = (apiResponse) => {
    if (apiResponse.data.status === API_STATUS_CODES.SUCCESS) {
      /* setResponse(
        response?.data?.items?.getSavedJobList?.map((item, index) => {
          if (item.jobItemID == jobToBeRemoved)
            response.data.items.getSavedJobList.splice(index, 1);
        })
      ); */
      response?.data?.items?.getSavedJobList?.length === 1
        ? getSavedJobs(page === 1 ? page : page - 1)
        : getSavedJobs(page);
    }
  };

  const columns = [
    {
      selector: 'icon',
      grow: 0,
      cell: (row) => (
        <div
          style={{
            backgroundImage: row?.icon ? `url(${row?.icon})` : `url(${DefaultIcon})`,
            displayName: '',
          }}
          className="ibm-logo-container"
        >
          <img className="sr-only" src={row?.icon} alt="" />
        </div>
      ),
    },
    {
      selector: 'title',
      grow: 2,
      cell: (row) => {
        return <div className="job-title">{row.title}</div>;
      },
    },
    {
      selector: 'view',
      right: true,
      cell: (row) => (
        <div className="view-or-remove">
          <TextLink
            ctaText={t('view')}
            cssClass="primary"
            ctaLabel="View opens in a new window/tab"
            ctaUrl={`${row.view}`}
            style={{
              displayName: '',
            }}
          />
          <div className="vertical-seperator" />
          <button
            className="remove-cell"
            aria-label={t('remove')}
            aria-description={`${t('remove')} pop-up box opens`}
            onClick={() => {
              setJobToBeRemoved(row.id);
              handleAlert();
            }}
          >
            {t('remove')}
          </button>
        </div>
      ),
    },
  ];

  /**
   * @description prepare data for suggested jobs
   * @param {object} response - api response
   * @returns {undefined} - no return
   */
  const prepareData = async (apiResponse) => {
    const jobs = [];
    setResponse(apiResponse);
    await apiResponse?.data?.items?.getSavedJobList?.forEach((job, index) => {
      jobs[index] = {
        icon: job?.customerLogoURL,
        title: job?.jobTitle,
        view: job?.jobURL,
        id: job?.jobItemID,
      };
    });
    setData(jobs);
  };

  /**
   * @description - call to card scroll api
   * @param {object} queryParams - request body
   * @returns {undefined}
   */
  const getSavedJobs = (pageNo) => {
    setPage(pageNo);
    load(`${DASHBOARD.SAVED_JOBS.GET}`, { pageNumber: pageNo - 1 }, prepareData);
  };

  useEffect(() => {
    getSavedJobs(1);
  }, []);

  return (
    <>
      <div className="dashboard-block normal saved-job">
        <Table
          columns={columns}
          row={data}
          icon={icon}
          title={title}
          headerClasses="header-class"
          noTableHead={true}
          pagination={true}
          paginationPerPage={itemsPerPage?.value}
          paginationTotalRows={response?.data?.count}
          paginationOptions={{ noRowsPerPage: true }}
          paginationServer={true}
          onChangePage={(page) => getSavedJobs(page)}
          noDataMessage={t('empty-saved-jobs')}
          progressPending={showLoading}
          error={showError}
          handleRefresh={(page) => {
            setError(false);
            setLoading(true);
            setTimeout(() => getSavedJobs(page), 500);
          }}
        />

        <AlertPopUp
          message={t('delete-confirmation-savedjob')}
          btnOkText={t('yes')}
          btnCancelText={t('no')}
          visibility={showAlert}
          handleModal={handleAlert}
          callback={() => handleDelete(jobToBeRemoved)}
        ></AlertPopUp>
      </div>
    </>
  );
};

DashboardSavedJobs.defaultProps = {
  fields: {
    title: { value: 'SAVED JOBS' },
    icon: { value: '' },
  },
  ctaUrl: '#',
};

DashboardSavedJobs.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  ctaUrl: PropTypes.string.isRequired,
};

export default withTranslation()(DashboardSavedJobs);
