import { setADB2CConfig } from '../../redux/actions/loginActions';
import initAuth from '../auth/authProvider';

/**
 * @description - config for auth.
 * @param {Object} fields - Fields Object.
 * @param {Object} path - Path Object.
 * @param {Object} dispatch - Dispatch Object.
 * @param {String} language - language String.
 * @returns {undefined}
 */
const authConfig = async (fields, path, dispatch, language) => {
  if (fields) {
    const {
      adB2CHostName,
      clientId,
      passwordResetPolicy,
      passwordUpdatePolicy,
      phoneNumberUpdatePolicy,
      profileInformationUpdatepolicy,
      signUpSignInPolicy,
      tenantName,
    } = fields;

    const b2cPolicy = {
      signUpSignIn: signUpSignInPolicy,
      forgotPassword: passwordResetPolicy,
      profileUpdate: profileInformationUpdatepolicy,
      passwordUpdate: passwordUpdatePolicy,
      editPhoneNumber: phoneNumberUpdatePolicy,
    };
    const authority = {
      signUpSignIn: `${adB2CHostName}/${tenantName}/${b2cPolicy.signUpSignIn}`,
      forgotPassword: `${adB2CHostName}/${tenantName}/${b2cPolicy.forgotPassword}`,
      profileUpdate: `${adB2CHostName}/${tenantName}/${b2cPolicy.profileUpdate}`,
      passwordUpdate: `${adB2CHostName}/${tenantName}/${b2cPolicy.passwordUpdate}`,
      editPhoneNumber: `${adB2CHostName}/${tenantName}/${b2cPolicy.editPhoneNumber}`,
    };
    // Msal Configurations
    const msalConfig = {
      auth: {
        authority: authority.signUpSignIn,
        clientId,
        validateAuthority: false,
        redirectUri: `${path?.origin}/${language}/redirect`,
        postLogoutRedirectUri: `${path?.origin}/${language}/redirect`,
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
      },
      scopes: ['openid', 'profile'],
      extraQueryParameters: { ui_locales: language },
    };
    dispatch(
      setADB2CConfig({
        msalConfig,
        authority,
        b2cPolicy,
        tenantName,
        clientId,
        hostName: adB2CHostName,
      })
    );

    await initAuth();
  }
};

export default authConfig;
