import { COVER_LETTER } from '../actions/actionTypes';

const initialState = {
  data: [],
  removeFile: [],
};
/**
 * @description - Resume Reducer
 * @param {*} state
 * @param {*} action
 */
const coverReducer = (state = initialState, action) => {
  switch (action.type) {
    case COVER_LETTER.GET:
      return {
        ...state,
        data: action.payload,
      };
    case COVER_LETTER.CLEAR:
      return {
        ...state,
        removeFile: action.payload,
      };
    case COVER_LETTER.CLEARALL:
      return initialState;
    default:
      return state;
  }
};
export default coverReducer;
