import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import './sidebarcards.scss';

/**
 * @description to show location details
 * @param {object} fields - card data object
 * @param {function} t - translate function
 * @returns {node} - html
 */
const SidebarCardLocation = ({ fields, t }) => {
  return (
    <div className="location-sidebar">
      <div className="sidebar-title">{t('location')}</div>

      <div className="address-container">
        {fields?.items?.jobLocation && (
          <div className="address">{fields?.items?.jobLocation}</div>
        )}
      </div>
    </div>
  );
};

SidebarCardLocation.defaultProps = {
  fields: {},
  t: () => {},
};

SidebarCardLocation.propTypes = {
  fields: PropTypes.shape({}),
  t: PropTypes.func,
};

export default withTranslation()(SidebarCardLocation);
