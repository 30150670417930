import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Link as JssLink,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';

import {
  showString,
  checkHref,
  checkJSSObject,
  checkLinkType,
} from '../../../../utils/helperUtils';
import './buttons.scss';

/**
 * @description - Button link.
 * @param {object} param0 - Input prams.
 * @returns {Node} - HTML node.
 */
const ButtonLink = ({
  ctaText,
  ctaUrl,
  ctaLabel,
  cssClass,
  externalLink,
  sitecoreContext,
  arrow,
  // cssArrowClass,
}) => {
  const isJSSObject = checkJSSObject(ctaUrl);
  const { pageEditing } = sitecoreContext;
  let linkVal = null;
  const isJSSText = ctaText && ctaText.hasOwnProperty('value');
  const isTextExists = isJSSText ? ctaText?.value : ctaText;
  const isHrefVlaue = isJSSObject ? checkHref(ctaUrl) : ctaUrl;
  const textVal = (
    <Fragment>
      {isJSSText ? <Text field={ctaText} /> : showString(ctaText)}
      {arrow && (
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          className="bi bi-arrow-right"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
          />
        </svg>
      )}
      {/* <div className={`arrow-icon ${cssArrowClass ? cssArrowClass : ''}`}></div> */}
    </Fragment>
  );
  const propVal = {
    className: `primary-button ${cssClass ? cssClass : ''}`,
    'aria-label': ctaLabel,
  };
  if ((isJSSObject && checkLinkType(ctaUrl)) || (!isJSSObject && !externalLink)) {
    linkVal = (
      <>
        <Link
          to={isHrefVlaue}
          {...propVal}
          target={ctaUrl?.value?.target || ctaUrl?.target}
        >
          {textVal}
        </Link>
      </>
    );
  } else {
    linkVal = (
      <JssLink field={ctaUrl} {...propVal} editable={false}>
        {textVal}
      </JssLink>
    );
  }
  return pageEditing || (isHrefVlaue && isTextExists) ? <>{linkVal}</> : null;
};

ButtonLink.propTypes = {
  cssClass: PropTypes.string.isRequired,
  ctaText: PropTypes.shape({ value: PropTypes.string }).isRequired,
  ctaUrl: PropTypes.shape({ href: PropTypes.string }).isRequired,
  ctaLabel: PropTypes.string,
  externalLink: PropTypes.bool,
  sitecoreContext: PropTypes.shape({
    pageEditing: PropTypes.bool,
  }),
  cssArrowClass: PropTypes.string,
};

ButtonLink.defaultProps = {
  ctaText: '',
  ctaUrl: {},
  ctaLabel: '',
  cssClass: '',
  externalLink: false,
  sitecoreContext: {},
  cssArrowClass: '',
  arrow: false,
};

export default withSitecoreContext()(ButtonLink);
