import { createGlobalStyle } from 'styled-components';

export const ButtonTheme = createGlobalStyle`
.primary-button {
    &.lightblue {
        border-color: ${({ theme }) => theme?.secondaryButtonLink};
        color: ${({ theme }) => theme?.secondaryButtonLink} !important;
        &:hover {
            background-color: ${({ theme }) => theme?.secondaryButtonLink};
        }
        svg.bi.bi-arrow-right{
            fill: ${({ theme }) => theme?.secondaryButtonLink} !important;
            width: 22px;
            height: 22px;
        }
    }
    &.reversed-white {
        &:hover {
            color: ${({ theme }) => theme?.secondaryButton} !important;
        }
    }
}
`;
