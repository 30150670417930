/* eslint-disable max-lines-per-function */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TextareaComplex from '../FormFields/Textarea';
import CheckboxGroup from '../FormFields/CheckboxGroup';
import RadioToggle from '../FormFields/RadioToggle';
import { optionsFormat } from '../../../../utils/datafactory';
import './screenerForm.scss';
import '../forms.scss';

/**
 * @description Screener Question Form.
 * @param {Object} props - Input props.
 * @returns {Node} HTML Form.
 */
const ScreenerQuestionsForm = (props) => {
  const {
    register,
    getValues,
    errors,
    Validations,
    formData,
    setValue,
    control,
  } = props;
  return (
    <Fragment>
      <form className="screenerForm">
        {formData?.map((data, index) => {
          switch (data?.editType) {
            case 'FreeText':
              return (
                <div key={index}>
                  <div className="section-heading">{data?.sectionHeading}</div>
                  <div className="question">
                    <div className="questionNumber">{`${data?.questionID}. `}</div>
                    <div className="questionText">&nbsp;{data?.question}</div>
                  </div>
                  <div className="formAnswer">
                    <TextareaComplex
                      name={(data?.questionID).toString()}
                      id={`freeText_${data?.questionID}`}
                      label={data?.question}
                      type={'textarea'}
                      rows={3}
                      key={data?.questionID}
                      isSearchEnabled={false}
                      onEnter={false}
                      dataValidations={Validations}
                      register={register}
                      customError={errors}
                      getValues={getValues}
                      setValue={setValue}
                    />
                  </div>
                </div>
              );
            case 'Radio Button':
              return (
                <div key={index}>
                  <div className="section-heading">{data?.sectionHeading}</div>
                  <div className="question">
                    <div className="questionNumber">{`${data?.questionID}. `}</div>
                    <div className="questionText">&nbsp;{data?.question}</div>
                  </div>
                  <div className="formAnswer radio">
                    <RadioToggle
                      name={data?.questionID}
                      label={data?.question}
                      id={`radio_${data?.questionID}`}
                      options={optionsFormat(data?.listofAnswers, 'radio')}
                      type="radio"
                      key={data?.questionID}
                      dataValidations={Validations}
                      register={register}
                      customError={errors}
                      toggleStyle={{
                        flexDirection:
                          data?.listofAnswers?.length <= 4 ? 'row' : 'column',
                      }}
                      getValues={getValues}
                    />
                  </div>
                </div>
              );
            case 'Single-Select':
              return (
                <div key={index}>
                  <div className="section-heading">{data?.sectionHeading}</div>
                  <div className="question">
                    <div className="questionNumber">{`${data?.questionID}. `}</div>
                    <div className="questionText">&nbsp;{data?.question}</div>
                  </div>
                  <div className="formAnswer singleSelect">
                    <RadioToggle
                      name={data?.questionID}
                      label={data?.question}
                      id={`singleSelect_${data?.questionID}`}
                      options={optionsFormat(data?.listofAnswers, 'singleSelect')}
                      type="radio"
                      key={data?.questionID}
                      dataValidations={Validations}
                      register={register}
                      customError={errors}
                      getValues={getValues}
                    />
                  </div>
                </div>
              );
            case 'Multi-Select':
              return (
                <div key={index}>
                  <div className="section-heading">{data?.sectionHeading}</div>
                  <div className="question">
                    <div className="questionNumber">{`${data?.questionID}. `}</div>
                    <div className="questionText">&nbsp;{data?.question}</div>
                  </div>
                  <div className="formAnswer multiselect">
                    <CheckboxGroup
                      name={`${data?.questionID}`}
                      id={`multiSelect_${data?.questionID}`}
                      options={data?.listofAnswers}
                      type="checkbox"
                      key={data?.questionID}
                      label={data?.question}
                      dataValidations={Validations}
                      // required={true}
                      register={register}
                      customError={errors}
                      getValues={getValues}
                      setValue={setValue}
                      control={control}
                    />
                  </div>
                </div>
              );
            case 'default':
              return;
          }
        })}
      </form>
    </Fragment>
  );
};

ScreenerQuestionsForm.defaultProps = {
  formData: [],
  Validations: [],
};

ScreenerQuestionsForm.propTypes = {
  formData: PropTypes.arrayOf(PropTypes.shape({})),
  Validations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getValues: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  control: PropTypes.func.isRequired,
};

export default ScreenerQuestionsForm;
