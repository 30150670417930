import { createGlobalStyle } from 'styled-components';

export const SearchIconStyles = createGlobalStyle`
.site-header {
    .site-header-bar {
        .main-nav {
            .main-nav-item {
                a {
                    span{
                        #search-icon {
                            margin-right: 5px;
                            fill: #000000;
                            stroke: #000000;
                            &:hover {
                                fill: ${({ theme }) => theme?.secondaryButton};
                                stroke: ${({ theme }) => theme?.secondaryButton};
                            }
                        }
                    }
                }
            }
        }
    }
}
`;
