import React from 'react';
import PropTypes from 'prop-types';

/**
 *
 * @param {*} param0
 */
const ButtonWithIcon = ({
  cssClass,
  text,
  handleButtonClick,
  actionParams,
  btnIcon,
  classArrow,
}) => (
  <>
    <button
      className={`primary-button btn-with-icon ${cssClass}`}
      onClick={handleButtonClick ? (e) => handleButtonClick(e, actionParams) : null}
    >
      {btnIcon && <div className={`button-icon ${btnIcon}`} />}
      {btnIcon ? <div>{text}</div> : text}
      {/* <div className="arrow-icon"></div> */}
      {classArrow && (
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          className="bi bi-arrow-right"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
          />
        </svg>
      )}
    </button>
  </>
);

ButtonWithIcon.defaultProps = {
  classArrow: true,
  cssClass: '',
  text: 'Button',
  handleButtonClick: null,
  actionParams: null,
  btnIcon: null,
};

ButtonWithIcon.propTypes = {
  /** Color class  */
  cssClass: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func,
  actionParams: PropTypes.shape({}),
  btnIcon: PropTypes.string,
};

export default ButtonWithIcon;
