import { createGlobalStyle } from 'styled-components';
export const PressReleaseStyle = createGlobalStyle`
.pressRelease-results {
    .pressRelease-item {
        .content {
            .buttonContainer {
                .downloadContainer {
                    .download_link {
                        color: ${({ theme }) => theme?.secondaryButton};
                    }
                }
            }
        }
    }
}
.pressRelease-pagination{
    .pressRelease-wrap{
        .page-list{
            .page-item{
                .page-link{
                    color: ${({ theme }) => theme?.secondaryButton};
                }
            }
        }
    }
}
`;
