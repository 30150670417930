/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './photoUpload.scss';
import EditPhoto from './EditPhoto';
import Modal from '../../../../core/Modal';
import Button from '../../../../core/globals/buttons/Button';
import { dataURLtoFile, isObjNotEmpty } from '../../../../../utils/helperUtils';
import { useDispatch, connect } from 'react-redux';
import { updateProPicApi } from '../../../../../services/apiServices/candidateService';
import { mediaApi } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

/**
 * @description - Photo Upload component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const PhotoUpload = (props) => {
  const { width, height, icon, profilePic, t } = props;
  const uploadIcon = icon?.value?.src ? mediaApi.updateImageUrl(icon.value.src) : '';
  const [proPic, setProPic] = useState(uploadIcon);
  const [photoUploaded, setPhotoUploaded] = useState();
  const [crop, setCrop] = useState('');
  const [photo, setPhoto] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const [showUpload, setShowUpload] = useState(true);
  const [sizeError, setSizeError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [name, setFileName] = useState('');
  const dispatch = useDispatch();
  const validFileTypes = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'bmp'];

  const checkTypeValid = (extn) => validFileTypes.includes(extn.toLowerCase());

  const checkSizeValid = (size) => size < 2000000;

  useEffect(() => {
    if (profilePic?.data) setProPic(profilePic?.data);
  }, [profilePic]);

  /**
   * @description - On Photo Upload.
   * @param {Object} props - Event.
   */
  const handleImageUpload = (e) => {
    setSizeError(false);
    setTypeError(false);
    if (e?.target?.files[0]) {
      const extn = e?.target?.files[0].name.split('.').pop();
      const fileSize = e?.target?.files[0].size;
      if (checkTypeValid(extn)) {
        if (checkSizeValid(fileSize)) {
          setDisabled(false);
          setShowUpload(false);
          const url = URL.createObjectURL(e?.target?.files[0]);
          setFileName(e?.target?.files[0].name);
          setPhoto(url);
          setShowModal(true);
          setEdit(true);
        } else {
          setSizeError(true);
        }
      } else {
        setTypeError(true);
      }
    }
  };

  /**
   * @description - On Photo Crop.
   * @param {Object} props - Image.
   */
  const onCrop = (data) => {
    setCrop(data);
  };

  /**
   * @description - Handle Modal Close.
   */
  const handleModal = () => {
    setShowModal(false);
    setPhoto('');
    setEdit(false);
  };

  /**
   * @description - Save Profile Picture
   */
  const setProfilePic = () => {
    if (crop === '' || crop == undefined) {
      setProPic(uploadIcon);
      updateProPicApi([], '', dispatch);
    } else {
      const canvasScaled = crop.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      const file = dataURLtoFile(croppedImg, name);
      const data = new FormData();
      data.append('uploadedfile', file);
      updateProPicApi(data, croppedImg, dispatch);
      setProPic(croppedImg);
    }
    setShowModal(false);
    setEdit(false);
  };

  /**
   * @description - Open Modal
   */
  const openModal = () => {
    setShowModal(true);
    setDisabled(true);
    setShowUpload(true);
    setSizeError(false);
    setTypeError(false);
    if (proPic !== uploadIcon) {
      if (proPic !== '') {
        setPhotoUploaded(proPic);
        setShowUpload(false);
      }
    }
  };

  const openOnFocus = (e) => {
    if (e && (e.keyCode === 13 || e.keyCode === 32)) {
      openModal();
    }
  };
  /**
   * @description - Get background style
   */
  const getBgSize = () => {
    return proPic === uploadIcon || !proPic ? 'auto' : 'cover';
  };

  /**
   * @description - Remove pic
   */
  const removePic = () => {
    setDisabled(false);
    setShowUpload(true);
    setPhotoUploaded('');
    setPhoto('');
    setCrop('');
    setEdit(false);
  };
  return (
    <>
      <div
        className="photo-container"
        role="button"
        style={{
          backgroundImage: `url(${proPic})`,
          backgroundSize: getBgSize(),
        }}
        onClick={openModal}
        tabIndex={0}
        onKeyUp={openOnFocus}
        aria-label="Upload Photo"
        aria-description="Pop-up box opens"
      ></div>
      <Modal showModal={showModal} handleModal={handleModal} size="xl">
        <div className="err-container-pic" key="header">
          <>
            {sizeError ? <div>{t('pro-pic-size-error-msg')}</div> : null}
            {typeError ? <div>{t('pro-pic-format-error-msg')}</div> : null}
          </>
        </div>
        <div className="avatar-crop-container" key="body">
          <EditPhoto
            width={width}
            height={height}
            photoUploaded={photoUploaded}
            handleImageUpload={handleImageUpload}
            removePic={removePic}
            onCrop={onCrop}
            photo={photo}
            getBgSize={getBgSize}
            edit={edit}
            uploadIcon={uploadIcon}
            showUpload={showUpload}
            t={t}
          />
        </div>
        <div className="modal-button-container" key="footer">
          <Button
            handleButtonClick={handleModal}
            text={t('cancel')}
            cssClass="cancel-button"
          ></Button>
          <Button
            handleButtonClick={setProfilePic}
            text={t('save-photo')}
            isDisabled={isDisabled}
          ></Button>
        </div>
      </Modal>
    </>
  );
};

PhotoUpload.defaultProps = {
  width: 200,
  height: 200,
};

PhotoUpload.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    profilePic: state.profilePicReducer,
  };
};

export default withTranslation()(connect(mapStateToProps)(PhotoUpload));
