import React from 'react';
import PropTypes from 'prop-types';

import './boundaries.scss';

/**
 *
 * @param {*} param0
 */
const Loader = ({
  loaderMsg,
  loaderWithMsg = false,
  customLoaderStyle = {},
  customTextStyle = {},
}) =>
  loaderWithMsg ? (
    <div className="container-fluid customLoader">
      <div className="row justify-content-center">
        <div className="spinner-border" style={customLoaderStyle} role="status">
          <span className="sr-only">{loaderMsg}</span>
        </div>
      </div>
      <div className="row justify-content-center" style={customTextStyle}>
        <strong>{loaderMsg}</strong>
      </div>
    </div>
  ) : (
    <div className="container-fluid text-center loader">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">{loaderMsg}</span>
      </div>
    </div>
  );

Loader.propTypes = {
  loaderMsg: PropTypes.string,
};

Loader.defaultProps = {
  loaderMsg: 'Loading...',
};

export default Loader;
