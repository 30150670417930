import React from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

import MediaCard from '../../Cards/MediaCard';
import Slider from 'react-slick';
import { isDataExists } from '../../../../utils/helperUtils';

import '../cardscroll.scss';

const settings = {
  dots: true,
  // infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  infinite: false,
  arrows: false,
  accessibility: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1.1,
        slidesToScroll: 1,
      },
    },
  ],
};

/**
 * @description - Card Scroll case studies component.
 * @param {object} props - Input props.
 * @returns {Node} - HTML section.
 */
const MediaCardScroll = (props) => {
  const { cssClass, fields, t, sitecoreContext } = props;
  const { items } = fields;
  const checkItemExist = isDataExists(items);
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;

  return (
    <>
      {(checkItemExist || isEditing) && (
        <section
          className={`card-scroll media-card-scroll ${cssClass} ${
            checkItemExist ? '' : 'no-padding'
          }`}
        >
          <div className="container">
            <div className="card-scroll-header">
              <div className="title" role="heading" aria-labelledby="job-resources">
                {t('picture-slider-title')}
              </div>
            </div>
            <Slider {...settings}>
              {items?.map((item, idx) => {
                const itemVal = item || {};
                return (
                  <MediaCard
                    key={`card_${idx}`}
                    src={itemVal?.src}
                    isVideo={item?.isVideo}
                  />
                );
              })}
            </Slider>
          </div>
        </section>
      )}
    </>
  );
};

MediaCardScroll.defaultProps = {
  fields: {
    items: [],
  },
  cssClass: '',
  t: () => {},
  sitecoreContext: {},
};

MediaCardScroll.propTypes = {
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  cssClass: PropTypes.string,
  t: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({
    pageEditing: PropTypes.bool,
  }).isRequired,
};

export default withTranslation()(withSitecoreContext()(MediaCardScroll));
