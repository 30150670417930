import { createGlobalStyle } from 'styled-components';

export const SubMenuStyle = createGlobalStyle`
.site-header {
    .main-nav-item {
        .nav-sub-menu {
            .main-nav-sub-menu {
            .sub-nav-menu-item {
                a{
                    &.sub-menu-active {
                        text-shadow: 0px 0px 1px ${({ theme }) =>
                          theme?.secondaryButton};
                        color: ${({ theme }) => theme?.secondaryButton};
                      }
                }
                &.parent-sub-nav {
                    a {
                        color: ${({ theme }) => theme?.headingSecondary};
                        &.sub-menu-active {
                            text-shadow: 0px 0px 1px ${({ theme }) =>
                              theme?.secondaryButton};
                            color: ${({ theme }) => theme?.secondaryButton};
                        }
                    }
                }
            }
        }
    }
}
`;
