/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ProfileItemTemplate from './Template/ProfileItem';
import { missingInfo } from './Template/MissingInfoTemplate';

/**
 * @description - Experience component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const Experience = ({ fields, profileInfo, t, componentName, getEmptyFields }) => {
  const { items } = fields;
  const [newFieldArr, setFieldArr] = useState([]);
  const [data, setData] = useState([]);
  const [candidateId, setCandidateId] = useState('');

  useEffect(() => {
    if (profileInfo?.experienceInfo) {
      setData(profileInfo.experienceInfo?.data);
      setCandidateId(profileInfo?.data?.id);

      if (items.length > 0 && profileInfo.experienceInfo?.formCount > 0) {
        setFieldArr(new Array(profileInfo.experienceInfo?.formCount).fill(items[0]));
      }
    }
  }, [profileInfo]);

  useEffect(() => {
    let experienceInfoData = profileInfo?.experienceInfo?.data;
    let expFieldItems = items;
    if (expFieldItems.length > 0 && profileInfo?.experienceInfo?.formCount > 0) {
      expFieldItems = new Array(profileInfo?.experienceInfo?.formCount).fill(
        items[0]
      );
    }
    let emptyCount = 0;
    let emptyObj = {};
    let array = [];
    if (experienceInfoData && experienceInfoData?.length === 0) {
      emptyCount = expFieldItems.length;
    } else {
      expFieldItems?.map((item, index) => {
        array.push(missingInfo(item, experienceInfoData?.[index]));
      });
      array.map((item) => {
        Object.keys(item).map((k) => {
          if (k === 'emptyCount') {
            emptyCount = emptyCount + item[k];
          }
        });
      });
    }
    emptyObj[componentName] = emptyCount;
    getEmptyFields(emptyObj);
  }, [profileInfo]);

  return (
    <div className="accordion-container-block">
      {data.length > 0 ? (
        newFieldArr.map((block, index) => (
          <ProfileItemTemplate
            data={data[index]}
            candidateId={candidateId}
            block={block}
            filterRequired={true}
            t={t}
            cssClass="exp_section"
          />
        ))
      ) : (
        <div className="no-content-data">{t('no-experience-data')}</div>
      )}
    </div>
  );
};

Experience.defaultProps = {
  fields: {},
  t: (val) => val,
};

Experience.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
  };
};

export default withTranslation()(connect(mapStateToProps)(Experience));
