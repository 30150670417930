import { PUBLIC_POSITION } from '../actions/actionTypes';

const initialState = {
  data: {},
};

const publicPositionReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUBLIC_POSITION.SAVE:
      return {
        ...state,
        data: action.payload,
      };
    case PUBLIC_POSITION.CLEAR:
      return {
        ...state,
        data: {},
      };
    default:
      return state;
  }
};
export default publicPositionReducer;
