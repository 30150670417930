/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../globals/buttons/Button';
import { withTranslation } from 'react-i18next';
import { formObjIteration } from '../../../business/Utils/helper';
import Textbox from './Textbox';
import SelectComplex from './SelectComplex';
import CheckboxGroup from './CheckboxGroup';
import { getValidationDatabyType } from '../DynamicForm/helper/filter';
import lget from 'lodash.get';
import '../forms.scss';
/**
 * @description - SkillsPicker component.
 * @param {Object} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const SkillsPicker = (props) => {
  const {
    optionData,
    setValue,
    subType,
    name,
    getValues,
    requiredValidation,
    t,
    cssClass,
    titleText,
    customError,
    register,
    id,
    label,
    dataValidations,
  } = props;

  const [skillSet, setSkillSet] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const fieldError = lget(customError, name + '_hidden');
  const requiredData =
    requiredValidation?.length > 0
      ? requiredValidation[0]
      : getValidationDatabyType(dataValidations, 'required');
  let param = { shouldValidate: true, shouldDirty: true };

  useEffect(() => {
    const primarySkills = optionData?.primaryOptions;
    const secondarySkills = optionData?.secondaryOptions;
    if (primarySkills && primarySkills !== null) {
      setSelectedSkills(primarySkills);
      setSkillSet(primarySkills);
      setValue(name + '_hidden', primarySkills, param);
    }
    if (secondarySkills && secondarySkills !== null) {
      setSkillSet(secondarySkills);
      setValue(name + '_hidden', null, param);
    }
  }, [optionData]);

  const onSubmit = () => {
    let data = getValues(`${name}_picker`);
    if (data) {
      let formValue = getValues(name) || [];
      let selectedItem =
        Object.keys(data).length > 0 && Object.keys(data).includes('value')
          ? data.value
          : data;
      selectedItem = selectedItem.split().filter((item) => item !== '');
      if (
        selectedItem.length > 0 &&
        !skillSet.some((r) => selectedItem.indexOf(r) >= 0)
      ) {
        let newSelectedSkill = [...new Set([...formValue, ...selectedItem])];
        setSelectedSkills(newSelectedSkill);
        setSkillSet([...new Set([...skillSet, ...selectedItem])]);
        setValue(name + '_hidden', newSelectedSkill, param);
      }
    }
    setValue(`${name}_picker`, null);
  };

  const updateDefaultOptions = (values) => {
    setSelectedSkills(values);
    setValue(name + '_hidden', values, param);
  };

  const getBlockComponent = () => {
    switch (subType) {
      case 'Text':
        return (
          <Textbox
            {...props}
            name={`${name}_picker`}
            requiredValidation={[]}
            cssClass={selectedSkills.length === 0 && cssClass}
          />
        );
      case 'TypeaheadDropdown':
        return (
          <SelectComplex
            {...props}
            name={`${name}_picker`}
            requiredValidation={[]}
            cssClass={selectedSkills.length === 0 && cssClass}
          />
        );
    }
  };

  return (
    <section className="form-block">
      <div className="personal-info-form">
        <form>
          <div className="appflow profile-skills">
            {skillSet && skillSet.length > 0 && (
              <>
                <div className="suggestedSkills">{titleText}</div>

                <div className="skills-list">
                  <CheckboxGroup
                    {...props}
                    name={name}
                    id={`${id}_checkbox`}
                    options={skillSet}
                    defaultOptions={selectedSkills}
                    type="checkbox"
                    key={`${id}_checkbox`}
                    label=""
                    updateDefaultOptions={updateDefaultOptions}
                    isPicker
                    requiredValidation={[]}
                  />
                </div>
              </>
            )}
            <div className="add-skills form-block complex">
              <div className="dynamicForm">
                {selectedSkills && getBlockComponent()}
              </div>
              <div className="add-btn">
                <Button
                  cssClass="blue"
                  text={t('add-skill')}
                  handleButtonClick={onSubmit}
                />
              </div>
            </div>
          </div>
          <input
            type="hidden"
            name={`${name}_hidden`}
            defaultValue={selectedSkills}
            ref={
              register
                ? register({
                    required: requiredData?.fields?.value?.value || false,
                  })
                : () => {}
            }
          />
          {fieldError && requiredData?.fields?.value?.value && (
            <div className="error-msg" id={`err_${id}`} role="alert">
              {fieldError.type === 'required'
                ? requiredData?.fields?.message?.value
                  ? requiredData?.fields?.message?.value.replace(
                      '{0}',
                      label?.replace('*', '')
                    )
                  : t('mandatory-field-message')
                : fieldError?.message.replace('{0}', label?.replace('*', ''))}
            </div>
          )}
        </form>
      </div>
    </section>
  );
};

SkillsPicker.defaultProps = {
  updateSelectedSkills: () => {},
  onSubmit: () => {},
  handleChange: () => {},
  items: [],
  titleText: '',
  cssClass: '',
  optionData: [],
};

SkillsPicker.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  titleText: PropTypes.string.isRequired,
  cssClass: PropTypes.string.isRequired,
  optionData: PropTypes.arrayOf(
    PropTypes.shape({
      primaryOptions: PropTypes.arrayOf(PropTypes.string),
      secondaryOptions: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(SkillsPicker);
