import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AccordionSection from './AccordionSection';

/**
 * @description - Accordion Block component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const AccordionBlock = (props) => {
  props.data.forEach((child) => {
    if (child.isOpen) {
      test[child.id] = true;
    }
  });

  const [openSections, setOpenSession] = useState({});

  const handleClick = (id) => {
    const isOpen = !!openSections[id];

    if (props.allowMultipleOpen) {
      const newSection = {
        [id]: !isOpen,
      };
      setOpenSession({
        ...openSections,
        ...newSection,
      });
    } else {
      setOpenSession({
        [id]: !isOpen,
      });
    }
    props.handleAccordionClick(`accordion${id}`);
  };

  return (
    <div role="list">
      {props?.data?.map((child) => (
        <AccordionSection
          isOpen={!!openSections[child.id]}
          key={child.id}
          id={child.id}
          title={child.fields.title}
          content={child.fields.content}
          onClick={() => handleClick(child.id)}
        />
      ))}
    </div>
  );
};

AccordionBlock.defaultProps = {
  allowMultipleOpen: false,
  data: [],
};

AccordionBlock.propTypes = {
  allowMultipleOpen: PropTypes.bool,
  data: PropTypes.instanceOf(Object).isRequired,
};

export default AccordionBlock;
