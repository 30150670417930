import { PROFILE_PIC } from '../actions/actionTypes';

const initialState = {
  data: '',
};
const profilePicReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_PIC.GET:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
export default profilePicReducer;
