/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Line } from 'rc-progress';
import { withTranslation } from 'react-i18next';
import ButtonLink from '../../../../core/globals/link/ButtonLink';
import '../../apply_job_flow.scss';
import PrivateRoute from '../../../../core/PrivateRoute';
import ProgressBar from '../../../../core/ProgressBar';
import { withTheme } from 'styled-components';

/*
NewProfile
  "fields": {
    "title": "Increase your matches by 30%",
    "percentage": 0,
    "text": "Continue building your profile to find more opportunities.",
    "buttonText": "BUILD YOUR PROFILE",
    "link": "/en"
  }
PartiallyComplete
  "fields": {
    "title": "Almost there! Your profile is (_)% complete.",
    "percentage": 85,
    "text": "A more complete profile can increase your matches by 30%",
    "buttonText": "UPDATE YOUR PROFILE",
    "link": "/en"
  }
CompletedProfile
  "fields": {
    "title": "Do you need to update your profile?",
    "percentage": 100,
    "text": "Check your profile regularly in the dashboard to make sure it’s up to date.",
    "buttonText": "",
    "link": ""
  }

*/

/**
 * @description - Success component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML template.
 */
const JobApplySuccessProfile = ({ fields, t, theme }) => {
  const { text, title, percentage, buttonText, link } = fields;
  const newProfileTemplate = (
    <div className="build-profile">
      <div className="titleText">{title}</div>
      <div className="subText">{text}</div>
      {buttonText && (
        <div className="btnContainer">
          <ButtonLink
            ctaText={buttonText}
            ctaUrl={{ href: link, linktype: 'internal' }}
            arrow
          />
        </div>
      )}
    </div>
  );
  const partiallyCompleteProfileTemplate = () => {
    let titleText = title?.replace('(_)', percentage);
    return (
      <div className="build-profile">
        <div className="titleText">{titleText}</div>
        <div className="col-lg-6 customStyle">
          <ProgressBar
            completed={percentage}
            primaryColor={theme?.gradient}
            secondaryColor={theme?.primaryColor}
            gradient
          />
        </div>
        <div className="tipText">
          <strong>{t('tip')}&nbsp;</strong>
          <span>{text}</span>
        </div>
        {buttonText && (
          <div className="btnContainer">
            <ButtonLink
              ctaText={buttonText}
              ctaUrl={{ href: link, linktype: 'internal' }}
              arrow
            />
          </div>
        )}
      </div>
    );
  };
  const completedProfileTemplate = (
    <div className="build-profile">
      <div className="titleText">{title}</div>
      <div className="subText">{text}</div>
      {buttonText && (
        <div className="btnContainer">
          <ButtonLink
            ctaText={buttonText}
            ctaUrl={{ href: link, linktype: 'internal' }}
            arrow
          />
        </div>
      )}
    </div>
  );

  const getSuccessTemplate = () => {
    if (percentage === 0) return newProfileTemplate;
    if (percentage >= 100) return completedProfileTemplate;
    else return partiallyCompleteProfileTemplate();
  };
  return (
    <div className="create-profile-success-block col-lg-6 offset-lg-3">
      {Object.keys(fields).length > 0 && getSuccessTemplate()}
    </div>
  );
};

JobApplySuccessProfile.propTypes = {
  fields: PropTypes.shape({
    text: PropTypes.shape({
      value: PropTypes.string,
    }),
  }).isRequired,
  t: PropTypes.func.isRequired,
};

JobApplySuccessProfile.defaultProps = {
  fields: {},
};

export default withTheme(withTranslation()(JobApplySuccessProfile));
