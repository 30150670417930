/**
 * @description Function to restructure Object in required format.
 * @param {Object} obj - Object.
 * @returns {Object} - Resturctured Object.
 */
export const mergeCommonObjects = (obj, separator = '.') => {
  const newObj = {};
  let a, i, j, k, o, p, q;

  for (i in obj) {
    a =
      separator === '.'
        ? i?.match(/([^.]+)(\[[^\[\]]+[^\]])*?/g)
        : i?.match(/([^_]+)(\[[^\[\]]+[^\]])*?/g);
    p = obj[i];
    j = a.length;
    while (j--) {
      q = {};
      q[a[j]] = p;
      p = q;
    }
    // merge object
    k = Object.keys(p)[0];
    o = newObj;
    while (k in o) {
      p = p[k];
      o = o[k];
      k = Object.keys(p)[0];
    }
    o[k] = p[k];
  }
  return newObj;
};

/**
 * @description - Convert single object to array of objects.
 * @param {Object} data - Input prop Object.
 * @returns {Array} - Array of objects.
 */
export const objectToArray = (data) => {
  const objs = [];
  const obj = { ...data };
  Object.keys(obj).forEach(function (key) {
    const match = key.match(/(.*)(\d.*)$/);
    const newKey = match[1];
    const index = parseInt(match[2]) - 1;
    objs[index] = objs[index] || {};
    objs[index][newKey] = obj[key];
  });
  return objs;
};

/**
 * @description - Convert array of objects to single object.
 * @param {Array} data - Input prop Array of objects.
 * @returns {Object} - Single object.
 */
export const arrayToObject = (data = []) => {
  const newObj = {};
  data.map((obj, index) => {
    Object.keys(obj).forEach(function (key) {
      newObj[key + (index + 1)] = obj[key];
    });
  });
  return newObj;
};

/**
 * @description - Function to flatten an object.
 * @param {Array} obj - Nested object.
 * @param {string} prefix - Prefix for object key.
 * @param {string} suffix - Suffix for object key.
 * @returns {Object} - Flattened object.
 */
export const flattenObject = (obj = {}, prefix = '', suffix = '') =>
  Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? `${prefix}.` : '';
    if (
      typeof obj[k] === 'object' &&
      !Array.isArray(obj[k]) &&
      obj[k] !== null &&
      Object.keys(obj[k]).length > 0
    )
      Object.assign(acc, flattenObject(obj[k], pre + k));
    else acc[pre + k + suffix] = obj[k];
    return acc;
  }, {});

/**
 * @description - Function to flatten an object.
 * @param {Array} options - Nested object.
 * @param {string} type - Prefix for object key.
 * @param {boolen} isChecked - Prefix for object key.
 * @returns {Object} - Flattened object.
 */
export const optionsFormat = (options = [], type, isChecked = false) => {
  const formattedOptions = [];
  options.map((opt) => {
    const tempObj = {};
    tempObj.id = `${type}_${opt}`;
    tempObj.text = opt;
    tempObj.value = opt;
    tempObj.disabled = false;
    tempObj.isChecked = isChecked;
    formattedOptions.push(tempObj);
  });
  return formattedOptions;
};
