/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { checkArray } from '../../../utils/helperUtils';

/**
 * @description - Modal Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const ModalComponent = (props) => {
  const { showModal, handleModal, size, className } = props;

  const handleClose = () => {
    handleModal();
  };

  const getComponent = (key) => {
    return Array.isArray(props.children)
      ? props.children.filter((comp) => {
          return comp.key === key;
        })
      : props.children.key === key && props.children;
  };

  return (
    <Modal
      {...props}
      show={showModal}
      onHide={handleClose}
      size={size}
      dialogClassName={className}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton style={{ border: 'none' }}>
        {getComponent('header')}
      </Modal.Header>
      <Modal.Body
        id="contained-modal-title-vcenter"
        closeButton
        style={{ border: 'none' }}
      >
        {getComponent('body')}
      </Modal.Body>
      <Modal.Footer closeButton style={{ border: 'none' }}>
        {getComponent('footer')}
      </Modal.Footer>
    </Modal>
  );
};

ModalComponent.propTypes = {
  children: PropTypes.node.isRequired,
  showModal: PropTypes.bool.isRequired,
  size: PropTypes.string,
  handleModal: PropTypes.func,
  className: PropTypes.string,
};

ModalComponent.defaultProps = {
  children: null,
  PropTypes: false,
  size: 'lg', //sm, lg, xl
  className: '',
};

export default ModalComponent;
