import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DATE_FORMATS } from '../../../../utils/enums';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext, mediaApi } from '@sitecore-jss/sitecore-jss-react';

const JobDetailsList = ({ ...props }) => {
  const {
    t,
    mediaIcons,
    datePosted,
    applyBy,
    formOfEmployment,
    jobType,
    domain,
    experience,
    eduation,
    salaryRate,
    sitecoreContext,
    noOfPositions,
    hourPerWeek,
    // education,
    // headerDetails,
    // referenceNumber,
    // companyName,
    // companyLogo,
    // jobAdvertisementTeaser,
    // openingParagraph,
    // publicDescription,
    // companyDescription,
  } = props;
  let {
    iconDomian,
    iconHourPerWeek,
    // iconJobType,
    iconjobDetailsType,
    iconPositions,
    iconPublishedDate,
    iconSalaryRate,
    iconEducation,
    // iconjobID,
  } = {};

  const dateFormat =
    sitecoreContext?.Country?.dateFormat || DATE_FORMATS.ROBOTS_FORMAT_REV;

  /**
   * @description to set social share icons
   * @returns {undefined} -  no returns
   */
  const setIcons = () => {
    if (Array.isArray(mediaIcons)) {
      mediaIcons.forEach((iconItem) => {
        switch (iconItem?.itemName) {
          case 'domain':
            iconDomian = iconItem?.icon || '';
            break;
          case 'hourPerWeek':
            iconHourPerWeek = iconItem?.icon || '';
            break;
          case 'jobTypeDetails':
            iconjobDetailsType = iconItem?.icon || '';
            break;
          case 'noOfPositions':
            iconPositions = iconItem?.icon || '';
            break;
          case 'publishfromDate':
            iconPublishedDate = iconItem?.icon || '';
            break;
          case 'salaryRate':
            iconSalaryRate = iconItem?.icon || '';
            break;
          case 'education':
            iconEducation = iconItem?.icon || '';
            break;
          // case 'jobID':
          //   iconjobID = iconItem.icon;
          //   break;
          default:
            break;
        }
      });
    }
  };

  setIcons();

  /**
   * @description validate apply by date
   * @param {string} applyBy - apply by date
   * @returns {boolean} - boolean
   */
  const validateApplyDate = (applyBy) => {
    return applyBy && applyBy !== '0001-01-01T00:00:00' ? true : false;
  };

  return (
    <ul className="job-details-list">
      {datePosted && (
        <li>
          <div className="job-details-text">
            <img src={mediaApi.updateImageUrl(iconPublishedDate)} alt=" " />
            <strong>{`${t('posted')}:`} </strong>
            {`${moment(datePosted).format(dateFormat)}`}
          </div>
        </li>
      )}
      {validateApplyDate(applyBy) && (
        <li>
          <div className="job-details-text">
            <img src={mediaApi.updateImageUrl(iconPublishedDate)} alt=" " />
            <strong>{`${t('apply-by')}: `} </strong>
            {`${moment(applyBy).format(dateFormat)}`}
          </div>
        </li>
      )}
      {formOfEmployment && (
        <li>
          <div className="job-details-text">
            <img src={mediaApi.updateImageUrl(iconjobDetailsType)} alt=" " />
            <strong>{`${t('form-of-employment')}: `} </strong>
            {formOfEmployment}
          </div>
        </li>
      )}
      {salaryRate && (
        <li>
          <div className="job-details-text">
            <img src={mediaApi.updateImageUrl(iconSalaryRate)} alt=" " />
            <strong>{`${t('rate')}: `} </strong> {salaryRate}
          </div>
        </li>
      )}
      {jobType && (
        <li>
          <div className="job-details-text">
            <img src={mediaApi.updateImageUrl(iconHourPerWeek)} alt=" " />
            <strong>{`${t('job-type')}: `} </strong>
            {jobType}
          </div>
        </li>
      )}
      {domain && (
        <li>
          <div className="job-details-text">
            <img src={mediaApi.updateImageUrl(iconDomian)} alt=" " />
            <strong>{`${t('industry')}: `} </strong>
            {domain}
          </div>
        </li>
      )}
      {experience && (
        <li>
          <div className="job-details-text">
            <img src={mediaApi.updateImageUrl(iconPositions)} alt=" " />
            <strong>{`${t('experience-level')}: `} </strong>
            {experience}
          </div>
        </li>
      )}
      {eduation && (
        <li>
          <div className="job-details-text">
            <img src={mediaApi.updateImageUrl(iconEducation)} alt=" " />
            <strong>{`${t('education')}: `} </strong>
            {eduation}
          </div>
        </li>
      )}
      {noOfPositions && (
        <li>
          <div className="job-details-text">
            <img src={mediaApi.updateImageUrl(iconPositions)} alt=" " />
            <strong>{`${t('position ')}: `} </strong>
            {noOfPositions}
          </div>
        </li>
      )}
      {hourPerWeek && (
        <li>
          <div className="job-details-text">
            <img src={mediaApi.updateImageUrl(iconHourPerWeek)} alt=" " />
            <strong>{`${t('hour-per-week ')}: `} </strong>
            {hourPerWeek}
          </div>
        </li>
      )}
      {/* {referenceNumber && (
        <li>{`${t('reference-number')}: ${referenceNumber}`}</li>
      )} */}
    </ul>
  );
};

JobDetailsList.defaultProps = {
  items: [],
};

JobDetailsList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.string,
    })
  ).isRequired,
};

export default withSitecoreContext()(withTranslation()(JobDetailsList));
