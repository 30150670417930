import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '../globals/link/ButtonLink';
import './diamondpagebanner.scss';
import { getImg } from '../../../utils/helperUtils';
import { mediaApi, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
/**
 * @description - TripleImageDiamondPageBanner.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template.
 */
const TripleImageDiamondPageBanner = (props) => {
  const { fields } = props;
  const {
    title,
    text,
    ctaText,
    ctaUrl,
    backgroundTop,
    backgroundMain,
    backgroundBottom,
    mobileBanner,
  } = fields;

  return (
    <section className="triple-image banner-section stats diamond about">
      <Image classname="mobile" field={mobileBanner} />
      <div className="white-bg"></div>
      <ul className="diamond-grid">
        <li className="item2 dark"></li>
        <li className="item1">
          <div
            className="bg"
            style={{
              backgroundImage: `url(${mediaApi.updateImageUrl(
                getImg(backgroundTop)
              )})`,
            }}
          ></div>
        </li>
        <li className="item2">
          <div
            className="bg"
            style={{
              backgroundImage: `url(${mediaApi.updateImageUrl(
                getImg(backgroundMain)
              )})`,
            }}
          ></div>
        </li>
        <li className="item3">
          <div
            className="bg"
            style={{
              backgroundImage: `url(${mediaApi.updateImageUrl(
                getImg(backgroundBottom)
              )})`,
            }}
          ></div>
        </li>
      </ul>
      <div className="container">
        <div className="row in-left">
          <div className="col-xl-6 col-lg-6 col-md-12">
            <RichText tag="h1" className="reversed" field={title} />
            <RichText tag="p" className="large reversed" field={text} />
            <ButtonLink
              ctaText={ctaText}
              ctaUrl={ctaUrl}
              cssClass="reversed"
              arrow
            />
          </div>
        </div>
      </div>
    </section>
  );
};

TripleImageDiamondPageBanner.defaultProps = {
  fields: {
    title: { value: '' },
    text: { value: '' },
    ctaText: { value: '' },
    ctaUrl: { value: '' },
    backgroundTop: { value: { src: '' } },
    backgroundMain: { value: { src: '' } },
    backgroundBottom: { value: { src: '' } },
    mobileBanner: { value: { src: '' } },
  },
};

TripleImageDiamondPageBanner.propTypes = {
  fields: PropTypes.shape({
    text: PropTypes.shape({ value: PropTypes.string.isRequired }),
    title: PropTypes.shape({ value: PropTypes.string.isRequired }),
    ctaText: PropTypes.shape({ value: PropTypes.string }),
    ctaUrl: PropTypes.shape({ value: PropTypes.object }),
    backgroundTop: PropTypes.shape({ value: PropTypes.object.isRequired }),
    backgroundMain: PropTypes.shape({ value: PropTypes.object.isRequired }),
    backgroundBottom: PropTypes.shape({ value: PropTypes.object.isRequired }),
    mobileBanner: PropTypes.shape({ value: PropTypes.object.isRequired }),
  }),
};

export default TripleImageDiamondPageBanner;
