/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { showString } from '../../../../utils/helperUtils';

const Checkbox = ({
  id,
  name,
  text,
  required,
  register,
  errors,
  handleChange,
  isHideField,
  showBasedOnFieldName,
  watch,
  customLabel,
  errorMsg,
  t,
}) => {
  const showField = watch ? watch(showBasedOnFieldName) : true;
  return (
    <div
      className={`form-block checkbox ${isHideField && !showField ? 'hide' : ''}`}
    >
      <input
        type="checkbox"
        name={name}
        id={id}
        onChange={handleChange}
        ref={
          register
            ? register({
                required: (!isHideField || showField) && required ? required : false,
              })
            : () => {}
        }
      />
      {customLabel ? (
        <label className={errors && errors[name] && 'error'} id={id}>
          {customLabel}
          {!customLabel && <span className="sr-only">Checkbox Label</span>}
        </label>
      ) : (
        <label className={errors && errors[name] && 'error'} htmlFor={id}>
          {text?.value ? (
            <RichText field={text} editable={false} />
          ) : (
            showString(text)
          )}
          {!text && <span className="sr-only">Checkbox Label</span>}
        </label>
      )}
      {errors && errors[name] && (
        <div className="error-msg">{errorMsg || t('required-field')}</div>
      )}
    </div>
  );
};

Checkbox.defaultProps = {
  id: 'id',
  text: '',
  customLabel: '',
  handleChange: () => {},
  errorMsg: '',
  t: () => {},
};

Checkbox.propTypes = {
  /** Field identifier  */
  id: PropTypes.string.isRequired,

  /** Label text */
  text: PropTypes.string.isRequired,
  customLabel: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Checkbox);
