import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import moment from 'moment';
import ButtonLink from '../globals/link/ButtonLink';
import { DATE_FORMATS } from '../../../utils/enums';
import { CardStyle } from './CardTheme';

/**
 *
 * @param {*} param0
 */
const CardJob = ({
  title,
  subTitle,
  location,
  date,
  ctaText,
  ctaUrl,
  t,
  sitecoreContext,
}) => {
  const dateFormat = sitecoreContext?.Country?.dateFormat || DATE_FORMATS.LOCALE;
  return (
    <>
      <CardStyle />
      <div className="card job">
        <div className="card-body">
          <div className="sub-title">{subTitle}</div>
          <div className="title reversed">{title}</div>
          <div className="location">{location}</div>
          <div className="date">
            {t('posted')} {moment(date).format(dateFormat)}
          </div>
        </div>
        <div className="btn-wrapper">
          <ButtonLink
            ctaText={ctaText}
            cssClass="lightblue"
            ctaUrl={ctaUrl}
            ctaLabel={`${title} ${ctaText}`}
          />
        </div>
      </div>
    </>
  );
};

CardJob.defaultProps = {
  subTitle: '',
  title: '',
  location: '',
  date: 'January 1, 1001',
  ctaText: 'View Job',
  ctaUrl: '#',
};

CardJob.propTypes = {
  subTitle: PropTypes.oneOf(['permanent', 'contract']),
  title: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.string.isRequired,
};

export default withSitecoreContext()(withTranslation()(CardJob));
