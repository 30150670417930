import { createGlobalStyle } from 'styled-components';

export const ErrorTheme = createGlobalStyle`
.alert-inline {
    &.error {
        color: ${({ theme }) => theme?.ErrorColor};
    }
    &.success {
        color: ${({ theme }) => theme?.SuccessColor};
    }
  }
`;
