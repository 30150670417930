import React from 'react';
import PropTypes from 'prop-types';
import CardConnector from './CardConnector';
import Slider from 'react-slick';
import '../cardscroll.scss';

/**
 * @description ConnectorCardScroll
 * @param {*} props - props
 * @returns {node} html
 */
const ConnectorCardScroll = (props) => {
  const {
    fields,
    rendering: { uid },
  } = props;

  /**
   * @description Slide load hook.
   * @returns {undefined}
   */
  const slideLoadHook = () => {
    if (typeof window !== 'undefined') {
      document.querySelectorAll(`.scroll_${uid} .slick-slide`).forEach((node) => {
        if (node.className.includes('slick-active')) {
          node.style.visibility = '';
          node.setAttribute('aria-hidden', 'false');
        } else {
          node.style.visibility = 'hidden';
          node.setAttribute('aria-hidden', 'true');
        }
      });
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    accessibility: true,
    lazyLoad: true,
    arrows: true,
    onLazyLoad: () => slideLoadHook(),
    afterChange: () => slideLoadHook(),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 564,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className={`card-scroll connector connector-card-scroll scroll_${uid}`}>
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="card-scroll-header">
              <div className="title">{fields?.title?.value}</div>
            </div>
            <Slider {...settings}>
              {fields?.items?.map((item) => (
                <CardConnector
                  key={item?.fields?.title}
                  title={item?.fields?.title}
                  img={item?.fields?.image}
                  text={item?.fields?.text}
                  ctaText={item?.fields?.ctaText}
                  ctaUrl={item?.fields?.ctaUrl}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

ConnectorCardScroll.defaultProps = {
  fields: {
    title: '',
    items: [],
  },
};

ConnectorCardScroll.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({
      value: PropTypes.string,
    }),
    ctaText: PropTypes.shape({
      value: PropTypes.string,
    }),
    ctaUrl: PropTypes.shape({
      value: PropTypes.shape({}),
    }),
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  rendering: PropTypes.shape({ uid: PropTypes.string }).isRequired,
};

export default ConnectorCardScroll;
