import { createGlobalStyle } from 'styled-components';

export const FooterStyles = createGlobalStyle`
.site-footer {
    .title{
        color:${({ theme }) => theme?.primaryButton} !important
    }
    .footer-nav {
        a{
            &.sub-title {
                color:${({ theme }) => theme?.secondaryButtonLink}
            }
        }
    }
    .footer-disclaimer {
        color: ${({ theme }) => theme?.footerText};
        background-color: ${({ theme }) => theme?.footerBackground}
    }
    .footer-disclaimer-links{
        a, button {
            color: ${({ theme }) => theme?.footerText} !important;

        }
    }
    #ot-sdk-btn.ot-sdk-show-settings{
        border: none;
    color: ${({ theme }) => theme?.primaryColor};
    background-color: transparent;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    padding-left: 5px;
    text-transform: Uppercase;
    }
    #ot-sdk-btn.ot-sdk-show-settings:hover{
        color: ${({ theme }) => theme?.primaryColor};
        background-color: transparent;
    }
    .otbtncontainer.title {
        padding-top:24px
    }
    .social-icons {
        margin-left: 3px !important;
    }
}
`;
