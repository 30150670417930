/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import JobsForm from '../PageBanners/BannerJobs/JobsForm';
import ButtonLink from '../globals/link/ButtonLink';
import {
  withSitecoreContext,
  isExperienceEditorActive,
  RichText,
  mediaApi,
} from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import './homeblocks.scss';
import { showString, getImg } from '../../../utils/helperUtils';

/**
 * @description - HomePageBanner component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const HomePageBanner = (props) => {
  const { fields, sitecoreContext, params } = props;
  const {
    title,
    text,
    ctaText,
    ctaUrl,
    findJobsCtaText,
    backgroundImage,
    t = () => {},
  } = fields;

  const { mode = '' } = params;
  const brand_name = sitecoreContext?.Country?.brandName?.toLowerCase() || 'experis';
  const banner_mode =
    typeof params?.mode !== 'undefined' ? showString(mode).toLowerCase() : 'b2c';

  const PageBanner = () => {
    return (
      <section
        className={`home-banner-block banner-section standard ${brand_name} ${banner_mode}`}
      >
        <div class="home-block active">
          <div
            className="banner-image"
            style={{
              backgroundImage: `url(${mediaApi.updateImageUrl(
                getImg(backgroundImage)
              )})`,
            }}
          ></div>
          <div className="content-wrapper animating-in">
            <div className="rectangle"></div>
            <div className="rectangle2"></div>
            <div className="container">
              <div className="row">
                <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12 content">
                  <h1 class="title">{title?.value}</h1>
                  <RichText field={text} tag="p" className="large" />
                  {banner_mode === 'b2c' && (
                    <div className="form">
                      {isExperienceEditorActive() ? (
                        <div>{t('job-search-experience-editor-preview')}</div>
                      ) : (
                        <JobsForm
                          ctaText={findJobsCtaText?.value}
                          bannerMode={banner_mode}
                        />
                      )}
                    </div>
                  )}
                  {banner_mode === 'b2b' && ctaUrl && (
                    <div className="cta">
                      <ButtonLink
                        cssClass="find-job-btn"
                        ctaText={ctaText}
                        ctaUrl={ctaUrl}
                        arrow
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  return sitecoreContext?.route?.name.toLowerCase() === 'home' ? (
    PageBanner()
  ) : (
    <div class="homepage">{PageBanner()}</div>
  );
};

HomePageBanner.defaultProps = {
  fields: {
    title: '',
    text: '',
    // namePlaceHolder: '',
    // locationPlaceHolder: '',
    findJobsCtaText: '',
    ctaText: '#',
    ctaUrl: '',
    // submitUrl: '/',
    backgroundImage: '',
  },
  params: { mode: { value: 'b2c' } },
};

HomePageBanner.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    //namePlaceHolder: PropTypes.string.isRequired,
    //locationPlaceHolder: PropTypes.string.isRequired,
    findJobsCtaText: PropTypes.string.isRequired,
    ctaText: PropTypes.string,
    ctaUrl: PropTypes.string,
    //submitUrl: PropTypes.string.isRequired,
    backgroundImage: PropTypes.string.isRequired,
  }),
  params: PropTypes.shape({
    mode: {
      value: PropTypes.oneOf(['b2c', 'b2b']),
    },
  }),
};

export default withTranslation()(withSitecoreContext()(HomePageBanner));
