/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import lget from 'lodash.get';
import { withTranslation } from 'react-i18next';
import {
  patternRegexCheck,
  validateDNI,
  symbolValidationCheck,
} from '../DynamicForm/helper/validationFactory';
import { truncate } from '../../../../utils/helperUtils';
import { getValidationDatabyType } from '../DynamicForm/helper/filter';
import { checkValueTypeAndGetTheCount } from '../../../business/Utils/helper';

/**
 * @description - Dynamic Textbox component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template for dashboard.
 */
const Textbox = ({
  id,
  name,
  label,
  placeholder,
  requiredValidation = [],
  type,
  icon,
  customStyle,
  readOnly,
  customError,
  hintText,
  register,
  dataValidations,
  connectorField,
  connectorType,
  getValues,
  setValue,
  watch,
  showBasedOnFieldName,
  hideBasedOnFieldName,
  isHideField,
  value,
  validateOnLoad,
  trimLabelCharacters,
  formName,
  t,
}) => {
  const fieldError = lget(customError, name);
  const patternData = getValidationDatabyType(dataValidations, 'pattern');
  const requiredData =
    requiredValidation?.length > 0
      ? requiredValidation[0]
      : getValidationDatabyType(dataValidations, 'required');
  let param = readOnly
    ? { shouldValidate: true }
    : { shouldValidate: true, shouldDirty: true };
  param = validateOnLoad ? param : {};
  const maximumData = getValidationDatabyType(dataValidations, 'maximum');
  const minimumData = getValidationDatabyType(dataValidations, 'minimum');
  const dniValidator = getValidationDatabyType(dataValidations, 'dni');
  const [selectedValue, setSelectedValue] = useState();
  const [filledCls, setFilledCls] = useState('');
  const [showField, setShowField] = useState(true);
  const [hideField, setHideField] = useState(false);
  const connectorFormName = formName
    ? `${formName}[${connectorField}]`
    : connectorField;
  const showFieldName = formName
    ? `${formName}[${showBasedOnFieldName}]`
    : showBasedOnFieldName;
  const hideFieldName = formName
    ? `${formName}[${hideBasedOnFieldName}]`
    : hideBasedOnFieldName;

  const showFieldValue = showFieldName ? watch(showFieldName) : null;
  const hideFieldValue = hideFieldName ? watch(hideFieldName) : null;

  useEffect(() => {
    if (showFieldValue) {
      let isFieldHasVal = checkValueTypeAndGetTheCount(showFieldValue);
      setShowField(isFieldHasVal);
    }
  }, [showFieldValue]);

  useEffect(() => {
    if (hideFieldValue) {
      let isFieldHasVal = checkValueTypeAndGetTheCount(hideFieldValue);
      setHideField(isFieldHasVal);
    }
  }, [hideFieldValue]);

  useEffect(() => {
    let newValue = value !== null ? value : '';
    setValue(name, newValue, param);
    setSelectedValue(newValue);
  }, [value]);

  useEffect(() => {
    if ((getValues && getValues(name)) || value) {
      setFilledCls('filled');
    } else {
      setFilledCls('');
    }
  }, [getValues(name), value]);

  const onFocusHandler = () => {
    setFilledCls('filled');
  };
  const onBlurHandler = () => {
    if ((getValues && getValues(name)) || value) {
      setFilledCls('filled');
    } else {
      setFilledCls('');
    }
  };

  const dataValidator = (value) => {
    let result = true;
    const connectoreFieldsValue = connectorFormName
      ? getValues(connectorFormName)
      : null;
    if (value && connectoreFieldsValue) {
      result = symbolValidationCheck(value, connectoreFieldsValue, connectorType);
    }
    return result;
  };

  return (
    <div
      className={`form-block complex ${fieldError ? 'error withoutBg' : ''} ${
        isHideField || !showField || hideField ? 'hide' : ''
      }`}
      style={customStyle}
    >
      <input
        className={filledCls}
        name={name}
        id={id}
        type={type}
        autoComplete="off"
        readOnly={readOnly}
        defaultValue={selectedValue}
        aria-required={requiredData?.fields?.value?.value}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        {...(fieldError
          ? { 'aria-invalid': true, 'aria-describedby': `err_${id}` }
          : '')}
        {...((getValues && getValues(name)) || value
          ? {}
          : { 'aria-label': `${label} edit blank` })}
        onChange={(e) => {
          setValue(name, e.target.value, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }}
        ref={
          register
            ? register({
                required:
                  ((!isHideField || !showField || hideField) &&
                    requiredData?.fields?.value?.value) ||
                  false,
                validate: {
                  dateValidator: (value) =>
                    (connectorField ? dataValidator(value) : true) ||
                    connectorMessage ||
                    t('validation-failed'),
                  dniValidationCheck: (value) =>
                    (dniValidator?.fields?.value?.value === 'true' ||
                    dniValidator?.fields?.value?.value === true
                      ? validateDNI(value)
                      : true) ||
                    dniValidator?.fields?.message?.value ||
                    t('validation-failed'),
                },
                maxLength: {
                  value: maximumData?.fields?.value?.value || null,
                  message:
                    maximumData?.fields?.message?.value || t('validation-failed'),
                },
                minLength: {
                  value: minimumData?.fields?.value?.value || null,
                  message:
                    minimumData?.fields?.message?.value || t('validation-failed'),
                },
                pattern: {
                  value: patternData?.fields?.value?.value
                    ? patternRegexCheck(patternData.fields.value.value)
                    : '',
                  message:
                    patternData?.fields?.message?.value || t('validation-failed'),
                },
              })
            : () => {}
        }
      />

      {label && (
        <label htmlFor={id}>
          <div className="label-text">
            {icon && (
              <div className="input-icon">
                <span className="input-icon"></span>
              </div>
            )}
            {truncate(label, trimLabelCharacters)}
          </div>
        </label>
      )}
      {fieldError && (
        <div className="error-msg" id={`err_${id}`} role="alert">
          {fieldError.type === 'required'
            ? requiredData?.fields?.message?.value
              ? requiredData?.fields?.message?.value?.replace(
                  '{0}',
                  label?.replace('*', '')
                )
              : t('mandatory-field-message').replace('{0}', label?.replace('*', ''))
            : fieldError?.message?.replace('{0}', label?.replace('*', ''))}
        </div>
      )}
      {hintText && <div className="hintText">{hintText}</div>}
    </div>
  );
};

Textbox.defaultProps = {
  id: '',
  name: '',
  label: '',
  placeholder: '',
  type: 'text',
  icon: '',
  readOnly: false,
  setValue: () => {},
  trimLabelCharacters: 25,
  t: () => {},
};

Textbox.propTypes = {
  id: PropTypes.string.isRequired,
  t: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  icon: PropTypes.string,
  readOnly: PropTypes.bool,
  setValue: PropTypes.func,
  trimLabelCharacters: PropTypes.number,
};

export default withTranslation()(Textbox);
