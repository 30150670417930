import React from 'react';
import PropTypes from 'prop-types';
import TextLink from '../globals/link/TextLink';
import ButtonLink from '../globals/link/ButtonLink';
//import mockData from './mockData';
import { setAltUrlForImg } from '../../../utils/helperUtils';
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import './featurette.scss';
//import { Colors } from '../../../utils/enums';
/**
 * @description - featurette component;
 * @param {object}  props - Input props;
 * @returns{Node} - return html block;
 */
const Featurette = (props) => {
  const { fields } = props; //mockData;<div className="col-lg-4 offset-lg-1"> commented to increse the image size (sprint4)

  const background_color =
    fields.backgroundColor?.value === 'GREY' ? 'grey' : 'white';
  const externalLinkType =
    fields?.ctaUrl?.value?.linktype === 'external' ? true : false;
  const backgroundImg = fields.backgroundImage?.value?.src
    ? fields.backgroundImage?.value?.src
    : '';
  return (
    <div
      className={`featurette-section ${fields.mode?.value} ${background_color}`}
      style={{
        backgroundImage: backgroundImg ? 'url(' + backgroundImg + ')' : 'none',
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="featurette-imgs">
              <div className="blurred">
                <Image field={setAltUrlForImg(fields.image, ' ')} />
              </div>
              <Image field={setAltUrlForImg(fields.image, ' ')} />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="featurette-body">
              <RichText tag="h2" className="title" field={fields.title} />
              {fields.nameText && (
                <RichText tag="div" className="name" field={fields.nameText} />
              )}
              {fields.subTitle && (
                <RichText
                  tag="div"
                  className="light heading heading-6"
                  field={fields.subTitle}
                />
              )}
              <RichText tag="p" className="large" field={fields.text} />
              {fields.mode?.value === 'standard' || fields.mode?.value === '' ? (
                <ButtonLink
                  cssClass="orange-sd"
                  ctaText={fields?.ctaText}
                  ctaUrl={fields?.ctaUrl}
                  externalLink={externalLinkType}
                />
              ) : (
                <TextLink
                  ctaText={fields?.ctaText?.value}
                  ctaUrl={fields?.ctaUrl?.value}
                  externalLink={externalLinkType}
                  arrow
                />
              )}
              <div className="quote"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Featurette.defaultProps = {
  fields: {
    title: {},
    subTitle: {},
    nameText: {},
    text: {},
    image: { value: { src: 'http://via.placeholder.com/300', alt: 'img' } },
    altText: {},
    ctaText: {},
    ctaUrl: {},
    backgroundColor: {},
    backgroundimage: {},
    mode: { value: 'standard' },
  },
};

Featurette.propTypes = {
  fields: PropTypes.shape({
    title: {
      value: PropTypes.string,
    },
    subTitle: {
      value: PropTypes.string,
    },
    nameText: {
      value: PropTypes.string,
    },
    text: {
      value: PropTypes.string,
    },
    image: {
      value: PropTypes.string,
    },
    altText: {
      value: PropTypes.string,
    },
    ctaText: {
      value: PropTypes.string,
    },
    ctaUrl: {
      value: PropTypes.string,
    },
    backgroundColor: {
      value: PropTypes.string,
    },
    backgroundImage: {
      value: PropTypes.string,
    },
    mode: {
      value: PropTypes.oneOf(['standard', 'profile', 'quote']),
    },
  }),
};

export default Featurette;
