import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '../../core/globals/pagination/Pagination';

const PressReleasePagination = ({
  handlePageChange,
  totalRecords,
  pageLimit,
  selectedPage,
  label,
}) => {
  return (
    <section className="pressRelease-pagination">
      <div className="container">
        <div className="pressRelease-wrap">
          <Pagination
            totalRecords={totalRecords}
            pageLimit={pageLimit}
            onPageChanged={handlePageChange}
            selectedPage={selectedPage}
            label={label}
          ></Pagination>
        </div>
      </div>
    </section>
  );
};

PressReleasePagination.defaultProps = {
  handlePageChange: () => {},
  totalRecords: 0,
  pageLimit: 0,
  selectedPage: 1,
  label: 'Page',
};

PressReleasePagination.propTypes = {
  handlePageChange: PropTypes.func,
  totalRecords: PropTypes.number,
  pageLimit: PropTypes.number,
  selectedPage: PropTypes.number,
  label: PropTypes.string,
};

export default PressReleasePagination;
