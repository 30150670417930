/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { attachementDownloadApi } from '../../../../../services/apiServices/candidateService';
import { filterArrayByType, isNullorEmpty } from '../../../../../utils/helperUtils';
import lget from 'lodash.get';
import parse from 'html-react-parser';
import ProfileItemTemplate from './Template/ProfileItem';
import { missingInfo } from './Template/MissingInfoTemplate';

/**
 * @description - Resume component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const Resume = ({ profileInfo, t, fields, getEmptyFields, componentName }) => {
  const { items } = fields;

  const [data, setData] = useState([]);
  const [candidateId, setCandidateId] = useState('');
  const [selectedId, setSelectedId] = useState([]);
  const [noData, showNoData] = useState(false);

  useEffect(() => {
    let personalDetailsData = profileInfo?.data?.personalDetails;
    setData(profileInfo?.data?.personalDetails);
    setCandidateId(profileInfo?.data?.id);
    let temp = 0;
    let emptyCount = 0;
    let emptyObj = {};
    let array = [];
    items?.map((item, index) => {
      array.push(missingInfo(item, personalDetailsData, true));
    });
    array.map((item) => {
      Object.keys(item).map((k) => {
        if (k === 'emptyCount') {
          emptyCount = emptyCount + item[k];
        } else if (k === 'temp') {
          temp = temp + item[k];
        }
      });
    });
    emptyObj[componentName] = emptyCount;
    getEmptyFields(emptyObj);
    if (temp === 0) {
      showNoData(true);
    }
  }, [profileInfo]);

  return (
    <div className="dashboard-block file-container">
      {data &&
        Object.keys(data).length > 0 &&
        items?.map((block, i) => (
          <ProfileItemTemplate
            data={data}
            candidateId={candidateId}
            block={block}
            filterRequired={true}
            t={t}
          />
        ))}
      {noData && <div className="no-content-data">{t('no-resume-data')}</div>}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer,
  };
};

export default connect(mapStateToProps)(Resume);
