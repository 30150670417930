import React from 'react';
import TextLink from '../globals/link/TextLink';
import PropTypes from 'prop-types';

import { setAltUrlForImg, showString } from '../../../utils/helperUtils';
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import './cards.scss';
/**
 * @description - cardinfoblock component
 * @param {object} props  - input props
 * @returns{Node} - HTML block;
 */
const CardInfo = ({ title, text, icon, ctaText, ctaUrl, mode, readMoreColour }) => {
  return (
    <div className={`card ${mode}`}>
      <div className="img-container">
        <Image className="infoIcon" field={setAltUrlForImg(icon, ' ')} />
      </div>
      <div className="card-wrapper">
        <div className="card-body">
          {title?.value ? (
            <RichText tag="div" className="title" field={title} />
          ) : (
            <div className="title">{showString(title)}</div>
          )}
          <RichText tag="p" field={text} />
        </div>
        {ctaUrl && (
          <div>
            <span className="align-bottom">
              <TextLink
                //cssClass="link-blue"
                cssClass={`link-${readMoreColour.toLowerCase()}`}
                ctaText={ctaText}
                ctaUrl={ctaUrl}
                arrow
              />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

CardInfo.defaultProps = {
  // fields: {
  title: '',
  text: '',
  mode: '',
  icon: '',
  altText: '',
  ctaText: 'Read more',
  ctaUrl: '#',
  readMoreColour: 'blue',
  // },
};

CardInfo.propTypes = {
  title: {
    value: PropTypes.string,
  },
  text: {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  },
  altText: {
    value: PropTypes.string,
  },
  mode: {
    value: PropTypes.oneOf(['dark', 'light']),
  },
  ctaText: {
    value: PropTypes.string,
  },
  ctaUrl: {
    value: PropTypes.string,
  },
  readMoreColour: {
    value: PropTypes.string,
  },
  icon: {
    value: PropTypes.string.isRequired,
  },
};

export default CardInfo;
