/* eslint-disable require-jsdoc */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Switch, Route, Link } from 'react-router-dom';
import {
  withSitecoreContext,
  Image,
  withPlaceholder,
} from '@sitecore-jss/sitecore-jss-react';
import { useDispatch } from 'react-redux';

import SiteLogo from '../../../assets/images/site-logo.png';
import {
  checkArray,
  checkRouteField,
  checkUrl,
  setAltUrlForImg,
  pushNavToDataLayer,
} from '../../../utils/helperUtils';
import MenuLink from '../globals/MenuLink';

import { setShowLogin } from '../../../redux/actions/loginActions';
import HamburgerMenuItems from './HamburgerMenu/HamburgerMenuItems';
import HeaderSubMenu from '../HeaderSubMenu';

import './siteheader.scss';
import './siteheadercountryselector.scss';

/**
 * @description - Get Hamburger Icon
 * @param {boolean} menuOpen - Menu open state.
 * @param {Function} setMenuOpen - Setter for menu open state
 * @returns {Node} - Humbuger menu html.
 */
const HamburgerIcon = ({ menuOpen, setMenuOpen }) => {
  /**
   * @description - Handle menu toggle.
   * @param {object} event - Event object.
   * @returns {undefined} - Sets menu state.
   */
  const handleMenuToggle = (event) => {
    event.preventDefault();
    if (
      event.target.className.includes('hamburger-menu-toggle') ||
      event.target.className.includes('line1') ||
      event.target.className.includes('line2') ||
      event.target.className.includes('line3')
    ) {
      setMenuOpen(!menuOpen);
    }
  };

  /**
   * @description - Accessibility: Action to toggle menu close/open on click of enter button.
   * @param {object} e - Event object.
   * @returns {undefined}
   */
  const onEnter = (e) => {
    if (e && (e.keyCode === 13 || e.keyCode === 32)) {
      setMenuOpen(!menuOpen);
    }
  };

  return (
    <div className="main-nav-item hamburger">
      <div
        className={`hamburger-menu-toggle ${menuOpen ? 'active' : ''}`}
        role="button"
        tabIndex={0}
        aria-expanded={menuOpen}
        aria-label="Hamburger Menu"
        onClick={handleMenuToggle}
        onKeyUp={onEnter}
      >
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
      {/* <div className="search-icon"></div> */}
    </div>
  );
};

HamburgerIcon.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  setMenuOpen: PropTypes.func.isRequired,
};

/**
 * @description - Set sticky header states.
 * @param {boolean} menuOpen - Menu open state.
 * @param {boolean} isEditing - Is in editor window.
 * @returns {undefined}
 */
const setStickyHeader = (menuOpen, isEditing) => {
  const bodyElement = document.querySelector('body');

  if (!isEditing) {
    let lastScrollTop = 0;
    const stickyHeader = document.querySelector('.site-header');

    window.addEventListener(
      'scroll',
      function () {
        const st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > lastScrollTop) {
          stickyHeader.classList.remove('sticky');
        } else {
          stickyHeader.classList.add('sticky');
        }
        lastScrollTop = st <= 0 ? 0 : st;
      },
      false
    );
  }

  if (menuOpen) {
    bodyElement.classList.add('menu-open');
  } else {
    bodyElement.classList.remove('menu-open');
  }
};

/**
 * @description - Get full route items.
 * @param {Array} routes - Array of routes.
 * @returns {Array} - Final route array.
 */
const getFullRouteData = (routes) => {
  let finalRoute = [];
  checkArray(routes).map((r) => {
    finalRoute = finalRoute.concat(...checkRouteField(r));
  });
  return finalRoute;
};

/**
 * @description - Check whether the current link is active.
 * @param {string} url - Current url.
 * @param {string} currentPath - Active Path.
 * @param {string} sitecoreRoute - Route from sitecore context.
 * @returns {boolean} - Returns whether the link is active or not.
 */
const isActiveLink = (url, currentPath, sitecoreRoute) => {
  if (url && currentPath) {
    const urlVal = url.split('?')[0];
    const urlLower = urlVal.toLowerCase();
    const pathLower = currentPath.toLowerCase();
    const sitecoreLower = `/${sitecoreRoute && sitecoreRoute.toLowerCase()}`;
    return (
      urlLower === pathLower ||
      pathLower.startsWith(urlLower) ||
      sitecoreLower.startsWith(urlLower)
    );
  }
};

/**
 * @description - SiteHeader component.
 * @param {Object} props - Input Props.
 * @returns {Node} - Header HTML.
 */
// eslint-disable-next-line max-lines-per-function
const SiteHeader = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { fields, sticky, sitecoreContext, notFixed } = props;
  const { location, match } = props;
  const { searchTabs, topMenuComps } = props;
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';
  const { logo, mobileLogo } = fields;
  const placeholders = sitecoreContext?.route?.placeholders;
  const common = placeholders && placeholders['jss-common'];
  const { items = [] } = (common && common[0]?.fields) || {};
  const { showLogin, rightComponents = [] } = props;
  const logoVal = setAltUrlForImg(logo, 'Site Logo') || {
    value: { src: SiteLogo, alt: 'Site Logo' },
  };
  const dispatch = useDispatch();
  const fullRoutes = getFullRouteData(items);
  const baseUrl = sitecoreContext?.language ? `/${sitecoreContext?.language}` : '/';

  useEffect(() => {
    setStickyHeader(menuOpen, isEditing);
  });

  useEffect(() => {
    dispatch(setShowLogin({ showLogin }));
  }, [showLogin, dispatch]);

  return (
    <Fragment>
      <div className="bg-blackout"></div>
      <header
        className={`site-header ${sticky ? 'sticky' : ''} ${
          menuOpen ? 'active' : ''
        } ${notFixed || isEditing ? 'not-fixed' : ''}`}
      >
        <div className="container-xl">
          <a
            href="#main"
            className="sr-only skip-to-main-content"
            aria-label="skip to the main content"
          >
            skip to the main content
          </a>
          <div className="row site-header-bar">
            <div className="col-auto">
              <Link
                to={baseUrl}
                aria-label="Go to Home"
                onClick={() => pushNavToDataLayer('Top Nav', 'logo')}
              >
                <Image
                  field={logoVal}
                  style={{
                    width: logoVal?.value?.width
                      ? `${logoVal?.value?.width}px`
                      : '105px',
                    maxWidth: '185px',
                  }}
                  className={`site-logo ${brandName}`}
                />
                {/* <img src={logoVal.value.src} alt="Site logo" className="site-logo" /> */}
              </Link>
            </div>
            <div className="col">
              <nav className="main-nav" aria-label="Main Navigation">
                {checkArray(fullRoutes).map((route, index) => {
                  const { name } = route;
                  const { url, title } = route.fields || { title: {}, url: {} };
                  const isRouteActive = isActiveLink(
                    checkUrl(route),
                    location.pathname,
                    match?.params?.sitecoreRoute
                  );
                  if (checkArray(route.fields.items).length) {
                    return (
                      <HeaderSubMenu
                        menuItem={route.fields}
                        key={`${name}_${index}`}
                        isParentActive={isRouteActive}
                      />
                    );
                  }
                  return (
                    <div className="main-nav-item" key={`${name}_${index}`}>
                      <MenuLink
                        url={url}
                        routeName={title}
                        activeLinkClass={isRouteActive ? 'header-link-active' : ''}
                        clickHandler={pushNavToDataLayer}
                        actionParams={['Top Nav', title?.value]}
                      />
                    </div>
                  );
                })}
                {topMenuComps.map((component) => component)}
                <HamburgerIcon menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                <Switch>
                  {checkArray(fullRoutes).map((route, index) => {
                    return (
                      <Route
                        path={checkUrl(route)}
                        key={`${route.name}_${index}`}
                      ></Route>
                    );
                  })}
                </Switch>
                {rightComponents.map((component) => {
                  return component?.props?.rendering?.componentName ===
                    'ShowCountryLinksManpower'
                    ? component
                    : '';
                })}
              </nav>
            </div>
          </div>
        </div>
        <HamburgerMenuItems
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          items={items}
          logo={mobileLogo}
          rightComponents={rightComponents}
          searchTabs={searchTabs}
          brandName={brandName}
          baseUrl={baseUrl}
        />
      </header>
    </Fragment>
  );
};

SiteHeader.defaultProps = {
  sticky: true,
  loggedIn: false,
  notFixed: false,
  loginFormSubmitUrl: '/',
  fields: {
    items: [],
    logo: {
      value: {
        src: SiteLogo,
      },
    },
  },
  sitecoreContext: {
    Country: {
      brandName: '',
    },
  },
  showLogin: true,
  rightComponents: [],
  searchTabs: [],
  topMenuComps: [],
};

SiteHeader.propTypes = {
  rightComponents: PropTypes.arrayOf(PropTypes.shape({})),
  /** Sets the navigation to be visible/fixed. */
  sticky: PropTypes.bool,

  /** Sets the state of the user dropdown */
  loggedIn: PropTypes.bool,

  /** Removes the fixed position. Should not be used outside of Storybook. */
  notFixed: PropTypes.bool,
  loginFormSubmitUrl: PropTypes.string,
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    logo: PropTypes.shape({}),
    mobileLogo: PropTypes.shape({}),
  }),
  sitecoreContext: PropTypes.shape({
    pageEditing: PropTypes.bool,
    Country: PropTypes.shape({
      brandName: PropTypes.string,
    }),
  }),
  showLogin: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({}).isRequired,
  searchTabs: PropTypes.arrayOf(PropTypes.node),
  topMenuComps: PropTypes.arrayOf(PropTypes.node),
};

const containerComp = withSitecoreContext()(
  withPlaceholder([
    { placeholder: 'jss-right', prop: 'rightComponents' },
    { placeholder: 'jss-content', prop: 'searchTabs' },
    { placeholder: 'jss-left', prop: 'topMenuComps' },
  ])(withRouter(SiteHeader))
);

export default containerComp;
