/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '../globals/link/ButtonLink';
import {
  RichText,
  mediaApi,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { getImg } from '../../../utils/helperUtils';
import './page-banners.scss';
//import mockBannerData from './mockBannerData';

const OverlayPageBanner = ({ fields, sitecoreContext }) => {
  //fields = Object.entries(fields).length ? fields : mockBannerData;
  const { title, subTitle, text, ctaText, ctaUrl, backgroundImage } = fields;
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';
  return (
    <section
      className={`banner-section stats overlay-banner ${brandName}`}
      style={{
        backgroundImage: `url(${mediaApi.updateImageUrl(getImg(backgroundImage))})`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-8 white">
            {subTitle?.value && (
              <RichText tag="h5" className="sub-title" field={subTitle} />
            )}
            {title?.value && (
              <RichText
                tag="h2"
                className="title"
                aria-describedby="section-description"
                field={title}
              />
            )}
            {text?.value && (
              <RichText
                tag="p"
                className="large"
                id="section-description"
                field={text}
              />
            )}
            {ctaUrl && (
              <ButtonLink
                ctaText={ctaText}
                ctaUrl={ctaUrl}
                cssClass="orange"
                arrow
              />
            )}
          </div>
        </div>
      </div>
    </section>
    /*
    <section class="banner-section stats overlay" style="background-image: url({{ module.hero_banner.src }});">
      <div class="container">
        <div class="row">
          <div class="col-xl-5 col-lg-6 white">
            <div class="title">{{ module.sub_title }}</div>
            <h1>{{ module.title }}</h1>
            <p class="large">{{ module.description }}</p>
            {% if module.cta_url.href %}
            <a href="{{ module.cta_url.href }}" class="primary-button" aria-label="">{{ module.cta_text }}<div class="arrow-icon"></div>
              {% endif %}
            </a>
          </div>
        </div>
      </div>
    </section>
    */
  );
};

OverlayPageBanner.defaultProps = {
  fields: {
    title: { value: '' },
    subTitle: { value: '' },
    text: { value: '' },
    ctaText: { value: '' },
    ctaUrl: {
      value: null,
    },
    backgroundImage: {
      value: {
        src: 'http://via.placeholder.com/1440x583/FEFEFE/EEEEEE',
      },
    },
  },
  sitecoreContext: {},
};

OverlayPageBanner.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({ value: PropTypes.string.isRequired }),
    subTitle: PropTypes.shape({ value: PropTypes.string.isRequired }),
    text: PropTypes.shape({ value: PropTypes.string.isRequired }),
    ctaText: PropTypes.shape({ value: PropTypes.string }),
    ctaUrl: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
      }),
    }),
    backgroundImage: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }),
    }),
  }),
  sitecoreContext: PropTypes.shape({}),
};

export default withSitecoreContext()(OverlayPageBanner);
