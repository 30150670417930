import React from 'react';
import PropTypes from 'prop-types';

import ModalComponent from '../Modal';
import Button from '../../core/globals/buttons/Button';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import './boundaries.scss';

/**
 * @description - to show inline alert
 * @param {string} message - message to show
 * @param {string} type - type of alert
 * @returns {node} - html
 */
const AlertPopUp = ({
  message,
  visibility,
  handleModal,
  callback,
  btnOkText,
  btnCancelText,
  sitecoreContext,
}) => {
  /**
   * @description to handle alert pop up interaction
   * @param {*} action - true/false
   * @returns {undefined} - no retunrs
   */
  const handleAction = (action) => {
    handleModal();
    if (action === true && callback) {
      callback();
    }
  };
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';
  return (
    <ModalComponent showModal={visibility} handleModal={handleModal} size="md">
      <div className="alert-popup-body" key="body">
        <p>{message}</p>
      </div>
      <div key="footer" className="alert-popup-footer">
        <div className="footer-items">
          {btnOkText && (
            <Button
              text={btnOkText}
              handleButtonClick={() => handleAction(true)}
              cssClass={`btn-save ${brandName === 'stegplus' ? 'blue' : ''}`}
            />
          )}
        </div>
        <div className="footer-items">
          {btnCancelText && (
            <Button
              text={btnCancelText}
              handleButtonClick={() => handleAction(false)}
              cssClass={`btn-save ${brandName === 'stegplus' ? 'blue' : ''}`}
            />
          )}
        </div>
      </div>
    </ModalComponent>
  );
};

AlertPopUp.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(['error', 'success', '']),
  sitecoreContext: PropTypes.shape({}),
};

AlertPopUp.defaultProps = {
  message: 'Some Error occurred.',
  type: '',
  sitecoreContext: {},
};

export default withSitecoreContext()(AlertPopUp);
