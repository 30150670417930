import { LANGUAGE } from '../actions/actionTypes';
// import config from './../../temp/config';

const initialState = {
  language: null,
};

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGE.UPDATE:
      return {
        ...state,
        language: action.lang,
      };
    default:
      return state;
  }
};
export default languageReducer;
