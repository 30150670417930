/*eslint-disable*/
import moment from 'moment';

export const symbolValidationCheck = (data1, data2, type) => {
  let result = true;
  switch (type) {
    case '=':
      result = data1 === data2;
      break;
    case '<':
      result = data1 < data2;
      break;
    case '>':
      result = data1 > data2;
      break;
    case '<=':
      result = data1 <= data2;
      break;
    case '>=':
      result = data1 >= data2;
      break;
    default:
      break;
  }
  return result;
};

export const dateValiditionCheck = (date, data) => {
  if (date.length !== 10) return false;
  const dateVar = date.split('/');
  const dateVal = new Date(dateVar[1] + '/' + dateVar[0] + '/' + dateVar[2]);
  const isValid = moment(dateVal).isValid();
  const formattedInputDate = moment(dateVal, 'DD/MM/YYYY');
  const formattedCurrentDate = moment(new Date(), 'DD/MM/YYYY').startOf('day');
  if (isValid) {
    let result = true;
    switch (data?.fields?.value?.value) {
      case '=':
        result = formattedInputDate.isSame(formattedCurrentDate);
        break;
      case '<':
        result = formattedInputDate.isBefore(formattedCurrentDate);
        break;
      case '>':
        result = formattedInputDate.isAfter(formattedCurrentDate);
        break;
      default:
        break;
    }
    return result;
  }
  return isValid;
};

const parseString = (string) => {
  var obj = {};
  var arr = string.split('||');
  for (var x = 0; x < arr.length; x++) {
    var temp = arr[x].split('=');
    obj[temp[0]] = temp[1];
  }
  return obj;
};

const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const yearValiditionCheck = (date, data) => {
  const dateVar = date.split('/');
  const dateVal = new Date(dateVar[1] + '/' + dateVar[0] + '/' + dateVar[2]);
  const isValid = moment(dateVal).isValid();
  if (isValid) {
    const obj = parseString(data?.fields?.value?.value);
    const inputYear = moment(dateVal).format('YYYY');
    if (inputYear <= obj?.min) {
      return false;
    }
  }
  return isValid;
};

export const patternRegexCheck = (pattern) => {
  let regex = '';
  if (pattern && pattern.match) {
    const match = pattern.match(new RegExp('^/(.*?)/([gimy]*)$'));
    regex = match ? new RegExp(match[1], match[2]) : new RegExp(pattern);
  }
  return regex;
};

export const validateDNI = (dni) => {
  var numero, lett, letra;
  var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;
  dni = dni.toUpperCase();
  if (expresion_regular_dni.test(dni) === true) {
    numero = dni.substr(0, dni.length - 1);
    numero = numero.replace('X', 0);
    numero = numero.replace('Y', 1);
    numero = numero.replace('Z', 2);
    lett = dni.substr(dni.length - 1, 1);
    numero = numero % 23;
    letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
    letra = letra.substring(numero, numero + 1);
    if (letra != lett) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};
