/*eslint-disable*/
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, connect } from 'react-redux';
import lget from 'lodash.get';
import {
  savePersonalInfo,
  saveProfileInfoForm,
  saveProfileInfoFormCount,
  saveProfileInfoFormCountIfFormValid,
  saveField,
} from '../../../../redux/actions/actions';
import {
  replaceTextwithJSX,
  traverseAndDataExist,
} from '../../../../utils/helperUtils';
import DynamicForm from '../../../core/Forms/DynamicForm';
import Button from '../../../core/globals/buttons/Button';
import { formObjIteration } from '../../Utils/helper';

/**
 * @description - ProfileInfo component.
 * @param {Object} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const ProfileInfo = ({ inputRef, ...props }) => {
  const [newProfileInfo, flattenProfileInfo] = useState({});
  const {
    profileInfo,
    fields,
    t,
    handleClick,
    accordionId,
    profileInfoFormData,
    profileEmptyFieldsData,
    componentName,
    getEmptyFields,
    // uploadFields,
  } = props;

  const {
    register,
    handleSubmit,
    getValues,
    errors,
    setValue,
    control,
    formState,
    setError,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    revalidate: 'onChange',
    shouldFocusError: false,
  });
  const { subTitle, formText, items, subText, ctaUrl, ctaText } = fields;
  const formSubmitRef = useRef();
  const dispatch = useDispatch();
  const [triggered, settriggered] = useState(false);
  const [fileUpload, setFileUpload] = useState({});
  const [candidateId, setCandidateId] = useState('');
  const [fieldList, setFieldList] = useState([]);

  const { isValid, isDirty, touched, isValidating } = formState;

  useEffect(() => {
    let emptyObj = {};
    emptyObj[componentName] = isValid ? 0 : 1;
    getEmptyFields(emptyObj);
  }, [isValid]);

  useEffect(() => {
    setTimeout(() => {
      let isEmpty = !traverseAndDataExist(getValues());
      dispatch(savePersonalInfo({ isDirty, isValid, touched, isEmpty }));
    }, 500);
  }, [isValid, isDirty, touched, isValidating]);

  useEffect(() => {
    if (profileInfo?.id) {
      setCandidateId(profileInfo?.id);
      const personalInfoValues = profileInfo?.personalDetails;
      // const tempData = getDataObj(personalInfoValues);
      flattenProfileInfo({ data: personalInfoValues });
      if (profileInfoFormData?.parse) {
        handleClick(parseInt(accordionId), false);
      }
    }
  }, [profileInfo]);

  useEffect(() => {
    if (profileInfoFormData?.parse) {
      handleClick(parseInt(accordionId), false);
    }
  }, [profileInfoFormData?.parse]);

  const transform = (onSubmitFunc) => (data) => {
    let formattedData = Object.assign({}, data);
    formattedData = formObjIteration(data);
    onSubmitFunc(formattedData);
  };

  /**
   * @description - onsubmit hander.
   * @returns {undefined}
   */
  const onSubmit = (formData) => {
    dispatch(
      saveProfileInfoForm({
        ...profileInfoFormData.data,
        EditPersonalInfo: formData,
        files: { ...profileInfoFormData?.data?.files, fileUpload },
        deletedFiles: [
          ...new Set([...profileInfoFormData?.data?.deletedFiles, ...fieldList]),
        ],
      })
    );
    if (triggered) {
      settriggered(false);
      dispatch(saveProfileInfoFormCountIfFormValid(0));
    } else handleClick(parseInt(accordionId) + 1);
  };

  /**
   * @description - useImperativeHandle function.
   * @returns {undefined}
   */
  useImperativeHandle(inputRef, () => ({
    trigger: () => {
      settriggered(true);
      setTimeout(() => dispatch(saveProfileInfoFormCount(0)), 50);
      let event;
      if (typeof Event === 'function') {
        event = new Event('submit', { cancelable: true });
      } else {
        event = document.createEvent('Event');
        event.initEvent('submit', false, { cancelable: true });
      }
      formSubmitRef?.current?.dispatchEvent(event);
      // formSubmitRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
    },
  }));

  const handleUpload = (fileData, name) => {
    const fileList = fileUpload;
    const obj = { [name]: fileData };
    if (fileUpload.hasOwnProperty(name)) {
      delete fileList[name];
    }
    setFileUpload({ ...fileList, ...obj });

    // if (!uploadFields.includes(name)) {
    //   dispatch(saveField(name));
    // }
  };

  const handleDelete = (id, name, index) => {
    if (fileUpload.hasOwnProperty(name)) {
      const fileList = fileUpload;
      const temp = fileUpload[name];
      delete temp[index];
      delete fileList[name];
      const newtemp = temp?.filter((item) => item);
      const obj = { ...fileUpload, [name]: newtemp };
      setFileUpload({ ...fileList, ...obj });
    }
    if (id) {
      setFieldList([...fieldList, { fileid: id, type: name }]);
    }
  };

  return (
    <div className="personalInfo">
      <div className="build-profile signinup">
        <div className="text">{subTitle?.value}</div>
        <section className="forms signinup">
          <div className="formText">{formText?.value}</div>
          <form
            autoComplete="off"
            onSubmit={handleSubmit(transform(onSubmit))}
            ref={formSubmitRef}
          >
            <div className="personal-info-form">
              <DynamicForm
                formData={items}
                register={register}
                errors={errors}
                getValues={getValues}
                values={newProfileInfo}
                setValue={setValue}
                setError={setError}
                clearErrors={clearErrors}
                control={control}
                validateOnLoad={true}
                handleUpload={handleUpload}
                handleDelete={handleDelete}
                APIQuerryParams={{ cid: candidateId }}
              />
            </div>
          </form>
        </section>
        <div className="subTextContainer">
          {replaceTextwithJSX(
            subText?.value,
            '{0}',
            <Link key="personalInfoSubText" to={ctaUrl?.value?.href}>
              {ctaText?.value}
            </Link>
          )}
        </div>
        <div className="accordionNextBtn">
          <Button
            text={t('next')}
            cssClass={`blue ${!isValid ? 'white-disabled' : 'blue-enabled'}`}
            handleButtonClick={handleSubmit(transform(onSubmit))}
          />
        </div>
      </div>
    </div>
  );
};

ProfileInfo.defaultProps = {
  fields: {},
  profileInfo: {},
  getEmptyFields: () => {},
  profileInfoFormData: {},
  profileEmptyFieldsData: {},
};

ProfileInfo.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  profileInfo: PropTypes.shape({}).isRequired,
  profileInfoFormData: PropTypes.shape({}).isRequired,
  getEmptyFields: PropTypes.func.isRequired,
  profileEmptyFieldsData: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
    profileInfoFormData: state.profileInfoFormReducer,
    profileEmptyFieldsData: state.profileEmptyFieldsReducer,
    // uploadFields: state.uploadFieldsReducer,
  };
};

const ConnectedComponent = connect(mapStateToProps)(ProfileInfo);
export default forwardRef((props, ref) => {
  return <ConnectedComponent {...props} inputRef={ref} />;
});
