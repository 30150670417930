/* eslint-disable*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { mediaApi, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Components } from '../Utils/helper';

/**
 * @description - Accordion Section component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const AccordionSection = ({
  onClick,
  id,
  componentName,
  fields = {},
  isOpen,
  t,
  inputRef,
}) => {
  const { title, text, icon, missingIcon } = fields;
  const Component = Components[componentName];
  const [emptyFields, setEmptyFields] = useState({});
  const [headerLoading, setHeaderLoading] = useState(false);
  if (!Component) return false;
  /**
   * @description - to handle accordian click
   * @returns {undefined}
   */
  const handleClick = () => {
    onClick(id);
    // id && document.getElementById(`form${id}`).scrollIntoView();
    // window.scrollTo(0, window.pageYOffset * 0.5);
  };

  const getEmptyFields = (data = {}) => {
    setEmptyFields(data);
  };

  const setLoader = (isLoading = false) => {
    setTimeout(() => {
      setHeaderLoading(isLoading);
    }, 1);
  };

  /**
   * @description - to handle accordian toggle
   * @param {*} event - click event
   * @returns {undefined} - nothing
   */
  const handleToggleClick = (event) => {
    if (event.keyCode === 13) {
      onClick(id);
    }
  };

  return (
    <div
      className={'profile-accordion-block ' + (isOpen ? 'active' : '')}
      role="listitem"
      id={`form${id}`}
      key={`form${id}`}
    >
      <div
        className="icon"
        onKeyUp={handleToggleClick}
        onClick={handleClick}
        role="button"
        tabIndex={0}
        aria-label={`toggle accordian ${title?.value}`}
        aria-expanded={isOpen}
      ></div>
      <div className="profile-accordion-title" onClick={handleClick}>
        {!headerLoading &&
        emptyFields &&
        emptyFields?.[componentName] &&
        emptyFields?.[componentName] !== 0 ? (
          missingIcon?.value?.src || missingIcon?.value?.alt ? (
            <img
              className={missingIcon?.value?.src ? 'accordion-icon' : ''}
              src={
                missingIcon?.value?.src &&
                mediaApi.updateImageUrl(missingIcon?.value?.src)
              }
              alt=""
            />
          ) : (
            ''
          )
        ) : icon?.value?.src || icon?.value?.alt ? (
          <img
            className={icon?.value?.src ? 'accordion-icon' : ''}
            src={icon?.value?.src && mediaApi.updateImageUrl(icon?.value?.src)}
            alt={icon?.value?.alt}
          />
        ) : (
          ''
        )}
        <div className="titleTextSection">
          <RichText field={title} tag="h2" className="section-title" />
          {headerLoading ? (
            <div className="spinner-border spinner-border-sm" role="status"></div>
          ) : emptyFields &&
            emptyFields?.[componentName] &&
            emptyFields?.[componentName] !== 0 ? (
            <span className="missingText">{text?.value}</span>
          ) : (
            ''
          )}
        </div>
      </div>
      <div key={id} className="profile-accordion-content clearfix">
        <Component
          fields={fields}
          t={t}
          key={id}
          handleClick={onClick}
          accordionId={id}
          componentName={componentName}
          ref={inputRef}
          getEmptyFields={(emptyFields) => getEmptyFields(emptyFields)}
          setLoader={(isLoading) => setLoader(isLoading)}
        />
      </div>
    </div>
  );
};

AccordionSection.defaultProps = {
  isOpen: '',
  id: '',
  title: { value: '' },
  content: { value: '' },
  fields: {},
  showNextButton: false,
};
AccordionSection.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  showNextButton: PropTypes.bool,
  id: PropTypes.string.isRequired,
  title: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
  content: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
  fields: PropTypes.shape({}),
  onClick: PropTypes.func.isRequired,
};

export default AccordionSection;
