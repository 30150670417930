import { SEO_TYPES } from '../actions/actionTypes';

const initialState = {
  jobs: [],
  totalJobResults: 0,
  filterResults: '',
  seeAllJobs: '',
  showingResults: 0,
};

/**
 * @description - to add or remove seo jobs
 * @param {object} state - state object
 * @param {object} action - reducer action object
 * @returns {object} - new sate
 */
const seoSearchResultsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEO_TYPES.SAVE:
      return {
        ...state,
        ...action.payload,
      };
    case SEO_TYPES.CLEAR:
      return {
        jobs: [],
        totalJobResults: 0,
        filterResults: '',
        seeAllJobs: '',
        showingResults: 0,
      };
    default:
      return state;
  }
};

export default seoSearchResultsReducer;
