import React, { Fragment } from 'react';
/**
 * @description - Filter component for jobs.
 * @param {object} param0 - Filter params.
 * @returns {Node} - Filter elements.
 */
const FilterIcon = () => (
  <Fragment>
    <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg" id="search-icon">
      <path
        d="M6.059 11.117a4.967 4.967 0 0 0 3.23-1.177l3.926 3.925a.45.45 0 0 0 .325.135.46.46 0 0 0 .325-.785L9.94 9.289a5.05 5.05 0 0 0 1.177-3.23A5.06 5.06 0 0 0 6.06 1C3.277 1 1 3.277 1 6.059a5.067 5.067 0 0 0 5.059 5.058zm0-9.197a4.143 4.143 0 0 1 4.139 4.139 4.136 4.136 0 0 1-4.14 4.139 4.143 4.143 0 0 1-4.138-4.14A4.15 4.15 0 0 1 6.059 1.92z"
        stroke="currentColor"
        strokeWidth="1.26"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  </Fragment>
);

FilterIcon.propTypes = {};

FilterIcon.defaultProps = {};

export default FilterIcon;
