import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '../../globals/link/ButtonLink';
import { mediaApi, RichText } from '@sitecore-jss/sitecore-jss-react';
import { getImg } from '../../../../utils/helperUtils';
import $ from 'jquery';
import './diamondBanner.scss';
// import data from '../../../mocks/diamondBanner';

/**
 * @description Diamond page banner component
 * @param {*} props - component content fields
 * @returns {node} - html
 */
const HomepageBannerDiamond = (props) => {
  useEffect(() => {
    $(function () {
      window.addEventListener('resize', () => {
        $('ul.diamond-wrapper').css('display', 'none');
        $('.fadeIn.content').addClass('resize-animation-stopper');
      });
    });
  });
  const { fields } = props;
  const { title, text, ctaText, ctaUrl, image } = fields;
  return (
    <div className="carousel-containerjw jw">
      <section
        className="home-carousel-block simple diamond"
        style={{ height: '277px' }}
      >
        <div
          className="carousel-item"
          style={{
            backgroundImage: `url(${mediaApi.updateImageUrl(getImg(image))})`,
          }}
        >
          {/* <div
          className="carousel-item"
          style={{
            backgroundImage: `url(https://jwcddevnorway.manpowergroup.com/-/media/project/manpowergroup/jeffersonwells/jw-norway/banners/homepage-banner.png?h=1618&iar=0&w=2876&hash=C9B40254441899DB41696EFFD5CFF3CA)`,
          }}
        > */}
          <ul className="diamond-wrapper">
            <li className="diamond orange"></li>
            <li className="diamond blue"></li>
            <li className="diamond green"></li>
          </ul>
          <div className="skewed-rectangle"></div>
          <div className="fadeIn content">
            <RichText tag="h2" className="sub-title" field={text} />
            <RichText tag="h1" className="title" field={title} />
            <div className="cta">
              {ctaUrl && (
                <ButtonLink
                  ctaText={ctaText}
                  ctaUrl={ctaUrl}
                  cssClass="blue"
                  arrow
                />
              )}
            </div>
            <div className="cta mobile">
              {ctaUrl && (
                <ButtonLink
                  ctaText={ctaText}
                  ctaUrl={ctaUrl}
                  cssClass="reversed"
                  arrow
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
HomepageBannerDiamond.defaultProps = {
  fields: {
    title: '',
    text: '',
    ctaText: '',
    ctaUrl: {
      value: {
        href: '#',
      },
    },
    image: {
      value: { src: '' },
    },
  },
};

HomepageBannerDiamond.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({ value: PropTypes.string }),
    text: PropTypes.shape({ value: PropTypes.string }),
    ctaUrl: PropTypes.shape({ value: PropTypes.shape({}) }),
    ctaText: PropTypes.shape({ value: PropTypes.string }),
    image: PropTypes.shape({ value: PropTypes.shape({}) }),
  }).isRequired,
};

export default HomepageBannerDiamond;
