/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import lget from 'lodash.get';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import ReactDatePicker from 'react-date-picker';
import classNames from 'classnames';
import { getValidationDatabyType } from '../../DynamicForm/helper/filter';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

/**
 * @description - Card Event.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template.
 */
const DatePickerComponent = ({
  customError,
  name,
  control,
  requiredValidation = [],
  dataValidations,
  label,
  connectorField,
  connectorType,
  connectorMessage,
  getValues,
  locale,
  customStyle,
  maxDate,
  minDate,
  setValue,
  value,
  t,
}) => {
  const fieldError = lget(customError, name);
  const requiredData = getValidationDatabyType(dataValidations, 'required');

  const [isOpen, setOpen] = useState(false);
  const [date, setDate] = useState(null);

  const connectorData = {
    connectorField: connectorField,
    connectorType: connectorType,
    getValues: getValues,
  };
  // const [value, onChange] = useState(new Date());

/**
 * @description  validation check
 * @param {*} value symbol for validation
 * @returns {*} result after validation
 */

  const symbolValidationCheck = (value) => {
    if (value && connectorData.connectorField) {
      const customConnector = `${formName}.[${connectorData.connectorField}]`;
      const connectorFieldValue = lget(getValues(), customConnector);
      let result = true;
      if (connectorFieldValue && value) {
        switch (connectorData.connectorType) {
          case '=':
            result = value.value === connectorFieldValue.value;
            break;
          case '<':
            result = value.value < connectorFieldValue.value;
            break;
          case '>':
            result = value.value > connectorFieldValue.value;
            break;
          case '<=':
            result = value.value <= connectorFieldValue.value;
            break;
          case '>=':
            result = value.value >= connectorFieldValue.value;
            break;
          default:
            break;
        }
      }
      return result;
    }
  };

  /**
   * @description Function on calendar close
   */
  const toggleCal = () => {
    setOpen(!isOpen);
  };

  useEffect(() => {
    const dt = value ? new Date(value) : null;
    setValue(name, dt);
    setDate(dt);
  }, [value]);

  return (
    <div className="date-form-block" style={customStyle}>
      <Controller
        control={control}
        name={name}
        rules={{
          required: true,
          validate: symbolValidationCheck || connectorMessage,
        }}
        render={(props) => (
          <ReactDatePicker
            className={classNames('date-picker', !date ? 'hide-input' : '')}
            //className="date-picker"
            placeholderText="Select date"
            onChange={(e) => {
              props.onChange(e);
              setDate(e);
            }}
            value={props.value}
            minDate={minDate && moment(minDate).toDate()}
            maxDate={maxDate && moment(maxDate).toDate()}
            locale={locale}
            isOpen={isOpen}
            onCalendarClose={() => toggleCal()}
          />
        )}
      />

      {!isOpen && (
        <div
          className={date ? 'active' : 'placeholder-container'}
          onClick={() => toggleCal()}
        >
          {label}
        </div>
      )}
      {fieldError && (
        <>
          {fieldError.type === 'required' && requiredData?.fields?.value?.value && (
            <div className="error-msg">
              {requiredData?.fields?.message?.value
                ? requiredData?.fields?.message?.value?.replace(
                    '{0}',
                    label?.replace('*', '')
                  )
                : t('mandatory-field-message')}
            </div>
          )}
          {fieldError?.type === 'validate' && (
            <div className="error-msg">{connectorMessage}</div>
          )}
        </>
      )}
    </div>
  );
};

DatePickerComponent.defaultProps = {
  locale: 'en',
  maxDate: '',
  minDate: '',
  customStyle: {},
  t: () => {},
};

DatePickerComponent.propTypes = {
  t: PropTypes.func,
};

export default withTranslation()(DatePickerComponent);
