import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import PressReleaseItem from './PressReleaseItem';
import PressReleasePagination from './PressReleasePagination';
import Loader from '../ErrorBoundary/Loader';
import Error from '../ErrorBoundary/Error';
import NoData from '../ErrorBoundary/NoData';
import { usePostData } from '../../../hooks/http-client';
import { withTranslation } from 'react-i18next';
import './pressRelease.scss';
// import mock from './mockData';
import { PRESS_RELEASE } from '../../../constants';
import { withTheme } from 'styled-components';

const PressRelease = ({ theme, rendering, t = () => {} }) => {
  const { dataSource, fields = {} } = rendering;
  const { itemsPerPage } = fields;
  const [currentPage, setCurrentPage] = useState(1);
  const { loading, error, write } = usePostData();
  const [result, setResult] = useState();
  // const result = mock;

  /**
   * @description to fetch data from api
   * @param {number} currentPage - page selected
   * @returns {undefined}
   */
  const getData = (currentPage) => {
    write(
      `${PRESS_RELEASE.PRESS_RELEASE_RESULTS}`,
      {
        pageNumber: currentPage,
        dataPath: dataSource,
      },
      setResult
    );
  };

  useEffect(() => {
    setCurrentPage(1);
    getData(currentPage);
  }, []);

  /**
   * @description to load selected page content
   * @param {number} selectedPage - selected page number
   * @returns {array} - page content
   */
  const handlePageChange = (selectedPage) => {
    if (selectedPage !== currentPage) {
      setCurrentPage(selectedPage);
      getData(selectedPage);
    }
    const elmnt = document.getElementsByClassName('pressRelease-results');
    setTimeout(() => {
      elmnt[0].scrollIntoView(
        {
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        },
        500
      );
    });
  };

  return (
    <>
      <section className={`pressRelease-results`}>
        <div>
          {loading ? (
            <Loader
              loaderWithMsg
              loaderMsg=""
              customLoaderStyle={{
                width: '4rem',
                height: '4rem',
                color: theme?.secondaryButton,
              }}
            />
          ) : result?.data?.items?.pressReleases?.length > 0 ? (
            result?.data?.count > 0 &&
            result?.data?.items?.pressReleases?.map((item, index) => {
              return (
                <PressReleaseItem
                  key={index}
                  title={item?.headline}
                  text={item?.description}
                  ctaText={t('learn-more')}
                  ctaUrl={item?.redirectURL}
                  downloadUrl={item?.documentURL}
                  downloadUrlText={t('press-release-download')}
                  t={t}
                />
              );
            })
          ) : error ? (
            <Error errorMsg={t('error-message')} />
          ) : null}

          {!loading &&
            result?.data?.items?.pressReleases?.length === 0 &&
            !error && <NoData message={t('no-data-exists')} />}
        </div>
      </section>
      {result?.data?.count > 0 && (
        <PressReleasePagination
          handlePageChange={handlePageChange}
          totalRecords={result?.data?.count}
          pageLimit={parseInt(itemsPerPage?.value)}
          selectedPage={currentPage}
          label={t('search-page')}
        ></PressReleasePagination>
      )}
    </>
  );
};
PressRelease.defaultProps = {
  fields: {
    itemsPerPage: {
      value: '10',
    },
  },
  t: () => {},
};

PressRelease.propTypes = {
  fields: PropTypes.shape({
    itemsPerPage: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
  }),
  t: PropTypes.func,
};

export default withTheme(withTranslation()(withRouter(PressRelease)));
