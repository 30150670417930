/*eslint-disable*/
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { authstates } from '../../../utils/enums';
import PublicPosition from './PublicPosition';
import ScreenerQuestions from './ScreenerQuestions';
import UploadResume from './UploadResume';
import JobNav from './JobNav';
import { scrollTop, isObjNotEmpty } from '../../../utils/helperUtils';
import PrivateRoute from '../../core/PrivateRoute';
import {
  GET_JOB_MINIMAL_INFO_DETAILS,
  GET_JOB_DETAILS,
  GET_JOB_DETAILS_NOAUTH,
} from '../../../constants';
import { dataFetcher } from '../../../dataFetcher';
import Loader from '../../core/ErrorBoundary/Loader';
import Error from '../../core/ErrorBoundary/Error';
import Button from '../../core/globals/buttons/Button';
import { saveJobDetails, clearJobDetails } from '../../../redux/actions/actions';
import { uploadPluginConfigApi } from '../../../services/apiServices/commonService';
import './apply_job_flow.scss';
import { mockJobDetailsData } from './jobDetails';
import { mockData } from './mockData';
import { withTheme } from 'styled-components';

/**
 * @description - Job Apply component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML template.
 */
const ApplyJobFlow = ({ rendering, t, sitecoreContext, theme, ...props }) => {
  //rendering = mockData;
  const JobContentArr = rendering?.placeholders['jss-content'] || [];
  const [step, setStep] = useState({});
  const [jobStorageDetails, setJobStorageDetails] = useState({});
  const [isScreenerLoading, setScreenerLoading] = useState(false);
  const profileInfoReducer = useSelector((state) => state.profileInfoReducer);
  const [jobDataLoading, setJobLoading] = useState(true);
  const [error, setError] = useState(false);
  const step0Ref = useRef(null);
  const step1Ref = useRef(null);
  const step2Ref = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const queryStrings = new URLSearchParams(useLocation().search);
  const jobItemId = queryStrings.get('id');
  const loginReducer = useSelector((state) => state.loginReducer);
  const uploadConfigReducer = useSelector((state) => state.uploadConfigReducer);

  /**
   * @description - Get Job Details By Job Id fuction.
   * @returns {undefined}
   */
  const getJobDetails = () => {
    dataFetcher(
      loginReducer.initialized && loginReducer.state === authstates.AUTHENTICATED
        ? `${GET_JOB_MINIMAL_INFO_DETAILS}?jobItemID=${jobItemId}&includeUserData=true`
        : `${
            sitecoreContext?.LoginType !== 'NA'
              ? GET_JOB_DETAILS_NOAUTH
              : GET_JOB_DETAILS
          }?jobItemID=${jobItemId}&includeUserData=false`
    ).then(
      (response) => {
        if (
          loginReducer.state !== authstates.AUTHENTICATED &&
          response?.data?.jobItemID
        ) {
          let JobDetails = response?.data;
          setContent({ JobDetails });
        } else if (response?.data?.JobDetails?.jobItemID) {
          setContent(response?.data);
          // setContent(mockJobDetailsData);
        } else {
          setError(true);
        }
        setJobLoading(false);
      },
      () => {
        setJobLoading(false);
        setError(true);
      }
    );
  };

  useEffect(() => {
    if (!jobItemId) {
      history.push({
        pathname: sitecoreContext?.Country?.jobSearchURL || '/',
      });
    } else {
      //File Upload Configuration
      if (!isObjNotEmpty(uploadConfigReducer?.data)) uploadPluginConfigApi(dispatch);
      getJobDetails();
    }
    return () => {
      dispatch(clearJobDetails());
    };
  }, []);

  const getMockData = () => {
    setContent(mockJobDetailsData);
    setJobLoading(false);
  };

  const setContent = (data) => {
    const newData =
      Object.keys(data).length > 0
        ? {
            ...data,
            CandidateMinimalInfo: {
              ...data.CandidateMinimalInfo,
              // uploadResume: [{ ...data?.CandidateMinimalInfo?.uploadResume }],
            },
          }
        : {};
    setJobStorageDetails(newData);
    setStep({
      activeStep: newData?.JobDetails?.isPublicPosition
        ? 0
        : newData?.JobDetails?.hasScreenerQuestion
        ? 1
        : 2,
    });
  };

  /**
   * @description - HandleOnClickNext fuction.
   * @returns {undefined}
   */
  const handleOnClickNext = () => {
    switch (step.activeStep) {
      case 0:
        step0Ref.current.trigger();
        break;
      case 1:
        step1Ref.current.trigger();
        break;
      case 2:
        step2Ref.current.trigger();
        break;
      default:
        break;
    }
  };

  /**
   * @description - onSubmitHandler fuction.
   * @returns{undefined}
   */
  const onSubmitHandler = (loading = false, status = '') => {
    let activeStep = step.activeStep;
    if (
      activeStep === 0 &&
      jobStorageDetails?.JobDetails?.hasScreenerQuestion === false
    ) {
      activeStep = 1;
    }
    setScreenerLoading(loading);
    if (loading === false && status !== 'error') {
      activeStep += 1;
      setStep({ activeStep });
    }
    scrollTop();
  };

  /**
   * @description - HandleOnClickBack fuction.
   * @returns {undefined}
   */
  const handleOnClickBack = () => {
    const activeStep = step.activeStep - 1;
    setStep({ activeStep: activeStep });
    scrollTop();
  };

  const handleRedirectButtonClick = () => {
    history.push({
      pathname: sitecoreContext?.Country?.jobSearchURL || '/',
    });
  };

  const getBlockComponent = (block, index) => {
    switch (block.componentName) {
      case 'PublicPosition':
        return (
          <PublicPosition
            key={block?.uid}
            fields={block?.fields}
            onSubmitHandler={onSubmitHandler}
            ref={step0Ref}
            candidateJobDetails={jobStorageDetails}
            t={t}
          />
        );

      case 'ScreenerQuestions':
        return (
          <ScreenerQuestions
            key={block?.uid}
            fields={block?.fields}
            onSubmitHandler={(loading, status) => onSubmitHandler(loading, status)}
            ref={step1Ref}
            candidateJobDetails={jobStorageDetails}
            t={t}
          />
        );
      case 'UploadResume':
        return (
          <UploadResume
            key={block?.uid}
            fields={block?.fields}
            candidateJobDetails={jobStorageDetails}
            ref={step2Ref}
            t={t}
            context={sitecoreContext}
            auth={loginReducer.state}
          />
        );

      default:
        return <div className="no_block_type" />;
    }
  };

  return (
    <>
      {jobDataLoading ? (
        <Loader
          loaderMsg={t('fetching-your-data')}
          loaderWithMsg
          customLoaderStyle={{
            width: '4rem',
            height: '4rem',
            color: theme?.secondaryButton,
          }}
          customTextStyle={{ color: theme?.secondaryButton }}
        />
      ) : error ? (
        <div className="text-center col-lg-6 offset-lg-3 job-apply-error-container">
          <Error
            errorMsg={t('no-job-found')}
            refreshApi={true}
            handleApiRefresh={() => {
              setJobLoading(true);
              getJobDetails();
            }}
          />
          <Button
            text={t('go-to-search')}
            handleButtonClick={handleRedirectButtonClick}
            cssClass="profile-nav-next"
          />
        </div>
      ) : (
        <div className="candidate-flow">
          <div className="col-lg-6 offset-lg-3 job-title">
            <span className="job-desc">{t('you-are-applying-to')}&nbsp;</span>
            <span className="job-name">
              <Link to={jobStorageDetails?.JobDetails?.jobURL}>
                {jobStorageDetails?.JobDetails?.jobTitle}
              </Link>
            </span>
          </div>
          {JobContentArr.map((block, index) => {
            return step.activeStep === index && getBlockComponent(block, index);
          })}
          {step.activeStep !== JobContentArr.length - 1 && (
            <JobNav
              activeStep={step.activeStep}
              handleOnClickNext={handleOnClickNext}
              handleOnClickBack={handleOnClickBack}
              jobStorageDetails={jobStorageDetails?.JobDetails}
              t={t}
              loading={isScreenerLoading}
              disabled={isScreenerLoading || jobDataLoading}
            />
          )}
        </div>
      )}
    </>
  );
};

ApplyJobFlow.defaultProps = {
  JobContent: [],
  t: (val) => val,
  sitecoreContext: {},
};

ApplyJobFlow.propTypes = {
  JobContent: PropTypes.arrayOf(PropTypes.shape()),
  t: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({}),
};

export default withSitecoreContext()(
  withTranslation()(withTheme(withPlaceholder('jss-content')(ApplyJobFlow)))
);
