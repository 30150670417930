import { createGlobalStyle } from 'styled-components';

export const PageBannerStyle = createGlobalStyle`
.banner-section {
    &.stats {
        background-color: ${({ theme }) => theme?.primaryColor};
    }
    &.insights {
        background-color: ${({ theme }) => theme?.primaryColor};
    }
    &.location {
        background-color: ${({ theme }) => theme?.primaryBackgroundColor} !important;
        .skewed-rectangle {
            background-color: ${({ theme }) =>
              theme?.primaryBackgroundColor} !important;
        }
    }
    &.standard {
        .title {
            color: ${({ theme }) => theme?.primaryColor};
        }
        p {
            color: ${({ theme }) => theme?.secondaryText};
        }
    }
    &.skewed {
        &.blue {
            .skewed-rectangle {
                background-color: ${({ theme }) => theme?.primaryColor} !important;
            }
        }
        &.green{ 
            .skewed-rectangle {
                background-color: ${({ theme }) =>
                  theme?.primaryBackgroundColor} !important;
            }
        }
    }
    &.overlay-banner{
        .title{
            color: ${({ theme }) => theme?.horizontalRule} !important;
        }
        h1 {
            color: ${({ theme }) => theme?.primaryBackgroundColor} !important;
        }
    }
}
@media (max-width: 992px) {
    .banner-section {
        &.standard {
            &.white {
                .title {
                    color: ${({ theme }) => theme?.primaryColor} !important;
                }
                p {
                    color: ${({ theme }) => theme?.secondaryText};
                }
            }
        }
        &.skewed {
            &.blue {
                background-color: ${({ theme }) => theme?.primaryColor};
            }
            &.green {
                background-color: ${({ theme }) => theme?.primaryBackgroundColor};
            } 
        }
    }
}
.skewed-rect{
    .skewed-rectangle{
        background-color: ${({ theme }) => theme?.primaryColor};
    }
}
`;
