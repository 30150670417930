/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TextLink from '../../core/globals/link/TextLink';
import Modal from '../../core/Modal';
import Button from '../globals/buttons/Button';
import { toast } from 'react-toastify';
import DownloadIcon from '../../core/Forms/FormFields/UploadBlock/DownloadIcon';
import { withTheme } from 'styled-components';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
/**
 * @description to list results
 * @param {string} title - result item title
 * @param {string} text - result item small description
 * @param {string} ctaUrl - url to redirect
 * @param {string} downloadUrl - url to download
 * @returns {node} -html
 */

const PressReleaseItem = (props) => {
  const {
    t,
    title,
    text,
    ctaUrl,
    ctaText,
    downloadUrl,
    downloadUrlText,
    theme,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const subTextObj = (text?.value || text?.hasOwnProperty('value')) ? text : {'value' : text};
  const handleModal = () => {
    setShowModal(!showModal);
  };

  const handleYes = () => {
    toast.success(`${t('downloading')} ${title}`, {
      autoClose: 3000,
    });
    handleModal();
    var link = document?.createElement('a');
    link.download = title;
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link = null;
  };

  const handleNo = () => {
    handleModal();
  };

  const downloadFile = () => {
    handleModal();
  };

  return (
    <section className="pressRelease-item">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-12">
            <div className="content">
              <Link to={ctaUrl}>
                <h2 className="title">{title}</h2>
              </Link>
              <RichText tag="div" className = "subText" field = {subTextObj} />
              <div className="buttonContainer">
                {downloadUrl && (
                  <div className={'downloadContainer'}>
                    <div className="download_link" onClick={() => downloadFile()}>
                      {downloadUrlText}
                      <DownloadIcon
                        iconColor={theme?.secondaryButton}
                        className="download-icon-orange"
                        onClick={() => downloadFile()}
                      />
                    </div>
                    <Modal showModal={showModal} handleModal={handleModal} size="md">
                      <div className="inner-modal-container" key="body">
                        {t('would-you-like-to-download-the-attached-document')}
                        <div
                          className="confirm-button-container"
                          role="button"
                          tabIndex={0}
                        >
                          <Button
                            cssClass="yes-button"
                            handleButtonClick={handleYes}
                            text={t('yes')}
                          />
                          <Button handleButtonClick={handleNo} text={t('no')} />
                        </div>{' '}
                      </div>
                    </Modal>
                  </div>
                )}
                {ctaUrl && (
                  <div className="textLinkContainer">
                    <TextLink ctaText={ctaText} ctaUrl={ctaUrl} arrow />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

PressReleaseItem.defaultProps = {
  title: '',
  text: '',
  ctaUrl: '',
  downloadUrl: '',
};

PressReleaseItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  downloadUrl: PropTypes.string.isRequired,
};

export default withTheme(PressReleaseItem);
