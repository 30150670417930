/* eslint-disable */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useCallback } from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AuthWrapper from './AuthWrapper';
import { authstates } from '../../../utils/enums';
import { logoutSuccess } from '../../../redux/actions/loginActions';
import useRoveFocus from '../../../hooks/useRoveFocus';
import { SOCIAL_LOGIN_VALUES } from '../../../constants';

/**
 * @description - Push authentication type to storage.
 * @returns {undefined}
 */
const pushToStorage = () => {
  localStorage.setItem('authenticationType', 'Create an account/Sign in');
};

/**
 * @description- user links after login
 * @param {Object} - component props
 * @returns {HTML} - html
 */
const Links = ({
  role,
  to,
  handleClick,
  character,
  // focus,
  index,
  setFocus,
  ctaText,
}) => {
  const ref = useRef(null);

  // useEffect(() => {
  //   if (focus) {
  //     // Move element into view when it is focused
  //     ref.current.focus();
  //   }
  // }, [focus]);

  const handleSelect = useCallback(() => {
    setFocus(index);
  }, [character, index, setFocus]);

  return (
    <li role="none" key={index}>
      <Link
        role={role}
        to={to}
        onClick={() => handleClick()}
        tabIndex={0}
        onKeyUp={handleSelect}
        ref={ref}
      >
        <Text field={ctaText} />
      </Link>
    </li>
  );
};

Links.propTypes = {
  role: PropTypes.string,
  to: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  character: PropTypes.shape({}),
  focus: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  setFocus: PropTypes.func.isRequired,
  ctaText: PropTypes.string.isRequired,
};

Links.defaultProps = {
  role: '',
  to: '',
  handleClick: () => {},
  character: {},
  focus: false,
  index: 0,
  setFocus: () => {},
  ctaText: '',
};

/**
 * @description _ MSALView Component.
 * @param {object} props - Props passed.
 * @returns {Node} - HTML node.
 */
const MSALView = ({ t, fields }) => {
  const dispatch = useDispatch();
  const [focused, setFocused] = useState(false);
  const [focus, setFocus] = useRoveFocus(fields?.items?.length);
  const loginReducer = useSelector((state) => state.loginReducer);

  return (
    <AuthWrapper>
      {({ authenticationState, user, login, logout, showLogin }) => {
        if (!showLogin) return;
        switch (authenticationState) {
          case authstates.AUTHENTICATED:
            const firstName = user ? user?.firstName : '';
            /**
             * @description - Logging Out user.
             * @returns {undefined}
             */
            const logoutUser = () => {
              if (window?.dataLayer) {
                const idpVal = loginReducer?.idToken?.idTokenClaims?.idp;
                window.dataLayer.push({
                  event: 'Authentication',
                  eventCategory: 'Sign Out',
                  eventAction:
                    SOCIAL_LOGIN_VALUES.get(idpVal?.toLowerCase()) || idpVal,
                  eventLabel: 'Successful Sign Out',
                });
              }
              dispatch(logoutSuccess());
              logout();
            };

            /**
             * @description toggle menuitem list
             */
            const handleClick = () => {
              setFocused(!focused);
            };

            const onEnter = (e) => {
              if (e && e.keyCode === 13) {
                logoutUser();
              }
            };

            return (
              <div
                role="none"
                className={`main-nav-item login logged-in ${
                  focused ? 'link-focused' : ''
                }`}
                onFocus={() => {
                  if (
                    document &&
                    !document.getElementsByClassName('hide_outline').length
                  )
                    setFocused(true);
                }}
                onMouseOver={() => setFocused(true)}
                // onBlur={() => setFocused(false)}
                onMouseLeave={() => setFocused(false)}
              >
                <a
                  href="#"
                  aria-haspopup="true"
                  aria-expanded={focused}
                  aria-controls="dp-menu"
                  title="User Login"
                  onClick={handleClick}
                  id="menubutton"
                >
                  <span>
                    <div className="user-icon"></div>
                  </span>
                </a>
                {focused ? (
                  <div
                    className={`main-nav-dropdown login-dropdown ${'link-active'}`}
                  >
                    <div className="main-nav-dropdown-inside">
                      <div className="triangle login-triangle"></div>
                      <div className="main-nav-user-block">
                        <div className="welcome-user">
                          {t('welcome-title')} {firstName}
                        </div>
                        <nav className="main-nav-user-links">
                          <ul className="login-submenu" role="menu" id="dp-menu">
                            {fields && fields.items && fields.items.length
                              ? fields.items.map((item, index) => (
                                  <Links
                                    key={index}
                                    role="menuitem"
                                    to={item?.fields?.ctaUrl?.value?.href}
                                    handleClick={handleClick}
                                    setFocus={setFocus}
                                    index={index}
                                    focus={focus === index}
                                    character={item}
                                    ctaText={item?.fields?.ctaText}
                                  />
                                ))
                              : null}
                            <li role="none">
                              <a
                                href="#"
                                aria-label={t('sign-out')}
                                onClick={logoutUser}
                                onKeyDown={onEnter}
                                // role="button"
                                tabIndex="0"
                                role="menuitem"
                              >
                                {t('sign-out')}
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            );
          case authstates.PROGRESS:
            return (
              <div className={`main-nav-item login`}>
                <a href="#" aria-label="Progress">
                  <span>
                    <div className="user-icon"></div>
                  </span>
                </a>
              </div>
            );
          default:
            return (
              <div className={`main-nav-item login`} role="menu">
                {' '}
                {/*className only for manpower header */}
                <a
                  href="#"
                  aria-expanded={true}
                  onClick={() => {
                    pushToStorage();
                    login();
                  }}
                  onKeyDown={(e) => {
                    if (e && (e.keyCode === 13 || e.keyCode === 32)) {
                      pushToStorage();
                      login();
                    }
                  }}
                  tabIndex="0"
                >
                  <span>
                    <div className="user-icon"></div>
                  </span>
                </a>
              </div>
            );
        }
      }}
    </AuthWrapper>
  );
};

MSALView.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  isMobile: PropTypes.bool.isRequired,
};

MSALView.defaultProps = {
  fields: {
    items: [],
  },
};

export default withTranslation()(MSALView);
