/*eslint-disable*/
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
// import ProfileNav from './ProfileNav';
import './build_profile.scss';
import mockData from './mockData';
import { connect } from 'react-redux';
import { Components } from '../Utils/helper';
import { isObjNotEmpty } from '../../../utils/helperUtils';
import Loader from '../../core/ErrorBoundary/Loader';
import Error from '../../core/ErrorBoundary/Error';
import PrivateRoute from '../../core/PrivateRoute';
import {
  clearAllCover,
  clearAllResume,
  clearBuildProfileForm,
  clearProfileInfo,
  clearProfileInfoForm,
} from '../../../redux/actions/actions';
import { getCandidateDetailsApi } from '../../../services/apiServices/candidateService';
import { uploadPluginConfigApi } from '../../../services/apiServices/commonService';
import { withTheme } from 'styled-components';

/**
 * @description - Build Profile component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML template.
 */
const BuildProfile = ({ rendering, t, dispatch, theme, ...props }) => {
  //rendering = mockData;
  const { placeholders = [] } = rendering;
  const { profileInfo, uploadConfig, fields } = props;
  const { title } = fields;
  const [profileDataLoading, setprofileDataLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (profileInfo?.id) {
      setprofileDataLoading(false);
    } else if (profileInfo?.error) {
      setprofileDataLoading(false);
      setError(true);
    }
  }, [profileInfo]);

  useEffect(() => {
    //File Upload Configuration
    if (!isObjNotEmpty(uploadConfig)) uploadPluginConfigApi(dispatch);
    return () => {
      dispatch(clearProfileInfo());
      dispatch(clearBuildProfileForm());
      dispatch(clearProfileInfoForm());
      dispatch(clearAllCover());
      dispatch(clearAllResume());
      getCandidateDetailsApi({}, dispatch);
    };
  }, []);

  const refObj = {};
  placeholders &&
    placeholders['jss-content'].map((block, index) => {
      refObj[block?.componentName] = useRef(null);
    });

  const handleApiRefresh = () => {
    setprofileDataLoading(true);
    setTimeout(() => getCandidateDetailsApi({}, dispatch), 500);
  };

  return (
    <PrivateRoute>
      <section className="candidate-flow">
        <h2 className="title">{title?.value}</h2>
        {profileDataLoading ? (
          <Loader
            loaderMsg={t('fetching-your-data')}
            loaderWithMsg
            customLoaderStyle={{
              width: '4rem',
              height: '4rem',
              color: theme?.secondaryButton,
            }}
            customTextStyle={{ color: theme?.secondaryButton }}
          />
        ) : error ? (
          <Error
            errorMsg={t('error-message')}
            t={t}
            refreshApi={true}
            handleApiRefresh={handleApiRefresh}
          />
        ) : (
          <>
            {placeholders &&
              placeholders['jss-content'].map((block, index) => {
                const Component = block.componentName
                  ? Components[block.componentName]
                  : '';
                if (!Component) return false;
                return (
                  <Component
                    t={t}
                    key={block?.uid}
                    fields={block?.fields}
                    data={
                      block?.placeholders && block?.placeholders['jss-content']
                        ? block?.placeholders['jss-content']
                        : []
                    }
                    ref={refObj[block?.componentName]}
                    refObj={refObj}
                  />
                );
              })}
          </>
        )}
      </section>
    </PrivateRoute>
  );
};

BuildProfile.defaultProps = {
  ProfileContent: [],
  t: (val) => val,
  sitecoreContext: {},
  renderContent: [],
};

BuildProfile.propTypes = {
  ProfileContent: PropTypes.arrayOf(PropTypes.shape()),
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({}),
  renderContent: PropTypes.arrayOf(PropTypes.shape()),
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
    uploadConfig: state.uploadConfigReducer.data,
  };
};

export default connect(mapStateToProps)(
  withTranslation()(withTheme(withPlaceholder('jss-content')(BuildProfile)))
);
