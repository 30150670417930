/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import HomeIconCard from '../HomeIconCard';
import TextLink from '../../globals/link/TextLink';
import '../homeblocks.scss';
import { Text } from '@sitecore-jss/sitecore-jss-react';

/**
 * @description - TabbedBlock component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const TabbedBlock = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { fields, params } = props;
  const { title, ctaText, ctaUrl, items } = fields;
  const [currentActiveTabIndex, setCurrentActiveTabIndex] = useState(0); // this tracks currently viewed tab in nav

  /**
   * @description - switches the nav tabs.
   * @param {number} selectedIndex - Nav index which need to be activated
   * @returns {void} - void.
   */
  const tabItemClicked = (selectedIndex) => {
    setCurrentActiveTabIndex(selectedIndex);
  };

  const [userLocation, setUserLocation] = useState(null);

  /**
   * @description - get user's current location
   * @returns {void} - void.
   */
  const getUserLocation = () => {
    if (navigator.geolocation && window?.google?.maps) {
      let latlng;
      navigator.geolocation.getCurrentPosition((position) => {
        latlng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            const result =
              results.find(
                (value) =>
                  value.types &&
                  value.types[0] === 'locality' &&
                  value.types[1] === 'political'
              ) || results[3];
            if (result) {
              latlng.place = result.formatted_address;
              setUserLocation(latlng);
            }
          }
        });
      });
    }
  };
  useEffect(() => {
    if (!userLocation) {
      getUserLocation();
    }
  });

  return (
    <section className="tabbed__block">
      <div className="container">
        <h2 className="headline">
          <Text field={title} />
        </h2>
        <nav>
          <div className="nav nav__tabs" id="jobs" role="tablist">
            {items?.map((item, navTabIndex) => (
              <button
                style={{ background: 'transparent', border: 0 }}
                key={'tabbedNavItemNo_' + navTabIndex}
                className={`nav__item nav__link ${
                  currentActiveTabIndex === navTabIndex ? 'active' : ''
                }`}
                id={'nav-tab' + navTabIndex}
                data-toggle="tab"
                role="tab"
                aria-controls={'navTabGroupNo_' + navTabIndex}
                aria-selected={currentActiveTabIndex === navTabIndex ? true : false}
                onClick={() => tabItemClicked(navTabIndex)}
              >
                {item?.fields?.title?.value}
              </button>
            ))}
            <span className="slider"></span>
          </div>
        </nav>
        <div className="tab__content" id="jobs-content">
          {items?.map((item, navTabIndex) => {
            return (
              <>
                <div
                  key={'tab__content_no_' + navTabIndex}
                  className={`tab__pane fade ${
                    currentActiveTabIndex === navTabIndex ? 'show active' : ''
                  }`}
                  id={'navTabGroupNo_' + navTabIndex}
                  role="tabpanel"
                  aria-labelledby={'nav-tab' + navTabIndex}
                >
                  <div className="row justify-content-center">
                    {item?.fields?.items?.map((itm, cardIndex, arr) => {
                      if (cardIndex > 5) {
                        // do not show more than 6 cards
                        return null;
                      }
                      if (arr?.length >= 5) {
                        // if there are 5 cards, stack 3 then 2
                        // if there are 6 cards, stack 3 then 3
                        return (
                          <HomeIconCard
                            key={'homeIconCarD_' + cardIndex}
                            title={itm?.fields?.text?.value}
                            cardUrl={
                              itm?.fields?.url?.value +
                              `${
                                navTabIndex === 1 && userLocation
                                  ? itm?.fields?.url?.value?.includes('?')
                                    ? '&'
                                    : '?' +
                                      'latitude=' +
                                      userLocation?.lat +
                                      '&longitude=' +
                                      userLocation.lng +
                                      '&place=' +
                                      userLocation.place.replace(/\s/g, '+')
                                  : ''
                              }`
                            }
                            img={itm?.fields?.icon?.value}
                          />
                        );
                      }
                      if (arr?.length === 4) {
                        // if there are 4 cards - stack 2, then 2
                        return (
                          <HomeIconCard
                            cardBodyClass="col-6"
                            key={'homeIconCarD_' + cardIndex}
                            title={itm?.fields?.text?.value}
                            cardUrl={
                              itm?.fields?.url?.value +
                              `${
                                navTabIndex === 1 && userLocation
                                  ? itm?.fields?.url?.value?.includes('?')
                                    ? '&'
                                    : '?' +
                                      'latitude=' +
                                      userLocation?.lat +
                                      '&longitude=' +
                                      userLocation.lng +
                                      '&place=' +
                                      userLocation.place.replace(/\s/g, '+')
                                  : ''
                              }`
                            }
                            img={itm?.fields?.icon?.value}
                          />
                        );
                      }
                      // if there are 1-3 cards – stack on a single row
                      return (
                        <HomeIconCard
                          key={'homeIconCarD_' + cardIndex}
                          title={itm?.fields?.text?.value}
                          cardUrl={
                            itm?.fields?.url?.value +
                            `${
                              navTabIndex === 1 && userLocation
                                ? itm?.fields?.url?.value?.includes('?')
                                  ? '&'
                                  : '?' +
                                    'latitude=' +
                                    userLocation?.lat +
                                    '&longitude=' +
                                    userLocation.lng +
                                    '&place=' +
                                    userLocation.place.replace(/\s/g, '+')
                                : ''
                            }`
                          }
                          img={itm?.fields?.icon?.value}
                        />
                      );
                    })}
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <div className="cta__wrap">
          <TextLink
            ctaText={ctaText}
            ctaUrl={ctaUrl}
            cssClass="button-text--large"
            underline
          />
        </div>
      </div>
    </section>
  );
};
TabbedBlock.defaultProps = {
  fields: {},
  params: {},
};

TabbedBlock.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({
      value: PropTypes.string,
    }),
    ctaText: PropTypes.shape({
      value: PropTypes.string,
    }),
    ctaUrl: PropTypes.shape({
      value: PropTypes.shape({}),
    }),
    items: PropTypes.shape([]),
  }),
  params: PropTypes.shape({}),
};
export default TabbedBlock;
