/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import Button from '../../../../core/globals/buttons/Button';
import Modal from '../../../../core/Modal';
import { logoutSuccess } from '../../../../../redux/actions/loginActions';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { dataFetcher } from '../../../../../../src/dataFetcher';
import { DELETE_ACCOUNT } from '../../../../../constants';
import { API_STATUS_CODES } from '../../../../../constants';
import { useHistory } from 'react-router-dom';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
/**
 * @description - DeleteAccount component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const DeleteAccount = ({ fields, t = () => {}, sitecoreContext }) => {
  const { alertMsg, content, ctaText } = fields;
  const history = useHistory();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const authReducer = useSelector((state) => state.authFunctionsReducer);
  const [loading, setLoading] = useState(false);

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const handleYes = () => {
    getDeleteResult();
    handleModal();
  };

  const handleNo = () => {
    handleModal();
  };

  const getDeleteResult = () => {
    setLoading(true);
    dataFetcher(DELETE_ACCOUNT.DELETE_ACC_API, 'POST')
      .then((response) => {
        setLoading(false);
        if (response?.data?.status === API_STATUS_CODES.SUCCESS) {
          toast.success(t('delete-success'), {
            position: 'top-center',
          });
          history.push('/');
          setTimeout(() => {
            dispatch(logoutSuccess());
            authReducer.logout();
          }, 2000);
        } else if (response?.data?.status === API_STATUS_CODES.ERROR) {
          setLoading(false);
          toast.error(t('delete-fail'), {
            position: 'top-center',
          });
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error(t('delete-fail'), {
          position: 'top-center',
        });
      });
  };
  const deleteAlertMsg = <RichText field={alertMsg} className="text" />;
  return (
    <div className="delete-section">
      <RichText field={content} className="text" />
      {ctaText && (
        <>
          <Button
            cssClass="dark-red"
            text={ctaText?.value}
            handleButtonClick={handleModal}
            isLoading={loading}
            isDisabled={loading}
            ctaLabel="opens in a modal window"
          />
          <Modal showModal={showModal} handleModal={handleModal} size="lg">
            <div className="inner-modal-container" key="body">
              {deleteAlertMsg}
              <div className="confirm-button-container">
                <Button
                  cssClass="yes-button"
                  handleButtonClick={handleYes}
                  text={t('delete-yes')}
                />
                <Button handleButtonClick={handleNo} text={t('cancel')} />
              </div>{' '}
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

DeleteAccount.defaultProps = {
  fields: {
    alertMsg: {
      value: '',
    },
    content: {
      value: '',
    },
    ctaText: {
      value: '',
    },
  },
};

DeleteAccount.propTypes = {
  fields: PropTypes.shape({
    content: PropTypes.shape({ value: PropTypes.string }),
    ctaText: PropTypes.string.isRequired,
  }),
};

export default withSitecoreContext()(withTranslation()(DeleteAccount));
