import { createGlobalStyle } from 'styled-components';

export const CardScrollStyle = createGlobalStyle`
.card-scroll {
  &.blue {
      background-color: ${({ theme }) => theme?.secondaryBackgroundColor} !important;
      .card-scroll-header {
          .title {
            color: ${({ theme }) => theme?.secondaryTitleText} !important;
          }
        }
        .card.event .card-body {
          border-color: ${({ theme }) => theme?.secondaryButton} !important;
      }
      .slick-slider {
          .slick-arrow {
            &.slick-prev {
              &:after {
                  border-color: ${({ theme }) =>
                    theme?.secondaryTitleText} !important;
              }
          }
            &.slick-next {
              &:after {
                  border-color: ${({ theme }) =>
                    theme?.secondaryTitleText} !important;
              }
          }
      }
  }
  }
  &.connector{
    .card-scroll-header {
      .title {
        color: ${({ theme }) => theme?.primaryTitleText} !important;
      }
    }
      .slick-slider {
          .slick-arrow {
            &.slick-prev {
              &:after {
                  border-color: ${({ theme }) => theme?.primaryTitleText} !important;
              }
            }
            &.slick-next {
              &:after {
                  border-color: ${({ theme }) => theme?.primaryTitleText} !important;
              }
              }
            }
          }
        }
}
`;
