import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import MenuLink from '../globals/MenuLink';
import {
  checkArray,
  isDataExists,
  checkUrl,
  checkDataExists,
  pushNavToDataLayer,
} from '../../../utils/helperUtils';
import './subMenu.scss';

/**
 * @description - Check whether the current link is active.
 * @param {string} url - Current url.
 * @param {string} currentPath - Active Path.
 * @returns {boolean} - Returns whether the link is active or not.
 */
const isActive = (url, currentPath) => {
  if (url && currentPath) {
    const urlVal = url.split('?')[0];
    const urlLower = urlVal.toLowerCase();
    return urlLower === currentPath.toLowerCase();
  }
};

/**
 * @description - Get menu items.
 * @param {Array} items -Items array.
 * @param {string} path - Current location.
 * @returns {Node} - HTML menu items.
 */
const getMenuItem = (items, path) => {
  let mainMenu = null;
  if (isDataExists(items)) {
    mainMenu = items.map((route, index) => {
      const { name } = route;
      const { url, title } = route.fields || { title: {}, url: {} };
      const isLinkActive = isActive(checkUrl(route), path);
      return (
        <div className="sub-nav-menu-item" key={`${name}_${index}`}>
          <MenuLink
            url={url}
            routeName={title}
            activeLinkClass={isLinkActive ? 'sub-menu-active' : ''}
            clickHandler={pushNavToDataLayer}
            actionParams={['Flyout Nav', title?.value]}
          />
        </div>
      );
    });
  }
  return mainMenu;
};

/**
 * @description _ Login Componnet.
 * @param {object} props - Input props.
 * @returns {Node} - HTML node.
 */
const HeaderSubMenu = (props) => {
  const { menuItem, isParentActive } = props;
  const location = useLocation();
  const path = location.pathname;
  const [focused, setFocused] = useState(false);

  return (
    <div
      className={`main-nav-item ${focused ? 'link-focused' : ''}`}
      onFocus={() => {
        if (document && !document.getElementsByClassName('hide_outline').length)
          setFocused(true);
      }}
      onBlur={() => setFocused(false)}
      role="menubar"
      aria-expanded={focused}
    >
      <MenuLink
        url={menuItem.url}
        routeName={menuItem.title}
        activeLinkClass={isParentActive ? 'header-link-active' : ''}
        clickHandler={pushNavToDataLayer}
        actionParams={['Top Nav', menuItem?.title?.value]}
        ariaProps={{ 'aria-expanded': focused }}
      />
      <div
        className="main-nav-dropdown nav-sub-menu"
        aria-label="Sub Navigation"
        role="menuitem"
      >
        <div className="main-nav-dropdown-inside">
          <div className="triangle"></div>
          <div className="main-nav-sub-menu">
            {checkArray(menuItem.items).map((route, index) => {
              const { name } = route;
              const { url, title, items, isHighlighted } = route.fields || {
                title: {},
                url: {},
                items: [],
              };
              const isLinkActive = isActive(checkUrl(route), path);
              const mainMenu = (
                <div
                  className={classNames('sub-nav-menu-item', {
                    'parent-sub-nav':
                      isDataExists(items) || checkDataExists(isHighlighted),
                  })}
                >
                  <MenuLink
                    url={url}
                    routeName={title}
                    activeLinkClass={isLinkActive ? 'sub-menu-active' : ''}
                  />
                </div>
              );
              const subMenu = getMenuItem(items, path);
              return (
                <Fragment key={`${name}_${index}`}>
                  {mainMenu}
                  {subMenu}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

HeaderSubMenu.propTypes = {
  menuItem: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    url: PropTypes.shape({}),
    title: PropTypes.shape({}),
  }),
  isParentActive: PropTypes.bool,
};

HeaderSubMenu.defaultProps = {
  menuItem: {},
  isParentActive: false,
};

export default HeaderSubMenu;
