/* eslint-disable complexity */
/* eslint-disable react/prop-types */
/* eslint-disable valid-jsdoc */
/* eslint-disable max-lines-per-function */
/* eslint-disable require-jsdoc */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ButtonLink from '../globals/link/ButtonLink';
import { generateID } from '../../../utils/helperUtils';
import {
  withSitecoreContext,
  Text,
  RichText,
  Image,
} from '@sitecore-jss/sitecore-jss-react';
import VidyardPlayer from '../../business/VidyardPlayer';
import WistiaPlayer from '../../business/WistiaPlayer';
import YoutubePlayer from '../../business/YoutubePlayer';
import VimeoPlayer from '../../business/VimeoPlayer';
import $ from 'jquery';
import { GET_ITEM_DETAILS } from '../../../constants';
import { dataFetcher } from '../../../dataFetcher';
import ActionBarSocialShare from '../ActionBars/ActionBarSocialShare';
import { DATE_FORMATS } from '../../../utils/enums';
import moment from 'moment';
import './page-banners.scss';

/**
 * @description - GatedContentPageBanner Insights.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template.
 */
const GatedContentPageBanner = (props) => {
  const { fields, sitecoreContext, rendering, t = () => {} } = props;
  const { heading, subHeading, text, ctaText, ctaUrl, image, date } = fields;

  const [componentSetting, setComponentSetting] = useState({});
  const itemId = rendering?.params?.componentColor;
  const compId = rendering?.params?.anchorLinkId || 'gated-content-banner';
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';
  const dateFormat = DATE_FORMATS.DEFAULT_US_SMALL;
  const nullishDate = '0001-01-01T00:00:00Z';
  const isValidDate = nullishDate === date?.value;

  /**
   * @description - Get Item Details By Item Id.
   * @returns {undefined}
   */
  const getItemDetails = (id) => {
    dataFetcher(`${GET_ITEM_DETAILS}?itemID=${id}`).then((response) => {
      setComponentSetting(response);
    });
  };
  useEffect(() => {
    itemId && getItemDetails(itemId);
  }, [itemId]);

  const fontColor = componentSetting?.data || {};
  const backgroundColor = componentSetting?.data?.BackgroundColor || '';
  const cssClass = componentSetting?.data?.CssClass.toLowerCase() || '';
  const defaultClass = backgroundColor ? '' : 'default';

  let bg_color_code = [];
  const updatebackgroundColor = () => {
    bg_color_code = backgroundColor ? backgroundColor : '';
    bg_color_code =
      bg_color_code?.indexOf(',') > -1 ? bg_color_code?.split(',') : bg_color_code;
    if (backgroundColor && backgroundColor.indexOf(',') > -1) {
      bg_color_code = `linear-gradient(${bg_color_code[0]}, ${bg_color_code[1]})`;
    } else {
      bg_color_code = bg_color_code;
    }
    return bg_color_code;
  };

  const videoDisplay = (
    <div className="row">
      <div className="player-wrapper"></div>
      <div className="video-cont">
        {fields?.source?.value === 'Youtube' && (
          <YoutubePlayer
            playerid={generateID(5)}
            videoUrl={fields?.videoCode?.value}
            videoPreviewImg={fields?.thumbnail}
            videoDuration={fields?.duration}
            title={fields?.title}
            color={fontColor?.FontColor}
          />
        )}
        {fields?.source?.value === 'Wistia' && (
          <WistiaPlayer
            playerid={generateID(5)}
            videoUrl={fields?.videoCode?.value}
            videoPreviewImg={fields?.thumbnail}
            videoDuration={fields?.duration}
            title={fields?.title}
            color={fontColor?.FontColor}
          />
        )}
        {fields?.source?.value === 'Vidyard' && (
          <VidyardPlayer
            playerid={generateID(5)}
            videoUrl={fields?.videoCode?.value}
            videoPreviewImg={fields?.thumbnail}
            videoDuration={fields?.duration}
            title={fields?.title}
            color={fontColor?.FontColor}
          />
        )}
        {fields?.source?.value === 'Vimeo' && (
          <VimeoPlayer
            playerid={generateID(5)}
            videoUrl={fields?.videoCode?.value}
            videoPreviewImg={fields?.thumbnail}
            videoDuration={fields?.duration}
            title={fields?.title}
            color={fontColor?.FontColor}
          />
        )}
      </div>
    </div>
  );

  useEffect(() => {
    /**
     * @description - loads the fancybox library.
     * @returns {*} - Returns nothing.
     */
    async function loadFancy() {
      await require('@fancyapps/fancybox');
      await require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');
    }

    $(function () {
      if (!window.jQuery) window.jQuery = $;
      loadFancy();
    });
  }, []);
  return (
    <Fragment>
      <section
        className={`banner-section campaigns ${brandName} ${defaultClass}`}
        id={`${compId}`}
        style={{
          background: backgroundColor ? updatebackgroundColor() : 'none',
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className={`sub-header ${cssClass} ${defaultClass}`}>
                <div className="sub-title" style={{ color: fontColor?.FontColor }}>
                  <Text field={subHeading} />
                </div>
                <div className="date" style={{ color: fontColor?.FontColor }}>
                  {!isValidDate &&
                    `${t('published-date')} ${moment(date?.value).format(
                      dateFormat
                    )}`}
                </div>
              </div>
              <h1 className="title" style={{ color: fontColor?.FontColor }}>
                <Text field={heading} />
              </h1>
              <RichText
                field={text}
                className="large"
                tag="div"
                style={{ color: fontColor?.FontColor }}
              />
              <div className="featured-image mobile">
                {/* <Image editable={true} field={image} /> */}
                {fields?.source?.value !== '' && fields?.videoCode?.value !== '' ? (
                  videoDisplay
                ) : (
                  <Image className="responsive" editable={true} field={image} />
                )}
              </div>
              {ctaUrl && (
                <ButtonLink cssClass="orange-sd" ctaText={ctaText} ctaUrl={ctaUrl} />
              )}
              <div className={`${cssClass} ${defaultClass}`}>
                <ActionBarSocialShare fields={fields} />
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1 col-md-12 featured-image">
              {fields?.source?.value !== '' && fields?.videoCode?.value !== '' ? (
                videoDisplay
              ) : (
                <Image className="responsive" editable={true} field={image} />
              )}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

GatedContentPageBanner.defaultProps = {
  fields: {
    heading: { value: '' },
    subHeading: { value: '' },
    text: { value: '' },
    ctaText: { value: '' },
    ctaUrl: { value: '#' },
    image: {
      value: { src: 'http://via.placeholder.com/400x400/999999/666666' },
    },
    date: { value: '' },
  },
  sitecoreContext: {},
  rendering: {},
};

GatedContentPageBanner.propTypes = {
  fields: PropTypes.shape({
    heading: PropTypes.shape({ value: PropTypes.string.isRequired }),
    subHeading: PropTypes.shape({ value: PropTypes.string.isRequired }),
    text: PropTypes.shape({ value: PropTypes.string.isRequired }),
    ctaText: PropTypes.shape({ value: PropTypes.string }),
    ctaUrl: PropTypes.shape({ value: PropTypes.string }),
    image: PropTypes.shape({ value: PropTypes.string.isRequired }),
    date: PropTypes.shape({ value: PropTypes.string.isRequired }),
  }).isRequired,
  sitecoreContext: {},
  rendering: {},
};
//export default withSitecoreContext()(GatedContentPageBanner);
export default withTranslation()(withSitecoreContext()(GatedContentPageBanner));
