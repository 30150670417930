/* eslint-disable */
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import DynamicForm from '../../../core/Forms/DynamicForm';
import { coverSave, removeCover } from '../../../../redux/actions/actions';
// import { saveCoverLetterInfo } from '../../../../redux/actions/actions';
import {
  saveProfileInfoForm,
  saveProfileInfoFormCount,
  saveProfileInfoFormCountIfFormValid,
  saveCoverLetterInfo,
} from '../../../../redux/actions/actions';
import { formObjIteration } from '../../Utils/helper';
import { traverseAndDataExist } from '../../../../utils/helperUtils';

/**
 * @description - Upload Resume Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const CoverLetters = ({ inputRef, t, ...props }) => {
  const {
    fields,
    profileInfoFormData,
    profileInfo,
    componentName,
    getEmptyFields,
    // uploadFields,
  } = props;
  const {
    register,
    handleSubmit,
    getValues,
    errors,
    setValue,
    control,
    formState,
    setError,
    clearErrors,
    watch,
  } = useForm({
    mode: 'onBlur',
    revalidate: 'onBlur',
  });
  const { title, subTitle, items = [] } = fields;
  const formSubmitRef = useRef();
  const dispatch = useDispatch();
  const [triggered, settriggered] = useState(false);
  const [data, setData] = useState({});
  const [coverletterInfo, flattenCoverletterInfo] = useState({});
  const [candidateId, setCandidateId] = useState('');
  const [fileUpload, setFileUpload] = useState({});
  const [fieldList, setFieldList] = useState([]);

  const { isValid, isDirty, touched, isValidating } = formState;

  useEffect(() => {
    let emptyObj = {};
    emptyObj[componentName] = isValid ? 0 : 1;
    getEmptyFields(emptyObj);
  }, [isValid]);

  useEffect(() => {
    setTimeout(() => {
      let isEmpty = !traverseAndDataExist(getValues());
      dispatch(saveCoverLetterInfo({ isDirty, isValid, touched, isEmpty }));
    }, 500);
  }, [isValid, isDirty, touched, isValidating]);

  useEffect(() => {
    if (profileInfo?.id) {
      setCandidateId(profileInfo?.id);
      flattenCoverletterInfo({ data: profileInfo?.personalDetails });
    }
  }, [profileInfo]);

  // useEffect(() => {
  //   if (Object.keys(cover).length > 0) dispatch(coverSave(cover));
  // }, [cover]);

  // useEffect(() => {
  //   if (fieldList.length > 0) dispatch(removeCover(fieldList));
  // }, [fieldList]);

  const handleUpload = (fileData, name) => {
    const fileList = fileUpload;
    const obj = { [name]: fileData };
    if (fileUpload.hasOwnProperty(name)) {
      delete fileList[name];
    }
    setFileUpload({ ...fileList, ...obj });

    // if (!uploadFields.includes(name)) {
    //   dispatch(saveField(name));
    // }
  };

  const handleDelete = (id, name, index) => {
    if (fileUpload.hasOwnProperty(name)) {
      const fileList = fileUpload;
      const temp = fileUpload[name];
      delete temp[index];
      delete fileList[name];
      const newtemp = temp?.filter((item) => item);
      const obj = { ...fileUpload, [name]: newtemp };
      setFileUpload({ ...fileList, ...obj });
    }
    if (id) {
      setFieldList([...fieldList, { fileid: id, type: name }]);
    }
  };

  /**
   * @description - useImperativeHandle function.
   * @returns {undefined}
   */
  useImperativeHandle(inputRef, () => ({
    trigger: () => {
      settriggered(true);
      setTimeout(() => dispatch(saveProfileInfoFormCount(5)), 50);
      let event;
      if (typeof Event === 'function') {
        event = new Event('submit', { cancelable: true });
      } else {
        event = document.createEvent('Event');
        event.initEvent('submit', false, { cancelable: true });
      }
      formSubmitRef?.current?.dispatchEvent(event);
    },
  }));

  const transform = (onSubmitFunc) => (data) => {
    let formattedData = Object.assign({}, data);
    formattedData = formObjIteration(data);
    onSubmitFunc(formattedData);
  };

  /**
   * @description - onsubmit hander.
   * @returns {undefined}
   */
  const onSubmit = (formData) => {
    dispatch(
      saveProfileInfoForm({
        ...profileInfoFormData?.data,
        EditCoverLetters: formData,
        files: { ...profileInfoFormData?.data?.files, ...fileUpload },
        deletedFiles: [
          ...new Set([...profileInfoFormData?.data?.deletedFiles, ...fieldList]),
        ],
      })
    );
    if (triggered) {
      settriggered(false);
      dispatch(saveProfileInfoFormCountIfFormValid(5));
    }
  };

  return (
    <section className="cover-upload">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="cover-letter-text">{subTitle?.value}</div>
            <div className="col-lg-8 offset-lg-3 coverWrapper">
              <form
                autoComplete="off"
                onSubmit={handleSubmit(transform(onSubmit))}
                ref={formSubmitRef}
              >
                <div className="upload-cover-form">
                  <DynamicForm
                    formData={items}
                    register={register}
                    errors={errors}
                    getValues={getValues}
                    setValue={setValue}
                    setError={setError}
                    clearErrors={clearErrors}
                    handleUpload={handleUpload}
                    values={coverletterInfo}
                    handleDelete={handleDelete}
                    control={control}
                    APIQuerryParams={{ cid: candidateId }}
                    t={t}
                    validateOnLoad={true}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

CoverLetters.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
  getEmptyFields: PropTypes.func.isRequired,
  profileInfoFormData: PropTypes.shape({}).isRequired,
};

CoverLetters.defaultProps = {
  fields: {},
  getEmptyFields: () => {},
  profileInfoFormData: {},
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
    profileInfoFormData: state.profileInfoFormReducer,
  };
};

const ConnectedComponent = connect(mapStateToProps)(CoverLetters);
export default forwardRef((props, ref) => {
  return <ConnectedComponent {...props} inputRef={ref} />;
});
