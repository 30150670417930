import { createGlobalStyle } from 'styled-components';

export const AccordionSectionStyle = createGlobalStyle`
.accordion-content {
    a{
        color: ${({ theme }) => theme?.secondaryButton}
        &:hover {
            color: ${({ theme }) => theme?.secondaryButton};
            opacity: 0.5;
        }
    }
}
`;
