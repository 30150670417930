import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import TextLink from '../globals/link/TextLink';
import { showString } from '../../../utils/helperUtils';
import { DATE_FORMATS } from '../../../utils/enums';
// import StaticLink from '../globals/link/StaticLink';
import './cards.scss';

/**
 * @description - Render button or arraow link.
 * @param {string} mode - Mode for display.
 * @param {string|object} ctaText - CTA name.
 * @returns {Node} - Button or arrow link.
 */
const CardArticleCta = ({
  title,
  subTitle,
  date,
  ctaText,
  ctaUrl = null,
  dateFormat,
}) =>
  ctaUrl ? (
    <Link to={ctaUrl}>
      <div className="card article-ctascroll card--wipe">
        <span className="card--wipe--apollo" />
        <div className="card-body">
          {subTitle?.value ? (
            <RichText tag="div" className="sub-title articleCta" field={subTitle} />
          ) : (
            <div className="sub-title articleCta"> {showString(subTitle)}</div>
          )}
          <div className="date">{date ? moment(date).format(dateFormat) : ''}</div>
          {title?.value ? (
            <RichText tag="div" className="title" field={title} />
          ) : (
            <div className="title">{showString(title)}</div>
          )}
        </div>
        {ctaText?.value && ctaUrl ? (
          <div className="cta__wrap card-articlefooter">
            <TextLink ctaText={ctaText} ctaUrl={ctaUrl} arrow />
          </div>
        ) : (
          ''
        )}
      </div>
    </Link>
  ) : (
    <div className="card article-ctascroll card--wipe">
      <span className="card--wipe--apollo" />
      <div className="card-body">
        {subTitle?.value ? (
          <RichText tag="div" className="sub-title articleCta" field={subTitle} />
        ) : (
          <div className="sub-title articleCta"> {showString(subTitle)}</div>
        )}
        <div className="date">{date ? moment(date).format(dateFormat) : ''}</div>
        {title?.value ? (
          <RichText tag="div" className="title" field={title} />
        ) : (
          <div className="title">{showString(title)}</div>
        )}
      </div>
    </div>
  );

CardArticleCta.defaultProps = {
  title: '',
  subTitle: '',
  date: 'January 1, 1001',
  ctaText: 'Read More',
  ctaUrl: '',
  dateFormat: DATE_FORMATS.DEFAULT_US,
  external: false,
};

CardArticleCta.propTypes = {
  title: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  dateFormat: PropTypes.string.isRequired,
  /** Source or type of article */
  subTitle: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  date: PropTypes.string.isRequired,
  ctaText: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  ctaUrl: PropTypes.string.isRequired,
  external: PropTypes.bool,
};

export default CardArticleCta;
