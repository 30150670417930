/* eslint-disable */
/* eslint-disable max-lines-per-function */
/*eslint complexity: ["error", 22]*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext, mediaApi } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { useDispatch, connect, useSelector } from 'react-redux';

import ButtonLink from '../globals/link/ButtonLink';
import Button from '../globals/buttons/ButtonWithIcon';
import ActionBarSocialShare from './ActionBarSocialShare';
import AlertInline from '../../core/ErrorBoundary/AlertInline';
import { SEARCH, API_STATUS_CODES } from '../../../constants';
import { addSavedJob } from '../../../redux/actions/actions';
import AuthWrapper from '../../core/LoginMSAL/AuthWrapper';
import { authstates, TYPES } from '../../../utils/enums';
import ApplyJob from '../Search/SearchJob/ApplyJob';
import './actionbarjob.scss';

/**
 * @description - to show action bar items (save/share)
 * @param {object} props - job action bar properties
 * @returns {node} html
 */
const ActionBarJob = (props) => {
  const {
    apply,
    applyCtaUrl,
    reversed,
    blue,
    fullPageUrl,
    socialFields,
    sitecoreContext,
    t,
    isApplyButton,
    jobDetails,
    handlePreview,
    savedjobs,
    jobShareUrl,
    emailContent,
    icons,
    applyJobUrl,
    isButtonActive = false,
    classDetails = '',
  } = props;
  const { jobItemID, isExpired, isSaved } = jobDetails;
  let {
    iconSave,
    iconSaved,
    iconShare,
    iconFullPage,
    iconSaveDetail,
    iconSavedDetail,
    iconShareDetail,
  } = {};

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();
  const loginReducer = useSelector((state) => state.loginReducer);
  const brandName = sitecoreContext?.Country?.brandName?.toUpperCase();

  const jobSaved = isSaved || savedjobs.indexOf(jobItemID) !== -1 ? true : false;
  const [inlineMessage, setInlineMessage] = useState(false);
  /**
   * @description to set social share icons
   * @returns {undefined} -  no returns
   */
  const setIcons = () => {
    if (Array.isArray(icons)) {
      icons.forEach((iconItem) => {
        switch (iconItem?.itemName) {
          case 'Save':
            iconSave = iconItem.icon;
            break;
          case 'Saved':
            iconSaved = iconItem.icon;
            break;
          case 'Share':
            iconShare = iconItem.icon;
            break;
          case 'Full Page':
            iconFullPage = iconItem.icon;
            break;
          case 'SaveDetail':
            iconSaveDetail = iconItem.icon;
            break;
          case 'SavedDetail':
            iconSavedDetail = iconItem.icon;
            break;
          case 'ShareDetail':
            iconShareDetail = iconItem.icon;
            break;
          default:
            break;
        }
      });
    }
  };

  setIcons();

  /**
   * @description to set button visibility
   * @param {string} applyCtaUrl - url
   * @param {string} isButtonActive - bool status
   * @returns {boolean} - boolean
   */
  const checkVisibility = (handlePreview, isButtonActive) => {
    return handlePreview && !isButtonActive;
  };
  const setShareVaccancyEvent = (setValue) => {
    const { jobDetails } = props;
    if (setValue && window?.dataLayer) {
      window.dataLayer.push({
        event: 'shareVacancy',
        products: {
          name: jobDetails?.jobTitle,
          id: jobDetails?.jobID,
          brand: jobDetails?.companyName ? jobDetails?.companyName : null,
          category: jobDetails?.domain ? jobDetails?.domain : null,
          variant: jobDetails?.employmentType ? jobDetails?.employmentType : null,
          dimension11: `Location: ${jobDetails?.jobLocation} | Education:   ${
            jobDetails?.education ? jobDetails.education.join(', ') : null
          } | Salary: ${jobDetails?.salaryRate} | Hours: ${jobDetails?.jobType}`,
        },
      });
    }
  };

  useEffect(() => {
    if (
      loginReducer.initialized &&
      loginReducer.state === authstates.AUTHENTICATED
    ) {
      const savejobButtonClickedToLogin = sessionStorage.getItem(
        'savejobButtonClickedToLogin'
      );
      if (savejobButtonClickedToLogin === 'true') {
        //job save flow
        const saveJobBtnClickDetails = JSON.parse(
          sessionStorage.getItem('saveJobBtnClickDetails')
        );
        if (jobItemID === saveJobBtnClickDetails.jobItemID) {
          sessionStorage.removeItem('savejobButtonClickedToLogin');
          sessionStorage.removeItem('saveJobBtnClickDetails');
          handleSave(null, loginReducer.state);
          if (window?.dataLayer) {
            window.dataLayer.push({
              event: 'saveVacancy',
              products: {
                name: jobDetails?.jobTitle,
                id: jobDetails?.jobID,
                brand: jobDetails?.companyName ? jobDetails?.companyName : null,
                category: jobDetails?.domain,
                variant: jobDetails?.employmentType,
                dimension11: `Location: ${jobDetails?.jobLocation} | Education: ${
                  jobDetails?.education ? jobDetails.education.join(', ') : null
                } | Salary: ${jobDetails?.salaryRate} | Hours: ${
                  jobDetails?.jobType
                }`,
              },
            });
          }
        }
      }
    }
  }, []);
  useEffect(() => {
    setInlineMessage(false);
  }, [jobDetails]);

  /**
   * @description - login user and saves job
   * @param {*} login - to login
   * @returns {undefined} -
   */
  const loginUserAndSaveJob = (login) => {
    if (login !== null && typeof window !== 'undefined') {
      sessionStorage.setItem(
        'saveJobBtnClickDetails',
        JSON.stringify({
          jobItemID,
        })
      );
      sessionStorage.setItem('savejobButtonClickedToLogin', true);
      localStorage.setItem(
        'authenticationType',
        'Apply > Create Account/Apply > Sign in'
      );
      login(null, 'saveJob');
    }
  };

  /**
   * @description - to save job
   * @param {function} login - to login
   * @param {string} authenticationState - current auth state
   * @returns {undefined} -
   */
  const handleSave = async (login, authenticationState) => {
    setLoading(true);
    setError('');
    setMessage('');
    authenticationState === authstates.AUTHENTICATED
      ? await axios
          .get(`${SEARCH.JOB_SEARCH.SAVE_JOB_API}`, {
            params: { jobItemID: jobItemID },
          })
          .then((result) => {
            setLoading(false);
            if (result.data.status === API_STATUS_CODES.SUCCESS) {
              dispatch(addSavedJob(jobItemID));
              setMessage(t('job-save-success'));
              setInlineMessage(true);
            } else if (result.data.status === API_STATUS_CODES.DATA_EXIST) {
              dispatch(addSavedJob(jobItemID));
              setError(t('job-already-saved'));
              setInlineMessage(true);
            }
          })
          .catch(() => {
            setLoading(false);
            setError(t('error-save'));
            setInlineMessage(true);
          })
      : loginUserAndSaveJob(login);
  };

  /**
   * @description - to show dropdown
   * @returns {String} - classname
   */
  const showDropDown = () => (expanded ? 'show-dropdown' : '');

  /**
   * @description - to show dropdown
   * @returns {String} - classname
   */
  const noSaveShareDropDown = () =>
    iconSave && iconSaved ? '' : 'share-dropdown-nosave';

  return (
    <>
      <div className={`${classDetails ? 'job-actionbar-cta' : 'job-actionbar'}`}>
        {classDetails ? (
          isApplyButton && !isExpired ? (
            <ApplyJob
              reversedClass={false}
              cssClass={classDetails}
              apply={apply}
              jobDetails={jobDetails}
              blue={blue}
              reversed={reversed}
              applyJobUrl={applyJobUrl}
              revenue={sitecoreContext?.Country?.revenuePerApplication}
            />
          ) : (
            classDetails &&
            applyCtaUrl && (
              <div className={`${classDetails} cta cta-mobile`}>
                <ButtonLink
                  cssClass={`${reversed ? 'reversed' : ''} ${blue ? 'blue' : ''}`}
                  ctaText={t('view-details')}
                  ctaUrl={applyCtaUrl}
                  arrow
                />
              </div>
            )
          )
        ) : null}

        {!isExpired && ((iconSave && iconSaved) || iconSaveDetail) && (
          <AuthWrapper>
            {({ login, authenticationState }) => {
              return (
                <>
                  {TYPES.BANKPOWER !== brandName && (
                    <button
                      aria-label="save job"
                      className="save"
                      onClick={(e) => {
                        if (window?.dataLayer) {
                          window.dataLayer.push({
                            event: 'saveVacancy',
                            products: {
                              name: jobDetails?.jobTitle,
                              id: jobDetails?.jobID,
                              brand: jobDetails?.companyName
                                ? jobDetails?.companyName
                                : null,
                              category: jobDetails?.domain,
                              variant: jobDetails?.employmentType,
                              dimension11: `Location: ${
                                jobDetails?.jobLocation
                              } | Education: ${
                                jobDetails?.education
                                  ? jobDetails.education.join(', ')
                                  : null
                              } | Salary: ${jobDetails?.salaryRate} | Hours: ${
                                jobDetails?.jobType
                              }`,
                            },
                          });
                        }
                        e.preventDefault();
                        !jobSaved && handleSave(login, authenticationState);
                      }}
                    >
                      <div className="icon-bookmark">
                        {!loading ? (
                          <>
                            {!jobSaved && (
                              <img
                                src={
                                  classDetails
                                    ? mediaApi.updateImageUrl(iconSaveDetail)
                                    : mediaApi.updateImageUrl(iconSave)
                                }
                                alt=" "
                                aria-hidden="true"
                              />
                            )}
                            {jobSaved && (
                              <img
                                src={
                                  classDetails
                                    ? mediaApi.updateImageUrl(iconSavedDetail)
                                    : mediaApi.updateImageUrl(iconSaved)
                                }
                                alt=" "
                                aria-hidden="true"
                              />
                            )}
                          </>
                        ) : (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </div>
                      {jobSaved ? t('saved') : t('save')}
                    </button>
                  )}
                </>
              );
            }}
          </AuthWrapper>
        )}

        {!isExpired && (iconShare || iconShareDetail) && (
          <div
            className={`${showDropDown()} share-wrapper`}
            tabIndex={-1}
            role="menubar"
            onFocus={() => setExpanded(true)}
            // onBlur={() => setExpanded(false)}
            onMouseOver={() => setExpanded(true)}
            onMouseLeave={() => setExpanded(false)}
            // aria-label="Share job tooltip"
            // aria-labelledby="share-tooltip"
            // aria-expanded={expanded}
          >
            <div
              className="share"
              aria-label="Share job tooltip"
              aria-expanded={expanded}
              role="button"
              tabIndex={0}
            >
              <div className="icon-share">
                <img
                  src={
                    classDetails
                      ? mediaApi.updateImageUrl(iconShareDetail)
                      : mediaApi.updateImageUrl(iconShareDetail)
                  }
                  alt=" "
                />
              </div>
              {t('share')}
            </div>
            <div
              className={`share-dropdown ${noSaveShareDropDown()}`}
              onBlur={() => setExpanded(false)}
              id="share-tooltip"
              role="tooltip"
            >
              <div className="share-dropdown-inside">
                <div className="triangle"></div>
                <ActionBarSocialShare
                  fields={socialFields}
                  sharableUrl={jobShareUrl}
                  sitecoreContext={sitecoreContext}
                  emailContent={emailContent}
                  setShareEvent={setShareVaccancyEvent}
                />
              </div>
            </div>
          </div>
        )}

        {
          // show full page icon
          fullPageUrl && iconFullPage && (
            <Link to={fullPageUrl} className="full-page">
              <div className="icon-share">
                <img src={mediaApi.updateImageUrl(iconFullPage)} alt=" " />
              </div>
              {t('full-page')}
            </Link>
          )
        }

        {!classDetails ? (
          isApplyButton && !isExpired ? (
            <ApplyJob
              apply={apply}
              jobDetails={jobDetails}
              blue={blue}
              reversed={reversed}
              applyJobUrl={applyJobUrl}
              revenue={sitecoreContext?.Country?.revenuePerApplication}
            />
          ) : (
            !classDetails &&
            applyCtaUrl && (
              //d-block d-sm-none
              <div className="cta cta-mobile">
                <ButtonLink
                  cssClass={`${reversed ? 'reversed' : ''} ${blue ? 'blue' : ''}`}
                  ctaText={t('view-details')}
                  ctaUrl={applyCtaUrl}
                  arrow
                />
              </div>
            )
          )
        ) : null}

        {checkVisibility(handlePreview, isButtonActive) && (
          //d-none d-md-block d-lg-block d-xl-block
          <div className="cta cta-desktop ">
            <Button
              cssClass={`${reversed ? 'reversed' : ''} ${blue ? 'blue' : ''}`}
              text={apply}
              handleButtonClick={() => handlePreview()}
            />
          </div>
        )}
        <br aria-hidden={true} />
      </div>
      {inlineMessage && (
        <div className="action-bar-messages">
          {error && <AlertInline message={error} type="error" />}
          {message && <AlertInline message={message} type="successText" />}
        </div>
      )}
    </>
  );
};

ActionBarJob.defaultProps = {
  jobExpired: '',
  apply: '',
  applyCtaUrl: '',
  reversed: false,
  blue: '',
  fullPage: '',
  fullPageUrl: '',
  fields: {},
  sitecoreContext: {},
  isApplyButton: false,
  jobDetails: {},
  socialFields: [],
  t: () => {},
  handlePreview: null,
  savedjobs: [],
  jobShareUrl: '',
  emailContent: '',
  icons: [],
  classDetails: '',
};

ActionBarJob.propTypes = {
  jobExpired: PropTypes.bool,
  apply: PropTypes.string,
  applyCtaUrl: PropTypes.string,
  reversed: PropTypes.bool,
  blue: PropTypes.string,
  fullPage: PropTypes.string,
  fullPageUrl: PropTypes.string,
  fields: PropTypes.shape({}),
  sitecoreContext: PropTypes.shape({}),
  isApplyButton: PropTypes.bool,
  jobDetails: PropTypes.shape({}),
  socialFields: PropTypes.arrayOf([]),
  t: PropTypes.func,
  handlePreview: PropTypes.func,
  savedjobs: PropTypes.arrayOf([]),
  jobShareUrl: PropTypes.string,
  emailContent: PropTypes.string,
  icons: PropTypes.arrayOf([]),
};

const mapStateToProps = (state) => ({
  savedjobs: state.savedJobsReducer.data,
});

export default withTranslation()(
  withSitecoreContext()(connect(mapStateToProps)(ActionBarJob))
);
