import { createGlobalStyle } from 'styled-components';

export const HeaderStyles = createGlobalStyle`
.site-header{
  .site-header-bar{
    .main-nav{
      .main-nav-item{
        a{
          span {
            &:after {
              background-color: ${({ theme }) => theme?.secondaryButton}
            }
          }
          &:hover,
          &.header-link-active{
            color: ${({ theme }) => theme?.secondaryButton} !important;
          }
        }
      }
      .main-nav-user-block {
        .main-nav-user-links {
          a {     
             color: ${({ theme }) => theme?.primaryButton} !important;
            &:hover {
             color: ${({ theme }) => theme?.primaryButton} !important;
             opacity: 0.5;
            }
          }
        }
      }
    }
  }
  .main-nav-slideout {
    .slideout-nav-insights {
      border-bottom: 2px solid ${({ theme }) => theme?.normalButtonLink};
    }
    .slideout-nav-sidebar {
      .button-text {
        &.blue {
          color: ${({ theme }) => theme?.normalButtonLink} !important;
        }
      }
    }
    .slideout-nav-block {
      nav {
        a {
          &.sub-title {
            color: ${({ theme }) => theme?.secondaryButtonLink} !important
          }
        }
      }
    }
  }
}
.main-nav-slideout{
  background-color: ${({ theme }) => theme?.primaryColor}
}
.search-header{
  background-color: ${({ theme }) => theme?.primaryColor} !important;
}
.slideout-nav-sidebar{
  background-color: ${({ theme }) => theme?.primaryLightBackground};
}
  `;
