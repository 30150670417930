import React from 'react';
import PropTypes from 'prop-types';
import JobDetailsList from '../JobDetailsList/JobDetailsList';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import './JobHeader.scss';

const JobHeader = ({ sitecoreContext, ...props }) => {
  const { icons, headerDetails } = props;
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';
  const isStegmann = brandName?.toLowerCase().indexOf('stegmann') > -1;
  return (
    <section className={`job-header ${isStegmann ? 'reversetheme' : brandName}`}>
      <div className="container">
        <div className="row">
          <div className="col-xl-10 offset-xl-1">
            <div className="row">
              <div className="col">
                <div className="article-header-container">
                  <Breadcrumb fields={props?.bcItems} />
                  <h1 className="title reversed">{props?.title}</h1>
                  <JobDetailsList
                    mediaIcons={icons}
                    headerDetails={headerDetails}
                    hourPerWeek={headerDetails?.items?.hourPerWeek}
                    education={headerDetails?.items?.education}
                    noOfPositions={headerDetails?.items?.noOfPositions}
                    domain={headerDetails?.items?.domain}
                    jobType={headerDetails?.items?.contractType}
                    salaryRate={headerDetails?.items?.salaryRate}
                    referenceNumber={headerDetails?.items?.jobID}
                    datePosted={headerDetails?.items?.publishfromDate}
                    companyLogo={headerDetails?.items?.customerLogoUrl}
                    applyBy={headerDetails?.items?.applicationDeadlineDate}
                    formOfEmployment={headerDetails?.items?.employmentType}
                    openingParagraph={headerDetails?.items?.openingParagraph}
                    publicDescription={headerDetails?.items?.publicDescription}
                    companyDescription={headerDetails?.items?.companyDescription}
                    jobAdvertisementTeaser={
                      headerDetails?.items?.jobAdvertisementTeaser
                    }
                  />
                  {props?.actionBar && props.actionBar}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

JobHeader.defaultProps = {
  title: '',
};

JobHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default withSitecoreContext()(JobHeader);
