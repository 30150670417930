/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  withPlaceholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
// import Helmet from 'react-helmet';
import ActionBarJob from '../../ActionBars/ActionBarJob';
import { SEARCH } from '../../../../constants';
import { socialShareMapper } from '../../../../factory/jobSearchFilterFactory';
import { useSelector } from 'react-redux';
import './JobBlock.scss';
import '../articleblock.scss';
import '../../Breadcrumb/breadcrumb.scss';
import { jobVacancyEvent } from '../../../../../src/services/apiServices/gtmService';

import JobHeader from '../JobHeader/JobHeader';
import JobDetails from '../JobDetails';
/**
 *@description to show detailed job
 * @param {*} param0 -
 * @returns {node} - html
 */
const JobBlock = ({ fields, t, JobDetailSidebar, sitecoreContext }) => {
  const history = useHistory();
  const [isGoogleMapAvail, setGoogleMapAvail] = useState(null);
  const [bcItems, setBcItems] = useState();
  const { socialPlatforms, items, icons, applyJobUrl } = fields;
  // const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';
  const loginReducer = useSelector((state) => state.loginReducer);
  const loginState = loginReducer?.state;

  const jobDetails = items;
  const hideParams = true;

  const expiredSearchPageUrl = !jobDetails?.isExpired
    ? sitecoreContext?.Country?.expiredJobSearchURL?.toLowerCase()
    : '';

  const socialItems = socialShareMapper(socialPlatforms?.socialShares);

  useEffect(() => {
    return () => {
      if (sessionStorage) sessionStorage.removeItem('applyButtonClickedToLogin');
    };
  }, []);

  /**
   * Redirect to Expired Search Page.
   * @param {object} jobObjItem The Job details.
   * @param {boolean} hideSeparator The true or false.
   * @returns {string} Job Details URL Params.
   */
  const getJobDetailsParams = (jobObjItem, hideSeparator = false) => {
    const separatorSymb = hideSeparator ? '' : ' _ ';
    return jobObjItem === '' || jobObjItem === null || jobObjItem === undefined
      ? ''
      : jobObjItem + separatorSymb;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (typeof window !== 'undefined' && window?.google?.maps) {
        setGoogleMapAvail(window?.google?.maps);
        clearInterval(interval);
      }
    }, 100);
  }, []);
  /**
   * @description - get user's current location
   * @param {boolean} params object lat and long.
   * @returns {void} - void.
   */
  const saveURLParams = async (params) => {
    let latlng = {};
    if (navigator.geolocation && window?.google?.maps) {
      latlng = {
        lat: +params?.lat,
        lng: +params?.lng,
      };
      const geocoder = new window.google.maps.Geocoder();
      await geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const result =
            results.find(
              (value) =>
                value.types &&
                value.types[0] === 'locality' &&
                value.types[1] === 'political'
            ) || results[3];
          if (result) {
            Object.assign(latlng, { place: result?.formatted_address || '' });
          }
        }
      });
    }
    return latlng;
  };

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      jobDetails?.isExpired &&
      expiredSearchPageUrl
    ) {
      handleRedirect(jobDetails);
    }
  }, [isGoogleMapAvail]);
  /**
   * Redirect to Expired Search Page.
   * @param {string} jobObj The Job Object.
   * @param {string} Location The Job Location.
   * @returns {json} Job Search result.
   */
  const handleRedirect = async (jobObj) => {
    let searchLocation = {
      lat: jobObj?.lattitude || '',
      lng: jobObj?.longitude || '',
      place: jobObj?.jobLocation || '',
    };
    if (searchLocation?.lat && searchLocation?.lng) {
      const locationObj = await saveURLParams(searchLocation);
      searchLocation = locationObj?.place !== '' ? locationObj : searchLocation;
    }
    const jobDetailsParams =
      getJobDetailsParams(jobObj?.salaryRate) +
      getJobDetailsParams(searchLocation?.place) +
      `${
        getJobDetailsParams(jobObj?.jobType)
          ? getJobDetailsParams(jobObj?.jobType)
          : getJobDetailsParams(jobObj?.employmentType)
      }` +
      getJobDetailsParams(jobObj?.domain, hideParams);
    const isSeparatorAtEnd = jobDetailsParams?.trim().slice(-1) === '_';
    const finalParams = isSeparatorAtEnd
      ? jobDetailsParams?.trim().substring(0, jobDetailsParams?.trim().length - 1)
      : jobDetailsParams?.trim();
    const searchPageUrl = expiredSearchPageUrl
      ? expiredSearchPageUrl +
        `?page=1&searchKeyword=${jobObj?.jobTitle}&latitude=${
          searchLocation?.lat || ''
        }&longitude=${searchLocation?.lng || ''}&place=${
          searchLocation?.place || ''
        }&jobDetails=${finalParams}`
      : sitecoreContext?.Country?.jobSearchURL;
    history.push(searchPageUrl || '/');
  };

  useEffect(() => {
    const searchParams = localStorage.getItem(SEARCH.JOB_SEARCH.SAVED_SEARCH_PARAMS);
    setBcItems({
      BreadCrumbs: [
        {
          id: '',
          // name: `< ${t('back-to-search-results')}`,
          name: `${fields?.items?.jobTitle}`,
          path: {
            pathname: sitecoreContext?.Country?.jobSearchURL,
            search: searchParams,
            focusResult: true,
          },
          IsJobInclude: true,
        },
      ],
    });
    jobVacancyEvent(
      fields?.items,
      sitecoreContext?.Country?.revenuePerApplication,
      loginState
    );
  }, []);

  const actionBarElement = () => {
    return (
      <ActionBarJob
        classDetails="job-details-cta"
        apply={t('apply-now')}
        isApplyButton
        applyCtaUrl="#"
        socialFields={{ items: socialItems }}
        jobDetails={jobDetails}
        icons={icons?.mediaIcons}
        applyJobUrl={{ href: applyJobUrl?.applyJobUrl }}
        emailContent={{ headline: { value: items?.jobTitle } }}
      />
    );
  };

  return (
    <>
      {/* <Helmet>
          <meta property="og:title" content={ogTitle} />
          <meta property="og:image" content={customerLogoUrl} />
          <meta property="og:description" content={ogDescription} />
          <meta property="og:image:width" content="640" />
          <meta property="og:image:height" content="442" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={ogTitle} />
          <meta name="twitter:image" content={customerLogoUrl} />
          <meta name="twitter:description" content={ogDescription} />
        </Helmet> */}
      {jobDetails && (
        <>
          <JobHeader
            icons={icons?.mediaIcons}
            headerDetails={fields}
            title={fields?.items?.jobTitle}
            bcItems={bcItems}
            actionBar={actionBarElement()}
          />
          {jobDetails.isExpired &&
            (expiredSearchPageUrl ? (
              <section className="job-details-block">
                <div className="row">
                  <div className="col">
                    <div className="expired-job">
                      <div className="icon" />
                      <div>{t('job-expired-message')}</div>
                    </div>
                  </div>
                </div>
              </section>
            ) : null)}
          <JobDetails
            fieldsData={fields}
            jobListing={false}
            sideBarDetails={JobDetailSidebar}
          />
        </>
      )}
    </>
  );
};

JobBlock.defaultProps = {
  t: () => {},
  JobDetailSidebar: [],
  sitecoreContext: {},
  fields: {
    socialPlatforms: {},
    icons: {},
    items: {
      metaTitle: '',
      metaDescription: '',
    },
    applyJobUrl: {},
  },
};

JobBlock.propTypes = {
  t: PropTypes.func,
  JobDetailSidebar: PropTypes.arrayOf([]),
  sitecoreContext: PropTypes.shape({}),
  fields: PropTypes.shape({
    socialPlatforms: PropTypes.shape({}),
    icons: PropTypes.shape({}),
    items: PropTypes.shape({
      metaTitle: PropTypes.string,
      metaDescription: PropTypes.string,
    }),
    applyJobUrl: PropTypes.shape({}),
  }),
};

export default withSitecoreContext()(
  withTranslation()(
    withPlaceholder([{ placeholder: 'jss-right', prop: 'JobDetailSidebar' }])(
      JobBlock
    )
  )
);
