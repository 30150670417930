/* eslint-disable */
import React, { useState, useEffect, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import ButtonLink from '../../../core/globals/link/ButtonLink';
import AccordionBlock from '../../Accordion';
import Loader from '../../../core/ErrorBoundary/Loader';
import './buildProfileAccordion.scss';
import Button from '../../../core/globals/buttons/Button';
import TextButton from '../../../core/globals/buttons/TextButton';
import { buildProfileApi } from '../../../../services/apiServices/candidateService';
import { useHistory } from 'react-router-dom';
import CustomOverlayLoader from '../../../core/ErrorBoundary/CustomOverlayLoader';
import {
  clearProfileInfoFormCount,
  saveParse,
} from '../../../../redux/actions/actions';
import {
  buildProfile,
  percentagePerSection as defaultPercent,
  buildProfileItems,
} from '../../../../utils/enums';
import { JSONParser } from '../../../../utils/helperUtils';
import { withTheme } from 'styled-components';
import RouteLeavingGuard from './RouteLeavingGuard';
import { toast } from 'react-toastify';

/**
 * @description - BuildProfileAccordion component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const BuildProfileAccordion = ({ inputRef, theme, ...props }) => {
  const {
    fields,
    data,
    t,
    profileInfo,
    profileInfoFormData,
    buildProfileFormState,
    refObj,
    sitecoreContext,
  } = props;
  const {
    text,
    allowMultipleOpen,
    ctaText,
    ctaUrl,
    saveLink,
    mandatoryFields,
    percentagePerSection,
    buttonLayout,
  } = fields;
  const [profileDataLoading, setprofileDataLoading] = useState(true);
  const [buttonClickStatus, setButtonClickStatus] = useState({
    isReview: false,
    isLater: false,
  });
  const [reviewLoading, setReviewLoading] = useState(false);
  const [completeLaterLoading, setcompleteLaterLoading] = useState(false);
  const [triggerArray, setTriggerArray] = useState([]);
  const [isDataFilled, setDataFilled] = useState(false);
  const [noChangesToSave, setNoChanges] = useState(false);
  const mandatoryItems = mandatoryFields?.value
    ? mandatoryFields?.value.split(',')
    : [];
  const defaultPercentagePerSection = percentagePerSection?.value
    ? JSONParser(percentagePerSection?.value)
    : defaultPercent;
  // const [uploadItems, setUploadItems] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (profileInfo?.id) {
      setprofileDataLoading(false);
    } else if (profileInfo?.error) {
      setprofileDataLoading(false);
    }
  }, [profileInfo]);

  const isFormDirty = () => {
    let result = false;
    Object.keys(buildProfileFormState).forEach((k) => {
      if (Object.keys(buildProfileFormState[k]).length > 0) {
        if (Object.keys(buildProfileFormState[k]?.touched).length > 0) {
          result = true;
        }
      }
    });
    return result;
  };

  useEffect(() => {
    const handler = (e) => {
      if (isDataFilled) {
        e.preventDefault();
        const message = t('you-will-loose-do-you-wish-to-continue');
        e.returnValue = message;
        return message;
      }
      return null;
    };
    window.addEventListener('beforeunload', handler);
    return () => window.removeEventListener('beforeunload', handler);
  }, [isDataFilled]);

  useEffect(() => {
    if (buildProfileFormState) {
      if (isFormDirty()) {
        setDataFilled(true);
      }
    }
  }, [buildProfileFormState]);

  const getPercent = () => {
    let percent = defaultPercentagePerSection?.Default || 0;
    Object.keys(buildProfileFormState).forEach((key) => {
      if (
        buildProfileFormState[key].isValid &&
        !buildProfileFormState[key].isEmpty
      ) {
        percent += defaultPercentagePerSection[key] || 0;
      }
    });
    percent = parseInt(percent);
    return percent > 100 ? 100 : percent;
  };

  const saveLaterfn = () => {
    setDataFilled(false);
    setcompleteLaterLoading(false);
    dispatch(saveParse(false));
    toast.success(t('details-saved-successfully'), {
      position: 'top-center',
    });
    setButtonClickStatus({ isReview: false, isLater: false });
    history.push(saveLink?.value?.href);
  };

  const hideSaveLoader = () => {
    setcompleteLaterLoading(false);
    setButtonClickStatus({ isReview: false, isLater: false });
  };

  const showError = (type = '') => {
    toast.error(t('error-message'), {
      position: 'top-center',
    });
    setButtonClickStatus({ isReview: false, isLater: false });
  };

  const checkValid = () => {
    const validationArray = [];
    Object.keys(buildProfileFormState).forEach((k, i) => {
      if (triggerArray.includes(i)) {
        validationArray.push(buildProfileFormState[k].isValid);
      }
    });
    return !validationArray.includes(false);
  };

  const transform = (data) => {
    let tempData = {};
    Object.keys(data).forEach((k, index) => {
      if (buildProfileItems[k]) {
        tempData[buildProfileItems[k]] = data[k];
      }
    });
    return tempData;
  };

  useEffect(() => {
    if (
      (buttonClickStatus?.isLater || buttonClickStatus?.isReview) &&
      profileInfoFormData?.count?.length !== 0 &&
      profileInfoFormData?.count?.length === triggerArray.length
    ) {
      if (
        profileInfoFormData?.countIfValid?.length === triggerArray.length &&
        checkValid()
      ) {
        setcompleteLaterLoading(true);
        const data = new FormData();

        Object.keys(profileInfoFormData?.data?.files).forEach((item) => {
          for (let i = 0; i < profileInfoFormData?.data?.files[item].length; i++) {
            if (!profileInfoFormData?.data?.files[item][i].id) {
              data.append(`${item}`, profileInfoFormData?.data?.files[item][i]);
            }
          }
        });
        const temp = Object.assign({}, profileInfoFormData?.data);
        const postData = JSON.stringify({
          ...transform(temp),
          candidate: { id: profileInfo?.id },
          percent: getPercent(),
        });
        data.append('profileData', postData);
        buildProfileApi(
          data,
          saveLaterfn,
          hideSaveLoader,
          showError,
          profileInfo?.id
        );
        dispatch(clearProfileInfoFormCount([]));
      } else {
        if (profileInfoFormData.count.length === triggerArray.length) {
          toast.error(t('missing-information-text'), {
            position: 'top-center',
          });
          dispatch(clearProfileInfoFormCount([]));
          setcompleteLaterLoading(false);
          setButtonClickStatus({ isReview: false, isLater: false });
        }
      }
    }
  }, [profileInfoFormData]);

  const getTriggerArray = (count) => {
    let isValid = [];
    Object.keys(buildProfileFormState).forEach((k, i) => {
      if (Object.keys(buildProfileFormState[k]).length > 0) {
        if (
          (Object.keys(buildProfileFormState[k]?.touched).length > 0 ||
            profileInfoFormData?.parse) &&
          !count.includes(k)
        ) {
          if (buildProfileFormState[k].isValid === true) {
            count.push(k);
          } else {
            isValid.push(k);
          }
        }
      }
    });
    if (isValid.length > 0) {
      document.getElementById('profile-form').scrollIntoView();
      toast.error(t('missing-information-text'), {
        position: 'top-center',
      });
      dispatch(clearProfileInfoFormCount([]));
      setButtonClickStatus({ isReview: false, isLater: false });
      setTriggerArray([]);
    } else if (count.length > 0 && isValid.length === 0) {
      setTriggerArray(count);
    } else if (count.length === 0) {
      setButtonClickStatus({ isReview: false, isLater: false });
      setNoChanges(true);
      setTimeout(() => {
        dispatch(clearProfileInfoFormCount([]));
        history.push(saveLink?.value?.href);
      }, 3000);
    }
  };

  const formSaveAndReview = () => {
    // console.log(
    //   '++++Valid Fields On Review Button Click++++',
    //   buildProfileFormState
    // );
    setButtonClickStatus({ isLater: false, isReview: true });
    let count = [];
    Object.keys(buildProfile).forEach((k, i) => {
      mandatoryItems.map((item) => {
        if (item === k) {
          count.push(item);
        }
      });
    });
    getTriggerArray(count);
  };

  const formSaveAndCompleteLater = () => {
    // console.log('++++Valid Fields On Later Button Click++++', buildProfileFormState);
    setButtonClickStatus({ isReview: false, isLater: true });
    let count = [];
    getTriggerArray(count);
  };

  useEffect(() => {
    if (triggerArray) {
      if (triggerArray.includes('UploadCandidateResume')) {
        refObj['UploadCandidateResume'].current.trigger();
      }
      childRef?.current?.handleRefTrigger();
    }
  }, [triggerArray]);

  const childRef = useRef(null);
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';

  return (
    <section
      className="profile-accordion-section buildProfileAccordion"
      aria-labelledby="title"
      aria-describedby="info"
    >
      <RouteLeavingGuard
        when={isDataFilled}
        t={t}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={(location) => {
          if (true) {
            if (location.pathname) {
              return true;
            }
          }
          return false;
        }}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            {text?.value && (
              <div className="row">
                <div className="col-md-12">
                  <RichText field={text} tag="h1" className="text" id="info" />
                </div>
              </div>
            )}

            {profileDataLoading ? (
              <Loader
                loaderMsg={t('fetching-your-data')}
                loaderWithMsg
                customLoaderStyle={{
                  width: '4rem',
                  height: '4rem',
                  color: theme?.secondaryButton,
                }}
                customTextStyle={{ color: theme?.secondaryButton }}
              />
            ) : (
              <>
                {data && data.length && (
                  <div id="profile-form">
                    <div className="row">
                      <div className="col">
                        <AccordionBlock
                          allowMultipleOpen={
                            allowMultipleOpen?.value === 'true' ? true : false
                          }
                          showNextButton
                          data={data}
                          showExpandButton={false}
                          ref={childRef}
                          t={t}
                          triggerArray={triggerArray}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <ButtonLink
                          cssClass="orange"
                          cssArrowClass="styleArrow"
                          ctaText={ctaText}
                          t={t}
                          ctaUrl={ctaUrl}
                          externalLink={
                            ctaUrl?.value?.linktype === 'internal' ? false : true
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {reviewLoading || completeLaterLoading || noChangesToSave ? (
        <CustomOverlayLoader
          showSpinner
          customLoaderMsg={
            noChangesToSave ? t('no-changes-to-save') : t('submitting-details')
          }
          customLoaderStyle={{
            width: '4rem',
            height: '4rem',
            color: '#fff',
          }}
          customTextStyle={{ color: '#fff' }}
          customStyle={{
            backgroundColor: 'rgb(0, 0, 0, 0.5)',
            background: 'rgba(0, 0, 0, 0.5)',
            opacity: '1',
          }}
        />
      ) : (
        ''
      )}
      <div className="container text-center">
        <div className="submitBtns">
          <div className="col-lg-10 offset-lg-1">
            <div className="submit-btn saveAndReview">
              <Button
                cssClass={brandName === 'stegplus' ? 'blue' : ''}
                text={t('save-and-review')}
                handleButtonClick={formSaveAndReview}
                isLoading={buttonClickStatus.isReview && !completeLaterLoading}
                isDisabled={buttonClickStatus.isReview}
              />
              {buttonLayout?.value?.toLowerCase() === 'inline' && (
                <Button
                  cssClass={brandName === 'stegplus' ? 'blue' : ''}
                  text={t('save-and-complete-later')}
                  handleButtonClick={formSaveAndCompleteLater}
                  isLoading={buttonClickStatus.isLater && !completeLaterLoading}
                  isDisabled={buttonClickStatus.isLater}
                />
              )}
            </div>
            {buttonLayout?.value?.toLowerCase() !== 'inline' && (
              <div className="submit-btn saveAndComplete">
                <TextButton
                  cssClass="light-gray"
                  text={t('save-and-complete-later')}
                  handleTextClick={formSaveAndCompleteLater}
                  isDisabled={buttonClickStatus.isLater}
                  isLoading={buttonClickStatus.isLater && !completeLaterLoading}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

BuildProfileAccordion.defaultProps = {
  t: (val) => val,
  fields: {},
  profileInfoFormData: {},
  sitecoreContext: {},
};

BuildProfileAccordion.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({}).isRequired,
  profileInfoFormData: PropTypes.shape({}).isRequired,
  sitecoreContext: PropTypes.shape({}),
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
    profileInfoFormData: state.profileInfoFormReducer,
    buildProfileFormState: state.buildProfileFormReducer,
    // uploadFields: state.uploadFieldsReducer,
  };
};

const ConnectedComponent = withTranslation()(
  connect(mapStateToProps)(withTheme(BuildProfileAccordion))
);
export default withSitecoreContext()(
  forwardRef((props, ref) => {
    return <ConnectedComponent {...props} inputRef={ref} />;
  })
);
