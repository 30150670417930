import { createGlobalStyle } from 'styled-components';

export const HomeCareerBlockStyles = createGlobalStyle`
.home-career-block{
    background-color: ${({ theme }) => theme?.backgroundColor} !important;
    .content{
        .title{
            color: ${({ theme }) => theme?.heading};
        }
        .text{
            p{
                color: ${({ theme }) => theme?.subText};
            }
        }
    }
}
`;
