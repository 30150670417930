/* eslint-disable */
import React, { useEffect, useState } from 'react';
import SuccessBlock from '../SuccessBlock';
import CreateAccount from '../CreateAccount';
import { authstates } from '../../../../../utils/enums';
import { SuccessBlockMock, CreateAccountMock } from '../mockdata';
import { useSelector } from 'react-redux';

const ApplyFlowProfile = (props) => {
  const { fields = {} } = props;

  if (Object.keys(fields).length > 0) {
    const component = fields?.items?.[0];
    const { name = '', fields: componentFields = {} } = component;
    switch (name) {
      case 'CreateAccount':
        return <CreateAccount fields={componentFields} />;
      case 'SuccessProfile':
        return <SuccessBlock fields={componentFields} />;
      default:
        return null;
    }
  }
  return '';
};

export default ApplyFlowProfile;
