import { createGlobalStyle } from 'styled-components';

export const CalloutBarStepsStyles = createGlobalStyle`
.callout-steps {
    background-image: linear-gradient(267deg, ${({ theme }) =>
      theme?.primaryColor},  ${({ theme }) => theme?.gradient});
    .steps-progress-bar {
        .steps-progress {
            .steps-progress-fill {
                background-color: ${({ theme }) => theme?.primaryColor};
            }
        }
    }
    .step {
        &:after {
            background-color: ${({ theme }) => theme?.primaryColor};
            box-shadow: 0px 0px 0px 1px ${({ theme }) => theme?.primaryColor};
        }
        .step-number {
            background-color: ${({ theme }) => theme?.primaryColor};
        }
        .step-name {
            color: ${({ theme }) => theme?.primaryColor};
        }
    }
}

`;
