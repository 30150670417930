import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './insightcard.scss';

import { DATE_FORMATS } from '../../../utils/enums';

/**
 * @description - InsightCard component.
 * @param {object} props - Input props.
 * @returns {Node} - HTML section.
 */
const InsightCard = (props) => {
  const { fields, sitecoreContext } = props;
  const dateFormat = sitecoreContext?.Country?.dateFormat || DATE_FORMATS.LOCALE;
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';
  //const dateMoment = moment(fields.article.fields?.date?.value, dateFormat);
  const lang = sitecoreContext?.language || 'en';
  return (
    <div className={`slideout-nav-insights insight-card ${brandName}`}>
      <div className="title">{fields?.title?.value}</div>

      {fields?.article && (
        <div>
          <Link to={`/${lang}${fields?.article?.url}`} aria-label="Go to Article">
            <Image
              field={fields?.article?.fields?.image}
              // alt="aticle"
              editable={false}
            />
          </Link>

          <div className="sub-title">
            {fields?.article?.fields?.contentType?.fields?.name?.value}
          </div>
          <div className="date">
            {fields?.article?.fields?.date?.value &&
              moment(fields?.article?.fields?.date?.value).format(dateFormat)}
          </div>
          <Link to={`/${lang}${fields?.article?.url}`}>
            <p className="see-all-article">
              {fields?.article?.fields?.headline?.value}
            </p>
          </Link>
        </div>
      )}
      <Link className="see-all-article" to={fields?.ctaUrl?.value?.href}>
        {fields?.ctaText?.value}
      </Link>
    </div>
  );
};

InsightCard.propTypes = {
  fields: PropTypes.shape({
    title: {
      value: PropTypes.string,
    },
    article: {
      value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    },
    ctaText: {
      value: PropTypes.string,
    },
    ctaUrl: {
      value: PropTypes.shape({}),
    },
  }),
  sitecoreContext: PropTypes.shape({
    Country: PropTypes.shape({
      brandName: PropTypes.string,
    }),
  }),
};

InsightCard.defaultProps = {
  fields: {
    title: {
      value: '',
    },
    article: {
      value: '',
    },
    ctaText: {
      value: '',
    },
    ctaUrl: {
      value: {
        href: '#',
      },
    },
  },
  sitecoreContext: {
    Country: {
      brandName: '',
    },
  },
};

export default withSitecoreContext()(InsightCard);
