import { createGlobalStyle } from 'styled-components';

export const LocationFinderItemStyles = createGlobalStyle`
.location-finder {
    .location-finder-panel {
         .location-finder-item-list {
              .location-finder-item { 
                   .title {
                        color: ${({ theme }) => theme?.headingSecondary} !important;
            }
        }
    }
}
`;
