/* eslint-disable */
import React, { useState, useEffect, Fragment } from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '../../../../core/globals/buttons/Button';
import DynamicForm from '../../../../core/Forms/DynamicForm';
import {
  ACCOUNT_SETTINGS,
  API_STATUS_CODES,
  STRING_VALUES,
} from '../../../../../constants';
import { saveProfileInfo } from '../../../../../redux/actions/actions';
import { dataFetcher } from '../../../../../dataFetcher';
import Loader from '../../../../core/ErrorBoundary/Loader';
import Error from '../../../../core/ErrorBoundary/Error';
import { getCandidateDetailsApi } from '../../../../../services/apiServices/candidateService';
import { withTheme } from 'styled-components';
import { toast } from 'react-toastify';
import './ConsentForm.scss';

/**
 * @description - ConsentForm component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const ConsentForm = ({ fields, t, profileInfo, theme }) => {
  const { subTitle, items } = fields;
  const [profileDataLoading, setprofileDataLoading] = useState(true);
  const [data, setData] = useState({});
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    getValues,
    errors,
    setValue,
    control,
    formState,
  } = useForm({
    mode: 'onChange',
    revalidate: 'onChange',
    shouldFocusError: false,
  });

  useEffect(() => {
    setprofileDataLoading(profileInfo?.loading);
    if (profileInfo?.data?.id) {
      setData({ data: profileInfo?.data?.personalDetails });
    } else if (profileInfo?.data?.error) {
      setprofileDataLoading(profileInfo?.loading);
      setError(true);
    }
  }, [profileInfo]);

  /**
   * @description - Save Consent changes.
   * @returns {undefined}
   */
  const onSubmit = (data) => {
    const postData = {
      candidateId: profileInfo?.data?.id,
      ...data,
    };
    dataFetcher(ACCOUNT_SETTINGS.CONSENT.UPDATE_CONSENT, 'POST', postData).then(
      (response) => {
        // handleUpdateResponse(response);
        getCandidateDetailsApi({}, dispatch);
        toast.success(t('details-saved-successfully'), {
          position: 'top-center',
        });
      },
      () => {
        toast.error(t('error-message'), {
          position: 'top-center',
        });
        // setLoading(false);
        // setMessage('error-message');
        // setErrorCode(true);
        // reset();
        // document.getElementById('formTitle').scrollIntoView();
      }
    );
  };

  return (
    <div className="dashboard-agent dashboard-block consent-section">
      {subTitle?.value && <RichText field={subTitle} tag="p" className="content" />}
      {profileDataLoading ? (
        <Loader
          loaderMsg={t('fetching-your-data')}
          loaderWithMsg
          customLoaderStyle={{
            width: '4rem',
            height: '4rem',
            color: theme?.secondaryButton,
            // margin: 'auto',
          }}
          customTextStyle={{ color: theme?.secondaryButton }}
        />
      ) : error ? (
        <div className="apiError">
          <Error errorMsg={t('error-message')} t={t} />
        </div>
      ) : (
        <form autoComplete="off">
          {items?.map((section, index) => {
            return (
              <>
                {section?.fields?.text?.value && (
                  <RichText
                    field={section.fields.text}
                    tag="p"
                    className="content"
                  />
                )}
                <div className={`formSection form_${index}`}>
                  <DynamicForm
                    formData={section?.fields?.items}
                    register={register}
                    errors={errors}
                    getValues={getValues}
                    setValue={setValue}
                    values={data}
                    control={control}
                    validateOnLoad={true}
                    t={t}
                  />
                </div>
                {section?.fields?.showLineBar?.value && (
                  <div className="seperatorLine" />
                )}
              </>
            );
          })}
          <Button
            text={t('save-changes')}
            cssClass="orange-bg"
            handleButtonClick={handleSubmit(onSubmit)}
            isLoading={profileDataLoading}
          />
        </form>
      )}
    </div>
  );
};

ConsentForm.defaultProps = {
  fields: {},
};

ConsentForm.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  profileInfo: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};

/**
 * @description - Map reducer state to prop.
 * @param {*} state - State value.
 * @returns {object} - State object
 */
const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer,
  };
};

export default withTranslation()(connect(mapStateToProps)(withTheme(ConsentForm)));
