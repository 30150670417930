import { createGlobalStyle } from 'styled-components';

export const SearchEverythingStyle = createGlobalStyle`
.search-pagination{
    .search-pagination-wrap{
        .page-list{
            .page-item{
                .page-link{
                    border-bottom: 2px solid ${({ theme }) =>
                      theme?.secondaryButton};
                    color: ${({ theme }) => theme?.secondaryButton};
                }
            }
        }
    }
}
.search-header .search-everything {
    .primary-button.reversed:hover {
        color: ${({ theme }) => theme?.secondaryButton} !important;
    }
}
`;
