import { SCREENER_QUESTIONS } from '../actions/actionTypes';

const initialState = {
  data: {},
};

const screenerQuestionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SCREENER_QUESTIONS.SAVE:
      return {
        ...state,
        data: action.payload,
      };
    case SCREENER_QUESTIONS.CLEAR:
      return {
        ...state,
        data: {},
      };
    default:
      return state;
  }
};
export default screenerQuestionsReducer;
