/* eslint-disable */
import React from 'react';
import Loader from '../ErrorBoundary/Loader';
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './table.scss';
import Error from '../ErrorBoundary/Error';
import { withTheme } from 'styled-components';

/**
 * @description - Table Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const ModalComponent = (props) => {
  const {
    t,
    row,
    columns,
    noTableHead,
    count,
    title,
    icon,
    sitecoreContext,
    progressPending,
    pagination = false,
    paginationPerPage,
    noDataMessage,
    paginationOptions = {
      rowsPerPageText: t('rows-per-page'),
      rangeSeparatorText: t('of'),
      selectAllRowsItem: true,
      selectAllRowsItemText: t('select-all'),
    },
    paginationServer,
    paginationTotalRows,
    onChangePage,
    showHeader = true,
    error,
    handleRefresh = () => {},
    theme,
  } = props;

  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || 'experis';

  return (
    <React.Fragment>
      {showHeader && (
        <div className={'table-header-block ' + brandName}>
          {icon?.value?.src && <img src={icon?.value?.src} alt={icon?.value?.alt} />}
          <h2>
            {title?.value} {count ? `(${count})` : ''}
          </h2>
        </div>
      )}

      {error ? (
        <div className="error-container-table">
          <Error handleApiRefresh={handleRefresh} refreshApi={true} />
        </div>
      ) : (
        <DataTable
          className="custom-table-wrapper"
          columns={columns}
          paginationPerPage={paginationPerPage || 5}
          paginationRowsPerPageOptions={[5, 10, 50]}
          data={row}
          striped={true}
          noTableHead={noTableHead}
          pagination={pagination}
          paginationComponentOptions={paginationOptions}
          progressComponent={
            <Loader
              loaderMsg={t('fetching-your-data')}
              loaderWithMsg
              customLoaderStyle={{
                width: '4rem',
                height: '4rem',
                color: theme?.secondaryButton,
              }}
              customTextStyle={{ color: theme?.secondaryButton }}
            />
          }
          noDataComponent={
            <div className="no-data">{noDataMessage || t('no-data-exists')}</div>
          }
          progressPending={progressPending}
          paginationServer={paginationServer}
          paginationTotalRows={paginationTotalRows}
          onChangePage={onChangePage}
        />
      )}
    </React.Fragment>
  );
};

ModalComponent.propTypes = {
  t: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataField: PropTypes.string,
      text: PropTypes.string,
      sort: PropTypes.bool,
      style: PropTypes.shape(),
      sortCaret: PropTypes.string,
    })
  ),
  row: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  bordered: PropTypes.bool,
  noTableHead: PropTypes.bool,
  onRowCLick: PropTypes.func,
  count: PropTypes.number,
  img: PropTypes.string,
  sitecoreContext: PropTypes.shape({}),
};

ModalComponent.defaultProps = {
  t: (val) => val,
  columns: [],
  row: [],
  bordered: false,
  noTableHead: false,
  onRowCLick: () => {},
  sitecoreContext: {},
  error: false,
};

export default withSitecoreContext()(withTranslation()(withTheme(ModalComponent)));
