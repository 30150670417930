/* eslint-disable require-jsdoc */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Image } from '@sitecore-jss/sitecore-jss-react';

import NavBlock from './NavBlock';
// import NavInsights from '../../../../assets/images/nav-insights.png';
import SiteLogoWhite from '../../../../assets/images/site-logo-white.png';
import {
  isDataExists,
  setAltUrlForImg,
  pushNavToDataLayer,
} from '../../../../utils/helperUtils';
import { HeaderContext } from './HeaderContext';

/**
 * @description - Handle Section change.
 * @param {string} section - Current section.
 * @param {Function} setMenuSection - Menu section setter.
 * @returns {undefined}
 */
const handleChangeValue = (section, setMenuSection) => {
  if (section === 'back') {
    setMenuSection('');
    return;
  }
  setMenuSection(section);
};

/**
 * @description - Get Navigation Blocks.
 * @param {Function} setMenuSection - Setter for menu section.
 * @param {object} navItems - Navigation items.
 * @param {string} menuSection - Current menu section.
 * @param {string} brandName - Class for experis/jw site.
 * @returns {Node} - Nav Blocks.
 */
const getNavItems = (setMenuSection, navItems, menuSection, brandName) => {
  const { fields } = navItems;
  const itemVals = (fields && fields.items) || [];

  return (
    <NavBlock
      onChangeValue={(sec) => handleChangeValue(sec, setMenuSection)}
      items={itemVals}
      section={menuSection}
      subTitleClass={brandName}
    />
  );
};

/**
 * @description - Hamburger menu right pane components.
 * @param {props} - Input props.
 * @returns {Node} - HTML components.
 */
const RightComponents = ({ rightComponents, showForm, brandName }) => (
  <div className={`col-lg-4 slideout-nav-sidebar ${brandName}`}>
    {rightComponents.map((component, index) => {
      if (component.props && component.props.type === 'text/sitecore')
        return component;
      if (component.props?.rendering?.componentName === 'ApsisNewsLetter')
        return showForm ? <Fragment key={index}>{component}</Fragment> : null;
      return <Fragment key={index}>{component}</Fragment>;
    })}
    {brandName !== 'bankpower' && brandName !== 'stegplus' && (
      <ul className="navlist__mobile" role="navigation">
        <li className="navlink__mobile country">
          {rightComponents.map((component) => {
            return component?.props?.rendering?.componentName ===
              'ShowCountryLinksManpower'
              ? component
              : '';
          })}
        </li>
      </ul>
    )}
  </div>
);

RightComponents.propTypes = {
  rightComponents: PropTypes.arrayOf(PropTypes.node).isRequired,
  showForm: PropTypes.bool.isRequired,
  brandName: PropTypes.string.isRequired,
};

/**
 * @description - Hamburger Menu items.
 * @param {object} props - Input props.
 * @returns {Node} - HTML slideout menu.
 */
// eslint-disable-next-line max-lines-per-function
const HamburgerMenuItems = (props) => {
  const { menuOpen, setMenuOpen, rightComponents = [] } = props;
  const { searchTabs, items, logo, t, brandName } = props;
  const { history, location, baseUrl } = props;
  const [menuSection, setMenuSection] = useState('');
  const [showForm, setShowForm] = useState(false);
  const logoVal = setAltUrlForImg(logo, 'Mobile Site Logo') || {
    value: { src: SiteLogoWhite, alt: 'Mobile Site Logo' },
  };

  /**
   * @description - Action to toggle menu close/open on click of enter button.
   * @param {object} e - Event object.
   * @returns {undefined}
   */
  const onEnter = (e) => {
    if (e && e.keyCode === 13) {
      setMenuOpen(!menuOpen);
    }
  };

  useEffect(() => {
    if (menuOpen) {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
    const unlisten = history.listen((nextLocation) => {
      if (nextLocation.pathname !== location.pathname) {
        setMenuOpen(false);
        setMenuSection('');
        setShowForm(menuOpen);
      }
    });
    return () => {
      unlisten();
    };
  }, [history, location, setMenuOpen, menuOpen]);

  return (
    <div className={`main-nav-slideout ${brandName} ${menuOpen ? 'active' : ''}`}>
      <div className="mobile--actives" />
      <div className="container">
        <div className="row mobile-nav-header">
          <div className="col">
            <div className={`mobile-site-logo ${brandName}`}>
              <Link
                to={baseUrl}
                aria-label="Go to home"
                onClick={() => pushNavToDataLayer('Hamburger Nav', 'logo')}
              >
                <Image field={logoVal} className="site-logo" />
                {/* <img src={SiteLogoWhite} alt="Site logo" className="site-logo" /> */}
              </Link>
            </div>
          </div>
          <div className="col-auto">
            <div
              className="close-text"
              role="button"
              onClick={() => setMenuOpen(!menuOpen)}
              tabIndex={0}
              onKeyUp={(e) => onEnter(e)}
            >
              {t('close-hamburger')}
            </div>
          </div>
        </div>
        <div className="row">
          <div className={`col-lg-8 slideout-nav-content ${menuSection}`}>
            {searchTabs.map((component, index) => {
              if (component.props && component.props.type === 'text/sitecore')
                return component;
              return (
                <HeaderContext.Provider
                  value={{
                    setMenuOpen,
                    setMenuSection,
                    isInHeader: true,
                    menuOpen,
                  }}
                  key={index}
                >
                  {component}
                </HeaderContext.Provider>
              );
            })}
            <div className="row">
              {isDataExists(items) && items[0] ? (
                <div className="col-lg-4">
                  {getNavItems(setMenuSection, items[0], menuSection, brandName)}
                </div>
              ) : null}
              {isDataExists(items) && items[1] ? (
                <div className="col-lg-4">
                  {getNavItems(setMenuSection, items[1], menuSection, brandName)}
                </div>
              ) : null}
              {isDataExists(items) && items[2] ? (
                <div className="col-lg-4">
                  {getNavItems(setMenuSection, items[2], menuSection, brandName)}
                </div>
              ) : null}
            </div>
          </div>
          <RightComponents
            showForm={showForm}
            rightComponents={rightComponents}
            brandName={brandName}
            t={t}
          />
        </div>
      </div>
    </div>
  );
};

HamburgerMenuItems.propTypes = {
  rightComponents: PropTypes.arrayOf(PropTypes.shape({})),
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  history: PropTypes.shape({
    listen: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  menuOpen: PropTypes.bool,
  setMenuOpen: PropTypes.func.isRequired,
  logo: PropTypes.shape({
    value: PropTypes.shape({}),
  }),
  t: PropTypes.func.isRequired,
  searchTabs: PropTypes.arrayOf(PropTypes.node).isRequired,
  brandName: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
};

HamburgerMenuItems.defaultProps = {
  menuOpen: false,
  logo: null,
  rightComponents: [],
  t: (val) => val,
  searchTabs: [],
};

export default withRouter(withTranslation()(HamburgerMenuItems));
