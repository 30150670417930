/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import TextLink from '../../../../core/globals/link/TextLink';
import '../../apply_job_flow.scss';

/**
 * @description - Success component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML template.
 */
const ApplyFlowButtons = ({ fields }) => {
  const { items = [] } = fields;
  return (
    <div className="create-profile-success-flex">
      <div className="create-profile-success-text-button">
        {items.map((item) => (
          <TextLink
            cssClass="dark-gray"
            ctaText={item?.fields?.ctaText}
            ctaUrl={item?.fields?.ctaUrl}
          />
        ))}
      </div>
    </div>
  );
};

ApplyFlowButtons.propTypes = {
  fields: PropTypes.shape({
    text: PropTypes.shape({
      value: PropTypes.string,
    }),
  }).isRequired,
};

ApplyFlowButtons.defaultProps = {
  fields: {},
};

export default ApplyFlowButtons;
