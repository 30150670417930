import { createGlobalStyle } from 'styled-components';

export const ArticleHeaderStyle = createGlobalStyle`
.article-block,
.details-block {
    .article-header {
        .title {
            color: ${({ theme }) => theme?.primaryColor}
        }
    }
    .article-header-container{
        .breadcrumb{
            li{
                a, 
                .breadcrumb-link {
                    color: ${({ theme }) => theme?.primaryButton};
                }
            }
        }
    }
    .single-article{
        p{
            a{
                color: ${({ theme }) => theme?.secondaryButton}
            }
        }
    }
    .article-sidebar{
        .sidebar-title{
            border-top: 3px solid ${({ theme }) => theme?.horizontalRule}
        }
    }
    &.job{
        .breadcrumb li:last-child a{
            color: ${({ theme }) => theme?.primaryButton} !important;
        }
    }   
}
 
`;
