import React from 'react';
import PropTypes from 'prop-types';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import moment from 'moment';
import 'moment/min/locales';

/**
 * @description - Article Header component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const ArticleHeader = ({
  title,
  subTitle,
  company,
  companyLogo,
  time,
  skill,
  cssClass,
  brandName,
  lang,
  sitecoreContext,
}) => {
  moment.locale(lang);
  const country_name = sitecoreContext?.Country?.name;
  return (
    <div className={`article-header ${cssClass ? cssClass : ''}`}>
      <RichText tag="h1" className={`title ${brandName}`} field={title} />
      {cssClass !== 'job' && (
        <RichText tag="div" className="sub-title" field={subTitle} />
      )}
      {cssClass === 'job' && country_name !== 'Sweden' && (
        <div className="company">
          {company && (
            <>
              {subTitle} <strong> {company}</strong>
            </>
          )}
          {companyLogo && companyLogo.value && companyLogo.src && (
            <img src={companyLogo} alt="logo" />
          )}
        </div>
      )}
      {(time || skill) && (
        <div className="time-skill-block">
          {time && <div className="time">{moment(time).fromNow()}</div>}
          {skill && <div className="skill">{skill}</div>}
        </div>
      )}
    </div>
  );
};

ArticleHeader.defaultProps = {
  title: { value: '' },
  subTitle: { value: '' },
  companyLogo: {
    value: { src: '' },
  },
  company: '',
  time: '',
  skill: '',
  cssClass: '',
  brandName: '',
  lang: '',
  sitecoreContext: '',
};

ArticleHeader.propTypes = {
  title: PropTypes.shape({ value: PropTypes.string }),
  subTitle: PropTypes.shape({ value: PropTypes.string }),
  time: PropTypes.string,
  company: PropTypes.string,
  skill: PropTypes.string,
  cssClass: PropTypes.string,
  companyLogo: PropTypes.shape({
    value: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }),
  brandName: PropTypes.string,
  lang: PropTypes.string,
  sitecoreContext: PropTypes.string,
};
export default withSitecoreContext()(ArticleHeader);
