/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';
import PropTypes from 'prop-types';
import './dropdown.scss';
// import { DropdownStyle } from './dropdownTheme';

/**
 * @description to generate dropdown
 * @param {*} items - list of items to display
 * @param {string} textField - field name of text to display
 * @param {string} valueField - field name of value to use as value
 * @param {string} label - label to be shown for the dropdown
 * @param {string} floatingLabel - label to be shown inside the input box
 * @returns {Node} - HTML section
 */
const TypeAheadDropDown = ({
  items,
  onItemSelect = () => {},
  textField = '',
  valueField = '',
  label = '',
  floatingLabel = '',
  seletedItem = '',
  brandName = '',
}) => {
  const [value, setValue] = useState('');
  const [showDropDown, setDropDown] = useState(false);

  useEffect(() => {
    /**
     *
     * @param {*} event
     */
    const listener = (event) => {
      if (
        event &&
        event.keyCode === 9 &&
        event.target?.className === 'dropdown-item' &&
        event.target?.nextSibling?.className !== 'dropdown-item'
      ) {
        setDropDown(false);
      }
    };
    document.addEventListener('keyup', listener);
    return () => {
      document.addEventListener('keyup', listener);
    };
  }, []);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
    return (
      <div className="custom-toggle">
        <div
          className="toggle-container"
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {children}

          {showDropDown && (
            <div className="input-gp">
              <FormControl
                autoFocus
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                value={value}
                onFocus={function (e) {
                  const val = e.target.value;
                  e.target.value = '';
                  e.target.value = val;
                }}
                id="type-ahead"
                aria-hidden="true"
              />
              <label className="floating-label" htmlFor="type-ahead">
                {floatingLabel}
              </label>
            </div>
          )}
        </div>
      </div>
    );
  });

  // set display name
  CustomToggle.displayName = 'CustomToggle';

  CustomToggle.propTypes = {
    children: PropTypes.shape({}),
    onClick: PropTypes.func,
  };

  CustomToggle.defaultProps = {
    children: {},
    onClick: () => {},
  };

  const CustomMenu = React.forwardRef(({ children, style, className }, ref) => {
    return (
      <div>
        {showDropDown && (
          <div ref={ref} style={style} className={className}>
            <div className="list-unstyled" role="menu">
              {React.Children.toArray(children).filter(
                (child) =>
                  !value ||
                  child?.props?.children
                    ?.toLowerCase()
                    .includes(value?.toLowerCase())
              )}
            </div>
          </div>
        )}
      </div>
    );
  });

  // set display name
  CustomMenu.displayName = 'CustomMenu';

  CustomMenu.propTypes = {
    children: PropTypes.arrayOf(PropTypes.shape({})),
    style: PropTypes.shape({}),
    className: PropTypes.string,
  };

  CustomMenu.defaultProps = {
    children: {},
    style: {},
    className: '',
  };

  // eslint-disable-next-line require-jsdoc
  const handleItemClick = (item) => {
    onItemSelect(item);
    setDropDown(false);
  };

  const toggleFilter = (e) => {
    e.preventDefault();
    if (e && (e.keyCode === 13 || e.keyCode === 32)) {
      setDropDown(!showDropDown);
    }
  };

  return (
    <>
      {/* <DropdownStyle /> */}
      <div
        className="dp-down"
        onMouseLeave={() => setDropDown(false)}
        onKeyUp={toggleFilter}
      >
        <Dropdown show={showDropDown}>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <div
              className={`item-label label-footer ${brandName}`}
              onClick={() => setDropDown(!showDropDown)}
              role="button"
              tabIndex={0}
              aria-label={label}
              aria-expanded={showDropDown}
            >
              {label} <span>{seletedItem}</span>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu as={CustomMenu}>
            {items.map((item, index) => {
              return (
                <Dropdown.Item
                  key={item[valueField] + index}
                  eventKey={item[valueField]}
                  onClick={() => handleItemClick(item)}
                  role="menuitem"
                >
                  {item[textField]}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

TypeAheadDropDown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onItemSelect: PropTypes.func.isRequired,
  textField: PropTypes.string.isRequired,
  valueField: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  floatingLabel: PropTypes.string,
  seletedItem: PropTypes.string.isRequired,
  brandName: PropTypes.string,
};

TypeAheadDropDown.defaultProps = {
  items: [],
  onItemSelect: () => {},
  textField: 'text',
  valueField: 'value',
  label: '',
  floatingLabel: '',
  seletedItem: '',
  brandName: '',
};

export default TypeAheadDropDown;
