import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import './SEOJobResultsRibbon.scss';

/**
 * @description to show search result count and related information
 * @param {number} searchResultCount - no of results
 * @returns {node} html
 */
const SEOJobResultsRibbon = ({ t }) => {
  const { totalJobResults, showingResults, seeAllJobs, searchLabels } = useSelector(
    (state) => state.seoSearchResultsReducer
  );

  const getReplacedLabel = (label) => {
    const mapObj = {
      count: showingResults,
      totalcount: totalJobResults,
    };

    const regexKeys = new RegExp(Object.keys(mapObj).join('|'), 'gi');
    let newSearchLabel = label?.replace(regexKeys, function (matched) {
      return mapObj[matched];
    });
    newSearchLabel = newSearchLabel?.replace(/[()]/g, '');
    return newSearchLabel;
  };

  return (
    <section className="search-ribbon-global container">
      <div className="col-md-8 card-ribbon">
        <div className="search-ribbon-wrap active">
          <div className="search-ribbon-inner">
            <div className="results">
              {showingResults && totalJobResults ? (
                <h2 className="text">
                  <RichText
                    tag="div"
                    className="searchLabel"
                    field={{
                      value: getReplacedLabel(searchLabels?.labelForDesktop),
                    }}
                  />
                </h2>
              ) : null}
            </div>
            <div className="see-all-results">
              {seeAllJobs ? (
                <Link to={seeAllJobs}>
                  <div className="text">{t('seo-all-job-results')}</div>
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

SEOJobResultsRibbon.defaultProps = {
  searchResultCount: 0,
  totalCount: 0,
  t: () => {},
};

SEOJobResultsRibbon.propTypes = {
  searchResultCount: PropTypes.number,
  totalCount: PropTypes.number,
  t: PropTypes.func,
};

export default withTranslation()(SEOJobResultsRibbon);
