/**
 * @description - Get filter list.
 * @param {object} options - Search object.
 * @returns {Array} - Filtered Array.
 */
export const getFormattedArray = (options = []) =>
  options.map((option) => ({
    key: option.id,
    value: option.value,
  }));

/**
 *@description - description
 * @param {object} options - options
 * @returns {*} - action
 */
export const getCitiesArray = (options = []) =>
  options.map((option) => ({
    id: option.key,
    value: option.value,
    text: `${option.value} (${option.count || 0})`,
    disabled: !option.count || option.count === 0 ? true : false,
  }));

/**
 *
 * @param {object} options - options
 * @returns {*} - action
 */
export const getFilterArray = (options = []) => {
  return options.map((option) => ({
    id: option.key,
    value: option.value,
    text: option.value,
    count: option.key !== 'None' ? (option?.count ? `(${option.count})` : '') : '',
    disabled: option?.isDisabled,
  }));
};
/**
 *
 * @param {object} options - options
 * @returns {*} - action
 */
export const getSelectArray = (options = []) =>
  options.map((option) => ({
    title: option.value,
    value: option.key,
  }));
/**
 *
 * @param {object} options - options
 * @returns {*} - action
 */
export const getIndustriesArray = (options = []) =>
  options.map((option) => ({
    key: option.value,
    value: option.title,
  }));
/**
 *
 * @param {object} options - options
 * @returns {*} - action
 */
export const getLanguageArray = (options = []) =>
  options.map((option, index) => ({
    text: option.name,
    id: index,
    redirectURL: option.redirectURL,
    value: option.isoCode,
    regionalISO: option.regionalISO,
  }));

/**
 *
 */
export const splitCitiesArray = (options = []) => {
  let jobCities = [],
    noJobCities = [];
  jobCities = options.filter((option) => !option.disabled);
  noJobCities = options.filter((option) => option.disabled);
  return {
    jobCities,
    noJobCities,
  };
};

export const getRadiusFilterArray = (options = []) => {
  return options.map((option) => ({
    id: option.key, // 0-10,  anydistance
    value: option.value, //10, 20, 300
    text: option.key,
    count: option.key !== 'None' ? (option?.count ? `(${option.count})` : '') : '',
    disabled: option?.isDisabled,
  }));
};

/**
 *
 * @param {*} searchParams - search params
 * @param {*} url - search url
 * @returns {string} - full search url
 */
export const prepareUrlParam = (searchParams, url) => {
  if (searchParams?.searchKeyword || searchParams?.filters?.lat) {
    let urlWithParam = url + '?';
    if (searchParams?.searchKeyword) {
      urlWithParam = urlWithParam + 'searchKeyword=' + searchParams?.searchKeyword;
    }
    if (searchParams?.filters?.lat) {
      urlWithParam = searchParams?.searchKeyword
        ? urlWithParam +
          '&filters=' +
          encodeURIComponent(JSON.stringify(searchParams?.filters))
        : urlWithParam +
          'filters=' +
          encodeURIComponent(JSON.stringify(searchParams?.filters));
    }
    return urlWithParam;
  } else if (!searchParams?.searchKeyword && !searchParams?.filters?.lat) {
    return url;
  }
};

/**
 
 * @param {*} socialShareItems  socialShareItems
 * @returns {*} - action
 */
export const socialShareMapper = (socialShareItems) => {
  const items = [];
  if (Array.isArray(socialShareItems)) {
    socialShareItems.forEach((item, index) => {
      items[index] = {
        fields: {
          icon: {
            src: item.icon,
          },
          name: {
            value: item.itemName,
          },
          url: {
            value: item.link,
          },
          utmQueryParam: {
            value: item.utmQueryParam,
          },
        },
        displayName: item.itemName,
      };
    });
  }
  return items;
};
