/* eslint-disable */
import heroImg from '../../../assets/images/hero.jpg';
import DashboardProfile from '../../../assets/images/icon__dashboard-profile.svg';
import DashboardSetting from '../../../assets/images/icon__dashboard-setting.svg';
import Dashboard from '../../../assets/images/icon__dashboard.svg';
import UploadIcon from '../../../assets/images/icon_upload.svg';

export const profileMock = {
  componentName: 'Dashboard',
  fields: {
    backgroundImage: {
      value: {
        src: '',
        alt: '',
        width: '',
        height: '',
      },
    },
  },
  placeholders: {
    'jss-left': [
      {
        componentName: 'DashboardProfile',
        dataSource: '{F7BA8A88-2133-41BB-A58B-986DEA4BD5DA}',
        params: {},
        fields: {
          icon: {
            value: {
              src: UploadIcon,
              alt: 'info-active',
              width: '200',
              height: '200',
            },
          },
          title: {
            value: 'Welcome, {0}!',
          },
        },
      },
      {
        componentName: 'DashboardNav',
        dataSource: '{F7BA8A88-2133-41BB-A58B-986DEA4BD5DA}',
        params: {},
        fields: {
          items: [
            {
              fields: {
                icon: {
                  value: {
                    src: Dashboard,
                    alt: 'info-active',
                    width: '200',
                    height: '200',
                  },
                },
                title: {
                  value: 'Dashboard',
                },
                url: {
                  value: '/candidate/dashboard',
                },
              },
            },
            {
              fields: {
                icon: {
                  value: {
                    src: DashboardProfile,
                    alt: 'info-active',
                    width: '200',
                    height: '200',
                  },
                },
                title: {
                  value: 'Profile',
                },
                url: {
                  value: '/candidate/profile',
                },
              },
            },
            {
              fields: {
                icon: {
                  value: {
                    src: DashboardSetting,
                    alt: 'info-active',
                    width: '200',
                    height: '200',
                  },
                },
                title: {
                  value: 'Account Settings',
                },
                url: {
                  value: '/candidate/account-settings',
                },
              },
            },
          ],
        },
      },
    ],
    'jss-content': [
      {
        componentName: 'DashboardProfileMobile',
        dataSource: '{F7BA8A88-2133-41BB-A58B-986DEA4BD5DA}',
        params: {},
        fields: {
          icon: {
            value: {
              src: UploadIcon,
              alt: 'info-active',
              width: '200',
              height: '200',
            },
          },
          title: {
            value: 'Welcome {0}',
          },
          width: {
            value: '100',
          },
        },
      },
      {
        uid: '7f01db03-f45b-428b-b502-b791d06a7969',
        componentName: 'ProgressBarBlock',
        dataSource:
          '/sitecore/content/Manpowergroup/Manpowergroup/Manpowergroup-Germany/Home/Candidate/Dashboard/Data/Dashboard Progressbar Block',
        params: {},
        fields: {
          items: [
            {
              id: '231856b9-fae6-4c2d-9f17-f966c2d652e6',
              name: 'Partial',
              displayName: 'Partial',
              fields: {
                text: {
                  value: 'A More complete profile can increase your matches by 30%.',
                },
                ctaUrl: {
                  value: {
                    href: '/de/candidate/buildprofile',
                    id: '{BCC279D6-AAD1-4F36-8D48-45078C0BA519}',
                    querystring: '',
                    target: '|Custom',
                    text: '',
                    anchor: '',
                    title: '',
                    class: '',
                    url:
                      '/Manpowergroup/Manpowergroup/Manpowergroup-Germany/Home/Candidate/BuildProfile',
                    linktype: 'internal',
                  },
                },
                ctaText: {
                  value: 'CONTINUE BUILDING PROFILE',
                },
                percentage: {
                  value: '',
                },
                type: {
                  value: '2',
                },
                width: {
                  value: '100',
                },
                title: {
                  value: 'Profile: {0}% complete',
                },
              },
            },
            {
              id: 'a78096e1-740c-4693-a8ac-64d14fff4fcb',
              name: 'Complete',
              displayName: 'Complete',
              fields: {
                text: {
                  value: 'A More complete profile can increase your matches by 30%.',
                },
                ctaUrl: {
                  value: {
                    href: '/de/candidate/buildprofile',
                    id: '{BCC279D6-AAD1-4F36-8D48-45078C0BA519}',
                    querystring: '',
                    target: '|Custom',
                    text: '',
                    anchor: '',
                    title: '',
                    class: '',
                    url:
                      '/Manpowergroup/Manpowergroup/Manpowergroup-Germany/Home/Candidate/BuildProfile',
                    linktype: 'internal',
                  },
                },
                ctaText: {
                  value: 'UPDATE PROFILE',
                },
                percentage: {
                  value: '',
                },
                type: {
                  value: '2',
                },
                width: {
                  value: '100',
                },
                title: {
                  value: 'Do you need to update your profile?',
                },
              },
            },
          ],
        },
      },
      {
        componentName: 'ProfileAccordion',
        fields: {
          text: {
            value: '',
          },
          allowMultipleOpen: {
            value: true,
          },
          ctaText: {
            value: 'Edit Profile',
          },
          ctaUrl: {
            value: {
              href: '/candidate/buildprofile',
              text: '',
              anchor: '',
              linktype: 'internal',
              class: '',
              title: '',
              querystring: '',
              id: '{4CD0B09C-C28D-4E1A-8A79-3AA46FD19CF9}',
            },
          },
          type: '2',
        },
        placeholders: {
          'jss-content': [
            {
              uid: 'cc7df7e8-03e4-4c73-ba4e-42af9636d7ad',
              componentName: 'PersonalInfo',
              dataSource:
                '/sitecore/content/Manpowergroup/Experis/Experis-Norway/Home/Candidate/Dashboard-Profile/Data/Personal Info',
              params: {},
              fields: {
                title: {
                  value: 'Personal Info',
                },
                missingIcon: {
                  value: {
                    src:
                      '/-/media/project/manpowergroup/experis/experis-norway/dashboard/personal-info-icon-red.svg?iar=0&hash=D26A3D3EC5E71D48C926C1B6B6E6CC4A',
                    alt: '',
                  },
                },
                icon: {
                  value: {
                    src:
                      '/-/media/project/manpowergroup/experis/experis-norway/icons/personal-info-active.svg?h=200&iar=0&w=200&hash=429A9066631F6304ABC21707C03D47F2',
                    alt: 'info-active',
                    width: '200',
                    height: '200',
                  },
                },
                text: {
                  value: '(missing information)',
                },
                items: [
                  {
                    id: '977b89c0-a091-43cd-8dd1-611d7d6d26ca',
                    name: 'Account Details',
                    displayName: 'Account Details',
                    fields: {
                      hintText: {
                        value: '(edited in {0})',
                      },
                      title: {
                        value: 'Account Details',
                      },
                      ctaText: {
                        value: 'account settings',
                      },
                      items: [
                        {
                          id: 'c820e5d7-26be-4cfc-b43f-db47435cf2ef',
                          name: 'Name',
                          displayName: 'Name',
                          fields: {
                            label: {
                              value: 'Name',
                            },
                            name: {
                              value: 'firstName/lastName',
                            },
                            value: {
                              value: '',
                            },
                            required: {
                              value: 'true',
                            },
                            separator: {
                              value: '',
                            },
                          },
                        },
                        {
                          id: '66b7cc94-a73f-4a27-bc3b-f520d61c3d50',
                          name: 'Phone Number',
                          displayName: 'Phone Number',
                          fields: {
                            label: {
                              value: 'Phone Number',
                            },
                            name: {
                              value: 'phone',
                            },
                            type: {
                              value: 'phone',
                            },
                            value: {
                              value: '',
                            },
                            required: {
                              value: 'true',
                            },
                          },
                        },
                        {
                          id: '4371aa02-e16b-4e8f-878a-537243fb97da',
                          name: 'Email',
                          displayName: 'Email',
                          fields: {
                            label: {
                              value: 'Email',
                            },
                            name: {
                              value: 'email',
                            },
                            value: {
                              value: '',
                            },
                            required: {
                              value: 'true',
                            },
                          },
                        },
                        {
                          id: '80b46da5-11b0-4a4a-a84f-ef2ae2aedaf7',
                          name: 'Password',
                          displayName: 'Password',
                          fields: {
                            label: {
                              value: 'Password',
                            },
                            name: {
                              value: 'password',
                            },
                            value: {
                              value: '*****',
                            },
                            required: {
                              value: 'true',
                            },
                          },
                        },
                      ],
                    },
                  },
                  {
                    id: '3f993c0e-f1e1-407d-997f-7e1c3662ff88',
                    name: 'Personal Details',
                    displayName: 'Personal Details',
                    fields: {
                      hintText: {
                        value: '',
                      },
                      title: {
                        value: 'Personal Details',
                      },
                      items: [
                        {
                          id: '85c52181-6628-4539-810b-d57109aeae85',
                          name: 'Country',
                          displayName: 'Country',
                          fields: {
                            label: {
                              value: 'Country',
                            },
                            name: {
                              value: 'country',
                            },
                            value: {
                              value: '',
                            },
                            required: {
                              value: 'true',
                            },
                          },
                        },

                        {
                          id: '0cb825da-0af6-4897-a1aa-48468581c243',
                          name: 'Address1',
                          displayName: 'Address1',
                          fields: {
                            label: {
                              value: 'Address 1',
                            },
                            name: {
                              value: 'address.address1',
                            },
                            value: {
                              value: '',
                            },
                            required: {
                              value: 'true',
                            },
                          },
                        },
                        {
                          id: '75ff35b6-0c79-4165-a16b-e0afe365b1a9',
                          name: 'Address2',
                          displayName: 'Address2',
                          fields: {
                            label: {
                              value: 'Address 2',
                            },
                            name: {
                              value: 'address.address2',
                            },
                            type: {
                              value: 'type2',
                            },
                            required: {
                              value: 'false',
                            },
                          },
                        },
                        {
                          id: '75ff35b6-0c79-4165-a16b-e0afe365b1a9',
                          name: 'CityZip',
                          displayName: 'CityZip',
                          fields: {
                            label: {
                              value: '',
                            },
                            name: {
                              value: 'address.city/address.zip',
                            },
                            value: {
                              value: '',
                            },
                            separator: {
                              value: ',',
                            },
                            required: {
                              value: 'false',
                            },
                            multipleRequired: {
                              value: 'true/true',
                            },
                          },
                        },
                        {
                          id: '75ff35b6-0c79-4165-a16b-e0afe365b1a9',
                          name: 'FullAdress',
                          displayName: 'FullAdress',
                          fields: {
                            label: {
                              value: 'Full Address',
                            },
                            name: {
                              value: 'fullAddress',
                            },
                            value: {
                              value: '',
                            },
                            required: {
                              value: 'false',
                            },
                            separator: {
                              value: '',
                            },
                            type: {
                              value: 'customValidation',
                            },
                            connectorField: {
                              value: 'address.zip',
                            },
                            hintText: {
                              value:
                                'In case Full address and Address provided by you are different, please edit address provided to be validated.',
                            },
                            connectorMessage: {
                              value:
                                'In case Full address and Address provided by you are different, please edit address provided to be validated.',
                            },
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              uid: '276d782f-deee-43b7-aab3-b9fa38d98b55',
              componentName: 'Experience',
              dataSource:
                '/sitecore/content/Manpowergroup/Experis/Experis-Norway/Home/Candidate/Dashboard-Profile/Data/Experience',
              params: {},
              fields: {
                title: {
                  value: 'Experience',
                },
                missingIcon: {
                  value: {
                    src:
                      '/-/media/project/manpowergroup/experis/experis-norway/dashboard/experience-icon-red.svg?h=200&iar=0&w=200&hash=F82D56805A718506E5D837F83BA37DAD',
                    alt: 'exp-red',
                    width: '200',
                    height: '200',
                  },
                },
                icon: {
                  value: {
                    src:
                      '/-/media/project/manpowergroup/experis/experis-norway/icons/experience-icon-active.svg?h=200&iar=0&w=200&hash=DC5ABC9727DFABA76EF36AB6C3D7B532',
                    alt: 'exp-active',
                    width: '200',
                    height: '200',
                  },
                },
                text: {
                  value: '(missing information)',
                },
                items: [
                  {
                    id: '59ebe82f-1234-48e8-bcf9-1fa5022a3670',
                    name: 'Experience Details',
                    displayName: 'Experience Details',
                    fields: {
                      hintText: {
                        value: '',
                      },
                      title: {
                        value: '',
                      },
                      items: [
                        {
                          id: 'd7b9c280-43a7-4467-ba22-57fa6d9c2aac',
                          name: 'Title',
                          displayName: 'Title',
                          fields: {
                            label: {
                              value: 'Title',
                            },
                            name: {
                              value: 'title',
                            },
                            value: {
                              value: '',
                            },
                            required: {
                              value: 'true',
                            },
                          },
                        },
                        {
                          id: '7274318c-53c7-4d65-a7f7-55d8542500ff',
                          name: 'Company Name',
                          displayName: 'Company Name',
                          fields: {
                            label: {
                              value: 'Company',
                            },
                            name: {
                              value: 'companyName',
                            },
                            value: {
                              value: '',
                            },
                            required: {
                              value: 'true',
                            },
                          },
                        },
                        {
                          id: 'e8e1bbfd-195d-4145-8574-82bd41101452',
                          name: 'Years of Experience',
                          displayName: 'Years of Experience',
                          fields: {
                            label: {
                              value: '',
                            },
                            name: {
                              value: 'startDate/endDate',
                            },
                            value: {
                              value: '',
                            },
                            type: {
                              value: 'yearDiff',
                            },
                            required: {
                              value: 'true',
                            },
                            separator: {
                              value: '-',
                            },
                            connectorField: {
                              value: 'isStillWorking',
                            },
                          },
                        },
                        {
                          id: 'c3d73239-ecc0-4cf9-9c7a-655fb0b1e741',
                          name: 'Description',
                          displayName: 'Description',
                          fields: {
                            label: {
                              value: '',
                            },
                            name: {
                              value: 'description',
                            },
                            value: {
                              value: '',
                            },
                            required: {
                              value: 'true',
                            },
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              uid: '6053bbe9-c78a-4937-a136-0e1c9e990591',
              componentName: 'Education',
              dataSource:
                '/sitecore/content/Manpowergroup/Experis/Experis-Norway/Home/Candidate/Dashboard-Profile/Data/Education',
              params: {},
              fields: {
                title: {
                  value: 'Education',
                },
                missingIcon: {
                  value: {
                    src:
                      '/-/media/project/manpowergroup/experis/experis-norway/dashboard/education-icon-red.svg?h=200&iar=0&w=200&hash=1A33720179D65664741A2E4EAC1CBC5D',
                    alt: 'edu-red',
                    width: '200',
                    height: '200',
                  },
                },
                icon: {
                  value: {
                    src:
                      '/-/media/project/manpowergroup/experis/experis-norway/icons/education-icon-active.svg?h=200&iar=0&w=200&hash=400E05C74398A880558EDD5BA7A0954C',
                    alt: 'ed-active',
                    width: '200',
                    height: '200',
                  },
                },
                text: {
                  value: '(missing information)',
                },
                items: [
                  {
                    id: 'b800a404-cd68-4ae6-8359-6906eb020b77',
                    name: 'Education Details',
                    displayName: 'Education Details',
                    fields: {
                      hintText: {
                        value: '',
                      },
                      title: {
                        value: '',
                      },
                      items: [
                        {
                          id: 'df0c79e9-a125-4c3b-b445-072b5bb56a41',
                          name: 'University',
                          displayName: 'University',
                          fields: {
                            label: {
                              value: '',
                            },
                            name: {
                              value: 'university',
                            },
                            value: {
                              value: '',
                            },
                            required: {
                              value: 'true',
                            },
                          },
                        },
                        // {
                        //   id: '45639b31-f276-42fc-9d1e-deb15bf04f2d',
                        //   name: 'Degree',
                        //   displayName: 'Degree',
                        //   fields: {
                        //     label: {
                        //       value: '',
                        //     },
                        //     name: {
                        //       value: 'degreeList',
                        //     },
                        //     value: {
                        //       value: '',
                        //     },
                        //     required: {
                        //       value: 'true',
                        //     },
                        //   },
                        // },
                        {
                          id: 'e8e1bbfd-195d-4145-8574-82bd41101452',
                          name: 'Graduated',
                          displayName: 'Graduated',
                          fields: {
                            label: {
                              value: '',
                            },
                            name: {
                              value: 'graduationEndDate',
                            },
                            value: {
                              value: '',
                            },
                            type: {
                              value: 'customText',
                            },
                            required: {
                              value: '',
                            },
                            separator: {
                              value: '',
                            },
                            options: [
                              {
                                id: 'c0d6a3a3-9f9f-4d1b-aa0d-d59b01cfe7f9',
                                fields: {
                                  key: {
                                    value: 'Y',
                                  },
                                  label: {
                                    value: 'Graduated, {degreeList} in {major}',
                                  },
                                },
                              },
                              {
                                id: 'a986ec9a-86c5-4e47-b8d0-39a0ebd821c9',
                                fields: {
                                  key: {
                                    value: 'N',
                                  },
                                  label: {
                                    value:
                                      'Worked towards a(n) {degreeList} in {major}',
                                  },
                                },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              uid: 'ee0c2feb-8f2d-466f-bb4a-ceb124f2bbf7',
              componentName: 'ExpertiseAndSkills',
              dataSource:
                '/sitecore/content/Manpowergroup/Experis/Experis-Norway/Home/Candidate/Dashboard-Profile/Data/Expertise and Skills',
              params: {},
              fields: {
                title: {
                  value: 'Expertise and Skills',
                },
                missingIcon: {
                  value: {
                    src:
                      '/-/media/project/manpowergroup/experis/experis-norway/dashboard/experience-icon-red.svg?h=200&iar=0&w=200&hash=F82D56805A718506E5D837F83BA37DAD',
                    alt: 'exp-red',
                    width: '200',
                    height: '200',
                  },
                },
                icon: {
                  value: {
                    src:
                      '/-/media/project/manpowergroup/experis/experis-norway/icons/experience-icon-active.svg?h=200&iar=0&w=200&hash=DC5ABC9727DFABA76EF36AB6C3D7B532',
                    alt: 'exp-active',
                    width: '200',
                    height: '200',
                  },
                },
                text: {
                  value: '(missing information)',
                },
                items: [
                  {
                    id: '01e087d5-126b-459c-ac6b-f36e0fe0aecd',
                    name: 'ExpertiseAndSkills Details',
                    displayName: 'ExpertiseAndSkills Details',
                    fields: {
                      hintText: {
                        value: '',
                      },
                      title: {
                        value: '',
                      },
                      items: [
                        {
                          id: 'df0c79e9-a125-4c3b-b445-072b5bb56a41',
                          name: 'Primary areas of expertise:',
                          displayName: 'Primary areas of expertise:',
                          fields: {
                            label: {
                              value: '',
                            },
                            name: {
                              value: 'primarySkills',
                            },
                            value: {
                              value: '',
                            },
                            required: {
                              value: 'true',
                            },
                            type: {
                              value: 'block',
                            },
                          },
                        },
                        // {
                        //   id: 'c3d73239-ecc0-4cf9-9c7a-655fb0b1e741',
                        //   name: 'Description',
                        //   displayName: 'Description',
                        //   fields: {
                        //     label: {
                        //       value: '',
                        //     },
                        //     name: {
                        //       value: 'description',
                        //     },
                        //     value: {
                        //       value: '',
                        //     },
                        //     required: {
                        //       value: 'true',
                        //     },
                        //   },
                        // },
                      ],
                    },
                  },
                ],
              },
            },
            {
              uid: '06709e42-e013-410d-be56-9111585b4ba6',
              componentName: 'Certifications',
              dataSource:
                '/sitecore/content/Manpowergroup/Experis/Experis-Norway/Home/Candidate/Dashboard-Profile/Data/Certifications',
              params: {},
              fields: {
                title: {
                  value: 'Certifications',
                },
                missingIcon: {
                  value: {
                    src:
                      '/-/media/project/manpowergroup/experis/experis-norway/dashboard/education-icon-red.svg?h=200&iar=0&w=200&hash=1A33720179D65664741A2E4EAC1CBC5D',
                    alt: 'edu-red',
                    width: '200',
                    height: '200',
                  },
                },
                icon: {
                  value: {
                    src:
                      '/-/media/project/manpowergroup/experis/experis-norway/icons/education-icon-active.svg?h=200&iar=0&w=200&hash=400E05C74398A880558EDD5BA7A0954C',
                    alt: 'ed-active',
                    width: '200',
                    height: '200',
                  },
                },
                text: {
                  value: '(missing information)',
                },
                items: [
                  {
                    id: '70ec6cb4-97e7-4379-b0e0-0fcea3a86417',
                    name: 'Certifications Details',
                    displayName: 'Certifications Details',
                    fields: {
                      hintText: {
                        value: '',
                      },
                      title: {
                        value: '',
                      },
                      items: [
                        {
                          id: 'bdfc92ca-bd88-40ab-9cb2-291b66cba6ab',
                          name: 'Certifications',
                          displayName: 'Certifications',
                          fields: {
                            label: {
                              value: '',
                            },
                            name: {
                              value: 'uploadCertifications',
                            },
                            type: {
                              value: 'file',
                            },
                            value: {
                              value: '',
                            },
                            apiEndPoint: {
                              value:
                                'api/services/Candidate/DownloadAttachment?{cid}',
                            },
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              uid: '06709e42-e013-410d-be56-9111585b4ba6',
              componentName: 'Resume',
              dataSource:
                '/sitecore/content/Manpowergroup/Experis/Experis-Norway/Home/Candidate/Dashboard-Profile/Data/Certifications',
              params: {},
              fields: {
                title: {
                  value: 'Resume',
                },
                missingIcon: {
                  value: {},
                },
                icon: {
                  value: {
                    src:
                      '/-/media/project/manpowergroup/experis/experis-norway/icons/coverletter-blue.svg?h=200&iar=0&w=200&hash=02BB9535B67D70CECDDD475EFBBC2CC1',
                    alt: 'cover-letter-blue',
                    width: '200',
                    height: '200',
                  },
                },
                text: {
                  value: '(missing information)',
                },
                items: [
                  {
                    id: '70ec6cb4-97e7-4379-b0e0-0fcea3a86417',
                    name: 'Resume',
                    displayName: 'Resume',
                    fields: {
                      hintText: {
                        value: '',
                      },
                      title: {
                        value: '',
                      },
                      items: [
                        {
                          id: 'bdfc92ca-bd88-40ab-9cb2-291b66cba6ab',
                          name: 'Resume',
                          displayName: 'Resume',
                          fields: {
                            label: {
                              value: '',
                            },
                            name: {
                              value: 'CV',
                            },
                            type: {
                              value: 'file',
                            },
                            value: {
                              value: '',
                            },
                            apiEndPoint: {
                              value:
                                'api/services/Candidate/DownloadAttachment?{cid}',
                            },
                            required: {
                              value: false,
                            },
                            separator: {
                              value: '',
                            },
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              uid: '06709e42-e013-410d-be56-9111585b4ba6',
              componentName: 'CoverLetters',
              dataSource:
                '/sitecore/content/Manpowergroup/Experis/Experis-Norway/Home/Candidate/Dashboard-Profile/Data/Certifications',
              params: {},
              fields: {
                title: {
                  value: 'CoverLetters',
                },
                missingIcon: {
                  value: {},
                },
                icon: {
                  value: {
                    src:
                      '/-/media/project/manpowergroup/experis/experis-norway/icons/coverletter-blue.svg?h=200&iar=0&w=200&hash=02BB9535B67D70CECDDD475EFBBC2CC1',
                    alt: 'cover-letter-blue',
                    width: '200',
                    height: '200',
                  },
                },
                text: {
                  value: '(missing information)',
                },
                items: [
                  {
                    id: '70ec6cb4-97e7-4379-b0e0-0fcea3a86417',
                    name: 'Cover Letter',
                    displayName: 'Cover Letter',
                    fields: {
                      hintText: {
                        value: '',
                      },
                      title: {
                        value: '',
                      },
                      items: [
                        {
                          id: 'bdfc92ca-bd88-40ab-9cb2-291b66cba6ab',
                          name: 'Cover Letter',
                          displayName: 'Cover Letter',
                          fields: {
                            label: {
                              value: '',
                            },
                            name: {
                              value: 'Attachment',
                            },
                            type: {
                              value: 'file',
                            },
                            value: {
                              value: '',
                            },
                            apiEndPoint: {
                              value:
                                'api/services/Candidate/DownloadAttachment?{cid}',
                            },
                            required: {
                              value: false,
                            },
                            separator: {
                              value: '',
                            },
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  },
};
