/* eslint-disable */
import React, {
  useEffect,
  useState,
  forwardRef,
  Fragment,
  useRef,
  useImperativeHandle,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Loader from '../../../core/ErrorBoundary/Loader';
import Button from '../../../core/globals/buttons/Button';
import DynamicForm from '../../../core/Forms/DynamicForm';
import './upload_resume.scss';
import lget from 'lodash.get';
import { mockData } from '../cvparserData';
import Modal from '../../../core/Modal';
import {
  arrayToObject,
  flattenObject,
  mergeCommonObjects,
  objectToArray,
} from '../../../../utils/datafactory';
import {
  filterArrayByType,
  traverseAndDataExist,
} from '../../../../utils/helperUtils';
import {
  saveProfileInfo,
  resumeSave,
  removeResume,
  saveProfileInfoForm,
  saveParse,
  saveUploadResume,
  saveProfileInfoFormCount,
  saveProfileInfoFormCountIfFormValid,
} from '../../../../redux/actions/actions';
import {
  attachementDownloadApi,
  resumeParseApi,
} from '../../../../services/apiServices/candidateService';
import CustomOverlayLoader from '../../../core/ErrorBoundary/CustomOverlayLoader';
import { formObjIteration } from '../../Utils/helper';
import { withTheme } from 'styled-components';
import { toast } from 'react-toastify';

/**
 * @description - Upload Resume Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const UploadResume = ({ inputRef, t, ...props }) => {
  const {
    fields,
    profileInfo,
    dispatch,
    profileInfoForm,
    profileInfoFormData,
    theme,
  } = props;
  const { title, subTitle, items = [] } = fields;
  const [data, setData] = useState({});
  const [fileUpload, setFileUpload] = useState({});
  const [attachments, setAttachment] = useState([]);
  const [candidateId, setCandidateId] = useState('');
  const [fieldList, setFieldList] = useState([]);
  const [resumeLoading, setresumeLoading] = useState(true);
  // const [downloading, setDownloading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  // const [name, setName] = useState('');
  const {
    handleSubmit,
    register,
    getValues,
    errors,
    setValue,
    control,
    formState,
    setError,
    clearErrors,
    watch,
  } = useForm({
    mode: 'onChange',
    revalidate: 'onChange',
  });
  const [showModal, setShowModal] = useState(false);
  // const [triggered, settriggered] = useState(false);

  const formSubmitRef = useRef();
  const { isValid, isDirty, touched, isValidating } = formState;

  useEffect(() => {
    setTimeout(() => {
      let isEmpty = !traverseAndDataExist(getValues());
      dispatch(saveUploadResume({ isDirty, isValid, touched, isEmpty }));
    }, 500);
  }, [isValid, isDirty, touched, isValidating]);

  /**
   * @description to show file remove alert dialog
   * @returns {undefined} - nothing
   */
  const handleModal = () => {
    setShowModal(!showModal);
  };

  /**
   * @description - Remove file
   * @returns {undefined} - nothing
   */
  const handleYes = () => {
    handleModal();
  };

  /**
   * @description to show job alert dialog
   * @returns {undefined} - nothing
   */
  const handleNo = () => {
    handleModal();
  };

  useEffect(() => {
    if (profileInfo?.id) {
      setData({ ...profileInfo?.personalDetails });
      setCandidateId(profileInfo?.id);
      setresumeLoading(profileInfo?.loading);
      // setName(items[0]?.fields?.name?.value);
      if (profileInfo?.personalDetails) {
        let onlyAttachments = lget(profileInfo?.personalDetails, 'attachment');
        setAttachment(onlyAttachments);
      }

      // setCv(data);
    }
  }, [profileInfo]);

  // useEffect(() => {
  //   if (Object.keys(cv).length > 0) dispatch(resumeSave(cv));
  // }, [cv]);

  // useEffect(() => {
  //   if (fieldList.length > 0) dispatch(removeResume(fieldList));
  // }, [fieldList]);

  const parseFn = (value) => {
    let resumeName = Object.keys(value);
    if (resumeName.length > 0) {
      setLoading(true);
      if (Object.keys(fileUpload).length > 0) {
        Object.keys(fileUpload).forEach((item) => {
          const resumeData = new FormData();
          resumeData.append(Object.keys(fileUpload)[0], fileUpload[item][0]);
          const params = {
            candidateId,
          };
          resumeParseApi(parseData, resumeData, params, parseFailed);
        });
      } else {
        let attachmentDetails = filterArrayByType(attachments, resumeName?.[0]);
        if (attachmentDetails.length > 0) {
          // const filedetail = attachmentDetails[0]?.mediaUrl
          //   ? { url: attachmentDetails[0]?.mediaUrl }
          //   : { id: attachmentDetails[0]?.id };
          const params = {
            candidateId,
            fileId: attachmentDetails[0]?.id,
          };
          resumeParseApi(parseData, [], params, parseFailed);
        }
        // console.log('attachmentDetails+++++', attachmentDetails);
      }
      handleModal();
    }
  };

  const parseData = (res) => {
    if (res.length > 0) {
      const cvData = res?.[0];
      const candidateDetailsCVParsed = flattenObject(
        cvData?.CandidateDetailsFetchResponse?.data[0]
      );
      const experienceDetailsCVParsed =
        cvData?.CandidateWorkHistoryFetchResponse !== null
          ? cvData?.CandidateWorkHistoryFetchResponse?.data
          : [];
      const educationDetailsCVParsed =
        cvData?.CandidateEducationFetchResponse !== null
          ? cvData?.CandidateEducationFetchResponse?.data
          : [];
      let skillsDataCVParsed =
        cvData?.CandidateDetailsFetchResponse?.data[0]?.expertiseAndSkillsString !==
        null
          ? cvData?.CandidateDetailsFetchResponse?.data[0]?.expertiseAndSkillsString?.split(
              ','
            )
          : [];
      skillsDataCVParsed = skillsDataCVParsed.filter((skill) => skill !== '');
      let deletedExperienceList = [];
      let deletedEducationList = [];
      let personalInfoData = flattenObject(profileInfo?.personalDetails);
      let certificationData = profileInfo?.certificationInfo;
      let expertiseAndSkills = {
        primarySkills: null,
        secondarySkills: skillsDataCVParsed,
      };
      let experienceData = {
        data: experienceDetailsCVParsed,
        formCount: experienceDetailsCVParsed?.length,
      };
      let educationData = {
        data: educationDetailsCVParsed,
        formCount: educationDetailsCVParsed?.length,
      };

      profileInfo?.experienceInfo?.data?.map((exp) => {
        if (exp && exp !== null) {
          deletedExperienceList.push(exp?.id);
        }
      });
      profileInfo?.educationInfo?.data?.map((edu) => {
        if (edu && edu !== null) {
          deletedEducationList.push(edu?.id);
        }
      });

      if (Object.keys(personalInfoData).length === 0) {
        personalInfoData = candidateDetailsCVParsed;
      }
      Object.keys(candidateDetailsCVParsed).forEach((key) => {
        if (personalInfoData[key] === null || personalInfoData[key] === '') {
          personalInfoData[key] = candidateDetailsCVParsed[key];
        }
        if (key === 'certifications') {
          certificationData[key] = candidateDetailsCVParsed[key];
        }
      });
      // const pData = {
      //   personalInfoData: personalInfoData,
      //   certificationData,
      //   experienceData,
      //   educationData,
      //   expertiseAndSkills,
      //   deletedExperienceList,
      //   deletedEducationList,
      // };
      // console.log('Parseeee  formatted Parsed++++++', pData);
      // console.log('Parseeee old Reducer++++', profileInfo);

      dispatch(
        saveProfileInfo({
          ...profileInfo,
          personalDetails: personalInfoData,
          certificationInfo: certificationData,
          expertiseAndSkills: expertiseAndSkills,
          experienceInfo: experienceData,
          educationInfo: educationData,
        })
      );
      dispatch(
        saveProfileInfoForm({
          ...profileInfoForm?.data,
          deletedEducationList,
          deletedExperienceList,
        })
      );
      dispatch(saveParse(true));
      toast.success(t('parsing-success-message'), {
        position: 'top-center',
      });
    } else
      toast.error(t('parsing-no-data-message'), {
        position: 'top-center',
      });
    setLoading(false);
  };

  const parseFailed = () => {
    setLoading(false);
    toast.error(t('parsing-failed-message'), {
      position: 'top-center',
    });
  };

  const handleUpload = (fileData, name) => {
    // setCv({ ...cv, [name]: fileData });
    const fileList = fileUpload;
    const obj = { [name]: fileData };
    if (fileUpload.hasOwnProperty(name)) {
      delete fileList[name];
    }
    setFileUpload({ ...fileList, ...obj });
  };

  const handleDelete = (id, name, index) => {
    if (fileUpload.hasOwnProperty(name)) {
      const temp = fileUpload[name];
      delete temp[index];
      const newtemp = temp?.filter((item) => item);
      const obj = { ...fileUpload, [name]: newtemp };
      setFileUpload({ ...fileUpload, [name]: newtemp });
    }
    if (id) {
      setFieldList([...fieldList, { fileid: id, type: name }]);
    }
  };

  const parseOnSubmit = (value) => {
    let resumeName = Object.keys(value);
    if (value[resumeName] !== null && value[resumeName]) {
      handleYes();
    } else {
      toast.error(t('resume-required'), {
        position: 'top-center',
      });
    }
  };

  const transform = (onSubmitFunc) => (data) => {
    let formattedData = Object.assign({}, data);
    formattedData = formObjIteration(data);
    onSubmitFunc(formattedData);
  };

  /**
   * @description - onsubmit hander.
   * @returns {undefined}
   */
  const onSubmit = (formData) => {
    dispatch(
      saveProfileInfoForm({
        ...profileInfoFormData?.data,
        uploadResume: formData,
        files: { ...profileInfoFormData?.data?.files, ...fileUpload },
        deletedFiles: [
          ...new Set([...profileInfoFormData?.data?.deletedFiles, ...fieldList]),
        ],
      })
    );

    dispatch(saveProfileInfoFormCountIfFormValid(6));
  };

  /**
   * @description - useImperativeHandle function.
   * @returns {undefined}
   */
  useImperativeHandle(inputRef, () => ({
    trigger: () => {
      setTimeout(() => dispatch(saveProfileInfoFormCount(6)), 50);
      let event;
      if (typeof Event === 'function') {
        event = new Event('submit', { cancelable: true });
      } else {
        event = document.createEvent('Event');
        event.initEvent('submit', false, { cancelable: true });
      }
      formSubmitRef?.current?.dispatchEvent(event);
    },
  }));

  return (
    <section className="resume-upload">
      {isLoading && (
        <CustomOverlayLoader
          showSpinner
          customLoaderMsg={t('parsing-in-progress')}
          customLoaderStyle={{
            width: '4rem',
            height: '4rem',
            color: '#fff',
          }}
          customTextStyle={{ color: '#fff' }}
          customStyle={{
            backgroundColor: 'rgb(0, 0, 0, 0.5)',
            background: 'rgba(0, 0, 0, 0.5)',
            opacity: '1',
          }}
        />
      )}
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 border-line">
            <div className="cover-letter-text">{subTitle?.value}</div>
            {resumeLoading ? (
              <div style={{ paddingBottom: '60px' }}>
                <Loader
                  loaderMsg={t('fetching-your-data')}
                  loaderWithMsg
                  customLoaderStyle={{
                    width: '4rem',
                    height: '4rem',
                    color: theme?.secondaryButton,
                  }}
                  customTextStyle={{ color: theme?.secondaryButton }}
                />
              </div>
            ) : (
              <Fragment>
                <div className="uploadWrapper">
                  <div className="col-lg-7 upload-resume-form">
                    <form
                      onSubmit={handleSubmit(transform(onSubmit))}
                      ref={formSubmitRef}
                    >
                      <DynamicForm
                        formData={items}
                        register={register}
                        errors={errors}
                        getValues={getValues}
                        values={{ data: data }}
                        setValue={setValue}
                        setError={setError}
                        clearErrors={clearErrors}
                        handleUpload={handleUpload}
                        handleDelete={handleDelete}
                        APIQuerryParams={{ cid: candidateId }}
                        control={control}
                        validateOnLoad={true}
                        t={t}
                      />
                    </form>
                  </div>
                  <div
                    className="upload-button-container"
                    role="button"
                    tabIndex={0}
                  >
                    <Button
                      text={t('parse-cv')}
                      handleButtonClick={handleSubmit(parseOnSubmit)}
                      cssClass="blue"
                      // isDisabled={cv.hasOwnProperty(name) > 0 && !cv[name][0]?.name}
                    ></Button>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
      <Modal showModal={showModal} handleModal={handleModal} size="md">
        <div className="inner-modal-container" key="body">
          {t('parse-confirmation-message')}
          <div className="confirm-button-container">
            <Button
              cssClass="yes-button"
              handleButtonClick={handleSubmit(parseFn)}
              text={t('continue')}
            />
            <Button handleButtonClick={handleNo} text={t('cancel')} />
          </div>{' '}
        </div>
      </Modal>
    </section>
  );
};

UploadResume.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
  profileInfoForm: PropTypes.shape({}),
};

UploadResume.defaultProps = {
  fields: {},
  profileInfoForm: {},
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
    profileInfoForm: state.profileInfoFormReducer,
    profileInfoFormData: state.profileInfoFormReducer,
  };
};

const ConnectedComponent = connect(mapStateToProps)(withTheme(UploadResume));
export default forwardRef((props, ref) => {
  return <ConnectedComponent {...props} inputRef={ref} />;
});
