import React from 'react';
import PropTypes from 'prop-types';
import CardArticleCta from '../../Cards/CardArticleCta';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import Slider from 'react-slick';
import TextLink from '../../globals/link/TextLink';
import { isDataExists } from '../../../../utils/helperUtils';
import { DATE_FORMATS } from '../../../../utils/enums';

import '../cardscroll.scss';

const settings = {
  dots: true,
  //   infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: false,
  arrows: false,
  accessibility: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1.1,
        slidesToScroll: 1,
      },
    },
  ],
};

/**
 * @description - Card Scroll Article component.
 * @param {object} props - Input props.
 * @returns {Node} - HTML section.
 */
const ArticleCardScroll = (props) => {
  const { fields, sitecoreContext } = props;
  const { title, ctaText, ctaUrl, linkText, items = [] } = fields;
  const isItemExists = isDataExists(items);
  let ctaUrlVal = '';
  const { contentType, topic, subTopic } = fields;
  const urlVal = ctaUrl?.value?.href ? ctaUrl?.value?.href.split('?') : [];
  const queryVal = isItemExists
    ? {
        contentType: {
          id: contentType?.id,
          name: contentType?.fields?.name?.value,
        },
        topic: {
          id: topic?.id,
          name: topic?.fields?.name?.value,
        },
        subTopic: {
          id: subTopic?.id,
          name: subTopic?.fields?.name?.value,
        },
      }
    : {};
  ctaUrlVal = {
    pathname: urlVal[0],
    search: urlVal[1],
    query: queryVal,
  };

  return fields?.items && isItemExists ? (
    <section
      className={`card-scroll article-cta  ${isItemExists ? '' : 'no-padding'}`}
    >
      <div className="container">
        <div className="card-scroll-header">
          <RichText className="title" tag="div" field={title} />
          {ctaUrl?.value && ctaText?.value && (
            <div className="cta">
              <TextLink cssClass="blue" ctaText={ctaText} ctaUrl={ctaUrlVal} />
            </div>
          )}
        </div>
        {isItemExists ? (
          <Slider {...settings}>
            {items.map((item, ind) => {
              const itemVal = item?.fields || {};
              const url = itemVal?.url?.value || itemVal?.url;
              return (
                <CardArticleCta
                  key={`cardArticle_${ind}`}
                  title={itemVal?.headline}
                  subTitle={itemVal?.contentType}
                  date={itemVal?.date}
                  ctaText={linkText}
                  ctaUrl={url}
                  dateFormat={
                    sitecoreContext?.Country?.dateFormat || DATE_FORMATS.LOCALE
                  }
                />
              );
            })}
          </Slider>
        ) : null}
      </div>
    </section>
  ) : null;
};

ArticleCardScroll.defaultProps = {
  fields: {
    title: '',
    ctaText: 'See All',
    ctaUrl: '',
    linkText: 'Read more',
    items: [],
    contentType: {},
    topic: {},
    subTopic: {},
  },
};

ArticleCardScroll.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string.isRequired,
    ctaText: PropTypes.string,
    ctaUrl: PropTypes.string,
    linkText: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    contentType: PropTypes.shape({}),
    topic: PropTypes.shape({}),
    subTopic: PropTypes.shape({}),
  }),
};

export default withTranslation()(withSitecoreContext()(ArticleCardScroll));
