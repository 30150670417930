import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '../globals/link/ButtonLink';
import mockData from './mockData';
import {
  mediaApi,
  withSitecoreContext,
  RichText,
} from '@sitecore-jss/sitecore-jss-react';
import { getImg } from '../../../utils/helperUtils';
import './homeblocks.scss';

/**
 * @description - StatBlock component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const StatBlock = ({ fields, sitecoreContext }) => {
  fields = Object.entries(fields).length ? fields : mockData;
  const {
    subTitle,
    title,
    number,
    text,
    findText,
    findUrl,
    hireText,
    hireUrl,
    color,
    backgroundImage,
  } = fields;
  const theme = color?.value ? color.value.toLowerCase() : 'light_blue';
  const brand_name = sitecoreContext?.Country?.brandName?.toLowerCase() || 'experis';
  return (
    <section className={`home-stat-block ${theme}`}>
      {backgroundImage?.value && (
        <div
          className={`bg-image ${brand_name}`}
          style={{
            backgroundImage: `url(${mediaApi.updateImageUrl(
              getImg(backgroundImage)
            )})`,
          }}
        ></div>
      )}
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col">
              <RichText field={title} className="sub-title" tag="div" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="title-container">
                <RichText field={number} className="number" tag="div" />
                <RichText field={subTitle} className="title" tag="div" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text">
                <RichText field={text} className="large" tag="p" />
              </div>
              <div className="cta">
                <ButtonLink
                  cssClass="reversed"
                  ctaText={findText}
                  ctaUrl={findUrl}
                  arrow
                />

                <ButtonLink
                  cssClass="reversed"
                  ctaText={hireText}
                  ctaUrl={hireUrl}
                  arrow
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

StatBlock.defaultProps = {
  fields: {
    subTitle: { value: '' },
    number: { value: 0 },
    title: { value: '' },
    text: { value: '' },
    findText: { value: '' },
    findUrl: {
      value: { href: '#' },
    },
    hireText: { value: '' },
    hireUrl: {
      value: { href: '#' },
    },
    color: { value: '' },
    backgroundImage: { value: { src: '' } },
  },
};

StatBlock.propTypes = {
  fields: PropTypes.shape({
    subTitle: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    number: PropTypes.shape({
      value: PropTypes.number,
    }),
    title: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    text: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    findText: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    findUrl: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
      }),
    }),
    hireText: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    hireUrl: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
      }),
    }),
    color: PropTypes.shape({
      value: PropTypes.string,
    }),
    backgroundImage: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string,
      }),
    }),
  }),
};

export default withSitecoreContext()(StatBlock);
