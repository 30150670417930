import { UPLOAD_FIELDS } from '../actions/actionTypes';

const initialState = [];

/**
 *
 * @param {*} state
 * @param {*} action
 */
const uploadFieldsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_FIELDS.SAVE:
      return [...state, action.payload];
    default:
      return state;
  }
};
export default uploadFieldsReducer;
