import { createGlobalStyle } from 'styled-components';

export const SearchFiltersStyle = createGlobalStyle`
.search-filters {
    .search-filters-panel {
      .searchfilters__title {
            border-bottom: 2px solid ${({ theme }) => theme?.primaryColor};
        .title {
            color: ${({ theme }) => theme?.primaryColor}
        }
      }
      .input-range__label-container {
        color: ${({ theme }) => theme?.primaryColor}
      }
      .input-range__track--active {
        background: ${({ theme }) => theme?.primaryColor}!important
      }
    }
    .search__filters-footer {
      .search-filters-title-text {
        color: ${({ theme }) => theme?.primaryColor}!important
      }
    }
  }
  .filter-container{
    .search-filters-title-text{
      #filter-icon{
        fill:#fff;
        margin-right: 10px
      }
      #caret-icon{
        fill:#fff;
        margin-left: 10px;
        margin-top: 7px;
      }
    }
  }

  .hide-filter{
    .search-filters-title-text{
  
        #filter-icon{
          fill:${({ theme }) => theme?.heading}!important;
          margin-right: 10px
        }
        #caret-icon{
          fill:${({ theme }) => theme?.heading}!important;
          margin-left: 10px;
          margin-top: 7px;
        
      }
    }
  }
`;
