import React from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  mediaApi,
  isExperienceEditorActive,
  RichText,
} from '@sitecore-jss/sitecore-jss-react';

import JobsForm from './JobsForm';
import { getImg } from '../../../../utils/helperUtils';
import './jobsBannerJW.scss';
import { withTranslation } from 'react-i18next';

/**
 *
 * @param {*} param0
 */
const BannerJobs = ({ fields }) => {
  const { title, text, backgroundImage, ctaText, t = () => {} } = fields;
  return (
    <section className="banner-sectionjob stats diamond">
      <Image className="mobile" field={backgroundImage} />
      <div className="white-bg"></div>
      <ul className="diamond-grid">
        <li className="item2 dark"></li>
        <li className="item1">
          <div className="bg"></div>
        </li>
        <li className="item2">
          <div
            className="bg"
            style={{
              backgroundImage: `url(${mediaApi.updateImageUrl(
                getImg(backgroundImage)
              )})`,
            }}
          ></div>
        </li>
        <li className="item3">
          <div className="bg"></div>
        </li>
      </ul>
      <div className="container in-left">
        <div className="row">
          <div className="col-xl-6 col-lg-8 col-md-12">
            <RichText tag="h1" className="reversed" field={title} />
            <RichText tag="p" className="large reversed" field={text} />
          </div>
        </div>
        <div className="row">
          <div className="col-xl-7 col-9">
            {isExperienceEditorActive() ? (
              <div>{t('job-search-experience-editor-preview')}</div>
            ) : (
              <JobsForm ctaText={ctaText?.value} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

BannerJobs.defaultProps = {
  fields: {},
};

BannerJobs.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({ value: PropTypes.string }),
    text: PropTypes.shape({ value: PropTypes.string }),
    ctaText: PropTypes.shape({ value: PropTypes.string }),
    backgroundImage: PropTypes.shape({ value: PropTypes.shape({}) }),
  }).isRequired,
};

export default withTranslation()(BannerJobs);
