/* eslint-disable max-lines */
import NormalIcon from '../../../assets/images/upload_icon_orange.svg';
import onedrive from '../../../assets/images/onedrive.svg';
import dropbox from '../../../assets/images/dropbox.svg';
import gDrive from '../../../assets/images/logo_of_Google_Drive.svg';

export const mockData = {
  uid: '7a07d128-85c0-46e0-bc34-49ddfdcc56a7',
  componentName: 'ApplyJobFlow',
  dataSource:
    '/sitecore/content/Manpowergroup/Experis/Experis-Norway/Home/JobApply/Data/Apply job flow',
  params: {},
  fields: {},
  placeholders: {
    'jss-content': [
      {
        uid: 'd8ebc5ac-a091-447e-bebe-5d84350cf8f4',
        componentName: 'PublicPosition',
        dataSource: '{F7BA8A88-2133-41BB-A58B-986DEA4BD5DA}',
        params: {},
        fields: {
          title: {
            value: 'Public Position',
          },
          subTitle: {
            value: 'Adpscing malort cane lorem ipsum dolor.',
          },
          text: {
            value: 'All fields are required unless otherwise stated.',
          },
          bigText: {
            value:
              "You are about to apply for a public position. It is important to include all your education and work experience for a brief presentation of you and your background which will be presented in the applicant list connected to this position. Your application can not be submitted before this is entered. In addition you can upload your personal CV for complementary information. Applicant with disabilities or minority background, if the applicant wishes, is entitled to special treatment in the application process. This is a public position,, and we provide the possibility to keep one's details exempt from the right or of public access. owever, it is up to the employer in question to decide whether the request should be granted or not. When issuing public applicant lists, the name will be omitted. If an applicant's request to be exempt from the right of the public access is not granted, the applicant will be notified of this decision and will thereby have the opportunity to withdraw their application.",
          },
          jobTitle: {
            value: "You're are applying to :",
          },
          items: [
            {
              id: 'b0a61759-ddb9-44d7-a670-c188bcf09414',
              name: 'Category',
              displayName: 'Category',
              fields: {
                readonly: {
                  value: false,
                },
                label: {
                  value: 'Check all that apply',
                },
                placeholderText: {
                  value: '',
                },
                name: {
                  value: 'publicPosition',
                },
                inputType: {
                  value: 'MultiCheckbox',
                },
                value: {
                  value: '',
                },
                width: {
                  value: '100',
                },
                isHideField: {
                  value: false,
                },
                showBasedOnFieldName: {
                  value: '',
                },
                values: [
                  {
                    id: '5cd0a5fc-1ac8-44b0-a2d2-c4d16339eaa9',
                    fields: {
                      label: {
                        value: 'Minority Background',
                      },
                      key: {
                        value: 'isMinority',
                      },
                      isChecked: {
                        value: false,
                      },
                      readonly: {
                        value: true,
                      },
                    },
                  },
                  {
                    id: '8fa43687-dd8c-4e0b-a29f-2fd618898bb0',
                    fields: {
                      label: {
                        value: 'Physically disabled',
                      },
                      key: {
                        value: 'isDisability',
                      },
                      isChecked: {
                        value: false,
                      },
                      readonly: {
                        value: false,
                      },
                    },
                  },
                  {
                    id: '4c087145-00dc-4337-86e2-f1684ca0bab2',
                    fields: {
                      label: {
                        value: 'Exempt for public disclosure',
                      },
                      key: {
                        value: 'isExempt',
                      },
                      isChecked: {
                        value: false,
                      },
                      readonly: {
                        value: false,
                      },
                    },
                  },
                ],
                Validations: [],
              },
            },
            {
              id: 'e457a37d-6c87-4a46-8277-f47b4dedb934',
              name: 'Reason for Exempt',
              displayName: 'Reason for Exempt',
              fields: {
                label: {
                  value: 'Reason for Exempt',
                },
                placeholderText: {
                  value: '',
                },
                name: {
                  value: 'publicPosition.reason',
                },
                inputType: {
                  value: 'MultiLineText',
                },
                required: {
                  value: true,
                },
                value: {
                  value: '',
                },
                pattern: {
                  value: '',
                },
                width: {
                  value: '100',
                },
                readonly: {
                  value: false,
                },
                isHideField: {
                  value: true,
                },
                showBasedOnFieldName: {
                  value: 'publicPosition.isExempt',
                },
                Validations: [
                  {
                    id: '7730be13-d71e-4584-b914-c68ad16b7554',
                    fields: {
                      message: {
                        value: '{0} is required',
                      },
                      inputType: {
                        value: 'requiredValidator',
                      },
                      value: {
                        value: 'true',
                      },
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        uid: 'a3235b61-2e36-4ffc-b597-fa633bb9cf9b',
        componentName: 'ScreenerQuestions',
        dataSource:
          '/sitecore/content/Manpowergroup/Experis/Experis-Norway/Home/JobApply/Data/Screener Questions',
        params: {},
        fields: {
          title: {
            value: 'Screener Questions',
          },
          subTitle: {
            value: 'Adpscing malort cane lorem ipsum dolor.',
          },
          text: {
            value: 'All fields are required unless otherwise stated.',
          },
          ctaUrl: {
            value: {
              href: '/de/candidate/job-apply-error',
              text: '',
              anchor: '',
              linktype: 'internal',
              class: '',
              title: '',
              querystring: '',
              id: '{4CD0B09C-C28D-4E1A-8A79-3AA46FD19CF9}',
            },
          },
          jobTitle: {
            value: "You're are applying to :",
          },
          Validations: [
            {
              fields: {
                message: {
                  value: 'This Field is Mandatory',
                },
                inputType: {
                  value: 'requiredValidator',
                },
                value: {
                  value: 'true',
                },
              },
            },
          ],
        },
      },
      {
        uid: '6a79f2fc-bb80-4355-b706-d91e640b90ae',
        componentName: 'UploadResume',
        dataSource:
          '/sitecore/content/Manpowergroup/Experis/Experis-Germany/Home/Candidate/JobApply/Data/UploadResume',
        params: {},
        fields: {
          subTitle: {
            value:
              'All we need is your resume and optional cover letter in order to apply.',
          },
          errorPageUrl: {
            value: {
              href: '',
              linktype: 'internal',
            },
          },
          progressTitle: {
            value: '',
          },
          bigText: {
            value: '',
          },
          percentagePerField: {
            value: '[{"name":"CV", "type":"attachment", "percentage":10 }]',
          },
          ctaUrl: {
            value: {
              href: '/de/candidate/jobapplysuccess',
              text: '',
              anchor: '',
              linktype: 'internal',
              class: '',
              title: '',
              target: '',
              querystring: '',
              id: '{670D55B7-6DD3-4562-B3A7-104BB9E7F21E}',
            },
          },
          text: {
            value: 'All fields are required unless otherwise stated.',
          },
          jobTitle: {
            value: "You're are applying to :",
          },
          title: {
            value: "Let's do this ,{0}!",
          },
          userNameIdentifier: {
            value: 'firstName',
          },
          items: [
            {
              id: 'c32f7ad4-b093-48cd-bbad-393083759c66',
              name: 'Layout1',
              displayName: 'Layout1',
              fields: {
                items: [
                  {
                    id: 'b91ab667-6308-4075-9a18-c4c31acf8069',
                    name: 'Country Of Nationality',
                    displayName: 'Country Of Nationality',
                    fields: {
                      CustomRequiredValidator: [],
                      apiEndPoint: {
                        value: '',
                      },
                      isMultiple: {
                        value: false,
                      },
                      isApi: {
                        value: false,
                      },
                      Validations: [
                        {
                          id: '7730be13-d71e-4584-b914-c68ad16b7554',
                          fields: {
                            message: {
                              value: '{0} is mandatory',
                            },
                            value: {
                              value: 'true',
                            },
                            inputType: {
                              value: 'requiredValidator',
                            },
                          },
                        },
                      ],
                      connectorType: {
                        value: '',
                      },
                      connectorMessage: {
                        value: '',
                      },
                      connectorField: {
                        value: '',
                      },
                      connectorPattern: {
                        value: '',
                      },
                      isHideField: {
                        value: false,
                      },
                      inputType: {
                        value: 'TypeaheadDropdown',
                      },
                      width: {
                        value: '100',
                      },
                      label: {
                        value: 'Country of Nationality',
                      },
                      readonly: {
                        value: false,
                      },
                      placeholderText: {
                        value: 'Country of Nationality',
                      },
                      value: {
                        value: '',
                      },
                      showBasedOnFieldName: {
                        value: '',
                      },
                      hintText: {
                        value: '',
                      },
                      subType: {
                        value: '',
                      },
                      name: {
                        value: 'countryofNationality',
                      },
                    },
                  },
                  {
                    id: '15e14e4d-a811-4e22-a4af-4d2888a50da8',
                    name: 'Date Of Birth',
                    displayName: 'Date Of Birth',
                    fields: {
                      format: {
                        value: 'dd/mm/yyyy',
                      },
                      Validations: [
                        {
                          id: '7730be13-d71e-4584-b914-c68ad16b7554',
                          fields: {
                            message: {
                              value: '{0} is mandatory',
                            },
                            value: {
                              value: 'true',
                            },
                            inputType: {
                              value: 'requiredValidator',
                            },
                          },
                        },
                        {
                          id: 'be588c1b-4147-4f5d-9780-e1724c753a9f',
                          fields: {
                            message: {
                              value: 'Please enter a valid date for DOB',
                            },
                            value: {
                              value: '<',
                            },
                            inputType: {
                              value: 'dateValidator',
                            },
                          },
                        },
                        {
                          id: '8b2ebb0b-52f9-44d0-b3cf-e6ef7d4ce0ed',
                          fields: {
                            message: {
                              value: 'Enter a date after 1920',
                            },
                            value: {
                              value: 'min=1920',
                            },
                            inputType: {
                              value: 'dobValidator',
                            },
                          },
                        },
                      ],
                      connectorType: {
                        value: '',
                      },
                      connectorMessage: {
                        value: '',
                      },
                      connectorField: {
                        value: '',
                      },
                      connectorPattern: {
                        value: '',
                      },
                      isHideField: {
                        value: false,
                      },
                      inputType: {
                        value: 'Date',
                      },
                      width: {
                        value: '60',
                      },
                      label: {
                        value: 'Date Of Birth',
                      },
                      readonly: {
                        value: false,
                      },
                      placeholderText: {
                        value: 'DOB format DD/MM/YYYY',
                      },
                      value: {
                        value: '',
                      },
                      showBasedOnFieldName: {
                        value: '',
                      },
                      hintText: {
                        value: '',
                      },
                      subType: {
                        value: '',
                      },
                      name: {
                        value: 'dateOfBirth',
                      },
                    },
                  },
                  {
                    id: 'e5fc48ae-f54a-4541-8360-deeff7d0921a',
                    name: 'Gender',
                    displayName: 'Gender',
                    fields: {
                      values: [
                        {
                          id: 'a5ec8637-d236-4541-a601-35fe77da2e35',
                          fields: {
                            key: {
                              value: 'M',
                            },
                            label: {
                              value: 'Male',
                            },
                          },
                        },
                        {
                          id: 'c1f187e8-eef0-4810-aba7-e5e2848b8007',
                          fields: {
                            key: {
                              value: 'K',
                            },
                            label: {
                              value: 'Female',
                            },
                          },
                        },
                      ],
                      Validations: [
                        {
                          id: '7730be13-d71e-4584-b914-c68ad16b7554',
                          fields: {
                            message: {
                              value: '{0} is mandatory',
                            },
                            value: {
                              value: 'true',
                            },
                            inputType: {
                              value: 'requiredValidator',
                            },
                          },
                        },
                      ],
                      connectorType: {
                        value: '',
                      },
                      connectorMessage: {
                        value: '',
                      },
                      connectorField: {
                        value: '',
                      },
                      connectorPattern: {
                        value: '',
                      },
                      isHideField: {
                        value: false,
                      },
                      inputType: {
                        value: 'NormalDropdown',
                      },
                      width: {
                        value: '40',
                      },
                      label: {
                        value: 'Gender',
                      },
                      readonly: {
                        value: false,
                      },
                      placeholderText: {
                        value: 'Gender',
                      },
                      value: {
                        value: '',
                      },
                      showBasedOnFieldName: {
                        value: '',
                      },
                      hintText: {
                        value: '',
                      },
                      subType: {
                        value: '',
                      },
                      name: {
                        value: 'gender',
                      },
                    },
                  },
                  {
                    id: 'd03e67b2-2603-4254-a84f-4b4ee1c3c825',
                    name: 'Upload Resume',
                    displayName: 'Upload Resume',
                    fields: {
                      Validations: [
                        {
                          id: '7730be13-d71e-4584-b914-c68ad16b7554',
                          fields: {
                            message: {
                              value: '{0} is mandatory',
                            },
                            value: {
                              value: 'true',
                            },
                            inputType: {
                              value: 'requiredValidator',
                            },
                          },
                        },
                      ],
                      label: {
                        value: 'Upload Resume',
                      },
                      placeholderText: {
                        value: 'Upload Resume',
                      },
                      name: {
                        value: 'uploadResume',
                      },
                      values: [
                        {
                          id: '88031c76-c822-4882-9c64-0a1c5fe382b9',
                          fields: {
                            key: {
                              value: '0',
                            },
                            label: {
                              value: 'From your device',
                            },
                            img: {
                              value: {
                                src: NormalIcon,
                                alt: '',
                                width: '200',
                                height: '200',
                              },
                            },
                          },
                        },
                        {
                          id: '08fa4017-b2a0-474b-83e0-81d3f0b129e7',
                          fields: {
                            key: {
                              value: '1',
                            },
                            label: {
                              value: 'From dropBox',
                            },
                            img: {
                              value: {
                                src: dropbox,
                                alt: '',
                                width: '200',
                                height: '200',
                              },
                            },
                          },
                        },
                        {
                          id: '233eed07-3e7b-406f-96d1-0e98360e9f9c',
                          fields: {
                            key: {
                              value: '2',
                            },
                            label: {
                              value: 'From oneDrive',
                            },
                            img: {
                              value: {
                                src: onedrive,
                                alt: '',
                                width: '200',
                                height: '200',
                              },
                            },
                          },
                        },
                        {
                          id: '032441c9-2982-407a-bda8-bd1a2ef48efe',
                          fields: {
                            key: {
                              value: '3',
                            },
                            label: {
                              value: 'From Google Drive',
                            },
                            img: {
                              value: {
                                src: gDrive,
                                alt: '',
                                width: '200',
                                height: '200',
                              },
                            },
                          },
                        },
                      ],
                      inputType: {
                        value: 'FileUpload',
                      },
                      readonly: {
                        value: false,
                      },
                      required: {
                        value: true,
                      },
                      value: {
                        value: '',
                      },
                      width: {
                        value: '100',
                      },
                      pattern: {
                        value: '',
                      },
                    },
                  },
                  {
                    id: '08070fa1-1744-4bad-9357-b2e10e55c33b',
                    name: 'Upload Cover Letter',
                    displayName: 'Upload Cover Letter',
                    fields: {
                      placeholderText: {
                        value: 'Upload Cover Letter (Optional)',
                      },
                      inputType: {
                        value: 'FileUpload',
                      },
                      readonly: {
                        value: false,
                      },
                      values: [
                        {
                          id: '88031c76-c822-4882-9c64-0a1c5fe382b9',
                          fields: {
                            key: {
                              value: '0',
                            },
                            label: {
                              value: 'From your device',
                            },
                            img: {
                              value: {
                                src:
                                  '/-/media/project/manpowergroup/experis/experis-norway/icons/upload-icon-orange.svg?h=200&iar=0&w=200&hash=E129354E220AE58B9D3370A6C7FDCB70',
                                alt: '',
                                width: '200',
                                height: '200',
                              },
                            },
                          },
                        },
                        {
                          id: '08fa4017-b2a0-474b-83e0-81d3f0b129e7',
                          fields: {
                            key: {
                              value: '1',
                            },
                            label: {
                              value: 'From dropBox',
                            },
                            img: {
                              value: {
                                src:
                                  '/-/media/project/manpowergroup/experis/experis-norway/icons/dropbox.svg?h=200&iar=0&w=200&hash=360291D05172FA56E21F4C8E16A89B72',
                                alt: '',
                                width: '200',
                                height: '200',
                              },
                            },
                          },
                        },
                        {
                          id: '233eed07-3e7b-406f-96d1-0e98360e9f9c',
                          fields: {
                            key: {
                              value: '2',
                            },
                            label: {
                              value: 'From oneDrive',
                            },
                            img: {
                              value: {
                                src:
                                  '/-/media/project/manpowergroup/experis/experis-norway/icons/onedrive-icon.svg?h=200&iar=0&w=200&hash=E66AFED211A200FD29C6BC5A9ED6AD6C',
                                alt: '',
                                width: '200',
                                height: '200',
                              },
                            },
                          },
                        },
                        {
                          id: '032441c9-2982-407a-bda8-bd1a2ef48efe',
                          fields: {
                            key: {
                              value: '3',
                            },
                            label: {
                              value: 'From Google Drive',
                            },
                            img: {
                              value: {
                                src:
                                  '/-/media/project/manpowergroup/experis/experis-norway/icons/logo_of_google_drive.svg?h=200&iar=0&w=200&hash=F29B1A88DAE5EF1C1FC4F6F3CF49A1AA',
                                alt: '',
                                width: '200',
                                height: '200',
                              },
                            },
                          },
                        },
                      ],
                      value: {
                        value: '',
                      },
                      width: {
                        value: '100',
                      },
                      label: {
                        value: 'Upload Cover Letter (Optional)',
                      },
                      name: {
                        value: 'uploadCover',
                      },
                      required: {
                        value: true,
                      },
                      pattern: {
                        value: '',
                      },
                      Validations: [],
                    },
                  },
                ],
              },
            },
            {
              id: 'c1fa8066-e146-4279-9d65-cc80347c4e13',
              name: 'Layout2',
              displayName: 'Layout2',
              fields: {
                items: [
                  {
                    id: '5b6862e6-4f4c-4f9b-a1ba-03546a884fc1',
                    name: 'Section 1',
                    displayName: 'Section 1',
                    fields: {
                      title: {
                        value: 'Personal Details',
                      },
                      showLineBar: {
                        value: true,
                      },
                      items: [
                        {
                          id: '544c403b-00eb-42ca-9693-024fe0e501a3',
                          name: 'Salutation',
                          displayName: 'Salutation',
                          fields: {
                            values: [
                              {
                                id: '5133450a-f8c1-461e-87d1-5207d8f7e0b2',
                                fields: {
                                  key: {
                                    value: 'True',
                                  },
                                  label: {
                                    value: 'Mr',
                                  },
                                },
                              },
                              {
                                id: '7bee5408-7370-4690-9623-a8ef27becb04',
                                fields: {
                                  key: {
                                    value: 'False',
                                  },
                                  label: {
                                    value: 'Mrs',
                                  },
                                },
                              },
                            ],
                            position: {
                              value: 'horizontal',
                            },
                            labelPosition: {
                              value: 'inline',
                            },
                            Validations: [
                              {
                                id: '7730be13-d71e-4584-b914-c68ad16b7554',
                                fields: {
                                  message: {
                                    value: '{0} is mandatory',
                                  },
                                  value: {
                                    value: 'true',
                                  },
                                  inputType: {
                                    value: 'requiredValidator',
                                  },
                                },
                              },
                            ],
                            connectorType: {
                              value: '',
                            },
                            connectorMessage: {
                              value: '',
                            },
                            connectorField: {
                              value: '',
                            },
                            connectorPattern: {
                              value: '',
                            },
                            isHideField: {
                              value: false,
                            },
                            inputType: {
                              value: 'RadioButton',
                            },
                            width: {
                              value: '',
                            },
                            label: {
                              value: 'Salutation',
                            },
                            readonly: {
                              value: false,
                            },
                            placeholderText: {
                              value: '',
                            },
                            value: {
                              value: '',
                            },
                            showBasedOnFieldName: {
                              value: '',
                            },
                            hintText: {
                              value: '',
                            },
                            subType: {
                              value: '',
                            },
                            name: {
                              value: 'gender',
                            },
                          },
                        },
                        {
                          id: 'bda91851-3fa6-40da-aa63-7abb62979ec9',
                          name: 'Street Address',
                          displayName: 'Street Address',
                          fields: {
                            Validations: [
                              {
                                id: '7730be13-d71e-4584-b914-c68ad16b7554',
                                fields: {
                                  message: {
                                    value: '{0} is mandatory',
                                  },
                                  value: {
                                    value: 'true',
                                  },
                                  inputType: {
                                    value: 'requiredValidator',
                                  },
                                },
                              },
                            ],
                            connectorType: {
                              value: '',
                            },
                            connectorMessage: {
                              value: '',
                            },
                            connectorField: {
                              value: '',
                            },
                            connectorPattern: {
                              value: '',
                            },
                            isHideField: {
                              value: false,
                            },
                            inputType: {
                              value: 'Text',
                            },
                            width: {
                              value: '',
                            },
                            label: {
                              value: 'Street Address',
                            },
                            readonly: {
                              value: false,
                            },
                            placeholderText: {
                              value: '',
                            },
                            value: {
                              value: '',
                            },
                            showBasedOnFieldName: {
                              value: '',
                            },
                            hintText: {
                              value: '',
                            },
                            subType: {
                              value: '',
                            },
                            name: {
                              value: 'streetAddress',
                            },
                          },
                        },
                        {
                          id: 'ea4853c7-5c89-42e1-99d1-903a254ab1f8',
                          name: 'Phone Number',
                          displayName: 'Phone Number',
                          fields: {
                            Validations: [
                              {
                                id: '7730be13-d71e-4584-b914-c68ad16b7554',
                                fields: {
                                  message: {
                                    value: '{0} is mandatory',
                                  },
                                  value: {
                                    value: 'true',
                                  },
                                  inputType: {
                                    value: 'requiredValidator',
                                  },
                                },
                              },
                            ],
                            connectorType: {
                              value: '',
                            },
                            connectorMessage: {
                              value: '',
                            },
                            connectorField: {
                              value: '',
                            },
                            connectorPattern: {
                              value: '',
                            },
                            isHideField: {
                              value: false,
                            },
                            inputType: {
                              value: 'Text',
                            },
                            width: {
                              value: '',
                            },
                            label: {
                              value: 'Phone Number',
                            },
                            readonly: {
                              value: false,
                            },
                            placeholderText: {
                              value: '',
                            },
                            value: {
                              value: '',
                            },
                            showBasedOnFieldName: {
                              value: '',
                            },
                            hintText: {
                              value: '',
                            },
                            subType: {
                              value: '',
                            },
                            name: {
                              value: 'phoneNumber',
                            },
                          },
                        },
                        {
                          id: '2847cae3-6015-4c46-8303-8c4034f86bd9',
                          name: 'Postal Code',
                          displayName: 'Postal Code',
                          fields: {
                            Validations: [
                              {
                                id: '7730be13-d71e-4584-b914-c68ad16b7554',
                                fields: {
                                  message: {
                                    value: '{0} is mandatory',
                                  },
                                  value: {
                                    value: 'true',
                                  },
                                  inputType: {
                                    value: 'requiredValidator',
                                  },
                                },
                              },
                            ],
                            connectorType: {
                              value: '',
                            },
                            connectorMessage: {
                              value: '',
                            },
                            connectorField: {
                              value: '',
                            },
                            connectorPattern: {
                              value: '',
                            },
                            isHideField: {
                              value: false,
                            },
                            inputType: {
                              value: 'Text',
                            },
                            width: {
                              value: '50',
                            },
                            label: {
                              value: 'Postal Code',
                            },
                            readonly: {
                              value: false,
                            },
                            placeholderText: {
                              value: '',
                            },
                            value: {
                              value: '',
                            },
                            showBasedOnFieldName: {
                              value: '',
                            },
                            hintText: {
                              value: '',
                            },
                            subType: {
                              value: '',
                            },
                            name: {
                              value: 'postalCode',
                            },
                          },
                        },
                        {
                          id: '7f8c97e2-cbb4-4580-92b0-4bd83b77821a',
                          name: 'City',
                          displayName: 'City',
                          fields: {
                            Validations: [
                              {
                                id: '7730be13-d71e-4584-b914-c68ad16b7554',
                                fields: {
                                  message: {
                                    value: '{0} is mandatory',
                                  },
                                  value: {
                                    value: 'true',
                                  },
                                  inputType: {
                                    value: 'requiredValidator',
                                  },
                                },
                              },
                            ],
                            connectorType: {
                              value: '',
                            },
                            connectorMessage: {
                              value: '',
                            },
                            connectorField: {
                              value: '',
                            },
                            connectorPattern: {
                              value: '',
                            },
                            isHideField: {
                              value: false,
                            },
                            inputType: {
                              value: 'Text',
                            },
                            width: {
                              value: '50',
                            },
                            label: {
                              value: 'City',
                            },
                            readonly: {
                              value: false,
                            },
                            placeholderText: {
                              value: '',
                            },
                            value: {
                              value: '',
                            },
                            showBasedOnFieldName: {
                              value: '',
                            },
                            hintText: {
                              value: '',
                            },
                            subType: {
                              value: '',
                            },
                            name: {
                              value: 'city',
                            },
                          },
                        },
                      ],
                    },
                  },
                  {
                    id: '443df2a8-a1fb-4e0d-9f05-a2fd7bc86864',
                    name: 'Section 2',
                    displayName: 'Section 2',
                    fields: {
                      title: {
                        value: 'Application Information',
                      },
                      items: [
                        {
                          id: 'c0e3c5cf-99b0-40ac-b065-d343be850c39',
                          name: 'Upload Resume',
                          displayName: 'Upload Resume',
                          fields: {
                            values: [
                              {
                                id: '88031c76-c822-4882-9c64-0a1c5fe382b9',
                                fields: {
                                  key: {
                                    value: '0',
                                  },
                                  label: {
                                    value: 'From your device',
                                  },
                                  img: {
                                    value: {
                                      src:
                                        '/-/media/project/manpowergroup/experis/experis-norway/icons/upload-icon-orange.svg?h=200&iar=0&w=200&hash=E129354E220AE58B9D3370A6C7FDCB70',
                                      alt: '',
                                      width: '200',
                                      height: '200',
                                    },
                                  },
                                },
                              },
                              {
                                id: '08fa4017-b2a0-474b-83e0-81d3f0b129e7',
                                fields: {
                                  key: {
                                    value: '1',
                                  },
                                  label: {
                                    value: 'From dropBox',
                                  },
                                  img: {
                                    value: {
                                      src:
                                        '/-/media/project/manpowergroup/experis/experis-norway/icons/dropbox.svg?h=200&iar=0&w=200&hash=360291D05172FA56E21F4C8E16A89B72',
                                      alt: '',
                                      width: '200',
                                      height: '200',
                                    },
                                  },
                                },
                              },
                              {
                                id: '233eed07-3e7b-406f-96d1-0e98360e9f9c',
                                fields: {
                                  key: {
                                    value: '2',
                                  },
                                  label: {
                                    value: 'From oneDrive',
                                  },
                                  img: {
                                    value: {
                                      src:
                                        '/-/media/project/manpowergroup/experis/experis-norway/icons/onedrive-icon.svg?h=200&iar=0&w=200&hash=E66AFED211A200FD29C6BC5A9ED6AD6C',
                                      alt: '',
                                      width: '200',
                                      height: '200',
                                    },
                                  },
                                },
                              },
                              {
                                id: '032441c9-2982-407a-bda8-bd1a2ef48efe',
                                fields: {
                                  key: {
                                    value: '3',
                                  },
                                  label: {
                                    value: 'From Google Drive',
                                  },
                                  img: {
                                    value: {
                                      src:
                                        '/-/media/project/manpowergroup/experis/experis-norway/icons/logo_of_google_drive.svg?h=200&iar=0&w=200&hash=F29B1A88DAE5EF1C1FC4F6F3CF49A1AA',
                                      alt: '',
                                      width: '200',
                                      height: '200',
                                    },
                                  },
                                },
                              },
                            ],
                            hintText: {
                              value:
                                'Data wont be parsed by uploading here. Visit build profile section for parsing.',
                            },
                            toolTip: {
                              value:
                                'A résumé with information about yourself / your professional path increases the chances of getting the ideal job. PDF, TXT, RTF, DOC - max. 1 document / 2 MB.',
                            },
                            required: {
                              value: false,
                            },
                            filterRequired: {
                              value: false,
                            },
                            showOnload: {
                              value: false,
                            },
                            apiEndPoint: {
                              value: '',
                            },
                            maxFileCount: {
                              value: '',
                            },
                            isMultifiles: {
                              value: false,
                            },
                            Validations: [
                              {
                                id: '7730be13-d71e-4584-b914-c68ad16b7554',
                                fields: {
                                  message: {
                                    value: '{0} is mandatory',
                                  },
                                  value: {
                                    value: 'true',
                                  },
                                  inputType: {
                                    value: 'requiredValidator',
                                  },
                                },
                              },
                            ],
                            connectorType: {
                              value: '',
                            },
                            connectorMessage: {
                              value: '',
                            },
                            connectorField: {
                              value: '',
                            },
                            connectorPattern: {
                              value: '',
                            },
                            isHideField: {
                              value: false,
                            },
                            inputType: {
                              value: 'FileUpload',
                            },
                            width: {
                              value: '100',
                            },
                            label: {
                              value: 'Upload Resume',
                            },
                            readonly: {
                              value: false,
                            },
                            placeholderText: {
                              value: 'Upload Resume',
                            },
                            value: {
                              value: '',
                            },
                            showBasedOnFieldName: {
                              value: '',
                            },
                            subType: {
                              value: '',
                            },
                            name: {
                              value: 'uploadResume',
                            },
                          },
                        },
                        {
                          id: '8ba4e7b1-bb47-44d1-9c47-fd0e244db2e7',
                          name: 'Upload Cover Letter',
                          displayName: 'Upload Cover Letter',
                          fields: {
                            values: [
                              {
                                id: '88031c76-c822-4882-9c64-0a1c5fe382b9',
                                fields: {
                                  key: {
                                    value: '0',
                                  },
                                  label: {
                                    value: 'From your device',
                                  },
                                  img: {
                                    value: {
                                      src:
                                        '/-/media/project/manpowergroup/experis/experis-norway/icons/upload-icon-orange.svg?h=200&iar=0&w=200&hash=E129354E220AE58B9D3370A6C7FDCB70',
                                      alt: '',
                                      width: '200',
                                      height: '200',
                                    },
                                  },
                                },
                              },
                              {
                                id: '08fa4017-b2a0-474b-83e0-81d3f0b129e7',
                                fields: {
                                  key: {
                                    value: '1',
                                  },
                                  label: {
                                    value: 'From dropBox',
                                  },
                                  img: {
                                    value: {
                                      src:
                                        '/-/media/project/manpowergroup/experis/experis-norway/icons/dropbox.svg?h=200&iar=0&w=200&hash=360291D05172FA56E21F4C8E16A89B72',
                                      alt: '',
                                      width: '200',
                                      height: '200',
                                    },
                                  },
                                },
                              },
                              {
                                id: '233eed07-3e7b-406f-96d1-0e98360e9f9c',
                                fields: {
                                  key: {
                                    value: '2',
                                  },
                                  label: {
                                    value: 'From oneDrive',
                                  },
                                  img: {
                                    value: {
                                      src:
                                        '/-/media/project/manpowergroup/experis/experis-norway/icons/onedrive-icon.svg?h=200&iar=0&w=200&hash=E66AFED211A200FD29C6BC5A9ED6AD6C',
                                      alt: '',
                                      width: '200',
                                      height: '200',
                                    },
                                  },
                                },
                              },
                              {
                                id: '032441c9-2982-407a-bda8-bd1a2ef48efe',
                                fields: {
                                  key: {
                                    value: '3',
                                  },
                                  label: {
                                    value: 'From Google Drive',
                                  },
                                  img: {
                                    value: {
                                      src:
                                        '/-/media/project/manpowergroup/experis/experis-norway/icons/logo_of_google_drive.svg?h=200&iar=0&w=200&hash=F29B1A88DAE5EF1C1FC4F6F3CF49A1AA',
                                      alt: '',
                                      width: '200',
                                      height: '200',
                                    },
                                  },
                                },
                              },
                            ],
                            hintText: {
                              value: '',
                            },
                            required: {
                              value: false,
                            },
                            filterRequired: {
                              value: false,
                            },
                            showOnload: {
                              value: true,
                            },
                            apiEndPoint: {
                              value:
                                'api/services/Candidate/DownloadAttachment?{cid}',
                            },
                            maxFileCount: {
                              value: '5',
                            },
                            isMultifiles: {
                              value: false,
                            },
                            Validations: [],
                            connectorType: {
                              value: '',
                            },
                            connectorMessage: {
                              value: '',
                            },
                            connectorField: {
                              value: '',
                            },
                            connectorPattern: {
                              value: '',
                            },
                            isHideField: {
                              value: false,
                            },
                            inputType: {
                              value: 'FileUpload',
                            },
                            width: {
                              value: '100',
                            },
                            label: {
                              value: 'Upload Cover Letter (Optional)',
                            },
                            readonly: {
                              value: false,
                            },
                            placeholderText: {
                              value: 'Upload Cover Letter (Optional)',
                            },
                            value: {
                              value: '',
                            },
                            showBasedOnFieldName: {
                              value: '',
                            },
                            subType: {
                              value: '',
                            },
                            name: {
                              value: 'uploadCover',
                            },
                          },
                        },
                        {
                          id: '44b3fafc-fe6e-4f40-80b8-57f22304859d',
                          name: 'Consider me for more jobs',
                          displayName: 'Consider me for more jobs',
                          fields: {
                            text: {
                              value:
                                '<span style="font-size: 16px; color: #212529;">Consider me for more Jobs.</span><br />\n<span style="font-size: 16px; color: #939498;">The ManpowerGroup protects and processes your data in accordance with the data processing agreement.</span>',
                            },
                            Validations: [
                              {
                                id: '7730be13-d71e-4584-b914-c68ad16b7554',
                                fields: {
                                  message: {
                                    value: '{0} is mandatory',
                                  },
                                  value: {
                                    value: 'true',
                                  },
                                  inputType: {
                                    value: 'requiredValidator',
                                  },
                                },
                              },
                            ],
                            connectorType: {
                              value: '',
                            },
                            connectorMessage: {
                              value: '',
                            },
                            connectorField: {
                              value: '',
                            },
                            connectorPattern: {
                              value: '',
                            },
                            isHideField: {
                              value: false,
                            },
                            inputType: {
                              value: 'CheckBox',
                            },
                            width: {
                              value: '',
                            },
                            label: {
                              value: 'Consider me for more jobs',
                            },
                            readonly: {
                              value: false,
                            },
                            placeholderText: {
                              value: '',
                            },
                            value: {
                              value: '',
                            },
                            showBasedOnFieldName: {
                              value: '',
                            },
                            hintText: {
                              value: '',
                            },
                            subType: {
                              value: '',
                            },
                            name: {
                              value: 'isLegalNoticePrivacy',
                            },
                            values: [
                              {
                                id: '5cd0a5fc-1ac8-44b0-a2d2-c4d16339eaa9',
                                fields: {
                                  label: {
                                    value: 'Y',
                                  },
                                  key: {
                                    value: 'true',
                                  },
                                },
                              },
                              {
                                id: '8fa43687-dd8c-4e0b-a29f-2fd618898bb0',
                                fields: {
                                  label: {
                                    value: 'N',
                                  },
                                  key: {
                                    value: 'false',
                                  },
                                },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  },
};
