import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CardSubpage from '../Cards/CardSubpage';
import CardSubpageDiamond from '../Cards/CardSubpageDiamond';

import './cardblock.scss';

/**
 * @description - subpage card block
 * @param {props} param0 - input props
 * @returns{node} - HTMl card
 *
 */
const CardBlockSubpage = (props) => {
  const { items = [] } = props.fields;
  const cssClass = props.params?.Type ? props.params?.Type : 'square';
  const theme = cssClass == 'diamond' ? 'gradient_green' : 'gradient_blue';

  return (
    <section className={`card-block subpage ${theme} ${cssClass}`}>
      <div className="container">
        <div className="row">
          {items.map((x, index) => (
            <Fragment key={index}>
              <div className="col-lg-6 row-div">
                {cssClass === 'diamond' ? (
                  <CardSubpageDiamond
                    cssClass={cssClass}
                    key={x.key}
                    title={x.fields?.title}
                    text={x.fields?.text}
                    ctaText={x.fields?.ctaText}
                    ctaUrl={x.fields?.ctaUrl}
                    img={x.fields?.image}
                    items={x.fields.items}
                  />
                ) : (
                  <CardSubpage
                    cssClass={cssClass}
                    key={x.key}
                    title={x.fields?.title}
                    text={x.fields?.text}
                    ctaText={x.fields?.ctaText}
                    ctaUrl={x.fields?.ctaUrl}
                    img={x.fields?.image}
                    items={x.fields.items}
                  />
                )}
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

CardBlockSubpage.defaultProps = {
  fields: { items: [] },
};

CardBlockSubpage.propTypes = {
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        cssClass: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.string,
        img: PropTypes.string,
        altText: PropTypes.string,
        ctaText: PropTypes.string,
        ctaUrl: PropTypes.string,
      })
    ).isRequired,
  }),
};

export default CardBlockSubpage;
