import React, { useRef, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import useRoveFocus from '../../../hooks/useRoveFocus';
// import './dropdowns.scss';

/**
 *
 * @param {*} setSelectedOption
 * @param {*} setSelectedLabel
 * @param {*} option
 */
const setValues = (setSelectedLabel, option, actionParams, clickHandler) => {
  setSelectedLabel(option.value);
  clickHandler(option, actionParams);
};

/**
 *
 * @param {*} e
 * @param {*} setSelectedLabel
 * @param {*} option
 * @param {*} actionParams
 * @param {*} clickHandler
 */
const setValuesOnEnter = (
  e,
  setSelectedLabel,
  option,
  actionParams,
  clickHandler
) => {
  if (e && e.keyCode === 13) {
    setValues(setSelectedLabel, option, actionParams, clickHandler);
  }
};

/**
 *
 * @param {*} param0
 */
export const ListItem = ({
  option,
  clickHandler,
  actionParams,
  selectedOption,
  setSelectedLabel,
  index,
  focus,
  setFocus,
}) => {
  const ref = useRef(null);
  useEffect(() => {
    if (focus) {
      // Move element into view when it is focused
      ref.current.focus();
    }
  }, [focus]);

  const handleSelect = useCallback(
    (e) => {
      setFocus(index);
      setValuesOnEnter(e, setSelectedLabel, option, actionParams, clickHandler);
    },
    [option, index, setFocus]
  );

  return (
    <div
      key={option.title}
      className={classNames('sort-option', {
        active: selectedOption === option.id,
      })}
      onClick={() => setValues(setSelectedLabel, option, actionParams, clickHandler)}
      role="option"
      aria-selected={selectedOption === option.id}
      onKeyUp={handleSelect}
      tabIndex={0}
      ref={ref}
    >
      {option.title}
    </div>
  );
};

/**
 * @description - Sort options list.
 * @param {object} param0 - Input params.
 * @returns {Node} - Options list.
 */
const Sort = ({
  options,
  clickHandler,
  actionParams,
  selectedOption,
  setSelectedLabel,
}) => {
  const [focus, setFocus] = useRoveFocus(options.length);

  return (
    <div role="listbox">
      {options.map((option, index) => (
        <ListItem
          key={`${option.title}_${index}`}
          clickHandler={clickHandler}
          actionParams={actionParams}
          selectedOption={selectedOption}
          setSelectedLabel={setSelectedLabel}
          option={option}
          index={index}
          focus={focus === index}
          setFocus={setFocus}
        />
      ))}
    </div>
  );
};

Sort.defaultProps = {
  options: [],
  setSelectedOption: () => null,
  setSelectedLabel: () => null,
};

Sort.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    })
  ),
  setSelectedOption: PropTypes.func,
  setSelectedLabel: PropTypes.func,
};

export default Sort;
