/**
 * @description - to calculate nearest office locations
 * @param {*} props - loation properties
 * @param {string} lat - latitude of from location
 * @param {string} lng - longitude of from location
 * @param {boolean} highlightNearest - to hide and show large pointer
 * @returns {Array} - list of office locations as nearest first
 */
export const NearbyFinder = (props, lat, lng, highlightNearest = true) => {
  const { fields } = props;
  /**
   * @description - to calculate distance between two geometry
   * @param {*} lat2 - to location latitude
   * @param {*} lon2 - to location longitude
   * @param {*} lat1 - from latitude
   * @param {*} lon1 - from longitude
   * @param {*} unit - unit in which distane to be calculated
   * @returns {number} - distance calculated
   */
  const distance = (lat2, lon2, lat1, lon1, unit = 'K') => {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') {
      dist = dist * 1.609344;
    }
    if (unit === 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  };

  /**
   * @description -  calculate disatnce and add to location list array
   * @param {number} lat - from location latitude
   * @param {number} lng - from location longitude
   * @param {boolean} highlightNearest - to hide and show large pointer
   * @returns {Array} - office location list with distance
   */
  const findDistance = (lat, lng, highlightNearest) => {
    if (Array.isArray(fields?.items)) {
      fields.items.forEach((item, index) => {
        fields.items[index].fields['distance'] = distance(
          parseFloat(item.fields?.latitude?.value),
          parseFloat(item.fields?.longtitude?.value),
          lat,
          lng
        );
      });
      fields.items.sort((a, b) => {
        if (isNaN(a?.fields?.distance) || isNaN(b?.fields?.distance)) {
          return 1;
        }
        return a?.fields?.distance > b?.fields?.distance ? 1 : -1;
      });
      fields.items.map((marker, index) => {
        marker.lat = parseFloat(marker?.fields?.latitude?.value);
        marker.lng = parseFloat(marker?.fields?.longtitude?.value);
        marker.locationUrl = marker?.fields?.ViewDetailsUrl?.value?.href;
        marker.isPrimary = highlightNearest === true && index === 0 ? true : false;
      });

      return fields.items;
    }
  };
  return Promise.resolve(findDistance(lat, lng, highlightNearest));
};
