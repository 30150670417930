/*eslint-disable*/
import React, { useEffect, useState, useReducer } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';
import lget from 'lodash.get';
import { withTranslation } from 'react-i18next';
import { dataFetcher } from '../../../../dataFetcher';
import { dropDownFormat } from '../DynamicForm/helper/dataFactory';
import { getValidationDatabyType } from '../DynamicForm/helper/filter';
import { API } from '../../../../constants';
import { symbolValidationCheck } from '../DynamicForm/helper/validationFactory';
import { checkValueTypeAndGetTheCount } from '../../../business/Utils/helper';

const { ValueContainer, Placeholder } = components;

const NewValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder
        {...props}
        isFocused={props.selectProps.menuIsOpen || props.hasValue}
      >
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const StyleObject = (error) => {
  return {
    menu: (styles) => ({ ...styles, zIndex: 999 }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      zIndex: 999999,
    }),
    menuList: (provided, state) => ({
      ...provided,
      overflowX: 'hidden',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: 'absolute',
      color: 'rgba(103, 105, 111, 0.5)',
      top:
        state.isFocused || state.isSelected || state.selectProps.inputValue
          ? 15
          : '50%',
      transition: 'top 0.1s, font-size 0.1s',
      whiteSpace: 'nowrap',
      fontSize:
        state.isFocused || state.isSelected || state.selectProps.inputValue
          ? 12
          : 16,
    }),
    control: (base) => ({
      ...base,
      height: 49,
      minHeight: 49,
      ...(error
        ? {
          border: '1px solid  #9d323d',
          '&:hover': { borderColor: '#9d323d' },
          boxShadow: 'none',
        }
        : { boxShadow: 'none' }),
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 49,
      // overflow: 'visible',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      // position: 'relative',
      // display: 'inline-block',
      top: 35,
    }),
  };
};

const SelectComplex = (props) => {
  const {
    className,
    name,
    label,
    options,
    isClearable,
    isSearchable,
    isMulti,
    customStyle,
    type,
    isApi,
    apiEndPoint,
    dataValidations,
    requiredValidation = [],
    handleSelected,
    customError,
    control,
    value,
    connectorField,
    connectorType,
    connectorMessage,
    connectorPattern,
    getValues,
    setValue,
    formName,
    readOnly,
    validateOnLoad,
    t,
    dateFormat,
    trigger,
    clearErrors,
    id,
    showBasedOnFieldName,
    hideBasedOnFieldName,
    watch,
    isHideField,
    hasApiCollection,
    apiData,
  } = props;
  const fieldError = lget(customError, name);
  const [loading, setLoading] = useState(false);
  const [dropDownData, setDropDownData] = useState([]);

  const [selectedValue, setSelectedValue] = useState();
  const requiredData =
    requiredValidation?.length > 0
      ? requiredValidation[0]
      : getValidationDatabyType(dataValidations, 'required');
  let param = readOnly
    ? { shouldValidate: true }
    : { shouldValidate: true, shouldDirty: true };
  param = validateOnLoad ? param : {};
  const optionSet = isApi ? dropDownData : options;
  const [showField, setShowField] = useState(true);
  const [hideField, setHideField] = useState(false);
  const connectorFormName = formName
    ? `${formName}[${connectorField}]`
    : connectorField;
  const showFieldName = formName
    ? `${formName}[${showBasedOnFieldName}]`
    : showBasedOnFieldName;
  const hideFieldName = formName
    ? `${formName}[${hideBasedOnFieldName}]`
    : hideBasedOnFieldName;

  const showFieldValue = showFieldName ? watch(showFieldName) : null;
  const hideFieldValue = hideFieldName ? watch(hideFieldName) : null;

  useEffect(() => {
    if (showFieldValue) {
      let isFieldHasVal = checkValueTypeAndGetTheCount(showFieldValue);
      setShowField(isFieldHasVal);
    }
  }, [showFieldValue]);

  useEffect(() => {
    if (hideFieldValue) {
      let isFieldHasVal = checkValueTypeAndGetTheCount(hideFieldValue);
      setHideField(isFieldHasVal);
    }
  }, [hideFieldValue]);

  useEffect(() => {
    let OptionObj = '';
    if (optionSet.length > 0) {
      OptionObj = optionSet.find(function (item) {
        return item.value === value || item.label === value;
      });
      setValue(name, OptionObj, param);
      setSelectedValue(OptionObj);
    }
  }, [value, dropDownData]);
  useEffect(() => {
    if (isApi) {
      setLoading(true);
      if (!hasApiCollection)
        dataFetcher(`${API.HOST}${apiEndPoint}`).then(
          (response) => {
            setLoading(false);
            setDropDownData(
              dropDownFormat(response?.data, getType(), 0, 0, '', dateFormat)
            );
          },
          (error) => {
            setLoading(false);
          }
        );
    }
  }, []);

  useEffect(() => {
    if (apiData) {
      setLoading(false);
      setDropDownData(dropDownFormat(apiData, getType(), 0, 0, '', dateFormat));
    }
  }, [apiData]);

  const getType = () =>
    type === 'DateDropdown' ? 'DateDropdownAPI' : 'DropdownAPI';

  const dataValidator = (value) => {
    let result = true;
    const connectoreFieldsValue = connectorFormName
      ? getValues(connectorFormName)
      : null;
    connectoreFieldsValue = connectoreFieldsValue?.value
      ? connectoreFieldsValue.value
      : connectoreFieldsValue;
    if (value && connectoreFieldsValue) {
      result = symbolValidationCheck(value, connectoreFieldsValue, connectorType);
    }
    return result;
  };

  return (
    <div
      className={`form-block select-complex ${fieldError ? 'error withoutBg' : ''} ${isHideField || !showField || hideField ? 'hide' : ''
        }`}
      style={customStyle}
      role="combobox"
    >
      <Controller
        as={<ReactSelect />}
        defaultValue={selectedValue}
        options={optionSet}
        className={className}
        name={name}
        isMulti={isMulti}
        isClearable={isClearable}
        isSearchable={isSearchable}
        control={control}
        placeholder={label}
        isLoading={loading}
        isDisabled={readOnly}
        rules={{
          required:
            ((!isHideField || !showField || hideField) &&
              requiredData?.fields?.value?.value) ||
            false,
          dateValidator: (value) =>
            (connectorField ? dataValidator(value) : true) ||
            connectorMessage ||
            t('validation-failed'),
        }}
        styles={StyleObject(fieldError)}
        components={{ ValueContainer: NewValueContainer }}
      />
      {fieldError && (
        <div className="error-msg" id={`err_${id}`} role="alert">
          {fieldError.type === 'required'
            ? requiredData?.fields?.message?.value
              ? requiredData?.fields?.message?.value?.replace(
                '{0}',
                label?.replace('*', '')
              )
              : t('mandatory-field-message').replace('{0}', label?.replace('*', ''))
            : fieldError?.message?.replace('{0}', label?.replace('*', ''))}
        </div>
      )}
    </div>
  );
};

SelectComplex.defaultProps = {
  id: '',
  label: '',
  name: '',
  placeholder: '',
  customStyle: {},
  readOnly: false,
  value: '',
  customError: {},
  setValue: () => { },
  t: () => { },
  dateFormat: 'LL',
};

SelectComplex.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  dateFormat: PropTypes.string,
  customStyle: PropTypes.shape({}),
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  customError: PropTypes.shape({}),
  setValue: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation()(SelectComplex);
