import { createGlobalStyle } from 'styled-components';

export const CardStyle = createGlobalStyle`
.card{
    .article-arrow svg.bi.bi-arrow-right{
        fill: ${({ theme }) => theme?.secondaryButton} !important;
        width: 45px;
        height: 45px;
    }
    &.related-content{
        .sub-title{
            color: ${({ theme }) => theme?.primaryColor} !important;
        }
    }
    &.article {
        &.featured {
            .card-body{
                border-color: ${({ theme }) => theme?.secondaryButton};
                border-top: 4px solid ${({ theme }) => theme?.secondaryButton};
                @include mobile {
                    border-top: ${({ theme }) => theme?.secondaryButton};
                }
            }
        }
        .card-body{
            border-top: 4px solid ${({ theme }) => theme?.secondaryButton};
        }
        .sub-title {
            color: ${({ theme }) => theme?.primaryColor} !important;
        }
    }
    &.job{
        .sub-title{
            color: ${({ theme }) => theme?.secondaryButtonLink}
        }
    }
    &.leadership {
        .card-body {
            p {
                a{
                    color: ${({ theme }) => theme?.primaryButton};
                }
            }
        }
    } 
} 
`;
