import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { showString } from '../../../../utils/helperUtils';
import './links.scss';
import './buttons.scss';

/**
 * @description - StaticLink Component.
 * @param {Object} Params - Input Parameters.
 * @returns {Node} - HTML Template.
 */
const StaticLink = ({ cssClass, ctaText, ctaLabel, ctaIcon, arrow }) => {
  return (
    <div className="textLink">
      <div className={`${cssClass} link-div`} aria-label={ctaLabel}>
        <div className={`cta-text ${cssClass}`}>
          {ctaText?.value ? <Text field={ctaText} /> : showString(ctaText)}
          {arrow && (
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-arrow-right"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              />
            </svg>
          )}
        </div>
        {ctaIcon && (
          <div className="cta-icon">
            <img src={ctaIcon} alt="icon" />
          </div>
        )}
      </div>
    </div>
  );
};

StaticLink.defaultProps = {
  cssClass: '',
  ctaText: '',
  ctaLabel: '',
  ctaIcon: '',
  arrow: false,
};

StaticLink.propTypes = {
  cssClass: PropTypes.string,
  ctaText: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  ctaLabel: PropTypes.string,
  ctaIcon: PropTypes.string,
  arrow: PropTypes.bool,
};

export default StaticLink;
