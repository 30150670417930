/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  symbolValidationCheck,
  dateValiditionCheck,
  yearValiditionCheck,
  patternRegexCheck,
} from '../../DynamicForm/helper/validationFactory';
import { getValidationDatabyType } from '../../DynamicForm/helper/filter';
import { withTranslation } from 'react-i18next';

class DateInputComplex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filled: false,
      inputValue: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.value && this.props.value !== '') {
      let inputVal = this.props?.value?.replace(/-/g, '/');
      const dateVar = inputVal?.split('/');
      const dateVal =
        dateVar?.length === 3
          ? new Date(dateVar[1] + '/' + dateVar[0] + '/' + dateVar[2])
          : '';
      const isValid = moment(dateVal).isValid();
      if (isValid) {
        const formattedInputDate = moment(dateVal).format('DD/MM/YYYY');
        this.setState({
          filled: formattedInputDate && formattedInputDate !== '',
          inputValue: formattedInputDate,
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      let inputVal = this.props?.value?.replace(/-/g, '/');
      const dateVar = inputVal?.split('/');
      const dateVal =
        dateVar?.length === 3
          ? new Date(dateVar[1] + '/' + dateVar[0] + '/' + dateVar[2])
          : '';
      const isValid = moment(dateVal).isValid();
      if (isValid) {
        const formattedInputDate = moment(dateVal).format('DD/MM/YYYY');
        this.setState({
          filled: formattedInputDate && formattedInputDate !== '',
          inputValue: formattedInputDate,
        });
      }
    }
  }
/***
 * @description Function to set the format of date.
 * @param {Number} elm value of input field
 * @returns {undefined} 
 */
  dateInputMask = (elm) => {
    elm.addEventListener('keypress', (e) => {
      if (e.keyCode < 47 || e.keyCode > 57) {
        e.preventDefault();
      }

      const len = elm.value.length;

      if (len !== 1 || len !== 3) {
        if (e.keyCode === 47) {
          e.preventDefault();
        }
      }

      if (len === 2) {
        elm.value += '/';
      }

      if (len === 5) {
        elm.value += '/';
      }
    });
  };

  /**
   * @description to handle change in date input field
   * @param {*} event  input change event
   * @returns {undefined} nothing
   */
  
  handleChange(event) {
    this.dateInputMask(event.target);
    // handleChange(event);
    if (event.target.value) {
      this.setState({
        filled: true,
        inputValue: event.target.value,
      });
    } else {
      this.setState({
        filled: false,
        inputValue: event.target.value,
      });
    }
  }

  // handleEnter = (e) => {
  //   if (e.keyCode === 13) {
  //     this.props.handleEnter(this.state.inputValue);
  //   }
  // };

  // handleValidations = () => {
  //   this.props.handleValidations(this.state.inputValue);
  // };

  /**
   * @description - Function to prevent pasting.
   * @param {Object} event - Event.
   * @returns {undefined}
   */
  handlePaste = (event) => {
    event.preventDefault();
  };

  render() {
    const { t } = this.props;
    const patternData = getValidationDatabyType(
      this.props.dataValidations,
      'pattern'
    );
    const requiredData = getValidationDatabyType(
      this.props.dataValidations,
      'required'
    );
    const maximumData = getValidationDatabyType(
      this.props.dataValidations,
      'maximum'
    );
    const minimumData = getValidationDatabyType(
      this.props.dataValidations,
      'minimum'
    );
    const dateData = getValidationDatabyType(this.props.dataValidations, 'date');
    const dobData = getValidationDatabyType(this.props.dataValidations, 'dob');
    const connectorData = {
      connectorField: this.props.connectorField,
      connectorType: this.props.connectorType,
      getValues: this.props.getValues,
    };
    const showField = this.props.watch
      ? this.props.watch(this.props.showBasedOnFieldName)
      : true;
    return (
      <div
        className={`form-block complex ${this.props.cssClass} ${
          this.props.isHideField && !showField ? 'hide' : ''
        }`}
        style={this.props.customStyle}
      >
        <input
          className={this.state.filled ? 'filled' : ''}
          id={this.props.id}
          name={this.props.name}
          type="text"
          // placeholder={this.props.placeholder}
          onChange={this.handleChange}
          defaultValue={this.state.inputValue}
          readOnly={this.props.readOnly}
          onKeyUp={this.props.onEnter ? this.handleEnter : () => {}}
          // onBlur={!this.props.onEnter ? this.handleValidations : () => {}}
          maxLength="10"
          onPaste={this.handlePaste}
          ref={
            this.props.register
              ? this.props.register({
                  required:
                    ((!this.props.isHideField || showField) &&
                      requiredData?.fields?.value?.value) ||
                    false,
                  validate: {
                    symbolValidationCheck: (value) =>
                      (connectorData.connectorField
                        ? symbolValidationCheck(value, connectorData)
                        : true) ||
                      this.props.connectorMessage ||
                      t('validation-failed'),
                    yearValiditionCheck: (value) =>
                      (dobData ? yearValiditionCheck(value, dobData) : true) ||
                      dobData?.fields?.message?.value ||
                      t('validation-failed'),
                    dateValiditionCheck: (value) =>
                      (dateData ? dateValiditionCheck(value, dateData) : true) ||
                      dateData?.fields?.message?.value ||
                      t('validation-failed'),
                  },
                  maxLength: {
                    value: maximumData?.fields?.value?.value || null,
                    message:
                      maximumData?.fields?.message?.value || t('validation-failed'),
                  },
                  minLength: {
                    value: minimumData?.fields?.value?.value || null,
                    message:
                      minimumData?.fields?.message?.value || t('validation-failed'),
                  },
                  pattern: {
                    value: patternData?.fields?.value?.value
                      ? patternRegexCheck(patternData.fields.value.value)
                      : '',
                    message:
                      patternData?.fields?.message?.value || t('validation-failed'),
                  },
                })
              : () => {}
          }
        />
        {this.props.placeholder && (
          <label htmlFor={this.props.id}>
            <div className="active">
              {this.props.icon && (
                <div className="input-pin-icon-wrap">
                  <span className="input-pin-icon"></span>
                </div>
              )}
              {this.props.placeholder}
            </div>
            <div className="inactive">
              {this.props.icon && (
                <div className="input-pin-icon-wrap">
                  <span className="input-pin-icon"></span>
                </div>
              )}
              {this.props.label && this.props.label !== ''
                ? this.props.label
                : this.props.placeholder}
            </div>
          </label>
        )}
        {this.props.errorMsg && (
          <div className="error-msg">{this.props.errorMsg}</div>
        )}
        {this.props.customError && this.props.customError[this.props.name] && (
          <div className="error-msg">
            {this.props.customError[this.props.name]?.type === 'required'
              ? requiredData?.fields?.message?.value
                ? requiredData?.fields?.message?.value?.replace(
                    '{0}',
                    this.props.placeholder?.replace('*', '')
                  )
                : t('mandatory-field-message')
              : this.props?.customError[this.props.name]?.message?.replace(
                  '{0}',
                  this.props.placeholder?.replace('*', '')
                )}
          </div>
        )}
      </div>
    );
  }
}

DateInputComplex.defaultProps = {
  id: '',
  name: '',
  label: '',
  label2: '',
  placeholder: '',
  type: 'text',
  cssClass: '',
  icon: '',
  errorMsg: '',
  onEnter: true,
  customStyle: {},
  handleValidations: () => {},
  t: () => {},
  value: '',
  readOnly: false,
};

DateInputComplex.propTypes = {
  /** Field identifier  */
  id: PropTypes.string.isRequired,

  /** Field name  */
  name: PropTypes.string.isRequired,

  /** Label text */
  label: PropTypes.string.isRequired,

  /** Secondary Label text */
  label2: PropTypes.string.isRequired,

  /** Placeholder label text */
  placeholder: PropTypes.string.isRequired,

  /** Type, like text, date, password, email, or tel */
  type: PropTypes.string,

  /** CSS class */
  cssClass: PropTypes.string,

  /** Icon, anchored left as a background */
  icon: PropTypes.string,

  /** Error message if validation occurs */
  errorMsg: PropTypes.string,
  onEnter: PropTypes.bool,
  customStyle: PropTypes.shape({}),
  handleValidations: PropTypes.func,
  t: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
};

export default withTranslation()(DateInputComplex);
