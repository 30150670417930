/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import lget from 'lodash.get';
import { isNullorEmpty } from '../../../../../utils/helperUtils';
import { checkValueTypeAndGetTheCount } from '../../../Utils/helper';
import ProfileItemTemplate from './Template/ProfileItem';
import { missingInfo } from './Template/MissingInfoTemplate';

/**
 * @description - ExpertiseAndSkills component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const ExpertiseAndSkills = ({
  fields,
  profileInfo,
  t,
  componentName,
  getEmptyFields,
}) => {
  const { items } = fields;
  const [data, setData] = useState([]);
  const [noData, showNoData] = useState(false);
  const [candidateId, setCandidateId] = useState('');

  useEffect(() => {
    if (profileInfo?.expertiseAndSkills) {
      setData(profileInfo.expertiseAndSkills);
      setCandidateId(profileInfo.id);
    }
  }, [profileInfo]);

  useEffect(() => {
    let skillData = profileInfo?.expertiseAndSkills;
    let emptyCount = 0;
    let emptyObj = {};
    let temp = 0;
    let array = [];
    items?.map((item, index) => {
      array.push(missingInfo(item, skillData));
    });
    array.map((item) => {
      Object.keys(item).map((k) => {
        if (k === 'emptyCount') {
          emptyCount = emptyCount + item[k];
        } else if (k === 'temp') {
          temp = temp + item[k];
        }
      });
    });
    emptyObj[componentName] = emptyCount;
    getEmptyFields(emptyObj);
    if (temp === 0) {
      showNoData(true);
    }
  }, [profileInfo]);

  return (
    <div className="accordion-container-block">
      {Object.keys(data).length > 0 ? (
        <>
          {items?.map((block, index) => (
            <ProfileItemTemplate
              data={data}
              candidateId={candidateId}
              block={block}
              filterRequired={true}
              t={t}
            />
          ))}
        </>
      ) : (
        <div className="no-content-data">{t('no-expertise-data')}</div>
      )}
      {noData && <div className="no-content-data">{t('no-expertise-data')}</div>}
    </div>
  );
};

ExpertiseAndSkills.defaultProps = {
  fields: {},
  t: (val) => val,
};

ExpertiseAndSkills.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
  };
};

export default withTranslation()(connect(mapStateToProps)(ExpertiseAndSkills));
