import React from 'react';
import PropTypes from 'prop-types';

import './forms.scss';

class Textbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filled: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    if (event.target.value) {
      this.setState({
        filled: true,
      });
    } else {
      this.setState({
        filled: false,
      });
    }
  }

  render() {
    const { customProps, focusHandler } = this.props;
    return (
      <div
        className={`form-block ${this.props.cssClass}`}
        style={this.props.customStyle}
      >
        <input
          style={{ backgroundImage: `url(${this.props.icon})` }}
          className={`${this.state.filled ? 'filled' : ''} ${
            this.props.errorBorder ? 'error-border' : ''
          }`}
          id={this.props.id}
          type={this.props.type}
          placeholder={this.props.placeholder}
          aria-label={this.props.ariaLabel}
          onChange={this.props.onHandleChange}
          onBlur={this.props.onBlurValidation}
          onFocus={focusHandler}
          {...customProps}
        />
        {this.props.label && (
          <label htmlFor={this.props.id}>{this.props.label}</label>
        )}
        {this.props.errorMsg && (
          <div className="error-msg">{this.props.errorMsg}</div>
        )}
      </div>
    );
  }
}

Textbox.defaultProps = {
  id: '',
  label: '',
  labelMsg: '',
  placeholder: '',
  type: 'text',
  cssClass: '',
  icon: '',
  errorMsg: '',
  customStyle: {},
  errorBorder: false,
  onBlurValidation: () => null,
  onHandleChange: () => null,
  customProps: {},
  focusHandler: () => null,
};

Textbox.propTypes = {
  /** Field identifier  */
  id: PropTypes.string.isRequired,

  /** Label text */
  label: PropTypes.string.isRequired,
  labelMsg: PropTypes.string,

  /** Placeholder label text */
  placeholder: PropTypes.string.isRequired,

  /** Type, like text, date, password, email, or tel */
  type: PropTypes.string,

  /** CSS class */
  cssClass: PropTypes.string,

  /** Icon, anchored left as a background */
  icon: PropTypes.string,

  /** Error message if validation occurs */
  errorMsg: PropTypes.string,

  customStyle: PropTypes.shape({}),
  /** Red border if validation occurs */
  errorBorder: PropTypes.bool,

  /** On blur Method */
  onBlurValidation: PropTypes.func,

  /** On Change Method */
  onHandleChange: PropTypes.func,
  customProps: PropTypes.shape({}),
  focusHandler: PropTypes.func,
};

export default Textbox;
