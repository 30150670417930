import React from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import ButtonLink from '../globals/link/ButtonLink';
import {
  Text,
  mediaApi,
  withSitecoreContext,
  RichText,
} from '@sitecore-jss/sitecore-jss-react';
import { getImg } from '../../../utils/helperUtils';
import './page-banners.scss';
/**
 *@description -  Stats banner component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */
const BannerStats = (props) => {
  const { fields, sitecoreContext } = props;
  const {
    title,
    statNumber,
    peopleTextLine,
    industryTextLine,
    ctaText,
    ctaUrl,
    backgroundImage,
  } = fields;
  const externalLinkType = ctaUrl?.value?.linktype === 'internal' ? false : true;
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';
  return (
    <section
      className={`banner-section stats ${brandName}`}
      style={{
        backgroundImage: `url(${mediaApi.updateImageUrl(getImg(backgroundImage))})`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <RichText tag="div" className="title" field={title} />
            <div className="stats-container">
              <CountUp
                className="stat"
                delay={0}
                end={statNumber?.value}
                separator=","
              />
              <div className="text">
                <Text field={peopleTextLine} />
                <br aria-hidden={true} />
                <Text field={industryTextLine} />
              </div>
            </div>

            <ButtonLink
              cssClass="reversed"
              ctaText={ctaText}
              ctaUrl={ctaUrl}
              externalLink={externalLinkType}
              arrow
            />
          </div>
        </div>
      </div>
    </section>
  );
};

BannerStats.defaultProps = {
  fields: {
    title: '',
    statNumber: 0,
    peopleTextLine: '',
    industryTextLine: '',
    ctaText: '',
    ctaUrl: '',
    backgroundImage: 'http://via.placeholder.com/1440x583/FEFEFE/EEEEEE',
    color: 'dark_blue',
  },
  sitecoreContext: {
    site: {
      name: '',
    },
    Country: {
      brandName: '',
    },
  },
};

BannerStats.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string.isRequired,
    statNumber: PropTypes.number.isRequired,
    peopleTextLine: PropTypes.string.isRequired,
    industryTextLine: PropTypes.string.isRequired,
    ctaText: PropTypes.string,
    ctaUrl: PropTypes.string,
    backgroundImage: PropTypes.string.isRequired,
    color: PropTypes.string,
  }),
  sitecoreContext: PropTypes.shape({
    route: PropTypes.shape({}),
    Country: PropTypes.shape({
      brandName: PropTypes.string,
    }),
  }).isRequired,
};

export default withSitecoreContext()(BannerStats);
