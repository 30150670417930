import React from 'react';
import PropTypes from 'prop-types';

const SiteFooterCountry = ({ title, country }) => (
  <div className="row footer-country">
    <div className="col">
      <div className="title">{title}</div>
      <a className="country" href="/">
        {country}
      </a>
    </div>
  </div>
);

SiteFooterCountry.defaultProps = {
  title: 'COUNTRY',
  country: 'United States',
};

SiteFooterCountry.propTypes = {
  title: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
};

export default SiteFooterCountry;
