/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import TextButton from '../../../core/globals/buttons/TextButton';
import Button from '../../../core/globals/buttons/Button';

/**
 * @description - Profile Navigation Component.
 * @param {Object} param0 - Input params.
 * @returns {Node} - HTML Template.
 */
const JobNav = ({
  activeStep,
  handleOnClickNext,
  handleOnClickBack,
  jobStorageDetails,
  loading,
  disabled,
  t,
}) => {
  const history = useHistory();
  const onBackClick = () => {
    history.push({
      pathname: jobStorageDetails?.jobURL || '/',
    });
  };
  return (
    <section className="create-profile profile-nav col-lg-6 offset-lg-3">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="profile-nav__wrap">
              <TextButton
                cssClass="dark-gray"
                text={t('back')}
                handleTextClick={
                  activeStep === 0 || !jobStorageDetails?.isPublicPosition
                    ? onBackClick
                    : handleOnClickBack
                }
              />
              {activeStep === 2 && (
                <TextButton cssClass="dark-gray" text={t('save-and-apply-later')} />
              )}
              <Button
                text={t('next')}
                handleButtonClick={handleOnClickNext}
                isLoading={loading}
                isDisabled={disabled}
                cssClass="profile-nav-next"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

JobNav.defaultProps = {};

JobNav.propTypes = {
  t: PropTypes.func.isRequired,
};

export default JobNav;
