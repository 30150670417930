import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { mediaApi, RichText } from '@sitecore-jss/sitecore-jss-react';

import ButtonLink from '../../globals/link/ButtonLink';
import '../page-banners.scss';
import { Colors } from '../../../../utils/enums';
import { getImg } from '../../../../utils/helperUtils';

/**
 * @description skewed page banner component
 * @param {*} fields - component content fields
 * @returns {node} - html
 */
const BannerSkewed = ({ fields }) => {
  const {
    title,
    subTitle,
    text,
    ctaText,
    ctaUrl,
    backgroundImage,
    colorMode,
    offset,
  } = fields;
  const [styleObj, setStyleObj] = useState({});

  useEffect(() => {
    setStyleObj(
      colorMode?.value ? { backgroundColor: Colors[colorMode?.value] } : {}
    );
  }, []);
  const imgVal = mediaApi.updateImageUrl(getImg(backgroundImage));
  return (
    <section
      className={`banner-section skewed  ${
        colorMode?.value ? colorMode.value.toLowerCase() : 'skewed-rect'
      }`}
      style={styleObj}
    >
      {/* <Image field={backgroundImage} className="banner-image" /> */}
      <figure
        className="banner-image"
        style={{
          backgroundImage: `url(${imgVal})`,
        }}
      >
        <img className="sr-only" alt={backgroundImage?.value?.alt} src={imgVal} />
      </figure>
      <div className="skewed-rectangle" style={styleObj}></div>
      <div className="container">
        <div className="row">
          <div className={'col-lg-5 col-12 ' + (offset?.value ? 'offset-lg-6' : '')}>
            {subTitle?.value && (
              <RichText tag="h5" className="sub-title" field={subTitle} />
            )}
            {title?.value && <RichText tag="h2" className="title" field={title} />}
          </div>
        </div>
        <div className="row">
          <div className={'col-lg-5 col-12 ' + (offset?.value ? 'offset-lg-6' : '')}>
            {text?.value && (
              <RichText tag="p" className="large reversed" field={text} />
            )}
            <ButtonLink
              cssClass="reversed-white"
              ctaText={ctaText}
              ctaUrl={ctaUrl}
              arrow
            />
          </div>
        </div>
      </div>
    </section>
  );
};

BannerSkewed.defaultProps = {
  fields: {
    title: { value: '' },
    subTitle: { value: '' },
    text: { value: '' },
    ctaText: { value: '' },
    ctaUrl: {
      value: null,
    },
    backgroundImage: {
      value: {
        src: 'http://via.placeholder.com/1440x583/FEFEFE/EEEEEE',
      },
    },
    colorMode: { value: 'BLUE' },
    offset: { value: false },
  },
};

BannerSkewed.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({ value: PropTypes.string.isRequired }),
    subTitle: PropTypes.shape({ value: PropTypes.string.isRequired }),
    text: PropTypes.shape({ value: PropTypes.string.isRequired }),
    ctaText: PropTypes.shape({ value: PropTypes.string }),
    ctaUrl: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
      }),
    }),
    backgroundImage: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }),
    }),
    colorMode: PropTypes.shape({ value: PropTypes.oneOf(['', 'BLUE']) }),
    offset: PropTypes.shape({ value: PropTypes.bool }),
  }),
};

export default BannerSkewed;
