import React from 'react';
import PropTypes from 'prop-types';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import CardLeader from '../../Cards/CardLeader';
import Slider from 'react-slick';
import { getImg, isDataExists } from '../../../../utils/helperUtils';

import '../cardscroll.scss';

const settings = {
  dots: true,
  // infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  infinite: false,
  arrows: false,
  accessibility: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1.1,
        slidesToScroll: 1,
      },
    },
  ],
};

/**
 * @description - Card Scroll Leadership component.
 * @param {object} props - Input props.
 * @returns {Node} - HTML section.
 */
const LeadershipCardScroll = (props) => {
  const { fields, sitecoreContext } = props;
  const { title, items = [] } = fields;
  const isItemExists = isDataExists(items);
  const titleColor =
    sitecoreContext?.Country?.brandName?.toLowerCase() == 'experis' && 'color-h2';

  return fields?.items && isItemExists ? (
    <section className={`card-scroll ${isItemExists ? '' : 'no-padding'}`}>
      <div className="container">
        <div className="card-scroll-header">
          <RichText tag="div" className={`title ${titleColor}`} field={title} />
        </div>
        {isItemExists && (
          <Slider {...settings}>
            {items.map((item, idx) => {
              const itemVal = item?.fields || {};
              return (
                <CardLeader
                  key={`card_${idx}`}
                  title={itemVal?.title}
                  text={itemVal?.text}
                  img={itemVal?.image?.value || getImg(itemVal?.image)}
                  altText={itemVal?.image?.altText || `Card Image ${idx}`}
                  ctaUrl={itemVal?.ctaUrl?.value}
                />
              );
            })}
          </Slider>
        )}
      </div>
    </section>
  ) : null;
};

LeadershipCardScroll.defaultProps = {
  fields: {
    title: '',
    items: [],
  },
};

LeadershipCardScroll.propTypes = {
  fields: PropTypes.shape({
    /** Header title */
    title: PropTypes.shape({
      value: PropTypes.string,
    }),

    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default withTranslation()(withSitecoreContext()(LeadershipCardScroll));
