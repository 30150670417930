import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import SEOJobLink from './SEOJobLink';
import './seoJobLink.scss';

/**
 * @description - Seo Job Link component
 * @param {*} fields - Seo Job Link component fields
 * @returns {Node} - HTML component
 */
const SEOJobLinkBlock = ({ fields }) => {
  const { title, subTitle, items, bgColor } = fields;
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    if (window) {
      resizeEvent();
      window.addEventListener('resize', resizeEvent);
    }
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  /**
   * @description to get width on load and resize
   * @param {string} setValue - url
   * @returns {null} - empty
   */
  const resizeEvent = () => {
    if (window) setMobileView(window.innerWidth < 768);
  };

  return (
    <section
      className={`seo-jobs seo-job-link-block ${
        bgColor?.value ? `seo-jobs--${bgColor?.value}` : `seo-jobs--default`
      }`}
    >
      <div className="container">
        <h1 className="seo-jobs__category">{title?.value}</h1>
        <RichText
          field={subTitle}
          tag="p"
          editable={true}
          className="text seo-jobs__category-text"
        />
        <div className="col-sm-12 col-md-12 col-xl-12">
          {!mobileView ? (
            <div>
              {items &&
                Array.isArray(items) &&
                items.map((item, index) => {
                  return <SEOJobLink key={index} jobList={item?.fields?.jobList} />;
                })}
            </div>
          ) : (
            <div>
              {items &&
                Array.isArray(items) &&
                items.map((item, index) => {
                  return <SEOJobLink key={index} jobList={item?.fields?.jobList} />;
                })}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

SEOJobLinkBlock.defaultProps = {
  fields: {
    title: {
      value: 'IT Focus Areas',
    },
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  },
};

SEOJobLinkBlock.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    subTitle: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    bgColor: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
  }),
};

export default withTranslation()(withSitecoreContext()(SEOJobLinkBlock));
