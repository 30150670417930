import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';

import TextLink from '../../globals/link/TextLink';

import '../locations.scss';

/**
 *
 * @param {string} title - office name
 * @param {string} address - office address
 * @param {string} hours - office hours
 * @param {string} phone - office phone
 * @param {string} hoursLabel - office hours label
 * @param {string} phoneLabel - office phone label
 * @param {string} ctaText - view details text
 * @param {object} ctaUrl - view details link
 * @returns {node} - html
 */
const LocationsNearbyItem = ({
  title,
  address,
  hours,
  phone,
  hoursLabel,
  phoneLabel,
  ctaText,
  ctaUrl,
}) => (
  <div className="near-location-item" role="listitem">
    {title?.value && (
      <RichText
        tag="div"
        className="location-title"
        aria-label={`location ${title?.value}`}
        field={title}
      />
    )}
    <div className="row locationItem">
      {address?.value && (
        <RichText
          tag="div"
          className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 address"
          aria-label={`address ${address?.value}`}
          field={address}
        />
      )}
      {hours?.value && (
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 working-hours">
          <strong>{hoursLabel}</strong>
          <br aria-hidden={true} />
          <RichText tag="div" field={hours} />
        </div>
      )}
      {phone?.value && (
        <div
          className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 phone"
          aria-label="phone"
        >
          <strong>{phoneLabel}</strong>
          <br aria-hidden={true} />
          <a
            href={`tel:${phone.value}`}
            aria-label="Phone number opens in a new window/tab"
          >
            <RichText tag="div" field={phone} />
          </a>
        </div>
      )}
    </div>
    <div className="cta">
      <TextLink ctaText={ctaText} ctaUrl={ctaUrl} arrow />
    </div>
  </div>
);

LocationsNearbyItem.defaultProps = {
  title: '',
  address: '',
  phone: '',
  hours: '',
  hoursLabel: '',
  phoneLabel: '',
  ctaText: '',
  ctaUrl: {
    value: {
      href: '#',
    },
  },
};

LocationsNearbyItem.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  hours: PropTypes.string.isRequired,
  hoursLabel: PropTypes.string.isRequired,
  phoneLabel: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.shape({
    value: PropTypes.shape({
      href: PropTypes.string.isRequired,
    }),
  }),
};

export default LocationsNearbyItem;
