import React, { useState, useEffect, Fragment } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image, mediaApi, RichText } from '@sitecore-jss/sitecore-jss-react';
// import { createUseStyles } from 'react-jss';

import ButtonLink from '../globals/link/ButtonLink';

// import { carouselItems } from './data';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Colors } from '../../../utils/enums';
import {
  getImg,
  // checkDataExists,
  setAltUrlForImg,
  checkColor,
} from '../../../utils/helperUtils';
import './slick_carousel.scss';

// const getStyleVals = createUseStyles({
//   rectDefault: {
//     '& .rectangle, .rectangle2': {
//       background: Colors['BLUE'],
//     },
//   },
//   rectOrange: {
//     '& .rectangle, .rectangle2': {
//       background: Colors['ORANGE'],
//     },
//   },
// });

/**
 * @description - Get Setting for React-slick carousel.
 * @param {Function} setNextIndex - Set next index state.
 * @param {Function} setCurrentIndex - Set current index state.
 * @returns {object} - Settings object.
 */
const getSlickSettings = (setNextIndex, setCurrentIndex) => ({
  accessibility: true,
  infinite: false,
  dots: true,
  fade: true,
  speed: 1750,
  arrows: false,
  useCSS: true,
  cssEase: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
  beforeChange: (oldIndex, newIndex) => {
    setTimeout(() => {
      setNextIndex(newIndex);
      setCurrentIndex(oldIndex);
    }, 500);
  },
});

/**
 * @description - Get Titles to display
 * @param {object} item - Carousel item.
 * @param {string} classVal - class name.
 * @returns {Node} - Titles texts
 */
const getTitles = (item, classVal) => {
  const { icon, subTitle, title } = item || {};
  const iconVal = getImg(icon);
  const logoVal = setAltUrlForImg(icon, 'Site Logo');
  return (
    <Fragment>
      <RichText tag="h1" className={iconVal ? 'title' : 'sub-title'} field={title} />
      {iconVal ? <Image field={logoVal} className={classVal} /> : null}
      <RichText
        tag="div"
        role="heading"
        aria-level="2"
        className={iconVal ? 'title' : 'desc-msg'}
        field={subTitle}
      />
    </Fragment>
  );
};

/**
 * @description - Carousel Block component using slick.
 * @param {object} props - Input props;
 * @returns {Node} - HTML carousel nodes.
 */
const CarouselBlock = ({ fields }) => {
  const [nextIndex, setNextIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const { items = [] } = fields;
  const slickSettings = getSlickSettings(setNextIndex, setCurrentIndex);
  const [animatingIn, setAnimatingIn] = useState('');
  const [animatingOut, setAnimatingOut] = useState('');
  const [backgroundColor, setBackgroundColor] = useState({});

  useEffect(() => {
    const colorArray = [];
    items.map((item) => {
      const fields = item?.fields || {};
      const { backgroundColor } = fields;
      colorArray.push(backgroundColor?.value);
    });
    setAnimatingOut('animating-out');
    setAnimatingIn('animating-in');
    setBackgroundColor(colorArray);
  }, []);

  return (
    <div className="carousel-container">
      <section className="carousel-block">
        <Slider {...slickSettings}>
          {items.map((item, idx) => {
            const fields = item.fields || {};
            const { icon, backgroundImage } = fields;
            const { ctaText, ctaUrl } = fields;
            const colorVal = checkColor(backgroundColor?.[idx]);
            // const classes = getStyleVals();

            return (
              <div key={item.id}>
                <div
                  className={classNames(
                    'carousel-items',
                    idx === currentIndex ? animatingOut : '',
                    idx === nextIndex ? animatingIn : ''
                    // { [classes.rectOrange]: colorVal === 'ORANGE' },
                    // { [classes.rectDefault]: colorVal !== 'ORANGE' }
                  )}
                  style={{
                    backgroundImage: `url(${mediaApi.updateImageUrl(
                      getImg(backgroundImage)
                    )})`,
                  }}
                >
                  <div
                    className="mobile-image"
                    style={{
                      backgroundImage: `url(${mediaApi.updateImageUrl(
                        getImg(backgroundImage)
                      )})`,
                    }}
                  />
                  <div
                    className="rectangle"
                    style={{ background: Colors[colorVal] }}
                  />
                  <div
                    className="rectangle2"
                    style={{ background: Colors[colorVal] }}
                  />
                  <div
                    className={classNames('content', {
                      'content-item': getImg(icon),
                    })}
                  >
                    {getTitles(fields, 'exp-logo')}

                    <div className="cta">
                      <ButtonLink
                        ctaText={ctaText}
                        ctaUrl={ctaUrl}
                        cssClass="reversed"
                        arrow
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </section>
    </div>
  );
};

CarouselBlock.defaultProps = {
  fields: {
    items: [],
  },
};

CarouselBlock.propTypes = {
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        fields: PropTypes.shape({
          icon: PropTypes.shape({}),
          backgroundImage: PropTypes.shape({}),
          title: PropTypes.shape({}),
          subTitle: PropTypes.shape({}),
          ctaText: PropTypes.shape({}),
          ctaUrl: PropTypes.shape({}),
          backgroundColor: PropTypes.shape({}),
        }),
      })
    ).isRequired,
  }),
};

export default CarouselBlock;
