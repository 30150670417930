/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  SitecoreContext,
  SitecoreContextFactory,
} from '@sitecore-jss/sitecore-jss-react';
import { Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { useDispatch, useStore, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import componentFactory from './temp/componentFactory';
import RouteHandler from './RouteHandler';
import FocusWrapper from './utils/FocusWrapper';
import { loadState, saveState } from './redux/store';
import { LOCATION } from './constants';
import { authstates } from './utils/enums';
import {
  initializeState,
  setLocation,
  adb2cRedirect,
} from './redux/actions/loginActions';
import { setAuthHeader } from './utils/auth';
import authConfig from './services/auth/authConfig';
import { getCandidateDetailsApi } from './services/apiServices/candidateService';
// import { uploadPluginConfigApi } from './services/apiServices/commonService';
import { errorProfileInfo } from './redux/actions/actions';
import { ThemeProvider } from 'styled-components';
import { style } from './styles/Themes/theme';
import { initMap } from './redux/actions';
import ThemeWrapper from './styles/Themes/themeWrapper';
import { JSONParser } from './utils/helperUtils';
import Toaster from './components/core/Toaster';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*',
  '/:lang([a-z]{2})/:sitecoreRoute*',
  '/:sitecoreRoute*',
];

// wrap the app with:
// ApolloProvider: provides an instance of Apollo GraphQL client to the app to make Connected GraphQL queries.
//    Not needed if not using connected GraphQL.
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
/**
 * @description - WrapAppRoot component.
 * @param {Node} AppRootComponent - Input params.
 * @returns {Node} - Router elements.
 */
const WrapAppRoot = (AppRootComponent) => {
  return (props) => {
    const { path, graphQLClient, Router, ssrState, t = () => {} } = props;
    const [channel, setChannel] = useState('');
    const [oneTrustKey, setOneTrustKey] = useState('');
    const [haveDetails, setHaveDetails] = useState(false);
    const [adb2cConfig, setConfigADB2C] = useState(null);
    const [language, setLanguage] = useState(null);
    const [googleApiKey, setGoogleAPIKey] = useState('');
    const [brandName, setBrandName] = useState(
      ssrState?.sitecore?.context?.Country?.brandName
    );
    // const [brandName, setBrandName] = useState('Stegplus');

    const [theme, setTheme] = useState(
      ssrState?.sitecore?.context?.Country?.brandTheme
        ? JSONParser(ssrState?.sitecore?.context?.Country?.brandTheme)
        : {}
    );
    const dispatch = useDispatch();
    const store = useStore();
    const loginReducer = useSelector((state) => state.loginReducer);
    const contextFactory = new SitecoreContextFactory();

    const scContextSubscribe = (context) => {
      if (channel === '') setChannel(context?.Country?.geoChannel);
      if (!googleApiKey && context?.Country?.googleAPIKey)
        setGoogleAPIKey(context?.Country?.googleAPIKey);
      if (adb2cConfig === null)
        setConfigADB2C({
          adb2c: context?.ADB2C,
          language: context?.language,
          path: props.path,
        });
      if (oneTrustKey === '') setOneTrustKey(context?.MetaData?.oneTrust);
      if (
        language === null ||
        (typeof language !== 'undefined' &&
          language &&
          language.toLowerCase() !== context?.language.toLowerCase())
      )
        setLanguage(context?.language);
      if (context?.Country?.brandName) {
        setBrandName(context?.Country?.brandName);
      }
      if (context?.Country?.brandTheme) {
        setTheme(
          context?.Country?.brandTheme
            ? JSONParser(context?.Country?.brandTheme)
            : {}
        );
      }
      // if (context?.LoginType === 'NA') {
      //   uploadPluginConfigApi(dispatch);
      // }
    };

    useEffect(() => {
      window.initGMap = () => dispatch(initMap());
      if (!window.google && channel) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAFdQLMUycnyxD6agbY8vCDU7rXe8PCXj0&channel=${channel}&libraries=places&callback=initGMap`;
        script.async = true;
        document.body.appendChild(script);
      }
    }, [channel]);

    useEffect(() => {
      // Set the axios interceptors.
      setAuthHeader(store);
      // Subscribe changes to store.
      store.subscribe(() => {
        if (localStorage) {
          const { loginReducer } = store.getState();
          saveState(loginReducer);
        }
      });
      const persistedState = localStorage ? loadState() : null;
      if (persistedState && persistedState.state !== authstates.PROGRESS) {
        dispatch(initializeState(persistedState));
      }
      if (path) dispatch(setLocation(path));
      //File Upload Configuration
      // uploadPluginConfigApi(dispatch);
    }, []);

    useEffect(() => {
      if (adb2cConfig && adb2cConfig.adb2c) {
        authConfig(
          adb2cConfig.adb2c,
          adb2cConfig.path,
          dispatch,
          adb2cConfig.language
        );
      }
    }, [adb2cConfig]);

    useEffect(() => {
      if (
        !haveDetails &&
        loginReducer &&
        loginReducer?.state === authstates.AUTHENTICATED
      ) {
        getCandidateDetails(loginReducer?.user);
      } else if (loginReducer?.state === authstates.UNAUTHENTICATED) {
        //uploadPluginConfigApi(dispatch);
      } else {
        if (sessionStorage) {
          const errorMsg = sessionStorage.getItem('errorMsg');
          if (errorMsg) {
            toast.error(t('error-message-adb2c'), {
              position: 'top-center',
              autoClose: 5000,
            });
            sessionStorage.removeItem('errorMsg');
          }
        }
      }
    }, [loginReducer]);

    // useEffect(() => {
    //   if (
    //     typeof window !== 'undefined' &&
    //     typeof language !== 'undefined' &&
    //     typeof OptanonWrapper !== 'undefined' &&
    //     language
    //   ) {
    //     window.location.reload(true);
    //   }
    // }, [language]);

    const getCandidateDetails = (user = {}) => {
      setHaveDetails(true);
      getCandidateDetailsApi(user, dispatch, loginReducer?.isRedirectFromAdb2c, 1);
      // uploadPluginConfigApi(dispatch);
      dispatch(adb2cRedirect(false));
    };
    return (
      <AppRootComponent
        channel={channel}
        graphQLClient={graphQLClient}
        Router={Router}
        oneTrustKey={oneTrustKey}
        language={language}
        contextSubscription={scContextSubscribe}
        googleApiKey={googleApiKey}
        brandName={brandName}
        theme={theme}
        {...props}
      />
    );
  };
};

class AppRoot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ssrRenderComplete: false,
      contextFactory: new SitecoreContextFactory(),
    };
    this.state.contextFactory.subscribeToContext(props.contextSubscription);

    if (props.ssrState && props.ssrState.sitecore && props.ssrState.sitecore.route) {
      // set the initial sitecore context data if we got SSR initial state
      this.state.contextFactory.setSitecoreContext({
        route: props.ssrState.sitecore.route,
        itemId: props.ssrState.sitecore.route.itemId,
        ...props.ssrState.sitecore.context,
      });
    } else if (props.ssrState) {
      this.state.contextFactory.setSitecoreContext(props.ssrState.sitecore.context);
    } else {
      this.state.contextFactory.setSitecoreContext(null);
    }
  }

  setSsrRenderComplete = (ssrRenderComplete) =>
    this.setState({
      ssrRenderComplete,
    });

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (JSON.stringify(this.props.language) !== JSON.stringify(nextProps.language)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  render() {
    const {
      path,
      Router,
      graphQLClient,
      channel,
      language,
      oneTrustKey,
      googleApiKey,
      brandName,
      theme,
    } = this.props;
    const routeRenderFunction = (props) => {
      return (
        <RouteHandler
          route={props}
          ssrRenderComplete={this.state.ssrRenderComplete}
          setSsrRenderComplete={this.setSsrRenderComplete}
          contextFactory={this.state.contextFactory}
        />
      );
    };

    return (
      <>
        {typeof oneTrustKey !== 'undefined' && oneTrustKey != '' && (
          <Helmet htmlAttributes={{ lang: language }}>
            <script
              type={`text/javascript`}
              src={`https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`}
              charset={`UTF-8`}
              data-domain-script={`${oneTrustKey}`}
              data-language={language}
            ></script>
            <script type={`text/javascript`}>{`
           
            function OptanonWrapper() {
                const hbspEvent = document.createEvent('Event');
                hbspEvent.initEvent('hbsp-form-reload', true, true);
                if(OptanonActiveGroups) {
                  window.dispatchEvent(hbspEvent);
                }
                const regCheckPerformance = /C0002/g;
                const regCheckFunctional = /,C0003,/;
                const regCheckTargeting = /,C0004,/;
                const regCheckSocial = /,C0008,/;
                if (OptanonActiveGroups.match(regCheckTargeting)) {
                  const elements = document.getElementsByClassName("optanon-category-C0004");
                  loadIframe(elements);
                }
                if (OptanonActiveGroups.match(regCheckFunctional)){
                  const elements = document.getElementsByClassName("optanon-category-C0003");
                  loadIframe(elements);
                }
                if (OptanonActiveGroups.match(regCheckPerformance)){
                  const elements = document.getElementsByClassName("optanon-category-C0002");
                  loadIframe(elements);
                }
                if (OptanonActiveGroups.match(regCheckSocial)){
                  const elements = document.getElementsByClassName("optanon-category-C0008");
                  loadIframe(elements);
                }
                function loadIframe(elements){
                  for(let i = 0; i < elements.length; i++){
                    elements[i].tagName === "IFRAME" && elements[i].dataset.src ? elements[i].setAttribute("src", elements[i].dataset.src) : -1;
                  }
                }

            }`}</script>
          </Helmet>
        )}

        <ApolloProvider client={graphQLClient}>
          <SitecoreContext
            componentFactory={componentFactory}
            contextFactory={this.state.contextFactory}
          >
            <ThemeProvider
              theme={
                Object.keys(theme).length > 0
                  ? theme
                  : brandName
                  ? style[brandName]
                  : {}
              }
            >
              <ThemeWrapper />
              <Toaster />
              <Router location={path} context={{}}>
                <FocusWrapper>
                  <Switch>
                    {routePatterns.map((routePattern) => (
                      <Route
                        exact
                        key={routePattern}
                        path={routePattern}
                        render={routeRenderFunction}
                      />
                    ))}
                  </Switch>
                </FocusWrapper>
              </Router>
            </ThemeProvider>
          </SitecoreContext>
        </ApolloProvider>
        <button
          id="ot-sdk-btn"
          className="ot-sdk-show-settings btnstyle"
          style={{ display: 'none' }}
        ></button>
      </>
    );
  }
}

export default withTranslation()(WrapAppRoot(AppRoot));
