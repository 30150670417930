import React from 'react';
import PropTypes from 'prop-types';
import {
  mediaApi,
  RichText,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';

import ButtonLink from '../globals/link/ButtonLink';
import { checkDataExists, getImg } from '../../../utils/helperUtils';
//import { Colors } from '../../../utils/enums';
import './calloutbar.scss';

/**
 *@description -  Calloutbar standard component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */
const CalloutBar = (props) => {
  const { fields, sitecoreContext } = props;
  const { mode, title, text, ctaText, ctaUrl, color, backgroundImage } = fields;
  const checkOverviewCondition =
    checkDataExists(mode) === 'simple' && checkDataExists(color) === 'GREY'
      ? 'overview'
      : checkDataExists(mode);
  const checkSimpleCondition =
    (checkDataExists(mode) === 'overview' && checkDataExists(color) === 'BLUE') ||
    checkDataExists(color) === 'GREEN'
      ? 'simple'
      : checkDataExists(mode);
  const checkCondition =
    checkDataExists(mode) === 'overview'
      ? checkSimpleCondition
      : checkOverviewCondition;
  // const bgColor = checkDataExists(color) ? checkDataExists(color) : 'BLUE';
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';

  return (
    <>
      <div className={`calloutbar-section ${checkCondition}`}>
        {checkDataExists(mode) === 'withlogo' && (
          <div
            className="calloutbar-x"
            style={{
              backgroundImage: `url(${mediaApi.updateImageUrl(
                getImg(backgroundImage)
              )})`,
            }}
          ></div>
        )}
        <div className="calloutbar-body container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <RichText
                field={title}
                tag="div"
                className={`title ${
                  checkCondition === 'overview' ? '' : 'reversed'
                }`}
              />
              <RichText tag="p" className="reversed large" field={text} />
              {checkCondition !== 'overview' ? (
                <ButtonLink
                  cssClass="reversed-white"
                  ctaText={ctaText}
                  ctaUrl={ctaUrl}
                  arrow
                />
              ) : (
                <ButtonLink
                  ctaText={ctaText}
                  ctaUrl={ctaUrl}
                  arrow
                  cssClass={brandName === 'stegplus' ? 'blue' : ''}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CalloutBar.defaultProps = {
  fields: {
    title: {},
    text: {},
    mode: {},
    ctaText: '',
    ctaUrl: '',
    color: '',
    backgroundImage: '',
  },
  sitecoreContext: {},
};

CalloutBar.propTypes = {
  fields: PropTypes.shape({
    title: {
      value: PropTypes.string,
    },
    text: {
      value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    },
    mode: {
      value: PropTypes.oneOf(['withlogo', 'simple', 'overview']),
    },
    ctaText: {
      value: PropTypes.string,
    },
    ctaUrl: {
      value: PropTypes.shape({}),
    },
    color: {
      value: PropTypes.string,
    },
    backgroundImage: {
      value: PropTypes.string,
    },
  }),
  sitecoreContext: PropTypes.shape({}),
};

export default withSitecoreContext()(CalloutBar);
