/*eslint-disable*/
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Button from '../../globals/buttons/Button';
import DynamicForm from '../../Forms/DynamicForm';
import { dataFetcher } from '../../../../dataFetcher';
import { EVENT_REGISTRATION_FORM } from '../../../../constants';

/**
 * @description - RSVP component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML.
 */
const EventRegistration = ({ fields, t, sitecoreContext, location }) => {
  const { route = {} } = sitecoreContext;
  const { itemId = '' } = route;
  const { title, items } = fields;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [errorCode, setErrorCode] = useState(false);
  const { register, handleSubmit, errors, getValues, setValue, control } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    setLoading(false);
    setErrorCode(false);
    setMessage('');
  }, [location]);

  /**
   * @description - Handle form submit function.
   * @param {Object} data - Form Data.
   * @returns {undefined}
   */
  const onSubmit = (data) => {
    const participantinfo = Object.assign({}, data);
    delete participantinfo?.email;
    const postData = {
      email: data?.email,
      EventItemID: itemId,
      participantinfo: {
        ...participantinfo,
      },
    };
    setLoading(true);
    dataFetcher(EVENT_REGISTRATION_FORM.POST_URL, 'POST', postData).then(
      (response) => {
        setLoading(false);
        setMessage(response?.data?.StatusMsg);
        if (response?.data?.ErrorCode && response?.data?.ErrorCode !== 0) {
          setErrorCode(true);
        } else {
          if (window?.dataLayer) {
            window.dataLayer.push({
              event: 'Forms',
              eventCategory: 'Form submission',
              formType: 'Event RSVP',
              // formPosition: '',
              relativeUrl: location?.pathname,
            });
          }
        }
      },
      () => {
        setLoading(false);
        setMessage('error-message');
        setErrorCode(true);
      }
    );
  };

  return (
    <div className="rsvp">
      <RichText tag="div" className="title" field={title} />
      {message !== '' ? (
        <p className={`statusMessage${errorCode ? 'Error' : 'Success'}`}>
          {t(message)}
        </p>
      ) : (
        <Fragment>
          <div className="row">
            <div className="col-lg-12">
              <DynamicForm
                formData={items}
                register={register}
                errors={errors}
                getValues={getValues}
                setValue={setValue}
                control={control}
              />
            </div>
          </div>
          <div className="cta">
            <Button
              text={t('register-now')}
              handleButtonClick={handleSubmit(onSubmit)}
              isLoading={loading}
              isDisabled={loading}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};

EventRegistration.defaultProps = {
  fields: {},
};

EventRegistration.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  sitecoreContext: PropTypes.shape({
    route: PropTypes.shape({}),
  }).isRequired,
  t: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default withTranslation()(withRouter(EventRegistration));
