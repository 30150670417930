import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Text,
  mediaApi,
  withSitecoreContext,
  RichText,
} from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import ButtonLink from '../link/ButtonLink';
import './errorscreen.scss';
import { getImg } from '../../../../utils/helperUtils';
//import data from './mockData';
/**
 * @description - Contact Us Callout bar.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template.
 */
const ErrorScreen = (props) => {
  const { fields, t, sitecoreContext } = props; //errorCode, text,goBackText,goBackUrl,contactText,contactUrl
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';
  const {
    errorCode,
    text,
    goBackText,
    goBackUrl,
    contactText,
    contactUrl,
    logo,
  } = fields;

  return (
    <div className="errorScreen">
      <section className={`calloutbar-section ${brandName}`}>
        <div
          className={`calloutbar-x ${brandName}`}
          style={{
            backgroundImage: `url(${mediaApi.updateImageUrl(getImg(logo))})`,
          }}
        ></div>
        <div className="calloutbar-body container">
          <div className="row">
            <div className="col-lg-8">
              {errorCode && errorCode?.value && (
                <p className="text-content">
                  {t('error-code')} {''}
                  <Text field={errorCode} editable={false} />
                </p>
              )}
              <RichText tag="div" className="title reversed" field={text} />
              <div className="buttonlink">
                <ButtonLink
                  cssClass="goback reversed-search"
                  ctaText={goBackText}
                  ctaUrl={goBackUrl}
                  cssArrowClass="styleArrow"
                />

                <ButtonLink
                  cssClass="reversed-search"
                  ctaText={contactText}
                  ctaUrl={contactUrl}
                  cssArrowClass="styleArrow"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

ErrorScreen.defaultProps = {
  fields: {
    errorCode: { value: '' },
    text: { value: '' },
    goBackText: { value: '' },
    contactText: { value: '' },
    goBackUrl: { value: '' },
    contactUrl: { value: '' },
    logo: { value: '' },
  },
};

ErrorScreen.propTypes = {
  fields: PropTypes.shape({
    errorCode: PropTypes.string.isRequired,
    text: PropTypes.string,
    goBackText: PropTypes.string,
    contactText: PropTypes.string,
    goBackUrl: PropTypes.string,
    contactUrl: PropTypes.string,
    logo: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({
    route: PropTypes.shape({}),
    Country: PropTypes.shape({
      brandName: PropTypes.string,
    }),
  }).isRequired,
};

export default withSitecoreContext()(withTranslation()(ErrorScreen));
