import React from 'react';
import PropTypes from 'prop-types';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import TextLink from '../globals/link/TextLink';
import './cards.scss';

/**
 * @description - Card Ad component.
 * @param {object} props - Input params.
 * @returns {Node} - HTML to render.
 */
const CardAd = (props) => {
  const { title, ctaText, ctaUrl } = props;
  return (
    <div className="card ad">
      <div className="card-body">
        <RichText tag="div" className="title reversed" field={title} />
        <TextLink cssClass="reversed-link" ctaText={ctaText} ctaUrl={ctaUrl} arrow />
      </div>
    </div>
  );
};

CardAd.defaultProps = {
  title: {
    value: '',
  },
  ctaText: {
    value: '',
  },
  ctaUrl: {
    value: {},
  },
};

CardAd.propTypes = {
  title: PropTypes.shape({
    value: PropTypes.string,
  }),
  ctaText: PropTypes.shape({
    value: PropTypes.string,
  }),
  ctaUrl: PropTypes.shape({
    value: PropTypes.shape({ href: PropTypes.string }),
  }),
};

export default withSitecoreContext()(CardAd);
