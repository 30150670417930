import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '../globals/link/ButtonLink';
import Button from '../globals/buttons/Button';
import TextLink from '../globals/link/TextLink';
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import { setAltUrlForImg, checkDataExists } from '../../../utils/helperUtils';
import './cards.scss';
import RegisterPartner from '../Forms/RegisterPartnerForm/RegisterPartner';
import { withTranslation } from 'react-i18next';

// eslint-disable-next-line require-jsdoc
const CardSubpage = ({
  title,
  text,
  img,
  altText,
  ctaText,
  ctaUrl,
  cssClass,
  items,
  t,
}) => {
  const externalLinkType = ctaUrl?.value?.linktype === 'internal' ? false : true;
  const [showRegPart, setshowRegPart] = useState(false);
  const handleModal = () => {
    setshowRegPart(!showRegPart);
  };

  return (
    <div className={`card article-simple ${cssClass}`}>
      <div className="subpage-image">
        <Image field={setAltUrlForImg(img, altText)} />
      </div>
      <div className="card-body subpage-subcard">
        <div>
          <RichText tag="div" className="title" field={title} />
          <RichText tag="p" className="large" field={text} />
        </div>

        {checkDataExists(ctaUrl) && checkDataExists(ctaText) && (
          <div className="subpage-button">
            {cssClass === 'manpower' ? (
              <TextLink cssClass="cta" ctaText={ctaText} ctaUrl={ctaUrl} />
            ) : (
              <ButtonLink
                ctaText={ctaText}
                ctaUrl={ctaUrl}
                ctaLabel={`${title} ${ctaText}`}
                externalLink={externalLinkType}
              />
            )}
          </div>
        )}
        {items.map((x, index) => (
          <div key={index}>
            <Button
              text={t('become-a-partner')}
              handleButtonClick={handleModal}
              // isLoading={loading}
              //isDisabled={disabled}
              cssClass="profile-nav-next"
            />
            {showRegPart && (
              <RegisterPartner
                title={x.fields.title}
                items={x.fields.items}
                handlefn={handleModal}
                showMode={showRegPart}
                cookie={x.fields.cookiesStatement}
                privacy={x.fields.privacyStatement}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

CardSubpage.defaultProps = {
  cssClass: '',
  title: '',
  text: '',
  img: 'http://via.placeholder.com/600x300',
  altText: '',
  ctaText: '',
  ctaUrl: '#',
  items: [],
  t: (val) => val,
};

CardSubpage.propTypes = {
  cssClass: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  altText: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  t: PropTypes.func.isRequired,
};

export default withTranslation()(CardSubpage);
